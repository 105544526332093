import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../core/auth.service';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FirstConnectionService {
  constructor(private firestore: AngularFirestore, private authService: AuthService, private httpClient: HttpClient) {}

  getBu() {
    return this.firestore.collection('businessUnit', ref => ref.orderBy('nomBu', 'asc')).snapshotChanges();
  }


  //Méthodes qui vont appelées les fonctions de auth.service pour récpérer les informations du user
  getDisplayNameUser() {
    return this.authService.currentUserDisplayName;
  }

  getEmailUser() {
    return this.authService.currentUserEmail;
  }

  getUidUser() {
    return this.authService.currentUserId;
  }

  getPhotoUser() {
    return this.authService.currentUserPhotoUrl;
  }

  /*
   getDomaineUser() {
    return this.authService.currentUserDomaine;
  }

  getBusinessUnitUser() {
    return this.authService.currentUserBusinessUnit;
  }

  getRoleUser() {
    return this.authService.currentUserRole;
  }
  */

  //récupérer les informations du user connues via URL
  getInformationsUser(id: string) {
    return this.firestore
      .collection('users')
      .doc(id)
      .snapshotChanges();
  }

  //ajout de la businessUnit du User Veolia
  updateInfosUser(value) {
    return this.firestore
      .collection('users', ref => ref.where('uid', '==', this.authService.currentUserId))
      .doc(this.authService.currentUserId) // dans la bd le uid du user = id du document de la collection users
      .update(value); //rajout de la businessUnit si VEOLIA + terms_radio="YES" si VEOLIA ou VENDOR
  }
  
  sendFirstConnectionVendor(firstname, lastname, emailVendor, solutionOwner){
    return this.httpClient.get('/api/first_connection_vendor?vendorEmail='+emailVendor+'&firstnameVendor='+firstname+'&lastnameVendor='+lastname+'&solOwner='+solutionOwner, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }
  
  public handleErrorHttpBasic(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }
}
