import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../core';
import { AdminComponent } from './admin.component';

const routes: Routes = [{ path: '', component: AdminComponent, data: { title: extract('Admin') } }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AdminRoutingModule {}
