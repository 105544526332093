import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './material.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { CoreModule } from './core';
import { HomeModule } from './home/home.module';
import { HomeUserModule } from './homeUser/homeUser.module';
import { DemandeDlaModule } from './homeUser/demandeDla/demandeDla.module';
import { AboutModule } from './about/about.module';
import { ContactModule } from './contact/contact.module';
import { AdminModule } from './admin/admin.module';
import { EvaluatorModule } from './evaluator/evaluator.module';
import { ProfileModule } from './homeUser/profile/profile.module';
import { EditDemandeDlaModule } from './homeUser/editDemandeDla/editDemandeDla.module';
import { TableauEvalsModule } from './homeUser/tableauEvals/tableauEvals.module';
import { FirstConnectionModule } from './firstConnection/firstConnection.module';
import { EditPasswordModule } from './editPassword/editPassword.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { LogsModule } from './homeUser/logs/logs.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { TableauEvalsResolver } from './homeUser/tableauEvals/tableauEvals.resolver';
import { EditDemandeDlaResolver } from './homeUser/editDemandeDla/editDemandeDla.resolver';
import { GestionVendorResolver } from './homeUser/gestionVendor/gestionVendor.resolver';
import { FirstConnectionResolver } from './firstConnection/firstConnection.resolver';
import { EditPasswordResolver } from './editPassword/editPassword.resolver';
import { LogsResolver } from './homeUser/logs/logs.resolver';
import { SolutionResolver } from './homeUser/formulairesAccess/solution.resolver';

import { FormulaireDaResolver } from './homeUser/formulairesAccess/formulaireAll/formulaireDa.resolver';
import { FormulaireLaResolver } from './homeUser/formulairesAccess/formulaireAll/formulaireLa.resolver';
import { FormulaireA28Resolver } from './homeUser/formulairesAccess/formulaireAll/formulaireA28.resolver';
import { FormulaireA32Resolver } from './homeUser/formulairesAccess/formulaireAll/formulaireA32.resolver';
import { FormulaireIaaSResolver } from './homeUser/formulairesAccess/formulaireAll/formulaireIaaS.resolver';

import { FormulaireAllService } from './homeUser/formulairesAccess/formulaireAll/formulaireAll.service';

import { Evaluation } from './homeUser/formulairesAccess/formulaireAll/evaluation.model';
import { ValidateSolutionResolver } from './admin/validate-solution.resolver.';
import { ValidateSolutionModule } from './admin/validate-solution.module';

import firebase from 'firebase/compat/app';
import {MatTabsModule} from "@angular/material/tabs";
import {CommonModule} from "@angular/common";

// import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ConfirmModalModule } from './components/confirm-modal/confirm-modal.module';
import { bearerTokenInterceptor } from './core/token-interceptor';
import { provideAuth } from '@angular/fire/auth';
import { getAuth } from 'firebase/auth';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    ShellModule,
    HomeModule,
    DemandeDlaModule,
    AboutModule,
    LogsModule,
    HomeUserModule,
    EditDemandeDlaModule,
    ValidateSolutionModule,
    ProfileModule,
    FirstConnectionModule,
    EditPasswordModule,
    AdminModule,
    EvaluatorModule,
    ContactModule,
    TableauEvalsModule,
    BrowserModule,
    NgxPageScrollModule, //scroll page
    NgxPageScrollCoreModule, //scroll
    //Pour BD
    AngularFireModule.initializeApp(environment.firebaseApp),
    // la persistance des données hors connexion: Firestore enablePersistence active la mise en cache des données Cloud Firestore lorsque l'appareil est hors ligne.
    AngularFirestoreModule.enablePersistence({synchronizeTabs: true}), //+allow shared access, multi-tab synchronization
    provideAuth(() => getAuth()),
    ToastrModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    ConfirmModalModule,
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    provideHttpClient(
      withInterceptors([bearerTokenInterceptor])
    ),
    AngularFireDatabase,
    AngularFirestore,
    AngularFireAnalyticsModule,
    EditDemandeDlaResolver,
    ValidateSolutionResolver,
    TableauEvalsResolver,
    GestionVendorResolver,
    FirstConnectionResolver,
    EditPasswordResolver,
    SolutionResolver,
    FormulaireDaResolver,
    FormulaireLaResolver,
    FormulaireA28Resolver,
    FormulaireA32Resolver,
    FormulaireIaaSResolver,
    LogsResolver,
    FormulaireAllService,
    Evaluation,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

