import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../../core';
import { EditDemandeDlaComponent } from './editDemandeDla.component';
import { DatePipe } from '@angular/common';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: '', component: EditDemandeDlaComponent, data: { title: extract('Edit') } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [DatePipe]
})
export class EditDemandeDlaRoutingModule {}
