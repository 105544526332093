import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { HomeUserService } from './homeUser.service';
import { Demande } from '../homeUser/demandeDla/demande.model';
import { AuthService } from '../core/auth.service';
import { filter } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SecurityScoreCard } from 'app/admin/securityScoreCard.model';
import {MatDialog} from '@angular/material/dialog';
import { DemandOverviewModalComponent } from 'app/admin/demand-overview-modal.component';

@Component({
  selector: 'app-homeUser',
  templateUrl: './homeUser.component.html',
  styleUrls: ['./homeUser.component.scss']
})
export class HomeUserComponent implements OnInit {

    //SaaS
  demandesSaaSUser: Demande[]; //demandesSaaSUser du user (il est SO)
  demandesSaaSUserVendor: Demande[]; // demandesSaaSUser partagées au vendor
  demandesSaaSUserPartagees: Demande[]; //demandesSaaSUser partagées au user veolia dont il n'est pas SO
  demandesSaaSUserBu: Demande[]; //ensemble des demandes SaaS qui appartiennent à la BU du user
  demandesSaaSUserOtherBu: Demande[]; //ensemble des demandes SaaS qui n'appartiennent pas à la BU du user
  demandesSaaSUserEvaluatorAuthorized: Demande[]; //ensemble des demandes SaaS dont le user est nommé sous emailEvaluatorAuthorized

    //IaaS
  demandesIaaSUser: Demande[]; //demandesIaaSUser du user (il est SO)
  demandesIaaSUserPartagees: Demande[]; //demandesIaaSUserPartagees partagées au user veolia dont il n'est pas SO
  demandesIaaSUserBu: Demande[]; //ensemble des demandes IaaS qui appartiennent à la BU du user
  demandesIaaSUserOtherBu: Demande[]; //ensemble des demandes IaaS qui n'appartiennent pas à la BU du user

  searchString: string; //référence name=searchString dans à homePm.html

  userBu: string; //recup la bu actuelle du user connecté
  userRole: string;

  sharedRequest: boolean;

  idDoc: string;

  listRequestsSharedWithUser: string[] = [];

  homeUserRoute = true;

  public showOverlay = true;

  //SaaS
  stop1: any ;
  stop2: any ;
  stop3: any ;

  //SSC
  securityScoreCardData: SecurityScoreCard[];
  observableSSC: any ;
  observableSSC2: any ;
  observableSSC3: any ;

  constructor(
    private route: ActivatedRoute,
    private service: HomeUserService,
    private router: Router,
    public auth: AuthService,
    private firestore: AngularFirestore,
    public dialog: MatDialog
  ) {

    //eviter le textcontent null globalSta
    router.events
    .pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart)
    )
    .subscribe((event:  NavigationStart) => {
      // "event" here is now of type "NavigationStart"
      console.log('--ROUTE EVENT START-- ' + event);
      if (event.url !== '/homeUser') {
        this.homeUserRoute = true;
      }

    });

    router.events
    .pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    )
    .subscribe((event:  NavigationEnd) => {
      // "event" here is now of type "NavigationEnd"
      if (event.url !== '/homeUser') {
        this.homeUserRoute = false;
      }
    });
  }

  //appliquer le datatbale quand on change de tab car par défaut c'est que les 2 premiers tab qui appliquent le datatable
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    //  console.log('tabChangeEvent => ', tabChangeEvent);
    //  console.log('index => ', tabChangeEvent.index);
    console.log('nom => ', tabChangeEvent.tab.textLabel);

    this.showOverlay = true;

    //SaaS//
    //SaaS default
    if(tabChangeEvent.tab.ariaLabel === 'SaaStab'){
      //mat-tab -> aria-label=UserReqSaaS (table -> aria-label=User SaaS requests (veolia) + aria-label=Shared SaaS requests (veolia) + aria-label=Named as evaluator SaaS requests (veolia) + aria-label=User SaaS requests (vendor))
        if(this.userRole === 'Vendor'){
            this.getUserVendorSaaSRequests() //loaded Par defaut
        }
        if(this.userRole !== 'Vendor'){
             this.getUserSaaSRequests() //loaded Par defaut
        }
    }

    if(tabChangeEvent.tab.ariaLabel === 'UserReqSaaS'){
      //mat-tab -> aria-label=UserReqSaaS (table -> aria-label=User SaaS requests (veolia) + aria-label=Shared SaaS requests (veolia) + aria-label=Named as evaluator SaaS requests (veolia) + aria-label=User SaaS requests (vendor))
        if(this.userRole === 'Vendor'){
            this.getUserVendorSaaSRequests() //loaded Par defaut
        }
        if(this.userRole !== 'Vendor'){
             this.getUserSaaSRequests() //loaded Par defaut
        }
    }

    if(tabChangeEvent.tab.ariaLabel === 'UserBuSaaSReq'){
      this.getUserBuSaaSRequests() //mat-tab -> aria-label=UserBuSaaSReq (table -> aria-label=All SaaS requests from my BU)
    }

    if(tabChangeEvent.tab.ariaLabel === 'UserOtherBuSaaSReq'){
      this.getUserOtherBuSaaSRequests() //mat-tab -> aria-label=UserOtherBuSaaSReq (table -> aria-label=All SaaS requests from other BUs)
    }
    //END SaaS

    //IaaS//
    //IaaS default
    if(tabChangeEvent.tab.ariaLabel === 'IaaStab'){
        //mat-tab -> aria-label=UserReqIaaS (table -> aria-label=User IaaS requests (veolia) + aria-label=Shared IaaS requests (veolia))
        this.getUserIaaSRequests() //loaded Par defaut
    }

    if(tabChangeEvent.tab.ariaLabel === 'UserReqIaaS'){
        //mat-tab -> aria-label=UserReqIaaS (table -> aria-label=User IaaS requests (veolia) + aria-label=Shared IaaS requests (veolia))
        this.getUserIaaSRequests()
    }

    if(tabChangeEvent.tab.ariaLabel === 'UserBuIaaSReq'){
      this.getUserBuIaaSRequests() //mat-tab -> aria-label=UserBuIaaSReq (table -> aria-label=All IaaS requests from my BU)
    }

    if(tabChangeEvent.tab.ariaLabel === 'UserOtherBuIaaSReq'){
      this.getUserOtherBuIaaSRequests() //mat-tab -> aria-label=UserOtherBuIaaSReq (table -> aria-label=All IaaS requests from other BUs)
    }
    //END IaaS

  };

  ngOnInit() {
    //par défaut à l'arrivée sur homeUser
    const fire = firebase.firestore();
    fire
      .collection('users')
      .where('uid', '==', this.auth.currentUserId)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          this.userBu = doc.data().businessUnit;
          this.userRole = doc.data().role;

          if(this.userRole === 'Vendor'){
              this.getUserVendorSaaSRequests() //loaded Par defaut
          }

          if(this.userRole !== 'Vendor'){
              this.getUserSaaSRequests() //loaded Par defaut
          }
        });
      })
  }

  //OK
//   ngOnDestroy() {
//       this.delay(4000).then(any => {
//       console.log("DANS LE DESTROYYYYY")
//         this.test.unsubscribe();
//       });
//     }

    ///////SaaS///////
    //3 tabs Veolia
    getUserSaaSRequests() {
    //VEOLIA mat-tab -> aria-label=UserReqSaaS (table -> aria-label=User SaaS requests (veolia))
        this.stop1 = this.service.getDemandesSaaSUser().subscribe(actionArray => {
          this.demandesSaaSUser = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as Demande;

          }

          });
        });

        this.delay(4000).then(any => {
            this.stop1.unsubscribe();
        });
        //this.ngOnDestroy();
        this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaSUser.length; i++) {

                if(document.getElementById('globalSta'+i)) {
                 //GLOBAL status
                    if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Empty") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Pending") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                }

                if(document.getElementById('designSta'+i)) {
                //DESIGN status
                    if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Reservations") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Completed") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('designSta'+i).textContent === "New") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Need help") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('legalSta'+i)) {
                //LEGAL status
                    if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Completed") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "New") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Need help") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                    }
                }

                //ARTICLE28 status
                // if (document.getElementById('article28Staaaa'+i).textContent === "Recommended") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#97BF0D';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Reservations") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#E09F25';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Not recommended") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#E95F47';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Completed") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = 'purple';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "New") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#9C9E9F';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Vendor is responding") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#54BBBA';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Need help") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = 'black';
                // }

                if(document.getElementById('article32Sta'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "New") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

        //GET data from securityScoreCard collection
        this.observableSSC = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });

        //VEOLIA mat-tab -> aria-label=UserReqSaaS (table -> aria-label=Shared SaaS requests)
        this.stop2 = this.service.getDemandesSaaSUserShared().subscribe(actionArray => {
          this.demandesSaaSUserPartagees = actionArray.map(item => {
            //console.log("DOC ID " + item.payload.doc.id + " EMAIL INITIATOR " + item.payload.doc.get('emailInitiatorVeolia'));

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as Demande;

          }

          });
        });

        this.delay(4000).then(any => {
            this.stop2.unsubscribe();
        });
        //this.ngOnDestroy();
        this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaSUserPartagees.length; i++) {

                if(document.getElementById('globalStaa'+i)) {
                 //GLOBAL status
                    if (document.getElementById('globalStaa'+i).textContent === "Recommended") {
                        document.getElementById('globalStaa'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('globalStaa'+i).textContent === "Reservations") {
                        document.getElementById('globalStaa'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('globalStaa'+i).textContent === "Not recommended") {
                        document.getElementById('globalStaa'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('globalStaa'+i).textContent === "Empty") {
                        document.getElementById('globalStaa'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('globalStaa'+i).textContent === "Vendor is responding") {
                        document.getElementById('globalStaa'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('globalStaa'+i).textContent === "Canceled") {
                        document.getElementById('globalStaa'+i).style.backgroundColor = 'red';
                    }
                    if (document.getElementById('globalStaa'+i).textContent === "Pending") {
                        document.getElementById('globalStaa'+i).style.backgroundColor = 'red';
                    }
                }

                if(document.getElementById('designStaa'+i)) {
                //DESIGN status
                    if (document.getElementById('designStaa'+i).textContent === "DLA is reviewing") {
                        document.getElementById('designStaa'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('designStaa'+i).textContent === "Recommended") {
                        document.getElementById('designStaa'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('designStaa'+i).textContent === "Reservations") {
                        document.getElementById('designStaa'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('designStaa'+i).textContent === "Not recommended") {
                        document.getElementById('designStaa'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('designStaa'+i).textContent === "Completed") {
                        document.getElementById('designStaa'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('designStaa'+i).textContent === "New") {
                        document.getElementById('designStaa'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('designStaa'+i).textContent === "Vendor is responding") {
                        document.getElementById('designStaa'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('designStaa'+i).textContent === "Need help") {
                        document.getElementById('designStaa'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('legalStaa'+i)) {
                //LEGAL status
                    if (document.getElementById('legalStaa'+i).textContent === "DLA is reviewing") {
                        document.getElementById('legalStaa'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('legalStaa'+i).textContent === "Recommended") {
                        document.getElementById('legalStaa'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('legalStaa'+i).textContent === "Reservations") {
                        document.getElementById('legalStaa'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('legalStaa'+i).textContent === "Not recommended") {
                        document.getElementById('legalStaa'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('legalStaa'+i).textContent === "Completed") {
                        document.getElementById('legalStaa'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('legalStaa'+i).textContent === "New") {
                        document.getElementById('legalStaa'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('legalStaa'+i).textContent === "Vendor is responding") {
                        document.getElementById('legalStaa'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('legalStaa'+i).textContent === "Need help") {
                        document.getElementById('legalStaa'+i).style.backgroundColor = 'black';
                    }
                }

                //ARTICLE28 status
                // if (document.getElementById('article28Staa'+i).textContent === "Recommended") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#97BF0D';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Reservations") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#E09F25';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Not recommended") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#E95F47';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Completed") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = 'purple';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "New") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#9C9E9F';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Vendor is responding") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#54BBBA';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Need help") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = 'black';
                // }

                if(document.getElementById('article32Staa'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Staa'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Staa'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Staa'+i).textContent === "Recommended") {
                        document.getElementById('article32Staa'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Staa'+i).textContent === "Reservations") {
                        document.getElementById('article32Staa'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Staa'+i).textContent === "Not recommended") {
                        document.getElementById('article32Staa'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Staa'+i).textContent === "Completed") {
                        document.getElementById('article32Staa'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Staa'+i).textContent === "New") {
                        document.getElementById('article32Staa'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Staa'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Staa'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Staa'+i).textContent === "Need help") {
                        document.getElementById('article32Staa'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

        //GET data from securityScoreCard collection
        this.observableSSC2 = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });

        //VEOLIA mat-tab -> aria-label=UserReqSaaS (table -> aria-label=Shared SaaS requests)
        this.stop3 = this.service.getDemandesSaaSUserNamedAsEvaluator().subscribe(actionArray => {
          this.demandesSaaSUserEvaluatorAuthorized = actionArray.map(item => {
            //console.log("DOC ID " + item.payload.doc.id + " EMAIL INITIATOR " + item.payload.doc.get('emailInitiatorVeolia'));

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as Demande;

          }

          });
        });

        this.delay(4000).then(any => {
            this.stop3.unsubscribe();
        });
        //this.ngOnDestroy();
        this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaSUserEvaluatorAuthorized.length; i++) {

                if(document.getElementById('globalStaaa'+i)) {
                 //GLOBAL status
                    if (document.getElementById('globalStaaa'+i).textContent === "Recommended") {
                        document.getElementById('globalStaaa'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('globalStaaa'+i).textContent === "Reservations") {
                        document.getElementById('globalStaaa'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('globalStaaa'+i).textContent === "Not recommended") {
                        document.getElementById('globalStaaa'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('globalStaaa'+i).textContent === "Empty") {
                        document.getElementById('globalStaaa'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('globalStaaa'+i).textContent === "Vendor is responding") {
                        document.getElementById('globalStaaa'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('globalStaaa'+i).textContent === "Canceled") {
                        document.getElementById('globalStaaa'+i).style.backgroundColor = 'red';
                    }
                    if (document.getElementById('globalStaaa'+i).textContent === "Pending") {
                        document.getElementById('globalStaaa'+i).style.backgroundColor = 'red';
                    }
                }

                if(document.getElementById('designStaaa'+i)) {
                //DESIGN status
                    if (document.getElementById('designStaaa'+i).textContent === "DLA is reviewing") {
                        document.getElementById('designStaaa'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('designStaaa'+i).textContent === "Recommended") {
                        document.getElementById('designStaaa'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('designStaaa'+i).textContent === "Reservations") {
                        document.getElementById('designStaaa'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('designStaaa'+i).textContent === "Not recommended") {
                        document.getElementById('designStaaa'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('designStaaa'+i).textContent === "Completed") {
                        document.getElementById('designStaaa'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('designStaaa'+i).textContent === "New") {
                        document.getElementById('designStaaa'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('designStaaa'+i).textContent === "Vendor is responding") {
                        document.getElementById('designStaaa'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('designStaaa'+i).textContent === "Need help") {
                        document.getElementById('designStaaa'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('legalStaaa'+i)) {
                //LEGAL status
                    if (document.getElementById('legalStaaa'+i).textContent === "DLA is reviewing") {
                        document.getElementById('legalStaaa'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('legalStaaa'+i).textContent === "Recommended") {
                        document.getElementById('legalStaaa'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('legalStaaa'+i).textContent === "Reservations") {
                        document.getElementById('legalStaaa'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('legalStaaa'+i).textContent === "Not recommended") {
                        document.getElementById('legalStaaa'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('legalStaaa'+i).textContent === "Completed") {
                        document.getElementById('legalStaaa'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('legalStaaa'+i).textContent === "New") {
                        document.getElementById('legalStaaa'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('legalStaaa'+i).textContent === "Vendor is responding") {
                        document.getElementById('legalStaaa'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('legalStaaa'+i).textContent === "Need help") {
                        document.getElementById('legalStaaa'+i).style.backgroundColor = 'black';
                    }
                }

                //ARTICLE28 status
                // if (document.getElementById('article28Staa'+i).textContent === "Recommended") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#97BF0D';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Reservations") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#E09F25';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Not recommended") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#E95F47';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Completed") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = 'purple';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "New") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#9C9E9F';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Vendor is responding") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = '#54BBBA';
                // }
                // if (document.getElementById('article28Staa'+i).textContent === "Need help") {
                //     document.getElementById('article28Staa'+i).style.backgroundColor = 'black';
                // }

                if(document.getElementById('article32Staaa'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Staaa'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Staaa'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Staaa'+i).textContent === "Recommended") {
                        document.getElementById('article32Staaa'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Staaa'+i).textContent === "Reservations") {
                        document.getElementById('article32Staaa'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Staaa'+i).textContent === "Not recommended") {
                        document.getElementById('article32Staaa'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Staaa'+i).textContent === "Completed") {
                        document.getElementById('article32Staaa'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Staaa'+i).textContent === "New") {
                        document.getElementById('article32Staaa'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Staaa'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Staaa'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Staaa'+i).textContent === "Need help") {
                        document.getElementById('article32Staaa'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

        //GET data from securityScoreCard collection
        this.observableSSC3 = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });
    }

    //1 tab vendor
    getUserVendorSaaSRequests() {
    //VENDOR div-> (table -> aria-label=User vendor SaaS requests)
    //demandesSaaSUserShared partagées au vendor
        this.stop2 = this.service.getDemandesSaaSUserVendor().subscribe(actionArray => {
          this.demandesSaaSUserVendor = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as Demande;

          }

          });
        });

        this.delay(4000).then(any => {
            this.stop2.unsubscribe();
        });
        //this.ngOnDestroy();
        this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaSUserVendor.length; i++) {

                if(document.getElementById('globalSta'+i)) {
                 //GLOBAL status
                if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('globalSta'+i).textContent === "Empty") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
                if (document.getElementById('globalSta'+i).textContent === "Pending") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
                }

                if(document.getElementById('designSta'+i)) {
                //DESIGN status
                if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                    document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('designSta'+i).textContent === "Recommended") {
                    document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('designSta'+i).textContent === "Reservations") {
                    document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                    document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('designSta'+i).textContent === "Completed") {
                    document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                }
                if (document.getElementById('designSta'+i).textContent === "New") {
                    document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('designSta'+i).textContent === "Need help") {
                    document.getElementById('designSta'+i).style.backgroundColor = 'black';
                }
                }

                if(document.getElementById('legalSta'+i)) {
                //LEGAL status
                if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                    document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                    document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                    document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                    document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('legalSta'+i).textContent === "Completed") {
                    document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                }
                if (document.getElementById('legalSta'+i).textContent === "New") {
                    document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('legalSta'+i).textContent === "Need help") {
                    document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                }
                }

                //ARTICLE28 status
                // if (document.getElementById('article28Staaa'+i).textContent === "Recommended") {
                //     document.getElementById('article28Staaa'+i).style.backgroundColor = '#97BF0D';
                // }
                // if (document.getElementById('article28Staaa'+i).textContent === "Reservations") {
                //     document.getElementById('article28Staaa'+i).style.backgroundColor = '#E09F25';
                // }
                // if (document.getElementById('article28Staaa'+i).textContent === "Not recommended") {
                //     document.getElementById('article28Staaa'+i).style.backgroundColor = '#E95F47';
                // }
                // if (document.getElementById('article28Staaa'+i).textContent === "Completed") {
                //     document.getElementById('article28Staaa'+i).style.backgroundColor = 'purple';
                // }
                // if (document.getElementById('article28Staaa'+i).textContent === "New") {
                //     document.getElementById('article28Staaa'+i).style.backgroundColor = '#9C9E9F';
                // }
                // if (document.getElementById('article28Staaa'+i).textContent === "Vendor is responding") {
                //     document.getElementById('article28Staaa'+i).style.backgroundColor = '#54BBBA';
                // }
                // if (document.getElementById('article28Staaa'+i).textContent === "Need help") {
                //     document.getElementById('article28Staaa'+i).style.backgroundColor = 'black';
                // }

                if(document.getElementById('article32Sta'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "New") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

        //GET data from securityScoreCard collection
        this.observableSSC = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });
    }

    //1 tab veolia
    getUserBuSaaSRequests(){
    //mat-tab -> aria-label=UserBuSaaSReq (table -> aria-label=All SaaS requests from my BU)
        this.stop1 = this.service.getDemandesSaaSBuUser(this.userBu).subscribe(actionArray => {
          this.demandesSaaSUserBu = actionArray.map(item => {
            //console.log("DOC ID " + item.payload.doc.id + " EMAIL INITIATOR " + item.payload.doc.get('emailInitiatorVeolia'));

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as Demande;

          }

          });
        });

        this.delay(4000).then(any => {
            this.stop1.unsubscribe();
        });
        //this.ngOnDestroy();
        this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaSUserBu.length; i++) {

                if(document.getElementById('globalSta'+i)) {
                 //GLOBAL status
                    if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Empty") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Pending") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                }

                if(document.getElementById('designSta'+i)) {
                //DESIGN status
                    if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Reservations") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Completed") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('designSta'+i).textContent === "New") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Need help") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('legalSta'+i)) {
                //LEGAL status
                    if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Completed") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "New") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Need help") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                    }
                }

                //ARTICLE28 status
                // if (document.getElementById('article28Staaaa'+i).textContent === "Recommended") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#97BF0D';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Reservations") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#E09F25';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Not recommended") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#E95F47';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Completed") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = 'purple';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "New") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#9C9E9F';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Vendor is responding") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#54BBBA';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Need help") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = 'black';
                // }

                if(document.getElementById('article32Sta'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "New") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

        //GET data from securityScoreCard collection
        this.observableSSC = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });
    }

    //1 tab veolia
    getUserOtherBuSaaSRequests() {
    //mat-tab -> aria-label=UserOtherBuSaaSReq (table -> aria-label=All other SaaS requests from other BUs)
        this.stop1 = this.service.getDemandesSaaSOtherBuUser(this.userBu).subscribe(actionArray => {
          this.demandesSaaSUserOtherBu = actionArray.map(item => {
            //console.log("DOC ID " + item.payload.doc.id + " EMAIL INITIATOR " + item.payload.doc.get('emailInitiatorVeolia'));

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as Demande;

          }
          
          console.log(this.demandesSaaSUserOtherBu)

          });
        });

        this.delay(4000).then(any => {
            this.stop1.unsubscribe();
        });
        //this.ngOnDestroy();

        this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaSUserOtherBu.length; i++) {

                if(document.getElementById('globalSta'+i)) {
                 //GLOBAL status
                    if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Empty") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Pending") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                }

                if(document.getElementById('designSta'+i)) {
                //DESIGN status
                    if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Reservations") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Completed") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('designSta'+i).textContent === "New") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Need help") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('legalSta'+i)) {
                //LEGAL status
                    if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Completed") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "New") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Need help") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                    }
                }

                //ARTICLE28 status
                // if (document.getElementById('article28Staaaa'+i).textContent === "Recommended") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#97BF0D';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Reservations") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#E09F25';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Not recommended") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#E95F47';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Completed") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = 'purple';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "New") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#9C9E9F';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Vendor is responding") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = '#54BBBA';
                // }
                // if (document.getElementById('article28Staaaa'+i).textContent === "Need help") {
                //     document.getElementById('article28Staaaa'+i).style.backgroundColor = 'black';
                // }

                if(document.getElementById('article32Sta'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "New") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

        //GET data from securityScoreCard collection
        this.observableSSC = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });
    }
    ///////END SaaS///////



    ///////IaaS///////
    //2 tabs Veolia
    getUserIaaSRequests() {
    //VEOLIA mat-tab -> aria-label=UserReqIaaS (table -> aria-label=User IaaS requests (veolia))
    this.stop1 = this.service.getDemandesIaaSUser().subscribe(actionArray => {
      this.demandesIaaSUser = actionArray.map(item => {

        if(this.homeUserRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;

      }

      });
    });

    this.delay(4000).then(any => {
        this.stop1.unsubscribe();
    });

    this.delay(3000).then(any => {
          for (let i = 0; i < this.demandesIaaSUser.length; i++) {

            if(document.getElementById('globalSta'+i)) {
             //GLOBAL status
                if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('globalSta'+i).textContent === "Empty") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
                if (document.getElementById('globalSta'+i).textContent === "Pending") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
            }

            if(document.getElementById('iaasSta'+i)) {
                //IAAS status
                if (document.getElementById('iaasSta'+i).textContent === "DLA is reviewing") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Reservations") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Not recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('iaasSta'+i).textContent === "New") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Completed") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'purple';
                }
            }
          }
            (window as any).initializeDatatable();
            this.showOverlay = false;
        });

    //GET data from securityScoreCard collection
        this.observableSSC = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });

    //VEOLIA mat-tab -> aria-label=UserReqIaaS (table -> aria-label=Shared IaaS requests)
    this.stop2 = this.service.getDemandesIaaSUserShared().subscribe(actionArray => {
      this.demandesIaaSUserPartagees = actionArray.map(item => {

        if(this.homeUserRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;

      }

      });
    });

    this.delay(4000).then(any => {
        this.stop2.unsubscribe();
    });

    this.delay(3000).then(any => {
          for (let i = 0; i < this.demandesIaaSUserPartagees.length; i++) {

            if(document.getElementById('globalStaa'+i)) {
             //GLOBAL status
                if (document.getElementById('globalStaa'+i).textContent === "Recommended") {
                    document.getElementById('globalStaa'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('globalStaa'+i).textContent === "Reservations") {
                    document.getElementById('globalStaa'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('globalStaa'+i).textContent === "Not recommended") {
                    document.getElementById('globalStaa'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('globalStaa'+i).textContent === "Empty") {
                    document.getElementById('globalStaa'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('globalStaa'+i).textContent === "Vendor is responding") {
                    document.getElementById('globalStaa'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('globalStaa'+i).textContent === "Canceled") {
                    document.getElementById('globalStaa'+i).style.backgroundColor = 'red';
                }
                if (document.getElementById('globalStaa'+i).textContent === "Pending") {
                    document.getElementById('globalStaa'+i).style.backgroundColor = 'red';
                }
            }

            if(document.getElementById('iaasStaa'+i)) {
                //IAAS status
                if (document.getElementById('iaasStaa'+i).textContent === "DLA is reviewing") {
                    document.getElementById('iaasStaa'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('iaasStaa'+i).textContent === "Recommended") {
                    document.getElementById('iaasStaa'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('iaasStaa'+i).textContent === "Reservations") {
                    document.getElementById('iaasStaa'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('iaasStaa'+i).textContent === "Not recommended") {
                    document.getElementById('iaasStaa'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('iaasStaa'+i).textContent === "New") {
                    document.getElementById('iaasStaa'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('iaasStaa'+i).textContent === "Vendor is responding") {
                    document.getElementById('iaasStaa'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('iaasStaa'+i).textContent === "Completed") {
                    document.getElementById('iaasStaa'+i).style.backgroundColor = 'purple';
                }
            }
          }
            (window as any).initializeDatatable();
            this.showOverlay = false;
        });

    //GET data from securityScoreCard collection
        this.observableSSC2 = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });

    }

    //1 tab veolia
    getUserBuIaaSRequests(){
    //mat-tab -> aria-label=UserBuIaaSReq (table -> aria-label=All IaaS requests from my BU)
        this.stop1 = this.service.getDemandesIaaSBuUser(this.userBu).subscribe(actionArray => {
          this.demandesIaaSUserBu = actionArray.map(item => {
            //console.log("DOC ID " + item.payload.doc.id + " EMAIL INITIATOR " + item.payload.doc.get('emailInitiatorVeolia'));

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as Demande;

          }

          });
        });

        this.delay(4000).then(any => {
            this.stop1.unsubscribe();
        });
        //this.ngOnDestroy();
        this.delay(3000).then(any => {
          for (let i = 0; i < this.demandesIaaSUserBu.length; i++) {

            if(document.getElementById('globalSta'+i)) {
             //GLOBAL status
                if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('globalSta'+i).textContent === "Empty") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
                if (document.getElementById('globalSta'+i).textContent === "Pending") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
            }

            if(document.getElementById('iaasSta'+i)) {
                //IAAS status
                if (document.getElementById('iaasSta'+i).textContent === "DLA is reviewing") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Reservations") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Not recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('iaasSta'+i).textContent === "New") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Completed") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'purple';
                }
            }
          }
            (window as any).initializeDatatable();
            this.showOverlay = false;
        });

        //GET data from securityScoreCard collection
        this.observableSSC = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });
    }

    //1 tab veolia
    getUserOtherBuIaaSRequests() {
    //mat-tab -> aria-label=UserOtherBuIaaSReq (table -> aria-label=All other IaaS requests from other BUs)
        this.stop1 = this.service.getDemandesIaaSOtherBuUser(this.userBu).subscribe(actionArray => {
          this.demandesIaaSUserOtherBu = actionArray.map(item => {
            //console.log("DOC ID " + item.payload.doc.id + " EMAIL INITIATOR " + item.payload.doc.get('emailInitiatorVeolia'));

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as Demande;

          }

          });
        });

        this.delay(4000).then(any => {
            this.stop1.unsubscribe();
        });
        //this.ngOnDestroy();
        this.delay(3000).then(any => {
          for (let i = 0; i < this.demandesIaaSUserOtherBu.length; i++) {

            if(document.getElementById('globalSta'+i)) {
             //GLOBAL status
                if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('globalSta'+i).textContent === "Empty") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
                if (document.getElementById('globalSta'+i).textContent === "Pending") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
            }

            if(document.getElementById('iaasSta'+i)) {
                //IAAS status
                if (document.getElementById('iaasSta'+i).textContent === "DLA is reviewing") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Reservations") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Not recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('iaasSta'+i).textContent === "New") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Completed") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'purple';
                }
            }
          }
            (window as any).initializeDatatable();
            this.showOverlay = false;
        });

        //GET data from securityScoreCard collection
        this.observableSSC = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.homeUserRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });
    }
    ///////END IaaS///////


  /////////FORMULAIRES/////////

  //Formulaire DESIGN
  gotoDesignForm(id: string) {
    this.service.initializeDesignForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire LEGAL
  gotoLegalForm(id: string) {
    this.service.initializeLegalForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 32
  gotoArticle32Form(id: string) {
    this.service.initializeArticle32Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 28
  gotoArticle28Form(id: string) {
    this.service.initializeArticle28Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //IaaS
  gotoIaaSForm(id: string) {
    this.service.initializeIaaSForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //demande d'évaluation avortée
//   abortSolution(id: string) {
//     this.service.avort(id);
//   }

  //Demande séléctionée dans le tableau pour afficher ses infos dans une autre pages avec redirection des données
  modifierDemande(id: string, type: string) {
    this.router.navigate(['/editDemandeDla/' + id + '/' + type]); //{ this.router.navigate(['/details/'+ item.payload.doc.id]);}
  }

  //redirection vers la page de création du compte pour le vendor
  creationCompteVendor(id: string, type: string) {
    this.router.navigate(['/gestionVendor/' + id + '/' + type]);
  }

  //redirection vers la page des logs pour la demande
  logs(id: string, type: string) {
    this.router.navigate(['/logs/' + id + '/' + type]);
  }

  //redirection sur le tableau de synthese des forms avec l'éval actuelle par form
  tableauEvals(id: string, type: string) {
    this.router.navigate(['/tableauEvals/' + id + '/' + type]);
  }


   async delay(ms: number) {
      await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }


//   testAcunetix() {


//      child_process.execFile('sh test.sh',
//         (error, stdout, stderr) => {
//             console.log(stdout);
//             console.log(stderr);
//             if (error !=== null) {
//                 console.log(`exec error: ${error}`);
//             }
//         });
//   }

    //ajout d'un champ pour tous les doc d'une collection
    // async addFieldFirestoreSolutions() {

    //     const collection = await firebase.firestore().collection('solutions').get()
    //     console.log("DOCS:",collection.docs.map(doc => doc.data()));
    //     collection.forEach(doc => {
    //       doc.ref.set({
    //         emailEvaluatorAuthorized: [null]
    //       }, { merge: true })
    //       .then(res => {
    //         console.log('OK', doc.id);
    //       }).catch(err => {
    //           console.log('Error service creating request', err);
    //         });

    //     })
    // }

    openDialog(demandId: string) {
        const dialogRef = this.dialog.open(DemandOverviewModalComponent, { data: { demandId }});

        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
    }
    
    isDate(value: any): boolean {
        const date = new Date(value);
        return !isNaN(date.getTime());
    }

}
