<div class="container">
  <div *ngIf="(authService.user | async) as user">
  <mat-card>

    <mat-card-content>
      <h3 translate>Modifier la demande/solution</h3>
      <br />
    
    <!--SaaS-->
    <section *ngIf="typeSol === 'SaaS'">
      <form
        class="edit-form"
        *ngIf="editFormSaaS"
        [formGroup]="editFormSaaS"
        novalidate
        (ngSubmit)="onSubmitSaaS(editFormSaaS.value)"
      >
        
        <mat-card-title id="titre_form" translate>Informations primaires</mat-card-title>
        
        <!--Si pas admin-->
        <div *ngIf="authService.isViewer(user) && !authService.isAdmin(user)" style="display: contents">
          
        <mat-form-field>
          <mat-label translate>full Name initiator</mat-label>
          <input
            matInput
            formControlName="fullNameInitiatorVeolia"
            readonly
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label translate>email initiator</mat-label>
          <input
            matInput
            formControlName="emailInitiatorVeolia"
            readonly
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label translate>actual So</mat-label>
          <input
            matInput
            formControlName="solutionOwner"
            readonly
          />
        </mat-form-field>
        
        <mat-form-field style="display: none">
              <input
                matInput
                placeholder="{{'identifiant' |translate}}"
                formControlName="uidInitiatorVeolia"
              />
            </mat-form-field>
        
        <mat-form-field>
          <mat-label translate>bu</mat-label>
              <input
                matInput
                formControlName="businessUnitOfSolution"
                readonly
              />
            </mat-form-field>
        </div>
        
        <!--Si admin-->
        <div *ngIf="authService.isAdmin(user)" style="display: contents">
        <mat-form-field appearance="outline">
          <mat-label translate>full Name initiator</mat-label>
          <input
            matInput
            placeholder="{{'full Name initiator' |translate}}"
            formControlName="fullNameInitiatorVeolia"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label translate>email initiator</mat-label>
          <input
            matInput
            placeholder="{{'email initiator' |translate}}"
            formControlName="emailInitiatorVeolia"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label translate>actual So</mat-label>
          <input
            matInput
            placeholder="{{'actual So' |translate}}"
            formControlName="solutionOwner"
          />
        </mat-form-field>
        
        <mat-form-field style="display: none">
              <input
                matInput
                placeholder="{{'identifiant' |translate}}"
                formControlName="uidInitiatorVeolia"
              />
            </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label translate>bu</mat-label>
              <input
                matInput
                placeholder="{{'bu' |translate}}"
                formControlName="businessUnitOfSolution"
              />
            </mat-form-field>
        </div>
        
        <br />
        <br />

          <mat-card-title id="titre_form" translate>Informations sur la demande</mat-card-title>
          <!-------------------------------Infos demande---------------------------------------->
              <mat-form-field>
              <mat-label translate>Date de demande</mat-label>
              <input
                matInput
                placeholder="{{'Date de demande' |translate}}"
                formControlName="requestDate"
                readonly
              />
            </mat-form-field>

            
            <!--section principale -->
            <mat-card-title id="titre_form2" translate>Main details</mat-card-title>
            
            <!--Ne peut être changé-->
              <mat-label translate class="radio-title">Solution type</mat-label>
              <em style="color: red; text-align: left;"> Cannot be changed, if so, create a new request and ask to abort this one</em>
              <mat-radio-group class="radio-group readonly" formControlName="type">
                <mat-radio-button class="radio-button" value="SaaS">SaaS</mat-radio-button>
                <mat-radio-button class="radio-button" value="PaaS">PaaS</mat-radio-button>
                <mat-radio-button class="radio-button" value="IaaS">IaaS</mat-radio-button>
                <mat-radio-button class="radio-button" value="Apps">Apps</mat-radio-button>
              </mat-radio-group>
              
              <h4 translate style="color: #00aec7">Step1</h4>
              <!--Si pas admin-->
              <mat-form-field *ngIf="authService.isViewer(user) && !authService.isAdmin(user)">
                <mat-label translate>Nom du RFP</mat-label>
                <input matInput placeholder="{{'Nom du RFP' | translate}}" formControlName="rfpName" readonly/>
              </mat-form-field>
              
              <!--Si admin-->
              <mat-form-field *ngIf="authService.isAdmin(user)" appearance="outline">
                <mat-label translate>Nom du RFP</mat-label>
                <input matInput placeholder="{{'Nom du RFP' | translate}}" formControlName="rfpName" />
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                    <mat-label translate>Domain</mat-label>
                    <mat-select
                      formControlName="domainOfSolution"
                      
                    >
                      <mat-option selected value="{{this.item.domainOfSolution}}">{{this.item.domainOfSolution}}</mat-option>
                      <mat-option *ngFor="let domain of listDomains" value="{{ domain.domainName }}" translate> {{ domain.domainName }} </mat-option>
                    </mat-select>
                    <span
                      class="text-danger"
                      *ngIf="(f.domainOfSolution.touched || submitted) && f.domainOfSolution.errors?.required"
                    >
                      <em style="font-size: small;">Domain required</em>
                    </span>
                    <div style="text-align: left">
                    <span><em>Info: DOMAIN -> Business capability</em></span>
                    <br>
                    <span><a target="_blank" rel="noopener" href="https://docs.google.com/spreadsheets/d/1GmhGkvDij4reoV0GL9r3PJLEJutSOi4iovac-L9b71o/edit#gid=1430216237">Business Capabilities definitions</a></span>
                  </div>
                  </mat-form-field>
            
              <mat-form-field appearance="outline">
                <mat-label translate>Pouvez-vous décrire le besoin ?</mat-label>
              <textarea
                matInput
                placeholder="{{'Pouvez-vous décrire le besoin ?' | translate}}"
                formControlName="description"
                rows="2"
              >
              </textarea>
              <span class="text-danger" *ngIf="(f.description.touched || submitted) && f.description.errors?.required">
                <em style="font-size: small;">Description required</em>
              </span>
            </mat-form-field>

              <mat-label translate class="radio-title">Who will access the solution ?</mat-label>
                <span style="display: flex;" class="text-danger" *ngIf="(f.accessQuestion.touched || submitted) && f.accessQuestion.errors?.required">
                  <em style="font-size: small;">Answer required</em>
                </span>
              <mat-selection-list formControlName="accessQuestion" class="checkbox-group" required>
              <mat-list-option checkboxPosition="before" class="font-size-checkbox" *ngFor="let val of accessQuestion" [value]="val">
                {{val}}
              </mat-list-option>
              </mat-selection-list>
      
              <br />
              
              <mat-label translate class="radio-title">Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?</mat-label>
              <mat-radio-group class="radio-group" formControlName="integrateQuestion">
                <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
              <span class="text-danger" *ngIf="(f.integrateQuestion.touched || submitted) && f.integrateQuestion.errors?.required">
                <em style="font-size: small;">One value required</em>
              </span>
              </mat-radio-group>
                    
              <br />
                    
                    <mat-label translate class="radio-title">Do you know if the solution will have to interconnect with 3rd party applications ?</mat-label>
                      <span style="display: flex;" class="text-danger" *ngIf="(f.interconnectQuestion.touched || submitted) && f.interconnectQuestion.errors?.required">
                        <em style="font-size: small;">Answer required</em>
                      </span>
                    <mat-selection-list formControlName="interconnectQuestion" class="checkbox-group" required>
                    <mat-list-option checkboxPosition="before" class="font-size-checkbox" *ngFor="let val of interconnectQuestion" [value]="val">
                      {{val}}
                    </mat-list-option>
                    </mat-selection-list>
            
                    <br />
                    
                    <mat-form-field appearance="outline">
                    <mat-label translate>Please give more details about interconnection with 3rd party applications</mat-label>
                    <input matInput placeholder="{{'Please give more details about interconnection with 3rd party applications' | translate}}" formControlName="interconnectQuestion2" />
                    <span
                        class="text-danger"
                        *ngIf="(f.interconnectQuestion2.touched || submitted) && f.interconnectQuestion2.errors?.required"
                      >
                        <em style="font-size: small;">Answer required</em>
                      </span>
                    </mat-form-field>
          
                    <mat-label translate class="radio-title">Communication with 3rd parties</mat-label>
                    <span style="display: flex;" class="text-danger" *ngIf="(f.communicationQuestion.touched || submitted) && f.communicationQuestion.errors?.required">
                        <em style="font-size: small;">Answer required</em>
                    </span>
                    <mat-selection-list formControlName="communicationQuestion" class="checkbox-group">
                    <mat-list-option checkboxPosition="before" class="font-size-checkbox" *ngFor="let val of communicationQuestion" [value]="val">
                      {{val}}
                    </mat-list-option>
                    </mat-selection-list>
                    
                    <br />
              
              <mat-form-field appearance="outline">
                <mat-label translate>Date completion attendue</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker3"
                  placeholder="{{'Date completion attendue' | translate}}"
                  formControlName="expectedCompletionDate"
                  
                  id="completionDate"
                />
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
                <span
                  class="text-danger"
                  *ngIf="(f.expectedCompletionDate.touched || submitted || conflictDate === true) && f.expectedCompletionDate.errors?.required"
                >
                  <em style="font-size: small;">Date required</em>
                </span>
                <br />
                <span class="text-danger" *ngIf="conflictDate === true">
                  <em style="font-size: small;">Expected completion date should be earlier than expected evaluation date</em>
                </span>
              </mat-form-field>
    
              <mat-form-field appearance="outline">
                <mat-label translate>Date eval attendue</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  placeholder="{{'Date eval attendue' | translate}}"
                  formControlName="expectedEvaluationDate"
                  
                  id="evaluationDate"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <span
                  class="text-danger"
                  *ngIf="(f.expectedEvaluationDate.touched || submitted || conflictDate === true) && f.expectedEvaluationDate.errors?.required"
                >
                  <em style="font-size: small;">Date required</em>
                </span>
                <br />
                <span class="text-danger" *ngIf="conflictDate === true">
                  <em style="font-size: small;">Expected completion date should be earlier than expected evaluation date</em>
                </span>
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label translate>expectedOnlineReleaseDate</mat-label>
                <input
                  matInput
                  [matDatepicker]="picker2"
                  placeholder="{{'expectedOnlineReleaseDate' | translate}}"
                  formControlName="expectedOnlineReleaseDate"
                  
                />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <span class="text-danger" *ngIf="(f.expectedOnlineReleaseDate.touched || submitted) && f.expectedOnlineReleaseDate.errors?.required">
                  <em style="font-size: small;">Date required</em>
                </span>
              </mat-form-field>
              
              <mat-label translate class="radio-title">Nombre de solution à évaluer</mat-label>
              <mat-form-field appearance="outline">
                <input style="width: 65px"
                  matInput
                  formControlName="nbSolution"
                  readonly
                />
              </mat-form-field>  
              <br />
              <br />
            
            
            <mat-card>
              <mat-card-content>
              
              <div class="text-center border border-light p-5">
                <h4 translate style="color: #00aec7">Step2</h4>
                <mat-card-title id="titre_form2">{{'The solution' | translate}}</mat-card-title>
                
                  <mat-form-field style="display: block" appearance="outline">
                    <mat-label translate>Nom de la solution à évaluer</mat-label>
                    <input
                      matInput
                      placeholder="{{'Nom de la solution à évaluer' |translate}}"
                      formControlName="solutionName"
                      id="solution"
                      
                    />
                    <span class="text-danger" *ngIf="(f.solutionName.touched || submitted) && f.solutionName.errors?.required">
                      <em style="font-size: small;">Solution name required</em>
                    </span>
                  </mat-form-field>
                  
                  <mat-form-field style="display: block" appearance="outline">
                    <mat-label translate>Nom editeur</mat-label>
                    <input
                      matInput
                      placeholder="{{'Nom editeur' |translate}}"
                      formControlName="editorBrand"
                      
                    />
                    <span class="text-danger" *ngIf="(f.editorBrand.touched || submitted) && f.editorBrand.errors?.required">
                      <em style="font-size: small;">Editor brand required</em>
                    </span>
                  </mat-form-field>
        
                  <mat-label translate class="radio-title">Solution is already in use / DLA renewal ?</mat-label>
                  <mat-radio-group class="radio-group" formControlName="inPlace">
                    <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                    <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
                    <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
                    <span class="text-danger" *ngIf="(f.inPlace.touched || submitted) && f.inPlace.errors?.required">
                      <em style="font-size: small;">One value required</em>
                    </span>
                  </mat-radio-group>
                  
                  <mat-form-field style="display: block" appearance="outline">
                    <mat-label translate>If the solution is already in use, please provide its APM number (if available)</mat-label>
                    <input matInput 
                    placeholder="{{'If the solution is already in use, please provide its APM number (if available)' | translate}}" 
                    formControlName="apmNumber" 
                     />
                    <span><a target="_blank" rel="noopener" href="https://oneintranet.veolia.com/corp-ist-norms/architecture/apm">What is APM ?</a></span>
                  </mat-form-field>
                  
                  <mat-form-field style="display: block" appearance="outline">
                    <mat-label translate>Lien app</mat-label>
                    <input
                      matInput
                      placeholder="{{'Lien app' | translate}}"
                      formControlName="link"
                    />
                  </mat-form-field>

                <br />
              
                <mat-card-title id="titre_form2" translate>{{'Main contact' | translate}}</mat-card-title>
                
                 <mat-form-field style="display: block" appearance="outline">
                   <mat-label translate>Email editeur</mat-label>
                  <input
                    email="true"
                    matInput
                    placeholder="{{'Email editeur' |translate}}"
                    formControlName="mainEmailVendor"
                    readonly
                  />
                </mat-form-field>
      
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>nom</mat-label>
                  <input
                    matInput
                    placeholder="{{'nom' |translate}}"
                    id="nomVendor"
                    formControlName="mainLastnameVendor"
                    
                  />
                  <span
                    class="text-danger"
                    *ngIf="(f.mainLastnameVendor.touched || submitted) && f.mainLastnameVendor.errors?.required"
                  >
                    <em style="font-size: small;">Vendor lastname required</em>
                  </span>
                </mat-form-field>
      
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>prenom</mat-label>
                  <input
                    matInput
                    placeholder="{{'prenom' |translate}}"
                    formControlName="mainFirstnameVendor"
                    
                    
                  />
                  <span
                    class="text-danger"
                    *ngIf="(f.mainFirstnameVendor.touched || submitted) && f.mainFirstnameVendor.errors?.required"
                  >
                    <em style="font-size: small;">Vendor firstname required</em>
                  </span>
                </mat-form-field>
      
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>entreprise</mat-label>
                  <input
                    matInput
                    placeholder="{{'entreprise' |translate}}"
                    id="entrepriseVendor"
                    formControlName="mainCompanyVendor"
                    
                  />
                  <span
                    class="text-danger"
                    *ngIf="(f.mainCompanyVendor.touched || submitted) && f.mainCompanyVendor.errors?.required"
                  >
                    <em style="font-size: small;">Vendor company required</em>
                  </span>
                </mat-form-field>
                
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>Lien app commercial</mat-label>
                  <input
                    matInput
                    placeholder="{{'Lien app commercial' |translate}}"
                    formControlName="linkCom"
                    
                  />
                  
                </mat-form-field>
              </div>
              
              </mat-card-content>
            </mat-card>

          <br />
          <br />
          
        <div class="submit-button-container">
            <button
              mat-raised-button
              class="submit-button"
              color="primary"
              type="submit"
              style="background:#00AEC7; "
            >
              {{ 'Save' | translate }}
            </button>
        </div>
      </form>
      
      <div style="text-align: center">
      <!-- redirection sur dashboard user -->
      <button *ngIf="!authService.isAdmin(user)"
        mat-raised-button
        color="primary"
        translate
        routerLink="/homeUser"
        routerLinkActive="active"
        style="margin-top: 5px; background:#00AEC7; color:white"
      >
        {{ 'Retour à mes demandes' | translate }}
      </button>
      
      <button *ngIf="authService.isAdmin(user)"
        mat-raised-button
        color="primary"
        translate
        routerLink="/admin"
        routerLinkActive="active"
        style="margin-top: 5px; background:#00AEC7; color:white"
      >
        {{ 'Retour à mes demandes' | translate }}
      </button>
      </div>
    
    </section>
    <!--END SaaS-->
    
    <!--IaaS-->
    <section *ngIf="typeSol === 'IaaS'">
      <form
        class="edit-form"
        *ngIf="editFormIaaS"
        [formGroup]="editFormIaaS"
        novalidate
        (ngSubmit)="onSubmitIaaS(editFormIaaS.value)"
      >
          <mat-card-title id="titre_form" translate>Informations primaires</mat-card-title>
        
        <!--Si pas admin-->
        <div *ngIf="authService.isViewer(user) && !authService.isAdmin(user)" style="display: contents">
        <mat-form-field appearance="outline">
          <mat-label translate>full Name initiator</mat-label>
          <input
            matInput
            placeholder="{{'full Name initiator' |translate}}"
            formControlName="fullNameInitiatorVeolia"
            readonly
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label translate>email initiator</mat-label>
          <input
            matInput
            placeholder="{{'email initiator' |translate}}"
            formControlName="emailInitiatorVeolia"
            readonly
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label translate>actual So</mat-label>
          <input
            matInput
            placeholder="{{'actual So' |translate}}"
            formControlName="solutionOwner"
            readonly
          />
        </mat-form-field>
        
        <mat-form-field style="display: none">
              <input
                matInput
                placeholder="{{'identifiant' |translate}}"
                formControlName="uidInitiatorVeolia"
              />
            </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label translate>bu</mat-label>
              <input
                matInput
                placeholder="{{'bu' |translate}}"
                formControlName="businessUnitOfSolution"
                readonly
              />
            </mat-form-field>
        </div>
        
        <!--Si admin-->
        <div *ngIf="authService.isAdmin(user)" style="display: contents">
        <mat-form-field appearance="outline">
          <mat-label translate>full Name initiator</mat-label>
          <input
            matInput
            placeholder="{{'full Name initiator' |translate}}"
            formControlName="fullNameInitiatorVeolia"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label translate>email initiator</mat-label>
          <input
            matInput
            placeholder="{{'email initiator' |translate}}"
            formControlName="emailInitiatorVeolia"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label translate>actual So</mat-label>
          <input
            matInput
            placeholder="{{'actual So' |translate}}"
            formControlName="solutionOwner"
          />
        </mat-form-field>
        
        <mat-form-field style="display: none">
              <input
                matInput
                placeholder="{{'identifiant' |translate}}"
                formControlName="uidInitiatorVeolia"
              />
            </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label translate>bu</mat-label>
              <input
                matInput
                placeholder="{{'bu' |translate}}"
                formControlName="businessUnitOfSolution"
              />
            </mat-form-field>
        </div>
        
        <br />
        <br />

          <mat-card-title id="titre_form" translate>Informations sur la demande</mat-card-title>
          <!-------------------------------Infos demande---------------------------------------->
              <mat-form-field appearance="outline">
                <mat-label translate>Date de demande</mat-label>
              <input
                matInput
                placeholder="{{'Date de demande' |translate}}"
                formControlName="requestDate"
                readonly
              />
            </mat-form-field>
          
          <!--section principale -->
            <mat-card-title id="titre_form2" translate>Main details</mat-card-title>
            
            <!--Ne peut être changé-->
              <mat-label translate class="radio-title">Solution type</mat-label>
              <em style="color: red; text-align: left;"> Cannot be changed, if so, create a new request and ask to abort this one</em>
              <mat-radio-group class="radio-group readonly" formControlName="type">
                <mat-radio-button class="radio-button" value="SaaS">SaaS</mat-radio-button>
                <mat-radio-button class="radio-button" value="PaaS">PaaS</mat-radio-button>
                <mat-radio-button class="radio-button" value="IaaS">IaaS</mat-radio-button>
                <mat-radio-button class="radio-button" value="Apps">Apps</mat-radio-button>
              </mat-radio-group>
              
                  <mat-label translate class="radio-title">q0a_iaas</mat-label>
                  <mat-radio-group class="radio-group" formControlName="q0a_iaas">
                    <mat-radio-button class="radio-button" value="Yes">Yes</mat-radio-button>
                    <mat-radio-button class="radio-button" value="No">No</mat-radio-button>
                    <span class="text-danger" *ngIf="(f1.q0a_iaas.touched || submitted) && f1.q0a_iaas.errors?.required">
                      <em style="font-size: small;">One value required</em>
                    </span>
                </mat-radio-group>
                
                  <mat-label translate class="radio-title">q0b_iaas</mat-label>
                  <mat-radio-group class="radio-group" formControlName="q0b_iaas">
                    <mat-radio-button class="radio-button" value="Yes">Yes</mat-radio-button>
                    <mat-radio-button class="radio-button" value="No">No</mat-radio-button>
                    <span class="text-danger" *ngIf="(f1.q0b_iaas.touched || submitted) && f1.q0b_iaas.errors?.required">
                      <em style="font-size: small;">One value required</em>
                    </span>
                </mat-radio-group>
                
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>Nom de la solution à évaluer</mat-label>
                  <input
                    matInput
                    placeholder="{{'Nom de la solution à évaluer' |translate}}"
                    formControlName="solutionName"
                    id="solution"
                    
                  />
                  <span class="text-danger" *ngIf="(f1.solutionName.touched || submitted) && f1.solutionName.errors?.required">
                    <em style="font-size: small;">Solution name required</em>
                  </span>
                </mat-form-field>
                        
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>Nom editeur</mat-label>
                  <input
                    matInput
                    placeholder="{{'Nom editeur' |translate}}"
                    formControlName="editorBrand"
                  />
                  <span class="text-danger" *ngIf="(f1.editorBrand.touched || submitted) && f1.editorBrand.errors?.required">
                    <em style="font-size: small;">Vendor brand required</em>
                  </span>
                </mat-form-field>
                        
                <mat-form-field appearance="outline">
                  <mat-label translate>Pouvez-vous décrire le besoin ?</mat-label>
                    <textarea
                      matInput
                      placeholder="{{'Pouvez-vous décrire le besoin ?' | translate}}"
                      formControlName="description"
                      rows="2"
                    >
                    </textarea>
                    <span class="text-danger" *ngIf="(f1.description.touched || submitted) && f1.description.errors?.required">
                      <em style="font-size: small;">Description required</em>
                    </span>
                </mat-form-field>
      
                <mat-form-field appearance="outline">
                    <mat-label translate>Domain</mat-label>
                    <mat-select
                      formControlName="domainOfSolution"
                    >
                      <mat-option selected value="{{this.item.domainOfSolution}}">{{this.item.domainOfSolution}}</mat-option>
                      <mat-option *ngFor="let domain of listDomains" value="{{ domain.domainName }}" translate> {{ domain.domainName }} </mat-option>
                    </mat-select>
                    <span
                      class="text-danger"
                      *ngIf="(f1.domainOfSolution.touched || submitted) && f1.domainOfSolution.errors?.required"
                    >
                      <em style="font-size: small;">Domain required</em>
                    </span>
                    <div style="text-align: left">
                    <span><em>Info: DOMAIN -> Business capability</em></span>
                    <br>
                    <span><a target="_blank" rel="noopener" href="https://docs.google.com/spreadsheets/d/1GmhGkvDij4reoV0GL9r3PJLEJutSOi4iovac-L9b71o/edit#gid=1430216237">Business Capabilities definitions</a></span>
                  </div>
                  </mat-form-field>
                
                <mat-label translate class="radio-title">Solution is already in use / DLA renewal ?</mat-label>
                        <mat-radio-group class="radio-group" formControlName="inPlace">
                          <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
                          <span class="text-danger" *ngIf="(f1.inPlace.touched || submitted) && f1.inPlace.errors?.required">
                            <em style="font-size: small;">One value required</em>
                          </span>
                        </mat-radio-group>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>If the solution is already in use, please provide its APM number (if available)'</mat-label>
                          <input matInput 
                          placeholder="{{'If the solution is already in use, please provide its APM number (if available)' | translate}}" 
                          formControlName="apmNumber" 
                           />
                          <span><a target="_blank" rel="noopener" href="https://oneintranet.veolia.com/corp-ist-norms/architecture/apm">What is APM ?</a></span>
                        </mat-form-field>
                        
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Vendor website url (if it exists)</mat-label>
                          <input
                            matInput
                            placeholder="{{'Vendor website url (if it exists)' | translate}}"
                            formControlName="link"
                          />
                        </mat-form-field>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Additionnal comments</mat-label>
                          <input
                            matInput
                            placeholder="{{'Additionnal comments' | translate}}"
                            formControlName="comment"
                          />
                        </mat-form-field>

          <br />
          <br />
          
        <div class="submit-button-container">
            <button
              mat-raised-button
              class="submit-button"
              color="primary"
              type="submit"
              style="background:#00AEC7; "
            >
              {{ 'Save' | translate }}
            </button>
        </div>
      </form>
      
      <div style="text-align: center">
      <!-- redirection sur dashboard user -->
      <button *ngIf="!authService.isAdmin(user)"
        mat-raised-button
        color="primary"
        translate
        routerLink="/homeUser"
        routerLinkActive="active"
        style="margin-top: 5px; background:#00AEC7; color:white"
      >
        {{ 'Retour à mes demandes' | translate }}
      </button>
      
      <button *ngIf="authService.isAdmin(user)"
        mat-raised-button
        color="primary"
        translate
        routerLink="/admin"
        routerLinkActive="active"
        style="margin-top: 5px; background:#00AEC7; color:white"
      >
        {{ 'Retour à mes demandes' | translate }}
      </button>
      </div>
    
    </section>
    <!--END IaaS-->
    
    <!--PaaS-->
    <section *ngIf="typeSol === 'PaaS'">
    </section>
    <!--END PaaS-->
    
    <!--Apps-->
    <section *ngIf="typeSol === 'Apps'">
    </section>
    <!--END Apps-->
    
    </mat-card-content>
  </mat-card>
</div>
</div>
