import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

import { Evaluation } from '../homeUser/formulairesAccess/formulaireAll/evaluation.model';
import {FormulaireAllService} from '../homeUser/formulairesAccess/formulaireAll/formulaireAll.service';
import { DesignFormService } from 'app/services/forms/design-form.service';
import { DesignForm, LegalForm, Article32Form } from 'app/types/forms.type';
import { LegalFormService } from 'app/services/forms/legal-form.service';
import { Article32FormService } from 'app/services/forms/article-32-form.service';
import { Demand } from 'app/types/demand.type';
import { DemandService } from 'app/services/demand.service';

export interface DialogData {
  demandId: string;
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'demand-overview-modal',
  templateUrl: 'demand-overview-modal.component.html',
  styleUrls: ['demand-overview-modal.component.scss']
})
export class DemandOverviewModalComponent {
    demand: Demand = undefined;
    currentEvaluation: Evaluation[];
    businessUnitOfSolution: string;
    download: boolean;
    designForm: DesignForm;
    legalForm: LegalForm;
    article32Form: Article32Form;
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public demandService: DemandService,
        public designFormService: DesignFormService,
        public legalFormService: LegalFormService,
        public article32FormSerice: Article32FormService,
        public service: FormulaireAllService,
        private toastr: ToastrService
    ) {}
    
    ngOnInit() {
        this.loadDemandData(this.data.demandId);
    }
    
    public loadDemandData(id: string)  {
        const data = this.demandService.getDemand(id);
        data.subscribe((snapshot: { data: () => any; }) => {
            const page = snapshot.data();
            
            if (page) {
                this.demand = page as Demand;
                this.loadEvaluation(id);
                this.loadDesignForm(id);
                this.loadArticle32Form(id);
                this.loadLegalForm(id);
            }
            
        })
    }
    
    loadEvaluation(demandId: string) {
        this.service
      .getEvaluation(demandId + '-' + 'Design Form')
      .snapshotChanges()
      .subscribe(ok => {
          ok.forEach(element => {
          const x = element.payload.toJSON();
          x['$key'] = element.key;
          this.currentEvaluation.push(x as Evaluation);
          });
      });
    }
    
    loadArticle32Form(demandId: string) {
        const data = this.article32FormSerice.getForm(demandId);
        
        data.subscribe(ok => {
            if (ok.data()) {
                this.article32Form = ok.data() as Article32Form;
            }
        });
    }
    
    loadDesignForm(demandId: string) {
        const data = this.designFormService.getForm(demandId);
        
        data.subscribe(ok => {
            if (ok.data()) {
                this.designForm = ok.data() as DesignForm;
            }
        });
    }
    
    loadLegalForm(demandId: string) {
        const data = this.legalFormService.getForm(demandId);
        
        data.subscribe(ok => {
            if (ok.data()) {
                this.legalForm = ok.data() as LegalForm;
            }
        });
    }
    
    async delay(ms: number) {
        await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
    }
    
    generatePdf() {
        this.toastr.success('Download in progress...', '', {
          timeOut: 4000,
          progressBar: true,
          closeButton: true,
          positionClass: 'toast-bottom-center'
        });
    
        this.download = true;
      
        this.delay(1500).then(any => { 
            let fullEval = document.getElementById('fullSolInfoForPdf').innerHTML; //tous les chapitres et sous chapitres + status/comment eval final
      
            let finalVar = htmlToPdfmake(fullEval);
          
            let documentDefinition = { 
                footer: function (pagenumber, pagecount) {
                  return {
                      alignment: 'right', margin: [0, 10, 10, 0],
                      text: pagenumber + '/' + pagecount
                  };
                },
                content: finalVar 
            };
          
            pdfMake.createPdf(documentDefinition).download('Overview-' + this.demand.solutionName + '.pdf');
              
            this.download = false;
        });
  }
  
    isDate(date) {
      try {
        let dateObj = new Date(date);
        if (dateObj.toDateString() !== 'Invalid Date') {
            return false;
        }
        return true;
      } catch {
        return false;
      }
   }
   
   isString(obj) {
      return typeof obj === "string"
   }
  
   convertDate(timestamp) {
        if (timestamp) {
            let date = new Date(
                 timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000,
            )
            return date;
        } else {
            console.log('Pas de date pour le champ');
        }
        
        return undefined;
  }
}