<header class="top-header"></header>

  <!-- hamburger dans le shell -->
  <!-- <div flexXs fxLayoutAlign="end center"> -->
  <!-- <button class="menu-button" mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button> -->
  <!-- <span class="title">{{ pageTitle }}</span> -->
  <!-- </div> -->

<!---------------------CONNECTED------------------------>
<div *ngIf="(auth.user | async) as user">

  <mat-toolbar class="mat-elevation-z6 navbar" color="primary" style="background: #00AEC7; margin-bottom: 0px;">

<!-- Partie LEFT -->
  <div class="brand-left" id="logo">
      <span class="sr-only">Veolia logo</span> <img alt="veolia logo" routerLink="/homeUser" src="/assets/logos/new_logo_veolia.png" style="width: 170px; cursor: pointer" id="img-veolia"/>
  </div>
  <h1 style="position: relative; right: 220px;">Design & Legal Assessment as a Service</h1>
  <strong *ngIf="auth.isVeolia(user)" class="ver"><a target="_blank" rel="noopener" href="https://lookerstudio.google.com/u/0/reporting/5a17bdf9-97bc-46aa-adaf-bcadfc31c358/page/v6eMD">Release notes</a></strong>

  <!-- <a class="v-navbar-brand" href="/"> <!-- style /logotype -->
  <!-- </a> -->

<!-- Partie RIGHT -->
  <div>

    <!--VEOLIA-->
      <div *ngIf="auth.isViewer(user) && !isFirstConnectionRoute()" class="veolia-buttons">

        <!-- DASHBOARD ADMIN -->
        <a *ngIf="isAdminRoute() && auth.isAdmin(user)" routerLink="/admin" routerLinkActive="active" class="selected">
          <span class="material-icons">settings_applications</span>
          <span style="font-weight: bold;" translate >{{ 'Administration' | translate }}</span>
        </a>
        <a *ngIf="!isAdminRoute() && auth.isAdmin(user)" title="{{ 'Admin' | translate }}" routerLink="/admin" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">settings_applications</span>
        </a>

        <!-- DASHBOARD USER-->
        <a *ngIf="isHomeUserRoute()" routerLink="/homeUser" routerLinkActive="active" class="selected">
          <span class="material-icons" >home</span>
          <span style="font-weight: bold;" translate>{{ 'Home' | translate }}</span>
        </a>
        <a *ngIf="!isHomeUserRoute()" title="{{ 'Home' | translate }}" routerLink="/homeUser" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">home</span>
        </a>

        <!-- DASHBOARD EVALUATOR-->
        <a *ngIf="isEvaluatorRoute() && auth.isEvaluator(user)" routerLink="/evaluator" routerLinkActive="active" class="selected">
          <span class="material-icons" >gavel</span>
          <span style="font-weight: bold;" translate>{{ 'Evaluator' | translate }}</span>
        </a>
        <a *ngIf="!isEvaluatorRoute() && auth.isEvaluator(user)" title="{{ 'Evaluator' | translate }}" routerLink="/evaluator" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">gavel</span>
        </a>

        <!-- Demande Dla -->
        <a *ngIf="isDemandeDlaRoute() && !auth.isEvaluator(user)" routerLink="/demandeDLA" routerLinkActive="active" class="selected">
          <span class="material-icons">note_add</span>
          <span style="font-weight: bold;" translate>{{ 'Nouvelle demande DLA' | translate }}</span>
        </a>
        <a *ngIf="!isDemandeDlaRoute()" title="{{ 'Nouvelle demande DLA' | translate }}" routerLink="/demandeDLA" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">note_add</span>
        </a>

        <!-- About -->
        <a *ngIf="isAboutRoute()" routerLink="/about" routerLinkActive="active" class="selected">
          <span class="material-icons">security</span>
          <span style="font-weight: bold;" translate>{{ 'About' | translate }}</span>
        </a>
        <a *ngIf="!isAboutRoute()" title="{{ 'About' | translate }}" routerLink="/about" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">security</span>
        </a>

        <!-- Contact -->
        <a *ngIf="isContactRoute()" routerLink="/contact" routerLinkActive="active" class="selected">
          <span class="material-icons">help</span>
          <span style="font-weight: bold;" translate>{{ 'Contact' | translate }}</span>
        </a>
        <a *ngIf="!isContactRoute()" title="{{ 'Contact' | translate }}" routerLink="/contact" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">help</span>
        </a>


      </div>

    <!--VENDOR-->
      <div *ngIf="auth.isVendor(user) && !isFirstConnectionRoute()" class="vendor-buttons">

        <!-- DASHBOARD -->
        <a *ngIf="isHomeUserRoute()" routerLink="/homeUser" routerLinkActive="active" class="selected">
          <span class="material-icons">home</span>
          <span style="font-weight: bold;" translate>{{ 'Home' | translate }}</span>
        </a>
        <a *ngIf="!isHomeUserRoute()" title="{{ 'Home' | translate }}" routerLink="/homeUser" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">home</span>
        </a>

        <!-- About -->
        <a *ngIf="isAboutRoute()" routerLink="/about" routerLinkActive="active" class="selected">
          <span class="material-icons">security</span>
          <span style="font-weight: bold;" translate>{{ 'About' | translate }}</span>
        </a>
        <a *ngIf="!isAboutRoute()" title="{{ 'About' | translate }}" routerLink="/about" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">security</span>
        </a>

        <!-- Contact -->
        <a *ngIf="isContactRoute()" routerLink="/contact" routerLinkActive="active" class="selected">
          <span class="material-icons">help</span>
          <span style="font-weight: bold;" translate>{{ 'Contact' | translate }}</span>
        </a>
        <a *ngIf="!isContactRoute()" title="{{ 'Contact' | translate }}" routerLink="/contact" routerLinkActive="active" class="actionBtns">
          <span class="material-icons">help</span>
        </a>

      </div>


    <!-- DROITE DROITE-->
    <!-- User logged in -->
    <div>
      <!-- VEOLIA -->
      <div *ngIf="auth.isViewer(user)">
        <div class="header-veolia">
          <div class="vertical-line"></div>

          <button mat-icon-button [matMenuTriggerFor]="notifMenu">
            <mat-icon>notifications</mat-icon>
            </button>
          <mat-menu #notifMenu="matMenu">
            <p mat-menu-item>
              No item
            </p>
          </mat-menu>

          <!--<button mat-icon-button [matMenuTriggerFor]="languageMenu">-->
          <!--  <em class="fas fa-globe" style="margin-top: 6px; font-size: 22px;"></em><!--Icone langage-->
          <!--  </button>-->
          <!--<mat-menu #languageMenu="matMenu">-->
          <!--  <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">-->
          <!--    {{ language }}-->
          <!--  </button>-->
          <!--</mat-menu>-->

          <em style="display:none" translate id="roleUser">{{ user?.role }}</em>

          <img
            mat-button
            [matMenuTriggerFor]="options"
            class="img-circle"
            [src]="user.photoURL"
            title="{{'Profile options' | translate}}"
            alt="User photo"
          />
          <mat-menu #options="matMenu">
            <strong id="email-veolia">{{ user.email }}</strong>
            <p id="roleTitle" translate>
              <em translate id="roleUser">{{ user?.role }}</em>
            </p>

            <!-- Profile user -->
            <button *ngIf="!isFirstConnectionRoute()" mat-menu-item routerLink="/profile/{{user.uid}}" routerLinkActive="active">
              <em class="material-icons">account_circle </em> {{ 'My profile' | translate }}
            </button>

            <!-- Admin -->
            <!--<div *ngIf="auth.isAdmin(user)">-->
            <!--  <button mat-menu-item routerLink="/admin" routerLinkActive="active">-->
            <!--    <em class="material-icons">reorder </em> {{ 'Administration' | translate }}-->
            <!--  </button>-->
            <!--</div>-->

            <!-- bouton deconnexion -->
            <button mat-menu-item (click)="auth.signOutVeolia()">
              <em class="material-icons">power_settings_new </em> {{ 'Logout' | translate }}
            </button>
          </mat-menu>

        </div>
      </div>
      <!-- END VEOLIA USER -->

      <!-- VENDOR -->
      <div *ngIf="auth.isVendor(user)">
        <!-- Si le user a le role editor=true -->
        <div class="header-vendor">
          <div class="vertical-line"></div>

          <button mat-icon-button [matMenuTriggerFor]="notifMenu">
            <mat-icon>notifications</mat-icon>
            </button>
          <mat-menu #notifMenu="matMenu">
            <p mat-menu-item>
              No item
            </p>
          </mat-menu>

          <!--<button mat-icon-button [matMenuTriggerFor]="languageMenu">-->
          <!--  <em class="fas fa-globe" style="margin-top: 6px; font-size: 22px;"></em><!--Icone langage-->
          <!--</button>-->
          <!--<mat-menu #languageMenu="matMenu">-->
          <!--  <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">-->
          <!--    {{ language }}-->
          <!--  </button>-->
          <!--</mat-menu>-->

          <em style="display:none" translate id="roleUser">{{ user?.role }}</em>


          <em
            mat-button
            [matMenuTriggerFor]="options"
            class="material-icons img-circle"
            style="cursor: pointer; font-size: xx-large;"
          >
            account_circle
          </em>
          <mat-menu #options="matMenu">
            <p id="email-vendor">{{ user.email }}</p>
            <p id="roleTitle" translate>
              <em translate id="roleUser">{{ user?.role }}</em>
            </p>

            <!-- profile user -->
            <button *ngIf="!isFirstConnectionRoute()" mat-menu-item routerLink="/profile/{{user.uid}}" routerLinkActive="active">
              <em class="material-icons">account_circle </em> {{ 'My profile' | translate }}
            </button>

            <!-- Redirection vers edit Password -->
            <button *ngIf="!isFirstConnectionRoute()" mat-menu-item (click)="goEditPassword(user.uid)">
              <em class="material-icons">perm_identity </em> {{ 'Change password' | translate }}
            </button>

            <!-- bouton deconnexion -->
            <button mat-menu-item (click)="auth.signOutVendor(user.email, user.uid)">
              <em class="material-icons">power_settings_new </em> {{ 'Logout' | translate }}
            </button>
          </mat-menu>


        </div>
      </div>
      <!-- END VENDOR USER -->
    </div>


    </div>
    <!--END RIGHT-->

</mat-toolbar>

</div>
<!---------------------END CONNECTED------------------------>


<!--------------------------NOT CONNECTED------------------>
<div *ngIf="!(auth.user | async) as user">

  <!--modale connexion vendor appelée par home.html -->
      <!-- Modal connexion email/mdp utilisé dans le home.html -->

  <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        onclick="openLogin()"
        aria-hidden="true"
        style="margin-top:60px"
      >
        <div class="modal-dialog" role="document" style="width:10%, height:15%">
          <div class="modal-content">
            <div class="modal-header" id="modalHeader1">
              <h4 style="color:black" translate>{{ 'Vendor sign in' | translate }}</h4>
              <!---->
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onclick="restoreModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" id="bodyModal">
              <!---->
              <em style="color:black; font-size:small">{{ 'info' | translate }}</em>
              <form [formGroup]="signinForm" (ngSubmit)="onSubmitLogin()" style="display: grid" id="loginForm">
                <label for="email">Email</label>
                <input email="true" type="email" class="form-control" formControlName="email" [(ngModel)]="email" />

                <ng-container *ngFor="let validation of validation_messages.email">
                  <mat-error
                    style="font-size: small;"
                    *ngIf="
                      signinForm.get('email').hasError(validation.type) &&
                      (signinForm.get('email').dirty || signinForm.get('email').touched)
                    "
                    >{{ validation.message }}</mat-error
                  >
                </ng-container>

                <br />
                <label for="password"
                  >{{ 'Password' | translate }}<em style="font-size: small;">{{ 'infos2' | translate }}</em>
                  <span class="material-icons" id="seePassword">visibility</span>
                  </label
                >
                <div>
                <input
                  type="password"
                  class="form-control"
                  formControlName="password"
                  [(ngModel)]="password"
                  required
                  minlength="6" maxlength="32" id="pass"
                />

                </div>

                <ng-container *ngFor="let validation of validation_messages.password">
                  <mat-error
                    style="font-size: small;"
                    *ngIf="
                      signinForm.get('password').hasError(validation.type) &&
                      (signinForm.get('password').dirty || signinForm.get('password').touched)
                    "
                    >{{ validation.message }}</mat-error
                  >
                </ng-container>

                <!-- Button ouvrir la modale de mdp oublié appel de la fonction jquery dans index -->
                <a href="" data-toggle="modal" id="closeModalLogin" class="btnMdpOublie" onclick="openForgotMdp()">{{
                  'Mot de passe oublié ?' | translate
                }}</a>

                <button
                  mat-raised-button
                  class="submit-button"
                  color="primary"
                  type="submit"
                  [disabled]="!signinForm.valid"
                  style="margin-top:15px;background-color: rgb(0, 174, 199); "
                >
                  {{ 'Sign in' | translate }}
                </button>
              </form>
            </div>
            <!-- END modal Connexion email/mdp -->

            <!-- Modale mot de passe oublié -->
            <!-- Juste renseignement de l'email du user pour envoyer le mail avec le lien reset-->
            <div class="modal-header" id="modalHeader2" style="display:none">
              <h4 style="color:black" translate>{{ 'Réinitialisation du mdp' | translate }}</h4>
              <!---->
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onclick="restoreModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" id="bodyModal2" style="display:none">
              <div>
        <em style="color:black;">{{ 'infosResetPass' | translate }}</em>
      </div>
      <br />

      <form
        *ngIf="forgotPassForm"
        [formGroup]="forgotPassForm"
        (ngSubmit)="onSubmitForgotPass()"
        class="forgotPassForm-form" id="resetPassForm"
      >

        <mat-card>

          <label for="email"
                    >{{ 'email' | translate }}</label
                  >
                  <input
                    email="true" type="email"
                    class="form-control"
                    formControlName="email"
                    [(ngModel)]="email"
                    required
                  />
                  <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" style="color:red;">
                    <em>Your email does not match with email format</em>
                  </div>

        </mat-card>
        <br />


            <div class="row submit-button-container">
              <div class="col-md-4">
                <button translate mat-raised-button class="submit-button" style="color: white;background: #00aec7;" type="submit">
                  {{ 'OK' | translate }}
                </button>
              </div>
            </div>

      </form>
            </div>
            <!-- END modale mdp oublié -->

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal" onclick="restoreModal()">
                {{ 'Close' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END BIG MODALE -->


    <div class="center" id="logo2">
      <span class="sr-only">Veolia logo</span> <img alt="veolia logo" src="/assets/logos/new_logo_veolia.png" style="width: 200px; height: 70px;" />
  </div>

</div>
<!--------------------------END NOT CONNECTED------------------>


<!--Au clic du OK-->
<div class="my-overlay" *ngIf="showOverlay"><em class="fa fa-spinner fa-spin" aria-hidden="true"></em></div>
