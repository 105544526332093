import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { HomeUserService } from '../homeUser.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/auth.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { FormulaireAllService } from '../formulairesAccess/formulaireAll/formulaireAll.service';
import { Logs } from './logs.model';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';

//pdf
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  //contient les infos en fonction de la date
  logsInfos: Logs[];

  //contient les dates qui ont des logs
  listeDate = [];

  selected = false;

  tabName: string;
  nameSol: string;

  typeSol: string;

  download=false ; //default: false

  businessUnitOfSolution: string;

  userIsInEmailEvaluatorAuthorized = false;
  userIsInEmailVeoliaAuthorized = false;
  userIsInEmailVendorAuthorized = false;
  userIsInEvaluatorsBu = false;
  isSoOfSolution = false; //on véirifie si le user est solution owner de la solution

  constructor(
    public homeUserServ: HomeUserService,
    public service: FormulaireAllService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    public authService: AuthService,
    private afs: AngularFireDatabase
  ) {}

  val: any;

  ngOnInit() {
    this.route.data.subscribe(routeData => {
      const data = routeData['data']; // référence au app-routing data

      //Données
      this.val = data.payload.data();
      this.val.id = data.payload.id;
      this.nameSol= data.payload.data().solutionName;
      this.businessUnitOfSolution = data.payload.data().businessUnitOfSolution;
    });

    //Pour récupérer dans le service l'id de la demande pour l'associé aux commentaires/fichiers et evaluation
    this.service.getSolutionId(this.val.id);

    //get type
    this.route.params.subscribe((params: Params) => this.typeSol = params['type']);
    console.log('type',this.typeSol)

    if(this.typeSol === 'SaaS') {
     this.tabName = 'Design Form';
    }

    if(this.typeSol === 'IaaS') {
      this.tabName = 'IaaS Form';
    }

    if(this.typeSol === 'PaaS') {
      //empty
    }

    if(this.typeSol === 'Apps') {
      //empty
    }

    //check si user est autorisé à accéder aux formulaires et actionsButtons

      //le user est dans emailEvaluatorAuthorized de la solution
      firebase.firestore().collection('solutions').doc(this.val.id).get().then(value => {
        if(!value.data().emailEvaluatorAuthorized.find(x => x === this.authService.currentUserEmail)){

          this.userIsInEmailEvaluatorAuthorized = false;
        } else {
          console.log("USER IS IN emailEvaluatorAuthorized")
          this.userIsInEmailEvaluatorAuthorized = true;
        }
      });

      //le user est dans emailVeoliaAuthorized de la solution
      firebase.firestore().collection('solutions').doc(this.val.id).get().then(value => {
        if(!value.data().emailVeoliaAuthorized.find(x => x === this.authService.currentUserEmail)){

          this.userIsInEmailVeoliaAuthorized = false;
        } else {
          console.log("USER IS IN emailVeoliaAuthorized")
          this.userIsInEmailVeoliaAuthorized = true;
        }
      });

      if(this.typeSol === 'SaaS') {
        //le user est dans emailVendorAuthorized de la solution (Only SaaS)
        firebase.firestore().collection('solutions').doc(this.val.id).get().then(value => {
          if(!value.data().emailVendorAuthorized.find(x => x === this.authService.currentUserEmail)){
            console.log("USER IS NOT IN emailVendorAuthorized")
            this.userIsInEmailVendorAuthorized = false;
          } else {
            console.log("USER IS IN emailVendorAuthorized")
            this.userIsInEmailVendorAuthorized = true;
          }
        });
      }

      //le user est sous ‘evaluators’ de la BU
      firebase.firestore().collection('businessUnit').where('nomBu', '==', this.businessUnitOfSolution)
        .get().then(snapshot => {
            snapshot.forEach(doc => {
              if(!doc.data().evaluators.find(x => x === this.authService.currentUserEmail)){ //GET evaluators for the BU matching with Bu of solution
                console.log("USER IS NOT IN evaluators for the BU")
                this.userIsInEvaluatorsBu = false;
              } else {
                console.log("USER IS IN evaluators for the BU")
                this.userIsInEvaluatorsBu = true;
              }
            });
          });

    //vérifie si le user est SO de la demande
    firebase.firestore().collection('solutions').where('solutionOwner', '==', this.authService.currentUserEmail).where(firebase.firestore.FieldPath.documentId(), '==', this.val.id)
      .get().then(snapshot => {
        if (snapshot.empty) {
          //si false
          console.log('The user is not SO of solution');
        } else {
          console.log('User is SO of solution');
          this.isSoOfSolution = true;
        }
      });

  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('nom => ', tabChangeEvent.tab.textLabel);

    if (tabChangeEvent.tab.textLabel === 'Formulaire Design') {
      tabChangeEvent.tab.textLabel = 'Design Form';
    }
    if (tabChangeEvent.tab.textLabel === 'Formulaire Legal') {
      tabChangeEvent.tab.textLabel = 'Legal Form';
    }
    // if (tabChangeEvent.tab.textLabel === 'Formulaire Article 28') {
    //   tabChangeEvent.tab.textLabel = 'Article 28 Form';
    // }
    if (tabChangeEvent.tab.textLabel === 'Annexes') {
      tabChangeEvent.tab.textLabel = 'Article 28 Form';
    }
    if (tabChangeEvent.tab.textLabel === 'Formulaire Article 32') {
      tabChangeEvent.tab.textLabel = 'Article 32 Form';
    }

    this.tabName = tabChangeEvent.tab.textLabel;
    this.listeDate = []; //on vide la liste à chaque clique
    this.logsInfos = []; //on vide la liste au changement de tab
    this.selected = false;
  };

  //appelé au clic du bouton dans le html, récupérer les dates qui correspondent à l'action
  getLogsAction(action) {
    this.listeDate = []; //on vide la liste à chaque clic
    this.selected = true;

    console.log('action: ' + action);
    console.log('tab: ' + this.tabName);
    console.log('solution: ' + this.service.idSolution);

    this.afs
      .list('logs/' + this.tabName + '/' + this.service.idSolution + '/' + action)
      .snapshotChanges()
      .subscribe(ok => {
        this.logsInfos = [];
        ok.forEach(elem => {
        this.listeDate.push(elem.key)
        });

        try {
          this.listeDate.sort((a, b) => this.parseDate(b).getTime() - this.parseDate(a).getTime());
        } catch(error) {
          console.log('Log date sort error', error);
        }

      });
  }

  //au clic pour voir le détail de la date du log
  getLogsInfosDate(action, date) {
    this.logsInfos = []; //vide la liste à chaque clic

    console.log('ACTION: ' + action);

    this.afs
      .list('logs/' + this.tabName + '/' + this.service.idSolution + '/' + action + '/' + date)
      .snapshotChanges()
      .subscribe(ok => {
        this.logsInfos = [];
        ok.forEach(elem => {
          const x = elem.payload.toJSON();
          x['$key'] = elem.key;
          this.logsInfos.push(x as Logs);
        });
        try {
          this.logsInfos.sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());
        } catch(error) {
          console.log('comment sort error', error);
        }
        //this.logsInfos.reverse(); //afficher du plus recent au plus ancien

      });
  }

  // function parseDate
  parseDate(dateString: string) {
    const [day, month, year] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  //au clic du bouton pour accéder au pdf
  generatePdf(action) {

    this.toastr.success('Download in progress...', '', {
      timeOut: 4000,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-center'
    });

    this.listeDate = []; //on vide la liste
    this.selected = true;

    this.download = true;

    console.log('action: ' + action);
    console.log('tab: ' + this.tabName);
    console.log('solution: ' + this.service.idSolution);

    this.afs
      .list('logs/' + this.tabName + '/' + this.service.idSolution + '/' + action)
      .snapshotChanges()
      .subscribe(ok => {
        this.logsInfos = [];
        ok.forEach(elem => {
          this.listeDate.push(elem.key); //les dates qui ont des logs

          this.logsInfos = [];

          this.afs
          .list('logs/' + this.tabName + '/' + this.service.idSolution + '/' + action + '/' + elem.key)
          .snapshotChanges()
          .subscribe(result => {
            result.forEach(element => {
              const x = element.payload.toJSON();
              x['$key'] = element.key;
              this.logsInfos.push(x as Logs);
            });
         });

        });
      });


    this.delay(1500).then(any => {

      if(this.tabName === 'Design Form' || this.tabName === 'Legal Form') {
            var fullEval = document.getElementById(action+this.tabName.substr(0,this.tabName.indexOf(' '))+'Pdf').innerHTML;
          }
          if(this.tabName === 'Article 28 Form') {
            var fullEval = document.getElementById(action+'Article28Pdf').innerHTML;
          }
          if(this.tabName === 'Article 32 Form') {
            var fullEval = document.getElementById(action+'Article32Pdf').innerHTML;
          }

          if(this.tabName === 'IaaS Form') {
            var fullEval = document.getElementById(action+'IaaSPdf').innerHTML;
          }

      let finalVar = htmlToPdfmake(fullEval);

      let documentDefinition = {
            footer: function (pagenumber, pagecount) {
              return {
                  alignment: 'right', margin: [0, 10, 10, 0],
                  text: pagenumber + '/' + pagecount
              };
            },
            content: finalVar
      };

      if(this.tabName === 'Design Form' || this.tabName === 'Legal Form' || this.tabName === 'Article 32 Form') {
        pdfMake.createPdf(documentDefinition).download('Logs-' + action + '-'+ this.nameSol + '-'+ this.tabName +'.pdf');
      }
      if(this.tabName === 'Article 28 Form') { //Annexes
        pdfMake.createPdf(documentDefinition).download('Logs-' + action + '-'+ this.nameSol + '-Annexes.pdf');
      }

      this.download = false;
    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  /////////FORMULAIRES/////////

  //Formulaire DESIGN
  gotoDesignForm(id: string) {
    this.homeUserServ.initializeDesignForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire LEGAL
  gotoLegalForm(id: string) {
    this.homeUserServ.initializeLegalForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 32
  gotoArticle32Form(id: string) {
    this.homeUserServ.initializeArticle32Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 28
  gotoArticle28Form(id: string) {
    this.homeUserServ.initializeArticle28Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //IaaS
  gotoIaaSForm(id: string) {
    this.homeUserServ.initializeIaaSForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Demande séléctionée dans le tableau pour afficher ses infos dans une autre pages avec redirection des données
  modifierDemande(id: string, type: string) {
    this.router.navigate(['/editDemandeDla/' + id + '/' + type]); //{ this.router.navigate(['/details/'+ item.payload.doc.id]);}
  }

  //redirection vers la page de création du compte pour le vendor
  creationCompteVendor(id: string, type: string) {
    this.router.navigate(['/gestionVendor/' + id + '/' + type]);
  }

  //redirection vers la page des logs pour la demande
  logs(id: string, type: string) {
    this.router.navigate(['/logs/' + id + '/' + type]);
  }

  //redirection sur le tableau de synthese des forms avec l'éval actuelle par form
  tableauEvals(id: string, type: string) {
    this.router.navigate(['/tableauEvals/' + id + '/' + type]);
  }

}
