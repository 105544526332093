import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';

import { AuthService } from '../core/auth.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EvaluatorService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
  ) {}

  ///////////HOME USER avec ses demandes (solutions)/////////////
  
  //SSC
  getAllSSCdata() {
    return this.firestore.collection('securityScoreCard').snapshotChanges();
  }
  
  ///////SaaS///////
  //VEOLIA
  //Demandes avec businessUnitOfSolution (solutions collection) <-> dont le user est évaluator via 'evaluators' (businessUnit collection) nomBu
  getEvaluatorSaaSRequestsQuery(bu) {
    return this.firestore
      .collection('solutions', ref => 
        ref.where('businessUnitOfSolution', '==', bu).where('type', '==', 'SaaS')
      )
      .snapshotChanges().pipe(take(1))
  }
  
  //Demandes évaluées avec businessUnitOfSolution (solutions collection) <-> dont le user est évaluator via 'evaluators' (businessUnit collection) nomBu
  getEvaluatorSaaSEvaluatedRequestsQuery(bu) {
    return this.firestore
      .collection('solutions', ref => 
        ref.where('businessUnitOfSolution', '==', bu).where('type', '==', 'SaaS').where('fullyEvaluated', '==', true)
      )
      .snapshotChanges().pipe(take(1));
  }
  
  ///////END SaaS///////
  
}
