import { Component, OnInit } from '@angular/core';
import { AdminService } from './admin.service';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase/compat/app';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/core/auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { FormulaireAllService } from 'app/homeUser/formulairesAccess/formulaireAll/formulaireAll.service';
import { dictToURI } from '../../app/core/helpers';
import { DesignForm } from 'app/homeUser/formulairesAccess/formulaireAll/designForm.model';
import { LegalForm } from 'app/homeUser/formulairesAccess/formulaireAll/legalForm.model';
import { Article32Form } from 'app/homeUser/formulairesAccess/formulaireAll/article32Form.model';
import { Article28Form } from 'app/homeUser/formulairesAccess/formulaireAll/article28Form.model';

@Component({
  selector: 'app-validate-solution',
  templateUrl: './validate-solution.component.html',
  styleUrls: ['./validate-solution.component.scss']
})
export class ValidateSolutionComponent implements OnInit {
  idSol: string;
  //données de la demande
  //SaaS
  expectedEvaluationDate: Date;
  expectedCompletionDate: Date;
  expectedOnlineReleaseDate: Date;
  accessQuestion: string;
  interconnectQuestion: string;
  interconnectQuestion2: string;
  communicationQuestion: string;
  nbSolution: number;
  linkCom: string;
  rfpName: string;
  emailVendorAuthorized: Array<string>;
  emailVeoliaAuthorized: Array<string>;
  emailEvaluatorAuthorized: Array<string>;

  mainEmailVendor?: string;
  mainFirstnameVendor?: string;
  mainLastnameVendor?: string;
  mainCompanyVendor?: string;

  //IaaS
  q0a_iaas: string;
  q0b_iaas: string;

  //PaaS

  //Apps

  //All
  status: string;
  solutionName: string;
  domainOfSolution: string;
  businessUnitOfSolution: string;
  solutionOwner: string;
  requestDate: Date;
  description: string;
  editorBrand: string;
  link: string;
  emailInitiatorVeolia: string;
  fullNameInitiatorVeolia: string;
  inPlace: string;
  apmNumber: string;
  type: string;

  item: any;

  evaluatorsBu: string[] = [];

  displayNameOfSolOwner: string;

  fire = firebase.firestore();

  companyData: string;

  constructor(
    public service: AdminService,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
    private firestore: AngularFirestore,
    private httpClient: HttpClient,
    public formAllServ: FormulaireAllService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(routeData => {
      const data = routeData['data'];
      if (data) {
        this.item = data.payload.data();
        this.item.id = data.payload.id;
        this.item.businessUnitofSolution = data.payload.data().businessUnitOfSolution;
      }
    });

    //get type
    this.route.params.subscribe((params: Params) => this.type = params['type']);
    console.log('type',this.type)

    if(this.type === 'SaaS') {
      this.getDataSaaS(this.item.id);
    }

    if(this.type === 'IaaS') {
      this.getDataIaaS(this.item.id);
    }

    if(this.type === 'PaaS') {
      //this.getDataPaaS(this.item.id);
    }

    if(this.type === 'Apps') {
      //this.getDataApps(this.item.id);
    }
  }

  //SaaS
  getDataSaaS(id: string) {
  //Recup données de la demande: Partie demande datastore db
    const demandeRef = this.fire.collection('solutions').doc(id);
    demandeRef
      .get()
      .then(doc => {
          //console.log('Document data:', doc.data());
            this.domainOfSolution = doc.data().domainOfSolution;
            this.businessUnitOfSolution = doc.data().businessUnitOfSolution;
            this.solutionName = doc.data().solutionName;
            this.rfpName = doc.data().rfpName;
            this.description = doc.data().description;
            this.link = doc.data().link;
            this.linkCom = doc.data().linkCom;
            this.editorBrand = doc.data().editorBrand;
            this.solutionOwner = doc.data().solutionOwner;
            this.emailInitiatorVeolia = doc.data().emailInitiatorVeolia;
            this.fullNameInitiatorVeolia = doc.data().fullNameInitiatorVeolia;
            this.emailVendorAuthorized = doc.data().emailVendorAuthorized;
            this.mainEmailVendor = doc.data().mainEmailVendor;
            this.mainFirstnameVendor = doc.data().mainFirstnameVendor;
            this.mainLastnameVendor = doc.data().mainLastnameVendor;
            this.mainCompanyVendor = doc.data().mainCompanyVendor;
            this.emailVeoliaAuthorized = doc.data().emailVeoliaAuthorized;
            this.emailEvaluatorAuthorized = doc.data().emailEvaluatorAuthorized;
            this.expectedEvaluationDate = this.convertDate(doc.data().expectedEvaluationDate);
            this.expectedCompletionDate = this.convertDate(doc.data().expectedCompletionDate);
            this.expectedOnlineReleaseDate = this.convertDate(doc.data().expectedOnlineReleaseDate);
            this.requestDate = this.convertDate(doc.data().requestDate);
            this.status = doc.data().status;
            this.inPlace = doc.data().inPlace;
            this.apmNumber = doc.data().apmNumber;
            this.type = doc.data().type;
            this.accessQuestion =  doc.data().accessQuestion;
            this.interconnectQuestion =  doc.data().interconnectQuestion;
            this.interconnectQuestion2 =  doc.data().interconnectQuestion2;
            this.communicationQuestion =  doc.data().communicationQuestion;
            this.nbSolution=  doc.data().nbSolution;

            this.idSol = id;

        //GET evaluators from 'businessUnit' collection matching with businessUnitOfSolution from 'solutions'
        firebase.firestore()
        .collection('businessUnit')
        .where('nomBu', '==', this.businessUnitOfSolution)
        .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              this.evaluatorsBu = doc.data().evaluators //GET evaluators for the BU matching with Bu of solution
              console.log("Evaluators for this solution:",this.evaluatorsBu)
            });
          });
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

}

  //IaaS
  getDataIaaS(id: string) {
  //Recup données de la demande: Partie demande datastore db
    const demandeRef = this.fire.collection('solutions').doc(id);
    demandeRef
      .get()
      .then(doc => {
          //console.log('Document data:', doc.data());
            (this.q0a_iaas = doc.data().q0a_iaas),
            (this.q0b_iaas = doc.data().q0b_iaas),
            (this.domainOfSolution = doc.data().domainOfSolution),
            (this.businessUnitOfSolution = doc.data().businessUnitOfSolution),
            (this.solutionName = doc.data().solutionName),
            (this.description = doc.data().description),
            (this.link = doc.data().link),
            (this.editorBrand = doc.data().editorBrand),
            (this.solutionOwner = doc.data().solutionOwner),
            (this.emailInitiatorVeolia = doc.data().emailInitiatorVeolia),
            (this.fullNameInitiatorVeolia = doc.data().fullNameInitiatorVeolia),
            (this.emailVeoliaAuthorized = doc.data().emailVeoliaAuthorized),
            (this.requestDate = this.convertDate(doc.data().requestDate)),
            (this.status = doc.data().status),
            (this.inPlace = doc.data().inPlace),
            (this.apmNumber = doc.data().apmNumber),
            (this.type = doc.data().type),

            this.idSol = id;

      })
      .catch(err => {
        console.log('Error getting document', err);
      });

}


  convertDate(timestamp) {
    if (timestamp) {
      let date = new Date(new Date(null).getTime());
      let myDate = timestamp.toString();
      let seondsTimestamp = myDate.substring(18, myDate.indexOf(',')); //ON prend que les seconds de Timestamp(seconds=1564610400,nanoseconds=0)

      date.setTime(seondsTimestamp * 1000);
      //console.log(date); //Thu Aug 01 2019 00:00:00 GMT+0200 (heure d’été d’Europe centrale) bon format à insérer dans le champ
      //console.log(date.getTime()); //1564610400000
      return date;
    } else {
      console.log('Pas de date pour le champ');
    }
  }

  //Génération mot de passe aléatoire pour le vendor
  generate_pass() {
    let generatePassword = (
      length = 12,
      wishlist= '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~*:!@%-_/',
    ) =>
        Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => wishlist[x % (wishlist.length)])
        .join('');

    return generatePassword();
  }

  //SOLUTION OK
  validateSol() {
    //All
    this.firestore.doc('solutions/' + this.idSol).update({ status: 'Empty' });

    //SSC
    //1 add company to Veolia's Portfolio//
    this.addCompanyToPortfolio(this.linkCom).subscribe(data => {
      this.companyData = JSON.parse(data)
    });

    this.delay(3500).then(any => {
      //2 get added company score card with metadata
      this.getCompanyScorecard(this.linkCom).subscribe(data => {
        this.companyData = JSON.parse(data)

        //2.1 Add company metadata in firestore securityScoreCard collection
        this.service.addCompanyToFirestore(this.idSol, this.solutionName, this.linkCom, this.companyData);
      });
    });

    //SaaS
    if(this.type === 'SaaS') {
      this.toastr.success('Solution owner and vendor notified', 'Solution validated !', {
        timeOut: 8000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-center'
      });

      //envoi credentials au vendor
      //création du compte vendor
      this.auth.addVendorAuthorizedToDemande(
        this.idSol,
        this.mainEmailVendor,
        this.generate_pass(),
        this.mainFirstnameVendor,
        this.mainLastnameVendor,
        this.mainCompanyVendor,
        this.solutionName,
        this.rfpName,
        this.editorBrand,
        this.businessUnitOfSolution,
        this.emailInitiatorVeolia
      );

        //recup displayName du solOwner
      console.log('SOL OWNER : ' + this.solutionOwner);
        this.fire.collection('users').where("email", '==', this.solutionOwner)
        .get().then(snap => {
          snap.forEach(val => {
           this.displayNameOfSolOwner = val.data().displayName
          });
        }).then(res => {
          //1-envoi à la DLA + SO
          this.sendSolutionValidatedAcknowledgmentSaaS(this.idSol, this.solutionName, this.rfpName, this.editorBrand, this.solutionOwner, this.displayNameOfSolOwner).subscribe(data => {
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
        });

          //2-mail envoyé à chaque evaluator présent sous 'evaluators' appartenant à la Bu de la solution
          if(this.evaluatorsBu) {
            for (let j = 0; j <= this.evaluatorsBu.length-1 ; j++) {
              console.log("SENT TO", this.evaluatorsBu[j])
              console.log("DATA", this.solutionName, this.rfpName, this.editorBrand, this.solutionOwner, this.evaluatorsBu[j])
              this.sendSolutionValidatedAcknowledgmentForEvaluatorsSaaS(this.idSol, this.solutionName, this.rfpName, this.editorBrand, this.solutionOwner, this.evaluatorsBu[j]).subscribe(data => {
                if (data !== "200") {
                  console.error("KO mail retarus unsent !")
                } else {
                  console.log("OK mail retarus sent !")
                }
                  console.log("from api: " + data);
              });
            }
          }
        })

    //////creation des formulaires//////
    const fire = firebase.firestore();

    //FORMULAIRE DA
    fire
      .collection('designForm')
      .where('idSolution', '==', this.idSol)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          //Si le formDA n'existe pas
          //requete sur la demande concernée pour récup ses données et les insérées dans la nouvelle table designForm
          fire
            .collection('solutions')
            .doc(this.idSol)
            .get()
            .then(doc => {
              //création du formulaire formDa correspondant à la demande choisie
              const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc('designForm/' + this.idSol); // id du doc=id Demande concernée

              const data: DesignForm = {
                idSolution: this.idSol,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                rfpName: doc.data().rfpName,
                description: doc.data().description,
                type: doc.data().type,
                link: doc.data().link,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                expectedEvaluationDate: doc.data().expectedEvaluationDate,
                expectedCompletionDate: doc.data().expectedCompletionDate,
                requestDate: doc.data().requestDate,
                createdAt: new Date(Date.now()), //uniquement lors de l'initialisation
                emailVendorAuthorized: doc.data().emailVendorAuthorized,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                emailEvaluatorAuthorized: doc.data().emailEvaluatorAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS,
                designFull: false //uniquement lors de l'initialisation
              };
              console.log('Création doc designForm');
              refFormDa.set(data, { merge: true });

            })
            .catch(err => {
              console.log('Error creating document', err);
            });
        }
      });

    //FORMULAIRE LA
    fire
      .collection('legalForm')
      .where('idSolution', '==', this.idSol)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          //requete sur la demande concernée pour récup ses données et les insérées dans la nouvelle table legalForm
          fire
            .collection('solutions')
            .doc(this.idSol)
            .get()
            .then(doc => {
              //création du formulaire formLa correspondant à la demande choisie
              //en général ce sera toujours le veolia qui ira dessus en premier (il initialise)
              const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc('legalForm/' + this.idSol); // id du doc=id Demande concernée

              const data: LegalForm = {
                idSolution: this.idSol,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                rfpName: doc.data().rfpName,
                description: doc.data().description,
                type: doc.data().type,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                expectedEvaluationDate: doc.data().expectedEvaluationDate,
                expectedCompletionDate: doc.data().expectedCompletionDate,
                requestDate: doc.data().requestDate,
                createdAt: new Date(Date.now()),
                emailVendorAuthorized: doc.data().emailVendorAuthorized,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                emailEvaluatorAuthorized: doc.data().emailEvaluatorAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS,
                legalFull: false //uniquement lors de l'initialisation
              };
              console.log('Création doc legalForm');
              refFormLa.set(data, { merge: true });

            })
            .catch(err => {
              console.log('Error creating document', err);
            });
        }
      });

    //FORMULAIRE ARTICLE 32
    fire
      .collection('article32Form')
      .where('idSolution', '==', this.idSol)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          //Si le form32 n'existe pas
          //requete sur la demande concernée pour récup ses données et les insérées dans la nouvelle table article32Form
          fire
            .collection('solutions')
            .doc(this.idSol)
            .get()
            .then(doc => {
              //création du formulaire form32 correspondant à la demande choisie
              //en général ce sera toujours le veolia qui ira dessus en premier (il initialise)
              const refForm32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSol}`);

              const data: Article32Form = {
                idSolution: this.idSol,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                rfpName: doc.data().rfpName,
                description: doc.data().description,
                type: doc.data().type,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                expectedEvaluationDate: doc.data().expectedEvaluationDate,
                expectedCompletionDate: doc.data().expectedCompletionDate,
                requestDate: doc.data().requestDate,
                createdAt: new Date(Date.now()), //uniquement lors de l'initialisation
                emailVendorAuthorized: doc.data().emailVendorAuthorized,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                emailEvaluatorAuthorized: doc.data().emailEvaluatorAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS,
                article32Full: false //uniquement lors de l'initialisation
              };
              console.log('Création doc article32Form');
              refForm32.set(data, { merge: true });

            })
            .catch(err => {
              console.log('Error creating document', err);
            });
        }
      });

    //FORMULAIRE ARTICLE 28
    fire
      .collection('article28Form')
      .where('idSolution', '==', this.idSol)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          //Si le form28 n'existe pas
          //requete sur la demande concernée pour récup ses données et les insérées dans la nouvelle table article28Form
          fire
            .collection('solutions')
            .doc(this.idSol)
            .get()
            .then(doc => {
              //création du formulaire form28 correspondant à la demande choisie
              //en général ce sera toujours le veolia qui ira dessus en premier (il initialise)
              const refForm28: AngularFirestoreDocument<Article28Form> = this.firestore.doc(`article28Form/${this.idSol}`);

              const data: Article28Form = {
                idSolution: this.idSol,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                rfpName: doc.data().rfpName,
                description: doc.data().description,
                type: doc.data().type,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                expectedEvaluationDate: doc.data().expectedEvaluationDate,
                expectedCompletionDate: doc.data().expectedCompletionDate,
                requestDate: doc.data().requestDate,
                createdAt: new Date(Date.now()), //uniquement lors de l'initialisation
                emailVendorAuthorized: doc.data().emailVendorAuthorized,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                emailEvaluatorAuthorized: doc.data().emailEvaluatorAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS,
                article28Full: false //uniquement lors de l'initialisation
              };
              console.log('Création doc article28Form');
              refForm28.set(data, { merge: true });

            })
            .catch(err => {
              console.log('Error getting document', err);
            });
        }
      });

    } //END SaaS

    //IaaS PaaS Apps
    if(this.type !== 'SaaS') {
      this.toastr.success('Solution owner notified', 'Solution validated !', {
        timeOut: 8000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-center'
      });

      //envoi à la DLA + SO
      this.sendSolutionValidatedAcknowledgment(this.solutionName, this.editorBrand, this.solutionOwner, this.type).subscribe(data => {
        if (data !== "200") {
          console.error("KO mail retarus unsent !")
        } else {
          console.log("OK mail retarus sent !")
        }
          console.log("from api: " + data);
      });

    }

    //PaaS
    if(this.type === 'PaaS') {
      //empty
    }

    //Apps
    if(this.type === 'Apps') {
      //empty
    }

    this.router.navigate(['/admin']);
  }

  //SSC
  //1 add company to Veolia's Portfolio//
  addCompanyToPortfolio(linkCom) {
    const query = dictToURI({
      link: linkCom
    });
     return this.httpClient.get('/api/add_company_to_portfolio?' + query, {responseType:'text'}).pipe(
       catchError(this.handleErrorHttp)
     );
   }

  //2 get added company score card with metadata
  getCompanyScorecard(linkCom) {
    const query = dictToURI({
      link: linkCom
    });
     return this.httpClient.get('/api/get_company_info_scorecard_summary?' + query, {responseType:'text'}).pipe(
       catchError(this.handleErrorHttp)
     );
   }

  //SaaS
  sendSolutionValidatedAcknowledgmentSaaS(idSol, solName, rfpName, editorBrand, emailSo, displayNameSolOwner){
    const query = dictToURI({
      emailSo,
      solutionName: solName,
      idSol,
      rfpName,
      editorBrand,
      displayNameOfSolOwner: displayNameSolOwner
    });
    return this.httpClient.get('/api/solution_validated_acknowledgment_SaaS?' + query, {responseType:'text'}).pipe(
            catchError(this.handleErrorHttpBasic)
            );
  }

  sendSolutionValidatedAcknowledgmentForEvaluatorsSaaS(idSol, solName, rfpName, editorBrand, emailSo, emailEvaluatorBu){
    const query = dictToURI({
      emailSo,
      solutionName: solName,
      idSol,
      rfpName,
      editorBrand,
      emailEvaluatorBu
    });
    return this.httpClient.get('/api/solution_validated_acknowledgment_for_evaluators_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttpBasic)
    );
  }

  //IaaS PaaS Apps
  sendSolutionValidatedAcknowledgment(solName, editorBrand, emailSo, type){
    const query = dictToURI({
      emailSo: emailSo,
      solutionName: solName,
      editorBrand: editorBrand,
      type,
    });

    return this.httpClient.get('/api/solution_validated_acknowledgment?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttpBasic)
    );
  }

  //SOLUTION NOT OK
  cancelSol() {

    //All
    if (confirm('Do you really want to cancel this request')) {
      this.firestore.doc('solutions/' + this.idSol).update({ status: 'Canceled' });

      //SaaS
      if(this.type === 'SaaS') {
        this.toastr.info('All stakeholders have been notified', 'Cancelled successfully', {
          timeOut: 6000,
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-center'
        });

      //1-envoi au vendor + DLA + SO
        this.sendSolutionCanceledSaaS(this.auth.currentUserEmail, this.auth.currentUserDisplayName, this.solutionName, this.rfpName, this.editorBrand, this.businessUnitOfSolution, this.convertDate(this.requestDate), this.solutionOwner, this.emailVendorAuthorized, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
          }
            console.log("from api: " + data);
        });

        //2-envoi email aux evaluators BU inclus dans evaluators
          if(this.evaluatorsBu) {
           for(let i = 0; i < this.evaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.evaluatorsBu[i])

            this.sendSolutionCanceledSaaS(this.auth.currentUserEmail, this.auth.currentUserDisplayName, this.solutionName, this.rfpName, this.editorBrand, this.businessUnitOfSolution, this.convertDate(this.requestDate), null, null, this.evaluatorsBu[i], null).subscribe(data => {
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
            });
           }
          }
      }

      //IaaS PaaS Apps
      if(this.type !== 'SaaS') {
        this.toastr.info('All stakeholders have been notified', 'Cancelled successfully', {
          timeOut: 6000,
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-center'
        });

      //envoi à DLA + SO
        this.sendSolutionCanceled(this.auth.currentUserEmail, this.auth.currentUserDisplayName, this.solutionName, this.editorBrand, this.businessUnitOfSolution, this.convertDate(this.requestDate), this.solutionOwner, this.type).subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
          }
            console.log("from api: " + data);
        });
      }

      //PaaS
      if(this.type === 'PaaS') {
        //empty
      }

      //Apps
      if(this.type === 'Apps') {
        //empty
      }

    this.router.navigate(['/admin']);
    }
  }

  //SaaS
  sendSolutionCanceledSaaS(initiatorEmail, initiatorDisplayName, solutionName, rfpName, editorBrand, buOfSolution, requestDate, solOwner, vendorEmail, evaluatorsBu, emailgbldla){
    const query = dictToURI({
      emailVeolia: initiatorEmail,
      emailgbldla,
      displayNameVeolia: initiatorDisplayName,
      solName: solutionName,
      rfpName,
      editorBrand,
      bu: buOfSolution,
      requestDate,
      emailSolOwner: solOwner,
      emailvendor: vendorEmail,
      emailsEvaluatorsBuList: evaluatorsBu
    });
    return this.httpClient.get('/api/solution_cancelled_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttpBasic)
    );
  }

  //IaaS PaaS Apps
  sendSolutionCanceled(initiatorEmail, initiatorDisplayName, solutionName, editorBrand, buOfSolution, requestDate, solOwner, type){
    const query = dictToURI({
      emailVeolia: initiatorEmail,
      displayNameVeolia: initiatorDisplayName,
      solName: solutionName,
      editorBrand: editorBrand,
      bu: buOfSolution,
      requestDate: requestDate,
      emailSolOwner: solOwner,
      type
    });
    return this.httpClient.get('/api/solution_cancelled_IaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttpBasic)
    );
  }

  public handleErrorHttpBasic(error: HttpErrorResponse) {
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error);
      } else { //status code 500 ou 401 ou 400
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          console.error(
            `Backend returned code ${error.status}\n, ` +
            `body was: ${error.error}\n`);
      }
      // Return an observable with a user-facing error message.
      return throwError(
        'API request failed.');
  }

  public handleErrorHttp(error: HttpErrorResponse) {
     if (error.status === 0) {
       // A client-side or network error occurred. Handle it accordingly.
       console.error('An error occurred:', error.error);
     } else { //status code 500 ou 401 ou 400
         // The backend returned an unsuccessful response code.
         // The response body may contain clues as to what went wrong.
         this.toastr.error('Error', 'Error', {
               timeOut: 9000,
               closeButton: true,
               progressBar: true,
               positionClass: 'toast-bottom-center'
             });
         console.error(
           `Backend returned code ${error.status}\n, ` +
           `body was: ${error.error}\n`);
     }
     // Return an observable with a user-facing error message.
     return throwError(
       'API request failed.');
     }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

}
