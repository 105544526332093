import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../core/auth.service';
import { Demande } from '../homeUser/demandeDla/demande.model';
import { ContactService } from './contact.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

//Formulaire de la page Home avec vérification des champs
export class ContactComponent implements OnInit {
  allRequestsVeolia: Demande[];
  allRequestsVendor: Demande[];

  submitted = false;

  //veolia
  contactForm: FormGroup;

  //Vendor
  contactForm2: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    public auth: AuthService,
    public service: ContactService
  ) {}

  ngOnInit() {

    this.service.getRequestsUserVeolia().subscribe(actionArray => {
      this.allRequestsVeolia = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;
      });
    });
    
    this.service.getRequestsUserVendor().subscribe(actionArray => {
      this.allRequestsVendor = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;
      });
    });

    //Pour user veolia
    // ce sont les formControlName= qui sont insérées dans la bd et pas les name=
    this.contactForm = this.formBuilder.group({
      //uidUser: [this.auth.currentUserId],
      email: [this.auth.currentUserEmail],
      displayName: [this.auth.currentUserDisplayName],
      request: ['', Validators.required],
      subject: ['', Validators.required],
      content: ['', Validators.required]
    });

    //POur vendor
    this.contactForm2 = this.formBuilder.group({
      //uidUser: [this.auth.currentUserId],
      email: [this.auth.currentUserEmail],
      //nom: [this.auth.currentUserNom],
      //prenom: [this.auth.currentUserPrenom],
      lastname: ['', Validators.required],
      firstname: ['', Validators.required],
      request: ['', Validators.required],
      subject: ['', Validators.required],
      content: ['', Validators.required]
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  get v() {
    return this.contactForm2.controls;
  }

  onSubmitContactForm(value) {
    this.submitted = true;

    if (this.contactForm.invalid) {
      this.toastr.warning('Check out the required fields', 'Error fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      });
    } else {
      //si form valide
      
      //envoie au veolia un accusé de reception avec un récap de son formulaire soumis
      this.service.sendOverviewContactFormVeolia(value.displayName, value.email, value.subject, value.content, value.request).subscribe(data => {
        if (data !== "200") {
          console.error("KO mail retarus unsent !")
        } else {
          console.log("OK mail retarus sent !")
        }
          console.log("from api retarus: " + data);
      });
      
      //envoie juste à la DLA le formulaire soumis par le veolia
      this.service.sendContactFormVeolia(value.displayName, value.email, value.subject, value.content, value.request).subscribe(data => {
        if (data !== "200") {
          console.error("KO mail retarus unsent !")
        } else {
          console.log("OK mail retarus sent !")
        }
          console.log("from api retarus: " + data);
      });
      
      this.toastr.success(
        'We will reply to you directly by email once your request has been processed ',
        'Message sent !',
        { timeOut: 9000, closeButton: true, positionClass: 'toast-bottom-center' }
      ); //texte,titre,temps

      this.router.navigate(['home']);
    }
  }

  onSubmitContactForm2(value) {
    this.submitted = true;

    if (this.contactForm2.invalid) {
      this.toastr.warning('Check out the required fields', 'Error fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      });
    } else {
      //si form valide
      //envoie au vendor un accusé de reception avec un récap de son formulaire soumis
      this.service.sendOverviewContactFormVendor(value.lastname, value.firstname, value.email, value.subject, value.content, value.request).subscribe(data => {
        if (data !== "200") {
          console.error("KO mail retarus unsent !")
        } else {
          console.log("OK mail retarus sent !")
        }
          console.log("from api retarus: " + data);
      });
      
      //envoie à la DLA + SO de la demande le formulaire soumis par le vendor
      this.service.sendContactFormVendor(value.lastname, value.firstname, value.email, value.subject, value.content, value.request).subscribe(data => {
        if (data !== "200") {
          console.error("KO mail retarus unsent !")
        } else {
          console.log("OK mail retarus sent !")
        }
          console.log("from api retarus: " + data);
      });
      
      this.toastr.success(
        'We will reply to you directly by email once your request has been processed ',
        'Message sent !',
        { timeOut: 9000, closeButton: true, positionClass: 'toast-bottom-center' }
      ); //texte,titre,temps

      this.router.navigate(['home']);
    }
  }
}
