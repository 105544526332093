import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root'
})
export class Article32FormService {
    fire = firebase.firestore();
    
    constructor(private db: AngularFirestore) {}
    
    public getForm(demandId: string) {
        return this.db.collection('article32Form').doc(demandId).get();
    }
}