import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HomeUserService } from '../homeUser.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DemandeDlaService } from '../demandeDla/demandeDla.service';
import { AuthService } from '../../core/auth.service';
import { Domains } from '../demandeDla/domains.model';

@Component({
  selector: 'app-editDemandeDla',
  templateUrl: './editDemandeDla.component.html',
  styleUrls: ['./editDemandeDla.component.scss']
})
export class EditDemandeDlaComponent implements OnInit {
  editFormSaaS: FormGroup;
  editFormIaaS: FormGroup;
  editFormPaaS: FormGroup;
  editFormApps: FormGroup;
  
  item: any;

  submitted = false;
  
  //SaaS
  conflictDate = false;

  listDomains = [];
  
  //SaaS IaaS PaaS Apps
  typeSol : string;

  //checkbox
  accessQuestion: string[] = ['Veolia employees (internal or external, with a veolia.com account)',
    'Veolia customers (municipalities...)',
    'Veolia Partners',
    'Public (specify if there is authentication)',
    ];

  //checkbox
  interconnectQuestion: string[] = ['Yes, with other SaaS',
    'Yes, with Veolia IaaS or PaaS solution',
    "I don't know"
    ];
    
  //checkbox
  communicationQuestion: string[] = ['REST API capabilities are required',
    'File Transfer capabilities are required',
    'Offline capabilities are required',
    'Sensitive personal data are going to be accessed or stored',
    'Sensitive business data are going to be accessed or stored'
    ];

  constructor(
    public service: HomeUserService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private firestore: AngularFirestore,
    private serviceDemande: DemandeDlaService,
    public authService: AuthService
  ) {}

  ngOnInit() {
    //on récupère les données issues du tableau pour les mettre dans le formulaire
    this.route.data.subscribe(routeData => {
      const data = routeData['data'];
      if (data) {
        this.item = data.payload.data();
        this.item.id = data.payload.id;
      }
    });
    
    //get type
    this.route.params.subscribe((params: Params) => this.typeSol = params['type']);
    console.log('type',this.typeSol)
    
    if(this.typeSol === 'SaaS') {
      this.createEditFormSaaS();
    }
    
    if(this.typeSol === 'IaaS') {
      this.createEditFormIaaS();
    }
    
    if(this.typeSol === 'PaaS') {
      //this.createEditFormPaaS();
    }
    
    if(this.typeSol === 'Apps') {
      //this.createEditFormApps();
    }

    this.fetchDomaine();
    
  }
  
  ngAfterContentInit() {
    //empty
  }

  createEditFormSaaS() {
    this.editFormSaaS = this.fb.group({
      //infos user
      fullNameInitiatorVeolia: [this.item.fullNameInitiatorVeolia], 
      solutionOwner: [this.item.solutionOwner], 
      emailInitiatorVeolia: [this.item.emailInitiatorVeolia], 
      uidInitiatorVeolia: [this.item.uidInitiatorVeolia],
      businessUnitOfSolution: [this.item.businessUnitOfSolution],
      
      //Main details
      requestDate: [this.convertDate(this.item.requestDate)], //non modifiable
      type: [this.item.type, Validators.required],
      rfpName: [this.item.rfpName],
      description: [this.item.description, Validators.required],
      domainOfSolution: [this.item.domainOfSolution, Validators.required],
      accessQuestion: [this.item.accessQuestion, Validators.required],
      integrateQuestion: [this.item.integrateQuestion, Validators.required],
      interconnectQuestion: [this.item.interconnectQuestion, Validators.required],
      interconnectQuestion2: [this.item.interconnectQuestion2, Validators.required],
      communicationQuestion: [this.item.communicationQuestion],
      expectedCompletionDate: [this.convertDate(this.item.expectedCompletionDate)], //non modifiable // ou new FormControl(this.convertDate(this.item.dateAttendue)),
      expectedEvaluationDate: [this.convertDate(this.item.expectedEvaluationDate), Validators.required],
      expectedOnlineReleaseDate: [this.convertDate(this.item.expectedOnlineReleaseDate), Validators.required],
      nbSolution: [this.item.nbSolution],
      
      ////Solution(s) (pour un RFP)////
      //solutionSection: this.fb.array([]),
      
      //Solution
      solutionName: [this.item.solutionName, Validators.required],
      editorBrand: [this.item.editorBrand, Validators.required],
      inPlace: [this.item.inPlace, Validators.required],
      apmNumber: [this.item.apmNumber],
      link: [this.item.link],
      //Main contact
      mainEmailVendor: [this.item.mainEmailVendor], //non modifiable
      mainLastnameVendor: [this.item.mainLastnameVendor, Validators.required],
      mainFirstnameVendor: [this.item.mainFirstnameVendor, Validators.required],
      mainCompanyVendor: [this.item.mainCompanyVendor, Validators.required],
      linkCom: [this.item.linkCom],
    });
    
    
  }
  
  createEditFormIaaS() {
    this.editFormIaaS = this.fb.group({
      //infos user
      fullNameInitiatorVeolia: [this.item.fullNameInitiatorVeolia], 
      solutionOwner: [this.item.solutionOwner], 
      emailInitiatorVeolia: [this.item.emailInitiatorVeolia], 
      uidInitiatorVeolia: [this.item.uidInitiatorVeolia],
      businessUnitOfSolution: [this.item.businessUnitOfSolution],
      
      //Main details
      requestDate: [this.convertDate(this.item.requestDate)], //non modifiable
      type: [this.item.type, Validators.required],
      q0a_iaas: [this.item.q0a_iaas, Validators.required],
      q0b_iaas: [this.item.q0b_iaas, Validators.required],
      solutionName: [this.item.solutionName, Validators.required],
      editorBrand: [this.item.editorBrand, Validators.required],
      description: [this.item.description, Validators.required],
      domainOfSolution: [this.item.domainOfSolution, Validators.required],
      inPlace:  [this.item.inPlace, Validators.required],
      apmNumber:  [this.item.apmNumber],
      link:  [this.item.link],
      comment:  [this.item.comment],
    });
    
    
  }
  
  //SaaS
  get f() {
    return this.editFormSaaS.controls;
  }
  
  //IaaS
  get f1() {
    return this.editFormIaaS.controls;
  }

  //Récupérer dateAttendue+q7 et les placer dans le champ datePicker
  convertDate(timestamp) {
    //timestamp= this.item.dateAttendue et this.item.q7

    let date = new Date(new Date(null).getTime());
    let myDate = timestamp.toString();
    let seondsTimestamp = myDate.substring(18, myDate.indexOf(',')); //ON prend que les seconds de Timestamp(seconds=1564610400,nanoseconds=0)

    date.setTime(seondsTimestamp * 1000);
    //console.log(date); //Thu Aug 01 2019 00:00:00 GMT+0200 (heure d’été d’Europe centrale) bon format à insérer dans le champ
    //console.log(date.getTime()); //1564610400000
    
    return date;
  }


  onSubmitSaaS(value) {
    this.submitted = true;

    //on reinitialise
    this.conflictDate = false;
    
    //console.log(this.demandeForm.controls.prenomPm.value, value);

    let completionDate = this.editFormSaaS.controls.expectedCompletionDate.value;
    let evaluationDate = this.editFormSaaS.controls.expectedEvaluationDate.value;
    
     //on vérifie que la date de completion attendue est antérieur à la date d'évaluation
      if (completionDate >= evaluationDate) {
        this.conflictDate = true;
      }

    //si formulaire incomplet
    if (this.editFormSaaS.invalid || this.conflictDate === true) {
      
      this.toastr.warning('Error fields', 'Check out the required fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      }); //texte,titre,temps

      //SI formulaire complet
    } else {
    
    this.service.updateDemandeSaaS(this.item.id, value).then(res => {
      this.router.navigate(['/homeUser']); //rediriger vers le tableau des demandes (solutions)
      this.toastr.success('Updated successfuly', '', {
        timeOut: 7000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-center'
      });
    });
    }
    
    
  }
  
  onSubmitIaaS(value) {
    this.submitted = true;

    //si formulaire incomplet
    if (this.editFormIaaS.invalid) {
      
      this.toastr.warning('Error fields', 'Check out the required fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      }); //texte,titre,temps

      //SI formulaire complet
    } else {
    
    this.service.updateDemandeIaaS(this.item.id, value).then(res => {
      this.router.navigate(['/homeUser']); //rediriger vers le tableau des demandes (solutions)
      this.toastr.success('Updated successfuly', '', {
        timeOut: 7000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-center'
      });
    });
    }
    
    
  }

  //recup le noms des domaines et le place dans une liste
  fetchDomaine() {
    this.serviceDemande.getDomains().subscribe(actionArray => {
      this.listDomains = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Domains;
      });
    });
  }
}
