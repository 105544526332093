import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { getUniqueId } from 'app/core/helpers';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {
  tabId = getUniqueId();
  private uid: string = '';

  constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase) {}
  
  getTabId() {
    return this.tabId;
  }
  
  getPresence(uid: string) {
    return this.db.object(`status/${uid}`).valueChanges();
  }

  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }
  
  async setPresence(uid: string, status: string) {
    const user = await this.getUser();
    this.uid = uid;
    if (user) {
      this.db.database.ref(`status/${uid}`).get()
      .then((resp) => {
        const data = resp.val();
        const tabs = data?.tabs || [];
        const currentTabIndex = tabs.findIndex((element) => element.tabId === this.tabId);
        
        if(currentTabIndex !== -1) {
          tabs[currentTabIndex] = { tabId: this.tabId, timestamp: this.timestamp };
        } else {
          tabs.push({ tabId: this.tabId, timestamp: this.timestamp });
        }

        this.db.object(`status/${user.uid}`).update({ 
          status: tabs.length > 1 ? 'confirming' : 'confirmed', 
          timestamp: this.timestamp, 
          tabId: tabs.length > 1 ? '' : this.tabId, 
          tabs
        });
        
      }, (errorObject) => {
        console.log('The read failed: ' + errorObject.name);
      });
    }
  }
  
  // Confirm user tab choice
  async confirmPresence(uid: string, tabId: string) {
    const user = await this.getUser();
    if (user) {
      
      this.db.database.ref(`status/${uid}`).get()
      .then((resp) => {
        const data = resp.val();
        const tabs = data?.tabs || [];
        
        const confirmedTabs = tabs.map(element => {
          if (element['tabId'] !== tabId) {
            element['action'] = 'quit';
          }
          return element;
        })

        this.db.object(`status/${user.uid}`).update({ 
          status: 'confirmed', 
          timestamp: this.timestamp, 
          tabId: tabs.length > 1 ? '' : this.tabId, 
          tabs: confirmedTabs
        });
        
      }, (errorObject) => {
        console.log('The read failed: ' + errorObject.name);
      });
      // this.db.object(`status/${user.uid}`).update({ status: 'confirmed' , tabId });
    }
  }
  
  // Clean all other tab that have an action
  async cleanPresence(uid: string) {
    const user = await this.getUser();
    if (user) {
      
      this.db.database.ref(`status/${uid}`).get()
      .then((resp) => {
        const data = resp.val();
        const tabs = data?.tabs || [];
        
        const confirmedTabs = tabs.filter(element => !element['action']);

        this.db.object(`status/${user.uid}`).update({ 
          status: 'confirmed', 
          timestamp: this.timestamp, 
          tabId: tabs.length > 1 ? '' : this.tabId, 
          tabs: confirmedTabs
        });
        
      }, (errorObject) => {
        console.log('The read failed: ' + errorObject.name);
      });
    }
  } 

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  // Updates status when logged-in connection to Firebase starts
 updateOnUser() {
   
    const connection = this.db.object('.info/connected').valueChanges().pipe(
      map(connected => connected ? 'online' : 'offline')
    );

    return this.afAuth.authState.pipe(
      switchMap(user =>  user ? connection : of('offline')),
      tap(status => this.setPresence(this.uid, status!))
    );
  }
  
  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap(user => {
        if (user) {
          this.db.object(`status/${user!.uid}`).query.ref.onDisconnect()
            .update({
              status: 'offline',
              timestamp: this.timestamp
          });
        }
      })
    );
  }
  
  updateOnAway() {
    document.onvisibilitychange = (e) => {

      if (document.visibilityState === 'hidden') {
        this.setPresence(this.uid, 'away');
      } else {
        this.setPresence(this.uid, 'online');
      }
    };
  }

  async signOut() {
      await this.setPresence(this.uid, 'offline');
  }
}