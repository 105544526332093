import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HomeUserService } from '../homeUser.service';

@Injectable()
export class EditDemandeDlaResolver implements Resolve<any> {
  constructor(public firebaseService: HomeUserService) {}

  //on récupère l'id de la demande selectionnée pour ensuite avoir ses données associées et les placer dans le formulaire
  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      const solutionId = route.paramMap.get('id');
      this.firebaseService.getDemandeBySolutionId(solutionId).subscribe(data => {
        resolve(data);
      });
    });
  }
}
