<div class="container">
  <mat-card>
    <mat-card-content>
      <h3 translate>Formulaire de demande d'évaluation</h3>
      <br />
      
      <!--Informations du user + Q0_ABC-->
      <!--0-->
      <form [formGroup]="demandeForm" #form="ngForm" id="formulaire" (ngSubmit)="onSubmitQ0(demandeForm.value)">
        <div class="container-form">
          
        <!--Infos sur le demandeur-->
        <mat-card-title id="titre_form" translate>Vos informations</mat-card-title>
          <div *ngIf="(auth.user | async) as user" class="infoUser">
            
            <mat-form-field>
              <mat-label translate>Email</mat-label>
              <input
                matInput
                formControlName="emailInitiatorVeolia"
                readonly
              />
            </mat-form-field>

            <mat-form-field style="display: none">
              <input
                matInput
                placeholder="{{'Email' |translate}}"
                formControlName="solutionOwner"
                 readonly
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label translate>Full name</mat-label>
              <input
                matInput
                formControlName="fullNameInitiatorVeolia"
                readonly
              />
            </mat-form-field>

            <mat-form-field style="display: none">
              <input
                matInput
                placeholder="{{'identifiant' |translate}}"
                formControlName="uidInitiatorVeolia"
                
              />
            </mat-form-field>
            
            <mat-form-field>
              <mat-label translate>bu</mat-label>
              <input
                matInput
                value="{{ user?.businessUnit}} "
                readonly
              />
            </mat-form-field>
            
            <mat-form-field style="display: none">
              <input
                matInput
                placeholder="{{'bu' |translate}}"
                formControlName="businessUnitOfSolution"
              />
            </mat-form-field>
          </div>

          <br />
          <hr>
          
          <mat-card-title id="titre_form" translate>Informations sur la demande</mat-card-title>
          
            <!-------------------------------Infos type---------------------------------------->
            <!--section principale -->
            <mat-card-title id="titre_form2" translate>Main details</mat-card-title>
            
            <!--Questions avant le choix du type-->
            <mat-label translate class="radio-title">A. {{'Question A' | translate}}</mat-label>
            <mat-radio-group class="radio-group" formControlName="Q0_A">
              <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
              <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
              <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
            <span class="text-danger" *ngIf="(f0.Q0_A.touched || submitted) && f0.Q0_A.errors?.required">
              <em style="font-size: small;">One value required</em>
            </span>
            </mat-radio-group>
            
            <mat-label translate class="radio-title">B. {{'Question B' | translate}}</mat-label>
            <mat-radio-group class="radio-group" formControlName="Q0_B">
              <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
              <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
              <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
            <span class="text-danger" *ngIf="(f0.Q0_B.touched || submitted) && f0.Q0_B.errors?.required">
              <em style="font-size: small;">One value required</em>
            </span>
            </mat-radio-group>
            
            <mat-label translate class="radio-title">C. {{'Question C' | translate}}</mat-label>
            <mat-radio-group class="radio-group" formControlName="Q0_C">
              <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
              <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
              <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
            <span class="text-danger" *ngIf="(f0.Q0_C.touched || submitted) && f0.Q0_C.errors?.required">
              <em style="font-size: small;">One value required</em>
            </span>
            </mat-radio-group>
            
            <br />
            
          <div class="example-button-row">
            <button mat-raised-button color="primary" style="background: #00aec7;" type="submit">{{ 'Continuer' | translate }}</button>
          </div>
          
          <br />
          
          <!--Affichage en fonction des Q0-->
            <div style="color: red">
              <strong id="PaaS or IaaS type 1" style="display: none" translate>PaaS or IaaS type 1</strong>
              <strong id="PaaS or IaaS type 2" style="display: none" translate>PaaS or IaaS type 2</strong>
              <strong id="PaaS or IaaS type 3" style="display: none" translate>PaaS or IaaS type 3</strong>
              <strong id="PaaS or IaaS type 4" style="display: none" translate>PaaS or IaaS type 4</strong>
              <strong id="PaaS or IaaS type 5" style="display: none" translate>PaaS or IaaS type 5</strong>
              <strong id="SaaS type 1" style="display: none" translate>SaaS type 1</strong>
              <strong id="SaaS type 2" style="display: none" translate>SaaS type 2</strong>
              <strong id="SaaS type 3" style="display: none" translate>SaaS type 3</strong>
              <strong id="SaaS type 4" style="display: none" translate>SaaS type 4</strong>
              <strong id="SaaS type 5" style="display: none" translate>SaaS type 5</strong>
              <strong id="SaaS type 6" style="display: none" translate>SaaS type 6</strong>
              <strong id="SaaS type 7" style="display: none" translate>SaaS type 7</strong>
              <strong id="Apps type 1" style="display: none" translate>Apps type 1</strong>
              <strong id="Apps type 2" style="display: none" translate>Apps type 2</strong>
              <strong id="Apps type 3" style="display: none" translate>Apps type 3</strong>
              <strong id="Idk type 1" style="display: none" translate>Idk type 1</strong>
              <strong id="Idk type 2" style="display: none" translate>Idk type 2</strong>
              <strong id="Idk type 3" style="display: none" translate>Idk type 3</strong>
            </div>
        
        </div>
      </form>
      <hr>
      
      <!--Type form-->
      <form [formGroup]="demandeTypeForm" #form="ngForm" id="formulaireType" style="display: none">
        <div class="container-form">
          <div id="typeSol">
            <mat-label translate class="radio-title">Solution type</mat-label>
            <mat-radio-group class="radio-group" formControlName="type">
              <mat-radio-button class="radio-button" value="SaaS">SaaS</mat-radio-button>
              <mat-radio-button class="radio-button" value="IaaS">IaaS</mat-radio-button>
              <mat-radio-button class="radio-button" value="PaaS" disabled>PaaS</mat-radio-button>
              <mat-radio-button class="radio-button" value="Apps/Extensions/Add-ons" disabled>Apps/Extensions/Add-ons</mat-radio-button>
            </mat-radio-group>
            <span class="text-danger" *ngIf="(ftype.type.touched || submitted) && ftype.type.errors?.required">
              <em style="font-size: small;">One value required</em>
            </span>
        </div>
        </div>
      </form>
      
      <hr>
                              <!--Affichage en fonction du type de solution-->
      
      <!--SaaS-->
      <div *ngIf="demandeTypeForm.controls.type.value === 'SaaS'; then saasType"><!--<ng-template ici--></div>
      <ng-template #saasType>
        <form [formGroup]="demandeFormSaas" (ngSubmit)="onSubmitSaas(demandeFormSaas.value)" #form="ngForm" id="formulaire">
          <div class="container-form">
                
                <!--Description process-->
                  <section style="text-align: left">
                  <mat-card>
                    <p style="color: red">Once your request is created, the process is the following:</p>
                    <ul>
                      <li>1-Your request is pending approval by DLA team 
                        <ol>The forms are not yet available</ol>
                        <ol>The vendor is not yet notified</ol>
                        <ol>You can still edit your request informations</ol>
                      </li>
                      <li>2-Your request has been validated by DLA team 
                        <ol>As the Solution Owner, you are notified</ol>
                        <ol>The vendor's account is created and access is granted</ol>
                        <ol>The vendor must complete the forms (Design/Legal/Article 32) and allow Veolia to scan the solution</ol>
                      </li>
                      <p>As the Solution Owner, you must revive the vendor if the request remains incomplete (cf. <a href="https://docs.google.com/presentation/d/1kqMltFibPvQjwLI2bcVVdQAQ2w7Stf-MIw78wsZUkOI/edit?pli=1#slide=id.g85055ddc33_0_1700" target="_blank" rel="noopener"> DLAaaS Solution owner guide</a>)</p>
                    </ul>
                  </mat-card>
                </section>
                <br />
                
                <h4 translate style="color: #00aec7">Step1</h4>
                
                <mat-form-field appearance="outline">
                  <mat-label translate>Nom du RFP</mat-label>
                  <input matInput placeholder="{{'Nom du RFP' | translate}}" formControlName="rfpName" />
                    <span
                        class="text-danger"
                        *ngIf="demandeFormSaas.controls.nbSolution.value > 1 && (f.rfpName.touched || submitted) && f.rfpName.errors?.required"
                      >
                        <em style="font-size: small;">RFP required</em>
                      </span>
                      <span class="text-danger"
                          *ngIf="f.rfpName.hasError('minlength')"
                          [translate]="'minLengthErrorText'" 
                          [translateParams]="{length: f.rfpName.errors.minlength.requiredLength}"
                          >
                      </span>
                      <span class="text-danger"
                          *ngIf="f.rfpName.hasError('maxlength')"
                          [translate]="'maxLengthErrorText'" 
                          [translateParams]="{length: f.rfpName.errors.maxlength.requiredLength}"
                          >
                      </span>
                    </mat-form-field>
                  
                    <mat-form-field appearance="outline">
                    <mat-label translate>Domain</mat-label>
                    <mat-select
                      formControlName="domainOfSolution"
                    >
                      <mat-option selected value=""></mat-option>
                      <mat-option *ngFor="let domain of listDomains" value="{{ domain.domainName }}" translate> {{ domain.domainName }} </mat-option>
                    </mat-select>
                    <span
                      class="text-danger"
                      *ngIf="(f.domainOfSolution.touched || submitted) && f.domainOfSolution.errors?.required"
                    >
                      <em style="font-size: small;">Domain required</em>
                    </span>
                    <div style="text-align: left">
                    <span><em>Info: DOMAIN -> Business capability</em></span>
                    <br>
                    <span><a target="_blank" rel="noopener" href="https://docs.google.com/spreadsheets/d/1GmhGkvDij4reoV0GL9r3PJLEJutSOi4iovac-L9b71o/edit#gid=1430216237">Business Capabilities definitions</a></span>
                  </div>
                  </mat-form-field>
                  
                  <mat-form-field appearance="outline">
                    <mat-label translate>Pouvez-vous décrire le besoin ?</mat-label>
                    <textarea
                      matInput
                      placeholder="{{'Pouvez-vous décrire le besoin ?' | translate}}"
                      formControlName="description"
                      rows="2"
                    >
                    </textarea>
                    <span class="text-danger" *ngIf="(f.description.touched || submitted) && f.description.errors?.required">
                      <em style="font-size: small;">Description required</em>
                    </span>
                  </mat-form-field>
        
                    <mat-label translate class="radio-title">Who will access the solution ?</mat-label>
                      <span style="display: flex;" class="text-danger" *ngIf="(f.accessQuestion.touched || submitted) && f.accessQuestion.errors?.required">
                        <em style="font-size: small;">Answer required</em>
                      </span>
                    <mat-selection-list formControlName="accessQuestion" class="checkbox-group" required>
                    <mat-list-option checkboxPosition="before" class="font-size-checkbox" *ngFor="let val of accessQuestion" [value]="val">
                      {{val}}
                    </mat-list-option>
                    </mat-selection-list>
            
                    <br />
                    
                    <mat-label translate class="radio-title">Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?</mat-label>
                    <mat-radio-group class="radio-group" formControlName="integrateQuestion">
                      <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                      <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
                    <span class="text-danger" *ngIf="(f.integrateQuestion.touched || submitted) && f.integrateQuestion.errors?.required">
                      <em style="font-size: small;">One value required</em>
                    </span>
                    </mat-radio-group>
                    
                    <br />
                    
                    <mat-label translate class="radio-title">Do you know if the solution will have to interconnect with 3rd party applications ?</mat-label>
                      <span style="display: flex;" class="text-danger" *ngIf="(f.interconnectQuestion.touched || submitted) && f.interconnectQuestion.errors?.required">
                        <em style="font-size: small;">Answer required</em>
                      </span>
                    <mat-selection-list formControlName="interconnectQuestion" class="checkbox-group" required>
                    <mat-list-option checkboxPosition="before" class="font-size-checkbox" *ngFor="let val of interconnectQuestion" [value]="val">
                      {{val}}
                    </mat-list-option>
                    </mat-selection-list>
            
                    <br />
                    
                    <mat-form-field appearance="outline">
                    <mat-label translate>Please give more details about interconnection with 3rd party applications</mat-label>
                    <input matInput placeholder="{{'Please give more details about interconnection with 3rd party applications' | translate}}" formControlName="interconnectQuestion2" />
                    <span
                        class="text-danger"
                        *ngIf="(f.interconnectQuestion2.touched || submitted) && f.interconnectQuestion2.errors?.required"
                      >
                        <em style="font-size: small;">Answer required</em>
                      </span>
                    </mat-form-field>
          
                    <mat-label translate class="radio-title">Communication with 3rd parties</mat-label>
                    <span style="display: flex;" class="text-danger" *ngIf="(f.communicationQuestion.touched || submitted) && f.communicationQuestion.errors?.required">
                        <em style="font-size: small;">Answer required</em>
                    </span>
                    <mat-selection-list formControlName="communicationQuestion" class="checkbox-group">
                    <mat-list-option checkboxPosition="before" class="font-size-checkbox" *ngFor="let val of communicationQuestion" [value]="val">
                      {{val}}
                    </mat-list-option>
                    </mat-selection-list>
                    
                    <br />
                    
                    <mat-form-field appearance="outline">
                      <mat-label translate>Date completion attendue</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker3"
                        placeholder="{{'Date completion attendue' | translate}}"
                        formControlName="expectedCompletionDate"
                        id="completionDate"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                      <mat-datepicker #picker3></mat-datepicker>
                      <span
                        class="text-danger"
                        *ngIf="(f.expectedCompletionDate.touched || submitted || conflictDate === true) && f.expectedCompletionDate.errors?.required"
                      >
                        <em style="font-size: small;">Date required</em>
                      </span>
                      <br />
                      <span class="text-danger" *ngIf="conflictDate === true">
                        <em style="font-size: small;">Expected completion date should be earlier than expected evaluation date</em>
                      </span>
                    </mat-form-field>
          
                    <mat-form-field appearance="outline">
                      <mat-label translate>Date eval attendue</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        placeholder="{{'Date eval attendue' | translate}}"
                        formControlName="expectedEvaluationDate"
                        
                        id="evaluationDate"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <span
                        class="text-danger"
                        *ngIf="(f.expectedEvaluationDate.touched || submitted || conflictDate === true) && f.expectedEvaluationDate.errors?.required"
                      >
                        <em style="font-size: small;">Date required</em>
                      </span>
                      <br />
                      <span class="text-danger" *ngIf="conflictDate === true">
                        <em style="font-size: small;">Expected completion date should be earlier than expected evaluation date</em>
                      </span>
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                      <mat-label translate>expectedOnlineReleaseDate</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker2"
                        placeholder="{{'expectedOnlineReleaseDate' | translate}}"
                        formControlName="expectedOnlineReleaseDate"
                      />
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                      <span class="text-danger" *ngIf="(f.expectedOnlineReleaseDate.touched || submitted) && f.expectedOnlineReleaseDate.errors?.required">
                        <em style="font-size: small;">Date required</em>
                      </span>
                    </mat-form-field>
                    
                    <!--Increment auto-->
                    <mat-label translate class="radio-title">Nombre de solution à évaluer</mat-label>
                    <mat-form-field appearance="outline">
                      <input style="width: 65px"
                        matInput
                        formControlName="nbSolution"
                        readonly
                      />
                      <span class="text-danger" *ngIf="(f.nbSolution.touched || submitted) && (f.nbSolution.errors?.required || f.nbSolution.errors?.min)">
                          <em style="font-size: small;">You have to add at least one solution</em>
                        </span>
                      </mat-form-field>
                      
                      <div class="example-button-row" id="addSolBtn">
                    <button (click)="addNewSolution();" mat-raised-button color="primary" style="background: #00aec7; float: left" type="button">Add <span class="material-icons">plus_one </span> solution</button>
                      </div>
                      
                    <br />
                    <br />
                    <br />
                  
                  <!--SECTION dynamique en fonction du nombre de solution ajoutées pour un RFP-->
                  <div formArrayName="solutionSection">
                  <h4 translate style="color: #00aec7">Step2</h4>
                  <mat-card  *ngFor="let sol of solutionSection.controls; let i = index">
                  <ng-container [formGroupName]="i">
                    <mat-card-content>
                    
                    <div style="float:right;" class="example-button-row">
                  <button (click)="removeSolution(i)" mat-raised-button color="warn" type="button">Remove solution {{i+1}}</button>
                    </div>
                    <br />
                    
                    <div class="text-center border border-light p-5">
                      <mat-card-title id="titre_form2">{{'The solution' | translate}} {{i+1}}</mat-card-title>
                      
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Nom de la solution à évaluer</mat-label>
                          <input
                            matInput
                            placeholder="{{'Nom de la solution à évaluer' |translate}}"
                            formControlName="solutionName"
                            id="solution_{{i+1}}"
                          />
                          <span class="text-danger" *ngIf="(solutionSection.controls[i].get('solutionName').touched || submitted) && solutionSection.controls[i].get('solutionName').errors?.required">
                            <em style="font-size: small;">Solution name required</em>
                          </span>
                          <small class="text-danger"
                          *ngIf="solutionSection.controls[i].get('solutionName').hasError('minlength')" 
                          [translate]="'minLengthErrorText'" 
                          [translateParams]="{length: solutionSection.controls[i].get('solutionName').errors.minlength.requiredLength}">
                          </small>
                          <small class="text-danger"
                              *ngIf="solutionSection.controls[i].get('solutionName').hasError('maxlength')"
                              [translate]="'maxLengthErrorText'" 
                              [translateParams]="{length: solutionSection.controls[i].get('solutionName').errors.maxlength.requiredLength}"
                              >
                          
                          </small>
                          
                        </mat-form-field>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Nom editeur</mat-label>
                          <input
                            matInput
                            placeholder="{{'Nom editeur' |translate}}"
                            formControlName="editorBrand"
                          />
                          <span class="text-danger" *ngIf="(solutionSection.controls[i].get('editorBrand').touched || submitted) && solutionSection.controls[i].get('editorBrand').errors?.required">
                            <em style="font-size: small;">Vendor brand required</em>
                          </span>
                        </mat-form-field>
              
                        <mat-label translate class="radio-title">Solution is already in use / DLA renewal ?</mat-label>
                        <mat-radio-group class="radio-group" formControlName="inPlace">
                          <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
                          <span class="text-danger" *ngIf="(solutionSection.controls[i].get('inPlace').touched || submitted) && solutionSection.controls[i].get('inPlace').errors?.required">
                            <em style="font-size: small;">One value required</em>
                          </span>
                        </mat-radio-group>
                        <br>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>If the solution is already in use, please provide its APM number (if available)</mat-label>
                          <input matInput 
                          placeholder="{{'If the solution is already in use, please provide its APM number (if available)' | translate}}" 
                          formControlName="apmNumber"/>
                          <span><a target="_blank" rel="noopener" href="https://oneintranet.veolia.com/corp-ist-norms/architecture/apm">What is APM ?</a></span>
                        </mat-form-field>
                        
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Lien app</mat-label>
                          <input
                            matInput
                            placeholder="{{'Lien app' | translate}}"
                            formControlName="link"
                          />
                        </mat-form-field>
      
                      <br />
                      
                      <mat-card-title id="titre_form2">{{'Main contact' | translate}} {{i+1}}</mat-card-title>
                      <em style="text-align: left">You will be able to add more contact afterwards</em>
                      
                       <mat-form-field style="display: block" appearance="outline"> 
                       <mat-label translate>Email editeur</mat-label>
                        <input
                          email="true"
                          matInput
                          placeholder="{{'Email editeur' |translate}}"
                          formControlName="mainEmailVendor"
                          lowercase
                        />
                        <span
                          class="text-danger"
                          *ngIf="(solutionSection.controls[i].get('mainEmailVendor').touched || submitted) && solutionSection.controls[i].get('mainEmailVendor').errors?.required"
                        >
                          <em style="font-size: small;">Valid email required</em>
                        </span>
                        <small class="text-danger"
                              *ngIf="solutionSection.controls[i].get('mainEmailVendor').hasError('lowercase')"
                              [translate]="'emailNotUppercase'" 
                              >
                          
                          </small>
                        <div><em translate>domainNameAuthorized</em></div>
                      </mat-form-field>
            
                      <mat-form-field style="display: block" appearance="outline">
                        <mat-label translate>nom</mat-label>
                        <input
                          matInput
                          placeholder="{{'nom' |translate}}"
                          id="nomVendor_{{i+1}}"
                          formControlName="mainLastnameVendor"
                        />
                        <span
                          class="text-danger"
                          *ngIf="(solutionSection.controls[i].get('mainLastnameVendor').touched || submitted) && solutionSection.controls[i].get('mainLastnameVendor').errors?.required"
                        >
                          <em style="font-size: small;">Vendor lastname required</em>
                        </span>
                      </mat-form-field>
            
                      <mat-form-field style="display: block" appearance="outline">
                        <mat-label translate>prenom</mat-label>
                        <input
                          matInput
                          placeholder="{{'prenom' |translate}}"
                          formControlName="mainFirstnameVendor"
                        />
                        <span
                          class="text-danger"
                          *ngIf="(solutionSection.controls[i].get('mainFirstnameVendor').touched || submitted) && solutionSection.controls[i].get('mainFirstnameVendor').errors?.required"
                        >
                          <em style="font-size: small;">Vendor firstname required</em>
                        </span>
                      </mat-form-field>
            
                      <mat-form-field style="display: block" appearance="outline">
                        <mat-label translate>entreprise</mat-label>
                        <input
                          matInput
                          placeholder="{{'entreprise' |translate}}"
                          id="entrepriseVendor_{{i+1}}"
                          formControlName="mainCompanyVendor"
                        />
                        <span
                          class="text-danger"
                          *ngIf="(solutionSection.controls[i].get('mainCompanyVendor').touched || submitted) && solutionSection.controls[i].get('mainCompanyVendor').errors?.required"
                        >
                          <em style="font-size: small;">Vendor company required</em>
                        </span>
                        
                      </mat-form-field>
                      
                      <mat-form-field style="display: block" appearance="outline">
                        <mat-label translate>Lien app commercial</mat-label>
                        <input
                          matInput
                          placeholder="{{'Lien app commercial' |translate}}"
                          formControlName="linkCom"
                          lowercase
                          url-clean
                        />
                        <span><p>Make sure to enter only domain name like: <em><strong>example.com</strong></em> and not <em><strong>https://www.example.com/</strong></em></p></span>
                         <small class="text-danger"
                              *ngIf="solutionSection.controls[i].get('linkCom').hasError('blockedUrl')"
                              [translate]="'blockedUrl'" 
                              >
                           </small>
                      </mat-form-field>
                      
                    </div>
                    
                    </mat-card-content>
                  </ng-container>
                  </mat-card>
                  </div><!-- END solutionSection -->
                  
                  <br />
                  <br />
            
                  <div class="example-button-row">
                    <button mat-raised-button color="primary" style="background: #00aec7;" type="submit">{{ 'Générer' | translate }}</button>
                  </div>
                
            </div>
        </form>
      </ng-template>
      <!--END SAAS-->
      
      <!--IaaS-->
      <div *ngIf="demandeTypeForm.controls.type.value === 'IaaS'; then iaasType"><!--<ng-template ici--></div>
      <ng-template #iaasType>
        <form [formGroup]="demandeFormIaas" (ngSubmit)="onSubmitIaas(demandeFormIaas.value)" #form="ngForm" id="formulaire">
          <div class="container-form">
            
                  <br />
                  
                  <em>
                    <strong>IaaS is not part of Veolia Strategy</strong> and is only a transitional phase for lift&shift. SaaS solution must be favoured.
                  </em>
                  <br />
                  
                  <!--Description process-->
                  <section style="text-align: left">
                    <mat-card>
                      <p style="color: red">Once your request is created, the process is the following:</p>
                      <ul>
                        <li>1-Your request is pending approval by DLA team 
                          <ol>You can still edit your request informations</ol>
                        </li>
                        <li>2-Your request has been validated by DLA team 
                          <ol>You are notified</ol>
                          <ol>You have to complete the IaaS form generated</ol>
                        </li>
                      </ul>
                    </mat-card>
                  </section>
                  <br />
                  
                  <mat-label translate class="radio-title">q0a_iaas</mat-label>
                  <mat-radio-group class="radio-group" formControlName="q0a_iaas">
                    <mat-radio-button class="radio-button" value="Yes">Yes</mat-radio-button>
                    <mat-radio-button class="radio-button" value="No">No</mat-radio-button>
                    <span class="text-danger" *ngIf="(f1.q0a_iaas.touched || submitted) && f1.q0a_iaas.errors?.required">
                      <em style="font-size: small;">One value required</em>
                    </span>
                </mat-radio-group>
                
                  <mat-label translate class="radio-title">q0b_iaas</mat-label>
                  <mat-radio-group class="radio-group" formControlName="q0b_iaas">
                    <mat-radio-button class="radio-button" value="Yes">Yes</mat-radio-button>
                    <mat-radio-button class="radio-button" value="No">No</mat-radio-button>
                    <span class="text-danger" *ngIf="(f1.q0b_iaas.touched || submitted) && f1.q0b_iaas.errors?.required">
                      <em style="font-size: small;">One value required</em>
                    </span>
                </mat-radio-group>
                
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>Nom de la solution à évaluer</mat-label>
                  <input
                    matInput
                    placeholder="{{'Nom de la solution à évaluer' |translate}}"
                    formControlName="solutionName"
                    id="solution"
                  />
                  <span class="text-danger" *ngIf="(f1.solutionName.touched || submitted) && f1.solutionName.errors?.required">
                    <em style="font-size: small;">Solution name required</em>
                  </span>
                </mat-form-field>
                        
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>Nom editeur</mat-label>
                  <input
                    matInput
                    placeholder="{{'Nom editeur' |translate}}"
                    formControlName="editorBrand"
                  />
                  <span class="text-danger" *ngIf="(f1.editorBrand.touched || submitted) && f1.editorBrand.errors?.required">
                    <em style="font-size: small;">Vendor brand required</em>
                  </span>
                </mat-form-field>
                        
                <mat-form-field appearance="outline">
                  <mat-label translate>Can you describe your need ?</mat-label>
                    <textarea
                      matInput
                      placeholder="{{'Pouvez-vous décrire le besoin ?' | translate}}"
                      formControlName="description"
                      rows="2"
                    >
                    </textarea>
                    <span class="text-danger" *ngIf="(f1.description.touched || submitted) && f1.description.errors?.required">
                      <em style="font-size: small;">Description required</em>
                    </span>
                </mat-form-field>
      
                <mat-form-field appearance="outline">
                    <mat-label translate>Domain</mat-label>
                    <mat-select
                      formControlName="domainOfSolution"
                    >
                      <mat-option selected value=""></mat-option>
                      <mat-option *ngFor="let domain of listDomains" value="{{ domain.domainName }}" translate> {{ domain.domainName }} </mat-option>
                    </mat-select>
                    <span
                      class="text-danger"
                      *ngIf="(f1.domainOfSolution.touched || submitted) && f1.domainOfSolution.errors?.required"
                    >
                      <em style="font-size: small;">Domain required</em>
                    </span>
                    <div style="text-align: left">
                    <span><em>Info: DOMAIN -> Business capability</em></span>
                    <br>
                    <span><a target="_blank" rel="noopener" href="https://docs.google.com/spreadsheets/d/1GmhGkvDij4reoV0GL9r3PJLEJutSOi4iovac-L9b71o/edit#gid=1430216237">Business Capabilities definitions</a></span>
                  </div>
                  </mat-form-field>
                
                <mat-label translate class="radio-title">Solution is already in use / DLA renewal ?</mat-label>
                        <mat-radio-group class="radio-group" formControlName="inPlace">
                          <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
                          <span class="text-danger" *ngIf="(f1.inPlace.touched || submitted) && f1.inPlace.errors?.required">
                            <em style="font-size: small;">One value required</em>
                          </span>
                        </mat-radio-group>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>If the solution is already in use, please provide its APM number (if available)</mat-label>
                          <input matInput 
                          placeholder="{{'If the solution is already in use, please provide its APM number (if available)' | translate}}" 
                          formControlName="apmNumber" 
                          />
                          <span><a target="_blank" rel="noopener" href="https://oneintranet.veolia.com/corp-ist-norms/architecture/apm">What is APM ?</a></span>
                        </mat-form-field>
                        
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Vendor website url (if it exists)</mat-label>
                          <input
                            matInput
                            placeholder="{{'Vendor website url (if it exists)' | translate}}"
                            formControlName="link"
                          />
                        </mat-form-field>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Additionnal comments</mat-label>
                          <input
                            matInput
                            placeholder="{{'Additionnal comments' | translate}}"
                            formControlName="comment"
                          />
                        </mat-form-field>
                        
                        <br />
                  <br />
                  
                  <div class="example-button-row">
                    <button mat-raised-button color="primary" style="background: #00aec7;" type="submit">{{ 'Générer' | translate }}</button>
                  </div>
                  
              </div>
        </form>
      </ng-template>
      <!--END IAAS-->
      
      <!--PaaS-->
      <div *ngIf="demandeTypeForm.controls.type.value === 'PaaS'; then paasType"><!--<ng-template ici--></div>
      <ng-template #paasType>
        <form [formGroup]="demandeFormPaas" (ngSubmit)="onSubmitPaas(demandeFormPaas.value)" #form="ngForm" id="formulaire">
          <div class="container-form">
            
                  <br />
                  
                  <!--Description process-->
                  <section style="text-align: left">
                    <mat-card>
                      <p style="color: red">Once your request is created, the process is the following:</p>
                      <ul>
                        <li>1-Your request is pending approval by DLA team 
                          <ol>You can still edit your request informations</ol>
                        </li>
                        <li>2-Your request has been validated by DLA team 
                          <ol>You are notified</ol>
                          <ol>You have to complete the forms generated</ol>
                        </li>
                      </ul>
                    </mat-card>
                  </section>
                  <br />
                
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>Nom de la solution à évaluer</mat-label>
                  <input
                    matInput
                    placeholder="{{'Nom de la solution à évaluer' |translate}}"
                    formControlName="solutionName"
                    id="solution"
                  />
                  <span class="text-danger" *ngIf="(f2.solutionName.touched || submitted) && f2.solutionName.errors?.required">
                    <em style="font-size: small;">Solution name required</em>
                  </span>
                </mat-form-field>
                        
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>Qui a développé la solution</mat-label>
                  <input
                    matInput
                    placeholder="{{'Qui a développé la solution' |translate}}"
                    formControlName="developedBy"
                  />
                  <span class="text-danger" *ngIf="(f2.developedBy.touched || submitted) && f2.developedBy.errors?.required">
                    <em style="font-size: small;">Developer required</em>
                  </span>
                </mat-form-field>
                        
                <mat-form-field appearance="outline">
                  <mat-label translate>Can you describe your need ?</mat-label>
                    <textarea
                      matInput
                      placeholder="{{'Pouvez-vous décrire le besoin ?' | translate}}"
                      formControlName="description"
                      rows="2"
                    >
                    </textarea>
                    <span class="text-danger" *ngIf="(f2.description.touched || submitted) && f2.description.errors?.required">
                      <em style="font-size: small;">Description required</em>
                    </span>
                </mat-form-field>
      
                <mat-form-field appearance="outline">
                    <mat-label translate>Domain</mat-label>
                    <mat-select
                      formControlName="domainOfSolution"
                    >
                      <mat-option selected value=""></mat-option>
                      <mat-option *ngFor="let domain of listDomains" value="{{ domain.domainName }}" translate> {{ domain.domainName }} </mat-option>
                    </mat-select>
                    <span
                      class="text-danger"
                      *ngIf="(f2.domainOfSolution.touched || submitted) && f2.domainOfSolution.errors?.required"
                    >
                      <em style="font-size: small;">Domain required</em>
                    </span>
                    <div style="text-align: left">
                    <span><em>Info: DOMAIN -> Business capability</em></span>
                    <br>
                    <span><a target="_blank" rel="noopener" href="https://docs.google.com/spreadsheets/d/1GmhGkvDij4reoV0GL9r3PJLEJutSOi4iovac-L9b71o/edit#gid=1430216237">Business Capabilities definitions</a></span>
                  </div>
                  </mat-form-field>
                
                <mat-label translate class="radio-title">Solution is already in use / DLA renewal ?</mat-label>
                        <mat-radio-group class="radio-group" formControlName="inPlace">
                          <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
                          <span class="text-danger" *ngIf="(f2.inPlace.touched || submitted) && f2.inPlace.errors?.required">
                            <em style="font-size: small;">One value required</em>
                          </span>
                        </mat-radio-group>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>If the solution is already in use, please provide its APM number (if available)</mat-label>
                          <input matInput 
                          placeholder="{{'If the solution is already in use, please provide its APM number (if available)' | translate}}" 
                          formControlName="apmNumber" 
                          />
                          <span><a target="_blank" rel="noopener" href="https://oneintranet.veolia.com/corp-ist-norms/architecture/apm">What is APM ?</a></span>
                        </mat-form-field>
                        
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Website url (if it exists)</mat-label>
                          <input
                            matInput
                            placeholder="{{'Website url (if it exists)' | translate}}"
                            formControlName="link"
                          />
                        </mat-form-field>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Additionnal comments</mat-label>
                          <input
                            matInput
                            placeholder="{{'Additionnal comments' | translate}}"
                            formControlName="comment"
                          />
                        </mat-form-field>
                        
                        <br />
                  <br />
                  
                  <div class="example-button-row">
                    <button mat-raised-button color="primary" style="background: #00aec7;" type="submit">{{ 'Générer' | translate }}</button>
                  </div>
                  
              </div>
        </form>
      </ng-template>
      <!--END PAAS-->
              
      <!--Apps/Extensions/Add-ons-->
      <div *ngIf="demandeTypeForm.controls.type.value === 'Apps/Extensions/Add-ons'; then appsType"><!--<ng-template ici--></div>
      <ng-template #appsType>
        <form [formGroup]="demandeFormApps" (ngSubmit)="onSubmitApps(demandeFormApps.value)" #form="ngForm" id="formulaire">
          <div class="container-form">
            
                  <br />
                  
                  <!--Description process-->
                  <section style="text-align: left">
                    <mat-card>
                      <p style="color: red">Once your request is created, the process is the following:</p>
                      <ul>
                        <li>1-Your request is pending approval by DLA team 
                          <ol>You can still edit your request informations</ol>
                        </li>
                        <li>2-Your request has been validated by DLA team 
                          <ol>You are notified</ol>
                          <ol>You have to complete the forms generated</ol>
                        </li>
                      </ul>
                    </mat-card>
                  </section>
                  <br />
                
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>App name</mat-label>
                  <input
                    matInput
                    placeholder="{{'App name' |translate}}"
                    formControlName="applicationName"
                    id="solution"
                  />
                  <span class="text-danger" *ngIf="(f3.applicationName.touched || submitted) && f3.applicationName.errors?.required">
                    <em style="font-size: small;">Application name/extension or add-on required</em>
                  </span>
                </mat-form-field>
                
                <mat-form-field style="display: block" appearance="outline">
                  <mat-label translate>Nom editeur</mat-label>
                  <input
                    matInput
                    placeholder="{{'Nom editeur' |translate}}"
                    formControlName="editorBrand"
                  />
                  <span class="text-danger" *ngIf="(f3.editorBrand.touched || submitted) && f3.editorBrand.errors?.required">
                    <em style="font-size: small;">Vendor brand required</em>
                  </span>
                </mat-form-field>
                        
                <mat-label translate class="radio-title">Is the app, extension or add-on already used (in Veolia Play Store…) ?</mat-label>
                        <mat-radio-group class="radio-group" formControlName="inUse">
                          <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
                          <span class="text-danger" *ngIf="(f3.inUse.touched || submitted) && f3.inUse.errors?.required">
                            <em style="font-size: small;">One value required</em>
                          </span>
                        </mat-radio-group>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Additionnal comments ?</mat-label>
                          <input matInput 
                          placeholder="{{'Additionnal comments ?' | translate}}" 
                          formControlName="inUse_more" 
                          />
                        </mat-form-field>
                        
                        
                        <mat-label translate class="radio-title">Is the app, extension or add-on linked to a SaaS / PaaS or IaaS solution ?</mat-label>
                        <mat-radio-group class="radio-group" formControlName="linkedTo">
                          <mat-radio-button class="radio-button" value="Yes">{{ 'Oui' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="No">{{ 'Non' | translate }}</mat-radio-button>
                          <mat-radio-button class="radio-button" value="I don't know">{{ 'Idk' | translate }}</mat-radio-button>
                          <span class="text-danger" *ngIf="(f3.linkedTo.touched || submitted) && f3.linkedTo.errors?.required">
                            <em style="font-size: small;">One value required</em>
                          </span>
                        </mat-radio-group>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Additionnal comments ?</mat-label>
                          <input matInput 
                          placeholder="{{'Additionnal comments ?' | translate}}" 
                          formControlName="linkedTo_more" 
                          />
                        </mat-form-field>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Who will use the application, the extension or the add-on ?</mat-label>
                          <input
                            matInput
                            placeholder="{{'Who will use the application, the extension or the add-on ?' | translate}}"
                            formControlName="usedBy"
                          />
                          <span class="text-danger" *ngIf="(f3.usedBy.touched || submitted) && f3.usedBy.errors?.required">
                            <em style="font-size: small;">One value required</em>
                          </span>
                        </mat-form-field>
                        
                        <mat-form-field style="display: block" appearance="outline">
                          <mat-label translate>Link to the app, extension or add-on</mat-label>
                          <input
                            matInput
                            placeholder="{{'Link to the app, extension or add-on' | translate}}"
                            formControlName="link"
                          />
                        </mat-form-field>
                        
                        <br />
                  <br />
                  
                  <div class="example-button-row">
                    <button mat-raised-button color="primary" style="background: #00aec7;" type="submit">{{ 'Générer' | translate }}</button>
                  </div>
                  
              </div>
        </form>
      </ng-template>
      <!--END Apps/Extensions/Add-ons-->
              
    </mat-card-content>
  </mat-card>
</div>
