import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { ToastrService } from 'ngx-toastr';
import { Demande } from '../homeUser/demandeDla/demande.model';
import { AuthService } from '../core/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { dictToURI } from '../../app/core/helpers';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  tabName: string;
  uidUser: string;
  

  //SSC  
  companyData: string
  
  fire = firebase.firestore();
  
  constructor(private httpClient: HttpClient, private firestore: AngularFirestore, private toastr: ToastrService, private authService: AuthService, private router: Router) {}

  //get le tab actif appelé depuis le ts
  getTabName(currTab: string) {
    console.log(currTab);
    this.tabName = currTab;

    return this.tabName;
  }
  
  getUidUser(id: string){
    this.uidUser = id;
  }
  
  //SSC
  getAllSSCdata() {
    return this.firestore.collection('securityScoreCard').snapshotChanges();
  }
  
  //SaaS
  getAllSaaSRequest() {
    return this.firestore.collection('solutions', ref => ref.where('status', '!=', 'Pending').where('type', '==', 'SaaS')).snapshotChanges();
  }
  
  getAllSaaSEvaluatedRequest() {
    return this.firestore.collection('solutions', ref => ref.where('fullyEvaluated', '==', true).where('type', '==', 'SaaS')).snapshotChanges();
  }
  
  getAllSaaSPendingRequest() {
    return this.firestore.collection('solutions', ref => ref.where('status', '==', 'Pending').where('type', '==', 'SaaS')).snapshotChanges();
  }
  //END SaaS
  
  //IaaS
  getAllIaaSRequest() {
    return this.firestore.collection('solutions', ref => ref.where('status', '!=', 'Pending').where('type', '==', 'IaaS')).snapshotChanges();
  }
  
  getAllIaaSEvaluatedRequest() {
    return this.firestore.collection('solutions', ref => ref.where('fullyEvaluated', '==', true).where('type', '==', 'IaaS')).snapshotChanges();
  }
  
  getAllIaaSPendingRequest() {
    return this.firestore.collection('solutions', ref => ref.where('status', '==', 'Pending').where('type', '==', 'IaaS')).snapshotChanges();
  }
  //END IaaS
  
  getAllUsersVoelia() {
    return this.firestore.collection('users', ref => ref.where('isVeolia', '==', true)).snapshotChanges();
  }
  
  getAllUsersVendorEnabled() {
    return this.firestore.collection('users', ref => ref.where('isVeolia', '==', false).where('disable', '==', false)).snapshotChanges();
  }
  
  getAllUsersVendorDisabled() {
    return this.firestore.collection('users', ref => ref.where('isVeolia', '==', false).where('disable', '==', true)).snapshotChanges();
  }
  
  //SOLUTION NOT OK
  cancelSol(id: string, type: string) {
  
    //All
    if (confirm('Do you really want to cancel this request')) {
      //this.firestore.doc('solutions/' + id).update({ status: 'Canceled' });
      
      const fire = firebase.firestore();
      
      fire.collection('solutions').doc(id).set({status: 'Canceled', cancellationDate: new Date(Date.now())}, { merge: true })
    
        fire.collection('solutions').doc(id).get()
          .then(doc => {

        //SaaS
        if(type === 'SaaS') {
          this.toastr.info('All stakeholders have been notified', 'Cancelled successfully', {
            timeOut: 6000,
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-center'
          });
        
        //envoi au vendor + DLA + SO
          this.sendSolutionCanceledSaaS(this.authService.currentUserEmail, this.authService.currentUserDisplayName, doc.data().solutionName, doc.data().rfpName, doc.data().editorBrand, doc.data().businessUnitOfSolution, this.convertDate(doc.data().requestDate), doc.data().solutionOwner, doc.data().emailVendorAuthorized).subscribe(data => {
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
          });
          
        }
        
        //IaaS PaaS Apps
        if(type !== 'SaaS') {
          this.toastr.info('All stakeholders have been notified', 'Cancelled successfully', {
            timeOut: 6000,
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-center'
          });
          
        //envoi à DLA + SO
          this.sendSolutionCanceled(this.authService.currentUserEmail, this.authService.currentUserDisplayName, doc.data().solutionName, doc.data().editorBrand, doc.data().businessUnitOfSolution, this.convertDate(doc.data().requestDate), doc.data().solutionOwner, type).subscribe(data => {
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
          });
          
        }
        
        //PaaS
        if(type === 'PaaS') {
          //empty
        }
        
        //Apps
        if(type === 'Apps') {
          //empty
        }
      
      this.router.navigate(['/admin'])
    });
    
    }
      
  }
  
  //SaaS
  sendSolutionCanceledSaaS(initiatorEmail, initiatorDisplayName, solutionName, rfpName, editorBrand, buOfSolution, requestDate, solOwner, vendorEmail){
    const query = dictToURI({
      emailVeolia: initiatorEmail,
      displayNameVeolia: initiatorDisplayName,
      solName: solutionName,
      rfpName,
      editorBrand,
      bu: buOfSolution,
      requestDate,
      emailSolOwner: solOwner,
      emailvendor: vendorEmail
    });
    return this.httpClient.get('/api/solution_cancelled_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
  //IaaS PaaS Apps
  sendSolutionCanceled(initiatorEmail, initiatorDisplayName, solutionName, editorBrand, buOfSolution, requestDate, solOwner, type){
   const query = dictToURI({
      emailVeolia: initiatorEmail,
      displayNameVeolia: initiatorDisplayName,
      solName: solutionName,
      editorBrand: editorBrand,
      bu: buOfSolution,
      requestDate, 
      emailSolOwner: solOwner,
      type
    });
    return this.httpClient.get('/api/solution_cancelled?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  
  //uncancel solution, la rendre de nouveau dispo
  uncancel(id: string) {
    if (confirm('Do you really want to undo this canceled solution')) {
      this.firestore.doc('solutions/' + id).update({ status: 'Answering' });
      this.toastr.info('Undo done', 'Solution restored and accessible', {
        timeOut: 6000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-center'
      });
      
      const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${id}`);
    
    demandeRef.get().subscribe(snap => {
    });
    
    }
    
    this.router.navigate(['/admin'])
  }
  
  
  /////////////FIREBASE//////////////
  
  //modifie juste le champ "disable"=true pour le user mais ne desactive pas le compte directement
  disableAccountFirebase(id: string){
    this.firestore.doc('users/' + id).update({ disable: true });
  }
  
  //desactive le compte onelogin => state = 3: Unlicensed donc plus de possibilité de se connecter à Onelogin et donc à DLA
  disableAccountOnelogin(email: string) {
    this.httpClient.get('/api/disable_user?email='+email, {responseType:'text'}).subscribe(
      res => console.log('HTTP response API:', res),
      err => console.log('HTTP Error', err),
      () => console.log('HTTP request completed. VENDOR ONELOGIN DISABLE')
      );
  }
  
  //modifie juste le champ "disable"=false pour le user mais ne touche pas le compte directement
  enableAccountFirebase(id: string){
    this.firestore.doc('users/' + id).update({ disable: false });
  }
  
  //desactive le compte onelogin => state = 1: Approved donc possibilité de se connecter à Onelogin et donc à DLA
  enableAccountOnelogin(email: string) {
    this.httpClient.get('/api/enable_user?email='+email, {responseType:'text'}).subscribe(
      res => console.log('HTTP response API:', res),
      err => console.log('HTTP Error', err),
      () => console.log('HTTP request completed. VENDOR ONELOGIN ENABLE')
      );
  }

  /////////////FIREBASE//////////////
  
  //Update des rôles du user appelé depuis admin.ts
  updateRoles(id, value) {
    let newRole;

    if (JSON.parse(value.admin) === true) {
      newRole = 'ADMIN';
    }

    if (
      JSON.parse(value.evaluator) === true &&
      JSON.parse(value.viewer) === true &&
      JSON.parse(value.admin) === false
    ) {
      newRole = 'Evaluator';
    }

    if (JSON.parse(value.evaluator) === true && JSON.parse(value.admin) === true) {
      newRole = 'ADMIN';
    }

    if (
      JSON.parse(value.admin) === false &&
      JSON.parse(value.viewer) === true &&
      JSON.parse(value.evaluator) === false
    ) {
      newRole = 'Viewer';
    }

    if (JSON.parse(value.vendor) === true) {
      newRole = 'Vendor';
    }

    if (
      JSON.parse(value.admin) === false &&
      JSON.parse(value.viewer) === true &&
      JSON.parse(value.evaluator) === false &&
      JSON.parse(value.solutionOwner) === true
    ) {
      newRole = 'Solution Owner';
    }

    this.firestore.doc('users/' + id).update({
      businessUnit: value.businessUnit,
      role: newRole,
      roles: {
        admin: JSON.parse(value.admin),
        evaluator: JSON.parse(value.evaluator),
        vendor: JSON.parse(value.vendor),
        viewer: JSON.parse(value.viewer),
        solutionOwner: JSON.parse(value.solutionOwner)
      }
    });
    this.toastr.success('Update applied', '', {
      timeOut: 6000,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-center'
    });
  }
  
  async updateBUEvaluators(id, email) {
    this.firestore.collection('businessUnit').doc(id).update({
      evaluators: firebase.firestore.FieldValue.arrayUnion(email)
    })
  }
  
  async removeFromBUEvaluators(id, email) {
    this.firestore.collection('businessUnit').doc(id).update({
      evaluators: firebase.firestore.FieldValue.arrayRemove(email)
    })
  }
  
  public handleErrorHttp(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }
    
  convertDate(timestamp) {
    if (timestamp) {
      //de base this.timestamp
      let date = new Date(new Date(null).getTime());
      let myDate = timestamp.toString();
      let seondsTimestamp = myDate.substring(18, myDate.indexOf(',')); //ON prend que les seconds de Timestamp(seconds=1564610400,nanoseconds=0)

      date.setTime(seondsTimestamp * 1000);
      //console.log(date); //Thu Aug 01 2019 00:00:00 GMT+0200 (heure d’été d’Europe centrale) bon format à insérer dans le champ
      //console.log(date.getTime()); //1564610400000
      console.log(date)
      return date;
    } else {
      console.log('aucune date encore connue');
    }
  }
  
  ///////////SSC/////////////
  //2.1 Add company metadata in firestore securityScoreCard collection
  addCompanyToFirestore(idSol, solName, linkCom, companyData) {
  //check si la company a déjà été ajoutée dans firestore
  const sscRef = this.fire.collection('securityScoreCard').doc(idSol);
  sscRef.get().then((doc) => {
    if (doc.exists) {
      console.log("securityScoreCard data existing:", doc.data());
      
      this.firestore.collection('securityScoreCard').doc(idSol).update({  
        idSolution: idSol,
        solutionName: solName,
        link: linkCom,
        name: companyData.name || null,
        industry: companyData.industry || null,
        domain: companyData.domain || null,
        score: companyData.score || null,
        grade: companyData.grade || null,
        grade_url: companyData.grade_url || 'https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/help_outline_black_24dp.svg?alt=media&token=a95a241e-3a51-4a36-a3b1-3931f52c8435',
        last30day_score_change: companyData.last30day_score_change || null,
        //firstAddDate: doc.data().firstAddDate, //reste inchangé
        lastUpdateDate: new Date(Date.now())
      }).then(res => {
          console.log("SSC data updated into securityScoreCard collection")
        
        //3 remove company from Veolia's Portfolio
        this.removeCompanyFromPortfolioSSC(linkCom).subscribe();
        
      }).catch(err => {
        console.log('Error service creating request', err);
      });
        
    } else {
      // doc.data() will be undefined in this case
      console.log("No securityScoreCard data");
      
      this.firestore.collection('securityScoreCard').doc(idSol).set({
        idSolution: idSol,
        solutionName: solName,
        link: linkCom,
        name: companyData.name || null,
        industry: companyData.industry || null,
        domain: companyData.domain || null,
        score: companyData.score || null,
        grade: companyData.grade || null,
        grade_url: companyData.grade_url || 'https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/help_outline_black_24dp.svg?alt=media&token=a95a241e-3a51-4a36-a3b1-3931f52c8435',
        last30day_score_change: companyData.last30day_score_change || null,
        firstAddDate: new Date(Date.now()),
        lastUpdateDate: new Date(Date.now())
      }).then(res => {
          console.log("SSC data added into securityScoreCard collection")
        
        //3 remove company from Veolia's Portfolio
        this.removeCompanyFromPortfolioSSC(linkCom).subscribe();
        
      }).catch(err => {
          console.log('Error service creating request', err);
        });
    }
    
  }).catch((error) => {
      console.log("Error getting document:", error);
    });

  }
    
    //3 remove company from Veolia's Portfolio
  removeCompanyFromPortfolioSSC(linkCom) {
    const query = dictToURI({
      link: linkCom
    });
    return this.httpClient.get('/api/remove_company_from_portfolio?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
    
}
