import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { AngularFireAction, AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';

//pdf
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { HomeUserService } from '../../homeUser/homeUser.service';
import { FormulaireAllService } from '../formulairesAccess/formulaireAll/formulaireAll.service';
import { AuthService } from '../../core/auth.service';
import { Evaluation } from '../formulairesAccess/formulaireAll/evaluation.model';
import { UploadEval } from '../formulairesAccess/formulaireAll/uploadEval.model';

@Component({
  selector: 'app-summary-evaluation',
  templateUrl: './summary-evaluation.component.html',
  styleUrls: ['./summary-evaluation.component.scss']
})
export class SummaryEvaluationComponent implements OnInit {
  currentEvaluation: Evaluation[];
  currentEvaluationList: AngularFireList<any>;

  fileList: UploadEval[];
  files$: Observable<AngularFireAction<firebase.database.DataSnapshot>[]>;
  uploadsForEval: AngularFireList<UploadEval[]>;

  currentKey: string;

  lastUpdateDesign: Date;
  lastUpdateByDesign: string;
  lastUpdateLegal: Date;
  lastUpdateByLegal: string;
  lastUpdateArticle32: Date;
  lastUpdateByArticle32: string;
  lastUpdateArticle28: Date;
  lastUpdateByArticle28: string;
  createdAt: Date; //même pour les 4 forms
  
  lastUpdateIaaS: Date;
  lastUpdateByIaaS: string;
  lastUpdatePaaS: Date;
  lastUpdateByPaaS: string;
  lastUpdateApps: Date;
  lastUpdateByApps: string;
  
  type: string;
  
  tabName;

  collectionName;
  
  solName;
  
  download= false;

  constructor(
    public service: FormulaireAllService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private evaluation: Evaluation,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    public auth: AuthService,
    public homeUserServ: HomeUserService,
    private afs: AngularFireDatabase
  ) {}

  ngOnInit() {
    //get type
    this.route.params.subscribe((params: Params) => this.type = params['type']);
    console.log('type',this.type)
    
    if(this.type === 'SaaS') {
      const fire = firebase.firestore();
    //Recup les infos du formulaire: Partie Form datastore db
    const formRef = fire.collection('designForm').doc(this.service.idSolution);
    formRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          this.lastUpdateDesign = this.convertDate(doc.data().lastUpdate) || null;
          this.lastUpdateByDesign = doc.data().lastUpdateBy;
          this.createdAt = this.convertDate(doc.data().createdAt);
          this.solName = doc.data().solutionName;
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

    const formRef2 = fire.collection('legalForm').doc(this.service.idSolution);
    formRef2
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          this.lastUpdateLegal = this.convertDate(doc.data().lastUpdate) || null;
          this.lastUpdateByLegal = doc.data().lastUpdateBy;
          this.createdAt = this.convertDate(doc.data().createdAt);
          this.solName = doc.data().solutionName;
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
      
      const formRef32 = fire.collection('article32Form').doc(this.service.idSolution);
    formRef32
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          this.lastUpdateArticle32 = this.convertDate(doc.data().lastUpdate) || null;
          this.lastUpdateByArticle32 = doc.data().lastUpdateBy;
          this.createdAt = this.convertDate(doc.data().createdAt);
          this.solName = doc.data().solutionName;
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
      
      const formRef4 = fire.collection('article28Form').doc(this.service.idSolution);
    formRef4
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          this.lastUpdateArticle28 = this.convertDate(doc.data().lastUpdate) || null;
          this.lastUpdateByArticle28 = doc.data().lastUpdateBy;
          this.createdAt = this.convertDate(doc.data().createdAt);
          this.solName = doc.data().solutionName;
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
    }
    
    if(this.type === 'IaaS') {
      const fire = firebase.firestore();
    //Recup les infos du formulaire: Partie Form datastore db
    const formRef = fire.collection('iaasForm').doc(this.service.idSolution);
    formRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          this.lastUpdateIaaS = this.convertDate(doc.data().lastUpdate) || null;
          this.lastUpdateByIaaS = doc.data().lastUpdateBy;
          this.createdAt = this.convertDate(doc.data().createdAt);
          this.solName = doc.data().solutionName;
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
    }
    
    if(this.type === 'PaaS') {
      //empty
    }
    
    if(this.type === 'Apps') {
      //empty
    }
  }

  //Recup les données de l'évaluation à l'ouverture de la modale
  getEvaluation(form: string) {
    this.tabName = form;

    this.fetchEvaluation(this.service.idSolution + '-' + form, form)
      .snapshotChanges()
      .subscribe(ok => {
        this.currentEvaluation = [];

        //Si aucune évaluation faite
        if (ok.length === 0) {
          console.log('NO EVAL');
          this.currentKey = null; //va servir pour saveEval
        }

        //Si évaluation présente
        else {
          console.log('OK EVAL');
          ok.forEach(element => {
            const x = element.payload.toJSON();
            x['$key'] = element.key;
            this.currentEvaluation.push(x as Evaluation);
            //console.log("id: " + element.key + "posted BY: " + element.payload.val().postedBy)
            const user = this.afAuth.authState.pipe(first()).toPromise();
            if (user) {
              firebase
                .database()
                .ref('evaluations/' + form + '/' + this.service.idSolution + '/' + element.key)
                .once('value')
                .then(function(snapshot) {
                  //console.log(x)//C'est tout le doc avec ses données
                  console.log('Eval en cours ' + element.key);
                  return element.key;
                });
              this.currentKey = element.key; //va servir pour saveEval

              //Pour précomplété les champs lorsqu'il y a déjà eu une eval
              this.service.selectedEvaluation = Object.assign({}, x);
            }
          });
        }
      });
  }

  //recup les fichiers de l'évaluation à l'ouverture de la modale
  getFilesForEvaluation(form: string) {
    this.fecthFilesForEvaluation(this.service.idSolution + '-' + form, form)
      .snapshotChanges()
      .subscribe(ok => {
        this.fileList = [];
        ok.forEach(element => {
          const x = element.payload.toJSON();
          x['$key'] = element.key;
          this.fileList.push(x as UploadEval);
          //console.log("id: " + element.key + "posted BY: " + element.payload.val().postedBy)
        });
      });
  }

  fetchEvaluation(idrefeval: string, form: string) {
    return (this.currentEvaluationList = this.afs.list('evaluations/' + form + '/' + this.service.idSolution, ref =>
      ref.orderByChild('idRefEval').equalTo(idrefeval)
    ));
  }

  //Lors du clic sur la modale pour l'évalution totale par le ts
  fecthFilesForEvaluation(idrefeval: string, form: string) {
    console.log('dans le getFilesForEvaluation()');
    return (this.uploadsForEval = this.afs.list('evaluations/files/' + form + '/' + this.service.idSolution, ref =>
      ref.orderByChild('idRefEval').equalTo(idrefeval)
    ));
  }

  //au clic du bouton pour accéder au pdf
  generatePdf() {
    this.toastr.success('Download in progress...', '', {
      timeOut: 4000,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-center'
    });
    
    this.download = true;
  
    this.delay(1500).then(any => { 
      const fullEval = document.getElementById('fullEvalForPdf').innerHTML; //tous les chapitres et sous chapitres + status/comment eval final
  
      let finalVar = htmlToPdfmake(fullEval);
  
      let documentDefinition = { 
            footer: function (pagenumber, pagecount) {
              return {
                  alignment: 'right', margin: [0, 10, 10, 0],
                  text: pagenumber + '/' + pagecount
              };
            },
            content: finalVar 
      };
    
    if(this.tabName === 'Article 28 Form') { //Annexes
      pdfMake.createPdf(documentDefinition).download('Evaluation-' + this.solName + '-Annexes.pdf');  
    } else {
      pdfMake.createPdf(documentDefinition).download('Evaluation-' + this.solName + '-'+ this.tabName + '.pdf');
    }
    
      this.download = false;
    });
  }
  
   async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  //Juste pour convertir en timestamp
  convertDate(timestamp) {
    if(timestamp) {
      //de base this.timestamp
      let date = new Date(new Date(null).getTime());
      let myDate = timestamp.toString();
      let seondsTimestamp = myDate.substring(18, myDate.indexOf(',')); //ON prend que les seconds de Timestamp(seconds=1564610400,nanoseconds=0)
  
      date.setTime(seondsTimestamp * 1000);
      return date;
      
    } else {
        console.log('Pas de date')
      }
    
  }
  
}
