import { v4 as uuidv4 } from 'uuid';

export const dictToURI = (dict: {[key: string]: string}) => {
  var str = [];
  for(var p in dict){
     str.push(encodeURIComponent(p) + "=" + encodeURIComponent(dict[p]));
  }
  return str.join("&");
}


export function getUniqueId() {

    let value = window.sessionStorage.getItem('unique-id');

    if(!value || !window.name) {
        value = uuidv4();
        window.sessionStorage.setItem('unique-id', value);
    }

    window.name = value;
    return value;
}

function pickRandomProperty(obj) {
  let result;
  const props = Object.keys(obj);
  const randomValues = new Uint32Array(1); // Using a typed array for random index

  if (window.crypto && window.crypto.getRandomValues) {
    window.crypto.getRandomValues(randomValues);
  } else {
    throw new Error('crypto.getRandomValues() not supported by this browser.');
  }

  const randomIndex = randomValues[0] % props.length;
  result = props[randomIndex];

  return result;
}


export function materialColor() {
  // colors from https://github.com/egoist/color-lib/blob/master/color.json
  var colors = {
      "red": {
        "700": "#d32f2f",
        "800": "#c62828",
        "900": "#b71c1c",
        "hex": "#f44336",
        "a100": "#ff8a80",
        "a200": "#ff5252",
        "a400": "#ff1744",
        "a700": "#d50000"
      },
      "pink": {
        "700": "#c2185b",
        "800": "#ad1457",
        "900": "#880e4f",
        "hex": "#e91e63",
        "a100": "#ff80ab",
        "a200": "#ff4081",
        "a400": "#f50057",
        "a700": "#c51162"
      },
      "purple": {
        "700": "#7b1fa2",
        "800": "#6a1b9a",
        "900": "#4a148c",
        "hex": "#9c27b0",
        "a100": "#ea80fc",
        "a200": "#e040fb",
        "a400": "#d500f9",
        "a700": "#aa00ff"
      },
      "deepPurple": {
        "700": "#512da8",
        "800": "#4527a0",
        "900": "#311b92",
        "hex": "#673ab7",
        "a100": "#b388ff",
        "a200": "#7c4dff",
        "a400": "#651fff",
        "a700": "#6200ea"
      },
      "indigo": {
        "700": "#303f9f",
        "800": "#283593",
        "900": "#1a237e",
        "hex": "#3f51b5",
        "a100": "#8c9eff",
        "a200": "#536dfe",
        "a400": "#3d5afe",
        "a700": "#304ffe"
      },
      "blue": {
        "700": "#1976d2",
        "800": "#1565c0",
        "900": "#0d47a1",
        "hex": "#2196f3",
        "a100": "#82b1ff",
        "a200": "#448aff",
        "a400": "#2979ff",
        "a700": "#2962ff"
      },
      "lightBlue": {
        "700": "#0288d1",
        "800": "#0277bd",
        "900": "#01579b",
        "hex": "#03a9f4",
        "a100": "#80d8ff",
        "a200": "#40c4ff",
        "a400": "#00b0ff",
        "a700": "#0091ea"
      },
      "cyan": {
        "700": "#0097a7",
        "800": "#00838f",
        "900": "#006064",
        "hex": "#00bcd4",
        "a100": "#84ffff",
        "a200": "#18ffff",
        "a400": "#00e5ff",
        "a700": "#00b8d4"
      },
      "teal": {
        "700": "#00796b",
        "800": "#00695c",
        "900": "#004d40",
        "hex": "#009688",
        "a100": "#a7ffeb",
        "a200": "#64ffda",
        "a400": "#1de9b6",
        "a700": "#00bfa5"
      },
      "green": {
        "700": "#388e3c",
        "800": "#2e7d32",
        "900": "#1b5e20",
        "hex": "#4caf50",
        "a100": "#b9f6ca",
        "a200": "#69f0ae",
        "a400": "#00e676",
        "a700": "#00c853"
      },
      "lightGreen": {
        "700": "#689f38",
        "800": "#558b2f",
        "900": "#33691e",
        "hex": "#8bc34a",
        "a100": "#ccff90",
        "a200": "#b2ff59",
        "a400": "#76ff03",
        "a700": "#64dd17"
      },
      "lime": {
        "700": "#afb42b",
        "800": "#9e9d24",
        "900": "#827717",
        "hex": "#cddc39",
        "a100": "#f4ff81",
        "a200": "#eeff41",
        "a400": "#c6ff00",
        "a700": "#aeea00"
      },
      "amber": {
        "700": "#ffa000",
        "800": "#ff8f00",
        "900": "#ff6f00",
        "hex": "#ffc107",
        "a100": "#ffe57f",
        "a200": "#ffd740",
        "a400": "#ffc400",
        "a700": "#ffab00"
      },
      "orange": {
        "700": "#f57c00",
        "800": "#ef6c00",
        "900": "#e65100",
        "hex": "#ff9800",
        "a100": "#ffd180",
        "a200": "#ffab40",
        "a400": "#ff9100",
        "a700": "#ff6d00"
      },
      "deepOrange": {
        "700": "#e64a19",
        "800": "#d84315",
        "900": "#bf360c",
        "hex": "#ff5722",
        "a100": "#ff9e80",
        "a200": "#ff6e40",
        "a400": "#ff3d00",
        "a700": "#dd2c00"
      },
      "brown": {
        "700": "#5d4037",
        "800": "#4e342e",
        "900": "#3e2723",
        "hex": "#795548"
      },
      "grey": {
        "700": "#616161",
        "800": "#424242",
        "900": "#212121",
        "hex": "#9e9e9e"
      },
      "blueGrey": {
        "700": "#455a64",
        "800": "#37474f",
        "900": "#263238",
        "hex": "#607d8b"
      },
    }
    // pick random property
    //var property = pickRandomProperty(colors);
    var colorList = colors[pickRandomProperty(colors)];
    var newColorKey = pickRandomProperty(colorList);
    var newColor = colorList[newColorKey];
    return newColor;
}

export const IsFiveMinutesAgo = (timestamp: number): boolean => {
  return timestamp + 300000 < new Date().getTime();
}
