export class Comment {
  $key: string; //automatique
  idSolution: string; //pré complété dans le formulaireAll.service
  formIs: string; //pré complété dans le formulaireAll.service
  reference: string; //pré complété
  postedAt: string; //Date + heure du post du commentaire dans le formulaireAll.service
  userId: string; //Pré complété dans le formulaireAll.service
  postedBy: string; //pré complété dans le formulaireAll.service
  content: string; //dans le formulaireAll.html
  idRef: string;
  solutionName: string;
  taggedUser: string;
  //active: boolean = true; //Pré complété
}
