export class CompleteChapterSubChapter {
  $key?: string; //automatique
  idSolution?: string; //pré complété dans le formulaireAll.service
  formIs?: string; //pré complété dans le formulaireAll.service
  reference?: string; //pré complété
  addedAt?: string; //Date + heure du post de le completion dans le formulaireAll.service
  userId?: string; //Pré complété dans le formulaireAll.service
  addedBy?: string; //pré complété dans le formulaireAll.service
  comment?: string; 
  idRef?: string;
  solutionName?: string;
  status?: string; //completed ou not completed
}
