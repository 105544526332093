import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase/compat/app';

import { Router, Params, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';


import { HomeUserService } from '../../homeUser/homeUser.service';
import { FormulaireAllService } from '../formulairesAccess/formulaireAll/formulaireAll.service';
import { AuthService } from '../../core/auth.service';
import { Reservation } from '../formulairesAccess/formulaireAll/reservation.model';


@Component({
  selector: 'app-reservations-evaluation',
  templateUrl: './reservations-evaluation.component.html',
  styleUrls: ['./reservations-evaluation.component.scss']
})
export class ReservationsEvaluationComponent implements OnInit {
  idSol: string

  reservationsList: Reservation[];

  currentReservationKey: string;

  formName: string;

  reservationByChapterIsPresent: string[];

  reservationForm: FormGroup;

  resKey: string;

  click = false;

  isSoOfSolution = false; //on vérifie si le user est solution owner de la solution

  userIsInEmailEvaluatorAuthorized = false;

  fire = firebase.firestore();

  businessUnitOfSolution: string;
  evaluatorsBu: string[] = [];

  constructor(
    public service: FormulaireAllService,
    private toastr: ToastrService,
    private afAuth: AngularFireAuth,
    public auth: AuthService,
    public homeUserServ: HomeUserService,
    private afs: AngularFireDatabase,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    //get type
    this.route.params.subscribe((params: Params) => this.idSol = params['id']);

    this.checkUserIsSo();

    //Cas ou il n'est ni ADMIN ni evaluator
    //check si user a été ajouté dans emailEvaluatorAuthorized de la solution (il n'a pas forcément le rôle 'evaluator')
          firebase.firestore().collection('solutions').doc(this.service.idSolution).get().then(val2 => {
            if(!val2.data().emailEvaluatorAuthorized.find(x => x === this.auth.currentUserEmail)){

              this.userIsInEmailEvaluatorAuthorized = false;
            } else {
              this.userIsInEmailEvaluatorAuthorized = true;
            }
          });

    const demandeRef = this.fire.collection('solutions').doc(this.idSol);
    demandeRef
      .get()
      .then(doc => {
          //console.log('Document data:', doc.data());
        this.businessUnitOfSolution = doc.data().businessUnitOfSolution;

        //GET evaluators from 'businessUnit' collection matching with businessUnitOfSolution from 'solutions'
        firebase.firestore()
        .collection('businessUnit')
        .where('nomBu', '==', this.businessUnitOfSolution)
        .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              this.evaluatorsBu = doc.data().evaluators //GET evaluators for the BU matching with Bu of solution
              console.log("Evaluators for this solution:",this.evaluatorsBu)
            });
          });
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

  }


  //On vérifie les chapitres qui possèdent une reservation
  checkOutChaptersWithReservation(form) {
    this.formName = form;
    this.reservationsList = [];
    let i = 0;

    this.service
      .getAllReservations(form)
      .snapshotChanges()
      .subscribe(ok => {
        this.reservationByChapterIsPresent = [];
        ok.forEach(element => {
          console.log('Chaptitres contenant une reservation: ' + element.key);

          this.reservationByChapterIsPresent.push(element.key);

            //pour colorer les boutons en fonction des datas
            firebase.database().ref('reservations/' + this.formName + '/' + this.service.idSolution + '/' + element.key)
              .on('value', (snapshot) => {
                snapshot.forEach(v => {

                this.delay(1000).then(any => {
                  //console.log("HERE " + element.key + " content " + v.val().content);
                  if(v.val().fixed === false && !v.val().fixedDate) {
                    document.getElementById(element.key).style.backgroundColor="red";
                  }
                  if(v.val().fixed === false && v.val().fixedDate) {
                    document.getElementById(element.key).style.backgroundColor="orange";
                  }
                  if(v.val().fixed === true) {
                    document.getElementById(element.key).style.backgroundColor="green";
                    document.getElementById("icon"+i).style.display = 'none'

                  }

                  i++; //pour icone

                })

              });

              });


        });
      });
  }

   //Récupérer les reservations correspondants au clic du bouton du chap/ sous chap
  reservationByChapter() {
    this.click= false;
    this.service
      .getReservationForChapter(this.service.getIdRef(), this.formName)
      .snapshotChanges()
      .subscribe(ok => {
        this.reservationsList = [];
        ok.forEach(element => {
          const x = element.payload.toJSON();
          x['$key'] = element.key;
          this.reservationsList.push(x as Reservation);
          //console.log('id: ' + element.key + "content " + element.payload.val().content);
          this.resKey = element.key;

        });
      });
  }


  getForm(fixed, fixedDate) {
    this.click= true;

    this.reservationForm = this.fb.group({
            fixed: [fixed.toString(), Validators.required],//true ou false
            fixedDate: [fixedDate] //date facultative
    });

    this.delay(200).then(any => {
      if(fixed === false) {
        document.getElementById('fixedDateField').style.display = 'block';
      }
      if(fixed === true ) {
        document.getElementById('fixedDateField').style.display = 'none';
      }

    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }


  //copy to clipboard
   copy() {
    /* Get the text field */
    let copyText = document.getElementById("myInput");

    /* Select the text field */
    (<HTMLInputElement>copyText).select();
    (<HTMLInputElement>copyText).setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    this.toastr.success('Copied ! ', '', {
            timeOut: 2000,
            closeButton: true,
            positionClass: 'toast-bottom-right'
    });

  }

  //save de la date de correction + fixed oui ou non par vendor
  onSubmit(value, resKey, formName, idSol, ref, content, postedBy, postedAt) {
    this.service.updateFixedAndFixedDateReservation(value.fixed, value.fixedDate, new Date(Date.now()).toString(), resKey, formName, idSol, ref);

    if(value.fixed === "true") { //si reserve fixée
      const fire = firebase.firestore();

      console.log("DANS LE value.fixed === true");
    //récup soluntionName + rfpName + editorBrand + solOwner
      fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', idSol)
      .get().then(snap => {
        snap.forEach(val => {

        //SaaS
        if(formName === 'Design Form' || formName === 'Legal Form' || formName === 'Article 32 Form' || formName === 'Article 28 Form') {
        //1-envoie mail DLA + SO + evaluateur ayant emis la reserve que la reserve a été corrigée par vendor
            this.service.sendReservationFixedSaaS(formName, val.data().solutionName, val.data().rfpName, val.data().editorBrand, val.data().solutionOwner, val.data().businessUnitOfSolution, this.auth.currentUserEmail, ref, content, postedBy, postedAt, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {//postedBy = evaluateur qui a émis la reserve
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
          });

          //2-envoi email aux evaluators BU inclus dans evaluators (si liste vide ou n'existe pas alors le for n'est pas éxécuté et erreur de length)
          for(let i = 0; i < this.evaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.evaluatorsBu[i])

            this.service.sendReservationFixedSaaS(formName, val.data().solutionName, val.data().rfpName, val.data().editorBrand, null, val.data().businessUnitOfSolution, this.auth.currentUserEmail, ref, content, postedBy, postedAt, this.evaluatorsBu[i], null).subscribe(data => {//postedBy = evaluateur qui a émis la reserve
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
            });
          }
        }

        //IaaS PaaS Apps
        if(formName === 'IaaS Form' || formName === 'PaaS Form' || formName === 'Apps Form' ) {
        //envoie mail au SO + evaluateur ayant emis la reserve que la reserve a été corrigée par veolia
            this.service.sendReservationFixed(formName, val.data().solutionName, val.data().editorBrand, val.data().solutionOwner, val.data().businessUnitOfSolution, this.auth.currentUserEmail, ref, content, postedBy, postedAt).subscribe(data => {//postedBy = evaluateur qui a émis la reserve
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
          });
        }

        })
      })
    }

    if(value.fixedDate && value.fixed === "false") { //si reserve non fixée mais une date de correction est renseignée

     const fire = firebase.firestore();

     console.log("DANS LE value.fixedDate");
    //récup soluntionName + rfpName + editorBrand + solOwner
      fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', idSol)
      .get().then(snap => {
        snap.forEach(val => {

        //SaaS
        if(formName === 'Design Form' || formName === 'Legal Form' || formName === 'Article 32 Form' || formName === 'Article 28 Form') {
          //1-envoie mail au DLA + SO + evaluateur ayant emis la reserve que le vendor a ajouté une date de correction
            this.service.sendReservationDateProvidedSaaS(formName, val.data().solutionName, val.data().rfpName, val.data().editorBrand, val.data().solutionOwner, val.data().businessUnitOfSolution, this.auth.currentUserEmail, ref, content, postedBy, postedAt, value.fixedDate, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {//postedBy = evaluateur qui a émis la reserve
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
          });

          //2-envoi email aux evaluators BU inclus dans evaluators (si liste vide ou n'existe pas alors le for n'est pas éxécuté et erreur de length)
           for(let i = 0; i < this.evaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.evaluatorsBu[i])

            this.service.sendReservationDateProvidedSaaS(formName, val.data().solutionName, val.data().rfpName, val.data().editorBrand, null, val.data().businessUnitOfSolution, this.auth.currentUserEmail, ref, content, postedBy, postedAt, value.fixedDate, this.evaluatorsBu[i], null).subscribe(data => {//postedBy = evaluateur qui a émis la reserve
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
            });
           }
        }

        //IaaS PaaS Apps
        if(formName === 'IaaS Form' || formName === 'PaaS Form' || formName === 'Apps Form' ) {
          //envoie mail au SO + evaluateur ayant emis la reserve que le vendor a ajouté une date de correction
            this.service.sendReservationDateProvided(formName, val.data().solutionName, val.data().editorBrand, val.data().solutionOwner, val.data().businessUnitOfSolution, this.auth.currentUserEmail, ref, content, postedBy, postedAt, value.fixedDate).subscribe(data => {//postedBy = evaluateur qui a émis la reserve
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
          });
        }

        })
      })

    }


    this.toastr.success('Updated !', '', {
        timeOut: 3000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-center'
    });

    this.click= false;

    this.checkOutChaptersWithReservation(formName);
  }


  //Si au clic fixed = NO on affiche le champ fixedDate
    toggleDisplay() {
      document.getElementById('fixedDateField').style.display = 'block';
    }

  //Si au clic fixed = YES on cache le champ fixedDate
    toggleHide() {
      document.getElementById('fixedDateField').style.display = 'none';
    }

  checkUserIsSo(){
    const fire = firebase.firestore();
    //vérifie si le user est SO de la demande
    fire
      .collection('solutions')
      .where('solutionOwner', '==', this.auth.currentUserEmail).where(firebase.firestore.FieldPath.documentId(), '==', this.idSol)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          //si false
          console.log('The user is not SO of solution');
        } else {
          console.log('User is SO of solution');
          this.isSoOfSolution = true;
        }
      });
  }

}
