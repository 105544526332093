<div *ngIf="!currentEvaluation || currentEvaluation.length === 0; else elseBlock">
  <strong style="color:red" translate>No evaluation yet</strong>
</div>

<ng-template #elseBlock>
       <button mat-raised-button title="Click to download the evaluation" (click)="generatePdf()"
  >Download PDF of evaluation<em class="material-icons" style="margin-right: 5px"> cloud_download </em></button
>

<br />
<br />
</ng-template>

<!--Le bloc suivant ne s'affiche que s'il y a des datas-->
<div *ngFor="let object of currentEvaluation">
  
  <div id="fullEvalForPdf">
    <p *ngIf="download === true && tabName==='Article 28 Form'" style="text-align:center; font-size: 20px;">Evaluation for {{ object.solutionName }} : Annexes</p>
    <p *ngIf="download === true && tabName!=='Article 28 Form'" style="text-align:center; font-size: 20px;">Evaluation for {{ object.solutionName }} : {{ object.formIs }}</p>
    <!-- On recupère tout ce qu'il y a dans le div pour la génération du pdf -->

    <p class="currentStatus" style="color: #00aec7">
      <strong style="color: #00aec7" translate>Status of evaluation</strong>: {{ object.status }}
    </p>
    <p class="currentStatus" style="color: #00aec7">
      <strong style="color: #00aec7" translate>Final comment</strong>:
      {{ object.finalComment || 'No comment yet added by evaluator' }}
    </p>

    <p><strong>Solution:</strong> {{ object.solutionName }}</p>
    <p><strong>Type:</strong> {{ object.formIs }}</p>
    
    <p *ngIf="!createdAt"> <strong>{{ 'Formulaire créé le' | translate }}</strong>: {{ 'The vendor/PM/SO has not yet accessed the form' }}</p>
    <p *ngIf="createdAt"> <strong>{{ 'Formulaire créé le' | translate }}</strong>: {{ createdAt | date: 'MMMM d, y, h:mm a' }}</p>

    <div *ngIf="tabName === 'Design Form'">
      <p><strong translate>lastUpdate of the form</strong> {{ lastUpdateDesign | date: 'MMMM d, y, h:mm a' }}</p>
      <p><strong translate>lastUpdateBy of the form</strong> {{ lastUpdateByDesign }}</p>
    </div>
    
    <div *ngIf="tabName === 'Legal Form'">
      <p><strong translate>lastUpdate of the form</strong> {{ lastUpdateLegal | date: 'MMMM d, y, h:mm a' }}</p>
      <p><strong translate>lastUpdateBy of the form</strong> {{ lastUpdateByLegal }}</p>
    </div>
    
    <div *ngIf="tabName === 'Article 32 Form'">
      <p><strong translate>lastUpdate of the form</strong> {{ lastUpdateArticle32 | date: 'MMMM d, y, h:mm a' }}</p>
      <p><strong translate>lastUpdateBy of the form</strong> {{ lastUpdateByArticle32 }}</p>
    </div>
    
    <div *ngIf="tabName === 'Article 28 Form'">
      <p><strong translate>lastUpdate of the form</strong> {{ lastUpdateArticle28 | date: 'MMMM d, y, h:mm a' }}</p>
      <p><strong translate>lastUpdateBy of the form</strong> {{ lastUpdateByArticle28 }}</p>
    </div>
    
    <div *ngIf="tabName === 'IaaS Form'">
      <p><strong translate>lastUpdate of the form</strong> {{ lastUpdateIaaS | date: 'MMMM d, y, h:mm a' }}</p>
      <p><strong translate>lastUpdateBy of the form</strong> {{ lastUpdateByIaaS }}</p>
    </div>
    
    <p><strong translate>Evaluation made by</strong> {{ object.postedBy }}</p>
    <p><strong translate>lastUpdate of evaluation</strong> {{ object.postedAt | date: 'MMMM d, y, h:mm a' }}</p>

    <br />

    <!-- DESIGN -->
    <div *ngIf="tabName === 'Design Form'">
      <details>
        <summary translate id="DA1titre" class="titleChap">DA_1</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA1eval">{{ object.DA_1_RADIO }}</p>
            <strong>{{'Comment' | translate }}: </strong><p>
            {{ object.DA_1_RADIO_comment }}
          </p>
        </div>
        
        <hr>
        <p translate class="titleSsChap">DA_11</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_11_RADIO }}</td>
              <td>{{ object.DA_11_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">DA_12</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_12_RADIO }}</td>
              <td>{{ object.DA_12_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_13</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_13_RADIO }}</td>
              <td>{{ object.DA_13_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_14</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_14_RADIO }}</td>
              <td>{{ object.DA_14_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_150</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_150_RADIO }}</td>
              <td>{{ object.DA_150_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_15</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_15_RADIO }}</td>
              <td>{{ object.DA_15_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_16</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_16_RADIO }}</td>
              <td>{{ object.DA_16_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="DA2titre" class="titleChap">DA_2</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA2eval">{{ object.DA_2_RADIO }}</p>
            <strong>{{'Comment' | translate }}: </strong><p>
            {{ object.DA_2_RADIO_comment }}
          </p>
        </div>
        
        <hr>
        <p translate class="titleSsChap">DA_21</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_21_RADIO }}</td>
              <td>{{ object.DA_21_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_22</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_22_RADIO }}</td>
              <td>{{ object.DA_22_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_23</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_23_RADIO }}</td>
              <td>{{ object.DA_23_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_24</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_24_RADIO }}</td>
              <td>{{ object.DA_24_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_25</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_25_RADIO }}</td>
              <td>{{ object.DA_25_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />

      </details>
                
        <hr>
      <details>
        <summary translate id="DA3titre" class="titleChap">DA_3</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA3eval">{{ object.DA_3_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.DA_3_RADIO_comment }}
          </p>
        </div>
        
        <hr>
        <p translate class="titleSsChap">DA_31</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_31_RADIO }}</td>
              <td>{{ object.DA_31_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_32</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_32_RADIO }}</td>
              <td>{{ object.DA_32_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="DA4titre" class="titleChap">DA_4</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA4eval">{{ object.DA_4_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.DA_4_RADIO_comment }}
          </p>
        </div>
        
        <hr>
        <p translate class="titleSsChap">DA_41</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_41_RADIO }}</td>
              <td>{{ object.DA_41_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_42</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_42_RADIO }}</td>
              <td>{{ object.DA_42_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_43</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_43_RADIO }}</td>
              <td>{{ object.DA_43_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_44</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_44_RADIO }}</td>
              <td>{{ object.DA_44_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_45</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_45_RADIO }}</td>
              <td>{{ object.DA_45_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_46</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_46_RADIO }}</td>
              <td>{{ object.DA_46_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_47</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_47_RADIO }}</td>
              <td>{{ object.DA_47_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="DA5titre" class="titleChap">DA_5</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA5eval">{{ object.DA_5_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.DA_5_RADIO_comment }}
          </p>
        </div>
        
        <hr>
        <p translate class="titleSsChap">DA_51</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_51_RADIO }}</td>
              <td>{{ object.DA_51_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_52</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_52_RADIO }}</td>
              <td>{{ object.DA_52_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_53</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_53_RADIO }}</td>
              <td>{{ object.DA_53_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_54</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_54_RADIO }}</td>
              <td>{{ object.DA_54_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="DA6titre" class="titleChap">DA_6</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA6eval">{{ object.DA_6_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.DA_6_RADIO_comment }}
          </p>
        </div>
        
        <hr>
        <p translate class="titleSsChap">DA_61</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_61_RADIO }}</td>
              <td>{{ object.DA_61_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_62</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_62_RADIO }}</td>
              <td>{{ object.DA_62_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_63</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_63_RADIO }}</td>
              <td>{{ object.DA_63_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_64</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_64_RADIO }}</td>
              <td>{{ object.DA_64_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">DA_65</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.DA_65_RADIO }}</td>
              <td>{{ object.DA_65_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="DA7titre" class="titleChap">DA_7</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA7eval">{{ object.DA_7_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.DA_7_RADIO_comment }}
          </p>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="DA8titre" class="titleChap">DA_8</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA8eval">{{ object.DA_8_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.DA_8_RADIO_comment }}
          </p>
        </div>
        <br />
      </details>
                      
        <hr>
      <details>
        <summary translate id="DA9titre" class="titleChap">DA_9</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="DA9eval">{{ object.DA_9_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.DA_9_RADIO_comment }}
          </p>
        </div>
        <br />
      </details>
      
    </div>
    <!-- END DESIGN -->

    <!-- LEGAL -->
    <div *ngIf="tabName === 'Legal Form'">
       <details>
        <summary translate id="LA0titre" class="titleChap">LA_0</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="LA0eval">{{ object.LA_0_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.LA_0_RADIO_comment }}
          </p>
        </div>
      </details>
      
      <hr>
      
      <details>
        <summary translate id="LA1titre" class="titleChap">LA_1</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="LA1eval">{{ object.LA_1_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.LA_1_RADIO_comment }}
          </p>
        </div>
        
        <hr>
        <p translate class="titleSsChap">LA_10</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_10_RADIO }}</td>
              <td>{{ object.LA_10_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">LA_11</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_11_RADIO }}</td>
              <td>{{ object.LA_11_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_111</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_111_RADIO }}</td>
              <td>{{ object.LA_111_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_112</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_112_RADIO }}</td>
              <td>{{ object.LA_112_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_113</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_113_RADIO }}</td>
              <td>{{ object.LA_113_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_114</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_114_RADIO }}</td>
              <td>{{ object.LA_114_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_115</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_115_RADIO }}</td>
              <td>{{ object.LA_115_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_116</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_116_RADIO }}</td>
              <td>{{ object.LA_116_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">LA_12</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_12_RADIO }}</td>
              <td>{{ object.LA_12_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_121</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_121_RADIO }}</td>
              <td>{{ object.LA_121_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_122</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_122_RADIO }}</td>
              <td>{{ object.LA_122_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_123</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_123_RADIO }}</td>
              <td>{{ object.LA_123_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_124</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_124_RADIO }}</td>
              <td>{{ object.LA_124_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_125</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_125_RADIO }}</td>
              <td>{{ object.LA_125_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_126</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_126_RADIO }}</td>
              <td>{{ object.LA_126_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_127</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_127_RADIO }}</td>
              <td>{{ object.LA_127_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_128</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_128_RADIO }}</td>
              <td>{{ object.LA_128_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_129</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_129_RADIO }}</td>
              <td>{{ object.LA_129_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_1210</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_1210_RADIO }}</td>
              <td>{{ object.LA_1210_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_1211</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_1211_RADIO }}</td>
              <td>{{ object.LA_1211_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">LA_13</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_13_RADIO }}</td>
              <td>{{ object.LA_13_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_131</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_131_RADIO }}</td>
              <td>{{ object.LA_131_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_132</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_132_RADIO }}</td>
              <td>{{ object.LA_132_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">LA_14</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_14_RADIO }}</td>
              <td>{{ object.LA_14_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_141</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_141_RADIO }}</td>
              <td>{{ object.LA_141_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_142</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_142_RADIO }}</td>
              <td>{{ object.LA_142_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
        
        <hr>
        <p translate class="titleSsChap">LA_15</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_15_RADIO }}</td>
              <td>{{ object.LA_15_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_151</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_151_RADIO }}</td>
              <td>{{ object.LA_151_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_152</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_152_RADIO }}</td>
              <td>{{ object.LA_152_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_153</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_153_RADIO }}</td>
              <td>{{ object.LA_153_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="LA2titre" class="titleChap">LA_2</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="LA2eval">{{ object.LA_2_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.LA_2_RADIO_comment }}
          </p>
        </div>
                
        <hr>
        <p translate class="titleSsChap">LA_21</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_21_RADIO }}</td>
              <td>{{ object.LA_21_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_22</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_22_RADIO }}</td>
              <td>{{ object.LA_22_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_23</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_23_RADIO }}</td>
              <td>{{ object.LA_23_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_24</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_24_RADIO }}</td>
              <td>{{ object.LA_24_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_25</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_25_RADIO }}</td>
              <td>{{ object.LA_25_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_26</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_26_RADIO }}</td>
              <td>{{ object.LA_26_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="LA3titre" class="titleChap">LA_3</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="LA3eval">{{ object.LA_3_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.LA_3_RADIO_comment }}
          </p>
        </div>
                
        <hr>
        <p translate class="titleSsChap">LA_31</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_31_RADIO }}</td>
              <td>{{ object.LA_31_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_32</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_32_RADIO }}</td>
              <td>{{ object.LA_32_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_33</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_33_RADIO }}</td>
              <td>{{ object.LA_33_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_34</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_34_RADIO }}</td>
              <td>{{ object.LA_34_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                
        <hr>
      <details>
        <summary translate id="LA4titre" class="titleChap">LA_4</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="LA4eval">{{ object.LA_4_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.LA_4_RADIO_comment }}
          </p>
        </div>
                
        <hr>
        <p translate class="titleSsChap">LA_41</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_41_RADIO }}</td>
              <td>{{ object.LA_41_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_42</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_42_RADIO }}</td>
              <td>{{ object.LA_42_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_43</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_43_RADIO }}</td>
              <td>{{ object.LA_43_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
                      
        <hr>
      <details>
        <summary translate id="LA5titre" class="titleChap">LA_5</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="LA5eval">{{ object.LA_5_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.LA_5_RADIO_comment }}
          </p>
        </div>
                
        <hr>
        <p translate class="titleSsChap">LA_51</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_51_RADIO }}</td>
              <td>{{ object.LA_51_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
                
        <hr>
        <p translate class="titleSsChap">LA_52</p>
        <div>
          <table style="width:100%" aria-label="Sub-chap assessment">
            <tr class="tr">
              <th scope="col" translate>Status</th>
              <th scope="col" translate>Comment</th>
            </tr>
            <tr>
              <td>{{ object.LA_52_RADIO }}</td>
              <td>{{ object.LA_52_RADIO_comment }}</td>
            </tr>
          </table>
        </div>
        <br />
      </details>
    </div>
    <!-- END LEGAL -->
    
    <!-- ARTICLE 32 -->
    <div *ngIf="tabName === 'Article 32 Form'">
      <details>
        <summary translate id="AR32titre0" class="titleChap">AR32_0</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval0">{{ object.AR32_0_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_0_RADIO_comment }}
          </p>
        </div>
      </details>
                      
        <hr>
      <details>
        <summary translate id="AR32titre1" class="titleChap">AR32_1</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval1">{{ object.AR32_1_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_1_RADIO_comment }}
          </p>
        </div>
      </details>
                      
        <hr>
      <details>
        <summary translate id="AR32titre2" class="titleChap">AR32_2</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval2">{{ object.AR32_2_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_2_RADIO_comment }}
          </p>
        </div>
      </details>
                      
        <hr>
      <details>
        <summary translate id="AR32titre3" class="titleChap">AR32_3</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval3">{{ object.AR32_3_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_3_RADIO_comment }}
          </p>
        </div>
      </details>
                      
        <hr>
      <details>
        <summary translate id="AR32titre4" class="titleChap">AR32_4</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval4">{{ object.AR32_4_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_4_RADIO_comment }}
          </p>
        </div>
      </details>
                      
        <hr>
      <details>
        <summary translate id="AR32titre5" class="titleChap">AR32_5</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval5">{{ object.AR32_5_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_5_RADIO_comment }}
          </p>
        </div>
      </details>
                      
        <hr>
      <details>
        <summary translate id="AR32titre6" class="titleChap">AR32_6</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval6">{{ object.AR32_6_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_6_RADIO_comment }}
          </p>
        </div>
      </details>
                      
        <hr>
      <details>
        <summary translate id="AR32titre7" class="titleChap">AR32_7</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval7">{{ object.AR32_7_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_7_RADIO_comment }}
          </p>
        </div>
      </details>
                
        <hr>
      <details>
        <summary translate id="AR32titre8" class="titleChap">AR32_8</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR32eval8">{{ object.AR32_8_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR32_8_RADIO_comment }}
          </p>
        </div>
      </details>
      
    </div>
    <!-- END ARTICLE 32 -->
    
    <!-- ARTICLE 28 -->
    <div *ngIf="tabName === 'Article 28 Form'">
      <!--<strong translate style="color:#00aec7">SCC-STANDARD CONTRACTUAL CLAUSES - <em>ARTICLE 28</em></strong>-->
      <strong translate style="color:#00aec7">Annexes</strong>
      <!--<details>-->
      <!--  <summary translate id="AR28SCC1titre" class="titleChap">SCC-Article 28</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC1eval">{{ object.AR28_SCC1_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC1_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCC1ANNEXItitre" class="titleChap">SCC-Article 28 ANNEX I</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC1ANNEXIeval">{{ object.AR28_SCC1_ANNEX_1_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC1_ANNEX_1_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCC1ANNEXIItitre" class="titleChap">SCC-Article 28 ANNEX II</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC1ANNEXIIeval">{{ object.AR28_SCC1_ANNEX_2_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC1_ANNEX_2_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
      
        <hr>
      <details>
        <!--<summary translate id="AR28SCC1ANNEXIIItitre" class="titleChap">SCC-Article 28 ANNEX III</summary>-->
        <summary translate id="AR28_SCC1_ANNEX_3titre" class="titleChap">Annex A - TOMs Template</summary>
        <div>
          
            <strong> {{ 'Status' | translate }}: </strong><p id="AR28_SCC1_ANNEX_3eval">{{ object.AR28_SCC1_ANNEX_3_RADIO }}</p>
            <strong>{{ 'Comment' | translate }}: </strong><p>
            {{ object.AR28_SCC1_ANNEX_3_RADIO_comment }}
          </p>
        </div>
      </details>
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCC1ANNEXIVtitre" class="titleChap">SCC-Article 28 ANNEX IV</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC1ANNEXIVeval">{{ object.AR28_SCC1_ANNEX_4_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC1_ANNEX_4_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCC1ANNEXVtitre" class="titleChap">SCC-Article 28 ANNEX V</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC1ANNEXVeval">{{ object.AR28_SCC1_ANNEX_5_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC1_ANNEX_5_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
      <!--<br />-->
      
      <!--<strong translate style="color:#00aec7">SCC-STANDARD CONTRACTUAL CLAUSES - <em>Module 2</em></strong>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCC2titre" class="titleChap">SCC-Module 2</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC2eval">{{ object.AR28_SCC2_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC2_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCC2ANNEXItitre" class="titleChap">SCC-Module 2 ANNEX I</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC2ANNEXIeval">{{ object.AR28_SCC2_ANNEX_1_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC2_ANNEX_1_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCC2ANNEXIItitre" class="titleChap">SCC-Module 2 ANNEX II</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC2ANNEXIIeval">{{ object.AR28_SCC2_ANNEX_2_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC2_ANNEX_2_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
        
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCC2ANNEXIIItitre" class="titleChap">SCC-Module 2 ANNEX III</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCC2ANNEXIIIeval">{{ object.AR28_SCC2_ANNEX_3_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCC2_ANNEX_3_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
      <!--<br />-->
                      
      <!--  <hr>-->
      <!--<strong translate style="color:#00aec7">SCHREMS II</strong>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCHREMSUStitre" class="titleChap">SCHREMS II US</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCHREMSUSeval">{{ object.AR28_SCHREMS_US_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCHREMS_US_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCHREMSUKtitre" class="titleChap">SCHREMS II UK</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCHREMSUKeval">{{ object.AR28_SCHREMS_UK_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCHREMS_UK_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCHREMSINDIAtitre" class="titleChap">SCHREMS II INDIA</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCHREMSINDIAeval">{{ object.AR28_SCHREMS_INDIA_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCHREMS_INDIA_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->
                      
      <!--  <hr>-->
      <!--<details>-->
      <!--  <summary translate id="AR28SCHREMSGENERICtitre" class="titleChap">SCHREMS II GENERIC</summary>-->
      <!--  <div>-->
          
      <!--      <strong> {{ 'Status' | translate }}: </strong><p id="AR28SCHREMSGENERICeval">{{ object.AR28_SCHREMS_GENERIC_RADIO }}</p>-->
      <!--      <strong>{{ 'Comment' | translate }}: </strong><p>-->
      <!--      {{ object.AR28_SCHREMS_GENERIC_RADIO_comment }}-->
      <!--    </p>-->
      <!--  </div>-->
      <!--</details>-->

      
    </div>
    <!-- END ARTICLE 28 -->
    
    <!-- IAAS -->
    <div *ngIf="tabName === 'IaaS Form'">
      <details>
        <summary translate id="IAAS1titre" class="titleChap">IAAS_1</summary>
        <div>
            <strong> {{ 'Status' | translate }}: </strong><p id="IAAS1eval">{{ object.IAAS_1_RADIO }}</p>
            <strong>{{'Comment' | translate }}: </strong><p>
            {{ object.IAAS_1_RADIO_comment }}
          </p>
        </div>
      </details>
                
        <hr>
      <details>
        <summary translate id="IAAS2titre" class="titleChap">IAAS_2</summary>
        <div>
            <strong> {{ 'Status' | translate }}: </strong><p id="IAAS2eval">{{ object.IAAS_2_RADIO }}</p>
            <strong>{{'Comment' | translate }}: </strong><p>
            {{ object.IAAS_2_RADIO_comment }}
          </p>
        </div>
      </details>
                
        <hr>
      <details>
        <summary translate id="IAAS3titre" class="titleChap">IAAS_3</summary>
        <div>
            <strong> {{ 'Status' | translate }}: </strong><p id="IAAS3eval">{{ object.IAAS_3_RADIO }}</p>
            <strong>{{'Comment' | translate }}: </strong><p>
            {{ object.IAAS_3_RADIO_comment }}
          </p>
        </div>
      </details>
      
      <hr>
      <details>
        <summary translate id="IAAS4titre" class="titleChap">IAAS_4</summary>
        <div>
            <strong> {{ 'Status' | translate }}: </strong><p id="IAAS4eval">{{ object.IAAS_4_RADIO }}</p>
            <strong>{{'Comment' | translate }}: </strong><p>
            {{ object.IAAS_4_RADIO_comment }}
          </p>
        </div>
      </details>
      
    </div>
    <!-- END IAAS -->
  
  </div>
  <!-- END éval for pdf -->

  <!-- Evaluation finale-->
  <div *ngIf="(auth.user | async) as user">

    <!--Lecture seule pour tous les users-->
    <div>
      
      <!--DESIGN Acunetix-->
      <form
        *ngIf="tabName === 'Design Form'"
        #formAcunetix="ngForm"
        class="form"
      >
        <!-- TO UPDATE on check s'il ya déjà un id ou pas -->
        <input type="hidden" name="$key" #$key="ngModel" [(ngModel)]="service.selectedEvaluation.$key" />

        <br />
        <!-- scan acunetix a été fait -->
        <mat-label translate>Scan Acunetix fait ?</mat-label>
        <mat-radio-group
          class="radio-group readonly"
          name="acunetix_radio"
          #acunetix_radio="ngModel"
          [(ngModel)]="service.selectedEvaluation.acunetix_radio"
        >
          <mat-radio-button
            style="padding-left: 5px; 
    padding-right: 15px;"
            class="radio-button"
            value="Yes"
            >{{ 'Oui' | translate }}</mat-radio-button
          >
          <mat-radio-button
            style="padding-left: 5px; 
    padding-right: 15px;"
            class="radio-button"
            value="Non"
            >{{ 'Non' | translate }}
          </mat-radio-button>
        </mat-radio-group>
        

        <div *ngIf="!fileList || fileList.length === 0"><strong style="color:red" translate>No files uploaded yet</strong></div>

        <div *ngFor="let file of fileList">
          <!-- <p><strong>Posté par:</strong> {{ file.postedBy }}</p> -->
          <!-- <p><strong>Le:</strong> {{ file.createdAt | date: 'medium' }}</p> -->
          <strong>{{ file.name }} </strong>
          <a title="{{'Click to download the file' | translate}}" target="_blank" rel="noopener" href="{{ file.url }}"
            ><em class="material-icons" style="margin-right: 5px"> cloud_download </em></a
          >
        </div>

        <br />

        <!-- scan acunetix a été fait -->
        <mat-label translate>Failles critiques ont été détectées ?</mat-label>
        <mat-radio-group
          class="radio-group readonly"
          name="secu_radio"
          #secu_radio="ngModel"
          [(ngModel)]="service.selectedEvaluation.secu_radio"
        >
          <mat-radio-button
            style="padding-left: 5px; 
    padding-right: 15px;"
            class="radio-button"
            value="OUI"
            >{{ 'Oui' | translate }}</mat-radio-button
          >
          <mat-radio-button
            style="padding-left: 5px; 
    padding-right: 15px;"
            class="radio-button"
            value="NON"
            >{{ 'Non' | translate }}
          </mat-radio-button>
        </mat-radio-group>

        <div class="form-group">
          <textarea
            matInput
            type="text"
            class="form-control commentAcunetix readonly readonly2"
            name="acunetix_comment"
            #acunetix_comment="ngModel"
            [(ngModel)]="service.selectedEvaluation.acunetix_comment"
            readonly
          >
          </textarea>
        </div>
      </form>
      <!--DESIGN Fin acunetix -->

      <!-- Check que tous les chapitres pour X form ont été évalués -->

      <!-- DESIGN -->
      <div *ngIf="tabName === 'Design Form'">
        <div
          *ngIf="
              (!object.DA_1_RADIO || object.DA_1_RADIO.length === 0) ||
              (!object.DA_2_RADIO || object.DA_2_RADIO.length === 0) ||
              (!object.DA_3_RADIO || object.DA_3_RADIO.length === 0) ||
              (!object.DA_4_RADIO || object.DA_4_RADIO.length === 0) ||
              (!object.DA_5_RADIO || object.DA_5_RADIO.length === 0) ||
              (!object.DA_6_RADIO || object.DA_6_RADIO.length === 0) ||
              (!object.DA_7_RADIO || object.DA_7_RADIO.length === 0) ||
              (!object.DA_8_RADIO || object.DA_8_RADIO.length === 0) || 
              (!object.DA_9_RADIO || object.DA_9_RADIO.length === 0);
            then: ifNotAllComplete2;
            else: ifAllComplete2
          "
        ></div>
      </div>

      <!-- LEGAL -->
      <div *ngIf="tabName === 'Legal Form'">
        <div
          *ngIf="
              (!object.LA_0_RADIO || object.LA_0_RADIO.length === 0) ||
              (!object.LA_1_RADIO || object.LA_1_RADIO.length === 0) ||
              (!object.LA_2_RADIO || object.LA_2_RADIO.length === 0) ||
              (!object.LA_3_RADIO || object.LA_3_RADIO.length === 0) ||
              (!object.LA_4_RADIO || object.LA_4_RADIO.length === 0) ||
              (!object.LA_5_RADIO || object.LA_5_RADIO.length === 0);
            then: ifNotAllComplete2;
            else: ifAllComplete2
          "
        ></div>
      </div>
      
      <!-- ARTICLE 32 -->
      <div *ngIf="tabName === 'Article 32 Form'">
        <div
          *ngIf="
              (!object.AR32_0_RADIO || object.AR32_0_RADIO.length === 0) ||
              (!object.AR32_1_RADIO || object.AR32_1_RADIO.length === 0) ||
              (!object.AR32_2_RADIO || object.AR32_2_RADIO.length === 0) ||
              (!object.AR32_3_RADIO || object.AR32_3_RADIO.length === 0) ||
              (!object.AR32_4_RADIO || object.AR32_4_RADIO.length === 0) ||
              (!object.AR32_5_RADIO || object.AR32_5_RADIO.length === 0) ||
              (!object.AR32_6_RADIO || object.AR32_6_RADIO.length === 0) ||
              (!object.AR32_7_RADIO || object.AR32_7_RADIO.length === 0) ||
              (!object.AR32_8_RADIO || object.AR32_8_RADIO.length === 0);
            then: ifNotAllComplete2;
            else: ifAllComplete2
          "
        ></div>
      </div>
      
      <!-- ARTICLE 28 -->
      <div *ngIf="tabName === 'Article 28 Form'">
        <!--<div-->
        <!--  *ngIf="-->
        <!--      (!object.AR28_SCC1_RADIO || object.AR28_SCC1_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC1_ANNEX_1_RADIO || object.AR28_SCC1_ANNEX_1_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC1_ANNEX_2_RADIO || object.AR28_SCC1_ANNEX_2_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC1_ANNEX_3_RADIO || object.AR28_SCC1_ANNEX_3_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC1_ANNEX_4_RADIO || object.AR28_SCC1_ANNEX_4_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC1_ANNEX_5_RADIO || object.AR28_SCC1_ANNEX_5_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC2_RADIO || object.AR28_SCC2_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC2_ANNEX_1_RADIO || object.AR28_SCC2_ANNEX_1_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC2_ANNEX_2_RADIO || object.AR28_SCC2_ANNEX_2_RADIO.length === 0) ||-->
        <!--      (!object.AR28_SCC2_ANNEX_3_RADIO || object.AR28_SCC2_ANNEX_3_RADIO.length === 0);-->
        <!--    then: ifNotAllComplete2;-->
        <!--    else: ifAllComplete2-->
        <!--  "-->
        <!--></div>-->
        
        <div
          *ngIf="
              (!object.AR28_SCC1_ANNEX_3_RADIO || object.AR28_SCC1_ANNEX_3_RADIO.length === 0);
            then: ifNotAllComplete2;
            else: ifAllComplete2
          "
        ></div>
      </div>
      
      <!-- IAAS -->
      <div *ngIf="tabName === 'IaaS Form'">
        <div
          *ngIf="
              (!object.IAAS_1_RADIO || object.IAAS_1_RADIO.length === 0) ||
              (!object.IAAS_2_RADIO || object.IAAS_2_RADIO.length === 0) ||
              (!object.IAAS_3_RADIO || object.IAAS_3_RADIO.length === 0) ||
              (!object.IAAS_4_RADIO || object.IAAS_4_RADIO.length === 0);
            then: ifNotAllComplete;
            else: ifAllComplete
          "
        ></div>
      </div>
      
      <!-- Si pas tous les chap pour X form ont été évalués -->
      <ng-template #ifNotAllComplete2
        >

        <div class="borderTop">
          <div class="notComplete" style="opacity: 40%;" onmouseover="mouseOver()">
            <br />

            <mat-label translate class="radio-title">{{object.formIs}} assessment</mat-label>
            <br />

            <mat-radio-group
              class="radio-group readonly"
              name="status"
              #status="ngModel"
              [(ngModel)]="service.selectedEvaluation.status"
            >
              <mat-radio-button
                style="padding-left: 5px; 
      padding-right: 15px;"
                class="radio-button green"
                value="Recommended"
                >{{ 'Recommended' | translate }}</mat-radio-button
              >
              <mat-radio-button
                style="padding-left: 5px; 
      padding-right: 15px;"
                class="radio-button orange"
                value="Reservations"
                >{{ 'Recommended with reservations' | translate }}</mat-radio-button
              >
              <mat-radio-button
                style="padding-left: 5px; 
      padding-right: 15px;"
                class="radio-button orange"
                value="Not recommended"
                >{{ 'Not recommended' | translate }}</mat-radio-button
              >
            </mat-radio-group>

            <div class="form-group">
              <textarea
                matInput
                type="text"
                class="form-control commentFinal readonly2 readonly"
                name="finalComment"
                #finalComment="ngModel"
                [(ngModel)]="service.selectedEvaluation.finalComment"
                readonly
              >
              </textarea>
            </div>

          </div>

          <div class="overlay" style="visibility:hidden">
            <p style="color:red" translate>EvalNotCompleteText</p>
          </div>
        </div>
      </ng-template>
      
      <!-- Si tous les chaps pour X form on été évalués-->
      <ng-template #ifAllComplete2
        >

        <div class="borderTop">
          <form #formFinalEvaluation="ngForm" (ngSubmit)="saveFinalEval(formFinalEvaluation)" class="form">
            <br />

            <mat-label *ngIf="tabName !== 'Article 28 Form'" translate class="radio-title">{{object.formIs}} assessment</mat-label>
            <mat-label *ngIf="tabName === 'Article 28 Form'" translate class="radio-title">Annexes assessment</mat-label>
            <br />

            <mat-radio-group
              class="radio-group readonly"
              name="status"
              #status="ngModel"
              [(ngModel)]="service.selectedEvaluation.status"
            >
              <mat-radio-button
                style="padding-left: 5px; 
      padding-right: 15px;"
                class="radio-button green"
                value="Recommended"
                >{{ 'Recommended' | translate }}</mat-radio-button
              >
              <mat-radio-button
                style="padding-left: 5px; 
      padding-right: 15px;"
                class="radio-button orange"
                value="Reservations"
                >{{ 'Recommended with reservations' | translate }}</mat-radio-button
              >
              <mat-radio-button
                style="padding-left: 5px; 
      padding-right: 15px;"
                class="radio-button orange"
                value="Not recommended"
                >{{ 'Not recommended' | translate }}</mat-radio-button
              >
            </mat-radio-group>

            <div class="form-group">
              <textarea
                matInput
                type="text"
                class="form-control commentFinal readonly readonly2"
                name="finalComment"
                #finalComment="ngModel"
                [(ngModel)]="service.selectedEvaluation.finalComment" 
                readonly
              >
              </textarea>
            </div>

          </form>
        </div>
      </ng-template>
      
    </div>
    
  </div>
  
</div>

