import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../../core';
import { DemandeDlaComponent } from './demandeDla.component';

const routes: Routes = [{ path: '', component: DemandeDlaComponent, data: { title: extract('New request') } }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class DemandeDlaRoutingModule {}
