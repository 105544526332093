import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../core/auth.service';
import { throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  //connected: boolean;

  constructor(
    private firestore: AngularFirestore,
    private toastr: ToastrService,
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private router: Router,
    private httpClient: HttpClient
  ) {}
  

  
  //TEST API//
  // sendRetarusEmail(){
  //   return this.httpClient.get('/api/send_retarus?emailTo=vieira.dorian@outlook.fr', {responseType:'text'}).pipe(
  //     catchError(this.handleErrorHttp)
  //   );
  // }
  
  // getUserOneLogin() {
  //   return this.httpClient.get('/api/get_user_by_id', {responseType:'text'}).pipe(
  //     catchError(this.handleErrorHttp)
  //   );
  // }
  
  
  // sendRetarusEmail(){
  //   return this.httpClient.get('/api/send_retarus?emailTo=vieira.dorian@outlook.fr', {responseType:'text'}).pipe(
  //     catchError(this.handleErrorHttp)
  //   );
  // }
  
  public handleErrorHttp(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        this.toastr.error('Error', 'Error', {
              timeOut: 9000,
              closeButton: true,
              progressBar: true,
              positionClass: 'toast-bottom-center'
            });
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }
 

  //get l'email du user si pas a null alors ça veut dire il est connecté donc renvoyé true est appelé par ngOnInit du home.ts
  /*
  userConnected() {
    if (this.authService.currentUserEmail) {
      this.connected = true;
      console.log("User connected !");
        return this.router.navigate(['/homeUser']);
    } else {
      this.connected = false;
       console.log("User not connected !");
    }
  }*/
}
