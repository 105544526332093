import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FirstConnectionService } from './firstConnection.service';
import { BusinessUnit } from './businessUnit.model';

import { AuthService } from '../core/auth.service';
import firebase from 'firebase/compat/app';
import { Countries, Country } from '../core/countries';

@Component({
  selector: 'app-firstConnection',
  templateUrl: './firstConnection.component.html',
  styleUrls: ['./firstConnection.component.scss']
})
export class FirstConnectionComponent implements OnInit {
  firstConnectionForm: FormGroup; //veolia
  firstConnectionForm2: FormGroup; //vendor 
  
  submitted = false;
  
  listBU: BusinessUnit[];
  
  countryList: Country[] = Countries;
  
  
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private service: FirstConnectionService,
    public auth: AuthService, //toujours utilisé dans le cas ou on veut faire des {{user.?}}
    private authService: AuthService,
    private firestore: AngularFirestore
  ) {}

  item: any;

  ngOnInit() {
    //récupère depuis l'URL
    this.route.data.subscribe(routeData => {
      const data = routeData['data'];
      console.log('route', data);
      if (data) {
        this.item = data.payload.data();
        this.item.id = data.payload.id;
        this.createForm();
      }
    });
    
    //récupère BU
    this.service.getBu().subscribe(actionArray => {
      this.listBU = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as BusinessUnit;
      });
      console.log('buList', this.listBU);
      this.firstConnectionForm.get('businessUnit').setValue(this.item.businessUnit.toString());
    });

  }

  createForm() {
    console.log('Business Unit', this.item.businessUnit, this.item.country);
    //NOMFORMCONTROLENAME=item.NOMDANSLABD
    
    this.firstConnectionForm = this.fb.group({
      businessUnit: ['', Validators.required],
      country: ['', Validators.required],
      role: [this.item.role],
      displayName: [this.service.getDisplayNameUser()],
      email: [this.service.getEmailUser()],
      photoURL: [this.service.getPhotoUser()],
      uid: [this.service.getUidUser()],
      terms_RADIO: ['', Validators.required]
    });
    
    this.firstConnectionForm2 = this.fb.group({
      terms_RADIO: ['', Validators.required]
    });
  }
  
   get f() {
    return this.firstConnectionForm.controls;
  }
  
  get f2() {
    return this.firstConnectionForm2.controls;
  }
  

  //veolia
  onSubmit(value) {
    this.submitted = true;

    if (this.firstConnectionForm.invalid) {
      this.toastr.warning('Error on field', 'Please fill in required field(s) in red', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      });
    } else {
      
       //si les termes ne sont pas approuvé
      if(this.firstConnectionForm.get('terms_RADIO').value === 'No' || !this.firstConnectionForm.get('terms_RADIO').value) {
        this.toastr.warning('You must be agree with terms', '', {
          timeOut: 7000,
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-center'
        });
      } else {
      
        //console.log('TESTSTET ' + this.firstConnectionForm.controls['domainLeader'].value);
        this.service.updateInfosUser(value).then(res => {
          
        //si terms OK on met à true le field termsAccepted
        const userDoc = this.firestore.collection('users').doc(this.authService.currentUserId);
        // userVendorRef.update({ termsAccepted: firebase.firestore.FieldValue.delete() });
         userDoc.update({ termsAccepted: true, termsAcceptedDate: new Date(Date.now()) });
         
          this.router.navigate(['/homeUser']); //rediriger vers l'acceuil
          this.toastr.success('Your informations has been saved', '', {
            timeOut: 7000,
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-center'
          });
        });
      }
    }
  }
  
  //vendor
  onSubmit2(value) {
    this.submitted = true;
    
      //si les termes ne sont pas approuvé
      if(this.firstConnectionForm2.get('terms_RADIO').value === 'No' || !this.firstConnectionForm2.get('terms_RADIO').value) {
        this.toastr.warning('You must be agree with terms', '', {
          timeOut: 7000,
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-center'
        });
      } else {
        //console.log('' + this.firstConnectionForm.controls['domainLeader'].value);
        this.service.updateInfosUser(value).then(res => {
          
        //si terms OK on met à true le field termsAccepted
        const userDoc = this.firestore.collection('users').doc(this.authService.currentUserId);
        userDoc.update({ termsAccepted: true, termsAcceptedDate: new Date(Date.now()) });
         
          this.toastr.success('Your informations have been saved', '', {
            timeOut: 7000,
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-center'
          });
          
          
          const fire = firebase.firestore();
          fire.collection('users').doc(this.authService.currentUserId).get().then(val => {
            //on envoie la notif au createur du vendor que le vendor s'est connecté sur l'application pour la premiere fois
              this.service.sendFirstConnectionVendor(val.data().firstname, val.data().lastname, val.data().email, val.data().createdBy).subscribe(data => {
                if (data !== "200") {
                  console.error("KO mail retarus unsent !")
                } else {
                  console.log("OK mail retarus sent !")
                }
                  console.log("from api: " + data);
              });
            
          
            //On check si le vendor a changé son mot de passe
            //redirection vers page changement mot de passe
            if(val.data().passwordEdited === false) { 
              console.log('Password not edited yet');
              this.router.navigate(['/editPassword/' + this.authService.currentUserId]);
              this.toastr.warning('Please change your password now !', 'Password changing required', {
                timeOut: 9000,
                closeButton: true,
                progressBar: true,
                positionClass: 'toast-top-center'
              })
            } else {//sinon redirection vers homeUser
              console.log('Password already edited');
              this.router.navigate(['/homeUser']);
            }
                  
          });
          
        });
        
        
        
      }
    
  }
  
}
