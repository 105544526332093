import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { FirstConnectionService } from './firstConnection.service';

@Injectable()
export class FirstConnectionResolver implements Resolve<any> {
  constructor(public firebaseService: FirstConnectionService) {}

  //on récupère l'id du user connecté pour ensuite avoir ses données associées et les placer dans le formulaire
  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      const userId = route.paramMap.get('id');
      this.firebaseService.getInformationsUser(userId).subscribe(data => {
        resolve(data);
      });
    });
  }
}
