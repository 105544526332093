<div class="container">
  <mat-card>
    <mat-card-content>
      <mat-card-title id="titre_form" translate>Vos informations personnelles</mat-card-title>
      <br />
      <div *ngIf="(auth.user | async); then: authenticated"><!-- template will replace this div --></div>
      <!-- User logged in -->
      <ng-template #authenticated>
        <div *ngIf="(auth.user | async) as user">
          <section *ngIf="auth.isVeolia(user)">
            <!--<button type="button" (click)="changeStatus()">BUTTON</button>-->
            <p><strong translate>Votre uid:</strong> {{ user.uid }}</p>
            <p><strong translate>Votre email:</strong> {{ user.email }}</p>
            <p><strong translate>Votre nom et prénom:</strong> {{ user.displayName }}</p>
            <p><strong translate>Votre Business Unit:</strong> {{ user?.businessUnit }}</p>
            <p><strong translate>Vous êtes:</strong> {{ user?.role }}</p>
          </section>
          
          <section *ngIf="auth.isVendor(user)">
            <p><strong translate>Votre uid:</strong> {{ user.uid }}</p>
            <p><strong translate>Votre email:</strong> {{ user.email }}</p>
            <p><strong translate>Votre nom et prénom:</strong> {{ user?.lastname}} {{ user?.firstname}}</p>
            <p><strong translate>Votre entreprise:</strong> {{ user?.company }}</p>
            <p><strong translate>Vous êtes:</strong> {{ user?.role }}</p>
          </section>
        </div>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
