import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { EvaluatorService } from './evaluator.service';
import { HomeUserService } from '../homeUser/homeUser.service';
import { Demande } from '../homeUser/demandeDla/demande.model';
import { AuthService } from '../core/auth.service';
import { filter, map, zip } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SecurityScoreCard } from 'app/admin/securityScoreCard.model';
import {MatDialog} from '@angular/material/dialog';
import { DemandOverviewModalComponent } from 'app/admin/demand-overview-modal.component';

@Component({
  selector: 'app-evaluator',
  templateUrl: './evaluator.component.html',
  styleUrls: ['./evaluator.component.scss']
})
export class EvaluatorComponent implements OnInit {

  //SaaS
  demandesSaaSEvaluator: Demande[] = []; //Demandes avec businessUnitOfSolution (solutions collection) <-> dont le user est évaluator via 'evaluators' (businessUnit collection) nomBu
  demandesSaaSEvaluatorEvaluated: Demande[] = []; //Demandes évaluées avec businessUnitOfSolution (solutions collection) <-> dont le user est évaluator via 'evaluators' (businessUnit collection) nomBu

  evaluatorRoute = true;

  public showOverlay = true;

  //SaaS
  observable: any ;

  //SSC
  observable2: any ;
  securityScoreCardData: SecurityScoreCard[];

  constructor(
    private route: ActivatedRoute,
    private service: EvaluatorService,
    private homeUserService: HomeUserService,
    private router: Router,
    public auth: AuthService,
    private firestore: AngularFirestore,
    public dialog: MatDialog
  ) {

    //eviter le textcontent null globalSta
    router.events
    .pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart)
    )
    .subscribe((event:  NavigationStart) => {
      // "event" here is now of type "NavigationStart"
      console.log('--ROUTE EVENT START-- ' + event);
      if (event.url !== '/evaluator') {
        this.evaluatorRoute = true;
      }

    });

    router.events
    .pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    )
    .subscribe((event:  NavigationEnd) => {
      // "event" here is now of type "NavigationEnd"
      if (event.url !== '/evaluator') {
        this.evaluatorRoute = false;
      }
    });
  }

  //appliquer le datatbale quand on change de tab car par défaut c'est que les 2 premiers tab qui appliquent le datatable
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    //  console.log('tabChangeEvent => ', tabChangeEvent);
    //  console.log('index => ', tabChangeEvent.index);
    console.log('nom => ', tabChangeEvent.tab.textLabel);

    this.showOverlay = true;

    //SaaS default
    if(tabChangeEvent.tab.ariaLabel === 'SaaStab') {
        this.getEvaluatorSaaSRequests() //mat-tab -> aria-label=SaaStab (table -> aria-label=Evaluator Bu SaaS requests)
    }

    if(tabChangeEvent.tab.ariaLabel === 'EvaluatorReqSaaS') {
        this.getEvaluatorSaaSRequests() //mat-tab -> aria-label=EvaluatorReqSaaS (table -> aria-label=Evaluator Bu SaaS requests)
    }

    if(tabChangeEvent.tab.ariaLabel === 'EvaluatorReqSaaSEvaluated'){
      this.getEvaluatorSaaSEvaluatedRequests() //mat-tab -> aria-label=EvaluatorReqSaaSEvaluated (table -> aria-label=Evaluator Bu evaluated SaaS requests)
    }
    //END SaaS

  };

  ngOnInit() {
    this.getEvaluatorSaaSRequests()
  }


    ///////SaaS///////
    getEvaluatorSaaSRequests() {
        const fire = firebase.firestore();

        //récupère les BU dont le user est évaluator
        fire
          .collection('businessUnit')
          .where('evaluators', 'array-contains', this.auth.currentUserEmail)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {

              //VEOLIA mat-tab -> aria-label=EvaluatorReqSaaS (table -> aria-label=Evaluator Bu SaaS requests)
              this.observable = this.service.getEvaluatorSaaSRequestsQuery(doc.data().nomBu).subscribe(actionArray => {
              this.demandesSaaSEvaluator.push(...actionArray.map(item => {

                if(this.evaluatorRoute === true) {

                return {
                  id: item.payload.doc.id,
                  ...(item.payload.doc.data() as object)
                } as Demande;

                }

              }));
            });

            this.delay(4000).then(any => {
                this.observable.unsubscribe();
            });
            //this.ngOnDestroy();
            });
          })

        this.delay(3000).then(any => {
                  for (let i = 0; i < this.demandesSaaSEvaluator.length; i++) {

                    if(document.getElementById('globalSta'+i)) {
                      //GLOBAL status
                        if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Empty") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                            document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Pending") {
                            document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                        }
                    }

                     if(document.getElementById('designSta'+i)) {
                    //DESIGN status
                        if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                            document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Recommended") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Reservations") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Completed") {
                            document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                        }
                        if (document.getElementById('designSta'+i).textContent === "New") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Need help") {
                            document.getElementById('designSta'+i).style.backgroundColor = 'black';
                        }
                     }

                     if(document.getElementById('legalSta'+i)) {
                    //LEGAL status
                        if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                            document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Completed") {
                            document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "New") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Need help") {
                            document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                        }
                     }

                    if(document.getElementById('article32Sta'+i)) {
                    //ARTICLE32 status
                        if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "New") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                        }
                    }
                  }
                  (window as any).initializeDatatable();
                  this.showOverlay = false;
                });

        //GET data from securityScoreCard collection
        this.observable2 = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.evaluatorRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });
    }

    getEvaluatorSaaSEvaluatedRequests() {
        const fire = firebase.firestore();

        //récupère les BU dont le user est évaluator
        fire
          .collection('businessUnit')
          .where('evaluators', 'array-contains', this.auth.currentUserEmail)
          .get()
          .then(snapshot => {
            snapshot.forEach(doc => {

        //VEOLIA mat-tab -> aria-label=EvaluatorReqSaaSEvaluated (table -> aria-label=Evaluator Bu evaluated SaaS requests)
        this.observable = this.service.getEvaluatorSaaSEvaluatedRequestsQuery(doc.data().nomBu).subscribe(actionArray => {
              this.demandesSaaSEvaluatorEvaluated.push(...actionArray.map(item => {

                if(this.evaluatorRoute === true) {

                return {
                  id: item.payload.doc.id,
                  ...(item.payload.doc.data() as object)
                } as Demande;

                }

              }));
            });

            this.delay(4000).then(any => {
                this.observable.unsubscribe();
            });
            //this.ngOnDestroy();
            });
          })

        this.delay(3000).then(any => {
                  for (let i = 0; i < this.demandesSaaSEvaluatorEvaluated.length; i++) {

                    if(document.getElementById('globalSta'+i)) {
                      //GLOBAL status
                        if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Empty") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                            document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                            document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                        }
                        if (document.getElementById('globalSta'+i).textContent === "Pending") {
                            document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                        }
                    }

                     if(document.getElementById('designSta'+i)) {
                    //DESIGN status
                        if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                            document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Recommended") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Reservations") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Completed") {
                            document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                        }
                        if (document.getElementById('designSta'+i).textContent === "New") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                            document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                        }
                        if (document.getElementById('designSta'+i).textContent === "Need help") {
                            document.getElementById('designSta'+i).style.backgroundColor = 'black';
                        }
                     }

                     if(document.getElementById('legalSta'+i)) {
                    //LEGAL status
                        if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                            document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Completed") {
                            document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "New") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                            document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                        }
                        if (document.getElementById('legalSta'+i).textContent === "Need help") {
                            document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                        }
                     }

                    if(document.getElementById('article32Sta'+i)) {
                    //ARTICLE32 status
                        if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "New") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                        }
                        if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                            document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                        }
                    }
                  }
                  (window as any).initializeDatatable();
                  this.showOverlay = false;
                });

        //GET data from securityScoreCard collection
        this.observable2 = this.service.getAllSSCdata().subscribe(actionArray => {
          this.securityScoreCardData = actionArray.map(item => {

            if(this.evaluatorRoute === true) {

            return {
              id: item.payload.doc.id,
              ...(item.payload.doc.data() as object)
            } as SecurityScoreCard;

            }

          })
        });
    }
    ///////END SaaS///////


  /////////FORMULAIRES/////////

  //Formulaire DESIGN
  gotoDesignForm(id: string) {
    this.homeUserService.initializeDesignForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire LEGAL
  gotoLegalForm(id: string) {
    this.homeUserService.initializeLegalForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 32
  gotoArticle32Form(id: string) {
    this.homeUserService.initializeArticle32Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 28
  gotoArticle28Form(id: string) {
    this.homeUserService.initializeArticle28Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }


  //redirection vers la page de création du compte pour le vendor
  creationCompteVendor(id: string, type: string) {
    this.router.navigate(['/gestionVendor/' + id + '/' + type]);
  }

  //redirection vers la page des logs pour la demande
  logs(id: string, type: string) {
    this.router.navigate(['/logs/' + id + '/' + type]);
  }

  //redirection sur le tableau de synthese des forms avec l'éval actuelle par form
  tableauEvals(id: string, type: string) {
    this.router.navigate(['/tableauEvals/' + id + '/' + type]);
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  openDialog(demandId: string) {
    const dialogRef = this.dialog.open(DemandOverviewModalComponent, { data: { demandId }});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
