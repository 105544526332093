// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prd.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
import env from './.env';

//ENV PROD
export const environment = {
  production: true,
  version: env.npm_package_version + '-prod',
  serverUrl: '',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR', 'es-ES'],

//Base de données 'firestore' and 'realtime database' from FIREBASE => BD PROD gbl-ist-ve-dlaaas
  firebaseApp: {
    apiKey: "AIzaSyBhg2jgp3Ppw9b2H_j2pFe4eJblt5fDBVY",
    authDomain: "gbl-ist-ve-dlaaas.firebaseapp.com",
    databaseURL: "https://gbl-ist-ve-dlaaas-default-rtdb.firebaseio.com",
    projectId: "gbl-ist-ve-dlaaas",
    storageBucket: "gbl-ist-ve-dlaaas.appspot.com",
    messagingSenderId: "876376300466",
    appId: "1:876376300466:web:bbe4b42e063f7f535262ab",
    measurementId: "G-V345Q1B3Y4",
    experimentalForceLongPolling: true,
  },
  
};

//https://snyk.io/advisor/npm-package/firebase/functions/firebase%2Fapp.initializeApp
// const firestore = firebase.initializeApp(environment.firebaseApp).firestore();
// export const settings = { 
//   experimentalForceLongPolling: true 
  
// };
// firestore.settings(settings);