import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { FormulaireAllService } from './formulaireAll.service';

@Injectable()
export class FormulaireIaaSResolver implements Resolve<any> {
  constructor(public firebaseService: FormulaireAllService) {}

  //Pour récupérer les données de la collection formIaaS et les placer dans le formulaire pour pré-compléter les champs
  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      const docId = route.paramMap.get('id');
      this.firebaseService.getFormIaaSById(docId).subscribe(data => {
        resolve(data);
      });
    });
  }
}
