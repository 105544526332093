import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../../material.module';

import { TableauEvalsRoutingModule } from './tableauEvals-routing.module';

import { TableauEvalsComponent } from './tableauEvals.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SummaryEvaluationComponent } from './summary-evaluation.component';
import { ReservationsEvaluationComponent } from './reservations-evaluation.component';
import { ReservationsListComponent } from './reservations-list.component';
import { SummarySolutionComponent } from './summary-solution.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    TableauEvalsRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [TableauEvalsComponent, SummaryEvaluationComponent, ReservationsEvaluationComponent, ReservationsListComponent, SummarySolutionComponent],
  exports: []
})
export class TableauEvalsModule {}
