export class Reservation {
  $key?: string;
  idRefReservation?: string;
  content?: string;
  formIs?: string;
  idSolution?: number;
  solutionName?: string;
  postedAt?: string;
  postedBy?: string;
  userId?: string;
  userRole?: string;
  reference?: string;
  fixed?: boolean;
  fixedOn?:string; //date à laquelle le vendor a mis fixed en true
  fixedDate?: string; //date de correction soumise par vendor
  deadlineDate?: string; //date de correction souhaitée par evaluateur
}
