import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class BusinessUnitService {
    
    constructor(private firestore: AngularFirestore) {}
    
    getBu() {
        return this.firestore.collection('businessUnit', ref => ref.orderBy('nomBu', 'asc')).get().toPromise();
    }
    
}