import { Component, OnInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { I18nService } from '../../core/i18n.service';
import { AuthService } from '../../core/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HomeService } from '../../home/home.service';
import { EditPasswordService } from '../../editPassword/editPassword.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() sidenav: MatSidenav;
  user: any = {};

  signinForm: FormGroup;
  forgotPassForm: FormGroup;
  public email: string;
  public password: string;
  public uidUser: string ;

  submitted = false;

  //pwd = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~*:!@%-_/]).{12}$");// peut contenir une lettre min maj chiffre special chars (sachant que le mdp a été généré auto lors de la première creation du compte ((par gestionVendor ou demandeDLA)) et ne contient pas forcement tous les caractères de l'exrepssion reguliere)

  validation_messages = {
    email: [{ type: 'email', message: '*Valid email is required' }],
    password: [{ type: 'required', message: '*Valid password is required with 12 characters' }],
  };
  
  public showOverlay = false;
  
  constructor(
    private i18nService: I18nService,
    private titleService: Title,
    public auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private homeService: HomeService,
    private editPassService: EditPasswordService,
    private httpClient: HttpClient,
  ) {
  }

  //Formulaire de connexion vendor
  ngOnInit() {
    this.signinForm = this.fb.group({
      email: ['', Validators.required],
      password: [
        '',
        // [
        //   Validators.required,
        //   Validators.pattern(this.pwd)
        // ]
        [
          Validators.required,
        ]
      ]
    });

    this.forgotPassForm = this.fb.group({
      email: [ 
        '',Validators.required
      ]
    });

    this.currentLanguage;
    
    this.togglePassword();
  }
  
   get f() {
    return this.forgotPassForm.controls;
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.toastr.error(error.message, 'Account not found', {
      timeOut: 10000,
      positionClass: 'toast-top-center'
    });
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
    this.currentLanguage;
  }

  get currentLanguage(): string {
    console.log('dans current: ' + this.i18nService.language);
    if (this.i18nService.language === 'fr-FR') {
      console.log('FRENCH');
    } else {
      console.log('ENGLISH');
    }
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get pageTitle(): string {
    return this.titleService.getTitle();
  }

  //GOOGLE VEOLIA
  async signInWithGoogle() {
    await this.auth.googleLogin();
    return await this.afterSignIn();
  }

  private afterSignIn() {
    // Do after login stuff here, such router redirects, toast messages, etc.
    return this.router.navigate(['/homeUser']);
  }
  
  togglePassword() {
    this.delay(3500).then(any => {
      const togglePassword = document.querySelector('#seePassword');
      const password = document.querySelector('#pass');
      
      if(togglePassword && password) {
        
      togglePassword.addEventListener('click', function (e) {
        // toggle the type attribute
        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        // toggle the eye / eye slash icon
        togglePassword.textContent = "visibility";
        
      });
      
      }
      
    });
  }
  
   async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  //EMAIL/PASSWORD
  //Connexion email/password vendor
  onSubmitLogin() {
    this.auth
      .loginEmail(this.email, this.password)
      .then( (res) => {
        this.toastr.success('Connection successful !', '', {
          timeOut: 9000,
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-center'
        });
        
        (result) => console.log('HTTP request completed.')//SI OK
        this.signinForm.reset();
      })
      .catch((error) => {
        let errorCode = error.code;
        let errorMessage = error.message;
          console.log("ERR CODE " + errorCode)
          console.log("ERR MESS " + errorMessage)
          
          this.toastr.error(errorMessage, errorCode, {
          timeOut: 10000,
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-bottom-center'
          });
        }),
            
        err => console.log('HTTP Error', err)
    
  }
    
  
  onSubmitForgotPass() {
    this.submitted = true;
    let date = new Date();
    
    //Si le champs ne respecte les conditions
    if (this.forgotPassForm.get('email').invalid) {
      this.toastr.warning('', "You must fill in your email", {
        timeOut: 9000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      });
    }
    
    //Si conditions OK
    if (this.forgotPassForm.get('email').valid) {
      
      const currentEmail = this.forgotPassForm.get('email').value; //= this.email
          
          //on check si l'email existe en base
            const fire = firebase.firestore();
            fire
              .collection('users')
              .where('email', '==', currentEmail)
              .get()
              .then(snapshot => {//user existant

                  const auth = firebase.auth();
                  
                  //envoie mail firebase reset password
                   auth.sendPasswordResetEmail(currentEmail)
                    .then(
                      () =>
                        this.toastr.info('Checkout out your mails/spams', 'Reset password sent', {
                          timeOut: 30000,
                          closeButton: true,
                          progressBar: true,
                          positionClass: 'toast-bottom-center'
                        }),
                    )
                    .catch(error => this.handleError(error)) //user non existant
                    .catch(error => console.log(error));
                    
    
                  //appel dans index pour fermer entirement la modale login et forgot pass (evite les confilts avec les autres modales)
                  (window as any).closeLoginAndForgotPassAndNewPass();
                  (window as any).restoreModal()
                
              });
              
          }
          
  }
  
  
   public handleErrorHttpBasic(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }

  //Pour mettre en subrillance les boutons
  isHomeRoute() {
    return this.router.url === '/home';
  }
  isHomeUserRoute() {
    return this.router.url === '/homeUser';
  }
  isEvaluatorRoute() {
    return this.router.url === '/evaluator';
  }
  isDemandeDlaRoute() {
    return this.router.url === '/demandeDLA';
  }
  isAboutRoute() {
    return this.router.url === '/about';
  }
  isContactRoute() {
    return this.router.url === '/contact';
  }
  isAdminRoute() {
    return this.router.url === '/admin';
  }
  isFirstConnectionRoute() {
    return this.router.url === '/firstConnection/' + this.auth.currentUserId;
  }
  
  goEditPassword(userUid) {
    this.router.navigate(['/editPassword/'+userUid])
  }

  getDate() {
    console.log('today');
    let today = new Date(Date.now());
    console.log('today');

    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    let today2 = dd + '-' + mm + '-' + yyyy;

    window.localStorage.setItem('dateOfTheDay', today2);
  }
}
