import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import firebase from 'firebase/compat/app';
//import auth from 'firebase/compat/auth'; // ou firebase.auth()
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { HttpClient, HttpErrorResponse } from '@angular/common/http'; //lié à proxy.conf.js

// import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
// import Base64 from 'crypto-js/enc-base64';

// import * as cryptojs from 'crypto-js';

// import * as shajs from 'sha.js';

//Les rôles des users
//l'admin aura les full droits
interface Roles {
  viewer?: boolean;
  solutionOwner?: boolean;
  evaluator?: boolean;
  vendor?: boolean;
  admin?: boolean;
}

//est utilisé pour tout ce qui est {{user.?}}
//?=facultatif
interface User {
  uid?: string;
  email?: string;
  photoURL?: string;
  displayName?: string;
  role?: string;
  businessUnit?: string;
  roles?: Roles;
  lastname?: string; //dans la création du vendor
  firstname?: string; //dans la création du vendor
  company?: string; //dans la création du vendor
  disable?: boolean;
  createdAt?: Date; //veolia lors de sa toute première authent, vendor lorsqu'il est créé via admin (validate-solution) ou qu'on lui donne un accès avec creation (gestionVendor)
  isVeolia?: boolean;
  type?: string;
  passwordEdited?: boolean;
  termsAccepted?: boolean; //veolia et vendor qui n'ont pas encore accepté les termes
  termsAcceptedDate?: Date;
  createdBy?: string; //par qui a été crée le vendor
}

/*
 interface DomainesRef {
   id?: string;
  domainLeader: string;
  nomDomaine: string;
  productManager: string;
}*/

//est utilisé pour reprendre les informations dans les composants pour ne pas écraser le User customdata
////////////////////////////////
interface UserDetails {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  role?: string;
  businessUnit?: string;
  lastname?: string;
  firstname?: string;
  isVeolia?: boolean;
  disable?: boolean;
}
////////////////////////////

@Injectable()
export class AuthService {
  //utilisation de User de firebase donc phonenumber, emailverified, displayname etc que les attribus qu'incluent firebase
  // user: Observable<firebase.User>;
  // private userDetails: firebase.User = null;

  //utilisation de l'interface User avec attributs custom
  user: Observable<User>;

  /*
  domainesRef: Observable<DomainesRef[]>;
    domainesRefCOllection:  AngularFirestoreCollection<DomainesRef>;
*/
  secondInstanceAlreadyInitilized=false;
  thirdInstanceAlreadyInitilized=false;
  fourthInstanceAlreadyInitilized=false;
  vendorUserUid: string;

  //POur l'utilisation dans les composants et récup des données
  ////////////////////////////////////////////
  userDetail: Observable<UserDetails>;
  private userDetails: UserDetails = null;
  ///////////////////////////////////////////

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private toastr: ToastrService,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private httpClient: HttpClient) {

    ////https://firebase.google.com/docs/auth/web/auth-state-persistence////
    //local est par default, ou session (pas besoin d'ajouter le code)
    //this.afAuth.setPersistence('local');
    //ou firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

    //// Get auth data, then get firestore user document || null
    //A l'arrivée sur l'application // MAJ en temps réel
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          console.log('USER SESSION ONLINE')
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          console.log('USER SESSION OFFLINE')
          return of(null);
        }
      })
    );

    ///////////////////////////////Méthodes en plus///////////////////////////////////////
    /*Pour envoyé les infos du user connecté dans les autres composants pour avoir les attributs dans maFichePerso*/
    this.userDetail = afAuth.authState;
    this.userDetail.subscribe(userDetail => {
      if (this.userDetail) {
        this.userDetails = userDetail;
      } else {
        this.userDetails = null;
      }
    });
  }

  /*Pour récupérer les infos du suer connecté dans les autres composants*/
  // Returns true if user is logged in
  get authenticated(): boolean {
    // consider changing to 'return this.userDetails != null'
    if (this.userDetails == null) {
      return false;
    } else {
      return true;
    }
  }

  /*Méthodes appelées dans les composants pour remplir les formulaires*/

  // Returns current user UID
  get currentUserId(): string {
    return this.authenticated ? this.userDetails.uid : '';
  }

  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    return this.userDetails.displayName || this.userDetails.email;
  }

  // Returns current user email
  get currentUserEmail(): string {
    return this.userDetails.email;
  }

  get currentUserPhotoUrl(): string {
    return this.userDetails.photoURL;
  }

  ////////////////////Fin méthodes en plus//////////////////////////////////////////////////

  ///////////Authentification GOOGLE Provider////////////
  async googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return await this.oAuthLogin(provider);
  }

  private oAuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then(credential => {
        //credential.user.uid credential.user.email etc c'est issu de firebase "authentification" et non de firestore database

        //+méthodes dispo via firebase.auth().currentUser.blabla ou encore credential.additionalUserInfo.isNewUser
          firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
              // User is signed in.
              console.log("USER IS SIGNED IN")
            } else {
              // No user is signed in.
              console.log("USER IS LOGGED OUT")
            }
          });

          //USER VEOLIA
          if (credential.user.email.substring(credential.user.email.indexOf('@'),credential.user.email.length) === '@veolia.com' || credential.user.email.substring(credential.user.email.indexOf('@'),credential.user.email.length) === '@dev.veolia.com') {

          console.log('VEOLIA user connected !');
          this.toastr.success('Connection successful', '', {
            timeOut: 5000,
            progressBar: true,
            closeButton: true,
            positionClass: 'toast-top-center'
          });

            const fire = firebase.firestore();
            fire.collection('users').doc(credential.user.uid).get().then(val => {

              ////Si Doc du user existe sous 'Firestore database'////
              //Alors le user s'est connecté au moins 1 fois sur DLAaaS après une authent OK à IAP/IP//
              if(val.data()) {
                console.log("USER DOC ALREADY EXIST");

                const userDoc = this.firestore.collection('users').doc(credential.user.uid);
                //ajout champ dernière connexion à chaque authent sur dla
                 userDoc.update({ lastLoggedIn: new Date(Date.now()), browser: window.navigator.userAgent });

                //on check si le user Veolia a accepté les terms sinon redirection sur page firstConnection
                //si termsAccepted = false alors le veolia n'a pas accepté les terms
                  if(val.data().termsAccepted === false) {
                    console.log('Terms not accepted');
                    this.router.navigate(['/firstConnection/' + credential.user.uid]);
                  //Si termsAccepted = true alors redirection dashboard
                  } else {
                    console.log('Infos user complete, + de 1 connexion !');
                    console.log('VEOLIA user connected !');

                    if(val.data().roles.admin === false) {
                      this.router.navigate(['/homeUser']);
                    }

                    if(val.data().roles.admin === true) {
                      this.router.navigate(['/admin']);
                    }

                    //On récupère les données du user sans les écraser
                    return this.updateUserData(credential.user);
                  }

              ////Si Doc du user n'existe pas sous 'Firestore database',////
              //=>le user n'existe ni dans 'Authentification' ni sous 'users' dans firestore database//
              //Le user ne s'est jamais connecté sur DLAaaS après une authent OK à IAP/IP//
              } else {
                console.log("USER DOC NOT EXIST");
                return this.createVeoliaUserGoogle(credential.user);
              }

            });

          //USER VENDOR
          } else {
            console.log("Il ne sagit pas d'un user veolia mais d'un vendor");
            this.toastr.error('You must log in with your email/password received by email', 'Connection refused', {
              timeOut: 10000,
              progressBar: true,
              closeButton: true,
              positionClass: 'toast-bottom-center'
            });
            credential.user.delete() //suppression du user pour qu'il n'apparaisse pas dans 'authentification' sous firebase
          }

      }).catch(error => this.handleError(error));

  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.toastr.error(error.message, 'Error', {
      timeOut: 10000,
      positionClass: 'toast-top-center'
    });
  }

  // If error, console log and notify user
  private handleErrorPassword(error: Error) {
    console.error(error);
    this.toastr.error(error.message, 'Invalid current password', {
      timeOut: 10000,
      positionClass: 'toast-top-center'
    });
  }

  //creation de l'utilisateur Veolia Google
  private createVeoliaUserGoogle(user) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName || 'nameless user',
      photoURL: user.photoURL || 'https://goo.gl/Fz9nrQ',
      role: 'Viewer',
      isVeolia: true,
      type: 'Veolia',
      createdAt: new Date(Date.now()),
      termsAccepted: false, //par défaut n'a pas encore dit YES aux terms de firstConnection
      roles: {
        viewer: true,
        solutionOwner: false, //n'a pas encore fait de demande
        evaluator: false,
        vendor: false,
        admin: false
      }
    };

    userRef.set(data, { merge: true }).catch(err => {
      console.log('Error getting documents', err);
    });

    this.router.navigate(['/firstConnection/' + user.uid]);
  }


  //var cityRef = firebase.database().collection('domainesRef');
  //this.afs.collection('domainesRef', ref => ref.where('productManager', "array-contains", 'dorian.vieira@veolia.com')).get().toPromise()

  //Si user existait on n'écrase pas ses données pré-enregistrées
  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || 'nameless user',
      photoURL: user.photoURL || 'https://goo.gl/Fz9nrQ'
    };
    return userRef.update({ uid: user.uid, email: user.email, displayName: user.displayName, photoURL: user.photoURL });
  }

  //Pour logout Google VEOLIA
  signOutVeolia() {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/home']);
    });
    this.toastr.info('Disconnected !', '', {
      timeOut: 5000,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-center'
    });
    console.log('User disconnected !');
  }

  //Pour logout email/pass VENDOR
  signOutVendor(email) {
    //deconnexion de firebase
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/home']);
    });
    this.toastr.info('Disconnected !', '', {
      timeOut: 5000,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-center'
    });
    console.log('User disconnected !');
  }

  ///////////////GESTION VENDOR///////////////////////////////////////////////////////////////////

  //CAS Viewer/admin et vendor creation vendor +ajout à la solution (s'il n'existe pas) sinon juste ajout du vendor à la solution (s'il existe)
  //appelé par les méthodes dans gestionVendor.ts + validate-solution.ts
  //Ajoute dans la demande concernée le nouveau email du vendor (il aura désormais accès)
  addVendorAuthorizedToDemande(
    idSolution: string,
    email: string,
    password: string,
    lastnameVendor: string,
    firstnameVendor: string,
    companyVendor: string,
    solName: string,
    rfpName: string,
    editorBrand: string,
    buOfSolution: string,
    emailWhoShared: string
  ) {
    const fire = firebase.firestore();
    const usersRef = fire.collection('users');

    //On check si l'adresse email du vendor renseigné existe dans la base
    usersRef
      .where('email', '==', email)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          //Si vendor n'existe pas dans la BD on crée le compte et donne accès à la demande (solution)
          console.log('Pas de user trouvé=> création du vendor firebase et envoi des id au new vendor');
          this.emailSignUp(email, password, idSolution, lastnameVendor, firstnameVendor, companyVendor, solName, rfpName, editorBrand, buOfSolution, emailWhoShared); //Creation du compte vendor

        } else { //Si le vendor existe déjà dans la BD on donne juste l'accès
          //Envoi du mail au vendor pour l'informé
          this.sendRequestShareVendorMoreonetime(email, lastnameVendor, firstnameVendor, solName, rfpName, editorBrand, buOfSolution, emailWhoShared).subscribe(data => {
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
              console.log("OK mail retarus sent !")
            }
              console.log("from api retarus: " + data);
          });

          console.log('user existant=> partage de la demande ');
          this.toastr.success(email + ' has now access to the request', 'Access rights granted', {
            timeOut: 10000,
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-center'
          });

              fire.collection('users').doc(this.currentUserId).get().then(val => {
                if (val.data().roles.admin === true) {
                    this.router.navigate(['/admin']); //rediriger vers le tableau admin
                } else {
                  this.router.navigate(['/homeUser']); //rediriger vers le tableau des demandes(solutions)
                  }
              })

          return this.afs
            .doc('solutions/' + idSolution)
            .update({ emailVendorAuthorized: firebase.firestore.FieldValue.arrayUnion(email) });
        }
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });



  }

  ///////// Le cas ou le vendor n'existe pas dans la BD ////////////
  emailSignUp(
    emailVendor: string,
    password: string,
    idSolution: string,
    lastnameVendor: string,
    firstnameVendor: string,
    companyVendor: string,
    solName: string,
    rfpName: string,
    editorBrand: string,
    buOfSolution: string,
    emailWhoShared: string
  ) {

    console.log("PASSSWORD " + password)
    //Envoie des identifiants par mail au new vendor créé
      this.sendRequestShareVendorFirsttime(emailVendor, password, lastnameVendor, firstnameVendor, solName, rfpName, editorBrand, buOfSolution, emailWhoShared).subscribe(data => {
        if (data !== "200") {
          console.error("KO mail retarus unsent !")
        } else {
          console.log("OK mail retarus sent !")
        }
          console.log("from api retarus: " + data);
      });

    //Pour que la fonction VendorUserWithEmailAndPassword ne déconnecte pas l'utilisateur pour logger l'ajout du vendor
    //l'instance présente dans environment.ts (en fonction de si sur dev ou uat ou prod) sera celle ou les metadata du vendor seront ajoutées (sous users firestore database)
    //Pour la creation des comptes vendor (Authentication) c'est secondInstanceAuthConifg thirdInstanceAuthConifg fourthInstanceAuthConifg qui seront utilisé
    //Nécéssaires de créer 3 autres instances de firebase sinon erreur lors de la creation du compte vendor
    //Ici Possible de créer 3 comptes vendor par session user
    const secondInstanceAuthConifg = { //PROD
      apiKey: "AIzaSyBhg2jgp3Ppw9b2H_j2pFe4eJblt5fDBVY",
      authDomain: "gbl-ist-ve-dlaaas.firebaseapp.com",
      databaseURL: "https://gbl-ist-ve-dlaaas-default-rtdb.firebaseio.com",
      projectId: "gbl-ist-ve-dlaaas",
      storageBucket: "gbl-ist-ve-dlaaas.appspot.com",
      messagingSenderId: "876376300466",
      appId: "1:876376300466:web:bbe4b42e063f7f535262ab",
      measurementId: "G-V345Q1B3Y4"
    };

    const thirdInstanceAuthConifg = { //PROD
      apiKey: "AIzaSyBhg2jgp3Ppw9b2H_j2pFe4eJblt5fDBVY",
      authDomain: "gbl-ist-ve-dlaaas.firebaseapp.com",
      databaseURL: "https://gbl-ist-ve-dlaaas-default-rtdb.firebaseio.com",
      projectId: "gbl-ist-ve-dlaaas",
      storageBucket: "gbl-ist-ve-dlaaas.appspot.com",
      messagingSenderId: "876376300466",
      appId: "1:876376300466:web:bbe4b42e063f7f535262ab",
      measurementId: "G-V345Q1B3Y4"
    };

    const fourthInstanceAuthConifg = { //PROD
      apiKey: "AIzaSyBhg2jgp3Ppw9b2H_j2pFe4eJblt5fDBVY",
      authDomain: "gbl-ist-ve-dlaaas.firebaseapp.com",
      databaseURL: "https://gbl-ist-ve-dlaaas-default-rtdb.firebaseio.com",
      projectId: "gbl-ist-ve-dlaaas",
      storageBucket: "gbl-ist-ve-dlaaas.appspot.com",
      messagingSenderId: "876376300466",
      appId: "1:876376300466:web:bbe4b42e063f7f535262ab",
      measurementId: "G-V345Q1B3Y4"
    };

    //première creation d'un compte vendor
    if (this.secondInstanceAlreadyInitilized === false) {
      let secondaryApp = firebase.initializeApp(secondInstanceAuthConifg, 'second');

      this.secondInstanceAlreadyInitilized = true; //il y'a 1 compte vendor qui a été créé

        return secondaryApp
          .auth()
          .createUserWithEmailAndPassword(emailVendor, password) //permet de créer le user dans "authentification" de firebase
          .then(res => {
            console.log('VENDOR FIREBASE CREATED');
            console.log('IN secondaryAPP');

            return this.createVendorUserEmailPass(res.user, idSolution, lastnameVendor, firstnameVendor, companyVendor);
          })
          .catch(error => this.handleError(error));
    }

    //2e creation d'un compte vendor
    if (this.secondInstanceAlreadyInitilized === true) {
      const thirdApp = firebase.initializeApp(thirdInstanceAuthConifg, 'third');

      this.thirdInstanceAlreadyInitilized = true; //il y'a 2 comptes vendor qui ont été créés

      return thirdApp
        .auth()
        .createUserWithEmailAndPassword(emailVendor, password) //permet de créer le user dans "authentification" de firebase
        .then(res => {
          console.log('VENDOR FIREBASE CREATED');
          console.log('IN thirdAPP');

          return this.createVendorUserEmailPass(res.user, idSolution, lastnameVendor, firstnameVendor, companyVendor);
        })
        .catch(error => this.handleError(error));
    }

    //3e creation d'un compte vendor
    if (this.thirdInstanceAlreadyInitilized === true) {
      const fourthApp = firebase.initializeApp(fourthInstanceAuthConifg, 'fourth');

      this.fourthInstanceAlreadyInitilized = true; //il y'a 3 comptes vendor qui ont été créés

      return fourthApp
        .auth()
        .createUserWithEmailAndPassword(emailVendor, password) //permet de créer le user dans "authentification" de firebase
        .then(res => {
          console.log('VENDOR FIREBASE CREATED');
          console.log('IN thirdAPP');

          return this.createVendorUserEmailPass(res.user, idSolution, lastnameVendor, firstnameVendor, companyVendor);
        })
        .catch(error => this.handleError(error));
    }
  }

  //Si le vendor n'éxistait pas en base
  //version POST mais csrf token is missing
  // sendRequestShareVendorFirsttime(emailVendor: string, password: string, lastnameVendor: string, firstnameVendor: string, solName: string, rfpName: string, editorBrand: string, buOfSolution: string, userWhoShared: string) {
  //   let url = '/api/request_share_vendor_firsttime';
  //   let data ={'vendorEmail' : emailVendor, 'passwordVendor' : password, 'firstNameVendor': firstnameVendor, 'lastNameVendor': lastnameVendor, 'userWhoShared': userWhoShared, 'bu': buOfSolution, 'solutionName': solName, 'rfpName': rfpName, 'editorBrand': editorBrand};
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type':  'application/json',
  //       //"Access-Control-Allow-Origin": "*",
  //     }),responseType: 'text' as 'json'
  //   };

  //   return this.httpClient.post(url, data, httpOptions).pipe(
  //     catchError(this.handleErrorHttpBasic)
  //   )
  // }

  sendRequestShareVendorFirsttime(emailVendor, password, lastnameVendor, firstnameVendor, solName, rfpName, editorBrand, buOfSolution, userWhoShared) {
    return this.httpClient.get('/api/request_share_vendor_firsttime?vendorEmail='+emailVendor+'&passwordVendor='+password+'&firstNameVendor='+firstnameVendor+'&lastNameVendor='+lastnameVendor+'&userWhoShared='+userWhoShared+'&bu='+buOfSolution+'&solutionName='+solName+'&rfpName='+rfpName+'&editorBrand='+editorBrand, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttpBasic)
    )
  }

  //Si le vendor éxistait en base
  sendRequestShareVendorMoreonetime(emailVendor, lastnameVendor, firstnameVendor, solName, rfpName, editorBrand, buOfSolution, userWhoShared){
    return this.httpClient.get('/api/request_share_vendor_onemoretime?vendorEmail='+emailVendor+'&firstNameVendor='+firstnameVendor+'&lastNameVendor='+lastnameVendor+'&userWhoShared='+userWhoShared+'&bu='+buOfSolution+'&solutionName='+solName+'&rfpName='+rfpName+'&editorBrand='+editorBrand, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttpBasic)
    );
  }

  // Creation du new vendor dans la bd
  //Le mdp n'est pas stocké dans firebase par défaut (selon la doc firestore)
  createVendorUserEmailPass(user, idSolution, lastnameVendor, firstnameVendor, companyVendor) {
    console.log('Création du vendor sous firebase');
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      email: user.email,
      //password: window.localStorage.getItem('randomPassword'), //fonctionne
      //password: this.gestionVendor.password.value, a tester
      //depuis index
      createdAt: new Date(Date.now()),
      lastname: lastnameVendor,
      firstname: firstnameVendor,
      company: companyVendor,
      role: 'Vendor',
      disable: false,
      isVeolia: false,
      type: 'Vendor',
      passwordEdited: false, //le vendor n'a toujours pas changé son mot de passe Firebase
      termsAccepted: false, //par défaut n'a pas encore dit YES aux terms de firstConnection
      createdBy: this.currentUserEmail,// le createur du vendor
      roles: {
        viewer: false,
        solutionOwner: false,
        evaluator: false,
        vendor: true,
        admin: false
      }
    };
    //Conaitre la langue de l'utilisateur
    //firebase.auth().useDeviceLanguage();


        const fire = firebase.firestore();
          fire.collection('users').doc(this.currentUserId).get().then(val => {
              if (val.data().roles.admin === true) {
                this.router.navigate(['/admin']); //rediriger vers le tableau admin
              } else {
                this.router.navigate(['/homeUser']); //rediriger vers le tableau des demandes(solutions)
              }
          })

    this.toastr.info(
      `An email has been sent to ${user.email} with his informations connection !`,
      'Vendor account created',
      {
        timeOut: 10000,
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-center'
      }
    );

    this.toastr.success(user.email + ' has now access to the request', 'Access rights granted', {
      timeOut: 10000,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-center'
    });

    //ajouter le vendor à la demande (solution) pour l'accès
    this.afs
      .doc('solutions/' + idSolution)
      .update({ emailVendorAuthorized: firebase.firestore.FieldValue.arrayUnion(user.email) });

    return userRef.set(data);
  }

  //CAS donner acces à un utilisateur veolia par Viewer
  //Ajoute dans la demande(solution) concernée le nouveau email du user veolia (il aura désormais accès)
  addVeoliaAuthorizedToDemande(id: string, email: string, solName: string, rfpName: string, editorBrand: string, buOfSolution: string, userWhoShared: string, type: string) {
    this.toastr.success(email + ' has now access to the request', 'Access rights granted', {
      timeOut: 10000,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-center'
    });

      const fire = firebase.firestore();
          fire.collection('users').doc(this.currentUserId).get().then(val => {
            if (val.data().roles.admin === true) {
              this.router.navigate(['/admin']); //rediriger vers le tableau admin
            } else {
              this.router.navigate(['/homeUser']); //rediriger vers le tableau des demandes(solutions)
            }
      })

    if(type === 'SaaS') {
      return this.afs
        .doc('solutions/' + id)
        .update({ emailVeoliaAuthorized: firebase.firestore.FieldValue.arrayUnion(email) }).then(res => {
          this.httpClient.get('/api/request_share_veolia_SaaS?veoliaEmail='+email+'&userWhoShared='+userWhoShared+'&bu='+buOfSolution+'&solutionName='+solName+'&rfpName='+rfpName+'&editorBrand='+editorBrand, {responseType:'text'}).subscribe(
              result => console.log('HTTP response API:', result),
              err => console.log('HTTP Error', err),
              () => console.log('HTTP request completed. Request shared with Veolia')
              );

        }).catch(error => this.handleError(error));
    }

    if(type !== 'SaaS') { //IaaS PaaS Apps
      return this.afs
          .doc('solutions/' + id)
        .update({ emailVeoliaAuthorized: firebase.firestore.FieldValue.arrayUnion(email) }).then(res => {
          this.httpClient.get('/api/request_share_veolia?veoliaEmail='+email+'&userWhoShared='+userWhoShared+'&bu='+buOfSolution+'&solutionName='+solName+'&editorBrand='+editorBrand+'&type='+type, {responseType:'text'}).subscribe(
              result => console.log('HTTP response API:', result),
              err => console.log('HTTP Error', err),
              () => console.log('HTTP request completed. Request shared with Veolia')
              );

        }).catch(error => this.handleError(error));
    }
  }

  //Lorsque le Sol Owner d'une solution change et indique un autre SO à la place place
  addVeoliaAuthorizedToDemandeAndSolOwner(id: string, email: string, solName: string, rfpName: string, editorBrand: string, buOfSolution: string, userWhoShared: string, type: string) {
    this.toastr.success(email + ' has now access to the request and become the new solution owner', 'Access rights granted', {
      timeOut: 10000,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-bottom-center'
    });

      const fire = firebase.firestore();
          fire.collection('users').doc(this.currentUserId).get().then(val => {
            if (val.data().roles.admin === true) {
              this.router.navigate(['/admin']); //rediriger vers le tableau admin
            } else {
              this.router.navigate(['/homeUser']); //rediriger vers le tableau des demandes(solutions)
            }
      })

    if(type === 'SaaS') {
      return this.afs
      .doc('solutions/' + id)
      .update({ emailVeoliaAuthorized: firebase.firestore.FieldValue.arrayUnion(email), solutionOwner: email }).then(res => {
        this.httpClient.get('/api/request_share_veolia_and_new_solowner_SaaS?veoliaEmail='+email+'&userWhoShared='+userWhoShared+'&bu='+buOfSolution+'&solutionName='+solName+'&rfpName='+rfpName+'&editorBrand='+editorBrand, {responseType:'text'}).subscribe(
            result => console.log('HTTP response API:', result),
            err => console.log('HTTP Error', err),
            () => console.log('HTTP request completed. Request shared with Veolia')
            );

      }).catch(error => this.handleError(error));
    }

    if(type !== 'SaaS') {
      return this.afs
      .doc('solutions/' + id)
      .update({ emailVeoliaAuthorized: firebase.firestore.FieldValue.arrayUnion(email), solutionOwner: email }).then(res => {
        this.httpClient.get('/api/request_share_veolia_and_new_solowner?veoliaEmail='+email+'&userWhoShared='+userWhoShared+'&bu='+buOfSolution+'&solutionName='+solName+'&rfpName='+rfpName+'&editorBrand='+editorBrand+'&type='+type, {responseType:'text'}).subscribe(
            result => console.log('HTTP response API:', result),
            err => console.log('HTTP Error', err),
            () => console.log('HTTP request completed. Request shared with Veolia')
            );

      }).catch(error => this.handleError(error));
    }
  }
  //////////////////FIN GESTION VENDOR////////////////////////////////////////////////////

  //Connexion vendor dans la modale
  loginEmail(email, pass) {
  const fire = firebase.firestore();

    return new Promise((resolve, reject) => {

        //FIREBASE
          this.afAuth.signInWithEmailAndPassword(email, pass).then(userData => {

            const userDoc = this.firestore.collection('users').doc(userData.user.uid);
                //ajout champ dernière connexion à chaque authent sur dla
                 userDoc.update({ lastLoggedIn: new Date(Date.now()), browser: window.navigator.userAgent });

            firebase.auth().onAuthStateChanged(function(user) { //ou firebase.auth().onAuthStateChanged(user => {
              if (user) {
                // User is signed in. //lors de la connexion du user
                console.log("USER IS SIGNED IN")
              } else {
                // No user is signed in. //lors de la déconnexion du user
                console.log("USER IS LOGGED OUT")
              }
            });

                //on check si le user Vendor a accepté les terms sinon redirection sur page firstConnection
                fire.collection('users').doc(userData.user.uid).get().then(val => {

                  //si termsAccepted = false alors le vendor n'a pas accepté les terms
                  if(val.data().termsAccepted === false) {
                    console.log('Terms not accepted');
                    this.router.navigate(['/firstConnection/' + userData.user.uid]);
                    console.log('VENDOR user connected !'); //si passwordEdited = false il sera redirigé vers editPassword/ (check fait dans firstConnection.ts)

                  } else { //Si terms OK //On check si le vendor a déjà changé son mot de passe
                      if(val.data().passwordEdited === false) {
                        console.log('Password not edited yet');
                        this.router.navigate(['/editPassword/' + userData.user.uid]);
                        this.toastr.warning('Please change your password now !', 'Password changing required', {
                          timeOut: 9000,
                          closeButton: true,
                          progressBar: true,
                          positionClass: 'toast-top-center'
                        })
                      } else {//sinon redirection vers homeUser
                        //faire juste un userupdatedata pour pas perdre les données lors de la reconnexion
                        console.log('Infos user complete, + de 1 connexion !');
                        this.router.navigate(['/homeUser']);
                        console.log('VENDOR user connected !');
                      }
                  }

                });


            resolve(userData)
          }).catch(error => this.handleErrorPassword(error)); //SI mdp invalide;

          (window as any).closeLoginAndForgotPassAndNewPass(); //appel dans index pour fermer entierement la modale login et forgot pass (evite les confilts avec les autres modales)

        });
  }

  //Nécessaire avant de faire un update du password appelé par editPassword
  reauthenticateForEditPass(oldPass: string, newPass: string) {
    const user = firebase.auth().currentUser;

    const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPass);
    console.log('before', credential);

    return user.reauthenticateWithCredential(credential)
      .then(newCredential => { //Si ancien mdp valide
        console.log("after",newCredential);

        this.changePassword(newPass);

      })
      .catch(error => this.handleErrorPassword(error)); //SI ancien mdp invalide
  }

  changePassword(newPass: string) {
    const user = firebase.auth().currentUser;
    const userDetail = firebase.auth().currentUser;

    return user
      .updatePassword(newPass)
      .then(res => {
          this.toastr.success('Password successfuly updated !', '', {
            timeOut: 9000,
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-center'
          });

          this.router.navigate(['/homeUser']);
          console.log('Password firebase changed !');

          this.updateInfosUser(user.uid); //passwordEdited = true

          this.sendPasswordUpdatedAcknowledgment(user.email).subscribe(data => {
            if (data !== "200") {
              console.error("KO mail retarus unsent !")
            } else {
                console.log("OK mail retarus sent !")
            }
              console.log("from api: " + data);
          });

      }).catch(error => this.handleErrorPassword(error));

  }

  //editPassword
  updateInfosUser(currentUserId) {
    return this.afs
      .collection('users', ref => ref.where('uid', '==', currentUserId))
      .doc(currentUserId)
      .update({passwordEdited : true});
  }

  //editPassword
  sendPasswordUpdatedAcknowledgment(currentUserEmail){
    return this.httpClient.get('/api/password_updated_acknowledgment?emailVendor='+currentUserEmail, {responseType:'text'}).pipe(
      catchError(error => this.handleErrorHttpBasic(error))
    );
  }

  //Réinitialisé le mot de passe appelé depuis admin.ts
  resetPassword(email: string) {
    let auth = firebase.auth();
    return auth
      .sendPasswordResetEmail(email)
      .then(
        () =>
          this.toastr.success(`Reset password sent to ${email}`, '', {
            timeOut: 7000,
            progressBar: true,
            closeButton: true,
            positionClass: 'toast-bottom-center'
          }),

      )
      .catch(error => this.handleError(error))
      .catch(error => console.log(error));
  }



  //Abilities and roles authorization
  isAdmin(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  isViewer(user: User): boolean {
    const allowed = ['viewer'];
    return this.checkAuthorization(user, allowed);
  }

  isSolutionOwner(user: User): boolean {
    const allowed = ['solutionOwner'];
    return this.checkAuthorization(user, allowed);
  }

  isVendor(user: User): boolean {
    const allowed = ['vendor'];
    return this.checkAuthorization(user, allowed);
  }

  isEvaluator(user: User): boolean {
    const allowed = ['evaluator'];
    return this.checkAuthorization(user, allowed);
  }

  isVeolia(user: User): boolean {
    const allowed = ['evaluator', 'admin', 'viewer', 'solutionOwner'];
    return this.checkAuthorization(user, allowed);
  }

  //detremine si le user à le role correspondant
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) { return false  }
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }

  public handleErrorHttpBasic(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }

}
