<div *ngIf="!isHomeRoute()" fxFlex>

  <app-header></app-header>
  <router-outlet></router-outlet>
 
</div>

<div *ngIf="isHomeRoute()" fxFlex 
style="background-image: url('https://storage.googleapis.com/gbl-ist-ve-dlaaas.appspot.com/veolia-day.jpg');
background-repeat: no-repeat;
 background-size: 1950px 1120px;
">
  
<!--  <div *ngIf="isHomeRoute()" fxFlex -->
<!--style="background-image: url('https://storage.googleapis.com/gbl-ist-ve-dlaaas.appspot.com/veolia-night.jpg');-->
<!--background-repeat: no-repeat;-->
<!-- background-size: 1750px 1120px;-->
<!--">-->
  
  <app-header></app-header>
  <router-outlet></router-outlet>

</div>

