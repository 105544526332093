import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../core/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})


export class EditPasswordService {
  
  constructor(private firestore: AngularFirestore, private authService: AuthService, private httpClient: HttpClient, private toastr: ToastrService,) {}

  getUidUser() {
    return this.authService.currentUserId;
  }

  //récupérer les informations du user connues via URL
  getInformationsUser(id: string) {
    return this.firestore
      .collection('users')
      .doc(id)
      .snapshotChanges();
  }
  
   public handleErrorHttp3(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
    }
    
}
