import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { EditPasswordService } from './editPassword.service';

import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-editPassword',
  templateUrl: './editPassword.component.html',
  styleUrls: ['./editPassword.component.scss']
})
export class EditPasswordComponent implements OnInit {
  editPassForm: FormGroup;  
  
  submitted = false;
  
  terms = false;
  
  public password: string;
  
  //pwd = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.+[~*:!@%-_/]).{12}$"); // au moins 1 chiffre, au moins 1 lettre min et 1 lettre maj, au moins 1 car spécial / 12 de longeur peu importe l'ordre

  
  public oldPassword: string;
  public newPassword: string;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private service: EditPasswordService,
    public auth: AuthService, //toujours utilisé dans le cas ou on veut faire des {{user.?}}
    private authService: AuthService,
    private firestore: AngularFirestore,
  ) {}

  item: any;
  

  ngOnInit() {
    //récupère depuis l'URL
    this.route.data.subscribe(routeData => {
      const data = routeData['data'];
      if (data) {
        this.item = data.payload.data();
        this.item.id = data.payload.id;
        
        this.createForm();
      }
    });
    
    this.togglePassword();
    
  }
  
  togglePassword() {
    this.delay(4500).then(any => {
        const togglePassword = document.querySelector('#seePassword');
      const password = document.querySelector('#pwd');
        
      togglePassword.addEventListener('click', function (e) {
        // toggle the type attribute
        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        // toggle the eye / eye slash icon
        togglePassword.textContent = "visibility";
        
      });
    
    
    const togglePassword2 = document.querySelector('#seePassword2');
      const password2 = document.querySelector('#pwd2');
      
      togglePassword2.addEventListener('click', function (e) {
        // toggle the type attribute
        const type2 = password2.getAttribute('type') === 'password' ? 'text' : 'password';
        password2.setAttribute('type', type2);
        // toggle the eye / eye slash icon
        togglePassword2.textContent = "visibility";
        
      });
      
    });
  }
    
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }
  
  
  createForm() {
    //NOMFORMCONTROLENAME=item.NOMDANSLABD
    this.editPassForm = this.fb.group({
      uid: [this.service.getUidUser()],
      oldPassword: [ 
        //'',[Validators.pattern(this.pwd), Validators.required]
        '',[Validators.required]
      ],
      newPassword: [
        //'',[Validators.pattern(this.pwd), Validators.required]
        '',[Validators.required]
      ]
    });
  }
  
   get f() {
    return this.editPassForm.controls;
  }
  

  onSubmit() {
    this.submitted = true;
    
    //Si un des 2 mots de passe ne respecte pas l'expression régulière
    if (this.editPassForm.get('oldPassword').invalid) {
      this.toastr.warning('', "Your current password doesn't match with required characters", {
        timeOut: 9000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      });
    }
    if (this.editPassForm.get('newPassword').invalid) {
      this.toastr.warning('', "Your new password doesn't match with required characters", {
        timeOut: 9000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      });
    }
    
    //Si les 2 respectes l'expression régulière et n'ont pas d'erreur
    if (this.editPassForm.get('oldPassword').valid && this.editPassForm.get('newPassword').valid) {

      const oldPass = this.editPassForm.get('oldPassword').value; //= this.oldPassword
      const newPass = this.editPassForm.get('newPassword').value; //= this.newPassword
      
      //CHANGEMENT PASS FIREBASE
      //reauthenticate + changement de pass dans authservice
      try {
        this.auth.reauthenticateForEditPass(this.oldPassword, this.newPassword)
      } catch(e) {
          console.log(e);
        }
        
    }
    
  }
  
  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.toastr.error(error.message, 'Error', {
      timeOut: 10000,
      positionClass: 'toast-top-center'
    });
  }
  
  
}
