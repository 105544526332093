import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../../material.module';

import { LogsRoutingModule } from './logs-routing.module';

import { LogsComponent } from './logs.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    LogsRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [LogsComponent],
  exports: []
})
export class LogsModule {}
