<div class="my-overlay" *ngIf="showOverlay"><em class="fa fa-spinner fa-spin" aria-hidden="true"></em></div>

<mat-card>
  <mat-card-content>

      <div *ngIf="(auth.user | async) as user">

      <section>

        <button mat-raised-button
          class="submit-button legend"
          data-toggle="modal"
          data-target="#modalLegend"
          onclick="modalMovable()" >{{'Legend' | translate}}
        </button>

        <!-- Modal légende -->
        <div
            class="modal fade"
            id="modalLegend"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            style="margin-top:60px"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
                <div class="modal-header" >
                  {{ 'Legend and color code' | translate }}
                  <button class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" style="width:470px;">
                  <div class="container" style="display:inline; text-align:left">
                    <p translate>Chaque formulaire est identifié par un statut et une couleur:</p>
                    <ul>
                      <li><strong style="color: grey">Empty</strong>: {{'Empty status' | translate}}</li>
                      <li><strong style="color: grey">New</strong>: {{'New status' | translate}}</li>
                      <li><strong style="color: #54bbba">Vendor is responding</strong>: {{'Vendor is responding status' | translate}}</li>
                      <li><strong style="color: purple">Completed</strong>: {{'Completed status' | translate}}</li>
                      <li><strong style="color: blue">DLA is reviewing</strong>: {{'DLA is reviewing status' | translate}}</li>
                      <li><strong style="color: green">Recommended</strong>: {{'Recommended status' | translate}}</li>
                      <li><strong style="color: orange">Reservations</strong>: {{'Recommended with reservations status' | translate}}</li>
                      <li><strong style="color: red">Not recommended</strong>: {{'Not recommended status' | translate}}</li>
                      <li><strong style="color: black">Need help</strong>: {{'Need help status' | translate}}</li>
                      <li><strong style="color: red">Canceled</strong>: {{'Canceled status' | translate}}</li>
                      <li><strong style="color: red">Pending</strong>: {{'Pending status' | translate}}</li>
                    </ul>

                    <hr>
                    <p><span class="material-icons">zoom_in</span>: {{'more icon' | translate}}</p>
                    <p><span class="material-icons">zoom_out</span>: {{'less icon' | translate}}</p>
                    <p>{{'Différentes actions sont possibles sous' | translate}} <span class="material-icons">more_horiz</span> :</p>
                    <p *ngIf="auth.isVeolia(user)"><em class="material-icons text-primary btnEdit"> edit </em>: {{'edit button' | translate}}</p>
                    <p *ngIf="auth.isVeolia(user) || auth.isVendor(user)"><em class="material-icons text-warning btnShare"> share </em>: {{'share button' | translate}}</p>
                    <!--<p *ngIf="auth.isVeolia(user)"><em class="material-icons text-danger btnDelete"> delete </em>: {{'delete button' | translate}}</p>-->
                    <p *ngIf="auth.isVeolia(user) || auth.isVendor(user)"><em class="material-icons text-success btnSeeEval"> event_available </em>: {{'seeEval button' | translate}}</p>
                    <p *ngIf="auth.isVeolia(user)"><em class="material-icons text-primary btnLogs"> format_list_bulleted </em>: {{'logs button' | translate}}</p>
                  </div>
                </div>
                <!-- END Body -->
              </div>
            </div>
          </div>
        <!-- END MODALE -->

        <!--DOCS ADMIN-->
        <div *ngIf="auth.isAdmin(user)" style="text-align: end;">
          <a target="_blank" rel="noopener" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
          mat-raised-button type="button" style="color: #54bbba;"
          title="{{'Admin documentation' | translate}}"
          ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
          </a>

          <a target="_blank" rel="noopener" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
          mat-raised-button type="button" style="color: #54bbba;"
          title="{{'Admin documentation' | translate}}"
          ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
          </a>
        </div>
        <!--END ADMIN DOCS-->

        <!--DOCS VEOLIA-->
        <div *ngIf="auth.isVeolia(user)" style="text-align: end; margin-top:5px">
          <a target="_blank" rel="noopener" href="https://docs.google.com/presentation/d/1kqMltFibPvQjwLI2bcVVdQAQ2w7Stf-MIw78wsZUkOI/edit#slide=id.g85055ddc33_0_1700"
          mat-raised-button type="button" style="color: #54bbba;"
          title="{{'Veolia documentation' | translate}}"
          ><strong translate>Veolia documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
          </a>

          <br />
          <br />
          <a *ngIf="!auth.isEvaluator(user)" title="{{ 'Nouvelle demande DLA' | translate }}" style="text-align: end;" routerLink="/demandeDLA"
          mat-raised-button type="button" style="color: #54bbba; float: left"><strong translate>Nouvelle demande DLA</strong>
          <span class="material-icons">note_add</span>
          </a>

          <br />
          <br />
          <a *ngIf="auth.isAdmin(user)" style="text-align: end;" routerLink="/admin"
          mat-raised-button type="button" style="color: #54bbba; margin-right: 5px;"
          title="{{'Admin dashboard' | translate}}"
          ><strong translate>Go to Admin dashboard</strong>
          </a>
        </div>
        <!--END VEOLIA DOCS-->

        <!--DOCS VENDOR-->
        <div *ngIf="auth.isVendor(user)" style="text-align: end;">
          <a target="_blank" rel="noopener" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/vendor_doc%2FDLA%20DLAaaS%20Vendor%20Guide.pdf?alt=media&token=f3e33a65-afc7-48d4-8b30-1d2e5d692d92"
          mat-raised-button type="button" style="color: #54bbba;"
          title="{{'Vendor documentation' | translate}}"
          ><strong translate>Vendor documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
          </a>
        </div>
        <!--END VENDOR DOCS-->

      </section>

      <!--VEOLIA-->
      <div *ngIf="auth.isVeolia(user)">
        <!--Onglets haut: SaaS PaaS IaaS Apps Users-->
        <mat-tab-group (selectedTabChange)="tabChanged($event)">

          <!--DASHBOARDS SaaS-->
          <mat-tab label="SaaS" style="justify-content: center;" aria-label="SaaStab">
            <mat-card>
              <mat-card-content>

                <!--SaaS-->
                <mat-tab-group (selectedTabChange)="tabChanged($event)">
                  <!--Demandes SaaS du user + partagées-->
                  <mat-tab label="{{'My requests SaaS' | translate}}" aria-label="UserReqSaaS">

                    <!-- VEOLIA -->
                    <!--
                      Demandes du l'utilisateur connecté (lorsque c'est lui le solution Owner mais pas forcément l'initiateur) car plus révélateur de connaitre le so que l'initiateur
                    -->
                      <mat-card-title translate class="titleSection">Mes demandes effectuées (dont je suis Solution Owner)</mat-card-title>
                      <table data-order='[[ 7, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="User SaaS requests" onclick="hideColumnsTab1()">
                        <thead>
                        <tr>
                          <th title="RFP name">
                            {{ 'Nom RFP' | translate
                            }}
                          </th>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                          <th title="Global status of request"><!--statut global-->
                            {{ 'DLA recommendation' | translate
                            }}
                          </th>
                          <th title="Status of Design Form">
                            {{ 'Design' | translate
                            }}
                          </th>
                          <th title="Status of Legal Form">
                            {{ 'Legal' | translate
                            }}
                          </th>
                          <!--<th title="Status of Article 28 Form">-->
                          <!--  {{ 'Art 28' | translate-->
                          <!--  }}-->
                          <!--</th>-->
                          <th title="Status of Article 32 Form">
                            {{ 'Art 32' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                          <!--Hidden par défaut et affichable au clic du bouton-->
                            <th title="Expected completion date of forms" style="display:none" class="moreTab1">
                              {{ 'Date attendue completion' | translate
                              }}
                            </th>
                            <th title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                              {{ 'Date attendue eval' | translate
                              }}
                            </th>
                            <th title="Type of solution" style="display:none" class="moreTab1">
                              {{ 'Type' | translate
                              }}
                            </th>
                            <th title="Business capability" style="display:none" class="moreTab1">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                          <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <!-- Recherche par nom de solution -->
                          <tr
                            *ngFor="
                              let demande of demandesSaaSUser;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <td>{{ demande.rfpName }}
                              <a
                                class="modale_elem"
                                data-toggle="modal"
                                data-target="#modalOverviewSolution"
                                title="{{'recap demande/formulaire' | translate}}"
                                (click)="openDialog(demande.id)"
                                onclick="modalMovable()"
                                >
                                <em class="fa fa-info-circle" aria-hidden="true"></em>
                              </a>
                            </td>
                            <!-- formulaires-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection"
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>

                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoDesignForm(demande.id)"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    (click)="gotoLegalForm(demande.id)"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle32Form(demande.id)"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle28Form(demande.id)"
                                    >Annexes</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Annexes</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                  </div>
                                </div>
                              </mat-menu>
                              <!--Actions buttons-->
                              <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="btn text-primary"
                                  (click)="modifierDemande(demande.id,'SaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'SaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <!--<a-->
                                <!--  class="btn text-danger"-->
                                <!--  (click)="abortSolution(demande.id)"-->
                                <!--  title="Avorter la demande {{demande.solutionName}}"-->
                                <!--  ><em class="material-icons btnDelete"> delete </em></a-->
                                <!-->-->
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                                <a
                                  class="btn text-primary"
                                  (click)="modifierDemande(demande.id,'SaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                                <!--<a-->
                                <!--  class="btn text-danger"-->
                                <!--  (click)="abortSolution(demande.id)"-->
                                <!--  title="Avorter la demande {{demande.solutionName}}"-->
                                <!--  ><em class="material-icons btnDelete"> delete </em></a-->
                                <!-->-->
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                            </td>
                            <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                            <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                            <!--<td><p id="article28Sta{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>-->
                            <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                             <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>

                      <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>

                      <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>

                      <div *ngIf="!demandesSaaSUser || demandesSaaSUser.length === 0" style="text-align:center">
                        <strong style="color:red" translate>No record</strong>
                      </div>

                    <br />
                    <hr>

                    <!-- Demandes qui ont été partagées au user connecté (et dont il n'est pas le solution owner) -->
                      <mat-card-title translate class="titleSection">Demandes dont je suis collaborateur</mat-card-title>
                      <table data-order='[[ 7, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="Shared SaaS requests" onclick="hideColumnsTab2()">
                        <thead>
                        <tr>
                          <th title="RFP name">
                            {{ 'Nom RFP' | translate
                            }}
                          </th>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                          <th title="Global status of request"><!--statut global-->
                            {{ 'DLA recommendation' | translate
                            }}
                          </th>
                          <th title="Status of Design Form">
                            {{ 'Design' | translate
                            }}
                          </th>
                          <th title="Status of Legal Form">
                            {{ 'Legal' | translate
                            }}
                          </th>
                          <!--<th title="Status of Article 28 Form">-->
                          <!--  {{ 'Art 28' | translate-->
                          <!--  }}-->
                          <!--</th>-->
                          <th title="Status of Article 32 Form">
                            {{ 'Art 32' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                          <!--Hidden par défaut et affichable au clic du bouton-->
                              <th title="Expected completion date of forms" style="display:none" class="moreTab2">
                                {{ 'Date attendue completion' | translate
                                }}
                              </th>
                              <th title="Expected evaluation date of forms" style="display:none" class="moreTab2">
                                {{ 'Date attendue eval' | translate
                                }}
                              </th>
                              <th title="Current solution owner of solution" style="display:none" class="moreTab2">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                              <th title="Type of solution" style="display:none" class="moreTab2">
                                {{ 'Type' | translate
                                }}
                              </th>
                              <th title="Business capability" style="display:none" class="moreTab2">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                            <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let demande of demandesSaaSUserPartagees;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <td>{{ demande.rfpName }}
                              <a
                                class="modale_elem"
                                data-toggle="modal"
                                data-target="#modalOverviewSolution"
                                title="{{'recap demande/formulaire' | translate}}"
                                (click)="openDialog(demande.id)"
                                onclick="modalMovable()"
                              >
                                <em class="fa fa-info-circle" aria-hidden="true"></em>
                              </a>
                            </td>
                            <!-- formulaires-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection"
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>

                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoDesignForm(demande.id)"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    (click)="gotoLegalForm(demande.id)"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle32Form(demande.id)"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle28Form(demande.id)"
                                    >Annexes</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Annexes</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                </div>
                                </div>
                              </mat-menu>
                              <!--Actions buttons-->
                              <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'SaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                              <!--Empty-->
                              </div>
                               <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalStaa{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              </td>
                            <td><p id="designStaa{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                            <td><p id="legalStaa{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                            <!--<td><p id="article28Staa{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>-->
                            <td><p id="article32Staa{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab2">{{ demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab2">{{ demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab2">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab2">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab2">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>

                        <em onclick="displayColumnsTab2()" id="btnDisplayColumnsTab2" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                          <span class="material-icons">zoom_in</span>
                        </em>

                        <em onclick="hideColumnsTab2()" id="btnHideColumnsTab2" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                          <span class="material-icons">zoom_out</span>
                        </em>

                      <div *ngIf="!demandesSaaSUserPartagees || demandesSaaSUserPartagees.length === 0" style="text-align:center">
                            <strong style="color:red" translate>No record shared</strong>
                      </div>

                    <br />
                    <hr>

                    <!-- Demandes dont le user est dans emailEvaluatorAuthorized -->
                      <mat-card-title translate class="titleSection">Demandes dont je suis nommé evaluateur</mat-card-title>
                      <table data-order='[[ 7, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="Named as evaluator SaaS requests" onclick="hideColumnsTab3()">
                        <thead>
                        <tr>
                          <th title="RFP name">
                            {{ 'Nom RFP' | translate
                            }}
                          </th>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                          <th title="Global status of request"><!--statut global-->
                            {{ 'DLA recommendation' | translate
                            }}
                          </th>
                          <th title="Status of Design Form">
                            {{ 'Design' | translate
                            }}
                          </th>
                          <th title="Status of Legal Form">
                            {{ 'Legal' | translate
                            }}
                          </th>
                          <!--<th title="Status of Article 28 Form">-->
                          <!--  {{ 'Art 28' | translate-->
                          <!--  }}-->
                          <!--</th>-->
                          <th title="Status of Article 32 Form">
                            {{ 'Art 32' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                           <!--Hidden par défaut et affichable au clic du bouton-->
                              <th title="Expected completion date of forms" style="display:none" class="moreTab3">
                                {{ 'Date attendue completion' | translate
                                }}
                              </th>
                              <th title="Expected evaluation date of forms" style="display:none" class="moreTab3">
                                {{ 'Date attendue eval' | translate
                                }}
                              </th>
                              <th title="Current solution owner of solution" style="display:none" class="moreTab3">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                              <th title="Type of solution" style="display:none" class="moreTab3">
                                {{ 'Type' | translate
                                }}
                              </th>
                              <th title="Business capability" style="display:none" class="moreTab3">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                            <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let demande of demandesSaaSUserEvaluatorAuthorized;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <td>{{ demande.rfpName }}
                              <a
                                class="modale_elem"
                                data-toggle="modal"
                                data-target="#modalOverviewSolution"
                                title="{{'recap demande/formulaire' | translate}}"
                                (click)="openDialog(demande.id)"
                                onclick="modalMovable()"
                                >
                                <em class="fa fa-info-circle" aria-hidden="true"></em>
                              </a>
                            </td>
                            <!-- formulaires-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection"
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>

                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoDesignForm(demande.id)"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    (click)="gotoLegalForm(demande.id)"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle32Form(demande.id)"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle28Form(demande.id)"
                                    >Annexes</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Annexes</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                </div>
                                </div>
                              </mat-menu>
                              <!--Actions buttons-->
                              <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'SaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                              <!--Empty-->
                              </div>
                               <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalStaaa{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              </td>
                            <td><p id="designStaaa{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                            <td><p id="legalStaaa{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                            <!--<td><p id="article28Staa{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>-->
                            <td><p id="article32Staaa{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab3">{{ demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab3">{{ demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab3">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab3">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab3">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>

                        <em onclick="displayColumnsTab3()" id="btnDisplayColumnsTab3" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                          <span class="material-icons">zoom_in</span>
                        </em>

                        <em onclick="hideColumnsTab3()" id="btnHideColumnsTab3" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                          <span class="material-icons">zoom_out</span>
                        </em>

                      <div *ngIf="!demandesSaaSUserEvaluatorAuthorized || demandesSaaSUserEvaluatorAuthorized.length === 0" style="text-align:center">
                            <strong style="color:red" translate>No record shared</strong>
                      </div>

                  </mat-tab>

                  <!--Demandes SaaS de la BU du user-->
                  <mat-tab label="{{'Requests (from my BU)' | translate }}" aria-label="UserBuSaaSReq">
                      <mat-card-title class="titleSection"
                        >{{ 'Requests (from my BU)' | translate }}
                      </mat-card-title>
                      <table data-order='[[ 7, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All SaaS requests from my BU" onclick="hideColumnsTab1()">
                        <thead>
                        <tr>
                          <th title="RFP name">
                            {{ 'Nom RFP' | translate
                            }}
                          </th>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                            <th title="Global status of request"><!--statut global-->
                              {{ 'DLA recommendation' | translate
                              }}
                            </th>
                          <th title="Status of Design Form">
                            {{ 'Design' | translate
                            }}
                          </th>
                          <th title="Status of Legal Form">
                            {{ 'Legal' | translate
                            }}
                          </th>
                          <!--<th title="Status of Article 28 Form">-->
                          <!--  {{ 'Art 28' | translate-->
                          <!--  }}-->
                          <!--</th>-->
                          <th title="Status of Article 32 Form">
                            {{ 'Art 32' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                           <!--Hidden par défaut et affichable au clic du bouton-->
                              <th title="Expected completion date of forms" style="display:none" class="moreTab1">
                                {{ 'Date attendue completion' | translate
                                }}
                              </th>
                              <th title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                                {{ 'Date attendue eval' | translate
                                }}
                              </th>
                              <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                              <th title="Type of solution" style="display:none" class="moreTab1">
                                {{ 'Type' | translate
                                }}
                              </th>
                              <th title="Business capability" style="display:none" class="moreTab1">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                            <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let demande of demandesSaaSUserBu;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <td>{{ demande.rfpName }}
                              <a
                                class="modale_elem"
                                data-toggle="modal"
                                data-target="#modalOverviewSolution"
                                title="{{'recap demande/formulaire' | translate}}"
                                (click)="openDialog(demande.id)"
                                onclick="modalMovable()"
                                >
                                <em class="fa fa-info-circle" aria-hidden="true"></em>
                              </a>
                            </td>
                                   <!-- formulaires-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection"
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>

                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoDesignForm(demande.id)"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    (click)="gotoLegalForm(demande.id)"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle32Form(demande.id)"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle28Form(demande.id)"
                                    >Annexes</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Annexes</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                  </div>
                                </div>
                              </mat-menu>
                            <!--Actions buttons-->
                             <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'SaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                                <!--Empty-->
                              </div>
                               <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              </td>
                            <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                            <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                            <!--<td><p id="article28Staaaa{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>-->
                            <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>

                      <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>

                      <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>

                      <div *ngIf="!demandesSaaSUserBu || demandesSaaSUserBu.length === 0" style="text-align:center">
                            <strong style="color:red" translate>No record bu</strong>
                      </div>
                  </mat-tab>

                  <!--Demandes SaaS des autres BU-->
                  <mat-tab label="{{'Requests (from other BUs)' | translate }}" aria-label="UserOtherBuSaaSReq">
                  <!-- tableau de l'ensemble des demandes SaaS qui n'appartiennent pas à la BU du user et ne sont pas pending -->
                      <mat-card-title class="titleSection"
                        >{{ 'Requests (from other BUs)' | translate }}
                      </mat-card-title>
                      <table data-order='[[ 7, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All SaaS requests from other BUs" onclick="hideColumnsTab1()">
                        <thead>
                        <tr>
                          <th title="RFP name">
                            {{ 'Nom RFP' | translate
                            }}
                          </th>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                            <th title="Global status of request"><!--statut global-->
                              {{ 'DLA recommendation' | translate
                              }}
                            </th>
                          <th title="Status of Design Form">
                            {{ 'Design' | translate
                            }}
                          </th>
                          <th title="Status of Legal Form">
                            {{ 'Legal' | translate
                            }}
                          </th>
                          <!--<th title="Status of Article 28 Form">-->
                          <!--  {{ 'Art 28' | translate-->
                          <!--  }}-->
                          <!--</th>-->
                          <th title="Status of Article 32 Form">
                            {{ 'Art 32' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                          <!--Hidden par défaut et affichable au clic du bouton-->
                              <th title="Expected completion date of forms" style="display:none" class="moreTab1">
                                {{ 'Date attendue completion' | translate
                                }}
                              </th>
                              <th title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                                {{ 'Date attendue eval' | translate
                                }}
                              </th>
                              <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                              <th title="Type of solution" style="display:none" class="moreTab1">
                                {{ 'Type' | translate
                                }}
                              </th>
                              <th title="Business capability" style="display:none" class="moreTab1">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                            <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let demande of demandesSaaSUserOtherBu;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <td>{{ demande.rfpName }}</td>
                            <!-- formulaires-->
                            <td>
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  {{ demande.solutionName }}
                                </div>
                                <div *ngIf="demande.status === 'Pending'" style="display: contents">
                                  {{ demande.solutionName }}
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  {{ demande.solutionName }}
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                  </div>
                                </div>
                                <!--Actions buttons-->
                                <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                                <!--Empty-->
                              </div>
                               <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                              </div>
                            </td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate ? (demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd') : '' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate ? (demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd'): '' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                            <td>
                              <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              </td>
                            <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                            <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                            <!--<td><p id="article28Sta{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>-->
                            <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">    
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <!--<td *ngIf="demande.lastUpdate !== 'No forms completed yet'">-->
                            <!--  {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}-->
                            <!--</td>-->
                            <!-- Il y a eu une modif des forms -->
                            <td>{{ (demande.lastUpdate !== '' && demande.lastUpdate !== 'No forms completed yet') ? (demande.lastUpdate.toDate() | date: 'yyyy/MM/dd') : demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                          </tr>
                        </tbody>

                      </table>

                      <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>

                      <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>

                      <div *ngIf="!demandesSaaSUserOtherBu || demandesSaaSUserOtherBu.length === 0" style="text-align:center">
                            <strong style="color:red" translate>No request all others bu</strong>
                      </div>
                  </mat-tab>

                </mat-tab-group>
                <!--END SaaS-->

              </mat-card-content>
            </mat-card>
          </mat-tab>
          <!--END DASHBOARDS SaaS-->

          <!--DASHBOARDS IaaS-->
          <mat-tab label="IaaS" style="justify-content: center;" aria-label="IaaStab">
            <mat-card>
              <mat-card-content>

                <!--IaaS-->
                <mat-tab-group (selectedTabChange)="tabChanged($event)">
                  <!--Demandes IaaS du user + partagées-->
                  <mat-tab label="{{'My requests IaaS' | translate}}" aria-label="UserReqIaaS" selected>
                    <!-- VEOLIA -->
                    <!--
                      Demandes du l'utilisateur connecté (lorsque c'est lui le solution Owner mais pas forcément l'initiateur) car plus révélateur de connaitre le so que l'initiateur
                    -->
                      <mat-card-title translate class="titleSection"
                        >Mes demandes effectuées (dont je suis Solution Owner)</mat-card-title
                      >
                      <table data-order='[[ 4, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="User IaaS requests" onclick="hideColumnsTab1()">
                        <thead>
                        <tr>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                          <th title="Global status of request"><!--statut global-->
                            {{ 'DLA recommendation' | translate
                            }}
                          </th>
                          <th title="Status of IaaS form">
                            {{ 'IaaS' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                           <!--Hidden par défaut et affichable au clic du bouton-->
                           <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                            <th title="Type of solution" style="display:none" class="moreTab1">
                              {{ 'Type' | translate
                              }}
                            </th>
                            <th title="Business capability" style="display:none" class="moreTab1">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                          <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <!-- Recherche par nom de solution -->
                          <tr
                            *ngFor="
                              let demande of demandesIaaSUser;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <!-- formulaire-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection"
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>

                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoIaaSForm(demande.id)"
                                    >IaaS</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >IaaS</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                </div>
                                </div>
                              </mat-menu>
                              <!--Actions buttons-->
                              <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="modale_elem"
                                  data-toggle="modal"
                                  data-target="#modalOverviewSolution"
                                  title="{{'recap demande/formulaire' | translate}}"
                                  (click)="openDialog(demande.id)"
                                  onclick="modalMovable()"
                                  >
                                  <em class="fa fa-info-circle" aria-hidden="true"></em>
                                </a>
                                <a
                                  class="btn text-primary"
                                  (click)="modifierDemande(demande.id,'IaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'IaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <!--<a-->
                                <!--  class="btn text-danger"-->
                                <!--  (click)="abortSolution(demande.id)"-->
                                <!--  title="Avorter la demande {{demande.solutionName}}"-->
                                <!--  ><em class="material-icons btnDelete"> delete </em></a-->
                                <!-->-->
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'IaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'IaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                                <a
                                  class="btn text-primary"
                                  (click)="modifierDemande(demande.id,'IaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                                <!--<a-->
                                <!--  class="btn text-danger"-->
                                <!--  (click)="abortSolution(demande.id)"-->
                                <!--  title="Avorter la demande {{demande.solutionName}}"-->
                                <!--  ><em class="material-icons btnDelete"> delete </em></a-->
                                <!-->-->
                              </div>
                               <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'IaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'IaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                            </td>
                            <td><p id="iaasSta{{i}}" class="statusStyle">{{ demande.iaasStatus }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>

                      <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>

                      <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>

                      <div *ngIf="!demandesIaaSUser || demandesIaaSUser.length === 0" style="text-align:center">
                        <strong style="color:red" translate>No record</strong>
                      </div>

                    <br />
                    <hr>

                    <!-- Demandes qui ont été partagées au user connecté (et dont il n'est pas le solution owner) -->
                      <mat-card-title translate class="titleSection">Demandes dont je suis collaborateur</mat-card-title>
                      <table data-order='[[ 4, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="Shared IaaS requests" onclick="hideColumnsTab2()">
                        <thead>
                        <tr>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                          <th title="Global status of request"><!--statut global-->
                            {{ 'DLA recommendation' | translate
                            }}
                          </th>
                          <th title="Status of IaaS form">
                            {{ 'IaaS' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                          <!--Hidden par défaut et affichable au clic du bouton-->
                          <th title="Current solution owner of solution" style="display:none" class="moreTab2">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                            <th title="Type of solution" style="display:none" class="moreTab2">
                              {{ 'Type' | translate
                              }}
                            </th>
                            <th title="Business capability" style="display:none" class="moreTab2">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                          <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <!-- Recherche par nom de solution -->
                          <tr
                            *ngFor="
                              let demande of demandesIaaSUserPartagees;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <!-- formulaire-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection"
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>

                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoIaaSForm(demande.id)"
                                    >IaaS</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >IaaS</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                </div>
                                </div>
                              </mat-menu>
                              <!--Action buttons-->
                              <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="modale_elem"
                                  data-toggle="modal"
                                  data-target="#modalOverviewSolution"
                                  title="{{'recap demande/formulaire' | translate}}"
                                  (click)="openDialog(demande.id)"
                                  onclick="modalMovable()"
                                  >
                                  <em class="fa fa-info-circle" aria-hidden="true"></em>
                                </a>
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'IaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'IaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'IaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                                <!--Empty-->
                              </div>
                               <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'IaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'IaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalStaa{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                            </td>
                            <td><p id="iaasStaa{{i}}" class="statusStyle">{{ demande.iaasStatus }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab2">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab2">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab2">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>

                      <em onclick="displayColumnsTab2()" id="btnDisplayColumnsTab2" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>

                      <em onclick="hideColumnsTab2()" id="btnHideColumnsTab2" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>

                      <div *ngIf="!demandesIaaSUserPartagees || demandesIaaSUserPartagees.length === 0" style="text-align:center">
                            <strong style="color:red" translate>No record shared</strong>
                      </div>

                  </mat-tab>

                  <!--Demandes IaaS de la BU du user-->
                  <mat-tab label="{{'Requests (from my BU)' | translate }}" aria-label="UserBuIaaSReq">
                      <mat-card-title class="titleSection"
                        >{{ 'Requests (from my BU)' | translate }}
                      </mat-card-title>
                      <table data-order='[[ 4, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All IaaS requests from my BU" onclick="hideColumnsTab1()">
                        <thead>
                        <tr>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                          <th title="Global status of request"><!--statut global-->
                            {{ 'DLA recommendation' | translate
                            }}
                          </th>
                          <th title="Status of IaaS form">
                            {{ 'IaaS' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                          <!--Hidden par défaut et affichable au clic du bouton-->
                          <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                            <th title="Type of solution" style="display:none" class="moreTab1">
                              {{ 'Type' | translate
                              }}
                            </th>
                            <th title="Business capability" style="display:none" class="moreTab1">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                          <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let demande of demandesIaaSUserBu;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <!-- formulaire-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection"
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>

                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoIaaSForm(demande.id)"
                                    >IaaS</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >IaaS</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                  </div>
                                </div>
                              </mat-menu>
                              <!--Action buttons-->
                              <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="modale_elem"
                                  data-toggle="modal"
                                  data-target="#modalOverviewSolution"
                                  title="{{'recap demande/formulaire' | translate}}"
                                  (click)="openDialog(demande.id)"
                                  onclick="modalMovable()"
                                >
                                  <em class="fa fa-info-circle" aria-hidden="true"></em>
                                </a>
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'IaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'IaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'IaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                                <!--Empty-->
                              </div>
                               <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'IaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'IaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>

                            </td>
                            <td>
                              <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                            </td>
                            <td><p id="iaasSta{{i}}" class="statusStyle">{{ demande.iaasStatus }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>

                      <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>

                      <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>

                      <div *ngIf="!demandesIaaSUserBu || demandesIaaSUserBu.length === 0" style="text-align:center">
                            <strong style="color:red" translate>No record bu</strong>
                      </div>
                  </mat-tab>

                  <!--Demandes IaaS des autres BU-->
                  <mat-tab label="{{'Requests (from other BUs)' | translate }}" aria-label="UserOtherBuIaaSReq">
                      <mat-card-title class="titleSection"
                        >{{ 'Requests (from other BUs)' | translate }}
                      </mat-card-title>
                      <table data-order='[[ 4, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All IaaS requests from other BUs" onclick="hideColumnsTab1()">
                        <thead>
                        <tr>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                          <th title="Global status of request"><!--statut global-->
                            {{ 'DLA recommendation' | translate
                            }}
                          </th>
                          <th title="Status of IaaS form">
                            {{ 'IaaS' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                          <!--Hidden par défaut et affichable au clic du bouton-->
                          <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                            <th title="Type of solution" style="display:none" class="moreTab1">
                              {{ 'Type' | translate
                              }}
                            </th>
                            <th title="Business capability" style="display:none" class="moreTab1">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                          <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let demande of demandesIaaSUserOtherBu;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <!-- formulaire-->
                            <td>
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  {{ demande.solutionName }}
                                  <a
                                    class="modale_elem"
                                    data-toggle="modal"
                                    data-target="#modalOverviewSolution"
                                    title="{{'recap demande/formulaire' | translate}}"
                                    (click)="openDialog(demande.id)"
                                    onclick="modalMovable()"
                                  >
                                    <em class="fa fa-info-circle" aria-hidden="true"></em>
                                  </a>
                                </div>
                                <div *ngIf="demande.status === 'Pending'" style="display: contents">
                                  {{ demande.solutionName }}
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  {{ demande.solutionName }}
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                  </div>
                                </div>
                                <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'IaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                              </div>
                              <div *ngIf="demande.status === 'Pending'">
                                <!--Empty-->
                              </div>
                               <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'IaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                            </td>
                            <td><p id="iaasSta{{i}}" class="statusStyle">{{ demande.iaasStatus }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>

                      <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>

                      <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>

                      <div *ngIf="!demandesIaaSUserOtherBu || demandesIaaSUserOtherBu.length === 0" style="text-align:center">
                            <strong style="color:red" translate>No request all others bu</strong>
                      </div>
                  </mat-tab>

                </mat-tab-group>
                <!--END IaaS-->

              </mat-card-content>
            </mat-card>
          </mat-tab>
          <!--END DASHBOARDS IaaS-->

          <!--DASHBOARDS PaaS-->
          <!--<mat-tab label="PaaS" style="justify-content: center;" aria-label="PaaStab">-->
          <!--  <mat-card>-->
          <!--    <mat-card-content>-->

                <!--PaaS-->
          <!--      <mat-tab-group (selectedTabChange)="tabChanged($event)">-->

          <!--        <mat-tab label="{{'My requests PaaS' | translate}}" aria-label="UsersReqPaaS"></mat-tab>-->

          <!--      </mat-tab-group>-->
                <!--END PaaS-->

          <!--    </mat-card-content>-->
          <!--  </mat-card>-->
          <!--</mat-tab>-->
          <!--END DASHBOARDS PaaS-->

          <!--DASHBOARDS Apps-->
          <!--<mat-tab label="Apps" style="justify-content: center;" aria-label="Appstab">-->
          <!--  <mat-card>-->
          <!--    <mat-card-content>-->

                <!--Apps-->
          <!--      <mat-tab-group (selectedTabChange)="tabChanged($event)">-->

          <!--        <mat-tab label="{{'My requests Apps' | translate}}" aria-label="UsersReqApps"></mat-tab>-->

          <!--      </mat-tab-group>-->
                <!--END Apps-->

          <!--    </mat-card-content>-->
          <!--  </mat-card>-->
          <!--</mat-tab>-->
          <!--END DASHBOARDS Apps-->

        </mat-tab-group>
        <!--END ONGLETS DASHBOARDS-->
      </div>
      <!--END VEOLIA-->

      <!-- VENDOR -->
      <!-- ONLY SaaS -->
      <div *ngIf="auth.isVendor(user)">
        <hr>
        <mat-card-title class="titleSection" translate>Demandes me concernant</mat-card-title>
        <table data-order='[[ 7, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="User vendor SaaS requests" onclick="hideColumnsTab1()">
                <thead>
                  <tr>
                    <th title="RFP name">
                      {{ 'Nom RFP' | translate
                      }}
                    </th>
                    <th title="Solution name">
                      {{ 'Nom solution' | translate
                      }}
                    </th>
                    <th title="Global status of request"><!--statut global-->
                      {{ 'DLA recommendation' | translate
                      }}
                    </th>
                    <th title="Status of Design Form">
                      {{ 'Design' | translate
                      }}
                    </th>
                    <th title="Status of Legal Form">
                      {{ 'Legal' | translate
                      }}
                    </th>
                    <!--<th title="Status of Article 28 Form">-->
                    <!--  {{ 'Art 28' | translate-->
                    <!--  }}-->
                    <!--</th>-->
                    <th title="Status of Article 32 Form">
                      {{ 'Art 32' | translate
                      }}
                    </th>
                    <th title="Vendor brand name and SecurityScorecard">
                      {{ 'Nom vendor et SSC' | translate
                      }}
                    </th>
                    <th title="Request date of request">
                      {{ 'Date de demande' | translate
                      }}
                    </th>
                    <th title="Last update on forms">
                      {{ 'lastUpdate' | translate
                      }}
                    </th>
                    <!--Hidden par défaut et affichable au clic du bouton-->
                      <th title="Expected completion date of forms" style="display:none" class="moreTab1">
                        {{ 'Date attendue completion' | translate
                        }}
                      </th>
                      <th title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                        {{ 'Date attendue eval' | translate
                        }}
                      </th>
                        <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                      {{ 'Proriétaire actuel' | translate
                      }}
                    </th>
                      <th title="Type of solution" style="display:none" class="moreTab1">
                        {{ 'Type' | translate
                        }}
                      </th>
                      <th title="Business capability" style="display:none" class="moreTab1">
                      {{ 'Domain(s)' | translate
                      }}
                    </th>
                    <!--END Hidden par défaut et affichable au clic du bouton-->
                  </tr>
                </thead>
          <tbody>
            <tr
              *ngFor="
                let demande of demandesSaaSUserVendor;
                let i = index
              "
              style="cursor: default"
            >
              <td>
                {{ demande.rfpName }}
                <a
                  class="modale_elem"
                  data-toggle="modal"
                  data-target="#modalOverviewSolution"
                  title="{{'recap demande/formulaire' | translate}}"
                  (click)="openDialog(demande.id)"
                  onclick="modalMovable()"
                >
                  <em class="fa fa-info-circle" aria-hidden="true"></em>
                </a>
              </td>
                    <!-- formulaires-->
                    <td style="cursor:pointer">
                      <span class="btn text-primary nomSol"
                        mat-button
                        [matMenuTriggerFor]="formsSelection"
                        title="Select the form">
                        {{ demande.solutionName }}
                      </span>

                      <mat-menu #formsSelection="matMenu">
                      <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                          <a
                            class="btn text-primary"
                            (click)="gotoDesignForm(demande.id)"
                            >Design</a
                          >
                           <a
                            class="btn text-primary"
                            (click)="gotoLegalForm(demande.id)"
                            >Legal</a
                          >
                          <a
                            class="btn text-primary"
                            (click)="gotoArticle32Form(demande.id)"
                            >Article 32</a
                          >
                          <a
                            class="btn text-primary"
                            (click)="gotoArticle28Form(demande.id)"
                            >Annexes</a
                          >
                        </div>
                        <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                            <a
                            class="btn text-primary"
                            >Design</a
                          >
                           <a
                            class="btn text-primary"
                            >Legal</a
                          >
                          <a
                            class="btn text-primary"
                            >Article 32</a
                          >
                          <a
                            class="btn text-primary"
                            >Annexes</a
                          >
                          <div class="overlay" style="visibility:hidden">
                            <p style="color:red" translate>SolutionPending</p>
                          </div>
                        </div>
                        <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                            <a
                            class="btn text-primary"
                            >Design</a
                          >
                           <a
                            class="btn text-primary"
                            >Legal</a
                          >
                          <a
                            class="btn text-primary"
                            >Article 32</a
                          >
                          <a
                            class="btn text-primary"
                            >Annexes</a
                          >
                          <div class="overlay" style="visibility:hidden">
                            <p style="color:red" translate>SolutionCanceled</p>
                          </div>
                        </div>
                      </mat-menu>
                      <!--Actions buttons-->
                   <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                      <a
                          class="btn text-warning"
                          (click)="creationCompteVendor(demande.id,'SaaS')"
                          title="Access rights for the solution/request"
                          ><em class="material-icons btnShare"> share </em></a
                        >
                      <a
                        class="btn text-success"
                        (click)="tableauEvals(demande.id,'SaaS')"
                        title="Assessments status for request/solution forms"
                        ><em class="material-icons btnSeeEval"> event_available </em></a
                      >
                      <a
                        class="btn text-primary"
                        (click)="logs(demande.id,'SaaS')"
                        title="Logs of forms from the request/solution"
                        ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                      >
                      </div>
                      <div class="actionsButtons" *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                        <a
                          class="btn text-primary"
                        >Design</a
                        >
                                 <a
                                  class="btn text-primary"
                                  >Legal</a
                                >
                                <a
                                  class="btn text-primary"
                                  >Article 32</a
                                >
                                <a
                                  class="btn text-primary"
                                  >Annexes</a
                                >
                                <div class="overlay" style="visibility:hidden">
                                  <p style="color:red" translate>SolutionPending</p>
                                </div>
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                <!--Empty-->
                                <div class="overlay" style="visibility:hidden">
                                  <p style="color:red" translate>SolutionCanceled</p>
                                </div>
                              </div>
                    </td>
                    <td>
                      <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                      <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                      <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                      </td>
                    <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                    <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                    <!--<td><p id="article28Sta{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>-->
                    <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                    <td>{{ demande.editorBrand }}
                    <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                    <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                      <div *ngIf="demande.id === sscdata.id">
                        <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                          <mat-menu #sscSelection="matMenu">
                            <ul style="padding-right: 15px">
                              <li>Company name: {{sscdata.name}}</li>
                              <li>Industry style: {{sscdata.industry}}</li>
                              <li>Domain name: {{sscdata.domain}}</li>
                              <li>Score: {{sscdata.score}}</li>
                              <li>Grade: {{sscdata.grade}} </li>
                              <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                              <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                              <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                            </ul>
                            <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                          </mat-menu>
                      </div>
                    </div>
                    </td>
                    <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                    <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                      {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                    </td>
                    <!-- Il y a eu une modif des forms -->
                    <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                    <!--Hidden par défaut et affichable au clic du bouton-->
                    <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                    <!-- END Hidden par défaut et affichable au clic du bouton-->
            </tr>
          </tbody>
        </table>

        <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
          <span class="material-icons">zoom_in</span>
        </em>

        <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
          <span class="material-icons">zoom_out</span>
        </em>

        <div *ngIf="!demandesSaaSUserVendor || demandesSaaSUserVendor.length === 0" style="text-align:center">
              <strong style="color:red" translate>No request shared with you</strong>
        </div>

      </div>
      <!-- END VENDOR -->

  </div>

  </mat-card-content>
</mat-card>

