import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ValidateSolutionComponent } from './validate-solution.component';
import { ValidateSolutionRoutingModule } from './validate-solution-routing.module';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    ValidateSolutionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ValidateSolutionComponent,
  ],
  exports: [
    ValidateSolutionComponent,
  ],
  providers: []
})

export class ValidateSolutionModule {}
