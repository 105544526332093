import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment.prd';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})

//Formulaire de la page Home avec vérification des champs
export class AboutComponent implements OnInit {
  version: string = environment.version;
  
  constructor() {
    //empty
  }

  ngOnInit() {
    //empty
  }
}