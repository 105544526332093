<div class="container">
  <mat-card-title translate style="text-align: center">Review the request/solution for validation</mat-card-title>
  
  <mat-card>
    <mat-card-content>
    <!--SaaS-->
    <section *ngIf="this.type === 'SaaS'">
      <h4 style="color:#00aec7; text-align:center" translate>Solution/Request informations</h4>
      <strong translate class="title" style="color: #5bc0de;">Pimary informations</strong>
        <div>
          <p>
          {{ 'full Name initiator' | translate }}: <strong>{{ fullNameInitiatorVeolia }}</strong>
          </p>
          <p>
          {{ 'email initiator' | translate }}: <strong>{{ emailInitiatorVeolia }}</strong>
          </p>
          <p>
          {{ 'Solution owner' | translate }}: <strong>{{ solutionOwner }}</strong>
          </p>
          <p>
          {{ 'bu' | translate }}: <strong>{{ businessUnitOfSolution }}</strong>
          </p>
        </div>
        
        <strong translate class="title" style="color: #5bc0de;">Informations about the request</strong>
          <p>
            {{ 'Date de demande' | translate }}: <strong>{{ requestDate | date: 'medium' }}</strong>
          </p>
          <p>
            {{ 'Statut de la demande' | translate }}: <strong>{{ status }}</strong>
          </p>
        
          <strong translate class="title" style="color: #5bc0de;">Main details</strong>
            <div>
              <p>
                {{ 'Solution type' | translate }}: <strong>{{ type }}</strong>
              </p>
              <p>
                {{ 'Nom du RFP' | translate }}: <strong>{{ rfpName }}</strong>
              </p>
              <p>
              {{ 'Domain' | translate }}: <strong>{{ domainOfSolution }}</strong>
              </p>
              <p>
                {{ 'Pouvez-vous décrire le besoin ?' | translate }}: <strong>{{ description }}</strong>
              </p>
              <p>
                {{ 'Who will access the solution ?' | translate }}: <strong>{{ accessQuestion }}</strong>
              </p>
              <p>
                {{ 'Do you know if the solution will have to interconnect with 3rd party applications ?' | translate }}: <strong>{{ interconnectQuestion }}</strong>
              </p>
              <p>
                {{ 'Please give more details about interconnection with 3rd party applications' | translate }}: <strong>{{ interconnectQuestion2 }}</strong>
              </p>
              <p>
                {{ 'Communication with 3rd parties' | translate }}: <strong>{{ communicationQuestion }}</strong>
              </p>
              <p>
                {{ 'Date completion attendue' | translate }}: <strong>{{ expectedCompletionDate | date }}</strong>
              </p>
              <p>
                {{ 'Date eval attendue' | translate }}: <strong>{{ expectedEvaluationDate | date }}</strong>
              </p>
              <p>
                {{ 'expectedOnlineReleaseDate' | translate }}: <strong>{{ expectedOnlineReleaseDate | date }}</strong>
              </p>
              <p>
                {{ 'Nombre de solution à évaluer' | translate }}: <strong>{{ nbSolution }}</strong>
              </p>
            </div>
            
            <strong translate class="title" style="color: #5bc0de;">The solution</strong>
              <div>
                <p>
                {{ 'Nom de la solution à évaluer' | translate }}: <strong>{{ solutionName }}</strong>
                </p>
                <p>
                {{ 'Nom editeur' | translate }}: <strong>{{ editorBrand }}</strong>
                </p>
                <p>
                {{ 'Solution is already in use / DLA renewal ?' | translate }}: <strong>{{ inPlace }}</strong>
                </p>
                <p>
                {{ 'If the solution is already in use, please provide its APM number (if available)' | translate }}: <strong>{{ apmNumber }}</strong>
                </p>
                <p>
                {{ 'Lien app' | translate }}: <strong>{{ link }}</strong>
                </p>
              </div>
              
            <strong translate class="title" style="color: #5bc0de;">Main contact</strong>
              <div>
                <p>
                {{ 'Email editeur' | translate }}: <strong>{{ mainEmailVendor }}</strong>
                </p>
                <p>
                {{ 'nom' | translate }}: <strong>{{ mainLastnameVendor }}</strong>
                </p>
                <p>
                {{ 'prenom' | translate }}: <strong>{{ mainFirstnameVendor }}</strong>
                </p>
                <p>
                {{ 'Entreprise' | translate }}: <strong>{{ mainCompanyVendor }}</strong>
                </p>
                <p>
                {{ 'Lien app commercial' | translate }}: <strong>{{ linkCom }}</strong>
                </p>
              </div>
    </section>
    <!--END SaaS-->

    <!--IaaS-->
    <section *ngIf="this.type === 'IaaS'">
      <h4 style="color:#00aec7; text-align:center" translate>Solution/Request informations</h4>
      <strong translate class="title" style="color: #5bc0de;">Pimary informations</strong>
        <div>
          <p>
          {{ 'full Name initiator' | translate }}: <strong>{{ fullNameInitiatorVeolia }}</strong>
          </p>
          <p>
          {{ 'email initiator' | translate }}: <strong>{{ emailInitiatorVeolia }}</strong>
          </p>
          <p>
          {{ 'Solution owner' | translate }}: <strong>{{ solutionOwner }}</strong>
          </p>
          <p>
          {{ 'bu' | translate }}: <strong>{{ businessUnitOfSolution }}</strong>
          </p>
        </div>
        
        <strong translate class="title" style="color: #5bc0de;">Informations about the request</strong>
          <p>
            {{ 'Date de demande' | translate }}: <strong>{{ requestDate | date: 'medium' }}</strong>
          </p>
          <p>
            {{ 'Statut de la demande' | translate }}: <strong>{{ status }}</strong>
          </p>
        
          <strong translate class="title" style="color: #5bc0de;">Main details</strong>
            <div>
              <p>
                {{ 'Solution type' | translate }}: <strong>{{ type }}</strong>
              </p>
              <p>
                {{ 'Pouvez-vous décrire le besoin ?' | translate }}: <strong>{{ description }}</strong>
              </p>
              <p>
                {{ 'Domain' | translate }}: <strong>{{ domainOfSolution }}</strong>
              </p>
              <p>
                {{ 'q0a_iaas' | translate }}: <strong>{{ q0a_iaas }}</strong>
              </p>
              <p>
                {{ 'q0b_iaas' | translate }}: <strong>{{ q0b_iaas }}</strong>
              </p>
            </div>
            
            <strong translate class="title" style="color: #5bc0de;">The solution</strong>
              <div>
                <p>
                {{ 'Nom de la solution à évaluer' | translate }}: <strong>{{ solutionName }}</strong>
                </p>
                <p>
                {{ 'Nom editeur' | translate }}: <strong>{{ editorBrand }}</strong>
                </p>
                <p>
                {{ 'Solution is already in use / DLA renewal ?' | translate }}: <strong>{{ inPlace }}</strong>
                </p>
                <p>
                {{ 'If the solution is already in use, please provide its APM number (if available)' | translate }}: <strong>{{ apmNumber }}</strong>
                </p>
                <p>
                {{ 'Lien app' | translate }}: <strong>{{ link }}</strong>
                </p>
              </div>
              
    </section>
    <!--END IaaS-->
  </mat-card-content>
</mat-card>

    <br />
        
    <div class="example-button-row" style="text-align: center">
      <button mat-raised-button color="primary" (click)="validateSol()">{{ 'Valider la demande' | translate }}</button>
      <button mat-raised-button color="warn" (click)="cancelSol()">{{ 'Annuler la demande' | translate }}</button>
    </div>
    
</div>