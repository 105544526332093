<div class="my-overlay" *ngIf="showOverlay"><em class="fa fa-spinner fa-spin" aria-hidden="true"></em></div>

<mat-card>
  <mat-card-content>
    
      <div *ngIf="(auth.user | async) as user">
      
      <section>
        
        <button mat-raised-button
          class="submit-button legend"
          data-toggle="modal"
          data-target="#modalLegend"
          onclick="modalMovable()" >{{'Legend' | translate}}
        </button>
        
        <!-- Modal légende -->
        <div
            class="modal fade"
            id="modalLegend"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            style="margin-top:60px"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
                <div class="modal-header" >
                  {{ 'Legend and color code' | translate }}
                  <button class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" style="width:470px;">
                  <div class="container" style="display:inline; text-align:left">
                    <p translate>Chaque formulaire est identifié par un statut et une couleur:</p>
                    <ul>
                      <li><strong style="color: grey">Empty</strong>: {{'Empty status' | translate}}</li>
                      <li><strong style="color: grey">New</strong>: {{'New status' | translate}}</li>
                      <li><strong style="color: #54bbba">Vendor is responding</strong>: {{'Vendor is responding status' | translate}}</li>
                      <li><strong style="color: purple">Completed</strong>: {{'Completed status' | translate}}</li>
                      <li><strong style="color: blue">DLA is reviewing</strong>: {{'DLA is reviewing status' | translate}}</li>
                      <li><strong style="color: green">Recommended</strong>: {{'Recommended status' | translate}}</li>
                      <li><strong style="color: orange">Reservations</strong>: {{'Recommended with reservations status' | translate}}</li>
                      <li><strong style="color: red">Not recommended</strong>: {{'Not recommended status' | translate}}</li>
                      <li><strong style="color: black">Need help</strong>: {{'Need help status' | translate}}</li>
                      <li><strong style="color: red">Canceled</strong>: {{'Canceled status' | translate}}</li>
                      <li><strong style="color: red">Pending</strong>: {{'Pending status' | translate}}</li>
                    </ul>
                    
                    <hr>
                    <p><span class="material-icons">zoom_in</span>: {{'more icon' | translate}}</p>
                    <p><span class="material-icons">zoom_out</span>: {{'less icon' | translate}}</p>
                    <p>{{'Différentes actions sont possibles sous solutions' | translate}}:</p>
                    <p *ngIf="auth.isVeolia(user)"><em class="material-icons text-warning btnShare"> share </em>: {{'share button' | translate}}</p>
                    <!--<p *ngIf="auth.isVeolia(user)"><em class="material-icons text-danger btnDelete"> delete </em>: {{'delete button' | translate}}</p>-->
                    <p *ngIf="auth.isVeolia(user)"><em class="material-icons text-success btnSeeEval"> event_available </em>: {{'seeEval button' | translate}}</p>
                    <p *ngIf="auth.isVeolia(user)"><em class="material-icons text-primary btnLogs"> format_list_bulleted </em>: {{'logs button' | translate}}</p>
                  </div>
                </div>
                <!-- END Body -->
              </div>
            </div>
          </div>
        <!-- END MODALE -->
          
        <!--DOCS EVALUATOR-->
        <div *ngIf="auth.isEvaluator(user)" style="text-align: end;">
          <a target="_blank" rel="noopener" href="https://docs.google.com/presentation/d/1okm7sYmb9LPF6a203Z2s2X3nHFeubF5lZxyHkqaBS3g/edit#slide=id.gcb96afa5f7_0_0"
          mat-raised-button type="button" style="color: #00aec7;"
          title="{{'Evaluator documentation' | translate}}" 
          ><strong translate>Evaluator documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
          </a>
        </div>
        <!--END EVALUATOR DOCS-->
        
      </section>
      
      <!--VEOLIA-->
      <div *ngIf="auth.isVeolia(user)">
        <!--Onglet haut: SaaS -->
        <mat-tab-group (selectedTabChange)="tabChanged($event)">
          
          <!--DASHBOARDS SaaS-->
          <mat-tab label="SaaS" style="justify-content: center;" aria-label="SaaStab">
            <mat-card>
              <mat-card-content>
                
                <!--SaaS-->
                <mat-tab-group (selectedTabChange)="tabChanged($event)">
                  <mat-tab label="{{'Requests to evaluate' | translate}}" aria-label="EvaluatorReqSaaS">
         
                      <mat-card-title translate class="titleSection"
                        >Requests to evaluate</mat-card-title
                      >
                      <table data-order='[[ 7, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="Evaluator Bu SaaS requests" onclick="hideColumnsTab1()">
                        <thead>
                        <tr>
                          <th title="RFP name">
                            {{ 'Nom RFP' | translate
                            }}
                          </th>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                          <th title="Global status of request"><!--statut global-->
                            {{ 'DLA recommendation' | translate
                            }}
                          </th>
                          <th title="Status of Design Form">
                            {{ 'Design' | translate
                            }}
                          </th>
                          <th title="Status of Legal Form">
                            {{ 'Legal' | translate
                            }}
                          </th>
                          <!--<th title="Status of Article 28 Form">-->
                          <!--  {{ 'Art 28' | translate-->
                          <!--  }}-->
                          <!--</th>-->
                          <th title="Status of Article 32 Form">
                            {{ 'Art 32' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                          <!--Hidden par défaut et affichable au clic du bouton-->
                            <th title="Expected completion date of forms" style="display:none" class="moreTab1">
                              {{ 'Date attendue completion' | translate
                              }}
                            </th>
                            <th title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                              {{ 'Date attendue eval' | translate
                              }}
                            </th>
                            <th title="Type of solution" style="display:none" class="moreTab1">
                              {{ 'Type' | translate
                              }}
                            </th>
                            <th title="Business capability" style="display:none" class="moreTab1">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                          <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <!-- Recherche par nom de solution -->
                          <tr
                            *ngFor="
                              let demande of demandesSaaSEvaluator;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <td>{{ demande.rfpName }} 
                              <a
                                class="modale_elem"
                                data-toggle="modal"
                                data-target="#modalOverviewSolution"
                                title="{{'recap demande/formulaire' | translate}}"
                                (click)="openDialog(demande.id)"
                                onclick="modalMovable()"
                                ><em class="fa fa-info-circle" aria-hidden="true"></em>
                              </a>
                            </td>
                            <!-- formulaires-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection" 
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>
                              
                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoDesignForm(demande.id)"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    (click)="gotoLegalForm(demande.id)"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle32Form(demande.id)"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle28Form(demande.id)"
                                    >Annexes</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Annexes</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                  </div>
                                </div>
                              </mat-menu>
                              <!--Action buttons-->
                              <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'SaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                                <!--empty-->
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                            </td>
                            <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                            <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                            <!--<td><p id="article28Sta{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>-->
                            <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">    
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                            <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>
                      
                      <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>
                          
                      <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>
                      
                      <div *ngIf="!demandesSaaSEvaluator || demandesSaaSEvaluator.length === 0" style="text-align:center">
                        <strong style="color:red" translate>No request to evaluate</strong>
                      </div>
                      
                    <br />

                  </mat-tab>
                  
                  <mat-tab label="{{'Evaluated requests' | translate }}" aria-label="EvaluatorReqSaaSEvaluated">
                      <mat-card-title class="titleSection"
                        >{{ 'Evaluated requests' | translate }}
                      </mat-card-title>
                      <table data-order='[[ 7, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="Evaluator Bu evaluated SaaS requests" onclick="hideColumnsTab1()">
                        <thead>
                        <tr>
                          <th title="RFP name">
                            {{ 'Nom RFP' | translate
                            }}
                          </th>
                          <th title="Solution name">
                            {{ 'Nom solution' | translate
                            }}
                          </th>
                            <th title="Global status of request"><!--statut global-->
                              {{ 'DLA recommendation' | translate
                              }}
                            </th>
                          <th title="Status of Design Form">
                            {{ 'Design' | translate
                            }}
                          </th>
                          <th title="Status of Legal Form">
                            {{ 'Legal' | translate
                            }}
                          </th>
                          <!--<th title="Status of Article 28 Form">-->
                          <!--  {{ 'Art 28' | translate-->
                          <!--  }}-->
                          <!--</th>-->
                          <th title="Status of Article 32 Form">
                            {{ 'Art 32' | translate
                            }}
                          </th>
                          <th title="Vendor brand name and SecurityScorecard">
                            {{ 'Nom vendor et SSC' | translate
                            }}
                          </th>
                          <th title="Request date of request">
                            {{ 'Date de demande' | translate
                            }}
                          </th>
                          <th title="Last update on forms">
                            {{ 'lastUpdate' | translate
                            }}
                          </th>
                          <th title="Business unit">
                            {{ 'bu' | translate
                            }}
                          </th>
                          <!--Hidden par défaut et affichable au clic du bouton-->
                              <th title="Expected completion date of forms" style="display:none" class="moreTab1">
                                {{ 'Date attendue completion' | translate
                                }}
                              </th>
                              <th title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                                {{ 'Date attendue eval' | translate
                                }}
                              </th>
                              <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                              {{ 'Proriétaire actuel' | translate
                              }}
                            </th>
                              <th title="Type of solution" style="display:none" class="moreTab1">
                                {{ 'Type' | translate
                                }}
                              </th>
                              <th title="Business capability" style="display:none" class="moreTab1">
                              {{ 'Domain(s)' | translate
                              }}
                            </th>
                            <!--END Hidden par défaut et affichable au clic du bouton-->
                        </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let demande of demandesSaaSEvaluatorEvaluated;
                              let i = index
                            "
                            style="cursor: default"
                          >
                            <td>{{ demande.rfpName }}  
                              <a
                                class="modale_elem"
                                data-toggle="modal"
                                data-target="#modalOverviewSolution"
                                title="{{'recap demande/formulaire' | translate}}"
                                (click)="openDialog(demande.id)"
                                onclick="modalMovable()"
                                ><em class="fa fa-info-circle" aria-hidden="true"></em>
                              </a>
                            </td>
                            <!-- formulaires-->
                            <td style="cursor:pointer">
                              <span class="btn text-primary nomSol"
                                mat-button
                                [matMenuTriggerFor]="formsSelection" 
                                title="Select the form">
                                {{ demande.solutionName }}
                              </span>
                              
                              <mat-menu #formsSelection="matMenu">
                                <div *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'" style="display: contents">
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoDesignForm(demande.id)"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    (click)="gotoLegalForm(demande.id)"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle32Form(demande.id)"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    (click)="gotoArticle28Form(demande.id)"
                                    >Annexes</a
                                  >
                                </div>
                                <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                                    <a
                                    class="btn text-primary"
                                    >Design</a
                                  >
                                   <a
                                    class="btn text-primary"
                                    >Legal</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Article 32</a
                                  >
                                  <a
                                    class="btn text-primary"
                                    >Annexes</a
                                  >
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionPending</p>
                                  </div>
                                </div>
                                <div *ngIf="demande.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                                  <!--Empty-->
                                  <div class="overlay" style="visibility:hidden">
                                    <p style="color:red" translate>SolutionCanceled</p>
                                  </div>
                                </div>
                              </mat-menu>
                              <!--Actions buttons-->
                              <div class="actionsButtons" *ngIf="demande.status !== 'Pending' && demande.status !== 'Canceled'">
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(demande.id,'SaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Pending'">
                                <!--Empty-->
                              </div>
                              <div class="actionsButtons" *ngIf="demande.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(demande.id,'SaaS')"
                                  title="Assessments status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(demande.id,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </td>
                            <td>
                              <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                              <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                              <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                            </td>
                            <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                            <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                            <!--<td><p id="article28Sta{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>-->
                            <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                            <td>{{ demande.editorBrand }}
                            <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                            <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                              <div *ngIf="demande.id === sscdata.id">    
                                <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                                  <mat-menu #sscSelection="matMenu">
                                    <ul style="padding-right: 15px">
                                      <li>Company name: {{sscdata.name}}</li>
                                      <li>Industry style: {{sscdata.industry}}</li>
                                      <li>Domain name: {{sscdata.domain}}</li>
                                      <li>Score: {{sscdata.score}}</li>
                                      <li>Grade: {{sscdata.grade}} </li>
                                      <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                      <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                      <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                    </ul>
                                    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                                  </mat-menu>
                              </div>
                            </div>
                            </td>
                            <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                              {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                            </td>
                            <!-- Il y a eu une modif des forms -->
                            <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                            <td>{{ demande.businessUnitOfSolution }}</td>
                             <!--Hidden par défaut et affichable au clic du bouton-->
                            <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                            <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                            <!-- END Hidden par défaut et affichable au clic du bouton-->
                          </tr>
                        </tbody>
                      </table>
                      
                      <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                        <span class="material-icons">zoom_in</span>
                      </em>
                          
                      <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                        <span class="material-icons">zoom_out</span>
                      </em>
                      
                      <div *ngIf="!demandesSaaSEvaluatorEvaluated || demandesSaaSEvaluatorEvaluated.length === 0" style="text-align:center">
                            <strong style="color:red" translate>No request fully evaluated</strong>
                      </div>
                  </mat-tab>
                  
                </mat-tab-group>
                <!--END SaaS-->
                
              </mat-card-content>
            </mat-card>
          </mat-tab>
          <!--END DASHBOARDS SaaS-->
        
        </mat-tab-group>
        <!--END ONGLETS DASHBOARDS-->
      </div>
      <!--END VEOLIA-->
      
  </div>

  </mat-card-content>
</mat-card>

