import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap, map, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EvaluatorGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private toastr: ToastrService) {}
  
  //CHECK l'accès au lien /evaluator
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.user.pipe(
      take(1),
      map(user => (user && user.roles.evaluator ? true : false)),
      tap(isEvaluator => {
        if (!isEvaluator) {
          console.error('Access denied - Evaluators only');
          this.toastr.error('Access denied - Evaluators only !', 'Erreur', {
            timeOut: 5000,
            closeButton: true,
            progressBar: true,
            positionClass: 'toast-bottom-center'
          });
          this.router.navigate(['/home']);
        }
      })
    );
  }
}
