<!--SaaS-->
<section *ngIf="typeSol === 'SaaS'">
  <mat-card class="legend">
    <em class="legendTitle" translate>Legend for logs</em>
    <ul>
      <li><em class="material-icons">update</em>{{ 'Login on form' | translate }}</li>
      <li><em class="material-icons">save</em>{{ 'Save form' | translate }}</li>
      <li><em class="material-icons">send</em>{{ 'Save and send' | translate }}</li>
      <li><em class="material-icons">attach_file</em>{{ 'File uploaded/deleted' | translate }}</li>
      <li><em class="material-icons">comment</em>{{ 'Comment added/edited/deleted' | translate }}</li>
      <li><em class="material-icons">thumb_up</em>{{ 'Chapter/sub-chapter completed' | translate }}</li>
      <li><em class="material-icons">help</em>{{ 'Help requested' | translate }}</li>
      <li><em class="material-icons">check_box</em>{{ 'Save sub-chapter/chapter evaluation' | translate }}</li>
      <li><em class="material-icons">playlist_add_check</em>{{ 'Save final evaluation' | translate }}</li>
      <li><em class="material-icons">move_to_inbox</em>{{ 'Save acunetix' | translate }}</li>
    </ul>
  </mat-card>
</section>

<!--IaaS PaaS Apps-->
<section *ngIf="typeSol !== 'SaaS'">
  <mat-card class="legend">
    <em class="legendTitle" translate>Legend for logs</em>
      <ul>
      <li><em class="material-icons">update</em>{{ 'Login on form' | translate }}</li>
      <li><em class="material-icons">save</em>{{ 'Save form' | translate }}</li>
      <li><em class="material-icons">send</em>{{ 'Save and send' | translate }}</li>
      <li><em class="material-icons">attach_file</em>{{ 'File uploaded/deleted' | translate }}</li>
      <li><em class="material-icons">comment</em>{{ 'Comment added/edited/deleted' | translate }}</li>
      <li><em class="material-icons">thumb_up</em>{{ 'Chapter/sub-chapter completed' | translate }}</li>
      <li><em class="material-icons">check_box</em>{{ 'Save sub-chapter/chapter evaluation' | translate }}</li>
      <li><em class="material-icons">playlist_add_check</em>{{ 'Save final evaluation' | translate }}</li>
    </ul>
  </mat-card>
</section>

<div *ngIf="(authService.user | async) as user">
  
<mat-card style="display:grid">
  <br>
  
  <mat-card-title *ngIf="typeSol === 'SaaS'" style="color:#00aec7; text-align:center">{{ 'Logs for' | translate }} {{typeSol}}
            
      <span style="cursor: pointer; text-decoration: underline" [matMenuTriggerFor]="formsSelection" title="Select the form">
        {{ nameSol }}
      </span>
      <mat-menu #formsSelection="matMenu">
              <div *ngIf="(userIsInEmailEvaluatorAuthorized || userIsInEmailVeoliaAuthorized || userIsInEmailVendorAuthorized || userIsInEvaluatorsBu || authService.isAdmin(user) || user?.businessUnit === businessUnitOfSolution) 
                && (val.status !== 'Pending' && val.status !== 'Canceled')" style="display: contents">
                <a
                  class="btn text-primary"
                  (click)="gotoDesignForm(val.id)"
                  >Design</a
                >
                <a
                  class="btn text-primary"
                  (click)="gotoLegalForm(val.id)"
                  >Legal</a
                >
                <a
                  class="btn text-primary"
                  (click)="gotoArticle32Form(val.id)"
                  >Article 32</a
                >
                <a
                  class="btn text-primary"
                  (click)="gotoArticle28Form(val.id)"
                  >Annexes</a
                >
                </div>
              <div *ngIf="val.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                  <a
                    class="btn text-primary"
                    >Design</a
                  >
                  <a
                    class="btn text-primary"
                    >Legal</a
                  >
                 <a
                  class="btn text-primary"
                  >Article 32</a
                 >
                <a
                  class="btn text-primary"
                  >Annexes</a
                >
                <div class="overlay" style="visibility:hidden">
                  <p style="color:red" translate>SolutionPending</p>
                </div>
                </div>
              <div *ngIf="authService.isAdmin(user) && val.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                  <a
                    class="btn text-primary"
                    (click)="gotoDesignForm(val.id)"
                    >Design</a
                  >
                  <a
                    class="btn text-primary"
                    (click)="gotoLegalForm(val.id)"
                    >Legal</a
                  >
                  <a
                    class="btn text-primary"
                    (click)="gotoArticle32Form(val.id)"
                    >Article 32</a
                  >
                  <a
                   class="btn text-primary"
                    (click)="gotoArticle28Form(val.id)"
                    >Annexes</a
                  >
                  </div>
            </mat-menu>
            
      <span class="material-icons" style="cursor: pointer; margin-left: 5px;" [matMenuTriggerFor]="options" title="Actions...">
        more_horiz
      </span>
      <mat-menu #options="matMenu">
                              <div *ngIf="(userIsInEmailEvaluatorAuthorized || userIsInEmailVeoliaAuthorized || userIsInEmailVendorAuthorized || userIsInEvaluatorsBu || authService.isAdmin(user) || user?.businessUnit === businessUnitOfSolution) && (val.status !== 'Pending' && val.status !== 'Canceled')">
                                <a *ngIf="(authService.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu)"
                                  class="btn text-primary"
                                  (click)="modifierDemande(val.id,'SaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(val.id,'SaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(val.id,'SaaS')"
                                  title="Assessments val.status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                              </div>
                              <div *ngIf="(authService.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu) && val.status === 'Pending'">
                                <a
                                  class="btn text-primary"
                                  (click)="modifierDemande(val.id,'SaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                              </div>
                              <div *ngIf="(authService.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu) && val.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(val.id,'SaaS')"
                                  title="Assessments val.status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                              </div>
                            </mat-menu>
                          <hr>  
    </mat-card-title>
    
  <mat-card-title *ngIf="typeSol !== 'SaaS'" style="color:#00aec7; text-align:center">{{ 'Logs for' | translate }} {{typeSol}}
            
      <span style="cursor: pointer; text-decoration: underline" [matMenuTriggerFor]="formsSelection" title="Select the form">
        {{ nameSol }}
      </span>
      <mat-menu #formsSelection="matMenu">
              <div *ngIf="(userIsInEmailEvaluatorAuthorized || userIsInEmailVeoliaAuthorized || userIsInEvaluatorsBu || authService.isAdmin(user) || user?.businessUnit === businessUnitOfSolution) 
                && (val.status !== 'Pending' && val.status !== 'Canceled')" style="display: contents">
                <a
                  class="btn text-primary"
                  (click)="gotoIaaSForm(val.id)"
                  >IaaS</a
                >
                </div>
              <div *ngIf="val.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                  <a
                    class="btn text-primary"
                    >IaaS</a
                  >
                <div class="overlay" style="visibility:hidden">
                  <p style="color:red" translate>SolutionPending</p>
                </div>
                </div>
              <div *ngIf="authService.isAdmin(user) && val.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                  <a
                    class="btn text-primary"
                    (click)="gotoIaaSForm(val.id)"
                    >IaaS</a
                  >
                  </div>
            </mat-menu>
            
      <span class="material-icons" style="cursor: pointer; margin-left: 5px;" [matMenuTriggerFor]="options" title="Actions...">
        more_horiz
      </span>
      <mat-menu #options="matMenu">
                              <div *ngIf="(userIsInEmailEvaluatorAuthorized || userIsInEmailVeoliaAuthorized || userIsInEvaluatorsBu || authService.isAdmin(user) || user?.businessUnit === businessUnitOfSolution) && (val.status !== 'Pending' && val.status !== 'Canceled')">
                                <a *ngIf="(authService.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu)"
                                  class="btn text-primary"
                                  (click)="modifierDemande(val.id,'IaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(val.id,'IaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(val.id,'IaaS')"
                                  title="Assessments val.status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                              </div>
                              <div *ngIf="(authService.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu) && val.status === 'Pending'">
                                <a
                                  class="btn text-primary"
                                  (click)="modifierDemande(val.id,'IaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                              </div>
                              <div *ngIf="(authService.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu) && val.status === 'Canceled'">
                                <a
                                  class="btn text-success"
                                  (click)="tableauEvals(val.id,'IaaS')"
                                  title="Assessments val.status for request/solution forms"
                                  ><em class="material-icons btnSeeEval"> event_available </em></a
                                >
                              </div>
                            </mat-menu>
                          <hr>  
    </mat-card-title>
  
  <mat-tab-group mat-align-tabs="center" (selectedTabChange)="tabChanged($event)"
    ><!-- MAT GROUP PARENT ONGLETS -->
  
    <!--SaaS-->
    <section *ngIf="typeSol === 'SaaS'">
      
      <mat-tab label="{{'formDA' | translate}}"
        ><ng-template mat-tab-label>{{ 'formDA' | translate }}</ng-template
        ><!-- DESIGN -->
        
        
        <mat-tab-group mat-align-tabs="center">
          <!-- Login -->
          <mat-tab>
            <ng-template mat-tab-label><em (click)="getLogsAction('Connection')" class="material-icons xlarge">update</em></ng-template>

            <div class="framed" >
              
              <div *ngIf="selected === false" translate translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs1; else: okLogs1"
              ></div>
              
              <ng-template #noLogs1> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs1>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Connection')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Connection', date)" onclick="closeDetailsLogs()">
                <!--<summary>{{ date | date: 'yyyy/MM/dd' }}</summary>-->
                <summary>{{ date }}</summary>
                
                <br />
            
               <div id="ConnectionDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Connection action</strong>
                  <br *ngIf="download === true"/>
                  
                 <div *ngFor="let object of logsInfos">
                        <p>
                          <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                          >: {{ object.userEmail }} ({{ object.roleUser }}) logged in
                        </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Login -->

          <!-- Save -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveForm')" class="material-icons xlarge">save</em></ng-template
            >

            <div class="framed">
                
              <div *ngIf="selected === false" translate translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs2; else: okLogs2"
              ></div>
              
              <ng-template #noLogs2> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs2>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveFormDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong>: 
                      {{ object.userEmail }} saved the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save -->

          <!-- Save complete form and send -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveCompleteForm')" class="material-icons xlarge">send</em></ng-template
            >

           <div class="framed">
             
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs3; else: okLogs3"
              ></div>
              
              <ng-template #noLogs3> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs3>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveCompleteForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveCompleteForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveCompleteFormDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveCompleteForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} completed the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save complete form and send -->

          <!-- File uploaded/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('File')" class="material-icons xlarge">attach_file</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs4; else: okLogs4"
              ></div>
              
              <ng-template #noLogs4> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs4>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('File')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('File', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FileDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>File action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (<em>{{ object.fileName }} on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END File uploaded/deleted -->

          <!-- Comment added/edited/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Comment')" class="material-icons xlarge">comment</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs5; else: okLogs5"
              ></div>
              
              <ng-template #noLogs5> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs5>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Comment')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Comment', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CommentDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Comment action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                    <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (with content: {{ object.content }} <em>on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                       <em *ngIf="object.taggedUser"> => with tagged user {{ object.taggedUser }}</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Comment added/edited/deleted -->
          
          <!--Chapter/sub-chapter completed -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Complete')" class="material-icons xlarge">thumb_up</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs6; else: okLogs6"
              ></div>
              
              <ng-template #noLogs6> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs6>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Complete')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Complete', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CompleteDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Complete chapter/sub-chapter action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                    <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) added status "{{ object.status }}" (<em>on the chapter/sub-chapter {{ object.chapter }}) with addtional comment ("{{ object.comment }}")</em>
                      
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END chapter/sub-chapter completed -->

          <!-- Help requested -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Help')" class="material-icons xlarge">help</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs7; else: okLogs7"
              ></div>
              
              <ng-template #noLogs7> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs7>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Help')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Help', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="HelpDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Help action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                       <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} requested help on the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Help requested -->

          <!-- Save sub chapter/chapter evaluation -->
          <
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Evaluation')" class="material-icons xlarge">check_box</em></ng-template
            >

           <div class="framed">
             
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs8; else: okLogs8"
              ></div>
              
              <ng-template #noLogs8> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs8>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Evaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Evaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="EvaluationDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Evaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) saved the evaluation for reference
                      {{ object.chapter }}
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save sub chapter/chapter evaluation -->

          <!-- Save final evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('FinalEvaluation')" class="material-icons xlarge"
                >playlist_add_check</em
              ></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs9; else: okLogs9"
              ></div>
              
              <ng-template #noLogs9> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs9>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('FinalEvaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('FinalEvaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
                
                <div id="FinalEvaluationDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>FinalEvaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) submited the final evaluation with
                      status <strong>{{ object.status }} ({{ object.comment }})</strong>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save final evaluation -->

          <!-- Save acunetix -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Acunetix')" class="material-icons xlarge">move_to_inbox</em></ng-template
            >

            <div class="framed">
                
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs10; else: okLogs10"
              ></div>
              
              <ng-template #noLogs10> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs10>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Acunetix')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Acunetix', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="AcunetixDesignPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Acunetix action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) submited informations about acunetix
                      scan
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END acunetix -->
        </mat-tab-group> </mat-tab
      ><!-- END DESIGN -->

      <mat-tab label="{{'formLA' | translate}}"
        ><ng-template mat-tab-label>{{ 'formLA' | translate }}</ng-template
        ><!-- LEGAL -->

        <mat-tab-group mat-align-tabs="center">
          <!-- Login -->
          <mat-tab>
            <ng-template mat-tab-label><em (click)="getLogsAction('Connection')" class="material-icons xlarge">update</em></ng-template>

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs11; else: okLogs11"
              ></div>
              
              <ng-template #noLogs11> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs11>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Connection')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Connection', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="ConnectionLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Connection action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                          <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                          >: {{ object.userEmail }} ({{ object.roleUser }}) logged in
                        </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Login -->

          <!-- Save -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveForm')" class="material-icons xlarge">save</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs12; else: okLogs12"
              ></div>
              
              <ng-template #noLogs12> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs12>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveFormLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong>: 
                      {{ object.userEmail }} saved the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save -->

          <!-- Save complete form and send -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveCompleteForm')" class="material-icons xlarge">send</em></ng-template
            >

           <div class="framed">
             
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs13; else: okLogs13"
              ></div>
              
              <ng-template #noLogs13> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs13>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveCompleteForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveCompleteForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveCompleteFormLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveCompleteForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} completed the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save complete form and send -->

          <!-- File uploaded/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('File')" class="material-icons xlarge">attach_file</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs14; else: okLogs14"
              ></div>
              
              <ng-template #noLogs14> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs14>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('File')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('File', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FileLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>File action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (<em>{{ object.fileName }} on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END File uploaded/deleted -->

          <!-- Comment added/edited/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Comment')" class="material-icons xlarge">comment</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs15; else: okLogs15"
              ></div>
              
              <ng-template #noLogs15> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs15>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Comment')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Comment', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CommentLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Comment action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (with content: {{ object.content }} <em>on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                       <em *ngIf="object.taggedUser"> => with tagged user {{ object.taggedUser }}</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Comment added/edited/deleted -->
          
          <!--Chapter/sub-chapter completed -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Complete')" class="material-icons xlarge">thumb_up</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs16; else: okLogs16"
              ></div>
              
              <ng-template #noLogs16> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs16>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Complete')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Complete', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CompleteLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Complete chapter/sub-chapter action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                    <p>
                     <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) added status "{{ object.status }}" (<em>on the chapter/sub-chapter {{ object.chapter }}) with addtional comment ("{{ object.comment }}")</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END chapter/sub-chapter completed -->

          <!-- Help requested -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Help')" class="material-icons xlarge">help</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs17; else: okLogs17"
              ></div>
              
              <ng-template #noLogs17> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs17>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Help')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Help', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="HelpLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Help action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                       <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} requested help on the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Help requested -->

          <!-- Save sub chapter/chapter evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Evaluation')" class="material-icons xlarge">check_box</em></ng-template
            >

           <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs18; else: okLogs18"
              ></div>
              
              <ng-template #noLogs18> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs18>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Evaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Evaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="EvaluationLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Evaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) saved the evaluation for reference
                      {{ object.chapter }}
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save sub chapter/chapter evaluation -->

          <!-- Save final evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('FinalEvaluation')" class="material-icons xlarge"
                >playlist_add_check</em
              ></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs19; else: okLogs19"
              ></div>
              
              <ng-template #noLogs19> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs19>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('FinalEvaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('FinalEvaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FinalEvaluationLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>FinalEvaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) submited the final evaluation with
                      status <strong>{{ object.status }} ({{ object.comment }})</strong>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save final evaluation -->

        </mat-tab-group> </mat-tab
      ><!-- END LEGAL -->
      
      <mat-tab label="{{'Annexes' | translate}}"
        ><ng-template mat-tab-label>{{ 'Annexes' | translate }}</ng-template
        ><!-- ARTICLE 28 -->

        <mat-tab-group mat-align-tabs="center">
          <!-- Login -->
          <mat-tab>
            <ng-template mat-tab-label><em (click)="getLogsAction('Connection')" class="material-icons xlarge">update</em></ng-template>

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs20; else: okLogs20"
              ></div>
              
              <ng-template #noLogs20> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs20>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Connection')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Connection', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="ConnectionArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Connection action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                          <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                          >: {{ object.userEmail }} ({{ object.roleUser }}) logged in
                        </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Login -->

          <!-- Save -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveForm')" class="material-icons xlarge">save</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs21; else: okLogs21"
              ></div>
              
              <ng-template #noLogs21> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs21>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveFormArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong>: 
                      {{ object.userEmail }} saved the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save -->

          <!-- Save complete form and send -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveCompleteForm')" class="material-icons xlarge">send</em></ng-template
            >

           <div class="framed">
            
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs20; else: okLogs20"
              ></div>
              
              <ng-template #noLogs20> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs20>
                 
             <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveCompleteForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveCompleteForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveCompleteFormArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveCompleteForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} completed the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save complete form and send -->

          <!-- File uploaded/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('File')" class="material-icons xlarge">attach_file</em></ng-template
            >

            <div class="framed">
            
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs32; else: okLogs32"
              ></div>
              
              <ng-template #noLogs32> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs32>
                  
              <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('File')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('File', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FileArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>File action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (<em>{{ object.fileName }} on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END File uploaded/deleted -->

          <!-- Comment added/edited/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Comment')" class="material-icons xlarge">comment</em></ng-template
            >

            <div class="framed">
             
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs22; else: okLogs22"
              ></div>
              
              <ng-template #noLogs22> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs22>
                 
              <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Comment')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Comment', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CommentArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Comment action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (with content: {{ object.content }} <em>on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                       <em *ngIf="object.taggedUser"> => with tagged user {{ object.taggedUser }}</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Comment added/edited/deleted -->
          
          <!--Chapter/sub-chapter completed -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Complete')" class="material-icons xlarge">thumb_up</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs30; else: okLogs30"
              ></div>
              
              <ng-template #noLogs30> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs30>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Complete')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Complete', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CompleteArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Complete chapter/sub-chapter action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                    <p>
                     <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) added status "{{ object.status }}" (<em>on the chapter/sub-chapter {{ object.chapter }}) with addtional comment ("{{ object.comment }}")</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END chapter/sub-chapter completed -->

          <!-- Help requested -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Help')" class="material-icons xlarge">help</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs23; else: okLogs23"
              ></div>
              
              <ng-template #noLogs23> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs23>
                
              <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Help')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Help', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="HelpArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Help action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                       <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} requested help on the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Help requested -->

          <!-- Save sub chapter/chapter evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Evaluation')" class="material-icons xlarge">check_box</em></ng-template
            >

           <div class="framed">
             
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs24; else: okLogs24"
              ></div>
              
              <ng-template #noLogs24> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs24>
                
             <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Evaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Evaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="EvaluationArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Evaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) saved the evaluation for reference
                      {{ object.chapter }}
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save sub chapter/chapter evaluation -->

          <!-- Save final evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('FinalEvaluation')" class="material-icons xlarge"
                >playlist_add_check</em
              ></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs25; else: okLogs25"
              ></div>
              
              <ng-template #noLogs25> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs25>
                
              <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('FinalEvaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('FinalEvaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FinalEvaluationArticle28Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>FinalEvaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) submited the final evaluation with
                      status <strong>{{ object.status }} ({{ object.comment }})</strong>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save final evaluation -->

        </mat-tab-group> </mat-tab
      ><!-- END ARTICLE 28 -->
      
      <mat-tab label="{{'form32' | translate}}"
        ><ng-template mat-tab-label>{{ 'form32' | translate }}</ng-template
        ><!-- ARTICLE 32 -->

        <mat-tab-group mat-align-tabs="center">
          <!-- Login -->
          <mat-tab>
            <ng-template mat-tab-label><em (click)="getLogsAction('Connection')" class="material-icons xlarge">update</em></ng-template>

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs26; else: okLogs26"
              ></div>
              
              <ng-template #noLogs26> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs26>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Connection')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Connection', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="ConnectionArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Connection action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                          <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                          >: {{ object.userEmail }} ({{ object.roleUser }}) logged in
                        </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Login -->

          <!-- Save -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveForm')" class="material-icons xlarge">save</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs27; else: okLogs27"
              ></div>
              
              <ng-template #noLogs27> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs27>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveFormArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong>: 
                      {{ object.userEmail }} saved the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save -->

          <!-- Save complete form and send -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveCompleteForm')" class="material-icons xlarge">send</em></ng-template
            >

           <div class="framed">
            
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs28; else: okLogs28"
              ></div>
              
              <ng-template #noLogs28> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs28>
                 
             <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveCompleteForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveCompleteForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveCompleteFormArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveCompleteForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} completed the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save complete form and send -->

          <!-- File uploaded/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('File')" class="material-icons xlarge">attach_file</em></ng-template
            >

            <div class="framed">
            
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs29; else: okLogs29"
              ></div>
              
              <ng-template #noLogs29> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs29>
                  
              <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('File')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('File', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FileArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>File action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (<em>{{ object.fileName }} on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END File uploaded/deleted -->

          <!-- Comment added/edited/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Comment')" class="material-icons xlarge">comment</em></ng-template
            >

            <div class="framed">
             
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs30; else: okLogs30"
              ></div>
              
              <ng-template #noLogs30> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs30>
                 
              <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Comment')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Comment', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CommentArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Comment action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (with content: {{ object.content }} <em>on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                       <em *ngIf="object.taggedUser"> => with tagged user {{ object.taggedUser }}</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Comment added/edited/deleted -->
          
          <!--Chapter/sub-chapter completed -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Complete')" class="material-icons xlarge">thumb_up</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs31; else: okLogs31"
              ></div>
              
              <ng-template #noLogs31> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs31>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Complete')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Complete', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CompleteArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Complete chapter/sub-chapter action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                    <p>
                     <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) added status "{{ object.status }}" (<em>on the chapter/sub-chapter {{ object.chapter }}) with addtional comment ("{{ object.comment }}")</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END chapter/sub-chapter completed -->

          <!-- Help requested -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Help')" class="material-icons xlarge">help</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs31; else: okLogs31"
              ></div>
              
              <ng-template #noLogs31> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs31>
                
              <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Help')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Help', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="HelpArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Help action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                       <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} requested help on the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Help requested -->

          <!-- Save sub chapter/chapter evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Evaluation')" class="material-icons xlarge">check_box</em></ng-template
            >

           <div class="framed">
             
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs32; else: okLogs32"
              ></div>
              
              <ng-template #noLogs32> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs32>
                
             <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Evaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Evaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="EvaluationArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Evaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) saved the evaluation for reference
                      {{ object.chapter }}
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save sub chapter/chapter evaluation -->

          <!-- Save final evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('FinalEvaluation')" class="material-icons xlarge"
                >playlist_add_check</em
              ></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs33; else: okLogs33"
              ></div>
              
              <ng-template #noLogs33> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs33>
                
              <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('FinalEvaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('FinalEvaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FinalEvaluationArticle32Pdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>FinalEvaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) submited the final evaluation with
                      status <strong>{{ object.status }} ({{ object.comment }})</strong>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save final evaluation -->

        </mat-tab-group> </mat-tab
      ><!-- END ARTICLE 32 -->
      
    </section>
    <!-- END SaaS-->
    
    <!--IaaS-->
    <section *ngIf="typeSol === 'IaaS'">
    
    <mat-tab label="{{'IaaS Form' | translate}}"
        ><ng-template mat-tab-label>{{ 'IaaS Form' | translate }}</ng-template
        >
        <!-- IaaS -->

        <mat-tab-group mat-align-tabs="center">
          <!-- Login -->
          <mat-tab>
            <ng-template mat-tab-label><em (click)="getLogsAction('Connection')" class="material-icons xlarge">update</em></ng-template>

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs11; else: okLogs11"
              ></div>
              
              <ng-template #noLogs11> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs11>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Connection')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Connection', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="ConnectionLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Connection action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                          <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                          >: {{ object.userEmail }} ({{ object.roleUser }}) logged in
                        </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Login -->

          <!-- Save -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveForm')" class="material-icons xlarge">save</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs12; else: okLogs12"
              ></div>
              
              <ng-template #noLogs12> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs12>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveFormLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong>: 
                      {{ object.userEmail }} saved the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save -->

          <!-- Save complete form and send -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('SaveCompleteForm')" class="material-icons xlarge">send</em></ng-template
            >

           <div class="framed">
             
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs13; else: okLogs13"
              ></div>
              
              <ng-template #noLogs13> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs13>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('SaveCompleteForm')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('SaveCompleteForm', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="SaveCompleteFormLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>SaveCompleteForm action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} completed the form
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save complete form and send -->

          <!-- File uploaded/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('File')" class="material-icons xlarge">attach_file</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs14; else: okLogs14"
              ></div>
              
              <ng-template #noLogs14> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs14>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('File')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('File', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FileLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>File action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                        <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (<em>{{ object.fileName }} on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END File uploaded/deleted -->

          <!-- Comment added/edited/deleted -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Comment')" class="material-icons xlarge">comment</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs15; else: okLogs15"
              ></div>
              
              <ng-template #noLogs15> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs15>
                
                <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Comment')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Comment', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CommentLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Comment action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.action }} (with content: {{ object.content }} <em>on the chapter/sub-chapter {{ object.chapter }})</em> by
                      {{ object.userEmail }} ({{ object.roleUser }})
                       <em *ngIf="object.taggedUser"> => with tagged user {{ object.taggedUser }}</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Comment added/edited/deleted -->
          
          <!--Chapter/sub-chapter completed -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Complete')" class="material-icons xlarge">thumb_up</em></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs16; else: okLogs16"
              ></div>
              
              <ng-template #noLogs16> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs16>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Complete')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Complete', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="CompleteLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Complete chapter/sub-chapter action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                    <p>
                     <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) added status "{{ object.status }}" (<em>on the chapter/sub-chapter {{ object.chapter }}) with addtional comment ("{{ object.comment }}")</em>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END chapter/sub-chapter completed -->

          <!-- Save sub chapter/chapter evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('Evaluation')" class="material-icons xlarge">check_box</em></ng-template
            >

           <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs18; else: okLogs18"
              ></div>
              
              <ng-template #noLogs18> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs18>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('Evaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
                
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('Evaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="EvaluationLegalPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>Evaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) saved the evaluation for reference
                      {{ object.chapter }}
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save sub chapter/chapter evaluation -->

          <!-- Save final evaluation -->
          <mat-tab
            ><ng-template mat-tab-label
              ><em (click)="getLogsAction('FinalEvaluation')" class="material-icons xlarge"
                >playlist_add_check</em
              ></ng-template
            >

            <div class="framed">
              
              <div *ngIf="selected === false" translate>Please click on an icon to check out log</div>
              
              <div
                *ngIf="(!listeDate || listeDate.length === 0) && selected === true; then: noLogs19; else: okLogs19"
              ></div>
              
              <ng-template #noLogs19> <strong style="color:red" translate>No logs available for this action</strong> </ng-template>

              <ng-template #okLogs19>
                
                 <div class="center">
              <button mat-raised-button *ngIf="selected === true" title="Click to download all logs for this action"  (click)="generatePdf('FinalEvaluation')" onclick="closeAllDetailsLogs()"
                >{{'Download PDF of logs for this action' | translate}}<em class="material-icons" style="color: white; margin-right: 5px"> cloud_download </em></button>
              </div>
                <br />
              
                <details class="detailsLogs" *ngFor="let date of listeDate; index as i;" (click)="getLogsInfosDate('FinalEvaluation', date)" onclick="closeDetailsLogs()">
                <summary>{{ date }}</summary>
                
                <br />
            
                <div id="FinalEvaluationIaaSPdf">
                  <p *ngIf="download === true" style="text-align:center; font-size: 20px;">Logs list for {{this.nameSol}} : {{this.tabName}}</p>
                  <strong *ngIf="download === true" style="text-align:center; font-size: 20px; color: #5bc0de;" translate>FinalEvaluation action</strong>
                  <br *ngIf="download === true"/>
               
                 <div *ngFor="let object of logsInfos">
                      <p>
                      <strong>{{ object.dateTime | date: 'MMMM d, y, h:mm a' }}</strong
                      >: {{ object.userEmail }} ({{ object.roleUser }}) submited the final evaluation with
                      status <strong>{{ object.status }} ({{ object.comment }})</strong>
                    </p>
                 </div>
             
                 </div>
            
                </details>
              </ng-template>

            </div>
          </mat-tab>
          <!-- END Save final evaluation -->

        </mat-tab-group> 
        </mat-tab
      ><!-- END IaaS -->
    
    </section>
    <!--END IaaS PaaS Apps-->
    
    </mat-tab-group
  ><!-- END MAT GROUP PARENT ONGLETS -->
  
</mat-card>

</div> 
