import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../../material.module';
import { DemandeDlaRoutingModule } from './demandeDla-routing.module';
import { DemandeDlaComponent } from './demandeDla.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LowercaseDirective } from './lowercase.directive';
import { UrlCleanerDirective } from './url-cleaner.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    DemandeDlaRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [DemandeDlaComponent, LowercaseDirective, UrlCleanerDirective],
  providers: []
})
export class DemandeDlaModule {}
