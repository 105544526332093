import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { environment } from '../environments/environment';
import { Logger, I18nService } from './core';
import { PresenceService } from './services/presence.service';
import { AuthService } from './core/auth.service';
import firebase from 'firebase/compat/app';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { IsFiveMinutesAgo } from './core/helpers';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  user: any = {};
  presence$;
  isOpenPresenceConfirm: boolean = false;
  isConfirmed: boolean = false;
  intervalId!: ReturnType<typeof setInterval>;



  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private domSanitizer: DomSanitizer,
    private i18nService: I18nService,
    private matIconRegistry: MatIconRegistry,
    private presenceService: PresenceService,
    public auth: AuthService,
    private afAuth: AngularFireAuth,
    private idle: Idle,
    private keepalive: Keepalive,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) {

    // set idle parameters
    this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(3600); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
      this.presenceService.updateOnAway();
      this.presenceService.setPresence(this.auth.currentUserId, 'away');
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      this.presenceService.setPresence(this.auth.currentUserId, 'online');
      this.countdown = null;
      this.cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT"
      this.presenceService.updateOnDisconnect();
      this.logout();
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    // set keepalive parameters, omit if not using keepalive
    this.keepalive.interval(15); // will ping at this interval while not idle, in seconds
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });


    // this.reset();
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  //Logout when idle timeouted
  logout() {
    const fire = firebase.firestore();
    fire.collection('users').doc(this.auth.currentUserId).get().then(val => {
      this.presenceService.signOut()
      if (this.auth.isViewer(val.data())) {
        this.auth.signOutVeolia();
      } else {
        this.auth.signOutVendor(this.auth.currentUserEmail);
      }
    });
  }

  openPresenceDialog() {
    this.isOpenPresenceConfirm = true;
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      disableClose: true,
      data: {
        title: 'DLA',
        content: 'DLA is opened in another window(s) or tab(s). Please verify if you don\'t have unsaved changes.\n Click "Use Here" to use DLA in this window.',
        cancelBtnText: 'Close',
        confirmBtnText: 'Use Here'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
       this.isOpenPresenceConfirm = false;

       if (result === true) {
         this.isConfirmed = true;
         this.presenceService.confirmPresence(this.auth.currentUserId, this.presenceService.tabId);

         setTimeout(() => {
          this.presenceService.cleanPresence(this.auth.currentUserId);
         }, 2000);
       }
    });

  }

  ngAfterViewInit() {
    // listen presence
    this.afAuth.authState.subscribe(user => {
      // Check if user is authentified
      if (user && user.uid) {
        this.presenceService.setPresence(this.auth.currentUserId, 'online');
        this.presenceService.updateOnDisconnect().subscribe();
        this.presenceService.updateOnUser().subscribe();

        this.presence$ = this.presenceService.getPresence(this.auth.currentUserId);
        this.presence$.subscribe({
          next: (val) => {
            if (val) {
              // count that have at least five minutes of existence
              const recentTabsCount = val.tabs.filter(el => !IsFiveMinutesAgo(el.timestamp)).length;

              // Need confirmation when we have more than one tab
              if (val.tabs.length > 1 && recentTabsCount > 1) {
                // Check confirmation status and make redirection and cleaning
                if (val.status === 'confirmed') {
                  const currentTab = val.tabs.find(el => el.tabId === this.presenceService.tabId);

                  if (currentTab) {
                    if (currentTab.action === 'quit') {
                      this.presenceService.cleanPresence(this.auth.currentUserId);
                      window.location.href = "https://www.veolia.com";
                    }
                  }
                }

                // Need confirmation when we have more than one tab
                if (!this.isOpenPresenceConfirm &&  val.status === 'confirming') {
                  this.openPresenceDialog();
                }
              }
            }
          },
          error(msg) {
            console.log('Error Getting Location: ', msg);
          }
        });
      }
    });
  }

  setIntermitentPresence() {
      this.intervalId = setInterval(() => {
          console.log('5 minutes presence');
        this.presenceService.setPresence(this.auth.currentUserId,  this.idleState === "IDLE" ? 'away' : 'online');

      }, 300000);
  }

  ngOnDestroy(): void {
      this.presenceService.cleanPresence(this.auth.currentUserId);
      if (this.intervalId) {
            clearInterval(this.intervalId);
      }
    }
}
