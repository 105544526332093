import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormArray, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase/compat/app';

import { AuthService } from '../../core/auth.service';
import { DemandeDlaService } from './demandeDla.service';
import { Domains } from './domains.model';

@Component({
  selector: 'app-demandeDla',
  templateUrl: './demandeDla.component.html',
  styleUrls: ['./demandeDla.component.scss']
})

//Formulaire de la page Home avec vérification des champs
export class DemandeDlaComponent implements OnInit {
  submitted = false;
  
  demandeForm: FormGroup; //0 infos perso + Q0_ABC
  demandeTypeForm: FormGroup; //type
  demandeFormSaas: FormGroup; //SaaS
  demandeFormIaas: FormGroup; //IaaS
  demandeFormPaas: FormGroup; //PaaS
  demandeFormApps: FormGroup; //Apps/Extensions/Add-ons

  listDomains: Domains[];
  
  buOfUser: string;
  
  evaluatorsBu: string[] = [];

  conflictDate = false;
  
  //On regarde le nombre de solutions et on incremente/décremente le champs nbSolution en fonction
  numberOfSolution= 0;
  
  //checkbox
  accessQuestion: string[] = ['Veolia employees (internal or external, with a veolia.com account)',
    'Veolia customers (municipalities...)',
    'Veolia Partners',
    'Public (specify if there is authentication)',
    ];
  
  //checkbox
  interconnectQuestion: string[] = ['Yes, with other SaaS',
    'Yes, with Veolia IaaS or PaaS solution',
    "I don't know"
    ];
    
  //checkbox
  communicationQuestion: string[] = ['REST API capabilities are required',
    'File Transfer capabilities are required',
    'Offline capabilities are required',
    'Sensitive personal data are going to be accessed or stored',
    'Sensitive business data are going to be accessed or stored'
    ];
  
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private afs: AngularFirestore,
    private service: DemandeDlaService,
    private toastr: ToastrService,
    public auth: AuthService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    const fire = firebase.firestore();
    fire
      .collection('users')
      .where('uid', '==', this.authService.currentUserId)
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          this.buOfUser = doc.data().businessUnit;
        });
        
        //GET evaluators from 'businessUnit' collection matching with businessUnitOfSolution from 'solutions'
        firebase.firestore()
        .collection('businessUnit')
        .where('nomBu', '==', this.buOfUser)
        .get()
          .then(snapshot => {
            snapshot.forEach(doc => { 
              this.evaluatorsBu = doc.data().evaluators //GET evaluators for the BU matching with Bu of solution
              console.log("Evaluators for this solution:",this.evaluatorsBu)
            });
          });
      })
      
    this.demandeForm = this.formBuilder.group({
      //infos user
      fullNameInitiatorVeolia: [this.authService.currentUserDisplayName], //User est pris en compte dans le service
      solutionOwner: [''], //User est pris en compte dans le service
      emailInitiatorVeolia: [this.authService.currentUserEmail], //User est pris en compte dans le service
      uidInitiatorVeolia: [''], //User est pris en compte dans le service
      businessUnitOfSolution: [''], //ajouté au submit 
      
      Q0_A: ['', Validators.required],
      Q0_B: ['', Validators.required],
      Q0_C: ['', Validators.required],
    })
    
    //type
    this.demandeTypeForm = this.formBuilder.group({
      type: ['', Validators.required],
    })


    //SaaS
    this.demandeFormSaas = this.formBuilder.group({
      //Main details
      rfpName: ['', [Validators.maxLength(20), Validators.minLength(3)]], //facultatif sauf si + d'1 solutions pour le rfp
      description: ['', Validators.required],
      domainOfSolution: ['', Validators.required],
      accessQuestion: ['' , Validators.required],
      integrateQuestion: ['' , Validators.required],
      interconnectQuestion: ['' , Validators.required],
      interconnectQuestion2: ['', Validators.required],
      communicationQuestion: [''],
      expectedEvaluationDate: ['', Validators.required],
      expectedCompletionDate: ['', Validators.required],
      expectedOnlineReleaseDate: ['', Validators.required],
      nbSolution: ['', [Validators.required, Validators.min(1), Validators.max(4)]],
      
      ////Solution(s) (pour un RFP)////
      solutionSection:  this.formBuilder.array([]),
      
      //Solution (inutiliser dans le form c'est juste pour eviter erreur rawValidator)
      solutionName: [''],
      editorBrand: [''],
      inPlace: [''],
      apmNumber: [''],
      link: [''],
      //Main contact
      mainEmailVendor: [''],
      mainLastnameVendor: [''],
      mainFirstnameVendor: [''],
      mainCompanyVendor: [''],
      linkCom: [''],
    });

    
    
    //listener sur value nbSolution
    this.demandeFormSaas.get("nbSolution").valueChanges.subscribe(nb => {
      console.log('NB de solution:',nb)
      let rfp =  this.demandeFormSaas.get("rfpName")
      
      //Si +1 sol
      if(nb > 1) {
        //on rend le champs rfpName obligatoire
        rfp.setValidators(Validators.required)
        rfp.updateValueAndValidity();
      } else {
        rfp.removeValidators(Validators.required)
        rfp.updateValueAndValidity();
      } 
      //Si 4 solutions atteintes
      if(nb === 4) {
        this.toastr.info('Max 4 solutions are allowed in the scope of RFP', '4 solutions reached', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      }); //texte,titre,temps
        document.getElementById('addSolBtn').style.display = 'none'
      } else {
        document.getElementById('addSolBtn').style.display = 'block'
      }
    })
    
    //IaaS
    this.demandeFormIaas = this.formBuilder.group({
      //Main details
      q0a_iaas: ['', Validators.required],
      q0b_iaas: ['', Validators.required],
      solutionName: ['', Validators.required],
      editorBrand: ['', Validators.required],
      description: ['', Validators.required],
      domainOfSolution: ['', Validators.required],
      inPlace:  ['', Validators.required],
      apmNumber:  [''],
      link:  [''],
      comment:  [''],
    });
    
    //PaaS
    this.demandeFormPaas = this.formBuilder.group({
      //Main details
      solutionName: ['', Validators.required],
      developedBy: ['', Validators.required],
      description: ['', Validators.required],
      domainOfSolution: ['', Validators.required],
      inPlace:  ['', Validators.required],
      apmNumber:  [''],
      link:  [''],
      comment:  [''],
    });
    
    //Apps/Extensions/Add-ons
    this.demandeFormApps = this.formBuilder.group({
      //Main details
      applicationName: ['', Validators.required],
      editorBrand: ['', Validators.required],
      inUse: ['', Validators.required],
      inUse_more: ['', Validators.required],
      linkedTo:  ['', Validators.required],
      linkedTo_more:  [''],
      usedBy:  [''],
      link:  [''],
    });
    
    this.fetchDomains();
  }

  //0 Q0_ABC
  get f0() {
    return this.demandeForm.controls;
  }
  
  //type
  get ftype() {
    return this.demandeTypeForm.controls;
  }
  
  //SaaS
  get f() {
    return this.demandeFormSaas.controls;
  }
  
  //IaaS
  get f1() {
    return this.demandeFormIaas.controls;
  }
  
  //PaaS
  get f2() {
    return this.demandeFormPaas.controls;
  }
  
  //PaaS
  get f3() {
    return this.demandeFormApps.controls;
  }
  
  //SaaS
  get solutionSection(): FormArray {
    return this.demandeFormSaas.get("solutionSection") as FormArray
  }
  
  lowercaseValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && value !== value.toLowerCase()) {
        return { lowercase: true };
      }
    }
  }
  
  websiteUrlValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && (value.startsWith('www.') || value.startsWith('http'))) {
        return { blockedUrl: true };
      }
    }
  }

  //SaaS
  //au clic du bouton //ADD
  newSolution(): FormGroup {
    this.numberOfSolution = this.numberOfSolution + 1;
    this.demandeFormSaas.get('nbSolution').setValue(this.numberOfSolution);
    
    return this.formBuilder.group({
      //Solution
      solutionName: ['', [Validators.required, Validators.maxLength(20), Validators.minLength(3)]],
      rfpName: ['', [Validators.maxLength(20), Validators.minLength(3)]],
      editorBrand: ['', Validators.required],
      inPlace: ['', Validators.required],
      apmNumber: [''],
      link: [''],
      //Main contact 
      mainEmailVendor: ['',[Validators.required,
        this.lowercaseValidator(),
        //noms domaines non authorisés
        Validators.pattern('((.*)(?<!veolia.*)$)((.*)(?<!gmail.*)$)((.*)(?<!outlook.*)$)((.*)(?<!yahoo.*)$)((.*)(?<!hotmail.*)$)((.*)(?<!ymail.*)$)((.*)(?<!free.com)$)((.*)(?<!free.fr)$)')
        ]
      ],
      mainLastnameVendor: ['', Validators.required],
      mainFirstnameVendor: ['', Validators.required],
      mainCompanyVendor: ['', Validators.required],
      linkCom: ['', this.websiteUrlValidator()],
    })
  
  }
  
  //ADD ajoute la section ('The solution' et 'Main contact') supplémentaires
  addNewSolution(){
   this.solutionSection.push(this.newSolution());
  }
  
  //DELETE supprime la section ('The solution' et 'Main contact')
  removeSolution(i: number){
    this.numberOfSolution = this.numberOfSolution - 1;
    this.demandeFormSaas.get('nbSolution').setValue(this.numberOfSolution);
    
    this.solutionSection.removeAt(i);
  }
  
  //recup le noms des domaines et le place dans une liste
  fetchDomains() {
    this.service.getDomains().subscribe(actionArray => {
      this.listDomains = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Domains;
      });
    });
  }

  //infos perso + Q0_ABC
  onSubmitQ0(value){
    this.submitted = true;
    
    document.getElementById('PaaS or IaaS type 1').style.display = 'none';
    document.getElementById('PaaS or IaaS type 2').style.display = 'none';
    document.getElementById('PaaS or IaaS type 3').style.display = 'none';
    document.getElementById('PaaS or IaaS type 4').style.display = 'none';
    document.getElementById('PaaS or IaaS type 5').style.display = 'none';
    document.getElementById('SaaS type 1').style.display = 'none';
    document.getElementById('SaaS type 2').style.display = 'none';
    document.getElementById('SaaS type 3').style.display = 'none';
    document.getElementById('SaaS type 4').style.display = 'none';
    document.getElementById('SaaS type 5').style.display = 'none';
    document.getElementById('SaaS type 6').style.display = 'none';
    document.getElementById('SaaS type 7').style.display = 'none';
    document.getElementById('Apps type 1').style.display = 'none';
    document.getElementById('Apps type 2').style.display = 'none';
    document.getElementById('Apps type 3').style.display = 'none';
    document.getElementById('Idk type 1').style.display = 'none';
    document.getElementById('Idk type 2').style.display = 'none';
    document.getElementById('Idk type 3').style.display = 'none';
    
    document.getElementById('formulaireType').style.display = 'none';
    
    //si Q0_ABC incomplet
    if (this.demandeForm.invalid) {
      
      this.toastr.warning('Error fields', 'Check out the required fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      }); //texte,titre,temps
    
    document.getElementById('formulaireType').style.display = 'none';

    } else {//SI formulaire complet //si form valide
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('PaaS or IaaS type 1').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === 'No') {
        document.getElementById('PaaS or IaaS type 2').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('PaaS or IaaS type 1').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('SaaS type 1').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === 'No') {
        document.getElementById('SaaS type 2').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('SaaS type 3').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('SaaS type 4').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === 'No') {
        document.getElementById('SaaS type 5').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'Yes' && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('SaaS type 6').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('PaaS or IaaS type 4').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === 'No') {
        document.getElementById('PaaS or IaaS type 5').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('PaaS or IaaS type 5').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('Apps type 1').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === 'No') {
        document.getElementById('Idk type 1').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('Apps type 2').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('PaaS or IaaS type 4').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === 'No') {
        document.getElementById('PaaS or IaaS type 5').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === 'No' && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('PaaS or IaaS type 5').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('PaaS or IaaS type 4').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === 'No') {
        document.getElementById('PaaS or IaaS type 5').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === 'Yes' && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('PaaS or IaaS type 5').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('SaaS type 1').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === 'No') {
        document.getElementById('SaaS type 7').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === 'No' && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('SaaS type 7').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === 'Yes') {
        document.getElementById('Apps type 3').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === 'No') {
        document.getElementById('Idk type 2').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      if (this.f0.Q0_A.value === "I don't know" && this.f0.Q0_B.value === "I don't know" && this.f0.Q0_C.value === "I don't know") {
        document.getElementById('Idk type 3').style.display = 'block';
        
        document.getElementById('formulaireType').style.display = 'inline-flex';
      }
      
    }
    
  }

  //SaaS
  onSubmitSaas(value) {
    this.demandeForm.get('businessUnitOfSolution').setValue(this.buOfUser);
    
    // console.log("Firstname = " + this.f.firstnameVendor.value)
    // console.log("lastname = " + this.f.lastnameVendor.value)
    this.submitted = true;

    //on reinitialise
    this.conflictDate = false;
    
    //console.log(this.demandeFormSaas.controls.prenomPm.value, value);

    let completionDate = this.demandeFormSaas.controls.expectedCompletionDate.value;
    let evaluationDate = this.demandeFormSaas.controls.expectedEvaluationDate.value;
    
    //on vérifie que la date de completion attendue est antérieur à la date d'évaluation
      if (completionDate >= evaluationDate) {
        this.conflictDate = true;
      }

    //si formulaire incomplet
    if (this.demandeFormSaas.invalid || this.conflictDate === true) {
      
      this.toastr.warning('Error fields', 'Check out the required fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      }); //texte,titre,temps
    
    //méthodes pour récupérer une val
    // console.log('BU1',value.solutionSection.businessUnitOfSolution);
    // console.log('BU2',this.f.businessUnitOfSolution.value);
    // console.log('BU3',this.demandeFormSaas.value.businessUnitOfSolution);

    } else {//SI formulaire complet //si form valide

      //ajout des données du form dans la bd
      const data0 = Object.assign({}, this.demandeForm.value); //infos user + Q0_ABC
      const dataType = Object.assign({}, this.demandeTypeForm.value); //type
      const dataSaas1 = Object.assign({}, value); //SaaS demandeFormSaas
      const dataSaas2 = Object.assign({}, value.solutionSection); //SaaS demandeFormSaas solutionSection
      this.service.newDemandeSaas(data0, dataType, dataSaas1, dataSaas2, this.buOfUser, this.evaluatorsBu);
      
      for(let i= 0; i<=dataSaas1.nbSolution-1; i++) {
        this.toastr.success(
          'An email has just been sent to you for your solution: ' + value.solutionSection[i].solutionName,
          'Request has been noted !',
          { timeOut: 20000, closeButton: true, positionClass: 'toast-bottom-center' }
        ); //texte,titre,temps
      }
      
      this.router.navigate(['homeUser']);

    }

    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value));
    //console.log(this.registerForm.value.solutionName);
}


//IaaS
  onSubmitIaas(value) {
    this.demandeForm.get('businessUnitOfSolution').setValue(this.buOfUser);
  
    this.submitted = true;

    //si formulaire incomplet
    if (this.demandeFormIaas.invalid) {
      
      this.toastr.warning('Error fields', 'Check out the required fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      }); //texte,titre,temps

    } else {//SI formulaire complet //si form valide

      //ajout des données du form dans la bd
      const data0 = Object.assign({}, this.demandeForm.value); //infos user + Q0_ABC
      const dataType = Object.assign({}, this.demandeTypeForm.value); //type
      const dataIaas = Object.assign({}, value); //SaaS demandeFormIaas
      this.service.newDemandeIaas(data0, dataType, dataIaas, this.buOfUser);
      
      this.toastr.success(
          'An email has just been sent to you for your solution: ' + value.solutionName,
          'Request has been noted !',
          { timeOut: 20000, closeButton: true, positionClass: 'toast-bottom-center' }
        ); //texte,titre,temps
      
      this.router.navigate(['homeUser']);
      
      }

    }
    
  //PaaS
  onSubmitPaas(value) {
    this.demandeForm.get('businessUnitOfSolution').setValue(this.buOfUser);
  
    this.submitted = true;

    //si formulaire incomplet
    if (this.demandeFormPaas.invalid) {
      
      this.toastr.warning('Error fields', 'Check out the required fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      }); //texte,titre,temps

    } else {//SI formulaire complet //si form valide
      console.log('REQUEST',value, this.demandeForm.value)
      
      //ajout des données du form dans la bd
      const data0 = Object.assign({}, this.demandeForm.value); //stock dans une var l'ensemble données infos user + type solution
      const data = Object.assign({}, value); //stock dans une var l'ensemble données sauvegardées du form
      this.service.newDemandePaas(data0, data); //Ajout de la demande
      
      this.toastr.success(
          'An email has just been sent to you for your solution: ' + value.solutionName,
          'Request has been noted !',
          { timeOut: 20000, closeButton: true, positionClass: 'toast-bottom-center' }
        ); //texte,titre,temps
      
      this.router.navigate(['homeUser']);
      
      }

    }
    
  //Apps/Extensions/Add-ons
  onSubmitApps(value) {
    this.demandeForm.get('businessUnitOfSolution').setValue(this.buOfUser);
  
    this.submitted = true;

    //si formulaire incomplet
    if (this.demandeFormApps.invalid) {
      
      this.toastr.warning('Error fields', 'Check out the required fields', {
        timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      }); //texte,titre,temps

    } else {//SI formulaire complet //si form valide
      console.log('REQUEST',value, this.demandeForm.value)
      
      //ajout des données du form dans la bd
      const data0 = Object.assign({}, this.demandeForm.value); //stock dans une var l'ensemble données infos user + type solution
      const data = Object.assign({}, value); //stock dans une var l'ensemble données sauvegardées du form
      this.service.newDemandeApps(data0, data); //Ajout de la demande
      
      this.toastr.success(
          'An email has just been sent to you for your solution: ' + value.solutionName,
          'Request has been noted !',
          { timeOut: 20000, closeButton: true, positionClass: 'toast-bottom-center' }
        ); //texte,titre,temps
      
      this.router.navigate(['homeUser']);
      
      }

    }

}
