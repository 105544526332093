import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[url-clean]',
    host: {
        '(input)': '$event'
    }
})
export class UrlCleanerDirective {
    lastValue: string;
    
    constructor(public ref: ElementRef) {}
    
    @HostListener('input', ['$event']) onInput($event)
    {
        const inputElement = event.target as HTMLInputElement;
        let value = inputElement.value;
        value = value.replace(/^(https?:\/\/)?(www\.)?/, '');
        inputElement.value = value;
    }
}