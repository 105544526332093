import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../core/auth.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Demande } from '../demandeDla/demande.model';
import firebase from 'firebase/compat/app';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/compat/database';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private firestore: AngularFirestore,
    private afs: AngularFireDatabase,
    public auth: AuthService //toujours utilisé dans le cas ou on veut faire des {{user.?}},
  ) {}

  ngOnInit() {
    this.toastr.warning('If you wish to modify your information, please use the page contact', 'Important', {
      timeOut: 10000,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-center'
    });
  }
  
  changeStatus() {
      //LEGAL firestore
      //legalStatus in 'solutions' - Under assessment
      // const fire2 = firebase.firestore();
      // fire2.collection('solutions').where('legalStatus', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef2: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       legalStatus: 'DLA is reviewing'
      //     };
      
      //     userRef2.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //legalStatus in 'solutions' - In progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').where('legalStatus', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef3: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       legalStatus: 'Vendor is responding'
      //     };
      
      //     userRef3.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //legalStatus in 'legalForm' - Under assessment
      // const fire4 = firebase.firestore();
      // fire4.collection('legalForm').where('legalStatus', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef4: AngularFirestoreDocument<Demande> = this.firestore.doc(`legalForm/${doc.id}`);

      //     const data: Demande = {
      //       legalStatus: 'DLA is reviewing'
      //     };
      
      //     userRef4.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //legalStatus in 'legalForm' - In progress
      // const fire5 = firebase.firestore();
      // fire5.collection('legalForm').where('legalStatus', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef5: AngularFirestoreDocument<Demande> = this.firestore.doc(`legalForm/${doc.id}`);

      //     const data: Demande = {
      //       legalStatus: 'Vendor is responding'
      //     };
      
      //     userRef5.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      //END LEGAL OK
      
      //DESIGN firestore
      //designStatus in 'solutions' - Under assessment
      // const fire2 = firebase.firestore();
      // fire2.collection('solutions').where('designStatus', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef2: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       designStatus: 'DLA is reviewing'
      //     };
      
      //     userRef2.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //designStatus in 'solutions' - In progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').where('designStatus', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef3: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       designStatus: 'Vendor is responding'
      //     };
      
      //     userRef3.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //designStatus in 'designForm' - Under assessment
      // const fire4 = firebase.firestore();
      // fire4.collection('designForm').where('designStatus', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef4: AngularFirestoreDocument<Demande> = this.firestore.doc(`designForm/${doc.id}`);

      //     const data: Demande = {
      //       designStatus: 'DLA is reviewing'
      //     };
      
      //     userRef4.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //designStatus in 'designForm' - In progress
      // const fire5 = firebase.firestore();
      // fire5.collection('designForm').where('designStatus', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef5: AngularFirestoreDocument<Demande> = this.firestore.doc(`designForm/${doc.id}`);

      //     const data: Demande = {
      //       designStatus: 'Vendor is responding'
      //     };
      
      //     userRef5.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      //END DESIGN OK
      
      //ARTICLE 32 firestore
      //article32Status in 'solutions' - Under assessment
      // const fire2 = firebase.firestore();
      // fire2.collection('solutions').where('article32Status', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef2: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       article32Status: 'DLA is reviewing'
      //     };
      
      //     userRef2.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //article32Status in 'solutions' - In progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').where('article32Status', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef3: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       article32Status: 'Vendor is responding'
      //     };
      
      //     userRef3.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //article32Status in 'article32Form' - Under assessment
      // const fire4 = firebase.firestore();
      // fire4.collection('article32Form').where('article32Status', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef4: AngularFirestoreDocument<Demande> = this.firestore.doc(`article32Form/${doc.id}`);

      //     const data: Demande = {
      //       article32Status: 'DLA is reviewing'
      //     };
      
      //     userRef4.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //article32Status in 'article32Form' - In progress
      // const fire5 = firebase.firestore();
      // fire5.collection('article32Form').where('article32Status', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef5: AngularFirestoreDocument<Demande> = this.firestore.doc(`article32Form/${doc.id}`);

      //     const data: Demande = {
      //       article32Status: 'Vendor is responding'
      //     };
      
      //     userRef5.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      //END ARTICLE 32 OK
      
      //ARTICLE 28 firestore
      //article28Status in 'solutions' - Under assessment
      // const fire2 = firebase.firestore();
      // fire2.collection('solutions').where('article28Status', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef2: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       article28Status: 'DLA is reviewing'
      //     };
      
      //     userRef2.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //article28Status in 'solutions' - In progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').where('article28Status', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef3: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       article28Status: 'Vendor is responding'
      //     };
      
      //     userRef3.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //article28Status in 'article28Form' - Under assessment
      // const fire4 = firebase.firestore();
      // fire4.collection('article28Form').where('article28Status', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef4: AngularFirestoreDocument<Demande> = this.firestore.doc(`article28Form/${doc.id}`);

      //     const data: Demande = {
      //       article28Status: 'DLA is reviewing'
      //     };
      
      //     userRef4.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //article28Status in 'article28Form' - In progress
      // const fire5 = firebase.firestore();
      // fire5.collection('article28Form').where('article28Status', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef5: AngularFirestoreDocument<Demande> = this.firestore.doc(`article28Form/${doc.id}`);

      //     const data: Demande = {
      //       article28Status: 'Vendor is responding'
      //     };
      
      //     userRef5.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      //END ARTICLE 28 OK
      
      //status
      //status in 'solutions' - Under assessment
      // const fire2 = firebase.firestore();
      // fire2.collection('solutions').where('status', '==', 'Under assessment')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef2: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       status: 'DLA is reviewing'
      //     };
      
      //     userRef2.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      
      // //status in 'solutions' - In progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').where('status', '==', 'In progress')
      // .get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
      //     const userRef3: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${doc.id}`);

      //     const data: Demande = {
      //       status: 'Vendor is responding'
      //     };
      
      //     userRef3.set(data, { merge: true }).catch(err => {
      //       console.log('Error getting documents', err);
      //     });
      //   })
      // })
      //status OK
      
      //Design Form node -- 'status' realtime db - in progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
          
      //       firebase
      //       .database()
      //       .ref('evaluations/Design Form/' + doc.id)
      //       .once('value', snapshot => { 
      //           snapshot.forEach(data => {
      //             console.log("DOC", snapshot.key, ' => ', data.val);
                  
      //                 if(data.child('status').toJSON() === 'In progress') { //c'est l'éval qui est in progress pas le vendor qui rempli donc on met le statut DLA is reviewing et pas Vendor is responding
      //                   this.afs.list('evaluations/Design Form/' + doc.id).update(data.key, {
      //                     status: 'DLA is reviewing'
      //                   });
      //                 }
      //           })
      //       })
      //   })
      // })
      
      // //Design Form node -- 'status' realtime db - under assessment
      // const fire4 = firebase.firestore()
      // fire4.collection('solutions').get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
          
      //       firebase
      //       .database()
      //       .ref('evaluations/Design Form/' + doc.id)
      //       .once('value', snapshot => { 
      //           snapshot.forEach(data => {
      //             console.log("DOC", snapshot.key, ' => ', data.val);
                  
      //                 if(data.child('status').toJSON() === 'Under assessment') {
      //                   this.afs.list('evaluations/Design Form/' + doc.id).update(data.key, {
      //                     status: 'DLA is reviewing'
      //                   });
      //                 }
      //           })
      //       })
      //   })
      // })
      //Fin Design realtime db 
      
      // //Legal Form node -- 'status' realtime db - in progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
          
      //       firebase
      //       .database()
      //       .ref('evaluations/Legal Form/' + doc.id)
      //       .once('value', snapshot => { 
      //           snapshot.forEach(data => {
      //             console.log("DOC", snapshot.key, ' => ', data.val);
                  
      //                 if(data.child('status').toJSON() === 'In progress') {
      //                   this.afs.list('evaluations/Legal Form/' + doc.id).update(data.key, {
      //                     status: 'DLA is reviewing'
      //                   });
      //                 }
      //           })
      //       })
      //   })
      // })
      
      // //Legal Form node -- 'status' realtime db - under assessment
      // const fire4 = firebase.firestore()
      // fire4.collection('solutions').get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
          
      //       firebase
      //       .database()
      //       .ref('evaluations/Legal Form/' + doc.id)
      //       .once('value', snapshot => { 
      //           snapshot.forEach(data => {
      //             console.log("DOC", snapshot.key, ' => ', data.val);
                  
      //                 if(data.child('status').toJSON() === 'Under assessment') {
      //                   this.afs.list('evaluations/Legal Form/' + doc.id).update(data.key, {
      //                     status: 'DLA is reviewing'
      //                   });
      //                 }
      //           })
      //       })
      //   })
      // })//Fin Legal realtime db
      
      // //Article 32 Form node -- 'status' realtime db - in progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
          
      //       firebase
      //       .database()
      //       .ref('evaluations/Article 32 Form/' + doc.id)
      //       .once('value', snapshot => { 
      //           snapshot.forEach(data => {
      //             console.log("DOC", snapshot.key, ' => ', data.val);
                  
      //                 if(data.child('status').toJSON() === 'In progress') {
      //                   this.afs.list('evaluations/Article 32 Form/' + doc.id).update(data.key, {
      //                     status: 'DLA is reviewing'
      //                   });
      //                 }
      //           })
      //       })
      //   })
      // })
      
      // //Article 32 Form node -- 'status' realtime db - under assessment
      // const fire4 = firebase.firestore()
      // fire4.collection('solutions').get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
          
      //       firebase
      //       .database()
      //       .ref('evaluations/Article 32 Form/' + doc.id)
      //       .once('value', snapshot => { 
      //           snapshot.forEach(data => {
      //             console.log("DOC", snapshot.key, ' => ', data.val);
                  
      //                 if(data.child('status').toJSON() === 'Under assessment') {
      //                   this.afs.list('evaluations/Article 32 Form/' + doc.id).update(data.key, {
      //                     status: 'DLA is reviewing'
      //                   });
      //                 }
      //           })
      //       })
      //   })
      // })//Fin Article 32 realtime db
      
      //Article 28 Form node -- 'status' realtime db - in progress
      // const fire3 = firebase.firestore();
      // fire3.collection('solutions').get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
          
      //       firebase
      //       .database()
      //       .ref('evaluations/Article 28 Form/' + doc.id)
      //       .once('value', snapshot => { 
      //           snapshot.forEach(data => {
      //             console.log("DOC", snapshot.key, ' => ', data.val);
                  
      //                 if(data.child('status').toJSON() === 'In progress') {
      //                   this.afs.list('evaluations/Article 28 Form/' + doc.id).update(data.key, {
      //                     status: 'DLA is reviewing'
      //                   });
      //                 }
      //           })
      //       })
      //   })
      // })
      
      // //Article 28 Form node -- 'status' realtime db - under assessment
      // const fire4 = firebase.firestore()
      // fire4.collection('solutions').get().then(snap => {
      //   snap.forEach(doc => {
      //     console.log("DOC", doc.id, ' => ', doc.data());
          
      //       firebase
      //       .database()
      //       .ref('evaluations/Article 28 Form/' + doc.id)
      //       .once('value', snapshot => { 
      //           snapshot.forEach(data => {
      //             console.log("DOC", snapshot.key, ' => ', data.val);
                  
      //                 if(data.child('status').toJSON() === 'Under assessment') {
      //                   this.afs.list('evaluations/Article 28 Form/' + doc.id).update(data.key, {
      //                     status: 'DLA is reviewing'
      //                   });
      //                 }
      //           })
      //       })
      //   })
      // })//Fin Article 28 realtime db
  }
}
