<button mat-raised-button title="Click to download the solution overview" style="margin-left: 120px;" (click)="generatePdf()"
  >Download PDF of request summary<em class="material-icons" style="margin-right: 5px"> cloud_download </em></button
>
<br /><br />

<div id="fullSolInfoForPdf">
  
  <p *ngIf="download === true && tabName==='Article 28 Form'" style="text-align:center; font-size: 20px;">Solution-Form overview for {{ solutionName }} : Annexes</p>
  <p *ngIf="download === true && tabName!=='Article 28 Form'" style="text-align:center; font-size: 20px;">Solution-Form overview for {{ solutionName }} : {{ tabName }}</p>
  
  <section *ngIf="type === 'SaaS'">
    <!-- PARTIE DROITS AUX FORMULAIRES -->
    <div>
      <strong translate class="title" style="color: #5bc0de;">Users access</strong>
      
      <div *ngIf="(auth.user | async) as user">
      <a mat-raised-button
        *ngIf="isSoOfSolution === true || auth.isAdmin(user) || auth.isEvaluator(user)"
        class="submit-button btn"
        color="primary"
        (click)="redirectGestionVendor(service.idSolution,'SaaS')"
        title="{{'Edit access rights' | translate}}" data-dismiss="modal"
        >Edit rights</a
      >
      </div>
      <br />
      <br />
      <div>
        <strong translate class="owner" style="color: #5bc0de;">Current solution owner:</strong>&nbsp;
        <strong>{{solutionOwner}}</strong>
      </div>
      <br />
      <br />

      
      <p>{{ 'Veolia email access' | translate }}:</p>
      <table style="display: initial;" aria-label="Veolia users">
        <tr>
          <th scope="col">{{ 'User email' | translate }}</th>
        </tr>  
        <tr *ngFor="let email of emailVeoliaAuthorized; index as i">
          <td><strong>{{ i }}- {{email}}</strong></td>
        </tr>
      </table>
      
      <br />
      <br />
      
      <p>{{ 'Editor email access' | translate }}:</p>
      <table style="display: initial;" aria-label="Editor users">
        <tr>
          <th scope="col">{{ 'User email' | translate }}</th>
        </tr>
        <tr *ngFor="let email of emailVendorAuthorized; index as i">
          <td><strong>{{ i }}- {{email}}</strong></td>
        </tr>
      </table>
    
    
  </div>
    
    <br />
    <!-- PARTIE EVALUATION -->
    <div>
      <div *ngFor="let object of currentEvaluation">
        <strong translate class="title" style="color: #5bc0de;">Evaluation informations</strong>
          <div style="text-align : left">
          <!-- Si aucune évaluation le bloc ne s'affiche pas -->
          <p>
            {{ 'lastUpdate of evaluation' | translate }}: <strong>{{ object.postedAt | date: 'MMMM d, y, h:mm a' }}</strong>
          </p>
          <p>
            {{ 'Status of evaluation' | translate }}: <strong>{{ object.status || 'Evaluation is not fully completed yet' }}</strong>
          </p>
          <p>
            {{ 'Final comment' | translate }}: <strong>{{ object.finalComment || 'No comment yet added by evaluator' }}</strong>
          </p>
        </div>
      </div>
  </div>
    
    <br />
    <!-- PARTIE FORMULAIRE -->
    <div>
      <strong translate class="title" style="color: #5bc0de;">Form informations</strong>
      <div style="text-align : left">
      <p>Version json-FR: <strong>{{ jsonFR }}</strong></p>
      <p>Version json-US: <strong>{{ jsonUS }}</strong></p>
                
      <p>
        {{ 'Formulaire créé le' | translate }}: 
        <strong *ngIf="!createdAt">{{ 'The vendor/PM/SO has not yet accessed the form'  }}</strong>
        <strong *ngIf="createdAt">{{ createdAt | date: 'MMMM d, y, h:mm a' }}</strong>
      </p>
      
      <!-- status legal ou design ou ar28 ou ar32 selon l'onglet-->
      <p *ngIf="service.tabName === 'Design Form'">
        {{ 'status of the current form' | translate }}:
        <strong *ngIf="!designStatus">{{ 'The vendor did not begin to complete the form' }} </strong>
        <strong *ngIf="designStatus">{{ designStatus }} </strong>
      </p>
      
      <p *ngIf="service.tabName === 'Legal Form'">
        {{ 'status of the current form' | translate }}:
        <strong *ngIf="!legalStatus">{{ 'The vendor did not begin to complete the form' }} </strong>
        <strong *ngIf="legalStatus">{{ legalStatus }} </strong>
      </p>
      
      <p *ngIf="service.tabName === 'Article 32 Form'">
        {{ 'status of the current form' | translate }}:
        <strong *ngIf="!article32Status">{{ 'The vendor did not begin to complete the form' }} </strong>
        <strong *ngIf="article32Status">{{ article32Status }} </strong>
      </p>
      
      <p *ngIf="service.tabName === 'Article 28 Form'">
        {{ 'status of the current form' | translate }}:
        <strong *ngIf="!article28Status">{{ 'The vendor did not begin to complete the form' }} </strong>
        <strong *ngIf="article28Status">{{ article28Status }} </strong>
      </p>
      
      <!-- dernière maj du designForm ou legalForm ou article32Form ou article28Form (selon onglet) et pas de la demande -->
      <p>
        {{ 'Last update of the form' | translate }}:
        <strong *ngIf="!lastUpdate">{{ 'The vendor did not begin to complete the form' }} </strong>
        <strong *ngIf="lastUpdate">{{ lastUpdate | date: 'MMMM d, y, h:mm a' }} </strong>
      </p>
    
      <p>
        {{ 'lastUpdateBy of the form' | translate }}:
        <strong>{{ lastUpdateBy || 'The vendor did not begin to complete the form' }}</strong>
      </p>
    
      <p>
        {{ 'firstUpdate of the form' | translate }}:
        <strong *ngIf="!firstUpdate">{{ 'The vendor did not begin to complete the form' }} </strong>
        <strong *ngIf="firstUpdate">{{ firstUpdate | date: 'MMMM d, y, h:mm a' }} </strong>
      </p>
    
      <p>
        {{ 'firstUpdateBy of the form' | translate }}:
        <strong>{{ firstUpdateBy || 'The vendor did not begin to complete the form' }}</strong>
      </p>
    </div>
  </div>
    
    <br />
    <!-- PARTIE demande/solution en elle même -->
    <div>
      <h4 style="color:#00aec7;" translate>Solution/Request informations</h4>
      <strong translate class="title" style="color: #5bc0de;">Primary informations</strong>
      <div style="text-align : left">
        <p>
        {{ 'full Name initiator' | translate }}: <strong>{{ fullNameInitiatorVeolia }}</strong>
        </p>
        <p>
        {{ 'email initiator' | translate }}: <strong>{{ emailInitiatorVeolia }}</strong>
        </p>
        <p>
        {{ 'Solution owner' | translate }}: <strong>{{ solutionOwner }}</strong>
        </p>
        <p>
        {{ 'bu' | translate }}: <strong>{{ businessUnitOfSolution }}</strong>
        </p>
      </div>
      
      <strong translate class="title" style="color: #5bc0de;">Informations about the request</strong>
      <div style="text-align : left">
        <p>
          {{ 'Date de demande' | translate }}: <strong>{{ requestDate | date: 'longDate' }}</strong>
        </p>
        <p>
          {{ 'Statut de la demande' | translate }}: <strong>{{ status }}</strong>
        </p>
        <!--Seulement si Design + Legal + Art32 évalué => fullyEvaluated=true -->
        <p *ngIf="fullyEvaluated===true">
          {{ "Date de l'évaluation finale" | translate }}: <strong>{{ finalEvaluationDate | date: 'longDate' }}</strong>
        </p>
      </div>
      
        <strong translate class="title" style="color: #5bc0de;">Main details</strong>
          <div style="text-align : left">
            <p>
              {{ 'Solution type' | translate }}: <strong>{{ type }}</strong>
            </p>
            <p>
              {{ 'Nom RFP' | translate }}: <strong>{{ rfpName }}</strong>
            </p>
            <p>
            {{ 'Domain' | translate }}: <strong>{{ domainOfSolution }}</strong>
            </p>
            <p>
              {{ 'Pouvez-vous décrire le besoin ?' | translate }}: <strong>{{ description }}</strong>
            </p>
            <p>
              {{ 'Who will access the solution ?' | translate }}: <strong>{{ accessQuestion }}</strong>
            </p>
            <p>
              {{ 'Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?' | translate }}: <strong>{{ integrateQuestion }}</strong>
            </p>
            <p>
              {{ 'Do you know if the solution will have to interconnect with 3rd party applications ?' | translate }}: <strong>{{ interconnectQuestion }}</strong>
            </p>
            <p>
              {{ 'Please give more details about interconnection with 3rd party applications' | translate }}: <strong>{{ interconnectQuestion2 }}</strong>
            </p>
            <p>
              {{ 'Communication with 3rd parties' | translate }}: <strong>{{ communicationQuestion }}</strong>
            </p>
            <p>
              {{ 'Date completion attendue' | translate }}: <strong>{{ expectedCompletionDate | date }}</strong>
            </p>
            <p>
              {{ 'Date eval attendue' | translate }}: <strong>{{ expectedEvaluationDate | date }}</strong>
            </p>
            <p>
              {{ 'expectedOnlineReleaseDate' | translate }}: <strong>{{ expectedOnlineReleaseDate | date }}</strong>
            </p>
            <p>
              {{ 'Nombre de solution à évaluer' | translate }}: <strong>{{ nbSolution }}</strong>
            </p>
          </div>
          
          <strong translate class="title" style="color: #5bc0de;">The solution</strong>
            <div style="text-align : left">
              <p>
              {{ 'Nom de la solution à évaluer' | translate }}: <strong>{{ solutionName }}</strong>
              </p>
              <p>
              {{ 'Nom editeur' | translate }}: <strong>{{ editorBrand }}</strong>
              </p>
              <p>
              {{ 'Solution is already in use / DLA renewal ?' | translate }}: <strong>{{ inPlace }}</strong>
              </p>
              <p>
              {{ 'If the solution is already in use, please provide its APM number (if available)' | translate }}: <strong>{{ apmNumber }}</strong>
              </p>
              <p>
              {{ 'Lien app' | translate }}: <strong>{{ link }}</strong>
              </p>
            </div>
            
          <strong translate class="title" style="color: #5bc0de;">Main contact</strong>
            <div style="text-align : left">
              <p>
              {{ 'Email editeur' | translate }}: <strong>{{ mainEmailVendor }}</strong>
              </p>
              <p>
              {{ 'nom' | translate }}: <strong>{{ mainLastnameVendor }}</strong>
              </p>
              <p>
              {{ 'prenom' | translate }}: <strong>{{ mainFirstnameVendor }}</strong>
              </p>
              <p>
              {{ 'entreprise' | translate }}: <strong>{{ mainCompanyVendor }}</strong>
              </p>
              <p>
              {{ 'Lien app commercial' | translate }}: <strong>{{ linkCom }}</strong>
              </p>
            </div>
    
</div>
  </section>
  
  <section *ngIf="type === 'IaaS'">
    <!-- PARTIE DROITS AUX FORMULAIRES -->
    <div>
      <strong translate class="title" style="color: #5bc0de;">Users access</strong>
      
      <div *ngIf="(auth.user | async) as user">
      <a mat-raised-button
        *ngIf="isSoOfSolution === true || auth.isAdmin(user) || auth.isEvaluator(user)"
        class="submit-button btn"
        color="primary"
        (click)="redirectGestionVendor(service.idSolution,'IaaS')"
        title="{{'Edit access rights' | translate}}" data-dismiss="modal"
        >Edit rights</a
      >
      </div>
      <br />
      <br />
      
      <p>{{ 'Veolia email access' | translate }}:</p>
      <table style="display: initial;" aria-label="Veolia users">
        <tr>
          <th scope="col">{{ 'User email' | translate }}</th>
        </tr>  
        <tr *ngFor="let email of emailVeoliaAuthorized; index as i">
          <td><strong>{{ i }}- {{email}}</strong></td>
        </tr>
      </table>
    
      <br />
      <br />
    
    </div>
    
    <br />
    <!-- PARTIE EVALUATION -->
    <div>
      <div *ngFor="let object of currentEvaluation">
        <strong translate class="title" style="color: #5bc0de;">Evaluation informations</strong>
          <div style="text-align : left">
          <!-- Si aucune évaluation le bloc ne s'affiche pas -->
          <p>
            {{ 'lastUpdate of evaluation' | translate }}: <strong>{{ object.postedAt | date: 'MMMM d, y, h:mm a' }}</strong>
          </p>
          <p>
            {{ 'Status of evaluation' | translate }}: <strong>{{ object.status || 'Evaluation is not fully completed yet' }}</strong>
          </p>
          <p>
            {{ 'Final comment' | translate }}: <strong>{{ object.finalComment || 'No comment yet added by evaluator' }}</strong>
          </p>
        </div>
      </div>
    </div>
    
    <br />
    <!-- PARTIE FORMULAIRE -->
    <div>
      <strong translate class="title" style="color: #5bc0de;">Form informations</strong>
      <div style="text-align : left">
      <p>Version json-FR: <strong>{{ jsonFR }}</strong></p>
      <p>Version json-US: <strong>{{ jsonUS }}</strong></p>
      
      <p>
      {{ 'Formulaire créé le' | translate }}: 
      <strong *ngIf="!createdAt">{{ 'The vendor/PM/SO has not yet accessed the form'  }}</strong>
      <strong *ngIf="createdAt">{{ createdAt | date: 'MMMM d, y, h:mm a' }}</strong>
    </p>
      
      <!-- status IaaS -->
      <p *ngIf="service.tabName === 'IaaS Form'">
        {{ 'status of the current form' | translate }}:
        <strong *ngIf="!iaasStatus">{{ 'The Veolia owner did not begin to complete the form' }} </strong>
        <strong *ngIf="iaasStatus">{{ iaasStatus }} </strong>
      </p>
    
      <p>
        <!-- dernière maj du IaaSForm et pas de la demande -->
        {{ 'lastUpdate of the form' | translate }}:
        <strong *ngIf="!lastUpdate">{{ 'The Veolia owner did not begin to complete the form' }} </strong>
        <strong *ngIf="lastUpdate">{{ lastUpdate | date: 'MMMM d, y, h:mm a' }} </strong>
      </p>
    
      <p>
        {{ 'lastUpdateBy of the form' | translate }}:
        <strong>{{ lastUpdateBy || 'The Veolia owner did not begin to complete the form' }}</strong>
      </p>
    
      <p>
        {{ 'firstUpdate of the form' | translate }}:
        <strong *ngIf="!firstUpdate">{{ 'The Veolia owner did not begin to complete the form' }} </strong>
        <strong *ngIf="firstUpdate">{{ firstUpdate | date: 'MMMM d, y, h:mm a' }} </strong>
      </p>
    
      <p>
        {{ 'firstUpdateBy of the form' | translate }}:
        <strong>{{ firstUpdateBy || 'The Veolia owner did not begin to complete the form' }}</strong>
      </p>
    </div>
    </div>
    
    <br />
    <!-- PARTIE demande/solution en elle même -->
    <div>
      <h4 style="color:#00aec7;" translate>Solution/Request informations</h4>
      <strong translate class="title" style="color: #5bc0de;">Primary informations</strong>
      <div style="text-align : left">
        <p>
        {{ 'full Name initiator' | translate }}: <strong>{{ fullNameInitiatorVeolia }}</strong>
        </p>
        <p>
        {{ 'email initiator' | translate }}: <strong>{{ emailInitiatorVeolia }}</strong>
        </p>
        <p>
        {{ 'Solution owner' | translate }}: <strong>{{ solutionOwner }}</strong>
        </p>
        <p>
        {{ 'bu' | translate }}: <strong>{{ businessUnitOfSolution }}</strong>
        </p>
      </div>
      
      <strong translate class="title" style="color: #5bc0de;">Informations about the request</strong>
      <div style="text-align : left">
        <p>
          {{ 'Date de demande' | translate }}: <strong>{{ requestDate | date: 'longDate' }}</strong>
        </p>
        <p>
          {{ 'Statut de la demande' | translate }}: <strong>{{ status }}</strong>
        </p>
        <p *ngIf="fullyEvaluated===true">
          {{ "Date de l'évaluation finale" | translate }}: <strong>{{ finalEvaluationDate | date: 'longDate' }}</strong>
        </p>
      </div>
      
        <strong translate class="title" style="color: #5bc0de;">Main details</strong>
          <div style="text-align : left">
            <p>
                {{ 'Solution type' | translate }}: <strong>{{ type }}</strong>
              </p>
              <p>
                {{ 'Pouvez-vous décrire le besoin ?' | translate }}: <strong>{{ description }}</strong>
              </p>
              <p>
                {{ 'Domain' | translate }}: <strong>{{ domainOfSolution }}</strong>
              </p>
              <p>
                {{ 'q0a_iaas' | translate }}: <strong>{{ q0a_iaas }}</strong>
              </p>
              <p>
                {{ 'q0b_iaas' | translate }}: <strong>{{ q0b_iaas }}</strong>
              </p>
          </div>
          
          <strong translate class="title" style="color: #5bc0de;">The solution</strong>
            <div style="text-align : left">
              <p>
              {{ 'Nom de la solution à évaluer' | translate }}: <strong>{{ solutionName }}</strong>
              </p>
              <p>
              {{ 'Nom editeur' | translate }}: <strong>{{ editorBrand }}</strong>
              </p>
              <p>
              {{ 'Solution is already in use / DLA renewal ?' | translate }}: <strong>{{ inPlace }}</strong>
              </p>
              <p>
              {{ 'If the solution is already in use, please provide its APM number (if available)' | translate }}: <strong>{{ apmNumber }}</strong>
              </p>
              <p>
              {{ 'Lien app' | translate }}: <strong>{{ link }}</strong>
              </p>
            </div>
    
  </div>
  </section>

</div>