<div class="my-overlay" *ngIf="showOverlay"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></div>

<!--Onglets haut: SaaS PaaS IaaS Apps Users-->
<mat-tab-group (selectedTabChange)="tabChanged($event)">

  <!--SaaS-->
  <mat-tab label="SaaS" style="justify-content: center;" aria-label="SaaStab">
    <mat-card>
      <mat-card-content>

        <!--Onglets dashboard all demandesSaaS SaaS/all demandesSaaS evaluated/all demandesSaaS pending-->
        <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" mat-align-tabs="center">

          <!--All demandesSaaS SaaS-->
          <mat-tab label="{{ 'allSaaSRequests' | translate }}" style="justify-content: center;" aria-label="allSaaSReq">
            <!--<button type="button" (click)="getAllSolTest()">TESTgetrefreshTAB</button>-->
            <mat-card>
              <mat-card-content>

                <button mat-raised-button
                    class="submit-button legend"
                    color="primary"
                    data-toggle="modal"
                    data-target="#modalLegend"
                    onclick="modalMovable()" >{{'Legend' | translate}}
                  </button>

                <div style="text-align: end;">
                  <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
                  mat-raised-button type="button" style="color: blue;"
                  title="{{'Admin documentation' | translate}}" rel="noopener"
                  ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                  </a>

                  <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
                  mat-raised-button type="button" style="color: blue;"
                  title="{{'Admin documentation' | translate}}" rel="noopener"
                  ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                  </a>
                </div>
                <br />

                <!-- Modal légende -->
                <div
                  class="modal fade"
                  id="modalLegend"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  style="margin-top:60px"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
                      <div class="modal-header" >
                        {{ 'Legend and color code' | translate }}
                        <button class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width:470px;">
                        <div class="container" style="display:inline; text-align:left">
                          <p translate>Chaque formulaire est identifié par un statut et une couleur:</p>
                          <ul>
                            <li><strong style="color: grey">Empty</strong>: {{'Empty status' | translate}}</li>
                            <li><strong style="color: grey">New</strong>: {{'New status' | translate}}</li>
                            <li><strong style="color: #54bbba">Vendor is responding</strong>: {{'Vendor is responding status' | translate}}</li>
                            <li><strong style="color: purple">Completed</strong>: {{'Completed status' | translate}}</li>
                            <li><strong style="color: blue">DLA is reviewing</strong>: {{'DLA is reviewing status' | translate}}</li>
                            <li><strong style="color: green">Recommended</strong>: {{'Recommended status' | translate}}</li>
                            <li><strong style="color: orange">Reservations</strong>: {{'Recommended with reservations status' | translate}}</li>
                            <li><strong style="color: red">Not recommended</strong>: {{'Not recommended status' | translate}}</li>
                            <li><strong style="color: black">Need help</strong>: {{'Need help status' | translate}}</li>
                            <li><strong style="color: red">Canceled</strong>: {{'Canceled status' | translate}}</li>
                            <li><strong style="color: red">Pending</strong>: {{'Pending status' | translate}}</li>
                          </ul>

                          <hr>
                          <p><span class="material-icons">zoom_in</span>: {{'more icon' | translate}}</p>
                          <p><span class="material-icons">zoom_out</span>: {{'less icon' | translate}}</p>
                          <p>{{'Différentes actions sont possibles sous' | translate}} <span class="material-icons">more_horiz</span> :</p>
                          <p><em class="material-icons text-primary btnEdit"> edit </em>: {{'edit button' | translate}}</p>
                          <p><em class="material-icons text-warning btnShare"> share </em>: {{'share button' | translate}}</p>
                          <p><em class="material-icons text-danger btnDelete"> delete </em>: {{'delete button' | translate}}</p>
                          <p><em class="material-icons text-success btnDelete"> delete </em>: {{'restore button' | translate}}</p>
                          <p><em class="material-icons text-success btnSeeEval"> event_available </em>: {{'seeEval button' | translate}}</p>
                          <p><em class="material-icons text-primary btnLogs"> format_list_bulleted </em>: {{'logs button' | translate}}</p>
                          <p><em class="material-icons text-info btnSSC"> update </em>: {{'secuscorecard button' | translate}}</p>
                        </div>
                      </div>
                      <!-- END Body -->
                    </div>
                  </div>
                </div>
                <!-- END MODALE -->

                <table data-order='[[ 8, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All SaaS solutions" onclick="hideColumnsTab1()">
                  <thead>
                    <tr>
                    <th scope="col" title="RFP name">
                      {{ 'Nom RFP' | translate }}
                    </th>
                    <th scope="col" title="Solution name">
                      {{ 'Nom solution' | translate
                      }}
                    </th>
                    <th scope="col" title="Global status of request"><!--statut global-->
                      {{ 'DLA recommendation' | translate
                      }}
                    </th>
                    <th scope="col" title="Status of Design Form">
                      {{ 'Design' | translate
                      }}
                    </th>
                    <th scope="col" title="Status of Legal Form">
                      {{ 'Legal' | translate
                      }}
                    </th>
                    <th scope="col" title="Status of Annexes Form">
                      {{ 'Annexes' | translate
                      }}
                    </th>
                    <th scope="col" title="Status of Article 32 Form">
                      {{ 'Art 32' | translate
                      }}
                    </th>
                    <th scope="col" title="Vendor brand name and SecurityScorecard">
                      {{ 'Nom vendor et SSC' | translate
                      }}
                    </th>
                    <th scope="col" title="Request date of request">
                      {{ 'Date de demande' | translate
                      }}
                    </th>
                    <th scope="col" title="Last update on forms">
                      {{ 'lastUpdate' | translate
                      }}
                    </th>
                    <th scope="col" title="Business Unit of request">
                      {{ 'Business Unit' | translate
                      }}
                    </th>
                    <!--Hidden par défaut et affichable au clic du bouton-->
                    <th scope="col" title="Expected completion date of forms" style="display:none" class="moreTab1">
                      {{ 'Date attendue completion' | translate
                      }}
                    </th>
                    <th scope="col" title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                      {{ 'Date attendue eval' | translate
                      }}
                    </th>
                    <th scope="col" title="Current solution owner of solution" style="display:none" class="moreTab1">
                      {{ 'Proriétaire actuel' | translate
                      }}
                    </th>
                    <th scope="col" title="Type of solution" style="display:none" class="moreTab1">
                      {{ 'Type' | translate
                      }}
                    </th>
                    <th title="Business capability" style="display:none" class="moreTab1">
                      {{ 'Domain(s)' | translate
                      }}
                    </th>
                    <!--END Hidden par défaut et affichable au clic du bouton-->
                    <th scope="col" title="Actions available">
                      {{ 'Actions' | translate
                      }}
                    </th>
                   </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let demande of demandesSaaS;
                        let i = index
                      "
                      style="cursor: default"
                    >
                    <td>
                      {{ demande.rfpName }}
                      <a
                        class="modale_elem"
                        data-toggle="modal"
                        data-target="#modalOverviewSolution"
                        title="{{'recap demande/formulaire' | translate}}"
                        (click)="openDialog(demande.id)"
                        onclick="modalMovable()"
                        ><em class="fa fa-info-circle" aria-hidden="true"></em>
                      </a>
                    </td>
                    <!-- formulaires-->
                    <td style="cursor:pointer">
                      <span class="btn text-primary nomSol"
                        mat-button
                        [matMenuTriggerFor]="formsSelection"
                        title="Select the form">
                        {{ demande.solutionName }}
                      </span>

                      <mat-menu #formsSelection="matMenu">
                        <div *ngIf="demande.status !== 'Pending'" style="display: contents">
                          <a
                            class="btn text-primary"
                            (click)="gotoDesignForm(demande.id)"
                            >Design</a
                          >
                           <a
                            class="btn text-primary"
                            (click)="gotoLegalForm(demande.id)"
                            >Legal</a
                          >
                          <a
                            class="btn text-primary"
                            (click)="gotoArticle32Form(demande.id)"
                            >Article 32</a
                          >
                          <a
                            class="btn text-primary"
                            (click)="gotoArticle28Form(demande.id)"
                            >Annexes</a
                          >
                        </div>
                      </mat-menu>
                      <hr>
                      {{ demande.id }}
                    </td>
                    <td>
                      <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                      <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                      <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                    </td>
                    <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                    <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                    <td><p id="article28Sta{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>
                    <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                    <td>{{ demande.editorBrand }}
                    <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                    <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                      <div *ngIf="demande.id === sscdata.id">
                        <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                          <mat-menu #sscSelection="matMenu">
                            <ul style="padding-right: 15px">
                              <li>Company name: {{sscdata.name}}</li>
                              <li>Industry style: {{sscdata.industry}}</li>
                              <li>Domain name: {{sscdata.domain}}</li>
                              <li>Score: {{sscdata.score}}</li>
                              <li>Grade: {{sscdata.grade}} </li>
                              <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                              <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                              <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                            </ul>
                            <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                          </mat-menu>
                      </div>
                    </div>
                    </td>
                    <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                    <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                      {{ demande.lastUpdate ? (demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a'): '' }}
                    </td>
                    <!-- Il y a eu une modif des forms -->
                    <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                    <td>{{ demande.businessUnitOfSolution }}</td>
                     <!--Hidden par défaut et affichable au clic du bouton-->
                    <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate ?  (demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd'): '' }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate ? (demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd'): '' }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                    <!-- END Hidden par défaut et affichable au clic du bouton-->
                    <td style="cursor:pointer">
                      <span class="material-icons"
                      mat-button
                      [matMenuTriggerFor]="options"
                      title="Actions...">
                      more_horiz
                    </span>

                    <mat-menu #options="matMenu">
                      <div *ngIf="demande.status !== 'Canceled'">
                        <a
                          class="btn text-primary"
                          (click)="modifierDemande(demande.id,'SaaS')"
                          title="Edit the request/solution"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-warning"
                          (click)="creationCompteVendor(demande.id,'SaaS')"
                          title="Access rights for the solution/request"
                          ><em class="material-icons btnShare"> share </em></a
                        >
                        <a
                          class="btn text-danger"
                          (click)="abortSolution(demande.id,'SaaS')"
                          title="Abort the request/solution"
                          ><em class="material-icons btnDelete"> delete </em></a
                        >
                        <a
                          class="btn text-success"
                          (click)="tableauEvals(demande.id,'SaaS')"
                          title="Assessments status for request/solution forms"
                          ><em class="material-icons btnSeeEval"> event_available </em></a
                        >
                        <a
                          class="btn text-primary"
                          (click)="logs(demande.id,'SaaS')"
                          title="Logs of forms from the request/solution"
                          ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                        >
                         <a
                          class="btn text-info"
                          (click)="fetchRefreshSecurityScoreCard(demande.id, demande.linkCom, demande.solutionName)"
                          title="Refresh/Get the Security Score Card for the company"
                          ><em class="material-icons btnSSC"> update </em></a
                        >
                      </div>

                      <div *ngIf="demande.status === 'Canceled'">
                        <a
                          class="btn text-primary"
                          (click)="modifierDemande(demande.id,'SaaS')"
                          title="Edit the request/solution"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-warning"
                          (click)="creationCompteVendor(demande.id,'SaaS')"
                          title="Access rights for the solution/request"
                          ><em class="material-icons btnShare"> share </em></a
                        >
                        <a
                          class="btn text-success"
                          (click)="rollbackCancel(demande.id)"
                          title="Restore the request/solution"
                          ><em class="material-icons btnDelete"> delete </em></a
                        >
                        <a
                          class="btn text-success"
                          (click)="tableauEvals(demande.id,'SaaS')"
                          title="Assessments status for request/solution forms"
                          ><em class="material-icons btnSeeEval"> event_available </em></a
                        >
                        <a
                          class="btn text-primary"
                          (click)="logs(demande.id,'SaaS')"
                          title="Logs of forms from the request/solution"
                          ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                        >
                        <a
                          class="btn text-info"
                          (click)="fetchRefreshSecurityScoreCard(demande.id, demande.linkCom, demande.solutionName)"
                          title="Refresh/Get the Security Score Card for the company"
                          ><em class="material-icons btnSSC"> update </em></a
                        >
                      </div>
                    </mat-menu>
                    </td>
                    </tr>
                  </tbody>
                </table>


                <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                  <span class="material-icons">zoom_in</span>
                </em>

                <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                  <span class="material-icons">zoom_out</span>
                </em>

              </mat-card-content>
            </mat-card>
          </mat-tab>
          <!--END All demandesSaaS SaaS-->

          <!--All demandesSaaS SaaS évaluées-->
          <mat-tab label="{{ 'allSaaSEvaluatedRequests' | translate }}" style="justify-content: center;" aria-label="allSaaSEvalReq" onclick="hideColumnsTab1()">
            <mat-card>
              <mat-card-content>

              <button mat-raised-button
                  class="submit-button legend"
                  color="primary"
                  data-toggle="modal"
                  data-target="#modalLegend"
                  onclick="modalMovable()" >{{'Legend' | translate}}
                </button>

              <div style="text-align: end;">
                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
                mat-raised-button type="button" style="color: blue;"
                title="{{'Admin documentation' | translate}}" rel="noopener"
                ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                </a>

                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
                mat-raised-button type="button" style="color: blue;"
                title="{{'Admin documentation' | translate}}" rel="noopener"
                ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                </a>
              </div>
              <br />

                <!-- Modal légende -->
                <div
                  class="modal fade"
                  id="modalLegend"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  style="margin-top:60px"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
                      <div class="modal-header" >
                        {{ 'Legend and color code' | translate }}
                        <button class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width:470px;">
                        <div class="container" style="display:inline; text-align:left">
                          <p translate>Chaque formulaire est identifié par un statut et une couleur:</p>
                          <ul>
                            <li><strong style="color: grey">Empty</strong>: {{'Empty status' | translate}}</li>
                            <li><strong style="color: grey">New</strong>: {{'New status' | translate}}</li>
                            <li><strong style="color: #54bbba">Vendor is responding</strong>: {{'Vendor is responding status' | translate}}</li>
                            <li><strong style="color: purple">Completed</strong>: {{'Completed status' | translate}}</li>
                            <li><strong style="color: blue">DLA is reviewing</strong>: {{'DLA is reviewing status' | translate}}</li>
                            <li><strong style="color: green">Recommended</strong>: {{'Recommended status' | translate}}</li>
                            <li><strong style="color: orange">Reservations</strong>: {{'Recommended with reservations status' | translate}}</li>
                            <li><strong style="color: red">Not recommended</strong>: {{'Not recommended status' | translate}}</li>
                            <li><strong style="color: black">Need help</strong>: {{'Need help status' | translate}}</li>
                            <li><strong style="color: red">Canceled</strong>: {{'Canceled status' | translate}}</li>
                            <li><strong style="color: red">Pending</strong>: {{'Pending status' | translate}}</li>
                          </ul>

                          <hr>
                          <p><span class="material-icons">zoom_in</span>: {{'more icon' | translate}}</p>
                          <p><span class="material-icons">zoom_out</span>: {{'less icon' | translate}}</p>
                          <p>{{'Différentes actions sont possibles sous' | translate}} <span class="material-icons">more_horiz</span> :</p>
                          <p><em class="material-icons text-primary btnEdit"> edit </em>: {{'edit button' | translate}}</p>
                          <p><em class="material-icons text-warning btnShare"> share </em>: {{'share button' | translate}}</p>
                          <p><em class="material-icons text-danger btnDelete"> delete </em>: {{'delete button' | translate}}</p>
                          <p><em class="material-icons text-success btnSeeEval"> event_available </em>: {{'seeEval button' | translate}}</p>
                          <p><em class="material-icons text-primary btnLogs"> format_list_bulleted </em>: {{'logs button' | translate}}</p>
                          <p><em class="material-icons text-info btnSSC"> update </em>: {{'secuscorecard button' | translate}}</p>
                        </div>
                      </div>
                      <!-- END Body -->
                    </div>
                  </div>
                </div>
                <!-- END MODALE -->

            <table data-order='[[ 8, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All evaluated SaaS solutions">
              <thead>
                <tr>
                <th scope="col" title="RFP name">
                  {{ 'Nom RFP' | translate
                  }}
                </th>
                <th scope="col" title="Solution name">
                  {{ 'Nom solution' | translate
                  }}
                </th>
                <th scope="col" title="Global status of request"><!--statut global-->
                {{ 'DLA recommendation' | translate
                }}
                </th>
                <th scope="col" title="Status of Design Form">
                  {{ 'Design' | translate
                  }}
                </th>
                <th scope="col" title="Status of Legal Form">
                  {{ 'Legal' | translate
                  }}
                </th>
                <th scope="col" title="Status of Annexes Form">
                  {{ 'Annexes' | translate
                  }}
                </th>
                <th scope="col" title="Status of Article 32 Form">
                  {{ 'Art 32' | translate
                  }}
                </th>
                <th scope="col" title="Vendor brand name and SecurityScorecard">
                  {{ 'Nom vendor et SSC' | translate
                  }}
                </th>
                <th scope="col" title="Request date of request">
                  {{ 'Date de demande' | translate
                  }}
                </th>
                <th scope="col" title="Last update on forms">
                  {{ 'lastUpdate' | translate
                  }}
                </th>
                <th scope="col" title="Business Unit of request">
                  {{ 'Business Unit' | translate
                  }}
                </th>
                <!--Hidden par défaut et affichable au clic du bouton-->
                <th scope="col" title="Expected completion date of forms" style="display:none" class="moreTab1">
                  {{ 'Date attendue completion' | translate
                  }}
                </th>
                <th scope="col" title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                  {{ 'Date attendue eval' | translate
                  }}
                </th>
                <th scope="col" title="Current solution owner of solution" style="display:none" class="moreTab1">
                  {{ 'Proriétaire actuel' | translate
                  }}
                </th>
                <th scope="col" title="Type of solution" style="display:none" class="moreTab1">
                  {{ 'Type' | translate
                  }}
                </th>
                <th title="Business capability" style="display:none" class="moreTab1">
                  {{ 'Domain(s)' | translate
                  }}
                </th>
                <!--END Hidden par défaut et affichable au clic du bouton-->
                <th scope="col" title="Actions available">
                  {{ 'Actions' | translate
                  }}
                </th>
                </tr>
              </thead>
              <tbody>
                <tr
                *ngFor="
                  let demande of demandesSaaSEvaluees;
                  let i = index
                  "
                  style="cursor: default"
                >
                  <td>{{ demande.rfpName }}
                  <a
                        class="modale_elem"
                        data-toggle="modal"
                        data-target="#modalOverviewSolution"
                        title="{{'recap demande/formulaire' | translate}}"
                        (click)="openDialog(demande.id)"
                        onclick="modalMovable()"
                        ><em class="fa fa-info-circle" aria-hidden="true"></em>
                      </a>
                  </td>
                  <!-- formulaires-->
                  <td style="cursor:pointer">
                    <!--Pour chaque compagny contractualisée, on fetch les data depuis le portofolio SCC "DLA contractualized solutions" ce sera dans le futur mais ici le code est déjà prêt-->
                    <!--<img [matMenuTriggerFor]="sscSelection" src='{{serverDataGet.grade_url}}' alt="Company SSC" width="30px" style="float: left">-->

                    <!--  <mat-menu #sscSelection="matMenu"> <!--IL faut l'afficher pour chaque url-->
                    <!--    <ul style="padding-right: 15px">-->
                    <!--      <li>Company name: {{serverDataGet.name}}</li>-->
                    <!--      <li>Industry style: {{serverDataGet.industry}}</li>-->
                    <!--      <li>Domain name: {{serverDataGet.domain}}</li>-->
                    <!--      <li>Score: {{serverDataGet.score}}</li>-->
                    <!--      <li>Grade: {{serverDataGet.grade}} </li>-->
                    <!--      <li>Score over 30 days: {{serverDataGet.last30day_score_change}} </li>-->
                    <!--    </ul>-->
                    <!--    <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/badge/{{serverDataGet.domain}}">Visit the SecurityScore Card</a>-->
                    <!--  </mat-menu>-->

                      <span class="btn text-primary nomSol"
                        mat-button
                        [matMenuTriggerFor]="formsSelection"
                        title="Select the form">
                        {{ demande.solutionName }}
                      </span>

                      <mat-menu #formsSelection="matMenu">
                        <div *ngIf="demande.status !== 'Pending'" style="display: contents">
                          <a
                            class="btn text-primary"
                            (click)="gotoDesignForm(demande.id)"
                            >Design</a
                          >
                           <a
                            class="btn text-primary"
                            (click)="gotoLegalForm(demande.id)"
                            >Legal</a
                          >
                          <a
                            class="btn text-primary"
                            (click)="gotoArticle32Form(demande.id)"
                            >Article 32</a
                          >
                          <a
                            class="btn text-primary"
                            (click)="gotoArticle28Form(demande.id)"
                            >Annexes</a
                          >
                        </div>
                      </mat-menu>
                      <hr>
                      {{ demande.id }}
                    </td>
                    <td>
                      <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                      <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                      <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                    </td>
                    <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                    <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                    <td><p id="article28Sta{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>
                    <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                    <td>{{ demande.editorBrand }}
                    <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                    <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                      <div *ngIf="demande.id === sscdata.id">
                        <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                          <mat-menu #sscSelection="matMenu">
                            <ul style="padding-right: 15px">
                              <li>Company name: {{sscdata.name}}</li>
                              <li>Industry style: {{sscdata.industry}}</li>
                              <li>Domain name: {{sscdata.domain}}</li>
                              <li>Score: {{sscdata.score}}</li>
                              <li>Grade: {{sscdata.grade}} </li>
                              <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                              <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                              <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                            </ul>
                            <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                          </mat-menu>
                      </div>
                    </div>
                    </td>
                    <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                    <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                      {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                    </td>
                    <!-- Il y a eu une modif des forms -->
                    <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                    <td>{{ demande.businessUnitOfSolution }}</td>
                    <!--Hidden par défaut et affichable au clic du bouton-->
                    <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate ? (demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd'): '' }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate ? (demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd'): '' }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                    <!-- END Hidden par défaut et affichable au clic du bouton-->
                    <td style="cursor:pointer">
                      <span class="material-icons"
                      mat-button
                      [matMenuTriggerFor]="options"
                      title="Actions...">
                      more_horiz
                    </span>

                    <mat-menu #options="matMenu">
                      <div>
                        <a
                          class="btn text-primary"
                          (click)="modifierDemande(demande.id,'SaaS')"
                          title="Edit the request/solution"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-warning"
                          (click)="creationCompteVendor(demande.id,'SaaS')"
                          title="Access rights for the solution/request"
                          ><em class="material-icons btnShare"> share </em></a
                        >
                        <a
                          class="btn text-danger"
                          (click)="abortSolution(demande.id,'SaaS')"
                          title="Abort the request/solution"
                          ><em class="material-icons btnDelete"> delete </em></a
                        >
                        <a
                          class="btn text-success"
                          (click)="tableauEvals(demande.id,'SaaS')"
                          title="Assessments status for request/solution forms"
                          ><em class="material-icons btnSeeEval"> event_available </em></a
                        >
                        <a
                          class="btn text-primary"
                          (click)="logs(demande.id,'SaaS')"
                          title="Logs of forms from the request/solution"
                          ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                        >
                        <a
                          class="btn text-info"
                          (click)="fetchRefreshSecurityScoreCard(demande.id, demande.linkCom, demande.solutionName)"
                          title="Refresh/Get the Security Score Card for the company"
                          ><em class="material-icons btnSSC"> update </em></a
                        >
                      </div>
                    </mat-menu>
                  </td>
                </tr>
              </tbody>
            </table>


            <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
              <span class="material-icons">zoom_in</span>
            </em>

            <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
              <span class="material-icons">zoom_out</span>
            </em>

            </mat-card-content>
          </mat-card>
        </mat-tab>
          <!--END All demandesSaaS SaaS évaluées-->

          <!--All pending SaaS-->
          <mat-tab label="{{ 'allSaaSPendingRequests' | translate }}" style="justify-content: center;" aria-label="allSaaSPendingReq" onclick="hideColumnsTab1()">

            <mat-card>
              <mat-card-content>

              <button mat-raised-button
                  class="submit-button legend"
                  color="primary"
                  data-toggle="modal"
                  data-target="#modalLegend"
                  onclick="modalMovable()" >{{'Legend' | translate}}
                </button>

              <div style="text-align: end;">
                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
                mat-raised-button type="button" style="color: blue;"
                title="{{'Admin documentation' | translate}}" rel="noopener"
                ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                </a>

                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
                mat-raised-button type="button" style="color: blue;"
                title="{{'Admin documentation' | translate}}" rel="noopener"
                ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                </a>
              </div>
              <br />

                <!-- Modal légende -->
                <div
                  class="modal fade"
                  id="modalLegend"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  style="margin-top:60px"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
                      <div class="modal-header" >
                        {{ 'Legend and color code' | translate }}
                        <button class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width:470px;">
                        <div class="container" style="display:inline; text-align:left">
                          <p translate>Chaque formulaire est identifié par un statut et une couleur:</p>
                          <ul>
                            <li><strong style="color: grey">Empty</strong>: {{'Empty status' | translate}}</li>
                            <li><strong style="color: grey">New</strong>: {{'New status' | translate}}</li>
                            <li><strong style="color: #54bbba">Vendor is responding</strong>: {{'Vendor is responding status' | translate}}</li>
                            <li><strong style="color: purple">Completed</strong>: {{'Completed status' | translate}}</li>
                            <li><strong style="color: blue">DLA is reviewing</strong>: {{'DLA is reviewing status' | translate}}</li>
                            <li><strong style="color: green">Recommended</strong>: {{'Recommended status' | translate}}</li>
                            <li><strong style="color: orange">Reservations</strong>: {{'Recommended with reservations status' | translate}}</li>
                            <li><strong style="color: red">Not recommended</strong>: {{'Not recommended status' | translate}}</li>
                            <li><strong style="color: black">Need help</strong>: {{'Need help status' | translate}}</li>
                            <li><strong style="color: red">Canceled</strong>: {{'Canceled status' | translate}}</li>
                            <li><strong style="color: red">Pending</strong>: {{'Pending status' | translate}}</li>
                          </ul>

                          <hr>
                          <p><span class="material-icons">zoom_in</span>: {{'more icon' | translate}}</p>
                          <p><span class="material-icons">zoom_out</span>: {{'less icon' | translate}}</p>
                          <p>{{'Différentes actions sont possibles sous' | translate}} <span class="material-icons">more_horiz</span> :</p>
                          <p><em class="material-icons text-primary btnEdit"> edit </em>: {{'edit button' | translate}}</p>
                          <p><em class="material-icons text-primary btnVerify"> verified </em>: {{'verify button' | translate}}</p>
                        </div>
                      </div>
                      <!-- END Body -->
                    </div>
                  </div>
                </div>
                <!-- END MODALE -->

                <table data-order='[[ 8, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All pending SaaS solutions">
                  <thead>
                    <tr>
                    <th scope="col" title="RFP name">
                      {{ 'Nom RFP' | translate
                      }}
                    </th>
                    <th scope="col" title="Solution name">
                      {{ 'Nom solution' | translate
                      }}
                    </th>
                    <th scope="col" title="Global status of request"><!--statut global-->
                      {{ 'DLA recommendation' | translate
                      }}
                    </th>
                    <th scope="col" title="Status of Design Form">
                      {{ 'Design' | translate
                      }}
                    </th>
                    <th scope="col" title="Status of Legal Form">
                      {{ 'Legal' | translate
                      }}
                    </th>
                    <th scope="col" title="Status of Annexes Form">
                      {{ 'Annexes' | translate
                      }}
                    </th>
                    <th scope="col" title="Status of Article 32 Form">
                      {{ 'Art 32' | translate
                      }}
                    </th>
                    <th scope="col" title="Vendor brand name and SecurityScorecard">
                      {{ 'Nom vendor et SSC' | translate
                      }}
                    </th>
                    <th scope="col" title="Request date of request">
                      {{ 'Date de demande' | translate
                      }}
                    </th>
                    <th scope="col" title="Last update on forms">
                      {{ 'lastUpdate' | translate
                      }}
                    </th>
                    <th scope="col" title="Business Unit of request">
                      {{ 'Business Unit' | translate
                      }}
                    </th>
                    <!--Hidden par défaut et affichable au clic du bouton-->
                    <th scope="col" title="Expected completion date of forms" style="display:none" class="moreTab1">
                      {{ 'Date attendue completion' | translate
                      }}
                    </th>
                    <th scope="col" title="Expected evaluation date of forms" style="display:none" class="moreTab1">
                      {{ 'Date attendue eval' | translate
                      }}
                    </th>
                    <th scope="col" title="Current solution owner of solution" style="display:none" class="moreTab1">
                      {{ 'Proriétaire actuel' | translate
                      }}
                    </th>
                    <th scope="col" title="Type of solution" style="display:none" class="moreTab1">
                      {{ 'Type' | translate
                      }}
                    </th>
                    <th title="Business capability" style="display:none" class="moreTab1">
                      {{ 'Domain(s)' | translate
                      }}
                    </th>
                    <!--END Hidden par défaut et affichable au clic du bouton-->
                    <th scope="col" title="Actions available">
                      {{ 'Actions' | translate
                      }}
                    </th>
                 </tr>
                </thead>
                <tbody>
                  <tr
                  *ngFor="
                    let demande of demandesSaaSPending;
                    let i = index
                    "
                    style="cursor: default"
                  >
                    <td>{{ demande.rfpName }} <a
                        class="modale_elem"
                        data-toggle="modal"
                        data-target="#modalOverviewSolution"
                        title="{{'recap demande/formulaire' | translate}}"
                        (click)="openDialog(demande.id)"
                        onclick="modalMovable()"
                        ><em class="fa fa-info-circle" aria-hidden="true"></em>
                      </a></td>
                    <!-- formulaires-->
                    <td style="cursor:pointer">
                      <span class="btn text-primary nomSol"
                        mat-button
                        [matMenuTriggerFor]="formsSelection"
                        title="Select the form">
                          {{ demande.solutionName }}
                      </span>

                      <mat-menu #formsSelection="matMenu">
                        <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()" style="display: contents">
                          <a
                            class="btn text-primary"
                            >Design</a
                          >
                          <a
                            class="btn text-primary"
                              >Legal</a
                          >
                          <a
                            class="btn text-primary"
                            >Article 32</a
                          >
                          <a
                            class="btn text-primary"
                            >Annexes</a
                          >
                          <div class="overlay" style="visibility:hidden">
                            <p style="color:red" translate>SolutionPendingAdmin</p>
                          </div>
                        </div>
                      </mat-menu>
                      <hr>
                      {{ demande.id }}
                      </td>
                      <td>
                        <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                        <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                        <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                      </td>
                      <td><p id="designSta{{i}}" class="statusStyle">{{ demande.designStatus }}</p></td>
                      <td><p id="legalSta{{i}}" class="statusStyle">{{ demande.legalStatus }}</p></td>
                      <td><p id="article28Sta{{i}}" class="statusStyle">{{ demande.article28Status }}</p></td>
                      <td><p id="article32Sta{{i}}" class="statusStyle">{{ demande.article32Status }}</p></td>
                      <td>{{ demande.editorBrand }}
                      <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                      <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                        <div *ngIf="demande.id === sscdata.id">
                          <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                            <mat-menu #sscSelection="matMenu">
                              <ul style="padding-right: 15px">
                                <li>Company name: {{sscdata.name}}</li>
                                <li>Industry style: {{sscdata.industry}}</li>
                                <li>Domain name: {{sscdata.domain}}</li>
                                <li>Score: {{sscdata.score}}</li>
                                <li>Grade: {{sscdata.grade}} </li>
                                <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                                <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                                <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                              </ul>
                              <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                            </mat-menu>
                        </div>
                      </div>
                      </td>
                      <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                      <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                        {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                      </td>
                      <!-- Il y a eu une modif des forms -->
                      <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                      <td>{{ demande.businessUnitOfSolution }}</td>
                      <!--Hidden par défaut et affichable au clic du bouton-->
                      <td style="display:none" class="moreTab1">{{ demande.expectedCompletionDate ? (demande.expectedCompletionDate.toDate() | date: 'yyyy/MM/dd'): '' }}</td>
                      <td style="display:none" class="moreTab1">{{ demande.expectedEvaluationDate? (demande.expectedEvaluationDate.toDate() | date: 'yyyy/MM/dd'): '' }}</td>
                      <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                      <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                      <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                      <!-- END Hidden par défaut et affichable au clic du bouton-->
                      <td style="cursor:pointer">
                        <span class="material-icons"
                          mat-button
                          [matMenuTriggerFor]="options"
                          title="Actions...">
                            more_horiz
                        </span>

                      <mat-menu #options="matMenu">
                        <a
                          class="btn text-primary"
                          (click)="modifierDemande(demande.id,'SaaS')"
                          title="Edit the request/solution"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-primary"
                          title="{{'Review the request/solution for validation' | translate}}"
                          (click)="goValidateSolution(demande.id,'SaaS')"
                          ><em class="material-icons btnVerify"> verified </em></a
                        >
                      </mat-menu>
                      </td>
                    </tr>
                  </tbody>
                </table>


                <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                  <span class="material-icons">zoom_in</span>
                </em>

                <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                  <span class="material-icons">zoom_out</span>
                </em>

              </mat-card-content>
            </mat-card>
          </mat-tab>
          <!--END All pending SaaS-->

        </mat-tab-group>
        <!--END Onglets dashboard all demandesSaaS SaaS/all demandesSaaS evaluated/all demandesSaaS pending-->

      </mat-card-content>
    </mat-card>
  </mat-tab>
  <!--END SaaS-->

  <!--IaaS-->
  <mat-tab label="IaaS" style="justify-content: center;" aria-label="IaaStab">
    <mat-card>
      <mat-card-content>

        <!--Onglets dashboard all demandesIaaS IaaS/all demandesIaaS evaluated/all demandesIaaS pending-->
        <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" mat-align-tabs="center">
        <!--END Onglets dashboard all demandesIaaS IaaS/all demandesIaaS evaluated/all demandesIaaS pending-->

          <!--All demandesIaaS IaaS-->
          <mat-tab label="{{'allIaaSRequests' | translate}}" style="justify-content: center;"  aria-label="allIaaSReq">

            <mat-card>
              <mat-card-content>

                <button mat-raised-button
                    class="submit-button legend"
                    color="primary"
                    data-toggle="modal"
                    data-target="#modalLegend"
                    onclick="modalMovable()" >{{'Legend' | translate}}
                  </button>

                <div style="text-align: end;">
                  <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
                  mat-raised-button type="button" style="color: blue;"
                  title="{{'Admin documentation' | translate}}" rel="noopener"
                  ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                  </a>

                  <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
                  mat-raised-button type="button" style="color: blue;"
                  title="{{'Admin documentation' | translate}}" rel="noopener"
                  ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                  </a>
                </div>
                <br />

                <!-- Modal légende -->
                <div
                  class="modal fade"
                  id="modalLegend"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  style="margin-top:60px"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
                      <div class="modal-header" >
                        {{ 'Legend and color code' | translate }}
                        <button class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width:470px;">
                        <div class="container" style="display:inline; text-align:left">
                          <p translate>Chaque formulaire est identifié par un statut et une couleur:</p>
                          <ul>
                            <li><strong style="color: grey">Empty</strong>: {{'Empty status' | translate}}</li>
                            <li><strong style="color: grey">New</strong>: {{'New status' | translate}}</li>
                            <li><strong style="color: #54bbba">Vendor is responding</strong>: {{'Vendor is responding status' | translate}}</li>
                            <li><strong style="color: purple">Completed</strong>: {{'Completed status' | translate}}</li>
                            <li><strong style="color: blue">DLA is reviewing</strong>: {{'DLA is reviewing status' | translate}}</li>
                            <li><strong style="color: green">Recommended</strong>: {{'Recommended status' | translate}}</li>
                            <li><strong style="color: orange">Reservations</strong>: {{'Recommended with reservations status' | translate}}</li>
                            <li><strong style="color: red">Not recommended</strong>: {{'Not recommended status' | translate}}</li>
                            <li><strong style="color: black">Need help</strong>: {{'Need help status' | translate}}</li>
                            <li><strong style="color: red">Canceled</strong>: {{'Canceled status' | translate}}</li>
                            <li><strong style="color: red">Pending</strong>: {{'Pending status' | translate}}</li>
                          </ul>

                          <hr>
                          <p><span class="material-icons">zoom_in</span>: {{'more icon' | translate}}</p>
                          <p><span class="material-icons">zoom_out</span>: {{'less icon' | translate}}</p>
                          <p>{{'Différentes actions sont possibles sous' | translate}} <span class="material-icons">more_horiz</span> :</p>
                          <p><em class="material-icons text-primary btnEdit"> edit </em>: {{'edit button' | translate}}</p>
                          <p><em class="material-icons text-warning btnShare"> share </em>: {{'share button' | translate}}</p>
                          <p><em class="material-icons text-danger btnDelete"> delete </em>: {{'delete button' | translate}}</p>
                          <p><em class="material-icons text-success btnDelete"> delete </em>: {{'restore button' | translate}}</p>
                          <p><em class="material-icons text-success btnSeeEval"> event_available </em>: {{'seeEval button' | translate}}</p>
                          <p><em class="material-icons text-primary btnLogs"> format_list_bulleted </em>: {{'logs button' | translate}}</p>
                          <p><em class="material-icons text-info btnSSC"> update </em>: {{'secuscorecard button' | translate}}</p>
                        </div>
                      </div>
                      <!-- END Body -->
                    </div>
                  </div>
                </div>
                <!-- END MODALE -->

              <table data-order='[[ 4, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All IaaS solutions">
                <thead>
                <tr>
                  <th title="Solution name">
                    {{ 'Nom solution' | translate
                    }}
                  </th>
                  <th title="Global status of request"><!--statut global-->
                    {{ 'DLA recommendation' | translate
                    }}
                  </th>
                  <th title="Status of IaaS form">
                    {{ 'IaaS' | translate
                    }}
                  </th>
                  <th title="Vendor brand name and SecurityScorecard">
                    {{ 'Nom vendor et SSC' | translate
                    }}
                  </th>
                  <th title="Request date of request">
                    {{ 'Date de demande' | translate
                    }}
                  </th>
                  <th title="Last update on forms">
                    {{ 'lastUpdate' | translate
                    }}
                  </th>
                  <th title="Business unit">
                    {{ 'bu' | translate
                    }}
                  </th>
                  <!--Hidden par défaut et affichable au clic du bouton-->
                  <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                    {{ 'Proriétaire actuel' | translate
                     }}
                  </th>
                  <th title="Type of solution" style="display:none" class="moreTab1">
                    {{ 'Type' | translate
                     }}
                  </th>
                  <th title="Business capability" style="display:none" class="moreTab1">
                    {{ 'Domain(s)' | translate
                    }}
                  </th>
                  <!--END Hidden par défaut et affichable au clic du bouton-->
                  <th title="Actions available">
                    {{ 'Actions' | translate
                    }}
                  </th>
                </tr>
                </thead>
                <tbody>
                  <!-- Recherche par nom de solution -->
                  <tr
                    *ngFor="
                      let demande of demandesIaaS;
                      let i = index
                    "
                    style="cursor: default"
                  >
                    <!-- formulaire-->
                    <td style="cursor:pointer">
                      <span class="btn text-primary nomSol"
                        mat-button
                        [matMenuTriggerFor]="formsSelection"
                        title="Select the form">
                        {{ demande.solutionName }}

                      </span>
                      <a
                        class="modale_elem"
                        data-toggle="modal"
                        data-target="#modalOverviewSolution"
                        title="{{'recap demande/formulaire' | translate}}"
                        (click)="openDialog(demande.id)"
                        onclick="modalMovable()"
                        ><em class="fa fa-info-circle" aria-hidden="true"></em>
                      </a>
                      <mat-menu #formsSelection="matMenu">
                        <div *ngIf="demande.status !== 'Pending'" style="display: contents">
                          <a
                            class="btn text-primary"
                            (click)="gotoIaaSForm(demande.id)"
                            >IaaS form</a
                          >
                        </div>
                      </mat-menu>
                      <hr>
                      {{ demande.id }}
                    </td>
                    <td>
                      <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                      <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                      <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                    </td>
                    <td><p id="iaasSta{{i}}" class="statusStyle">{{ demande.iaasStatus }}</p></td>
                    <td>{{ demande.editorBrand }}
                    <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                    <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                      <div *ngIf="demande.id === sscdata.id">
                        <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                          <mat-menu #sscSelection="matMenu">
                            <ul style="padding-right: 15px">
                              <li>Company name: {{sscdata.name}}</li>
                              <li>Industry style: {{sscdata.industry}}</li>
                              <li>Domain name: {{sscdata.domain}}</li>
                              <li>Score: {{sscdata.score}}</li>
                              <li>Grade: {{sscdata.grade}} </li>
                              <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                              <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                              <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                            </ul>
                            <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                          </mat-menu>
                      </div>
                    </div>
                    </td>
                    <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                    <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                      {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                    </td>
                    <!-- Il y a eu une modif des forms -->
                    <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                    <td>{{ demande.businessUnitOfSolution }}</td>
                    <!--Hidden par défaut et affichable au clic du bouton-->
                    <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                    <!-- END Hidden par défaut et affichable au clic du bouton-->
                    <td style="cursor:pointer">
                      <span class="material-icons"
                      mat-button
                      [matMenuTriggerFor]="options"
                      title="Actions...">
                      more_horiz
                    </span>

                    <mat-menu #options="matMenu">
                      <div *ngIf="demande.status !== 'Canceled'">
                        <a
                          class="btn text-primary"
                          (click)="modifierDemande(demande.id,'IaaS')"
                          title="Edit the request/solution"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-warning"
                          (click)="creationCompteVendor(demande.id,'IaaS')"
                          title="Access rights for the solution/request"
                          ><em class="material-icons btnShare"> share </em></a
                        >
                        <a
                          class="btn text-danger"
                          (click)="abortSolution(demande.id,'IaaS')"
                          title="Abort the request/solution"
                          ><em class="material-icons btnDelete"> delete </em></a
                        >
                        <a
                          class="btn text-success"
                          (click)="tableauEvals(demande.id,'IaaS')"
                          title="Assessments status for request/solution forms"
                          ><em class="material-icons btnSeeEval"> event_available </em></a
                        >
                        <a
                          class="btn text-primary"
                          (click)="logs(demande.id,'IaaS')"
                          title="Logs of forms from the request/solution"
                          ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                        >
                        <a
                          class="btn text-info"
                          (click)="fetchRefreshSecurityScoreCard(demande.id, demande.linkCom, demande.solutionName)"
                          title="Refresh/Get the Security Score Card for the company"
                          ><em class="material-icons btnSSC"> update </em></a
                        >
                      </div>

                      <div *ngIf="demande.status === 'Canceled'">
                        <a
                          class="btn text-primary"
                          (click)="modifierDemande(demande.id,'IaaS')"
                          title="Edit the request/solution"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-warning"
                          (click)="creationCompteVendor(demande.id,'IaaS')"
                          title="Access rights for the solution/request"
                          ><em class="material-icons btnShare"> share </em></a
                        >
                        <a
                          class="btn text-success"
                          (click)="rollbackCancel(demande.id)"
                          title="Restore the request/solution"
                          ><em class="material-icons btnDelete"> delete </em></a
                        >
                        <a
                          class="btn text-success"
                          (click)="tableauEvals(demande.id,'IaaS')"
                          title="Assessments status for request/solution forms"
                          ><em class="material-icons btnSeeEval"> event_available </em></a
                        >
                        <a
                          class="btn text-primary"
                          (click)="logs(demande.id,'IaaS')"
                          title="Logs of forms from the request/solution"
                          ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                        >
                        <a
                          class="btn text-info"
                          (click)="fetchRefreshSecurityScoreCard(demande.id, demande.linkCom, demande.solutionName)"
                          title="Refresh/Get the Security Score Card for the company"
                          ><em class="material-icons btnSSC"> update </em></a
                        >
                      </div>
                    </mat-menu>
                    </td>
                  </tr>
                </tbody>
              </table>

              <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                <span class="material-icons">zoom_in</span>
              </em>

              <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                <span class="material-icons">zoom_out</span>
              </em>

              <div *ngIf="!demandesIaaS || demandesIaaS.length === 0" style="text-align:center">
                <strong style="color:red" translate>No IaaS request</strong>
              </div>

              </mat-card-content>
            </mat-card>
          </mat-tab>
          <!--END All demandesIaaS IaaS-->

          <!--All demandesIaaS IaaS évaluées-->
          <mat-tab label="{{'allIaaSEvaluatedRequests' | translate}}" style="justify-content: center;"  aria-label="allIaaSEvalReq">

            <mat-card>
              <mat-card-content>

                <button mat-raised-button
                    class="submit-button legend"
                    color="primary"
                    data-toggle="modal"
                    data-target="#modalLegend"
                    onclick="modalMovable()" >{{'Legend' | translate}}
                  </button>

                <div style="text-align: end;">
                  <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
                  mat-raised-button type="button" style="color: blue;"
                  title="{{'Admin documentation' | translate}}" rel="noopener"
                  ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                  </a>

                  <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
                  mat-raised-button type="button" style="color: blue;"
                  title="{{'Admin documentation' | translate}}" rel="noopener"
                  ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                  </a>
                </div>
                <br />

                <!-- Modal légende -->
                <div
                  class="modal fade"
                  id="modalLegend"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  style="margin-top:60px"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
                      <div class="modal-header" >
                        {{ 'Legend and color code' | translate }}
                        <button class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width:470px;">
                        <div class="container" style="display:inline; text-align:left">
                          <p translate>Chaque formulaire est identifié par un statut et une couleur:</p>
                          <ul>
                            <li><strong style="color: grey">Empty</strong>: {{'Empty status' | translate}}</li>
                            <li><strong style="color: grey">New</strong>: {{'New status' | translate}}</li>
                            <li><strong style="color: #54bbba">Vendor is responding</strong>: {{'Vendor is responding status' | translate}}</li>
                            <li><strong style="color: purple">Completed</strong>: {{'Completed status' | translate}}</li>
                            <li><strong style="color: blue">DLA is reviewing</strong>: {{'DLA is reviewing status' | translate}}</li>
                            <li><strong style="color: green">Recommended</strong>: {{'Recommended status' | translate}}</li>
                            <li><strong style="color: orange">Reservations</strong>: {{'Recommended with reservations status' | translate}}</li>
                            <li><strong style="color: red">Not recommended</strong>: {{'Not recommended status' | translate}}</li>
                            <li><strong style="color: black">Need help</strong>: {{'Need help status' | translate}}</li>
                            <li><strong style="color: red">Canceled</strong>: {{'Canceled status' | translate}}</li>
                            <li><strong style="color: red">Pending</strong>: {{'Pending status' | translate}}</li>
                          </ul>

                          <hr>
                          <p><span class="material-icons">zoom_in</span>: {{'more icon' | translate}}</p>
                          <p><span class="material-icons">zoom_out</span>: {{'less icon' | translate}}</p>
                          <p>{{'Différentes actions sont possibles sous' | translate}} <span class="material-icons">more_horiz</span> :</p>
                          <p><em class="material-icons text-primary btnEdit"> edit </em>: {{'edit button' | translate}}</p>
                          <p><em class="material-icons text-warning btnShare"> share </em>: {{'share button' | translate}}</p>
                          <p><em class="material-icons text-danger btnDelete"> delete </em>: {{'delete button' | translate}}</p>
                          <p><em class="material-icons text-success btnDelete"> delete </em>: {{'restore button' | translate}}</p>
                          <p><em class="material-icons text-success btnSeeEval"> event_available </em>: {{'seeEval button' | translate}}</p>
                          <p><em class="material-icons text-primary btnLogs"> format_list_bulleted </em>: {{'logs button' | translate}}</p>
                          <p><em class="material-icons text-info btnSSC"> update </em>: {{'secuscorecard button' | translate}}</p>
                        </div>
                      </div>
                      <!-- END Body -->
                    </div>
                  </div>
                </div>
                <!-- END MODALE -->

              <table data-order='[[ 4, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All evaluated IaaS solutions">
                <thead>
                <tr>
                  <th title="Solution name">
                    {{ 'Nom solution' | translate
                    }}
                  </th>
                  <th title="Global status of request"><!--statut global-->
                    {{ 'DLA recommendation' | translate
                    }}
                  </th>
                  <th title="Status of IaaS form">
                    {{ 'IaaS' | translate
                    }}
                  </th>
                  <th title="Vendor brand name and SecurityScorecard">
                    {{ 'Nom vendor et SSC' | translate
                    }}
                  </th>
                  <th title="Request date of request">
                    {{ 'Date de demande' | translate
                    }}
                  </th>
                  <th title="Last update on forms">
                    {{ 'lastUpdate' | translate
                    }}
                  </th>
                  <th title="Business unit">
                    {{ 'bu' | translate
                    }}
                  </th>
                  <!--Hidden par défaut et affichable au clic du bouton-->
                  <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                    {{ 'Proriétaire actuel' | translate
                     }}
                  </th>
                  <th title="Type of solution" style="display:none" class="moreTab1">
                    {{ 'Type' | translate
                     }}
                  </th>
                  <th title="Business capability" style="display:none" class="moreTab1">
                    {{ 'Domain(s)' | translate
                    }}
                  </th>
                  <!--END Hidden par défaut et affichable au clic du bouton-->
                  <th title="Actions available">
                    {{ 'Actions' | translate
                    }}
                  </th>
                </tr>
                </thead>
                <tbody>
                  <!-- Recherche par nom de solution -->
                  <tr
                    *ngFor="
                      let demande of demandesIaaSEvaluees;
                      let i = index
                    "
                    style="cursor: default"
                  >
                    <!-- formulaire-->
                    <td style="cursor:pointer">
                      <span class="btn text-primary nomSol"
                        mat-button
                        [matMenuTriggerFor]="formsSelection"
                        title="Select the form">
                        {{ demande.solutionName }}
                        <a
                        class="modale_elem"
                        data-toggle="modal"
                        data-target="#modalOverviewSolution"
                        title="{{'recap demande/formulaire' | translate}}"
                        (click)="openDialog(demande.id)"
                        onclick="modalMovable()"
                        ><em class="fa fa-info-circle" aria-hidden="true"></em>
                      </a>
                      </span>

                      <mat-menu #formsSelection="matMenu">
                        <div *ngIf="demande.status !== 'Pending'" style="display: contents">
                          <a
                            class="btn text-primary"
                            (click)="gotoIaaSForm(demande.id)"
                            >IaaS form</a
                          >
                        </div>
                      </mat-menu>
                      <hr>
                      {{ demande.id }}
                    </td>
                    <td>
                      <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                      <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                      <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                    </td>
                    <td><p id="iaasSta{{i}}" class="statusStyle">{{ demande.iaasStatus }}</p></td>
                    <td>{{ demande.editorBrand }}
                    <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                    <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                      <div *ngIf="demande.id === sscdata.id">
                        <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                          <mat-menu #sscSelection="matMenu">
                            <ul style="padding-right: 15px">
                              <li>Company name: {{sscdata.name}}</li>
                              <li>Industry style: {{sscdata.industry}}</li>
                              <li>Domain name: {{sscdata.domain}}</li>
                              <li>Score: {{sscdata.score}}</li>
                              <li>Grade: {{sscdata.grade}} </li>
                              <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                              <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                              <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                            </ul>
                            <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                          </mat-menu>
                      </div>
                    </div>
                    </td>
                    <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                    <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                      {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                    </td>
                    <!-- Il y a eu une modif des forms -->
                    <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                    <td>{{ demande.businessUnitOfSolution }}</td>
                    <!--Hidden par défaut et affichable au clic du bouton-->
                    <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                    <!-- END Hidden par défaut et affichable au clic du bouton-->
                    <td style="cursor:pointer">
                      <span class="material-icons"
                      mat-button
                      [matMenuTriggerFor]="options"
                      title="Actions...">
                      more_horiz
                    </span>

                    <mat-menu #options="matMenu">
                      <div>
                        <a
                          class="btn text-primary"
                          (click)="modifierDemande(demande.id,'IaaS')"
                          title="Edit the request/solution"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-warning"
                          (click)="creationCompteVendor(demande.id,'IaaS')"
                          title="Access rights for the solution/request"
                          ><em class="material-icons btnShare"> share </em></a
                        >
                        <a
                          class="btn text-danger"
                          (click)="abortSolution(demande.id,'IaaS')"
                          title="Abort the request/solution"
                          ><em class="material-icons btnDelete"> delete </em></a
                        >
                        <a
                          class="btn text-success"
                          (click)="tableauEvals(demande.id,'IaaS')"
                          title="Assessments status for request/solution forms"
                          ><em class="material-icons btnSeeEval"> event_available </em></a
                        >
                        <a
                          class="btn text-primary"
                          (click)="logs(demande.id,'IaaS')"
                          title="Logs of forms from the request/solution"
                          ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                        >
                        <a
                          class="btn text-info"
                          (click)="fetchRefreshSecurityScoreCard(demande.id, demande.linkCom, demande.solutionName)"
                          title="Refresh/Get the Security Score Card for the company"
                          ><em class="material-icons btnSSC"> update </em></a
                        >
                      </div>
                    </mat-menu>
                    </td>
                  </tr>
                </tbody>
              </table>

              <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                <span class="material-icons">zoom_in</span>
              </em>

              <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                <span class="material-icons">zoom_out</span>
              </em>

              <div *ngIf="!demandesIaaSEvaluees || demandesIaaSEvaluees.length === 0" style="text-align:center">
                <strong style="color:red" translate>No IaaS request</strong>
              </div>

              </mat-card-content>
            </mat-card>
          </mat-tab>
          <!--END All demandesSaaS IaaS évaluées-->

          <!--All demandesIaaSPending IaaS pending-->
          <mat-tab label="{{'allIaaSPendingRequests' | translate}}" style="justify-content: center;"  aria-label="allIaaSPendingReq">

            <mat-card>
              <mat-card-content>

                <button mat-raised-button
                    class="submit-button legend"
                    color="primary"
                    data-toggle="modal"
                    data-target="#modalLegend"
                    onclick="modalMovable()" >{{'Legend' | translate}}
                  </button>

                <div style="text-align: end;">
                  <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
                  mat-raised-button type="button" style="color: blue;"
                  title="{{'Admin documentation' | translate}}" rel="noopener"
                  ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                  </a>

                  <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
                  mat-raised-button type="button" style="color: blue;"
                  title="{{'Admin documentation' | translate}}" rel="noopener"
                  ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                  </a>
                </div>
                <br />

                <!-- Modal légende -->
                <div
                  class="modal fade"
                  id="modalLegend"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  style="margin-top:60px"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
                      <div class="modal-header" >
                        {{ 'Legend and color code' | translate }}
                        <button class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width:470px;">
                        <div class="container" style="display:inline; text-align:left">
                          <p translate>Chaque formulaire est identifié par un statut et une couleur:</p>
                          <ul>
                            <li><strong style="color: grey">Empty</strong>: {{'Empty status' | translate}}</li>
                            <li><strong style="color: grey">New</strong>: {{'New status' | translate}}</li>
                            <li><strong style="color: #54bbba">Vendor is responding</strong>: {{'Vendor is responding status' | translate}}</li>
                            <li><strong style="color: purple">Completed</strong>: {{'Completed status' | translate}}</li>
                            <li><strong style="color: blue">DLA is reviewing</strong>: {{'DLA is reviewing status' | translate}}</li>
                            <li><strong style="color: green">Recommended</strong>: {{'Recommended status' | translate}}</li>
                            <li><strong style="color: orange">Reservations</strong>: {{'Recommended with reservations status' | translate}}</li>
                            <li><strong style="color: red">Not recommended</strong>: {{'Not recommended status' | translate}}</li>
                            <li><strong style="color: black">Need help</strong>: {{'Need help status' | translate}}</li>
                            <li><strong style="color: red">Canceled</strong>: {{'Canceled status' | translate}}</li>
                            <li><strong style="color: red">Pending</strong>: {{'Pending status' | translate}}</li>
                          </ul>

                          <hr>
                          <p><span class="material-icons">zoom_in</span>: {{'more icon' | translate}}</p>
                          <p><span class="material-icons">zoom_out</span>: {{'less icon' | translate}}</p>
                          <p>{{'Différentes actions sont possibles sous' | translate}} <span class="material-icons">more_horiz</span> :</p>
                          <p><em class="material-icons text-primary btnEdit"> edit </em>: {{'edit button' | translate}}</p>
                          <p><em class="material-icons text-primary btnVerify"> verified </em>: {{'verify button' | translate}}</p>
                        </div>
                      </div>
                      <!-- END Body -->
                    </div>
                  </div>
                </div>
                <!-- END MODALE -->


              <table data-order='[[ 4, "desc" ]]' class="tableUser table table-bordered" style="width:100%" aria-label="All evaluated IaaS solutions">
                <thead>
                <tr>
                  <th title="Solution name">
                    {{ 'Nom solution' | translate
                    }}
                  </th>
                  <th title="Global status of request"><!--statut global-->
                    {{ 'DLA recommendation' | translate
                    }}
                  </th>
                  <th title="Status of IaaS form">
                    {{ 'IaaS' | translate
                    }}
                  </th>
                  <th title="Vendor brand name and SecurityScorecard">
                    {{ 'Nom vendor et SSC' | translate
                    }}
                  </th>
                  <th title="Request date of request">
                    {{ 'Date de demande' | translate
                    }}
                  </th>
                  <th title="Last update on forms">
                    {{ 'lastUpdate' | translate
                    }}
                  </th>
                  <th title="Business unit">
                    {{ 'bu' | translate
                    }}
                  </th>
                  <!--Hidden par défaut et affichable au clic du bouton-->
                  <th title="Current solution owner of solution" style="display:none" class="moreTab1">
                    {{ 'Proriétaire actuel' | translate
                     }}
                  </th>
                  <th title="Type of solution" style="display:none" class="moreTab1">
                    {{ 'Type' | translate
                     }}
                  </th>
                  <th title="Business capability" style="display:none" class="moreTab1">
                    {{ 'Domain(s)' | translate
                    }}
                  </th>
                  <!--END Hidden par défaut et affichable au clic du bouton-->
                  <th title="Actions available">
                    {{ 'Actions' | translate
                    }}
                  </th>
                </tr>
                </thead>
                <tbody>
                  <!-- Recherche par nom de solution -->
                  <tr
                    *ngFor="
                      let demande of demandesIaaSPending;
                      let i = index
                    "
                    style="cursor: default"
                  >
                    <!-- formulaire-->
                    <td style="cursor:pointer">
                      <span class="btn text-primary nomSol"
                        mat-button
                        [matMenuTriggerFor]="formsSelection"
                        title="Select the form">
                        {{ demande.solutionName }}
                      </span>

                      <mat-menu #formsSelection="matMenu">
                        <div *ngIf="demande.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()" style="display: contents">
                          <a
                            class="btn text-primary"
                            >IaaS form</a
                          >
                          <div class="overlay" style="visibility:hidden">
                            <p style="color:red" translate>SolutionPendingAdmin</p>
                          </div>
                        </div>
                      </mat-menu>
                      <hr>
                      {{ demande.id }}
                    </td>
                    <td>
                      <p id="globalSta{{i}}" class="statusStyle">{{ demande.status }}</p>
                      <p *ngIf="demande.fullyEvaluated===true && demande.finalEvaluationDate">{{ demande.finalEvaluationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                      <p *ngIf="demande.fullyEvaluated===false && demande.cancellationDate && demande.status==='Canceled'">{{ demande.cancellationDate.toDate() | date: 'yyyy/MM/dd' }}</p>
                    </td>
                    <td><p id="iaasSta{{i}}" class="statusStyle">{{ demande.iaasStatus }}</p></td>
                    <td>{{ demande.editorBrand }}
                    <!--Pour chaque compagny, on fetch les data depuis securityScoreCard collection sous firestore-->
                    <div *ngFor="let sscdata of securityScoreCardData; let i = index">
                      <div *ngIf="demande.id === sscdata.id">
                        <img [matMenuTriggerFor]="sscSelection" src='{{sscdata.grade_url}}' alt="Company SSC" width="30px" style="cursor: pointer">
                          <mat-menu #sscSelection="matMenu">
                            <ul style="padding-right: 15px">
                              <li>Company name: {{sscdata.name}}</li>
                              <li>Industry style: {{sscdata.industry}}</li>
                              <li>Domain name: {{sscdata.domain}}</li>
                              <li>Score: {{sscdata.score}}</li>
                              <li>Grade: {{sscdata.grade}} </li>
                              <li>Score over 30 days: {{sscdata.last30day_score_change}}</li>
                              <li><em>First add date: {{sscdata.firstAddDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                              <li><em>Last update date: {{sscdata.lastUpdateDate.toDate() | date: 'yyyy/MM/dd'}}</em></li>
                            </ul>
                            <a style="margin-left: 15px" target="_blank" rel="noopener" href="https://securityscorecard.com/security-rating/{{sscdata.domain}}?utm_medium=badge&utm_source={{sscdata.domain}}&utm_campaign=seal-of-trust&utm_content=https://dla.veolia.com/">Visit the Security Score Card</a>
                          </mat-menu>
                      </div>
                    </div>
                    </td>
                    <td>{{ demande.requestDate.toDate() | date: 'yyyy/MM/dd' }}</td>
                    <td *ngIf="demande.lastUpdate !== 'No forms completed yet'">
                      {{ demande.lastUpdate.toDate() | date: 'yyyy/MM/dd, h:mm a' }}
                    </td>
                    <!-- Il y a eu une modif des forms -->
                    <td *ngIf="demande.lastUpdate === 'No forms completed yet'">{{ demande.lastUpdate }}</td>
                    <td>{{ demande.businessUnitOfSolution }}</td>
                    <!--Hidden par défaut et affichable au clic du bouton-->
                    <td style="display:none" class="moreTab1">{{ demande.solutionOwner }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.type }}</td>
                    <td style="display:none" class="moreTab1">{{ demande.domainOfSolution }}</td>
                    <!-- END Hidden par défaut et affichable au clic du bouton-->
                    <td style="cursor:pointer">
                      <span class="material-icons"
                      mat-button
                      [matMenuTriggerFor]="options"
                      title="Actions...">
                      more_horiz
                    </span>

                    <mat-menu #options="matMenu">
                        <a
                          class="btn text-primary"
                          (click)="modifierDemande(demande.id,'IaaS')"
                          title="Edit the request/solution"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-primary"
                          title="{{'Review the request/solution for validation' | translate}}"
                          (click)="goValidateSolution(demande.id,'IaaS')"
                          ><em class="material-icons btnVerify"> verified </em></a
                        >
                      </mat-menu>
                    </td>
                  </tr>
                </tbody>
              </table>

              <em onclick="displayColumnsTab1()" id="btnDisplayColumnsTab1" style="cursor:pointer" title="Display more informations">{{'plus' | translate}}
                <span class="material-icons">zoom_in</span>
              </em>

              <em onclick="hideColumnsTab1()" id="btnHideColumnsTab1" style="display:none; cursor:pointer" title="Hide informations">{{'moins' | translate}}
                <span class="material-icons">zoom_out</span>
              </em>

              <div *ngIf="!demandesIaaSPending || demandesIaaSPending.length === 0" style="text-align:center">
                <strong style="color:red" translate>No IaaS request</strong>
              </div>

              </mat-card-content>
            </mat-card>
          </mat-tab>
          <!--END All demandesIaaSPending IaaS pending-->

        </mat-tab-group>
        <!--END Onglets dashboard all demandesIaaS IaaS/all demandesIaaS evaluated/all demandesIaaS pending-->

      </mat-card-content>
    </mat-card>
  </mat-tab>
  <!--END IaaS-->

  <!--PaaS-->
  <!--<mat-tab label="PaaS" style="justify-content: center;" aria-label="PaaStab">-->
  <!--  <mat-card>-->
  <!--    <mat-card-content>-->
  <!--    ////-->
  <!--    </mat-card-content>-->
  <!--  </mat-card>-->
  <!--</mat-tab>-->
  <!--END PaaS-->

  <!--Apps-->
  <!--<mat-tab label="Apps" style="justify-content: center;" aria-label="Appstab">-->
  <!--  <mat-card>-->
  <!--    <mat-card-content>-->
  <!--    ////-->
  <!--    </mat-card-content>-->
  <!--  </mat-card>-->
  <!--</mat-tab>-->
  <!--END Apps-->

  <!--Users-->
  <!--All Veolia Users-->
  <mat-tab label="{{ 'allUsersVeolia' | translate }}" style="justify-content: center;" aria-label="allVeoliaUsers">

    <div *ngIf="service.tabName == 'Utilisateurs Veolia' || service.tabName == 'Veolia\'s users'">
      <mat-card>
        <mat-card-content>
              <div class="miniLegend">
                <ul style="list-style: none;">
                  <li><em class="material-icons btnEdit text-primary"> edit </em>: <em translate>Modifier les droits utilisateur</em></li>
                </ul>
              </div>

          <div style="text-align: start;">
            <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
            mat-raised-button type="button" style="color: blue;"
            title="{{'Admin documentation' | translate}}" rel="noopener"
            ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
            </a>

            <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
            mat-raised-button type="button" style="color: blue;"
            title="{{'Admin documentation' | translate}}" rel="noopener"
            ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
            </a>
          </div>
          <br />

          <table class="tableUser table table-bordered" style="width:100%" aria-label="All Veolia's users">
            <thead>
              <tr>
              <th scope="col">
                {{ 'Email' | translate
                }}
              </th>
              <th scope="col">
                {{ 'Nom complet' | translate
                }}
              </th>
              <th scope="col">
                {{ 'Business Unit' | translate
                }}
              </th>
              <th scope="col">
                {{ 'Rôle principal' | translate
                }}
              </th>
              <th scope="col">{{ 'Droits' | translate }}</th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="
                  let userInfo of usersVeolia;
                  let i = index
                "
              >
                <td>{{ userInfo.email }}
                <hr>
                  {{ userInfo.id }}
                </td>
                <td>{{ userInfo.displayName }}</td>
                <td>{{ userInfo.businessUnit }}</td>
                <td>{{ userInfo.role }}</td>
                <td class="tdBtns">
                  <a
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalRoles"
                    (click)="getRolesForUser(userInfo.id);"
                    onclick="modalMovable()"
                    ><em class="material-icons btnEdit"> edit </em></a
                  >
                </td>
                <!-- Modal pour rôles appelée par btn sur la ligne du user -->
                <div
                  class="modal fade"
                  id="modalRoles"
                  tabindex="-1"
                  role="dialog"
                  aria-hidden="true"
                  style="margin-top:60px"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: max-content; height: 570px;overflow-y: auto;">
                      <div class="modal-header" id="mdlheaderVeolia">
                        <h4 style="color:black">{{ 'Gestion des rôles' | translate }}</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          style="margin-right: 2px;"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width:470px;" class="bodyModalRoles" id="mdlbodyVeolia">

                        <div class="container" style="display:inline" *ngIf="!userLoading">
                          <div class="">
                          <!-- Form veolia -->
                          <form
                            class="roles-form"
                            *ngIf="rolesFormVeolia"
                            [formGroup]="rolesFormVeolia"
                            (ngSubmit)="onSubmit(rolesFormVeolia.value)"
                          >
                            <p>
                              {{'For' | translate}} <strong>{{ email }}</strong>
                            </p>

                            <p>
                              {{'Uid' | translate}} <strong>{{ uidUser }}</strong>
                            </p>

                            <mat-form-field appearance="fill" class="mat">
                              <mat-label translate>Votre Business unit</mat-label>
                              <mat-spinner *ngIf="userLoading" diameter="20"></mat-spinner>
                              <select
                                *ngIf="!userLoading"
                                matNativeControl
                                formControlName="businessUnit"
                                class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && rolesFormVeolia.businessUnit.errors }"
                              >
                                <option selected translate>Veuillez choisir</option>
                                <option *ngFor="let businessUnit of listBU" translate [value]="businessUnit.nomBu">
                                  {{ businessUnit.nomBu }}
                                </option>
                              </select>
                              <!--<span-->
                              <!--  class="text-danger"-->
                              <!--  *ngIf="(rolesFormVeolia.businessUnit.touched || submitted) && rolesFormVeolia.businessUnit.errors?.required"-->
                              <!-- >-->
                              <!--  <em style="font-size: small;">Domain required</em>-->
                              <!--</span>-->
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="mat">
                              <mat-label>{{ 'Admin role ?' | translate }}</mat-label>
                              <select matNativeControl formControlName="admin">
                                <option value="true" translate>Oui</option>
                                <option value="false" translate>Non</option>
                              </select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="mat hidden">
                              <mat-label>{{ 'Vendor role ?' | translate }}</mat-label>
                              <select matNativeControl formControlName="vendor">
                                <option value="true" translate>Oui</option>
                                <option value="false" translate>Non</option>
                              </select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="mat">
                              <mat-label>{{ 'Evaluator role ?' | translate }}</mat-label>
                              <select matNativeControl formControlName="evaluator">
                                <option value="true" translate>Oui</option>
                                <option value="false" translate>Non</option>
                              </select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="mat">
                              <mat-label>{{ 'Solution owner role ?' | translate }}</mat-label>
                              <select matNativeControl formControlName="solutionOwner">
                                <option value="true" translate>Oui</option>
                                <option value="false" translate>Non</option>
                              </select>
                            </mat-form-field>

                            <mat-form-field appearance="fill" class="mat">
                              <mat-label>{{ 'Viewer role ?' | translate }}</mat-label>
                              <select matNativeControl formControlName="viewer">
                                <option value="true" translate>Oui</option>
                                <option value="false" translate>Non</option>
                              </select>
                            </mat-form-field>

                            <div class="row submit-button-container">
                              <div class="col-md-4">
                                <button
                                  mat-raised-button
                                  class="submit-button"
                                  color="primary"
                                  type="submit"
                                  style="background:bluE; margin-left: 170px;"
                                  onclick="closeModaleRoles();"
                                >
                                  {{ 'Save' | translate }}
                                </button>
                              </div>
                            </div>
                          </form>

                          </div>
                        </div>
                      </div>
                      <!-- END body -->
                    </div>
                  </div>
                </div>
                <!-- END MODALE -->
              </tr>
            </tbody>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>
  <!-- END Veolia users -->

  <!-- All vendor users -->
  <mat-tab label="{{ 'allUsersVendor' | translate }}" style="justify-content: center;" aria-label="allVendors">

    <div *ngIf="service.tabName == 'Utilisateurs externes' || service.tabName == 'External\'s users (vendors)'">

      <!--ALL USERS vendor activés-->
      <mat-card>
              <mat-card-content>

              <div class="miniLegend">
                <ul style="list-style: none;">
                  <li><em class="material-icons btnEdit text-primary"> edit </em>: <em translate>Modifier les droits utilisateur</em></li>
                  <li><em class="fas fa-times-circle text-danger"></em>: <em translate>Désactiver le compte</em></li>
                  <li><em class="fas fa-check-circle text-success"></em>: <em translate>Réactiver le compte</em></li>
                  <li><em class="material-icons btnEdit text-primary"> cached </em>: <em translate>Reset le pass</em></li>
                </ul>
              </div>

              <div style="text-align: start;">
                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"
                mat-raised-button type="button" style="color: blue;"
                title="{{'Admin documentation' | translate}}" rel="noopener"
                ><strong translate>Admin documentation (EN)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                </a>

                <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"
                mat-raised-button type="button" style="color: blue;"
                title="{{'Admin documentation' | translate}}" rel="noopener"
                ><strong translate>Admin documentation (FR)</strong> <em style="padding-right:4px" class="material-icons"> cloud_download </em>
                </a>
              </div>
              <br />

              <mat-card-title translate class="titleSection"
                  >allUsersVendorEnabled</mat-card-title
                >
                <br />

              <table class="tableUser table table-bordered" style="width:100%" aria-label="All vendor's users enabled">
                <thead>
                  <tr>
                  <th scope="col">
                    {{ 'Email' | translate
                    }}
                  </th>
                  <th scope="col">
                    {{ 'Nom' | translate
                    }}
                  </th>
                  <th scope="col">
                    {{ 'Prénom' | translate
                    }}
                  </th>
                  <th scope="col">
                    {{ 'Entreprise' | translate
                    }}
                  </th>
                  <th scope="col">
                    {{ 'Rôle principal' | translate
                    }}
                  </th>
                  <th scope="col">{{ 'Droits' | translate }}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    *ngFor="
                      let userInfo of usersVendorEnabled;
                      let i = index
                    "
                  >
                    <td>{{ userInfo.email }}
                    <hr>
                      {{ userInfo.id }}
                    </td>
                    <td>{{ userInfo.lastname }}</td>
                    <td>{{ userInfo.firstname }}</td>
                    <td>{{ userInfo.company }}</td>
                    <td>{{ userInfo.role }}</td>
                    <td class="tdBtns">
                      <a
                        class="btn text-primary"
                        data-toggle="modal"
                        data-target="#modalRoles"
                        title="Voir les rôles"
                        (click)="getRolesForUser(userInfo.id)"
                        ><em class="material-icons btnEdit"> edit </em></a
                      >
                      <a
                        class="btn text-danger"
                        title="Désactiver cet utilisateur"
                        (click)="disableAccount(userInfo.id, userInfo.email)"
                        ><em class="fas fa-times-circle"></em></a
                      >
                      <a
                        class="btn text-primary"
                        title="Reset user password"
                        (click)="sendResetPass(userInfo.email)"
                        ><em class="material-icons btnEdit">cached</em></a
                      >
                    </td>
                    <!-- Modal pour rôles appelée par btn sur la ligne du user -->
                    <div
                      class="modal fade"
                      id="modalRoles"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                      style="margin-top:60px"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content" style="width: max-content; height: 310px;overflow-y: auto;">
                          <div class="modal-header" id="mdlheaderVendor">
                            <h4 style="color:black">{{ 'Gestion des rôles' | translate }}</h4>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              style="margin-right: 2px;"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div class="modal-body" style="width:470px;" class="bodyModalRoles" id="mdlbodyVendor">

                            <div class="container" style="display:inline">
                              <div class="">
                                  <!-- Form vendor -->
                                <form
                                  class="roles-form"
                                  *ngIf="rolesFormVendor"
                                  [formGroup]="rolesFormVendor"
                                  (ngSubmit)="onSubmit(rolesFormVendor.value)"
                                >
                                  <p>
                                    {{'For' | translate}} <strong>{{ email }}</strong>
                                  </p>

                                  <p>
                                    {{'Uid' | translate}} <strong>{{ uidUser }}</strong>
                                  </p>

                                  <mat-form-field appearance="fill" class="mat hidden">
                                    <mat-label>{{ 'Admin role ?' | translate }}</mat-label>
                                    <select matNativeControl formControlName="admin">
                                      <option value="true">Oui</option>
                                      <option value="false">Non</option>
                                    </select>
                                  </mat-form-field>

                                  <mat-form-field appearance="fill" class="mat">
                                    <mat-label>{{ 'Vendor role ?' | translate }}</mat-label>
                                    <select matNativeControl formControlName="vendor">
                                      <option value="true" translate>Oui</option>
                                      <option value="false" translate>Non</option>
                                    </select>
                                  </mat-form-field>

                                  <mat-form-field appearance="fill" class="mat hidden">
                                    <mat-label>{{ 'Evaluator role ?' | translate }}</mat-label>
                                    <select matNativeControl formControlName="evaluator">
                                      <option value="true">Oui</option>
                                      <option value="false">Non</option>
                                    </select>
                                  </mat-form-field>

                                  <mat-form-field appearance="fill" class="mat hidden">
                                    <mat-label>{{ 'Bu leader role ?' | translate }}</mat-label>
                                    <select matNativeControl formControlName="buLeader">
                                      <option value="true">Oui</option>
                                      <option value="false">Non</option>
                                    </select>
                                  </mat-form-field>

                                  <mat-form-field appearance="fill" class="mat hidden">
                                    <mat-label>{{ 'Solution owner role ?' | translate }}</mat-label>
                                    <select matNativeControl formControlName="solutionOwner">
                                      <option value="true">Oui</option>
                                      <option value="false">Non</option>
                                    </select>
                                  </mat-form-field>

                                  <div class="row submit-button-container">
                                    <div class="col-md-4">
                                      <button
                                        mat-raised-button
                                        class="submit-button"
                                        color="primary"
                                        type="submit"
                                        style="background:bluE;margin-left: 170px;"
                                        onclick="closeModaleRoles();"
                                      >
                                        {{ 'Save' | translate }}
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                          <!-- END body -->
                        </div>
                      </div>
                    </div>
                    <!-- END MODALE -->
                  </tr>
                </tbody>
              </table>
            </mat-card-content>
        </mat-card>
      <!--END ALL USERS vendor activés-->

      <!--ALL USERS vendor desactivés-->
      <mat-card>
              <mat-card-content>
                <mat-card-title translate class="titleSection"
                      >allUsersVendorDisabled</mat-card-title
                    >
                <br />

                <table class="tableUser table table-bordered" style="width:100%" aria-label="All vendor's users disabled">
                  <thead>
                    <tr>
                    <th scope="col">
                      {{ 'Email' | translate
                      }}
                    </th>
                    <th scope="col">
                      {{ 'Nom' | translate
                      }}
                    </th>
                    <th scope="col">
                      {{ 'Prénom' | translate
                      }}
                    </th>
                    <th scope="col">
                      {{ 'Entreprise' | translate
                      }}
                    </th>
                    <th scope="col">
                      {{ 'Rôle principal' | translate
                      }}
                    </th>
                    <th scope="col">{{ 'Droits' | translate }}</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      *ngFor="
                        let userInfo of usersVendorDisabled;
                        let i = index
                      "
                    >
                      <td>{{ userInfo.email }}</td>
                      <td>{{ userInfo.lastname }}</td>
                      <td>{{ userInfo.firstname }}</td>
                      <td>{{ userInfo.company }}</td>
                      <td>{{ userInfo.role }}</td>
                      <td class="tdBtns">
                        <a
                          class="btn text-primary"
                          data-toggle="modal"
                          data-target="#modalRoles"
                          title="Voir les rôles"
                          (click)="getRolesForUser(userInfo.id)"
                          onclick="modalMovable()"
                          ><em class="material-icons btnEdit"> edit </em></a
                        >
                        <a
                          class="btn text-success"
                          title="Activer cet utilisateur"
                          (click)="enableAccount(userInfo.id, userInfo.email)"
                          ><em class="fas fa-check-circle"></em></a
                        >
                      </td>

                       <!--Modal pour rôles appelée par btn sur la ligne du user -->
                      <div
                        class="modal fade"
                        id="modalRoles"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        style="margin-top:60px"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content" style="width: max-content; height: 310px;overflow-y: auto;">
                            <div class="modal-header" id="mdlheaderVendor">
                              <h4 style="color:black">{{ 'Gestion des rôles' | translate }}</h4>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                style="margin-right: 2px;"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body" style="width:470px;" class="bodyModalRoles" id="mdlbodyVendor">
                              <div class="container" style="display:inline">
                                <div class=""><app-roles-modal></app-roles-modal></div>
                              </div>
                            </div>
                             <!--END body -->
                          </div>
                        </div>
                      </div>
                       <!--END MODALE -->
                    </tr>
                  </tbody>
                </table>
              </mat-card-content>
        </mat-card>
      <!--END ALL USERS vendor désactivés-->

    </div>
  </mat-tab>
  <!-- END All vendor users -->
  <!--END Users-->

</mat-tab-group>
<!--END Onglets haut: SaaS PaaS IaaS Apps Users-->
