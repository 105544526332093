import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../core/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { dictToURI } from '../../app/core/helpers';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private firestore: AngularFirestore, private toastr: ToastrService, private authService: AuthService, private httpClient: HttpClient) {}

  //SELECT ALL demandes du user veolia (dont le emailVeoliaAuthorized contient email du user veolia)
  getRequestsUserVeolia() {
    return this.firestore
      .collection('solutions', ref => ref.where('emailVeoliaAuthorized', 'array-contains', this.authService.currentUserEmail))
      .snapshotChanges();
  }
  
  //SELECT ALL demandes du user vendor (dont le emailVendorAuthorized contient email du user vendor)
  getRequestsUserVendor() {
    return this.firestore
      .collection('solutions', ref => ref.where('emailVendorAuthorized', 'array-contains', this.authService.currentUserEmail))
      .snapshotChanges();
  }

  getVendorConnected() {
    return this.firestore
      .collection('users', ref => ref.where('uid', '==', this.authService.currentUserId))
      .snapshotChanges();
  }
  
  //VENDOR
  sendOverviewContactFormVendor(lastname, firstname, email, subject, content, request){
    const query = dictToURI({
      email,
      lastnameVendor: lastname,
      firstnameVendor: firstname,
      subject: subject,
      content: content,
      solName: request
    });
    return this.httpClient.get('/api/contact_form_overview?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
   sendContactFormVendor(lastname, firstname, email, subject, content, request){
     const query = dictToURI({
       email: email,
       lastnameVendor: lastname,
       firstnameVendor: firstname,
       subject,
       content,
       solName: request
     });
     return this.httpClient.get('/api/contact_form?'+query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
  //VEOLIA
  sendOverviewContactFormVeolia(displayname, email, subject, content, request){
    const query = dictToURI({
      email,
      displayName: displayname,
      subject,
      content,
      solName: request
    });
    return this.httpClient.get('/api/contact_form_overview?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
   sendContactFormVeolia(displayname, email, subject, content, request){
     const query = dictToURI({
      email,
      displayName: displayname,
      subject,
      content,
      solName: request
     });
  
    return this.httpClient.get('/api/contact_form?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
 
  public handleErrorHttp(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }
  
}
