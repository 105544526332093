    <p *ngIf="formName !== 'Article 28 Form'" class="title">{{formName}}</p>
    <p *ngIf="formName === 'Article 28 Form'" class="title">Annexes</p>
    
    <div *ngIf="!reservationByChapterIsPresent || reservationByChapterIsPresent.length === 0; else elseBlock"><strong style="color:red" translate>No reservations</strong></div>
    
    <ng-template #elseBlock>
        <button mat-raised-button title="Click to download all reservations" (click)="generatePdf()" onclick="closeAllDetailsRes()"
          >Download PDF of all reservations<em class="material-icons" style="margin-right: 5px"> cloud_download </em></button
        >
        <br />
        <br />
    </ng-template>
    
    <!--Le bloc suivant ne s'affiche que s'il y a des datas-->
    <details *ngFor="let item of reservationByChapterIsPresent; index as i;" (click)="reservationByChapter(item)" onclick="closeDetailsRes()">
        
            <summary>{{ item | translate }}</summary>
            <br />
            
            <div id="allReservationsForPdf">
                <p *ngIf="formName !== 'Article 28 Form'" translate class="radio-title">Reservations list for {{solName}} : {{formName}}</p>
                <p *ngIf="formName === 'Article 28 Form'" translate class="radio-title">Reservations list for {{solName}} : Annexes</p>
                
             <div *ngFor="let res of reservationsList">
                
                <strong *ngIf="download === true" style="color: #5bc0de;">{{ res.reference | translate }}</strong> <br />
                
                <em> {{'Réserve émise le' | translate }}: {{ res.postedAt | date: 'longDate' }}</em> <br />
                <em> {{'Deadline imposée' | translate }}: {{ res.deadlineDate | date: 'longDate' }} </em> <br />
                <em> {{'By' | translate }}: {{ res.postedBy }} ({{res.userRole}})</em> <br />
                <em> {{'Description de la reserve' | translate }}: 
                    <strong style="color:#5bc0de" *ngIf="res.content">{{ res.content }}</strong>
                    <strong style="color:#5bc0de" *ngIf="!res.content" translate>(Not provided)</strong>
                </em> <br /><br />
                
                <em> {{'Fixed ?' | translate }}: <strong style="color:#5bc0de">{{ res.fixed }}</strong> </em> <br />
                
                <!--Si le vendor a fixé une date de correction-->
                <strong *ngIf="res.fixedDate && res.fixedDate !== null" class="fix">{{'Date de correction prévue' | translate }} => {{res.fixedDate | date}}</strong> <br />
                
                <!--Si le vendor a fixé la reserve-->
                <strong *ngIf="res.fixed === true">{{'Fixé le' | translate }} => {{res.fixedOn | date}}</strong> <br /><br />
                
             </div>
             
            </div>
              
    </details>
    

    