import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../core/auth.service';
import { I18nService } from '../core';

import { HomeService } from './home.service';
import { Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

//import { User } from '../core/user';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

//Formulaire de la page Home avec vérification des champs
export class HomeComponent implements OnInit {
  //user: User;
  
  //user: Observable<User>;
  public showOverlay = true;
  
  constructor(private router: Router, private toastr: ToastrService, public auth: AuthService, private i18nService: I18nService, private service: HomeService) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }
  

  ngOnInit() {

    this.currentLanguage
    //Savoir si le user connecté à les droits
    //this.auth.user.subscribe(user => (this.user = user));

    //Quand le user est connecté on redirige direct sur homeUser c'est dans le service
    /*this.service.userConnected;
    setTimeout(() => { //delais de 5 secondes avant execution méthode
        this.service.userConnected;
    }, 5000)*/
    

    return this.router.navigate(['/homeUser']); //on redirige direct vers homeUser car c'est enfaite la page d'accueil
    
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
    this.currentLanguage;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }


  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

}
