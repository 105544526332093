import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../core/auth.service';
import firebase from 'firebase/compat/app';
import { throwError } from 'rxjs';

import { DesignForm } from './formulairesAccess/formulaireAll/designForm.model';
import { LegalForm } from './formulairesAccess/formulaireAll/legalForm.model';
import { Article28Form } from './formulairesAccess/formulaireAll/article28Form.model';
import { Article32Form } from './formulairesAccess/formulaireAll/article32Form.model';
import { IaaSForm } from './formulairesAccess/formulaireAll/iaasForm.model';

import { Demande } from './demandeDla/demande.model';

import { dictToURI } from '../../app/core/helpers';

import { FormulaireAllService } from './formulairesAccess/formulaireAll/formulaireAll.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HomeUserService {
  constructor(
    private firestore: AngularFirestore,
    private toastr: ToastrService,
    private authService: AuthService,
    private formAllService: FormulaireAllService,
    private afs: AngularFireDatabase,
    private httpClient: HttpClient,
    private router: Router,
  ) {}

  // designform: Observable<DesignForm>;
  // legalForm: Observable<LegalForm>;


  ///////////HOME USER avec ses demandes (solutions)/////////////
  
  //SSC
  getAllSSCdata() {
    return this.firestore.collection('securityScoreCard').snapshotChanges();
  }
  
  ///////SaaS///////
  //VEOLIA
  //SELECT ALL demandes dont il est SO du user connecté avec tableau sauf les demandes gelées par l'admin qui ont le statut Unavailable
  getDemandesSaaSUser() {
    return this.firestore
      .collection('solutions', ref =>
        ref.where('solutionOwner', '==', this.authService.currentUserEmail).where('type', '==', 'SaaS')
      ) //le SolutionOwner prend le dessus sur Initiator
      .snapshotChanges();
  }

  //VENDOR
  //le vendor voit apparaître les demandes pour lesquelles on lui a donné accès
  //SaaS forcément
  getDemandesSaaSUserVendor() {
    return this.firestore
      .collection('solutions', ref =>
        ref.where('emailVendorAuthorized', 'array-contains', this.authService.currentUserEmail).where('status', '!=', 'Canceled').where('type', '==', 'SaaS')
      )
      .snapshotChanges();
  }

  //VEOLIA
  //Demandes qui ont été partagées avec le user connecté mais dont il n'est pas solution owner (dont il a accès)
  getDemandesSaaSUserShared() {
    return this.firestore
      .collection('solutions', ref =>
        ref.where('emailVeoliaAuthorized', 'array-contains', this.authService.currentUserEmail).where('solutionOwner', '!=', this.authService.currentUserEmail).where('type', '==', 'SaaS')
      )
      .snapshotChanges();
  }

  //VEOLIA
  //Demandes qui ont été partagées avec le user connecté dont il est sous emailEvaluatorAuthorized
  getDemandesSaaSUserNamedAsEvaluator() {
    return this.firestore
      .collection('solutions', ref =>
        ref.where('emailEvaluatorAuthorized', 'array-contains', this.authService.currentUserEmail).where('solutionOwner', '!=', this.authService.currentUserEmail).where('type', '==', 'SaaS')
      )
      .snapshotChanges();
  }

  //VEOLIA
  //Ensemble des demandes qui appartiennent à la Bu du user
  getDemandesSaaSBuUser(userBu) {
    return this.firestore.collection('solutions', ref => ref.where('type', '==', 'SaaS').where('businessUnitOfSolution', '==', userBu)).snapshotChanges();
  }

  //VEOLIA-
  //Ensemble des demandes qui n'appartiennent pas à la Bu du user
  getDemandesSaaSOtherBuUser(userBu) {
    return this.firestore.collection('solutions', ref => ref.where('type', '==', 'SaaS').where('businessUnitOfSolution', '!=', userBu)).snapshotChanges();
  }
  ///////END SaaS///////

  ///////IaaS///////
  getDemandesIaaSUser() {
    return this.firestore
      .collection('solutions', ref =>
        ref.where('solutionOwner', '==', this.authService.currentUserEmail).where('type', '==', 'IaaS')
      ) //le SolutionOwner prend le dessus sur Initiator
      .snapshotChanges();
  }

  getDemandesIaaSUserShared() {
    return this.firestore
      .collection('solutions', ref =>
        ref.where('emailVeoliaAuthorized', 'array-contains', this.authService.currentUserEmail).where('solutionOwner', '!=', this.authService.currentUserEmail).where('type', '==', 'IaaS')
      )
      .snapshotChanges();
  }

  getDemandesIaaSBuUser(userBu) {
    return this.firestore.collection('solutions', ref => ref.where('type', '==', 'IaaS').where('businessUnitOfSolution', '==', userBu)).snapshotChanges();
  }

  getDemandesIaaSOtherBuUser(userBu) {
    return this.firestore.collection('solutions', ref => ref.where('type', '==', 'IaaS').where('businessUnitOfSolution', '!=', userBu)).snapshotChanges();
  }
  ///////END IaaS///////

  //UPDATE statut de la demande en avorté
  // avort(id: string) {
  //   if (confirm('Do you really want to cancel this request')) {
  //     this.firestore.doc('solutions/' + id).update({ status: 'Canceled' });
  //     this.toastr.info('All stakeholders have been notified', 'Cancelled successfully', {
  //       timeOut: 6000,
  //       closeButton: true,
  //       progressBar: true,
  //       positionClass: 'toast-bottom-center'
  //     });

  //     const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${id}`);

  //   demandeRef.get().subscribe(snap => {

  //     //envoi du mail demande annulée
  //     this.sendSolutionCanceled(this.authService.currentUserEmail, this.authService.currentUserDisplayName, snap.data().solutionName, snap.data().rfpName, snap.data().editorBrand, snap.data().businessUnitOfSolution, snap.data().requestDate.toDate(), snap.data().solutionOwner, snap.data().emailVendorAuthorized[0]).subscribe(data => {
  //           if (data !== "200") {
  //           console.error("KO mail retarus unsent !")
  //           } else {
  //             console.log("OK mail retarus sent !")
  //           }
  //           console.log("from api: " + data);
  //         });
  //   });

  //   }
  // }

  // sendSolutionCanceled(initiatorEmail, initiatorDisplayName, solutionName, rfpName, editorBrand, buOfSolution, requestDate, solOwner, vendorEmail){
  //   return this.httpClient.get('/api/solution_cancelled?emailVeolia='+initiatorEmail+'&displayNameVeolia='+initiatorDisplayName+'&solName='+solutionName+'&rfpName='+rfpName+'&editorBrand='+editorBrand+'&bu='+buOfSolution+'&requestDate='+requestDate+'&emailSolOwner='+solOwner+'&emailvendor='+vendorEmail, {responseType:'text'}).pipe(
  //     catchError(this.handleErrorHttp)
  //   );
  // }

  //////////EDIT DEMANDE////////////

  //Méthodes qui vont appelées les fonctions de auth.service pour récpérer les informations du user et les placer dans le formulaire en hidden
  getDisplayNameUser() {
    return this.authService.currentUserDisplayName;
  }

  getEmailUser() {
    return this.authService.currentUserEmail;
  }

  getUidUser() {
    return this.authService.currentUserId;
  }

  //select une demande par son id pour mettre ses informations dans la nouvelle page edit et formulairesAccess est utilisé dans resolver
  getDemandeBySolutionId(id: string) {
    return this.firestore
      .collection('solutions')
      .doc(id)
      .snapshotChanges();
  }

  //SaaS
  //on ne modifie qu'une solution même si +1 solutions pour le RFP
  updateDemandeSaaS(id: string, value) {
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${id}`);

    const data: Demande = { //type ne change pas
      fullNameInitiatorVeolia: value.fullNameInitiatorVeolia,
      emailInitiatorVeolia: value.emailInitiatorVeolia,
      solutionOwner: value.solutionOwner,
      businessUnitOfSolution: value.businessUnitOfSolution,
      expectedEvaluationDate: value.expectedEvaluationDate,
      expectedCompletionDate: value.expectedCompletionDate,
      expectedOnlineReleaseDate: value.expectedOnlineReleaseDate,
      description: value.description,
      accessQuestion: value.accessQuestion,
      integrateQuestion: value.integrateQuestion,
      interconnectQuestion: value.interconnectQuestion,
      interconnectQuestion2: value.interconnectQuestion2,
      communicationQuestion: value.communicationQuestion,
      rfpName: value.rfpName,
      domainOfSolution: value.domainOfSolution,
      /////Solution (pour 1 RFP)////
        //solutionSection : {
          //Solution
          solutionName: value.solutionName,
          editorBrand: value.editorBrand,
          inPlace: value.inPlace,
          apmNumber: value.apmNumber,
          link: value.link,

          //Main contact
          mainEmailVendor: value.mainEmailVendor,
          mainFirstnameVendor: value.mainFirstnameVendor,
          mainLastnameVendor: value.mainLastnameVendor,
          mainCompanyVendor: value.mainCompanyVendor,
          linkCom: value.linkCom,
      //}
    };
    return demandeRef.set(data, { merge: true }); //le reste des données n'est pas modifié; le lastUpdate concerne les forms donc on ne le modifie pas
  }

  //IaaS
  updateDemandeIaaS(id: string, value) {
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${id}`);

    const data: Demande = { //type ne change pas
      fullNameInitiatorVeolia: value.fullNameInitiatorVeolia,
      emailInitiatorVeolia: value.emailInitiatorVeolia,
      solutionOwner: value.solutionOwner,
      businessUnitOfSolution: value.businessUnitOfSolution,
      description: value.description,
      domainOfSolution: value.domainOfSolution,
      solutionName: value.solutionName,
      editorBrand: value.editorBrand,
      inPlace: value.inPlace,
      apmNumber: value.apmNumber,
      link: value.link,
      q0a_iaas: value.q0a_iaas,
      q0b_iaas: value.q0b_iaas,
      comment: value.comment
    };
    return demandeRef.set(data, { merge: true }); //le reste des données n'est pas modifié; le lastUpdate concerne les forms donc on ne le modifie pas
  }

  ////////GESTION VENDOR/////////

  //pour le admin/viewer ils voient quels sont les emails qui ont déjà accès à la demande
  getEmailsAuthorizedBySolutionId(id: string) {
    return this.firestore
      .collection('solutions')
      .doc(id)
      .snapshotChanges();
  }

  //Ok 1ere technique mais ça fait apparaître tous les email des vendor
  /* getEmailVendorAccountsOrganizationDomaine () {
 return this.firestore.collection('users', ref => ref.where('role', '==', "Vendor")).snapshotChanges();
  }*/

  //Liste des compte Veolia qui existent dans la bd
  getEmailVeoliaAccounts() {
    return this.firestore.collection('users', ref =>
    ref.where('isVeolia', '==', true)
       .where('email', '!=', this.authService.currentUserEmail))
       .snapshotChanges();
  }

  //appeler par gestionVendor.ts
  removeUserFromRequest(email: string, userEmailRef: string, idSolution: string) {
    const demandeRef = this.firestore.collection('solutions').doc(idSolution);
    //ou const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`demandes/${idDemande}`);

    if (userEmailRef === 'veolia') {
      console.log('VEOLIA USER');
      demandeRef.update({ emailVeoliaAuthorized: firebase.firestore.FieldValue.arrayRemove(email) });
    }

    if (userEmailRef === 'vendor') {
      console.log('VENDOR USER');
      demandeRef.update({ emailVendorAuthorized: firebase.firestore.FieldValue.arrayRemove(email) });
      demandeRef.update({ emailVendorReadonlyArticle28: firebase.firestore.FieldValue.arrayRemove(email) });
      demandeRef.update({ emailVendorReadonlyArticle32: firebase.firestore.FieldValue.arrayRemove(email) });
      demandeRef.update({ emailVendorReadonlyDesign: firebase.firestore.FieldValue.arrayRemove(email) });
      demandeRef.update({ emailVendorReadonlyLegal: firebase.firestore.FieldValue.arrayRemove(email) });
    }
  }

  //appeler par gestionVendor.ts
  giveOnlyReadAccessToVendor(email: string, userEmailRef: string, idSolution: string, form) {
    const demandeRef = this.firestore.collection('solutions').doc(idSolution);
    //ou const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`demandes/${idDemande}`);

      if(form === 'design') {
        demandeRef.update({ emailVendorReadonlyDesign: firebase.firestore.FieldValue.arrayUnion(email) });
      }
      if(form === 'legal') {
        demandeRef.update({ emailVendorReadonlyLegal: firebase.firestore.FieldValue.arrayUnion(email) });
      }
      if(form === 'art28') {
        demandeRef.update({ emailVendorReadonlyArticle28: firebase.firestore.FieldValue.arrayUnion(email) });
      }
      if(form === 'art32') {
        demandeRef.update({ emailVendorReadonlyArticle32: firebase.firestore.FieldValue.arrayUnion(email) });
      }
  }

  //appeler par gestionVendor.ts
  giveReadWriteAccessToVendor(email: string, userEmailRef: string, idSolution: string, form) {
    const demandeRef = this.firestore.collection('solutions').doc(idSolution);
    //ou const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`demandes/${idDemande}`);

      if(form === 'design') {
        demandeRef.update({ emailVendorReadonlyDesign: firebase.firestore.FieldValue.arrayRemove(email) });
      }
      if(form === 'legal') {
        demandeRef.update({ emailVendorReadonlyLegal: firebase.firestore.FieldValue.arrayRemove(email) });
      }
      if(form === 'art28') {
        demandeRef.update({ emailVendorReadonlyArticle28: firebase.firestore.FieldValue.arrayRemove(email) });
      }
      if(form === 'art32') {
        demandeRef.update({ emailVendorReadonlyArticle32: firebase.firestore.FieldValue.arrayRemove(email) });
      }
  }

  //envoi du mail d'info au SO pour partage de la demande à un vendor != du domaine du vendor demandeur
  sendRequestShareExternalVendor(emailVendor, lastnameVendor, firstnameVendor, companyVendor, idSol, solName, rfpName, editorBrand, userRequester, buOfSol, displayNameOfSolOwner, emailSolOwner){
    const query = dictToURI({
        newVendorEmail: emailVendor,
        firstNameVendor: firstnameVendor,
        lastNameVendor: lastnameVendor,
        companyVendor,
        idSol,
        vendorRequester: userRequester,
        solutionName: solName,
        rfpName,
        editorBrand,
        bu: buOfSol,
        displayNameOfSolOwner: displayNameOfSolOwner,
        emailSolOwner
      });
    return this.httpClient.get('/api/request_share_external_vendor?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttpBasic)
    );
  }

  ////////HomeUser vers le formulaire selectionné//////////

   //FORMULAIRES//
  //SaaS
  initializeDesignForm(id: string) {
    const fire = firebase.firestore();

    //FORMULAIRE DA
    fire
      .collection('designForm')
      .where('idSolution', '==', id)
      .get()
      .then(snapshot => {
          //Le form existe déjà en BD car il a été initilisé dans le validate-solution.ts, on met juste à jour les données initiales de la solution dans designForm (les réponses du form ne bougent pas)
          console.log('Le doc designForm existe déjà');

          fire
            .collection('solutions')
            .doc(id)
            .get()
            .then(doc => {
              const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc('designForm/' + id); // id du doc=id Demande concernée

              const data: DesignForm = {
                idSolution: id,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                rfpName: doc.data().rfpName,
                description: doc.data().description,
                type: doc.data().type,
                link: doc.data().link,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                expectedEvaluationDate: doc.data().expectedEvaluationDate,
                expectedCompletionDate: doc.data().expectedCompletionDate,
                requestDate: doc.data().requestDate,
                emailVendorAuthorized: doc.data().emailVendorAuthorized,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                emailEvaluatorAuthorized: doc.data().emailEvaluatorAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS
              };
              console.log('MAJ doc designForm');
              refFormDa.set(data, { merge: true }); //seulement les données qui ont changées sont maj

              return this.router.navigate(['/designForm/' + id]) //redirection
            })
            .catch(err => {
              console.log('Error getting document', err);
            });
      });
  }

  initializeLegalForm(id: string) {
    const fire = firebase.firestore();

    //FORMULAIRE LA
    fire
      .collection('legalForm')
      .where('idSolution', '==', id)
      .get()
      .then(snapshot => {
        //Le form existe déjà en BD car il a été initilisé dans le validate-solution.ts, on met juste à jour les données initiales de la solution dans legalForm (les réponses du form ne bougent pas)
          console.log('Le doc legalForm existe déjà');

          fire
            .collection('solutions')
            .doc(id)
            .get()
            .then(doc => {
              //création du document formLa correspondant à la demande choisie
              const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc('legalForm/' + id); // id du doc=id Demande concernée

              const data: LegalForm = {
                idSolution: id,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                rfpName: doc.data().rfpName,
                description: doc.data().description,
                type: doc.data().type,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                expectedEvaluationDate: doc.data().expectedEvaluationDate,
                expectedCompletionDate: doc.data().expectedCompletionDate,
                requestDate: doc.data().requestDate,
                emailVendorAuthorized: doc.data().emailVendorAuthorized,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                emailEvaluatorAuthorized: doc.data().emailEvaluatorAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS
              };
              console.log('MAJ doc LegalForm');
              refFormLa.set(data, { merge: true });

              return this.router.navigate(['/legalForm/' + id]) //redirection
            })
            .catch(err => {
              console.log('Error getting document', err);
            });
      });
  }

  initializeArticle32Form(id: string) {
    const fire = firebase.firestore();

    //FORMULAIRE ARTICLE 32
    fire
      .collection('article32Form')
      .where('idSolution', '==', id)
      .get()
      .then(snapshot => {
          //Le form existe déjà en BD car il a été initilisé dans le validate-solution.ts, on met juste à jour les données initiales de la solution dans article32Form (les réponses du form ne bougent pas)
          console.log('Le doc article32Form existe déjà');

          fire
            .collection('solutions')
            .doc(id)
            .get()
            .then(doc => {
              const refForm32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${id}`);

              const data: Article32Form = {
                idSolution: id,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                rfpName: doc.data().rfpName,
                description: doc.data().description,
                type: doc.data().type,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                expectedEvaluationDate: doc.data().expectedEvaluationDate,
                expectedCompletionDate: doc.data().expectedCompletionDate,
                requestDate: doc.data().requestDate,
                emailVendorAuthorized: doc.data().emailVendorAuthorized,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                emailEvaluatorAuthorized: doc.data().emailEvaluatorAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS
              };
              console.log('MAJ doc article32Form');
              refForm32.set(data, { merge: true }); //seulement les données qui ont changées sont maj

              return this.router.navigate(['/article32Form/' + id]) //redirection
            })
            .catch(err => {
              console.log('Error getting document', err);
            });
      });
  }

  initializeArticle28Form(id: string) {
    const fire = firebase.firestore();

    //FORMULAIRE ARTICLE 28
    fire
      .collection('article28Form')
      .where('idSolution', '==', id)
      .get()
      .then(snapshot => {
          //Le form existe déjà en BD car il a été initilisé dans le validate-solution.ts, on met juste à jour les données initiales de la solution dans article28Form (les réponses du form ne bougent pas)
          console.log('Le doc article28Form existe déjà');

          fire
            .collection('solutions')
            .doc(id)
            .get()
            .then(doc => {
              const refForm28: AngularFirestoreDocument<Article28Form> = this.firestore.doc(`article28Form/${id}`);

              const data: Article28Form = {
                idSolution: id,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                rfpName: doc.data().rfpName,
                description: doc.data().description,
                type: doc.data().type,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                expectedEvaluationDate: doc.data().expectedEvaluationDate,
                expectedCompletionDate: doc.data().expectedCompletionDate,
                requestDate: doc.data().requestDate,
                emailVendorAuthorized: doc.data().emailVendorAuthorized,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                emailEvaluatorAuthorized: doc.data().emailEvaluatorAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS
              };
              console.log('MAJ doc article28Form');
              refForm28.set(data, { merge: true }); //seulement les données qui ont changées sont maj

              return this.router.navigate(['/article28Form/' + id]) //redirection
            })
            .catch(err => {
              console.log('Error getting document', err);
            });
      });
  }

  //IaaS
  initializeIaaSForm(id: string) {
    //ON vérifie deja si le form IaaS existe si oui on ne touche à rien sinon on le créé avec id du doc = idDemande
    const fire = firebase.firestore();

    //FORMULAIRE IaaS
    fire
      .collection('iaasForm')
      .where('idSolution', '==', id)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          //Si le formIaas n'existe pas
          //requete sur la demande concernée pour récup ses données et les insérées dans la nouvelle table IaaSForm
          fire
            .collection('solutions')
            .doc(id)
            .get()
            .then(doc => {
              //création du formulaire formIaaS correspondant à la demande choisie
              //en général ce sera toujours le veolia qui ira dessus en premier (il initialise)
              const refFormIaaS: AngularFirestoreDocument<IaaSForm> = this.firestore.doc(`iaasForm/${id}`);

              const data: IaaSForm = {
                idSolution: id,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                description: doc.data().description,
                type: doc.data().type,
                link: doc.data().link,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                q0a_iaas: doc.data().q0a_iaas,
                q0b_iaas: doc.data().q0a_iaas,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                requestDate: doc.data().requestDate,
                createdAt: new Date(Date.now()), //uniquement lors de l'initialisation
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS,
                iaasFull: false //uniquement lors de l'initialisation
              };
              console.log('Création doc iaasForm');
              refFormIaaS.set(data, { merge: true });

              return this.router.navigate(['/IaaSForm/' + id]) //redirection
            })
            .catch(err => {
              console.log('Error getting document', err);
            });
        } else {
          //Si le form existe déjà, on met juste à jour les données initiales de la solution dans article28Form (les réponses du form ne bougent pas)
          console.log('Le doc iaasForm existe déjà');

          fire
            .collection('solutions')
            .doc(id)
            .get()
            .then(doc => {
              const refFormIaaS: AngularFirestoreDocument<IaaSForm> = this.firestore.doc(`iaasForm/${id}`);

              const data: IaaSForm = {
                idSolution: id,
                domainOfSolution: doc.data().domainOfSolution,
                businessUnitOfSolution: doc.data().businessUnitOfSolution,
                solutionName: doc.data().solutionName,
                description: doc.data().description,
                type: doc.data().type,
                link: doc.data().link,
                inPlace: doc.data().inPlace,
                apmNumber: doc.data().apmNumber,
                q0a_iaas: doc.data().q0a_iaas,
                q0b_iaas: doc.data().q0a_iaas,
                editorBrand: doc.data().editorBrand,
                solutionOwner: doc.data().solutionOwner,
                emailInitiatorVeolia: doc.data().emailInitiatorVeolia,
                fullNameInitiatorVeolia: doc.data().fullNameInitiatorVeolia,
                requestDate: doc.data().requestDate,
                emailVeoliaAuthorized: doc.data().emailVeoliaAuthorized,
                jsonFR: doc.data().jsonFR,
                jsonUS: doc.data().jsonUS,
              };
              console.log('MAJ doc iaasForm');
              refFormIaaS.set(data, { merge: true }); //seulement les données qui ont changées sont maj

              return this.router.navigate(['/IaaSForm/' + id]) //redirection
            })
            .catch(err => {
              console.log('Error getting document', err);
            });
        }
      });
  }


  ////////////////////////////////////LES STATUTS///////////////////////////////////
  //appelé depuis le contact
  //lorsque vendor demande de l'aide via le formulaire need help
  editStatusSolutionNeedHelp(idSolution: string, form: string) {
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${idSolution}`);

    if(form === "Design Form") {
      const data: Demande = {
        designStatus: 'Need help'
      };
    return demandeRef.update(data);
    }

    if(form === "Legal Form") {
      const data: Demande = {
        legalStatus: 'Need help'
      };
    return demandeRef.update(data);
    }

    if(form === "Article 28 Form") {
      const data: Demande = {
        article28Status: 'Need help'
      };
    return demandeRef.update(data);
    }

    if(form === "Article 32 Form") {
      const data: Demande = {
        article32Status: 'Need help'
      };
    return demandeRef.update(data);
    }

    if(form === "IaaS Form") {
      const data: Demande = {
        iaasStatus: 'Need help'
      };
    return demandeRef.update(data);
    }


  }

  //appelé depuis le xxxxForm.ts et create-item.ts
  //lorsque le vendor commence à remplir (on passe de Empty à Vendor is responding) le statut global ou qu'il fait des modifs
  //Statut global in solution
  editStatusSolutionInProgress(idSolution: string) {
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${idSolution}`);
    const data = {
      status: 'Vendor is responding',
      fullyEvaluated: false
    } as Demande;
    return demandeRef.update(data);
  }

  //statut design in solutions
  editSolutionStatusDesignInProgress(idSolution: string) {
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${idSolution}`);
    const data: Demande = {
      designStatus: 'Vendor is responding'
    };
    return demandeRef.update(data);
  }

  //statut legal in solutions
  editSolutionStatusLegalInProgress(idSolution: string) {
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${idSolution}`);
    const data: Demande = {
      legalStatus: 'Vendor is responding'
    };
    return demandeRef.update(data);
  }

  //statut 28 in solutions
  editSolutionStatusArticle28InProgress(idSolution: string) {
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${idSolution}`);
    const data: Demande = {
      article28Status: 'Vendor is responding'
    };
    return demandeRef.update(data);
  }

  //statut 32 in solutions
  editSolutionStatusArticle32InProgress(idSolution: string) {
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${idSolution}`);
    const data: Demande = {
      article32Status: 'Vendor is responding'
    };
    return demandeRef.update(data);
  }

  //statut iaas in solutions
  editSolutionStatusIaaSInProgress(idSolution: string) {
    const iaasRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${idSolution}`);
    const data: Demande = {
      iaasStatus: 'Vendor is responding'
    };
    return iaasRef.update(data);
  }

  //appelé depuis le overview-evaluation.ts
  editGlobalStatusSolutionEvaluated(idSolution: string, tabname: string) {
    //requetes pour récuperer les status de chaque form sous realtime database + commentaires associés
    let commentDesign;
    let commentLegal;
    let commentArt32;
    let statusDesign;
    let statusLegal;
    let statusArt32;

    firebase
      .database()
      .ref(`evaluations/Design Form/${idSolution}`)
      .once('value')
      .then(snapshot => {
        console.log('Noeud idSolution: ' + snapshot.key);

        snapshot.forEach(node => {
          console.log('Sous Noeud key: ' + node.key);
          statusDesign = node.val().status;
          commentDesign = node.val().finalComment;
          console.log('Status eval design = ' + statusDesign);
        });
      })
    firebase
      .database()
      .ref(`evaluations/Legal Form/${idSolution}`)
      .once('value')
      .then(snapshot => {
        console.log('Noeud idSolution: ' + snapshot.key);

        snapshot.forEach(node => {
          console.log('Sous Noeud key: ' + node.key);
          statusLegal = node.val().status;
          commentLegal = node.val().finalComment;
          console.log('Status eval legal = ' + statusLegal);
        });
      })
      firebase
      .database()
      .ref(`evaluations/Article 32 Form/${idSolution}`)
      .once('value')
      .then(snapshot => {
        console.log('Noeud idSolution: ' + snapshot.key);

        snapshot.forEach(node => {
          console.log('Sous Noeud key: ' + node.key);
          statusArt32 = node.val().status;
          commentArt32 = node.val().finalComment;
          console.log('Status eval article 32 = ' + statusArt32);
        });
      })

    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${idSolution}`);

    this.delay(2500).then(any => {
      //SaaS
      //lorsque évaluation finale émise pour design ET legal ET 32 sinon le status reste à Under assessment
      //le 28 ne compte pas pour statut global
      if(tabname === 'Design Form' || tabname === 'Legal Form' || tabname === 'Article 32 Form' || tabname === 'Article 28 Form') {
        //on check designStatus et legalStatus et 32 dans 'solutions' pour determiner le statusGlobal de la solution
        demandeRef.get().subscribe(snap => {

          //SI les 3 formulaires sont "Recommended"
          if(snap.data().legalStatus === "Recommended" && snap.data().designStatus === "Recommended" && snap.data().article32Status === "Recommended") {
             console.log("-----finalGlobalStatus => Recommended-------")

              //1- envoie mail à la DLA que la demande est completement évaluée
                this.sendSolutionFullyEvaluatedSaaS(idSolution, this.authService.currentUserEmail, snap.data().solutionName, snap.data().rfpName, snap.data().editorBrand, snap.data().solutionOwner, "Recommended", "Recommended", "Recommended", "Recommended", commentDesign, commentLegal, commentArt32, snap.data().businessUnitOfSolution, null,'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                if (data !== "200") {
                  console.error("KO mail retarus unsent !")
                } else {
                  console.log("OK mail retarus sent !")
                }
                  console.log("from api: " + data);
              });

            // 2-envoi email aux evaluators BU inclus dans evaluators
            if(this.formAllService.emailsEvaluatorsBu) {
              for(let i = 0; i < this.formAllService.emailsEvaluatorsBu.length; i++) {
                console.log("mail envoyé a l'evaluator: (" + i + ") " + this.formAllService.emailsEvaluatorsBu[i])

                this.sendSolutionFullyEvaluatedSaaS(idSolution, this.authService.currentUserEmail, snap.data().solutionName, snap.data().rfpName, snap.data().editorBrand, snap.data().solutionOwner, "Recommended", statusDesign, statusLegal, statusArt32, commentDesign, commentLegal, commentArt32, snap.data().businessUnitOfSolution, this.formAllService.emailsEvaluatorsBu[i], null).subscribe(data => {
                  if (data !== "200") {
                    console.error("KO mail retarus unsent !")
                  } else {
                      console.log("OK mail retarus sent !")
                  }
                  console.log("from api: " + data);
                });
              }
            }

              const data = {
              status: 'Recommended',
              fullyEvaluated: true,
              finalEvaluationDate: new Date(Date.now()) //ajout champ
              } as Demande;
            return demandeRef.update(data);
          }

          //SI au moins 1 formulaire avec "Recommended with reservations"
          if( (snap.data().legalStatus === "Reservations"
          || snap.data().designStatus === "Reservations"
          || snap.data().article32Status === "Reservations") &&
              (snap.data().legalStatus !== "Not recommended"
              && snap.data().designStatus !== "Not recommended"
              && snap.data().article32Status !== "Not recommended")
            ) {
             console.log("-----finalGlobalStatus => Recommended with reservations-----")

             //1- envoie mail à la DLA que la demande est completement évaluée
                this.sendSolutionFullyEvaluatedSaaS(idSolution, this.authService.currentUserEmail, snap.data().solutionName, snap.data().rfpName, snap.data().editorBrand, snap.data().solutionOwner, "Recommended with reservations", statusDesign, statusLegal, statusArt32, commentDesign, commentLegal, commentArt32, snap.data().businessUnitOfSolution, null,'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                if (data !== "200") {
                  console.error("KO mail retarus unsent !")
                } else {
                  console.log("OK mail retarus sent !")
                }
                  console.log("from api: " + data);
              });

            // 2-envoi email aux evaluators BU inclus dans evaluators
            if(this.formAllService.emailsEvaluatorsBu) {
              for(let i = 0; i < this.formAllService.emailsEvaluatorsBu.length; i++) {
                console.log("mail envoyé a l'evaluator: (" + i + ") " + this.formAllService.emailsEvaluatorsBu[i])

                this.sendSolutionFullyEvaluatedSaaS(idSolution, this.authService.currentUserEmail, snap.data().solutionName, snap.data().rfpName, snap.data().editorBrand, snap.data().solutionOwner, "Recommended with reservations", statusDesign, statusLegal, statusArt32, commentDesign, commentLegal, commentArt32, snap.data().businessUnitOfSolution, this.formAllService.emailsEvaluatorsBu[i], null).subscribe(data => {
                  if (data !== "200") {
                    console.error("KO mail retarus unsent !")
                  } else {
                      console.log("OK mail retarus sent !")
                  }
                  console.log("from api: " + data);
                });
              }
            }

             const data = {
              status: 'Reservations',
              fullyEvaluated: true,
              finalEvaluationDate: new Date(Date.now()) //ajout champ
              } as Demande;
            return demandeRef.update(data);
          }

          //SI au moins 1 formulaire avec "Not recommended"
          if(snap.data().legalStatus === "Not recommended" || snap.data().designStatus === "Not recommended" || snap.data().article32Status === "Not recommended") {
             console.log("-----finalGlobalStatus => Not recommended------")

             //1- envoie mail à la DLA que la demande est completement évaluée
                this.sendSolutionFullyEvaluatedSaaS(idSolution, this.authService.currentUserEmail, snap.data().solutionName, snap.data().rfpName, snap.data().editorBrand, snap.data().solutionOwner, "Not recommended", statusDesign, statusLegal, statusArt32, commentDesign, commentLegal, commentArt32, snap.data().businessUnitOfSolution, null,'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                if (data !== "200") {
                  console.error("KO mail retarus unsent !")
                } else {
                  console.log("OK mail retarus sent !")
                }
                  console.log("from api: " + data);
              });

            //2-envoi email aux evaluators BU inclus dans evaluators
            if(this.formAllService.emailsEvaluatorsBu) {
              for(let i = 0; i < this.formAllService.emailsEvaluatorsBu.length; i++) {
                console.log("mail envoyé a l'evaluator: (" + i + ") " + this.formAllService.emailsEvaluatorsBu[i])

                this.sendSolutionFullyEvaluatedSaaS(idSolution, this.authService.currentUserEmail, snap.data().solutionName, snap.data().rfpName, snap.data().editorBrand, snap.data().solutionOwner, "Not recommended", statusDesign, statusLegal, statusArt32, commentDesign, commentLegal, commentArt32, snap.data().businessUnitOfSolution, this.formAllService.emailsEvaluatorsBu[i], null).subscribe(data => {
                  if (data !== "200") {
                    console.error("KO mail retarus unsent !")
                  } else {
                      console.log("OK mail retarus sent !")
                  }
                  console.log("from api: " + data);
                });
              }
            }

             const data = {
              status: 'Not recommended',
              fullyEvaluated: true,
              finalEvaluationDate: new Date(Date.now()) //ajout champ
              } as Demande;
            return demandeRef.update(data);
          }

        });
      }

      //IaaS
      if(tabname === 'IaaS Form') {
        //on check iaasStatus pour determiner le statusGlobal de la solution
        demandeRef.get().subscribe(snap => {

          //SI le form iaas est "Recommended"
          if(snap.data().iaasStatus === "Recommended" ) {
             console.log("-----finalGlobalStatus => Recommended-------")

             //envoi du mail récapitulatif solution complétement évaluée avec son statut finale
              this.sendSolutionFullyEvaluated(this.authService.currentUserEmail, snap.data().solutionName, snap.data().editorBrand, snap.data().solutionOwner, "Recommended", snap.data().businessUnitOfSolution, tabname).subscribe(data => {
                if (data !== "200") {
                console.error("KO mail retarus unsent !")
                } else {
                  console.log("OK mail retarus sent !")
                }
                console.log("from api: " + data);
              });

             const data = {
              status: 'Recommended',
              fullyEvaluated: true,
              finalEvaluationDate: new Date(Date.now()) //ajout champ
              } as Demande;
            return demandeRef.update(data);
          }

          //SI iaas form est "Recommended with reservations"
          if(snap.data().iaasStatus === "Reservations") {
             console.log("-----finalGlobalStatus => Recommended with reservations-----")

             //envoi du mail récapitulatif solution complétement évaluée avec son statut finale
              this.sendSolutionFullyEvaluated(this.authService.currentUserEmail, snap.data().solutionName, snap.data().editorBrand, snap.data().solutionOwner, "Recommended with reservations", snap.data().businessUnitOfSolution, tabname).subscribe(data => {
                if (data !== "200") {
                console.error("KO mail retarus unsent !")
                } else {
                  console.log("OK mail retarus sent !")
                }
                console.log("from api: " + data);
              });

             const data = {
              status: 'Reservations',
              fullyEvaluated: true,
              finalEvaluationDate: new Date(Date.now()) //ajout champ
              } as Demande;
            return demandeRef.update(data);
          }

          //SI au moins 1 formulaire avec "Not recommended"
          if(snap.data().iaasStatus === "Not recommended" ) {
             console.log("-----finalGlobalStatus => Not recommended------")

             //envoi du mail récapitulatif solution complétement évaluée avec son statut finale
              this.sendSolutionFullyEvaluated(this.authService.currentUserEmail, snap.data().solutionName, snap.data().editorBrand, snap.data().solutionOwner, "Not recommended", snap.data().businessUnitOfSolution, tabname).subscribe(data => {
                if (data !== "200") {
                console.error("KO mail retarus unsent !")
                } else {
                  console.log("OK mail retarus sent !")
                }
                console.log("from api: " + data);
              });

             const data = {
              status: 'Not recommended',
              fullyEvaluated: true,
              finalEvaluationDate: new Date(Date.now()) //ajout champ
              } as Demande;
            return demandeRef.update(data);
          }
        });
      }

    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  //SaaS
  sendSolutionFullyEvaluatedSaaS(idSolution, evaluatorEmail, solutionName, rfpName, editorBrand, solOwner, finalStatus, statusDesign, statusLegal, statusArt32, commentDesign, commentLegal, commentArt32, buOfSolution, evaluatorsBu, emailgbldla){
    const query = dictToURI({
      idSol: idSolution,
      emailSolOwner: solOwner,
      emailgbldla,
      emailEvaluator: evaluatorEmail,
      solutionName,
      rfpName,
      editorBrand,
      globalStatusSolution: finalStatus,
      statusDesign: statusDesign, 
      statusLegal,
      statusArt32,
      commentDesign,
      commentLegal,
      commentArt32,
      bu: buOfSolution,
      emailsEvaluatorsBuList: evaluatorsBu
    });
    return this.httpClient.get('/api/solution_fully_evaluated_overview_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  //IaaS PaaS Apps
  sendSolutionFullyEvaluated(evaluatorEmail, solutionName, editorBrand, solOwner, finalStatus, buOfSolution, tabName){
    const query = dictToURI({
      emailSolOwner: solOwner,
      emailEvaluator: evaluatorEmail,
      solutionName: solutionName,
      editorBrand,
      globalStatusSolution: finalStatus,
      bu: buOfSolution, 
      type: tabName.substring(0, 4)
    });
    return this.httpClient.get('/api/solution_fully_evaluated_overview?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  public handleErrorHttp(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }

  //statut designStatus in designForm
  editDesignFormStatusDesignInProgress(idSolution: string) {
    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${idSolution}`);
    const data: DesignForm = {
      designStatus: 'Vendor is responding',
    };
    return refFormDa.update(data);
  }

  //statut legalStatus in legalForm
  editLegalFormStatusLegalInProgress(idSolution: string) {
    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${idSolution}`);
    const data: LegalForm = {
      legalStatus: 'Vendor is responding'
    };
    return refFormLa.update(data);
  }

  //statut article28Status in article28Form
  editArticle28FormStatusArticle28InProgress(idSolution: string) {
    const refForm28: AngularFirestoreDocument<Article28Form> = this.firestore.doc(`article28Form/${idSolution}`);
    const data: Article28Form = {
      article28Status: 'Vendor is responding'
    };
    return refForm28.update(data);
  }

  //statut article32Status in article32Form
  editArticle32FormStatusArticle32InProgress(idSolution: string) {
    const refForm32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${idSolution}`);
    const data: Article32Form = {
      article32Status: 'Vendor is responding'
    };
    return refForm32.update(data);
  }

  //statut iaasStatus in iaasForm
  editIaaSFormStatusIaaSInProgress(idSolution: string) {
    const refFormIaaS: AngularFirestoreDocument<IaaSForm> = this.firestore.doc(`iaasForm/${idSolution}`);
    const data: IaaSForm = {
      iaasStatus: 'Vendor is responding'
    };
    return refFormIaaS.update(data);
  }


  public handleErrorHttpBasic(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }

}
