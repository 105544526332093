//Attributs de départs de la construction du doc dans la table evaluations
//Utilisé dans formulaireAll.ts

export class Evaluation {
  $key?: string; //automatique
  idSolution?: string;
  solutionName?: string;
  formIs?: string;
  postedAt?: string;
  postedBy?: string;
  userId?: string;
  idRefEval?: string;
  
  //DESIGN//
  acunetix_radio?: string;
  secu_radio?: string;
  acunetix_comment?: string;
  //Chapitre1
  DA_1_RADIO?: string;
  DA_1_RADIO_comment?: string;
  DA_11_RADIO?: string;
  DA_11_RADIO_comment?: string;
  DA_12_RADIO?: string;
  DA_12_RADIO_comment?: string;
  DA_13_RADIO?: string;
  DA_13_RADIO_comment?: string;
  DA_14_RADIO?: string;
  DA_14_RADIO_comment?: string;
  DA_15_RADIO?: string;
  DA_15_RADIO_comment?: string;
  DA_16_RADIO?: string;
  DA_16_RADIO_comment?: string;

  //Chapitre2
  DA_2_RADIO?: string;
  DA_2_RADIO_comment?: string;
  DA_21_RADIO?: string;
  DA_21_RADIO_comment?: string;
  DA_22_RADIO?: string;
  DA_22_RADIO_comment?: string;
  DA_23_RADIO?: string;
  DA_23_RADIO_comment?: string;
  DA_24_RADIO?: string;
  DA_24_RADIO_comment?: string;
  DA_25_RADIO?: string;
  DA_25_RADIO_comment?: string;

  //Chapitre3
  DA_3_RADIO?: string;
  DA_3_RADIO_comment?: string;
  DA_31_RADIO?: string;
  DA_31_RADIO_comment?: string;
  DA_32_RADIO?: string;
  DA_32_RADIO_comment?: string;

  //Chapitre4
  DA_4_RADIO?: string;
  DA_4_RADIO_comment?: string;
  DA_41_RADIO?: string;
  DA_41_RADIO_comment?: string;
  DA_42_RADIO?: string;
  DA_42_RADIO_comment?: string;
  DA_43_RADIO?: string;
  DA_43_RADIO_comment?: string;
  DA_44_RADIO?: string;
  DA_44_RADIO_comment?: string;
  DA_45_RADIO?: string;
  DA_45_RADIO_comment?: string;
  DA_46_RADIO?: string;
  DA_46_RADIO_comment?: string;
  DA_47_RADIO?: string;
  DA_47_RADIO_comment?: string;

  //Chapitre5
  DA_5_RADIO?: string;
  DA_5_RADIO_comment?: string;
  DA_51_RADIO?: string;
  DA_51_RADIO_comment?: string;
  DA_52_RADIO?: string;
  DA_52_RADIO_comment?: string;
  DA_53_RADIO?: string;
  DA_53_RADIO_comment?: string;
  DA_54_RADIO?: string;
  DA_54_RADIO_comment?: string;

  //Chapitre6
  DA_6_RADIO?: string;
  DA_6_RADIO_comment?: string;
  DA_61_RADIO?: string;
  DA_61_RADIO_comment?: string;
  DA_62_RADIO?: string;
  DA_62_RADIO_comment?: string;
  DA_63_RADIO?: string;
  DA_63_RADIO_comment?: string;
  DA_64_RADIO?: string;
  DA_64_RADIO_comment?: string;
  DA_65_RADIO?: string;
  DA_65_RADIO_comment?: string;

  //chapitre7
  DA_7_RADIO?: string;
  DA_7_RADIO_comment?: string;
  DA_71_RADIO?: string;
  DA_71_RADIO_comment?: string;
  DA_72_RADIO?: string;
  DA_72_RADIO_comment?: string;

  //chapitre8
  DA_8_RADIO?: string;
  DA_8_RADIO_comment?: string;
  DA_81_RADIO?: string;
  DA_81_RADIO_comment?: string;
  DA_82_RADIO?: string;
  DA_82_RADIO_comment?: string;
  
  //chapitre9
  DA_9_RADIO?: string;
  DA_9_RADIO_comment?: string;

  //FINAL
  finalComment?: string;
  status?: string;

  //LEGAL
  //Chapitre1
  LA_1_RADIO?: string;
  LA_1_RADIO_comment?: string;
  LA_10_RADIO?: string;
  LA_10_RADIO_comment?: string;
  LA_11_RADIO?: string;
  LA_11_RADIO_comment?: string;
  LA_111_RADIO?: string;
  LA_111_RADIO_comment?: string;
  LA_112_RADIO?: string;
  LA_112_RADIO_comment?: string;
  LA_113_RADIO?: string;
  LA_113_RADIO_comment?: string;
  LA_114_RADIO?: string;
  LA_114_RADIO_comment?: string;
  LA_115_RADIO?: string;
  LA_115_RADIO_comment?: string;
  LA_116_RADIO?: string;
  LA_116_RADIO_comment?: string;
  
  LA_12_RADIO?: string;
  LA_12_RADIO_comment?: string;
  LA_121_RADIO?: string;
  LA_121_RADIO_comment?: string;
  LA_122_RADIO?: string;
  LA_122_RADIO_comment?: string;
  LA_123_RADIO?: string;
  LA_123_RADIO_comment?: string;
  LA_124_RADIO?: string;
  LA_124_RADIO_comment?: string;
  LA_125_RADIO?: string;
  LA_125_RADIO_comment?: string;
  LA_126_RADIO?: string;
  LA_126_RADIO_comment?: string;
  LA_127_RADIO?: string;
  LA_127_RADIO_comment?: string;
  LA_128_RADIO?: string;
  LA_128_RADIO_comment?: string;
  LA_129_RADIO?: string;
  LA_129_RADIO_comment?: string;
  LA_1210_RADIO?: string;
  LA_1210_RADIO_comment?: string;
  LA_1211_RADIO?: string;
  LA_1211_RADIO_comment?: string;
  
  LA_13_RADIO?: string;
  LA_13_RADIO_comment?: string;
  LA_131_RADIO?: string;
  LA_131_RADIO_comment?: string;
  LA_132_RADIO?: string;
  LA_132_RADIO_comment?: string;
  
  LA_14_RADIO?: string;
  LA_14_RADIO_comment?: string;
  LA_141_RADIO?: string;
  LA_141_RADIO_comment?: string;
  LA_142_RADIO?: string;
  LA_142_RADIO_comment?: string;
  
  LA_15_RADIO?: string;
  LA_15_RADIO_comment?: string;
  LA_151_RADIO?: string;
  LA_151_RADIO_comment?: string;
  LA_152_RADIO?: string;
  LA_152_RADIO_comment?: string;
  LA_153_RADIO?: string;
  LA_153_RADIO_comment?: string;

  //Chapitre2
  LA_2_RADIO?: string;
  LA_2_RADIO_comment?: string;
  LA_21_RADIO?: string;
  LA_21_RADIO_comment?: string;
  LA_22_RADIO?: string;
  LA_22_RADIO_comment?: string;
  LA_23_RADIO?: string;
  LA_23_RADIO_comment?: string;
  LA_24_RADIO?: string;
  LA_24_RADIO_comment?: string;
  LA_25_RADIO?: string;
  LA_25_RADIO_comment?: string;
  LA_26_RADIO?: string;
  LA_26_RADIO_comment?: string;

  //Chapitre3
  LA_3_RADIO?: string;
  LA_3_RADIO_comment?: string;
  LA_31_RADIO?: string;
  LA_31_RADIO_comment?: string;
  LA_32_RADIO?: string;
  LA_32_RADIO_comment?: string;
  LA_33_RADIO?: string;
  LA_33_RADIO_comment?: string;
  LA_34_RADIO?: string;
  LA_34_RADIO_comment?: string;

  //Chapitre4
  LA_4_RADIO?: string;
  LA_4_RADIO_comment?: string;
  LA_41_RADIO?: string;
  LA_41_RADIO_comment?: string;
  LA_42_RADIO?: string;
  LA_42_RADIO_comment?: string;
  LA_43_RADIO?: string;
  LA_43_RADIO_comment?: string;
  
  //Chapitre5
  LA_5_RADIO?: string;
  LA_5_RADIO_comment?: string;
  LA_51_RADIO?: string;
  LA_51_RADIO_comment?: string;
  LA_52_RADIO?: string;
  LA_52_RADIO_comment?: string;
  
  
  //ARTICLE 32
  //Chapitre0
  AR32_0_RADIO?: string;
  AR32_0_RADIO_comment?: string;
  
  //Chapitre1
  AR32_1_RADIO?: string;
  AR32_1_RADIO_comment?: string;

  //Chapitre2
  AR32_2_RADIO?: string;
  AR32_2_RADIO_comment?: string;
  
  //Chapitre3
  AR32_3_RADIO?: string;
  AR32_3_RADIO_comment?: string;
  
  //Chapitre4
  AR32_4_RADIO?: string;
  AR32_4_RADIO_comment?: string;
  
  //Chapitre5
  AR32_5_RADIO?: string;
  AR32_5_RADIO_comment?: string;
  
  //Chapitre6
  AR32_6_RADIO?: string;
  AR32_6_RADIO_comment?: string;
  
  //Chapitre7
  AR32_7_RADIO?: string;
  AR32_7_RADIO_comment?: string;
  
  //Chapitre8
  AR32_8_RADIO?: string;
  AR32_8_RADIO_comment?: string;
  
  //ARTICLE 28
  //SCC-ARTICLE 28
  AR28_SCC1_RADIO?: string;
  AR28_SCC1_RADIO_comment?: string;_comment?: string;
  
  AR28_SCC1_ANNEX_1_RADIO?: string;
  AR28_SCC1_ANNEX_1_RADIO_comment?: string;
  
  AR28_SCC1_ANNEX_2_RADIO?: string;
  AR28_SCC1_ANNEX_2_RADIO_comment?: string;
  
  AR28_SCC1_ANNEX_3_RADIO?: string;
  AR28_SCC1_ANNEX_3_RADIO_comment?: string;
  
  AR28_SCC1_ANNEX_4_RADIO?: string;
  AR28_SCC1_ANNEX_4_RADIO_comment?: string;
  
  AR28_SCC1_ANNEX_5_RADIO?: string;
  AR28_SCC1_ANNEX_5_RADIO_comment?: string;
  
  //SCC2
  AR28_SCC2_RADIO?: string;
  AR28_SCC2_RADIO_comment?: string;
  
  AR28_SCC2_ANNEX_1_RADIO?: string;
  AR28_SCC2_ANNEX_1_RADIO_comment?: string;
  
  AR28_SCC2_ANNEX_2_RADIO?: string;
  AR28_SCC2_ANNEX_2_RADIO_comment?: string;
  
  AR28_SCC2_ANNEX_3_RADIO?: string;
  AR28_SCC2_ANNEX_3_RADIO_comment?: string;
  
  //SCHREMS US
  AR28_SCHREMS_US_RADIO?: string;
  AR28_SCHREMS_US_RADIO_comment?: string;
  
  //SCHREMS UK
  AR28_SCHREMS_UK_RADIO?: string;
  AR28_SCHREMS_UK_RADIO_comment?: string;
  
  //SCHREMS INDIA
  AR28_SCHREMS_INDIA_RADIO?: string;
  AR28_SCHREMS_INDIA_RADIO_comment?: string;
  
  //SCHREMS GENERIC
  AR28_SCHREMS_GENERIC_RADIO?: string;
  AR28_SCHREMS_GENERIC_RADIO_comment?: string;
  
  //IaaS
  IAAS_1_RADIO?: string;
  IAAS_1_RADIO_comment?: string;
  
  IAAS_2_RADIO?: string;
  IAAS_2_RADIO_comment?: string;
  
  IAAS_3_RADIO?: string;
  IAAS_3_RADIO_comment?: string;

  IAAS_4_RADIO?: string;
  IAAS_4_RADIO_comment?: string;
}
