import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AdminService } from './admin.service';
import { Demande } from '../homeUser/demandeDla/demande.model';
import { HomeUserService } from '../homeUser/homeUser.service';
import { UserVeolia } from './userveolia.model';
import { UserVendor } from './uservendor.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { filter, catchError } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { ToastrService } from 'ngx-toastr';
import { dictToURI } from '../../app/core/helpers';
import { ValidateSolutionComponent } from './validate-solution.component';
import { FormGroup, FormBuilder } from '@angular/forms';
import firebase from 'firebase/compat/app';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'; //lié à proxy.conf.js
import { throwError } from 'rxjs';
import { SecurityScoreCard } from './securityScoreCard.model';
import { OverviewSolutionComponent } from '../homeUser/formulairesAccess/formulaireAll/overview-solution.component';
import {MatDialog} from '@angular/material/dialog';
import { DemandOverviewModalComponent } from './demand-overview-modal.component';
import { BusinessUnitService } from 'app/services/business-unit.service';
import { BusinessUnit } from 'app/firstConnection/businessUnit.model';
import { User } from 'app/types/user.type';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {

@ViewChild(ValidateSolutionComponent, { static: false }) validateSolutionComponent: ValidateSolutionComponent;
@ViewChild(OverviewSolutionComponent, { static: false }) overviewSolutionComponent: OverviewSolutionComponent;

  //SaaS
  demandesSaaS: Demande[];
  demandesSaaSEvaluees: Demande[];
  demandesSaaSPending: Demande[];

  //IaaS
  demandesIaaS: Demande[]
  demandesIaaSEvaluees: Demande[]
  demandesIaaSPending: Demande[]

  usersVeolia: UserVeolia[];

  usersVendorEnabled: UserVendor[];
  usersVendorDisabled: UserVendor[];

  email: string;

  listVeolia: [];
  listBU: BusinessUnit[] = [];

  searchString: string; //référence name=searchString dans à homePm.html

  adminRoute = true;

  public showOverlay = true;

  //tabs des rôles
  rolesFormVeolia: FormGroup;
  rolesFormVendor: FormGroup;

  uidUser: string; //= idDoc aussi
  businessUnit: string;
  admin: boolean;
  evaluator: boolean;
  vendor: boolean;
  viewer: boolean;
  solutionOwner: boolean;

  //SSC
  observable: any ;
  observable2: any ;
  securityScoreCardData: SecurityScoreCard[];

  serverDataGet: string;
  //serverDataGet: JSON
  companyData: string;
  currentTab: string = 'Article 28 Form';
  userLoading: boolean;

  constructor(
    private route: ActivatedRoute,
    public service: AdminService,
    private router: Router,
    private homeUserServ: HomeUserService,
    private auth: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private buService: BusinessUnitService
  ) {
     //eviter le textcontent null globalSta
    router.events
    .pipe(
      filter((event): event is NavigationStart => event instanceof NavigationStart)
    )
    .subscribe((event:  NavigationStart) => {
      // "event" here is now of type "NavigationStart"
    //   console.log('--ROUTE EVENT START-- ' + event);

      if (event.url !== '/admin') {
        this.adminRoute = true;
      }
    });

    router.events
    .pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    )
    .subscribe((event:  NavigationEnd) => {
      // "event" here is now of type "NavigationEnd"
      if (event.url !== '/admin') {
        this.adminRoute = false;
      }
    });
  }

  //appliquer le datatbale quand on change de tab car par défaut c'est que le 1er tab qui applique le datatable
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    //  console.log('tabChangeEvent => ', tabChangeEvent);
    //  console.log('index => ', tabChangeEvent.index);
    console.log('nom => ', tabChangeEvent.tab.textLabel);
    this.service.getTabName(tabChangeEvent.tab.textLabel);

    this.showOverlay = true;

    //SaaS//
    //SaaS default
    if(tabChangeEvent.tab.ariaLabel === 'SaaStab'){ //par défaut ngOnInit()
      this.tabSaaSAllSolutions() //mat-tab -> aria-label=allSaaSReq (table -> aria-label=All SaaS requests)
    }

    if(tabChangeEvent.tab.ariaLabel === 'allSaaSReq'){
      this.tabSaaSAllSolutions() //mat-tab -> aria-label=allSaaSReq (table -> aria-label=All SaaS requests)
    }

    if(tabChangeEvent.tab.ariaLabel === 'allSaaSEvalReq'){
      this.tabSaaSEvaluatedRequest() //mat-tab -> aria-label=allSaaSEvalReq (table -> aria-label=All SaaS evaluated solutions)
    }

    if(tabChangeEvent.tab.ariaLabel === 'allSaaSPendingReq'){
      this.tabSaaSPendingRequest() //mat-tab -> aria-label=allSaaSPendingReq (table -> aria-label=All SaaS pending solutions)
    }

    //IaaS//
    //IaaS default
    if(tabChangeEvent.tab.ariaLabel === 'IaaStab'){
      this.tabIaaSAllSolutions() //mat-tab -> aria-label=allIaaSReq (table -> aria-label=All IaaS solutions)
    }

    if(tabChangeEvent.tab.ariaLabel === 'allIaaSReq'){
      this.tabIaaSAllSolutions() //mat-tab -> aria-label=allIaaSReq (table -> aria-label=All IaaS solutions)
    }

    if(tabChangeEvent.tab.ariaLabel === 'allIaaSEvalReq'){
      this.tabIaaSEvaluatedRequest() //mat-tab -> aria-label=allIaaSEvalReq (table -> aria-label=All IaaS evaluated solutions)
    }

    if(tabChangeEvent.tab.ariaLabel === 'allIaaSPendingReq'){
      this.tabIaaSPendingRequest() //mat-tab -> aria-label=allIaaSPendingReq (table -> aria-label=All IaaS pending solutions)
    }

    //Users//
    if(tabChangeEvent.tab.ariaLabel === 'allVeoliaUsers'){
      this.tabVeoliaUsers() //mat-tab -> aria-label=allVeoliaUsers (table -> aria-label=All Veolia's users)
    }

    if(tabChangeEvent.tab.ariaLabel === 'allVendors'){
      this.tabVendorEnabledDisabled() //mat-tab -> aria-label=allVendors (table -> aria-label=All vendor's users enabled + table -> aria-label=All vendor's users disabled)
    }

  };

  ngOnInit() {
    this.tabSaaSAllSolutions()//Par défaut

    //this.getSecurityScoreCard("veolia.com");
    this.buService.getBu().then((snapshot) => {
        snapshot.forEach((doc) => {
            let bu = {
              id: doc.id,
              ...(doc.data() as object)
            } as BusinessUnit;
            this.listBU.push(bu);
         });
    })

  }
  //////SCC//////
  ///solutions contractualisées///

//   getSecurityScoreCard(linkUrl){
//       this.getSecuScoreCard(linkUrl).subscribe(data => {
//       console.log("from api: " + data);
//       //console.log("from api: " + data.domain);
//       //this.serverDataGet = data as JSON
//       this.serverDataGet = JSON.parse(data)
//       //this.serverDataGet = data
//       //this.serverDataGet = JSON.stringify(data.grade_url);
//       //return data
//      });
//   }

//   getSecuScoreCard(linkUrl) {
//      return this.httpClient.get('/api/get_company_info_scorecard_summary?link='+linkUrl, {responseType:'text'}).pipe(
//       catchError(this.handleErrorHttp)
//      );
//   }


    //SaaS//
  tabSaaSAllSolutions() {
    //1 tab chargé par défaut
    //mat-tab -> aria-label=allSaaSReq (table -> aria-label=All SaaS solutions)
    //tab des demandesSaaS (sauf celles évaluées et celles pending)
    this.observable = this.service.getAllSaaSRequest().subscribe(actionArray => {
      this.demandesSaaS = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;

      }

      })
    });

    this.delay(4000).then(any => {
        this.observable.unsubscribe();
    });
    //this.ngOnDestroy();
    this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaS.length; i++) {

                if(document.getElementById('globalSta'+i)) {
                 //GLOBAL status
                    if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Empty") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Pending") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                }

                if(document.getElementById('designSta'+i)) {
                //DESIGN status
                    if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Reservations") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Completed") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('designSta'+i).textContent === "New") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Need help") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('legalSta'+i)) {
                //LEGAL status
                    if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Completed") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "New") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Need help") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('article28Sta'+i)) {
                //ARTICLE28 status
                    if (document.getElementById('article28Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Recommended") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Reservations") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Completed") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "New") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Need help") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('article32Sta'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "New") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

    //GET data from securityScoreCard collection
    this.observable2 = this.service.getAllSSCdata().subscribe(actionArray => {
      this.securityScoreCardData = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as SecurityScoreCard;

        }

      })
    });
   }

  tabSaaSEvaluatedRequest() {
  //mat-tab -> aria-label=allSaaSEvalReq (table -> aria-label=All evaluated SaaS solutions)
  //tab demandesSaaSEvaluated évaluées entièrement
    this.observable = this.service.getAllSaaSEvaluatedRequest().subscribe(actionArray => {
      this.demandesSaaSEvaluees = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;

      }

      });

    });

    this.delay(4000).then(any => {
        this.observable.unsubscribe();
    });
    //this.ngOnDestroy();
    this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaSEvaluees.length; i++) {

                if(document.getElementById('globalSta'+i)) {
                 //GLOBAL status
                    if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Empty") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Pending") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                }

                if(document.getElementById('designSta'+i)) {
                //DESIGN status
                    if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Reservations") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Completed") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('designSta'+i).textContent === "New") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Need help") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('legalSta'+i)) {
                //LEGAL status
                    if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Completed") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "New") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Need help") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('article28Sta'+i)) {
                //ARTICLE28 status
                    if (document.getElementById('article28Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Recommended") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Reservations") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Completed") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "New") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Need help") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('article32Sta'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "New") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

    //GET data from securityScoreCard collection
    this.observable2 = this.service.getAllSSCdata().subscribe(actionArray => {
      this.securityScoreCardData = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as SecurityScoreCard;

        }

      })
    });
   }

  tabSaaSPendingRequest() {
  //mat-tab -> aria-label=allSaaSPendingReq (table -> aria-label=All SaaSpending solutions)
  //tab pending requests
    this.observable = this.service.getAllSaaSPendingRequest().subscribe(actionArray => {
      this.demandesSaaSPending = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;

      }

      });

    });

    this.delay(4000).then(any => {
        this.observable.unsubscribe();
    });
    //this.ngOnDestroy();
    this.delay(3000).then(any => {
              for (let i = 0; i < this.demandesSaaSPending.length; i++) {

                if(document.getElementById('globalSta'+i)) {
                 //GLOBAL status
                    if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Empty") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                    if (document.getElementById('globalSta'+i).textContent === "Pending") {
                        document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                    }
                }

                if(document.getElementById('designSta'+i)) {
                //DESIGN status
                    if (document.getElementById('designSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Reservations") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Not recommended") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Completed") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('designSta'+i).textContent === "New") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('designSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('designSta'+i).textContent === "Need help") {
                        document.getElementById('designSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('legalSta'+i)) {
                //LEGAL status
                    if (document.getElementById('legalSta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Reservations") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Not recommended") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Completed") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "New") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Vendor is responding") {
                        document.getElementById('legalSta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('legalSta'+i).textContent === "Need help") {
                        document.getElementById('legalSta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('article28Sta'+i)) {
                //ARTICLE28 status
                    if (document.getElementById('article28Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Recommended") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Reservations") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Completed") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "New") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article28Sta'+i).textContent === "Need help") {
                        document.getElementById('article28Sta'+i).style.backgroundColor = 'black';
                    }
                }

                if(document.getElementById('article32Sta'+i)) {
                //ARTICLE32 status
                    if (document.getElementById('article32Sta'+i).textContent === "DLA is reviewing") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'blue';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#97BF0D';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Reservations") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E09F25';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Not recommended") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#E95F47';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Completed") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'purple';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "New") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#9C9E9F';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Vendor is responding") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = '#54BBBA';
                    }
                    if (document.getElementById('article32Sta'+i).textContent === "Need help") {
                        document.getElementById('article32Sta'+i).style.backgroundColor = 'black';
                    }
                }
              }
                (window as any).initializeDatatable();
                this.showOverlay = false;
            });

    //GET data from securityScoreCard collection
    this.observable2 = this.service.getAllSSCdata().subscribe(actionArray => {
      this.securityScoreCardData = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as SecurityScoreCard;

        }

      })
    });
   }
    //END SaaS//

    //IaaS//
  tabIaaSAllSolutions() {
    //1 tab chargé par défaut
    //mat-tab -> aria-label=allIaaSReq (table -> aria-label=All IaaS solutions)
    //tab des demandesIaaS (sauf celles évaluées et celles pending)
    this.observable = this.service.getAllIaaSRequest().subscribe(actionArray => {
        // if (actionArray) {
        //     console.log('NEW DATA comming')
        // }
      this.demandesIaaS = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;

      }

      })
    });

    this.delay(4000).then(any => {
        this.observable.unsubscribe();
    });
    //this.ngOnDestroy();
    this.delay(3000).then(any => {
          for (let i = 0; i < this.demandesIaaS.length; i++) {

            if(document.getElementById('globalSta'+i)) {
             //GLOBAL status
                if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('globalSta'+i).textContent === "Empty") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
                if (document.getElementById('globalSta'+i).textContent === "Pending") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
            }

            if(document.getElementById('iaasSta'+i)) {
                //IAAS status
                if (document.getElementById('iaasSta'+i).textContent === "DLA is reviewing") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Reservations") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Not recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('iaasSta'+i).textContent === "New") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Completed") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'purple';
                }
            }
          }
            (window as any).initializeDatatable();
            this.showOverlay = false;
        });

    //GET data from securityScoreCard collection
    this.observable2 = this.service.getAllSSCdata().subscribe(actionArray => {
      this.securityScoreCardData = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as SecurityScoreCard;

        }

      })
    });
  }

  tabIaaSEvaluatedRequest() {
  //mat-tab -> aria-label=allIaaSEvalReq (table -> aria-label=All evaluated IaaS solutions)
  //tab demandesIaaS évaluées entièrement
    this.observable = this.service.getAllIaaSEvaluatedRequest().subscribe(actionArray => {
      this.demandesIaaSEvaluees = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;

      }

      });

    });

    this.delay(4000).then(any => {
        this.observable.unsubscribe();
    });
    //this.ngOnDestroy();
    this.delay(3000).then(any => {
          for (let i = 0; i < this.demandesIaaSEvaluees.length; i++) {

            if(document.getElementById('globalSta'+i)) {
             //GLOBAL status
                if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('globalSta'+i).textContent === "Empty") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
                if (document.getElementById('globalSta'+i).textContent === "Pending") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
            }

            if(document.getElementById('iaasSta'+i)) {
                //IAAS status
                if (document.getElementById('iaasSta'+i).textContent === "DLA is reviewing") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Reservations") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Not recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('iaasSta'+i).textContent === "New") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Completed") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'purple';
                }
            }
          }
            (window as any).initializeDatatable();
            this.showOverlay = false;
        });

    //GET data from securityScoreCard collection
    this.observable2 = this.service.getAllSSCdata().subscribe(actionArray => {
      this.securityScoreCardData = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as SecurityScoreCard;

        }

      })
    });
  }

  tabIaaSPendingRequest() {
  //mat-tab -> aria-label=allIaaSPendingReq (table -> aria-label=All IaaS pending solutions)
  //tab demandesIaaS évaluées entièrement
    this.observable = this.service.getAllIaaSPendingRequest().subscribe(actionArray => {
      this.demandesIaaSPending = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as Demande;

      }

      });

    });

    this.delay(4000).then(any => {
        this.observable.unsubscribe();
    });
    //this.ngOnDestroy();
    this.delay(3000).then(any => {
          for (let i = 0; i < this.demandesIaaSPending.length; i++) {

            if(document.getElementById('globalSta'+i)) {
             //GLOBAL status
                if (document.getElementById('globalSta'+i).textContent === "Recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('globalSta'+i).textContent === "Reservations") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('globalSta'+i).textContent === "Not recommended") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('globalSta'+i).textContent === "Empty") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('globalSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('globalSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('globalSta'+i).textContent === "Canceled") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
                if (document.getElementById('globalSta'+i).textContent === "Pending") {
                    document.getElementById('globalSta'+i).style.backgroundColor = 'red';
                }
            }

            if(document.getElementById('iaasSta'+i)) {
                //IAAS status
                if (document.getElementById('iaasSta'+i).textContent === "DLA is reviewing") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'blue';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#97BF0D';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Reservations") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E09F25';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Not recommended") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#E95F47';
                }
                if (document.getElementById('iaasSta'+i).textContent === "New") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#9C9E9F';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Vendor is responding") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = '#54BBBA';
                }
                if (document.getElementById('iaasSta'+i).textContent === "Completed") {
                    document.getElementById('iaasSta'+i).style.backgroundColor = 'purple';
                }
            }
          }
            (window as any).initializeDatatable();
            this.showOverlay = false;
        });

    //GET data from securityScoreCard collection
    this.observable2 = this.service.getAllSSCdata().subscribe(actionArray => {
      this.securityScoreCardData = actionArray.map(item => {

        if(this.adminRoute === true) {

        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as SecurityScoreCard;

        }

      })
    });
  }
    //END IaaS//

  tabVeoliaUsers() {
  //mat-tab -> aria-label=allVeoliaUsers (table -> aria-label=All Veolia's users)
  //tab des users veolia
    this.service.getAllUsersVoelia().subscribe(actionArray => {
      this.usersVeolia = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as UserVeolia;
      });

    });

    this.delay(3000).then(any => {
    (window as any).initializeDatatable();
    this.showOverlay = false;
    });
  }

  tabVendorEnabledDisabled(){
    //mat-tab -> aria-label=allVendors (table -> aria-label=All vendor's users enabled + table -> aria-label=All vendor's users disabled)
    //tab des users vendor activé
    this.service.getAllUsersVendorEnabled().subscribe(actionArray => {
      this.usersVendorEnabled = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as UserVendor;
      });
    });

    this.service.getAllUsersVendorDisabled().subscribe(actionArray => {
      this.usersVendorDisabled = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...(item.payload.doc.data() as object)
        } as UserVendor;
      });
    });

    this.delay(3000).then(any => {
    (window as any).initializeDatatable();
    this.showOverlay = false;
    });
  }


  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  /////////FORMULAIRES/////////

  //Formulaire DESIGN
  gotoDesignForm(id: string) {
    this.homeUserServ.initializeDesignForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire LEGAL
  gotoLegalForm(id: string) {
    this.homeUserServ.initializeLegalForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 32
  gotoArticle32Form(id: string) {
    this.homeUserServ.initializeArticle32Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 28
  gotoArticle28Form(id: string) {
    this.homeUserServ.initializeArticle28Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //IaaS
  gotoIaaSForm(id: string) {
    this.homeUserServ.initializeIaaSForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Demande séléctionée dans le tableau pour afficher ses infos dans une autre pages avec redirection des données
  modifierDemande(id: string, type: string) {
    this.router.navigate(['/editDemandeDla/' + id + '/' + type]); //{ this.router.navigate(['/details/'+ item.payload.doc.id]);}
  }

  //Redirection validate solution
  goValidateSolution(id: string, type: string) {
    this.router.navigate(['/validateSolution/' + id + '/' + type]); //{ this.router.navigate(['/details/'+ item.payload.doc.id]);}
  }

  //redirection vers la page de création du compte pour le vendor
  creationCompteVendor(id: string, type: string) {
    this.router.navigate(['/gestionVendor/' + id + '/' + type]);
  }

  //redirection sur le tableau de synthese des forms avec l'éval actuelle par form
  tableauEvals(id: string, type: string) {
    this.router.navigate(['/tableauEvals/' + id + '/' + type]);
  }

  //redirection vers la page des logs pour la demande
  logs(id: string, type: string) {
    this.router.navigate(['/logs/' + id + '/' + type]);
  }

  //demande d'évaluation avortée
  abortSolution(id: string, type: string) {
    this.service.cancelSol(id,type);
  }

  //demande de nouveau dispo
  rollbackCancel(id: string) {
      this.service.uncancel(id);
  }

  //envoyer un email de reset password firebase au user
  sendResetPass(email: string) {
      this.auth.resetPassword(email);
  }


  //desactiver le compte firebase + Onelogin d'un utilisateur vendor
  disableAccount(id: string, email: string) {
    if (confirm(`Would you really disable ${email} account ?`)) {
      //Desactivation du compte firebase
      this.service.disableAccountFirebase(id);
      //Desactivation du compte Onelogin
      this.service.disableAccountOnelogin(email);
      this.toastr.info(`Account ${email} disabled successfuly`, '', {
        timeOut: 7000,
        progressBar: true,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      });
    } else { //si cancel cliqué
        console.log('Not clicked !')
    }
  }

  //reactiver le compte firebase + Onelogin d'un utilisateur vendor
  enableAccount(id: string, email: string) {
    if (confirm(`Would you really enable ${email} account ?`)) {
      //Desactivation du compte firebase
      this.service.enableAccountFirebase(id);
      //Desactivation du compte Onelogin
      this.service.enableAccountOnelogin(email);
      this.toastr.info(`Account ${email} enabled successfuly`, '', {
        timeOut: 7000,
        progressBar: true,
        closeButton: true,
        positionClass: 'toast-bottom-center'
      });
    } else { //si cancel cliqué
        console.log('Not clicked !')
    }
  }

    //appelée via clic du bouton dans le tableau veolia ou vendor
      getRolesForUser(id: string) {

        const fire = firebase.firestore();
        this.userLoading = true;
        const userRef = fire.collection('users').doc(id);
         userRef
          .get()
          .then(doc => {
            if (!doc.exists) {
              console.log('No such document!');
            } else {
              this.email = doc.data().email;
              this.uidUser = doc.data().uid;
              this.businessUnit = doc.data().businessUnit;

              this.admin = doc.data().roles.admin;
              this.evaluator = doc.data().roles.evaluator;
              this.viewer = doc.data().roles.viewer;
              this.vendor = doc.data().roles.vendor;
              this.solutionOwner = doc.data().roles.solutionOwner;

              this.createForm();
            }
            this.userLoading = false;
          })
          .catch(err => {
            this.userLoading = false;
            console.log('Error getting document', err);
          });
      }

      createForm() {
        this.rolesFormVeolia = this.fb.group({
          businessUnit: [''],
          admin: [this.admin],
          evaluator: [this.evaluator],
          vendor: [this.vendor], //always false
          viewer: [this.viewer],
          solutionOwner: [this.solutionOwner],
        });

        this.rolesFormVeolia.controls.businessUnit.setValue(this.businessUnit);

        this.rolesFormVendor = this.fb.group({
          admin: [this.admin], //always false
          evaluator: [this.evaluator], //always false
          vendor: [this.vendor],
          viewer: [this.viewer], //always false
          solutionOwner: [this.solutionOwner], //always false
        });
      }

      //Save changement de rôle
      onSubmit(value) {
        this.service.updateRoles(this.uidUser, value);

        if (this.businessUnit != value.businesUnit) {
            const oldBU = this.listBU.find(el => el.nomBu === this.businessUnit);
            const newBU = this.listBU.find(el => el.nomBu === value.businessUnit);
            this.service.removeFromBUEvaluators(oldBU.id, this.email);
            if (value.evaluator) {
                this.service.updateBUEvaluators(newBU.id, this.email);
            }
        }
      }

    //SSC
    //1 add company to Veolia's Portfolio//
    fetchRefreshSecurityScoreCard(idSol, linkCom, solutionName) {
        this.showOverlay = true;

        this.addCompanyToPortfolio(linkCom).subscribe(data => {
          console.log("addCompanyToPortfolio from api: " + data);
          this.companyData = JSON.parse(data)
        });

        this.delay(3500).then(any => {
          //2 get added company score card with metadata
          this.getCompanyScorecard(linkCom).subscribe(data => {
            console.log("getCompanyScorecard from api: " + data);
            this.companyData = JSON.parse(data)

            //2.1 Add company metadata in firestore securityScoreCard collection
            this.service.addCompanyToFirestore(idSol, solutionName, linkCom, this.companyData);
          });
        }).then(res => {
            this.showOverlay = false;

            this.toastr.success("Security Score Card added/updated for " + solutionName, '', {
               timeOut: 9000,
               closeButton: true,
               progressBar: true,
               positionClass: 'toast-bottom-center'
             });
        });
    }

    //SSC
  //1 add company to Veolia's Portfolio//
  addCompanyToPortfolio(linkCom) {
     const query = dictToURI({
        link: linkCom
     });
     return this.httpClient.get('/api/add_company_to_portfolio?' + query, {responseType:'text'}).pipe(
       catchError(this.handleErrorHttp)
     );
   }

  //2 get added company score card with metadata
  getCompanyScorecard(linkCom) {
     const query = dictToURI({
          link: linkCom
     });
     return this.httpClient.get('/api/get_company_info_scorecard_summary?' + query, {responseType:'text'}).pipe(
       catchError(this.handleErrorHttp)
     );
   }

   public handleErrorHttp(error: HttpErrorResponse) {
     if (error.status === 0) {
       // A client-side or network error occurred. Handle it accordingly.
       console.error('An error occurred:', error.error);
     } else { //status code 500 ou 401 ou 400
         // The backend returned an unsuccessful response code.
         // The response body may contain clues as to what went wrong.
         this.toastr.error('Error', 'Error', {
               timeOut: 9000,
               closeButton: true,
               progressBar: true,
               positionClass: 'toast-bottom-center'
             });
         console.error(
           `Backend returned code ${error.status}\n, ` +
           `body was: ${error.error}\n`);
     }
     // Return an observable with a user-facing error message.
     return throwError(
       'API request failed.');
     }

  openDialog(demandId: string) {
    const dialogRef = this.dialog.open(DemandOverviewModalComponent, { data: { demandId }});

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
