<div class="container">
  <div *ngIf="(auth.user | async) as user">
    
  <mat-card>
    <mat-card-content>
      
        <!-- Modal evaluation overview au clic du statut -->
        <!-- Modale pour le recap de l'evaluation des chapitres -->
        <div
          class="modal fade"
          id="modalSummaryEvaluation"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          style="margin-top:60px"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 510px;height: 470px;overflow-y: auto; text-align:center">
              <div class="modal-header" id="headerEvaluation">
                <h5 style="color:#00aec7;">
                  {{ 'Evaluation overview for' | translate }} {{ solutionName }}
                </h5>
                <button class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" id="bodyEvaluation" style="width:500px;">
                <div class="container" style="display:inline"><app-summary-evaluation></app-summary-evaluation></div>
              </div>
              <!-- END Body -->
            </div>
          </div>
        </div>
        <!-- END MODALE -->
        
        <!-- Modal des reserves au clic du bouton -->
        <!-- Modale pour le recap des reserves des chapitres/sous chap -->
        <div
          class="modal fade"
          id="modalReservationsEvaluation"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          style="margin-top:60px"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 510px;height: 570px;overflow-y: auto; text-align:center">
              <div class="modal-header" id="headerReservation">
                <h5 style="color:#00aec7;">
                  {{ 'List of reservations for' | translate }} {{ solutionName }}
                </h5>
                <button class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" id="bodyReservation" style="width:500px;">
                <div class="container" style="display:inline"><app-reservations-evaluation></app-reservations-evaluation></div>
              </div>
              <!-- END Body -->
            </div>
          </div>
        </div>
        <!-- END MODALE -->
        
        <!-- Modal liste reserves au clic du bouton -->
        <!-- Modale pour le recap des reserves des chapitres/sous chap -->
        <div
          class="modal fade"
          id="modalReservationsListDetails"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          style="margin-top:60px"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 510px;height: 570px;overflow-y: auto; text-align:center">
              <div class="modal-header" id="headerReservation">
                <h5 style="color:#00aec7;">
                  {{ 'List of reservations for' | translate }} {{ solutionName }}
                </h5>
                <button class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" id="bodyReservation" style="width:500px;">
                <div class="container" style="display:inline"><app-reservations-list></app-reservations-list></div>
              </div>
              <!-- END Body -->
            </div>
          </div>
        </div>
        <!-- END MODALE -->
        
        <!-- Modal solution and form overview appelée par le a en dessous -->
                  <div
                    class="modal fade"
                    id="modalSummarySolution"
                    tabindex="-1"
                    role="dialog"
                    aria-hidden="true"
                    style="margin-top:60px"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content" style="width: max-content;height: 470px;overflow-y: auto;">
                        <div class="modal-header" id="headerOverview">
                          <h5 style="color:#00aec7;">
                            {{ 'Solution and form overview for' | translate }} {{ solutionName }}
                          </h5>
                          <button class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body" id="bodyOverview" style="width:500px;">
                          <div class="container" style="display:inline; text-align:center">
                              <app-summary-solution></app-summary-solution
                              >
                          </div>
                        </div>
                        <!-- END Body -->
                      </div>
                    </div>
                  </div>
                  <!-- END MODALE -->
        
        <!-- Modal légende -->
        <div
          class="modal fade"
          id="modalLegend"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          style="margin-top:60px"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="width: 500px;height: 570px;overflow-y: auto; text-align:center">
              <div class="modal-header" >
                <h5 style="color:#00aec7;">
                  {{ 'Legend and color code' | translate }}
                </h5>
                <button class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="width:470px;">
                <div class="container" style="display:inline; text-align:left">
                  <p *ngIf="auth.isAdmin(user) || auth.isEvaluator(user)">{{'tableauEvalsInfoVeolia' | translate}} 
                    <a target="_blank" routerLink="/formulaireAll/designForm/{{idSolution}}" translate>here</a>
                    <br />
                  </p>
                  <p *ngIf="auth.isVendor(user)">{{'tableauEvalsInfoVendor' | translate}} 
                  <br />
                  </p>
                  
                  <ul>
                    <li><span class="material-icons" style="color: blue"> check_circle </span>: {{'DLA is reviewing color' | translate}}</li>
                    <li><span class="material-icons" style="color: green"> check_circle </span>: {{'green color' | translate}}</li>
                    <li><span class="material-icons" style="color: orange"> check_circle </span>: {{'orange color' | translate}}</li>
                    <li><span class="material-icons" style="color: red"> check_circle </span>: {{'red color' | translate}}</li>
                    <li><span class="material-icons" style="color: black"> check_circle </span>: {{'black color' | translate}}</li>
                  </ul>
                  <hr>
                  
                  <p translate>In '{{'Reservations overview' | translate}}':</p>
                  <p style="color:red">{{'x.x-The chapter name' | translate}}<em class="fas fa-exclamation-triangle"></em></p>: {{'case1' | translate}}
                  <p style="color:orange">{{'x.x-The chapter name' | translate}}<em class="fas fa-exclamation-triangle"></em></p>: {{'case2' | translate}}
                  <p style="color:green">{{'x.x-The chapter name' | translate}}</p>: {{'case3' | translate}}
                </div>
              </div>
              <!-- END Body -->
            </div>
          </div>
        </div>
        <!-- END MODALE -->
        
        <!--SaaS-->
        <div *ngIf="type === 'SaaS'">
          
          <mat-card-title style="color:#00aec7; text-align:center">{{ 'Summary of the evaluations of the forms for' | translate }} {{item.type}}
            
            <span style="cursor: pointer; text-decoration: underline" [matMenuTriggerFor]="formsSelection" title="Select the form">
              {{ solutionName }}
            </span>
            <mat-menu #formsSelection="matMenu">
              <div *ngIf="(userIsInEmailEvaluatorAuthorized || userIsInEmailVeoliaAuthorized || userIsInEmailVendorAuthorized || userIsInEvaluatorsBu || auth.isAdmin(user) || user?.businessUnit === businessUnitOfSolution) 
                && (item.status !== 'Pending' && item.status !== 'Canceled')" style="display: contents">
                <a
                  class="btn text-primary"
                  (click)="gotoDesignForm(idSolution)"
                  >Design</a
                >
                <a
                  class="btn text-primary"
                  (click)="gotoLegalForm(idSolution)"
                  >Legal</a
                >
                <a
                  class="btn text-primary"
                  (click)="gotoArticle32Form(idSolution)"
                  >Article 32</a
                >
                <a
                  class="btn text-primary"
                  (click)="gotoArticle28Form(idSolution)"
                  >Annexes</a
                >
                </div>
              <div *ngIf="item.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                  <a
                    class="btn text-primary"
                    >Design</a
                  >
                  <a
                    class="btn text-primary"
                    >Legal</a
                  >
                 <a
                  class="btn text-primary"
                  >Article 32</a
                 >
                <a
                  class="btn text-primary"
                  >Annexes</a
                >
                <div class="overlay" style="visibility:hidden">
                  <p style="color:red" translate>SolutionPending</p>
                </div>
                </div>
              <div *ngIf="auth.isAdmin(user) && item.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                  <a
                    class="btn text-primary"
                    (click)="gotoDesignForm(idSolution)"
                    >Design</a
                  >
                  <a
                    class="btn text-primary"
                    (click)="gotoLegalForm(idSolution)"
                    >Legal</a
                  >
                  <a
                    class="btn text-primary"
                    (click)="gotoArticle32Form(idSolution)"
                    >Article 32</a
                  >
                  <a
                   class="btn text-primary"
                    (click)="gotoArticle28Form(idSolution)"
                    >Annexes</a
                  >
                  </div>
            </mat-menu>
            
            <span class="material-icons" style="cursor: pointer; margin-left: 5px;" [matMenuTriggerFor]="options" title="Actions...">
              more_horiz
            </span>
            <mat-menu #options="matMenu">
                              <div *ngIf="(userIsInEmailEvaluatorAuthorized || userIsInEmailVeoliaAuthorized || userIsInEmailVendorAuthorized || userIsInEvaluatorsBu || auth.isAdmin(user) || user?.businessUnit === businessUnitOfSolution) && (item.status !== 'Pending' && item.status !== 'Canceled')">
                                <a *ngIf="(auth.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu)"
                                  class="btn text-primary"
                                  (click)="modifierDemande(idSolution,'SaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(idSolution,'SaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(idSolution,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div *ngIf="(auth.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu) && item.status === 'Pending'">
                                <a
                                  class="btn text-primary"
                                  (click)="modifierDemande(idSolution,'SaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                              </div>
                              <div *ngIf="(auth.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu) && item.status === 'Canceled'">
                                <a
                                  class="btn text-primary"
                                  (click)="logs(idSolution,'SaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </mat-menu>
                            
          </mat-card-title>

          <table class="table table-hover" aria-label="Evaluations status">
            <thead style="text-align:center">
              <th scope="col" translate>
                {{ 'Design Form' | translate }} <span class="material-icons" id="designIcon"> check_circle </span>
              </th>
              <th scope="col" translate>
                {{ 'Legal Form' | translate }} <span class="material-icons" id="legalIcon"> check_circle </span>
              </th>
              <th scope="col" translate>
                {{ 'Article 32' | translate }} <span class="material-icons" id="article32Icon"> check_circle </span>
              </th>
              <th scope="col" translate>
                {{ 'Annexes' | translate }} <span class="material-icons" id="article28Icon"> check_circle </span>
              </th>
            </thead>
            <tbody>
              <tr style="text-align: center;">
                
                <td>
                  <a class="btn text-primary"
                    style="cursor: pointer;"
                      data-toggle="modal"
                      data-target="#modalSummarySolution"
                      title="Voir le récupitulatif de la demande"
                      (click)="summarySolutionComponent.getDataRealDb('Design Form')"
                      onclick="modalMovable()"
                      translate>solution and form overview</a
                    >
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('Design Form');
                      summaryEvaluationComponent.getFilesForEvaluation('Design Form')
                    "
                    onclick="getColorsChapters('Design Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="designText"
                    >{{ statusDesignForm }}
                  </a>
                  
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('Design Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}</a>
                  
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('Design Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}</a>

                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('Design Form');
                      summaryEvaluationComponent.getFilesForEvaluation('Design Form')
                    "
                    onclick="getColorsChapters('Design Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="designText"
                    >{{ statusDesignForm }}
                  </a>
                   
                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('Design Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}</a>
                  
                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('Design Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}
                  </a>
                </td>

                <td>
                  <a class="btn text-primary"
                    style="cursor: pointer;"
                      data-toggle="modal"
                      data-target="#modalSummarySolution"
                      title="Voir le récupitulatif de la demande"
                      (click)="summarySolutionComponent.getDataRealDb('Legal Form')"
                      onclick="modalMovable()"
                      translate>solution and form overview</a
                    >
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('Legal Form');
                      summaryEvaluationComponent.getFilesForEvaluation('Legal Form')
                    "
                    onclick="getColorsChapters('Legal Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="legalText"
                    >{{ statusLegalForm }}
                  </a>
                  
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('Legal Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}
                  </a>
                  <br/>
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('Legal Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}
                  </a>

                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('Legal Form');
                      summaryEvaluationComponent.getFilesForEvaluation('Legal Form')
                    "
                    onclick="getColorsChapters('Legal Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="legalText"
                    >{{ statusLegalForm }}
                  </a>
                  
                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('Legal Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}
                  </a>
                  
                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('Legal Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}
                  </a>
                </td>
                
                <td>
                  <a class="btn text-primary"
                    style="cursor: pointer;"
                      data-toggle="modal"
                      data-target="#modalSummarySolution"
                      title="Voir le récupitulatif de la demande"
                      (click)="summarySolutionComponent.getDataRealDb('Article 32 Form')"
                      onclick="modalMovable()"
                      translate>solution and form overview</a
                    >
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('Article 32 Form');
                      summaryEvaluationComponent.getFilesForEvaluation('Article 32 Form')
                    "
                    onclick="getColorsChapters('Article 32 Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="article32Text"
                    >{{ statusArticle32Form }}
                  </a>
                  
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('Article 32 Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}
                  </a>
                  <br/>
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('Article 32 Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}
                  </a>

                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('Article 32 Form');
                      summaryEvaluationComponent.getFilesForEvaluation('Article 32 Form')
                    "
                    onclick="getColorsChapters('Article 32 Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="article32Text"
                    >{{ statusArticle32Form }}
                  </a>
                  
                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('Article 32 Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}
                  </a>
                  
                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('Article 32 Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}
                  </a>
                </td>
                
                <td>
                  <a class="btn text-primary"
                    style="cursor: pointer;"
                      data-toggle="modal"
                      data-target="#modalSummarySolution"
                      title="Voir le récupitulatif de la demande"
                      (click)="summarySolutionComponent.getDataRealDb('Article 28 Form')"
                      onclick="modalMovable()"
                      translate>solution and form overview</a
                    >
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('Article 28 Form');
                      summaryEvaluationComponent.getFilesForEvaluation('Article 28 Form')
                    "
                    onclick="getColorsChapters('Article 28 Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="article28Text"
                    >{{ statusArticle28Form }}
                  </a>
                  
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('Article 28 Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}
                  </a>
                  <br/>
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('Article 28 Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}
                  </a>

                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('Article 28 Form');
                      summaryEvaluationComponent.getFilesForEvaluation('Article 28 Form')
                    "
                    onclick="getColorsChapters('Article 28 Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="article28Text"
                    >{{ statusArticle28Form }}
                  </a>
                  
                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('Article 28 Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}
                  </a>
                  
                  <a
                    *ngIf="auth.isVendor(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('Article 28 Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}
                  </a>
                </td>
                
              </tr>
              
            </tbody>
          </table>
        </div>
        <!--END SaaS-->
        
        <!--IaaS-->
        <div *ngIf="type === 'IaaS'">
          
          <mat-card-title style="color:#00aec7; text-align:center">{{ 'Summary of the evaluations of the forms for' | translate }} {{item.type}}
            
            <span style="cursor: pointer; text-decoration: underline" [matMenuTriggerFor]="formsSelection" title="Select the form">
              {{ solutionName }}
            </span>
            <mat-menu #formsSelection="matMenu">
              <div *ngIf="(userIsInEmailEvaluatorAuthorized || userIsInEmailVeoliaAuthorized || userIsInEvaluatorsBu || auth.isAdmin(user) || user?.businessUnit === businessUnitOfSolution) 
                && (item.status !== 'Pending' && item.status !== 'Canceled')" style="display: contents">
                <a
                  class="btn text-primary"
                  (click)="gotoIaaSForm(idSolution)"
                  >IaaS</a
                >
                </div>
              <div *ngIf="item.status === 'Pending'" class="pending" style="opacity: 40%;" onmouseover="mouseOver2()">
                  <a
                    class="btn text-primary"
                    >IaaS</a
                  >
                <div class="overlay" style="visibility:hidden">
                  <p style="color:red" translate>SolutionPending</p>
                </div>
                </div>
              <div *ngIf="auth.isAdmin(user) && item.status === 'Canceled'" class="Canceled" style="opacity: 40%;" onmouseover="mouseOver3()">
                  <a
                    class="btn text-primary"
                    (click)="gotoIaaSForm(idSolution)"
                    >IaaS</a
                  >
                  </div>
            </mat-menu>
            
            <span class="material-icons" style="cursor: pointer; margin-left: 5px;" [matMenuTriggerFor]="options" title="Actions...">
              more_horiz
            </span>
            <mat-menu #options="matMenu">
                              <div *ngIf="(userIsInEmailEvaluatorAuthorized || userIsInEmailVeoliaAuthorized || userIsInEvaluatorsBu || auth.isAdmin(user) || user?.businessUnit === businessUnitOfSolution) && (item.status !== 'Pending' && item.status !== 'Canceled')">
                                <a *ngIf="(auth.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu)"
                                  class="btn text-primary"
                                  (click)="modifierDemande(idSolution,'IaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                                <a
                                  class="btn text-warning"
                                  (click)="creationCompteVendor(idSolution,'IaaS')"
                                  title="Access rights for the solution/request"
                                  ><em class="material-icons btnShare"> share </em></a
                                >
                                <a
                                  class="btn text-primary"
                                  (click)="logs(idSolution,'IaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                              <div *ngIf="(auth.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu) && item.status === 'Pending'">
                                <a
                                  class="btn text-primary"
                                  (click)="modifierDemande(idSolution,'IaaS')"
                                  title="Edit the request/solution"
                                  ><em class="material-icons btnEdit"> edit </em></a
                                >
                              </div>
                              <div *ngIf="(auth.isAdmin(user) || isSoOfSolution || userIsInEvaluatorsBu) && item.status === 'Canceled'">
                                <a
                                  class="btn text-primary"
                                  (click)="logs(idSolution,'IaaS')"
                                  title="Logs of forms from the request/solution"
                                  ><em class="material-icons btnLogs"> format_list_bulleted </em></a
                                >
                              </div>
                            </mat-menu>
                            
          </mat-card-title>
          
          <table class="table table-hover" aria-label="Evaluations status">
            <thead style="text-align:center">
              <th scope="col" translate>
                {{ 'IaaS Form' | translate }} <span class="material-icons" id="iaasIcon"> check_circle </span>
              </th>
            </thead>
            <tbody>
              <tr style="text-align: center;">
                
                <td>
                  <a class="btn text-primary"
                    style="cursor: pointer;"
                      data-toggle="modal"
                      data-target="#modalSummarySolution"
                      title="Voir le récupitulatif de la demande"
                      (click)="summarySolutionComponent.getDataRealDb('IaaS Form')"
                      onclick="modalMovable()"
                      translate>solution and form overview
                    </a>
                    
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalSummaryEvaluation"
                    (click)="
                      summaryEvaluationComponent.getEvaluation('IaaS Form');
                      summaryEvaluationComponent.getFilesForEvaluation('IaaS Form')
                    "
                    onclick="getColorsChapters('IaaS Form'); modalMovable()"
                    title="Voir l'ensemble de l'évaluation" id="iaasText"
                    >{{ statusIaaSForm }}
                  </a>
                  
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsEvaluation"
                    (click)="reservationsEvaluationComponent.checkOutChaptersWithReservation('IaaS Form');
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations overview' | translate}}</a>
                  
                  <a
                    *ngIf="auth.isVeolia(user)"
                    class="btn text-primary"
                    data-toggle="modal"
                    data-target="#modalReservationsListDetails"
                    (click)="reservationsListComponent.checkOutChaptersWithReservation('IaaS Form',solutionName);
                    "
                    onclick="modalMovable()"
                    title="Voir l'ensemble des réserves" translate>{{'Reservations list overview' | translate}}</a>
                   
                </td>

              </tr>
              
            </tbody>
          </table>
        </div>
        <!--END IaaS-->
      
    </mat-card-content>
  </mat-card>

<button mat-raised-button
              class="submit-button legend"
              color="primary"
              data-toggle="modal"
                    data-target="#modalLegend"
                    onclick="modalMovable()" >{{'Legend' | translate}}</button>

    </div>
    
</div>
