<div class="container">
  <mat-card>
    <mat-card-content>
      <h2 translate>Formulaire de contact</h2>
      <br />

      <div *ngIf="(auth.user | async) as user">

        <details close>
          <summary class="chapitres">Contact us about a solution/request or about an other subject ?</summary>
          <br />

          <div *ngIf="auth.isViewer(user)" style="display: grid">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm(contactForm.value)" id="formulaire">
              <div class="container-form">
                <mat-form-field>
                  <mat-label translate>nom complet</mat-label>
                  <input
                    matInput
                    placeholder="{{'nom complet' |translate}}"
                    formControlName="displayName"
                    class="form-control"
                    readonly
                  />
                </mat-form-field>

                <mat-form-field>
                  <mat-label translate>email</mat-label>
                  <input
                    matInput
                    placeholder="{{'email' |translate}}"
                    formControlName="email"
                    class="form-control"
                    readonly
                  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label translate>Demande concernée</mat-label>
                  <select
                    matNativeControl
                    formControlName="request"
                    class="form-control"
                  >
                    <option selected translate>Veuillez choisir</option>
                    <option *ngFor="let demandesUser of allRequestsVeolia" translate>
                      {{ demandesUser.solutionName }}
                    </option>
                    <option>Bug report</option>
                    <option translate>Other</option>
                  </select>
                  <span
                      class="text-danger"
                      *ngIf="(f.request.touched || submitted) && f.request.errors?.required"
                      >
                      <em style="font-size: small;">Required</em>
                      </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label translate>Object</mat-label>
                  <input
                    matInput
                    placeholder="{{'Object' |translate}}"
                    formControlName="subject"
                    class="form-control"
                    translate
                  />
                   <span
                      class="text-danger"
                      *ngIf="(f.subject.touched || submitted) && f.subject.errors?.required"
                      >
                      <em style="font-size: small;">Subject required</em>
                      </span>
                </mat-form-field>

                <mat-form-field hintLabel="Max 500 characters" appearance="outline">
                  <mat-label translate>Text</mat-label>
                  <textarea
                    matInput
                    placeholder="{{'Text' |translate}}"
                    formControlName="content"
                    class="form-control"
                    translate
                    rows="5"
                    cols="33"
                  ></textarea>
                     <span
                      class="text-danger"
                      *ngIf="(f.content.touched || submitted) && f.content.errors?.required"
                      >
                      <em style="font-size: small;">Content required</em>
                      </span>
                </mat-form-field>

                <div class="example-button-row">
                  <button mat-raised-button color="primary">{{ 'Envoyer' | translate }}</button>
                </div>
              </div>
            </form>
          </div>

          <div *ngIf="auth.isVendor(user)" style="display: grid">
            <form [formGroup]="contactForm2" (ngSubmit)="onSubmitContactForm2(contactForm2.value)" id="formulaire">
              <div class="container-form">
                <mat-form-field appearance="outline">
                  <mat-label translate>nom</mat-label>
                  <input
                    matInput
                    placeholder="{{'nom' |translate}}"
                    formControlName="lastname"
                    class="form-control"
                  />
                  <span
                      class="text-danger"
                      *ngIf="(v.lastname.touched || submitted) && v.lastname.errors?.required"
                      >
                      <em style="font-size: small;">Lastname required</em>
                      </span>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label translate>prenom</mat-label>
                  <input
                    matInput
                    placeholder="{{'prenom' |translate}}"
                    formControlName="firstname"
                    class="form-control"
                  />
                  <span
                      class="text-danger"
                      *ngIf="(v.firstname.touched || submitted) && v.firstname.errors?.required"
                      >
                      <em style="font-size: small;">Firstname required</em>
                      </span>
                </mat-form-field>

                <mat-form-field>
                  <mat-label translate>email</mat-label>
                  <input
                    matInput
                    placeholder="{{'email' |translate}}"
                    formControlName="email"
                    class="form-control"
                    readonly
                  />
                </mat-form-field>


                  <mat-form-field appearance="outline">
                  <mat-label translate>Demande concernée</mat-label>
                  <select
                    matNativeControl
                    formControlName="request"
                    class="form-control"
                  >
                    <option selected translate>Veuillez choisir</option>
                    <option *ngFor="let demandesUser of allRequestsVendor" translate>
                      {{ demandesUser.solutionName
                      }}
                      </option>
                    <option>Bug report</option>
                    <option translate>Other</option>
                  </select>
                     <span
                      class="text-danger"
                      *ngIf="(v.request.touched || submitted) && v.request.errors?.required"
                      >
                      <em style="font-size: small;">Required</em>
                      </span>
                  </mat-form-field>


                <mat-form-field appearance="outline">
                  <mat-label translate>Object</mat-label>
                  <input
                    matInput
                    placeholder="{{'Object' |translate}}"
                    formControlName="subject"
                    class="form-control"
                    translate
                  />
                  <span
                      class="text-danger"
                      *ngIf="(v.subject.touched || submitted) && v.subject.errors?.required"
                      >
                      <em style="font-size: small;">Subject required</em>
                      </span>
                </mat-form-field>

                <mat-form-field hintLabel="Max 500 characters" appearance="outline">
                  <mat-label translate>Text</mat-label>
                  <textarea
                    matInput
                    #input
                    placeholder="{{'Text' |translate}}"
                    formControlName="content"
                    class="form-control"
                    translate
                    rows="5"
                    cols="33"
                  ></textarea>
                  <mat-hint align="end">{{ input.value?.length || 0 }}/500</mat-hint>
                  <span
                      class="text-danger"
                      *ngIf="(v.content.touched || submitted) && v.content.errors?.required"
                      >
                      <em style="font-size: small;">Content required</em>
                      </span>
                </mat-form-field>

                <div class="example-button-row">
                  <button mat-raised-button color="primary">{{ 'Envoyer' | translate }}</button>
                </div>
              </div>
            </form>
        </div>

        </details>

        <details *ngIf="auth.isViewer(user)" close>
          <summary class="chapitres">Report an incident on the application</summary>
          <br />

          <a rel="noopener" href="https://veglobaltest.service-now.com/dlaaas" target="_blank">Open a tiket here</a>

        </details>

      </div>
    </mat-card-content>
  </mat-card>
</div>
