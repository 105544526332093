<!-- Page Principale de présentation qui ne s'affiche que lorsque on n'est pas connecté -->
<div class="my-overlay" *ngIf="showOverlay"><em class="fa fa-spinner fa-spin" aria-hidden="true"></em></div>

<div *ngIf="(auth.user | async); then: authenticated; else: guest"><!-- template will replace this div --></div>

<!-- User NOT logged in -->
<ng-template #guest>
  <div class="container">
  
  <!--Page home content -->
  <section>
    
    <mat-card class="matcardleft">
      <mat-card-content class="cardcontent">
        <!-- Présentation -->
        <mat-card-title translate>Design & Legal Assessment as a Service</mat-card-title>
        <br />
        <br />
        <br />
        <strong class="description" translate>app_description</strong>

      </mat-card-content>
    </mat-card>
    
    <mat-card class="matcardright">
      <mat-card-content->

        <div *ngIf="!(auth.user | async); else: alreadyLoggedIn">
          
          <!--<p class="card-text" style="font-size: initial; color: red;" translate>Vous devez être connecté !</p>-->
          
            <strong translate style="font-size:1.5em">Sign in</strong>
            <!--<button mat-icon-button [matMenuTriggerFor]="languageMenu" style="float:right">-->
            <!--<mat-icon>language</mat-icon>-->
            <!--</button>-->
            <!--<mat-menu #languageMenu="matMenu">-->
            <!--        <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">{{ language }}</button>-->
            <!--</mat-menu>-->
            <br />
            <br />
            
              <p>For <strong>Veolia's</strong> users</p>
              <!-- Google connexion vendors + veolia-->
              <button mat-raised-button
                (click)="auth.googleLogin()"
                class="btnGoogle" 
                style="padding-left: 30px; padding-right: 30px; font-size: 1.1em;"
              >
                <em class="fa fa-google" style="margin-right: 5px;"></em>{{ 'Sign in with Google' | translate }}
              </button>
              <br />
              <br />
              
              <p>For <strong>external's</strong> users</p>
              <!-- Email/mdp connexion vendors seulement -->
              <!-- Button trigger modal code dans header.html -->
              <button mat-raised-button
                class="btnVendor"
                data-toggle="modal"
                data-target="#exampleModal"
                 style="font-size: 1.1em;"
              >
                <em class="fa fa-user-circle-o" style="margin-right: 5px;"></em>{{ 'Email/pass login' | translate }}
              </button>

        </div>
        <br />
        
        <div>
          <img src="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/DLA.jpg?alt=media&token=8b35f1db-8a50-4f60-90cb-fcbfabf9eaae" alt="Design & Legal Assessment">
          <img src="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/TC2.png?alt=media&token=e6e5ff0e-5ade-4c40-95f1-01633af1399d" alt="Technology Compliance & Cybersecurity">
        </div>
        
        <ng-template #alreadyLoggedIn>
          <p class="text-success">You are logged in <em class="fa fa-thumbs-up"></em></p>
        </ng-template>
        
      </mat-card-content->
    </mat-card>
    
  </section><!--END Page home content -->
  
  </div>
</ng-template>

<!-- authenticated n'est pas vu par le user car on redirige direct sur homeUser -->
<ng-template #authenticated>
  <div class="container">
    <mat-card>
      <mat-card-content>
      <!--Empty-->
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
