import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import firebase from 'firebase/compat/app';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class GuardFunctionsService {
  fire = firebase.firestore();

  emailsAuthorizedVeolia: string[] = [];
  emailsAuthorizedEvaluator: string[] = [];
  emailsAuthorizedEvaluatorBu: string[] = [];
  emailsAuthorizedVendor: string[] = [];

  isAuthorized: Observable<boolean>

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  ////////Authorisations guard///////

  //Check if user is authorized to access the forms (xxxxxForm/id)
  //0 //1 //2 //3 //4 //44 //CASE X
  public isAuthorizedToAccessForms(id): Observable<boolean> {

    const firestoredb = firebase.firestore();
    let buOfSolution;

    firestoredb.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(snap => {
      snap.forEach(val => {
        firestoredb
        .collection('businessUnit')
        .where('nomBu', '==', val.data().businessUnitOfSolution)
        .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              this.emailsAuthorizedEvaluatorBu = doc.data().evaluators //GET evaluators for the BU matching with Bu of solution
            });
          });
      })
    })

      return new Observable<boolean>(observer => {
        this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(snapshot => {
          snapshot.forEach(val => {
            this.emailsAuthorizedVeolia = val.data().emailVeoliaAuthorized; //add each user
            this.emailsAuthorizedEvaluator = val.data().emailEvaluatorAuthorized;
            this.emailsAuthorizedVendor = val.data().emailVendorAuthorized;
            buOfSolution = val.data().businessUnitOfSolution;
          })
        }).then(res => {
            //VEOLIA USER
            if (this.authService.currentUserEmail.substr(this.authService.currentUserEmail.indexOf('@')) === '@veolia.com') {
                  console.log("VEOLIA USER")

                    //Check if user is present in emailsAuthorizedVeolia (whatever his position in the list)
                    //1
                    for (let i = 0; i <= this.emailsAuthorizedVeolia.length-1 ; i++) {
                      if(this.authService.currentUserEmail === this.emailsAuthorizedVeolia[i]) { //user found in list
                        console.log("TRUE -- USER IS IN emailVeoliaAuthorized or is SO")
                        observer.next(true);
                        observer.complete();
                        break;
                      } else { //si emailVeoliaAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                          //user not found in list
                          if(i === this.emailsAuthorizedVeolia.length-1) { //tout a été parcouru


                          //Check if user is admin
                          //0
                          firebase.firestore().collection('users').doc(this.authService.currentUserId).get().then(val2 => {
                            if(val2.data().roles.admin === true) {
                              console.log("TRUE -- USER IS ADMIN")
                              observer.next(true);
                              observer.complete();
                            } else { //2 //THEN Check if the solution BU is matching with user's BU
                              console.log("USER IS NOT ADMIN")

                              if(val2.data().businessUnit === buOfSolution) {
                                console.log("TRUE -- BU of USER IS MATCHING")
                                observer.next(true);
                                observer.complete();
                              } else {
                                console.log("BU of USER NOT MATCHING")

                                //THEN Check if user is present in emailsAuthorizedEvaluator (whatever his position in the list)
                                //4
                                for (let i = 0; i <= this.emailsAuthorizedEvaluator.length-1 ; i++) {
                                  if(this.authService.currentUserEmail === this.emailsAuthorizedEvaluator[i]) { //user found in list
                                  console.log("TRUE -- USER IS IN emailEvaluatorAuthorized")
                                  observer.next(true);
                                  observer.complete();
                                  break;
                                  } else { //si emailEvaluatorAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                                    //user not found in list
                                    if(i === this.emailsAuthorizedEvaluator.length-1) { //tout a été parcouru


                                      //Check if user is evaluator of BU's solution
                                      //44
                                        if(this.emailsAuthorizedEvaluatorBu.find(x => x === this.authService.currentUserEmail)) {
                                          console.log("USER IS IN evaluators businessUnit matching with the solution");
                                          observer.next(true);
                                          observer.complete();
                                        } else {


                                          //CASE where the veolia is vendor
                                          for (let i = 0; i <= this.emailsAuthorizedVendor.length-1 ; i++) {
                                            //Check if user is present in emailsAuthorizedVendor (whatever his position in the list)
                                              //CASE X
                                              if(this.authService.currentUserEmail === this.emailsAuthorizedVendor[i]) { //user found in list
                                                console.log("TRUE -- USER IS IN emailsAuthorizedVendor")
                                                observer.next(true);
                                                observer.complete();
                                                break;
                                              } else { // + de 1 user dans emailsAuthorizedVendor et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                                                //user not found in list
                                                if(i === this.emailsAuthorizedVendor.length-1) { //tout a été parcouru

                                                  //FINAL ENDING
                                                  observer.next(false);
                                                  observer.complete();
                                                }
                                              }
                                           }

                                          }

                                    }
                                  }
                                }
                              }
                            }
                          });
                        }
                      }
                    }
            } else { //VENDOR USER
                  console.log("VENDOR USER")
                    this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(val => {
                      val.forEach(val => {
                        this.emailsAuthorizedVendor = val.data().emailVendorAuthorized; //add each user
                      })
                    }).then(res => {
                          //Check if user is present in emailsAuthorizedVendor (whatever his position in the list)
                          //3
                          for (let i = 0; i <= this.emailsAuthorizedVendor.length-1 ; i++) {
                            if(this.authService.currentUserEmail === this.emailsAuthorizedVendor[i]) { //user found in list
                            console.log("TRUE -- USER IS IN emailVendorAuthorized")
                              observer.next(true);
                              observer.complete();
                              break;
                            } else { //si emailVendorAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à la dernière occurrence

                              //user not found in list
                              if(i === this.emailsAuthorizedVendor.length-1){ //tout a été parcouru

                                observer.next(false);
                                observer.complete();
                              }
                            }
                          }
                      })
                }
          }).catch(err => {
              console.log('Error guard', err);
            });
      })

  }

  //Check if user is authorized to access edit demande (editDemandeDla/id/type)
  //5 //6 //7 //8 //9 //99
  public isAuthorizedToAccessEditDemande(id): Observable<boolean> {

    let solOwnerOfSolution;
    const firestoredb = firebase.firestore();
    let buOfSolution;

    firestoredb.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(snap => {
      snap.forEach(val => {
        firestoredb
        .collection('businessUnit')
        .where('nomBu', '==', val.data().businessUnitOfSolution)
        .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              this.emailsAuthorizedEvaluatorBu = doc.data().evaluators //GET evaluators for the BU matching with Bu of solution
            });
          });
      })
    })

    //5
    return new Observable<boolean>(observer => {
        this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(snapshot => {
          snapshot.forEach(val => {
            this.emailsAuthorizedVeolia = val.data().emailVeoliaAuthorized; //add each user
            this.emailsAuthorizedEvaluator = val.data().emailEvaluatorAuthorized;
            buOfSolution = val.data().businessUnitOfSolution;
            solOwnerOfSolution = val.data().solutionOwner;
          })
        }).then(res => {
            //VEOLIA USER
            if (this.authService.currentUserEmail.substr(this.authService.currentUserEmail.indexOf('@')) === '@veolia.com') {
                  console.log("VEOLIA USER")

                    //Check if user is present in emailsAuthorizedEvaluator (whatever his position in the list)
                    //9
                    for (let i = 0; i <= this.emailsAuthorizedEvaluator.length-1 ; i++) {
                      if(this.authService.currentUserEmail === this.emailsAuthorizedEvaluator[i]) { //user found in list
                        console.log("TRUE -- USER IS IN emailEvaluatorAuthorized")
                        observer.next(true);
                        observer.complete();
                        break;
                      } else { //si emailsAuthorizedEvaluator contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                          //user not found in list
                          if(i === this.emailsAuthorizedEvaluator.length-1) { //tout a été parcouru


                          //Check if user is admin
                          //0
                          firebase.firestore().collection('users').doc(this.authService.currentUserId).get().then(val2 => {
                            if(val2.data().roles.admin === true) {
                              console.log("TRUE -- USER IS ADMIN")
                              observer.next(true);
                              observer.complete();
                            } else { //8 //THEN Check if the solution BU is matching with user's BU and if the user has evaluator role
                                console.log("USER IS NOT ADMIN")

                                if(val2.data().businessUnit === buOfSolution && val2.data().roles.evaluator === true) {
                                  console.log("TRUE -- BU of USER IS MATCHING and he is EVALUATOR")
                                  observer.next(true);
                                  observer.complete();
                                } else {
                                  console.log('USER is NOT evaluator or his BU not matching')
                                  //Check if user is evaluator of BU's solution
                                  //99
                                  if(this.emailsAuthorizedEvaluatorBu.find(x => x === this.authService.currentUserEmail)) {
                                    console.log("USER IS IN evaluators businessUnit matching with the solution");
                                    observer.next(true);
                                    observer.complete();
                                  } else {


                                    //THEN Check if the user is SO of solution
                                    //6
                                    if(solOwnerOfSolution === this.authService.currentUserEmail) {
                                      console.log("TRUE -- USER IS SO")
                                      observer.next(true);
                                      observer.complete();
                                    } else {
                                      console.log("USER IS NOT SO")
                                      //FINAL ENDING
                                      observer.next(false);
                                      observer.complete();
                                    }
                                  }
                                }
                              }
                          });
                          }
                        }
                      }
            } else { //VENDOR USER
                    console.log("VENDOR USER")
                    observer.next(false);
                    observer.complete();
                }
        }).catch(err => {
              console.log('Error guard', err);
            });
    });
  }

  //Check if user is authorized to access gestion vendor (gestionVendor/id/type)
  //10 //11 //12 //13 //122 //CASE X
  public isAuthorizedToAccessGestionVendor(id): Observable<boolean> {

    let solOwnerOfSolution;
    const firestoredb = firebase.firestore();
    let buOfSolution;

    firestoredb.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(snap => {
      snap.forEach(val => {
        firestoredb
        .collection('businessUnit')
        .where('nomBu', '==', val.data().businessUnitOfSolution)
        .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              this.emailsAuthorizedEvaluatorBu = doc.data().evaluators //GET evaluators for the BU matching with Bu of solution
            });
          });
      })
    })

    return new Observable<boolean>(observer => {
        this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(snapshot => {
          snapshot.forEach(val => {
            this.emailsAuthorizedVeolia = val.data().emailVeoliaAuthorized; //add each user
            this.emailsAuthorizedEvaluator = val.data().emailEvaluatorAuthorized;
            this.emailsAuthorizedVendor = val.data().emailVendorAuthorized;
            buOfSolution = val.data().businessUnitOfSolution;
          })
        }).then(res => {
            //VEOLIA USER
            if (this.authService.currentUserEmail.substr(this.authService.currentUserEmail.indexOf('@')) === '@veolia.com') {
                  console.log("VEOLIA USER")

                    //Check if user is present in emailsAuthorizedVeolia (whatever his position in the list)
                    //10
                    for (let i = 0; i <= this.emailsAuthorizedVeolia.length-1 ; i++) {
                      if(this.authService.currentUserEmail === this.emailsAuthorizedVeolia[i]) { //user found in list
                        console.log("TRUE -- USER IS IN emailVeoliaAuthorized or is SO")
                        observer.next(true);
                        observer.complete();
                        break;
                      } else { //si emailVeoliaAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                          //user not found in list
                          if(i === this.emailsAuthorizedVeolia.length-1) { //tout a été parcouru


                          //Check if user is admin
                          //0
                          firebase.firestore().collection('users').doc(this.authService.currentUserId).get().then(val2 => {
                            if(val2.data().roles.admin === true) {
                              console.log("TRUE -- USER IS ADMIN")
                              observer.next(true);
                              observer.complete();
                            } else { //11 //THEN Check if the solution BU is matching with user's BU
                              console.log("USER IS NOT ADMIN")

                              if(val2.data().businessUnit === buOfSolution) {
                                console.log("TRUE -- BU of USER IS MATCHING")
                                observer.next(true);
                                observer.complete();
                              } else {
                                console.log("BU of USER NOT MATCHING")

                                //THEN Check if user is present in emailsAuthorizedEvaluator (whatever his position in the list)
                                //12
                                for (let i = 0; i <= this.emailsAuthorizedEvaluator.length-1 ; i++) {
                                  if(this.authService.currentUserEmail === this.emailsAuthorizedEvaluator[i]) { //user found in list
                                  console.log("TRUE -- USER IS IN emailEvaluatorAuthorized")
                                  observer.next(true);
                                  observer.complete();
                                  break;
                                  } else { //si emailEvaluatorAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                                    //user not found in list
                                    if(i === this.emailsAuthorizedEvaluator.length-1) { //tout a été parcouru


                                      //Check if user is evaluator of BU's solution
                                      //122
                                        if(this.emailsAuthorizedEvaluatorBu.find(x => x === this.authService.currentUserEmail)) {
                                          console.log("USER IS IN evaluators businessUnit matching with the solution");
                                          observer.next(true);
                                          observer.complete();
                                        } else {


                                          //CASE where the veolia is vendor
                                          for (let i = 0; i <= this.emailsAuthorizedVendor.length-1 ; i++) {
                                            //Check if user is present in emailsAuthorizedVendor (whatever his position in the list)
                                              //CASE X
                                              if(this.authService.currentUserEmail === this.emailsAuthorizedVendor[i]) { //user found in list
                                                console.log("TRUE -- USER IS IN emailsAuthorizedVendor")
                                                observer.next(true);
                                                observer.complete();
                                                break;
                                              } else { // + de 1 user dans emailsAuthorizedVendor et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                                                //user not found in list
                                                if(i === this.emailsAuthorizedVendor.length-1) { //tout a été parcouru

                                                  //FINAL ENDING
                                                  observer.next(false);
                                                  observer.complete();
                                                }
                                              }
                                           }

                                          }

                                    }
                                  }
                                }
                              }
                            }
                          });
                        }
                      }
                    }
            } else { //VENDOR USER
                  console.log("VENDOR USER")
                    this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(val => {
                      val.forEach(val => {
                        this.emailsAuthorizedVendor = val.data().emailVendorAuthorized; //add each user
                      })
                    }).then(res => {
                          //Check if user is present in emailsAuthorizedVendor (whatever his position in the list)
                          //13
                          for (let i = 0; i <= this.emailsAuthorizedVendor.length-1 ; i++) {
                            if(this.authService.currentUserEmail === this.emailsAuthorizedVendor[i]) { //user found in list
                            console.log("TRUE -- USER IS IN emailVendorAuthorized")
                              observer.next(true);
                              observer.complete();
                              break;
                            } else { //si emailVendorAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à la dernière occurrence

                              //user not found in list
                              if(i === this.emailsAuthorizedVendor.length-1){ //tout a été parcouru

                                observer.next(false);
                                observer.complete();
                              }
                            }
                          }
                      })
                }
          }).catch(err => {
              console.log('Error guard', err);
            });
      })
  }

  //Check if user is authorized to access logs (logs/id/type)
  //14 //15 //16 //17 //166 //CASE X
  public isAuthorizedToAccessLogs(id): Observable<boolean> {

    const firestoredb = firebase.firestore();
    let buOfSolution;

    firestoredb.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(snap => {
      snap.forEach(val => {
        firestoredb
        .collection('businessUnit')
        .where('nomBu', '==', val.data().businessUnitOfSolution)
        .get()
          .then(snapshot => {
            snapshot.forEach(doc => {
              this.emailsAuthorizedEvaluatorBu = doc.data().evaluators //GET evaluators for the BU matching with Bu of solution
            });
          });
      })
    })

    return new Observable<boolean>(observer => {
        this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(snapshot => {
          snapshot.forEach(val => {
            this.emailsAuthorizedVeolia = val.data().emailVeoliaAuthorized; //add each user
            this.emailsAuthorizedEvaluator = val.data().emailEvaluatorAuthorized;
            this.emailsAuthorizedVendor = val.data().emailVendorAuthorized;
            buOfSolution = val.data().businessUnitOfSolution;
          })
        }).then(res => {
            //VEOLIA USER
            if (this.authService.currentUserEmail.substr(this.authService.currentUserEmail.indexOf('@')) === '@veolia.com') {
                  console.log("VEOLIA USER")

                    //Check if user is present in emailsAuthorizedVeolia (whatever his position in the list)
                    //15
                    for (let i = 0; i <= this.emailsAuthorizedVeolia.length-1 ; i++) {
                      if(this.authService.currentUserEmail === this.emailsAuthorizedVeolia[i]) { //user found in list
                        console.log("TRUE -- USER IS IN emailVeoliaAuthorized or is SO")
                        observer.next(true);
                        observer.complete();
                        break;
                      } else { //si emailVeoliaAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                          //user not found in list
                          if(i === this.emailsAuthorizedVeolia.length-1) { //tout a été parcouru


                          //Check if user is admin
                          //0
                          firebase.firestore().collection('users').doc(this.authService.currentUserId).get().then(val2 => {
                            if(val2.data().roles.admin === true) {
                              console.log("TRUE -- USER IS ADMIN")
                              observer.next(true);
                              observer.complete();
                            } else { //14 //THEN Check if the solution BU is matching with user's BU
                              console.log("USER IS NOT ADMIN")

                              if(val2.data().businessUnit === buOfSolution) {
                                console.log("TRUE -- BU of USER IS MATCHING")
                                observer.next(true);
                                observer.complete();
                              } else {
                                console.log("BU of USER NOT MATCHING")

                                //THEN Check if user is present in emailsAuthorizedEvaluator (whatever his position in the list)
                                //16
                                for (let i = 0; i <= this.emailsAuthorizedEvaluator.length-1 ; i++) {
                                  if(this.authService.currentUserEmail === this.emailsAuthorizedEvaluator[i]) { //user found in list
                                  console.log("TRUE -- USER IS IN emailEvaluatorAuthorized")
                                  observer.next(true);
                                  observer.complete();
                                  break;
                                  } else { //si emailEvaluatorAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                                    //user not found in list
                                    if(i === this.emailsAuthorizedEvaluator.length-1) { //tout a été parcouru


                                      //Check if user is evaluator of BU's solution
                                      //166
                                        if(this.emailsAuthorizedEvaluatorBu.find(x => x === this.authService.currentUserEmail)) {
                                          console.log("USER IS IN evaluators businessUnit matching with the solution");
                                          observer.next(true);
                                          observer.complete();
                                        } else {


                                          //CASE where the veolia is vendor
                                          for (let i = 0; i <= this.emailsAuthorizedVendor.length-1 ; i++) {
                                            //Check if user is present in emailsAuthorizedVendor (whatever his position in the list)
                                              //CASE X
                                              if(this.authService.currentUserEmail === this.emailsAuthorizedVendor[i]) { //user found in list
                                                console.log("TRUE -- USER IS IN emailsAuthorizedVendor")
                                                observer.next(true);
                                                observer.complete();
                                                break;
                                              } else { // + de 1 user dans emailsAuthorizedVendor et que le user n'est pas à la première position //repasse dans le for jusqu'à dernière occurrence

                                                //user not found in list
                                                if(i === this.emailsAuthorizedVendor.length-1) { //tout a été parcouru

                                                  //FINAL ENDING
                                                  observer.next(false);
                                                  observer.complete();
                                                }
                                              }
                                           }

                                          }

                                    }
                                  }
                                }
                              }
                            }
                          });
                        }
                      }
                    }
            } else { //VENDOR USER
                  console.log("VENDOR USER")
                    this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(val => {
                      val.forEach(val => {
                        this.emailsAuthorizedVendor = val.data().emailVendorAuthorized; //add each user
                      })
                    }).then(res => {
                          //Check if user is present in emailsAuthorizedVendor (whatever his position in the list)
                          //17
                          for (let i = 0; i <= this.emailsAuthorizedVendor.length-1 ; i++) {
                            if(this.authService.currentUserEmail === this.emailsAuthorizedVendor[i]) { //user found in list
                            console.log("TRUE -- USER IS IN emailVendorAuthorized")
                              observer.next(true);
                              observer.complete();
                              break;
                            } else { //si emailVendorAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à la dernière occurrence

                              //user not found in list
                              if(i === this.emailsAuthorizedVendor.length-1){ //tout a été parcouru

                                observer.next(false);
                                observer.complete();
                              }
                            }
                          }
                      })
                }
          }).catch(err => {
              console.log('Error guard', err);
            });
      })
  }

  //Check if user is authorized to access tableauEvals (tableauEvals/id/type)
  //18 //19
  public isAuthorizedToAccessTableauEvals(id): Observable<boolean> {

    return new Observable<boolean>(observer => {
        this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(val => {
          val.forEach(val => {
            this.emailsAuthorizedVendor = val.data().emailVendorAuthorized; //add each user
          })
        }).then(res => {
            //VEOLIA USER a accès à tableauEvals de toutes les demandes
            if (this.authService.currentUserEmail.substr(this.authService.currentUserEmail.indexOf('@')) === '@veolia.com') {
                  console.log("TRUE -- VEOLIA USER")
                  observer.next(true);
                  observer.complete();
            } else { //VENDOR USER
                  console.log("VENDOR USER")
                    this.fire.collection('solutions').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(val => {
                      val.forEach(val => {
                        this.emailsAuthorizedVendor = val.data().emailVendorAuthorized; //add each user
                      })
                    }).then(res => {
                          //Check if user is present in emailsAuthorizedVendor (whatever his position in the list)
                          //17
                          for (let i = 0; i <= this.emailsAuthorizedVendor.length-1 ; i++) {
                                if(this.authService.currentUserEmail === this.emailsAuthorizedVendor[i]) { //user found in list
                                console.log("TRUE -- USER IS IN emailVendorAuthorized")
                                observer.next(true);
                                observer.complete();
                                break;
                                } else { //si emailVendorAuthorized contient + de 1 user et que le user n'est pas à la première position //repasse dans le for jusqu'à la dernière occurrence

                                  //user not found in list
                                  if(i === this.emailsAuthorizedVendor.length-1) { //tout a été parcouru

                                    observer.next(false);
                                    observer.complete();
                                  }
                                }
                              }
                      })
                }
          }).catch(err => {
              console.log('Error guard', err);
            });
      })
  }

  //Check if user is authorized to access edit password (editPassword/id)
  //00
  public isAuthorizedToAccessEditPassword(id): Observable<boolean> {

    return new Observable<boolean>(observer => {
        this.fire.collection('users').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(val => {
          val.forEach(val => {
          })
        }).then(res => {
            //VEOLIA USER
            if (this.authService.currentUserEmail.substr(this.authService.currentUserEmail.indexOf('@')) === '@veolia.com') {
                  console.log("VEOLIA USER")
                  observer.next(false);
                  observer.complete();
            } else { //VENDOR USER
                    console.log("VENDOR USER")
                    if (this.authService.currentUserId === id) {
                      console.log("TRUE -- VENDOR USER OK")
                      observer.next(true);
                      observer.complete();
                    } else {
                      observer.next(false);
                      observer.complete();
                    }
                }
        })
    });
  }

  //Check if user is authorized to access edit first connection (firstConnection/id)
  //000
  public isAuthorizedToAccessFirstConnection(id): Observable<boolean> {

    return new Observable<boolean>(observer => {
        this.fire.collection('users').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(val => {
          val.forEach(val => {
          })
        }).then(res => {
          if (this.authService.currentUserId === id) {
            console.log("TRUE -- VENDOR/VEOLIA USER OK")
            observer.next(true);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        })
    });
  }

  //Check if user is authorized to access profile (profile/id)
  //0000
  public isAuthorizedToAccessProfile(id): Observable<boolean> {

    return new Observable<boolean>(observer => {
        this.fire.collection('users').where(firebase.firestore.FieldPath.documentId(), '==', id).get().then(val => {
          val.forEach(val => {
          })
        }).then(res => {
          if (this.authService.currentUserId === id) {
            observer.next(true);
            observer.complete();
          } else {
            observer.next(false);
            observer.complete();
          }
        })
    });
  }
}
