import { AngularFirestore } from '@angular/fire/compat/firestore';
//import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
//import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { dictToURI } from '../../core/helpers';

@Injectable({
  providedIn: 'root'
})
export class DemandeDlaService {
  
  constructor(private toastr: ToastrService, private firestore: AngularFirestore, private authService: AuthService, private httpClient: HttpClient) {}

  //SaaS
  newDemandeSaas(demandeForm, demandeTypeForm, demandeFormSaas, demandeFormSaasSolutionSection, businessUnitOfSol, evaluatorsBu) {
        
    for(let i= 0; i<=demandeFormSaas.nbSolution-1; i++) {
      console.log('service',demandeFormSaasSolutionSection[i].solutionName)
        
      //on va créé autant de solutions en fonction de la valeur de nbSolution
      this.firestore
      .collection('solutions')
      .add({
        //infos user
        fullNameInitiatorVeolia: this.authService.currentUserDisplayName, //est récupéré depuis auth.service.ts
        uidInitiatorVeolia: this.authService.currentUserId, //est récupéré depuis auth.service.ts
        emailInitiatorVeolia: this.authService.currentUserEmail, //est récupéré depuis auth.service.ts
        solutionOwner: this.authService.currentUserEmail,
        businessUnitOfSolution: businessUnitOfSol, //correpondant aux données du user
        
        Q0_A: demandeForm.Q0_A,
        Q0_B: demandeForm.Q0_B,
        Q0_C: demandeForm.Q0_C,
        
        //type
        type: demandeTypeForm.type,
        
        //SaaS
        rfpName: demandeFormSaas.rfpName,
        description: demandeFormSaas.description,
        domainOfSolution: demandeFormSaas.domainOfSolution, //en fonction du choix dans le liste,
        accessQuestion: demandeFormSaas.accessQuestion,
        integrateQuestion: demandeFormSaas.integrateQuestion,
        interconnectQuestion: demandeFormSaas.interconnectQuestion,
        interconnectQuestion2: demandeFormSaas.interconnectQuestion2,
        communicationQuestion: demandeFormSaas.communicationQuestion,
        expectedEvaluationDate: demandeFormSaas.expectedEvaluationDate,
        expectedCompletionDate: demandeFormSaas.expectedCompletionDate,
        expectedOnlineReleaseDate: demandeFormSaas.expectedOnlineReleaseDate,
        nbSolution: demandeFormSaas.nbSolution,
        
        /////Solution(s) (pour 1 RFP)////
        //solutionSection : {
          //Solution
          solutionName: demandeFormSaasSolutionSection[i].solutionName,
          editorBrand: demandeFormSaasSolutionSection[i].editorBrand,
          inPlace: demandeFormSaasSolutionSection[i].inPlace,
          apmNumber: demandeFormSaasSolutionSection[i].apmNumber,
          link: demandeFormSaasSolutionSection[i].link,
          
          //Main contact
          mainEmailVendor: demandeFormSaasSolutionSection[i].mainEmailVendor,
          mainFirstnameVendor: demandeFormSaasSolutionSection[i].mainFirstnameVendor,
          mainLastnameVendor: demandeFormSaasSolutionSection[i].mainLastnameVendor,
          mainCompanyVendor: demandeFormSaasSolutionSection[i].mainCompanyVendor,
          linkCom: demandeFormSaasSolutionSection[i].linkCom,
        //},
        
        //Added after
        emailVendorAuthorized: [demandeFormSaasSolutionSection[i].mainEmailVendor],
        emailVeoliaAuthorized: [this.authService.currentUserEmail], //par défaut le 1er ayant acccès est l'initiateur
        emailEvaluatorAuthorized: [null],
        requestDate: new Date(Date.now()),
        //lastUpdate: window.localStorage.getItem('currDate'), //Sert juste lors de la modif formulaireALL, cette valeur va changer
        lastUpdate: 'No forms completed yet', //correspond aux forms et pas à la demande même
        status: 'Pending',
        designStatus: 'New',
        legalStatus: 'New',
        article28Status: 'New',
        article32Status: 'New',
        jsonFR: 'fr-FR-v1',
        jsonUS: 'en-US-v1',
        fullyEvaluated: false
      })
      .then(res => {
      
        console.log("SOLUTION ID GENERATED", res.id)
        
        //envoi du mail récapitulatif à inititateur demande
        this.sendRequestAcknowledgmentSaaS(demandeFormSaasSolutionSection[i].solutionName, demandeFormSaas.rfpName, demandeFormSaasSolutionSection[i].editorBrand, businessUnitOfSol, res.id).subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
          }
            console.log("from api: " + data);
        });
        
        //envoi du mail à la bu dla qu'une demande a été crée
        this.sendRequestCreatedSaaS(demandeFormSaasSolutionSection[i].solutionName, demandeFormSaas.rfpName, demandeFormSaasSolutionSection[i].editorBrand, businessUnitOfSol, res.id).subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
          }
            console.log("from api: " + data);
        });
        
        //mail envoyé à chaque evaluator présent sous 'evaluators' appartenant à la Bu de la solution
      for (let j = 0; j <= evaluatorsBu.length-1 ; j++) {
        this.sendRequestAcknowledgmentForEvaluatorsSaaS(demandeFormSaasSolutionSection[i].solutionName, demandeFormSaas.rfpName, demandeFormSaasSolutionSection[i].editorBrand, businessUnitOfSol, res.id, evaluatorsBu[j]).subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
          }
            console.log("from api: " + data);
        });
      }

      }).catch(err => {
          console.log('Error service creating request', err);
        });
  
    }//END for nbSolution

    this.userBecomeSolutionOwner();
  }
  
  //IaaS
  newDemandeIaas(demandeForm, demandeTypeForm, demandeFormIaas, businessUnitOfSol) {
        
      this.firestore
      .collection('solutions')
      .add({
        //infos user //0
        fullNameInitiatorVeolia: this.authService.currentUserDisplayName, //est récupéré depuis auth.service.ts
        uidInitiatorVeolia: this.authService.currentUserId, //est récupéré depuis auth.service.ts
        emailInitiatorVeolia: this.authService.currentUserEmail, //est récupéré depuis auth.service.ts
        solutionOwner: this.authService.currentUserEmail,
        businessUnitOfSolution: businessUnitOfSol, //correpondant aux données du user 
        
        Q0_A: demandeForm.Q0_A,
        Q0_B: demandeForm.Q0_B,
        Q0_C: demandeForm.Q0_C,
        
        //type
        type: demandeTypeForm.type,
        
        //IaaS
        q0a_iaas: demandeFormIaas.q0a_iaas,
        q0b_iaas: demandeFormIaas.q0b_iaas,
        solutionName: demandeFormIaas.solutionName,
        editorBrand: demandeFormIaas.editorBrand,
        description: demandeFormIaas.description,
        domainOfSolution: demandeFormIaas.domainOfSolution, //en fonction du choix dans le liste,
        inPlace: demandeFormIaas.inPlace,
        apmNumber: demandeFormIaas.apmNumber,
        link: demandeFormIaas.link,
        comment: demandeFormIaas.comment,
        
        //Added after
        emailVeoliaAuthorized: [this.authService.currentUserEmail], //par défaut le 1er ayant acccès est l'initiateur (SO)
        requestDate: new Date(Date.now()),
        lastUpdate: 'No forms completed yet', //correspond aux forms et pas à la demande même
        status: 'Pending',
        iaasStatus: 'New',
        jsonFR: 'fr-FR-v1',
        jsonUS: 'en-US-v1',
        fullyEvaluated: false
      })
      .then(res => {
      
        console.log("SOLUTION ID GENERATED", res.id)
        
        //envoi du mail récapitulatif à inititateur demande
        this.sendRequestAcknowledgment(demandeFormIaas.solutionName, demandeTypeForm.type, demandeFormIaas.editorBrand, businessUnitOfSol, res.id).subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
          }
            console.log("from api: " + data);
        });
        
        //envoi du mail à la bu dla qu'une demande a été crée
        this.sendRequestCreated(demandeFormIaas.solutionName, demandeTypeForm.type, demandeFormIaas.editorBrand, businessUnitOfSol, res.id).subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
          }
            console.log("from api: " + data);
        });

      }).catch(err => {
          console.log('Error service creating request', err);
        });
    
    this.userBecomeSolutionOwner();
    
  }
  
  //PaaS
  newDemandePaas(value, valuePaas) {
        
      this.firestore
      .collection('solutions')
      .add({
        //infos user //0
        fullNameInitiatorVeolia: this.authService.currentUserDisplayName, //est récupéré depuis auth.service.ts
        uidInitiatorVeolia: this.authService.currentUserId, //est récupéré depuis auth.service.ts
        emailInitiatorVeolia: this.authService.currentUserEmail, //est récupéré depuis auth.service.ts
        solutionOwner: this.authService.currentUserEmail,
        businessUnitOfSolution: value.businessUnitOfSolution, //correpondant aux données du user 
        
        //Main details //0
        Q0_A: value.Q0_A,
        Q0_B: value.Q0_B,
        Q0_C: value.Q0_C,
        Q0_D: value.Q0_D,
        type: value.type, 
        
        //IaaS
        solutionName: valuePaas.solutionName,
        developedBy: valuePaas.developedBy,
        description: valuePaas.description,
        domainOfSolution: valuePaas.domainOfSolution, //en fonction du choix dans le liste,
        inPlace: valuePaas.inPlace,
        apmNumber: valuePaas.apmNumber,
        link: valuePaas.link,
        comment: valuePaas.comment,
        
        //Added after
        emailVeoliaAuthorized: [this.authService.currentUserEmail], //par défaut le 1er ayant acccès est l'initiateur
        requestDate: new Date(Date.now()),
        lastUpdate: 'No forms completed yet', //correspond aux forms et pas à la demande même
        status: 'Pending',
        jsonFR: 'fr-FR-v1',
        jsonUS: 'en-US-v1',
        fullyEvaluated: false
      })
      .then(res => {
      
        console.log("SOLUTION ID GENERATED", res.id)
        
        //envoi du mail récapitulatif à inititateur demande
        // this.sendRequestAcknowledgment(valueSolutionSection[i].solutionName, valueSaas.rfpName, valueSolutionSection[i].editorBrand, valueSaas.businessUnitOfSolution, res.id).subscribe(data => {
        //   if (data !== "200") {
        //     console.error("KO mail retarus unsent !")
        //   } else {
        //     console.log("OK mail retarus sent !")
        //   }
        //     console.log("from api: " + data);
        // });
        
        // //envoi du mail à la bu dla qu'une demande a été crée
        // this.sendRequestCreated(valueSolutionSection[i].solutionName, valueSaas.rfpName, valueSolutionSection[i].editorBrand, valueSaas.businessUnitOfSolution, res.id).subscribe(data => {
        //   if (data !== "200") {
        //     console.error("KO mail retarus unsent !")
        //   } else {
        //     console.log("OK mail retarus sent !")
        //   }
        //     console.log("from api: " + data);
        // });

      }).catch(err => {
          console.log('Error service creating request', err);
        });
    
    this.userBecomeSolutionOwner();
    
  }
  
  //PaaS
  newDemandeApps(value, valueApps) {
        
      this.firestore
      .collection('solutions')
      .add({
        //infos user //0
        fullNameInitiatorVeolia: this.authService.currentUserDisplayName, //est récupéré depuis auth.service.ts
        uidInitiatorVeolia: this.authService.currentUserId, //est récupéré depuis auth.service.ts
        emailInitiatorVeolia: this.authService.currentUserEmail, //est récupéré depuis auth.service.ts
        solutionOwner: this.authService.currentUserEmail,
        businessUnitOfSolution: value.businessUnitOfSolution, //correpondant aux données du user 
        
        //Main details
        type: value.type, //0
        
        //IaaS
        applicationName: valueApps.solutionName,
        editorBrand: valueApps.editorBrand,
        inUse: valueApps.inUse,
        inUse_more: valueApps.inUse_more,
        linkedTo:  valueApps.linkedTo,
        linkedTo_more:  valueApps.linkedTo_more,
        usedBy: valueApps.usedBy,
        link: valueApps.link,
        
        //Added after
        emailVeoliaAuthorized: [this.authService.currentUserEmail], //par défaut le 1er ayant acccès est l'initiateur
        requestDate: new Date(Date.now()),
        lastUpdate: 'No forms completed yet', //correspond aux forms et pas à la demande même
        status: 'Pending',
        jsonFR: 'fr-FR-v1',
        jsonUS: 'en-US-v1',
        fullyEvaluated: false
      })
      .then(res => {
      
        console.log("SOLUTION ID GENERATED", res.id)
        
        //envoi du mail récapitulatif à inititateur demande
        // this.sendRequestAcknowledgment(valueSolutionSection[i].solutionName, valueSaas.rfpName, valueSolutionSection[i].editorBrand, valueSaas.businessUnitOfSolution, res.id).subscribe(data => {
        //   if (data !== "200") {
        //     console.error("KO mail retarus unsent !")
        //   } else {
        //     console.log("OK mail retarus sent !")
        //   }
        //     console.log("from api: " + data);
        // });
        
        // //envoi du mail à la bu dla qu'une demande a été crée
        // this.sendRequestCreated(valueSolutionSection[i].solutionName, valueSaas.rfpName, valueSolutionSection[i].editorBrand, valueSaas.businessUnitOfSolution, res.id).subscribe(data => {
        //   if (data !== "200") {
        //     console.error("KO mail retarus unsent !")
        //   } else {
        //     console.log("OK mail retarus sent !")
        //   }
        //     console.log("from api: " + data);
        // });

      }).catch(err => {
          console.log('Error service creating request', err);
        });
    
    this.userBecomeSolutionOwner();
    
  }


  //L'utilisateur devient solutionOwner, role solutionOwner à true
  userBecomeSolutionOwner() {
    this.firestore.doc('users/' + this.authService.currentUserId).set(
      {
        role: 'Solution Owner',
        roles: {
          solutionOwner: true
        }
      },
      { merge: true }
    );
  }

  //Liste des domaines dispos
  getDomains() {
    return this.firestore.collection('domainsRef', ref => ref.orderBy('domainName', 'asc')).snapshotChanges();
  }
  
  //SaaS
  sendRequestAcknowledgmentSaaS(solName, rfpName, editorBrand, buOfUser, idSolDoc){
    const query = dictToURI({
      emailVeoliaSolOwner: this.authService.currentUserEmail,
      idSol: idSolDoc,
      soName: this.authService.currentUserDisplayName,
      bu: buOfUser,
      solutionName: solName,
      rfpName: rfpName,
      editorBrand: editorBrand
    });
   return this.httpClient.get('/api/request_acknowledgment_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
  sendRequestCreatedSaaS(solName, rfpName, editorBrand, buOfUser, idSolDoc){
    const query = dictToURI({
      emailVeoliaSolOwner: this.authService.currentUserEmail,
      idSol: idSolDoc,
      soName: this.authService.currentUserDisplayName,
      bu: buOfUser,
      solutionName: solName,
      rfpName,
      editorBrand
    });
    return this.httpClient.get('/api/request_created_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
  sendRequestAcknowledgmentForEvaluatorsSaaS(solName, rfpName, editorBrand, buOfUser, idSolDoc, emailEvaluatorBu){
    const query = dictToURI({
      emailVeoliaSolOwner: this.authService.currentUserEmail,
      idSol: idSolDoc,
      soName: this.authService.currentUserDisplayName,
      bu: buOfUser,
      emailEvaluatorBu: emailEvaluatorBu,
      solutionName: solName,
      rfpName,
      editorBrand
    });
    
    return this.httpClient.get('/api/request_acknowledgment_for_evaluators_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
  //IaaS PaaS Apps
  sendRequestAcknowledgment(solName, type, editorBrand, buOfUser, idSolDoc){
    const query = dictToURI({
      emailVeoliaSolOwner: this.authService.currentUserEmail,
      idSol: idSolDoc,
      soName: this.authService.currentUserDisplayName,
      bu: buOfUser,
      solutionName: solName,
      editorBrand: editorBrand,
      type: type
    });
    return this.httpClient.get('/api/request_acknowledgment?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
  sendRequestCreated(solName, type, editorBrand, buOfUser, idSolDoc){
    const query = dictToURI({
      emailVeoliaSolOwner: this.authService.currentUserEmail,
      idSol: idSolDoc,
      soName: this.authService.currentUserDisplayName,
      bu: buOfUser,
      solutionName: solName,
      editorBrand: editorBrand,
      type: type
    });
    return this.httpClient.get('/api/request_created?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }
  
  public handleErrorHttp(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }
    
}
