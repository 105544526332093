import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';

//pdf
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';

import { HomeUserService } from '../../homeUser/homeUser.service';
import { FormulaireAllService } from '../formulairesAccess/formulaireAll/formulaireAll.service';
import { AuthService } from '../../core/auth.service';
import { Reservation } from '../formulairesAccess/formulaireAll/reservation.model';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-reservations-list',
  templateUrl: './reservations-list.component.html',
  styleUrls: ['./reservations-list.component.scss']
})
export class ReservationsListComponent implements OnInit {
  
  reservationsList: Reservation[];
  
  currentReservationKey: string;

  formName: string;

  reservationByChapterIsPresent: string[];
  
  resList: AngularFireList<any>;
  
  download: boolean; //default: null
  
  solName: string;

  constructor(
    public service: FormulaireAllService,
    private toastr: ToastrService,
    private afAuth: AngularFireAuth,
    public auth: AuthService,
    public homeUserServ: HomeUserService,
    private afs: AngularFireDatabase
  ) {}

  ngOnInit() {
    //empty
  }
  
  
  //On vérifie les chapitres qui possèdent une reservation
  checkOutChaptersWithReservation(form,solutionName) {
    this.formName = form;
    this.solName = solutionName; //pour pdf
   
    this.service
      .getAllReservations(form)
      .snapshotChanges()
      .subscribe(ok => {
        this.reservationByChapterIsPresent = [];
        ok.forEach(element => {
          console.log('Chaptitres contenant une reservation: ' + element.key);
          
          this.reservationByChapterIsPresent.push(element.key);
          
        });
      });
  }
  
  
  //Récupérer la reservation correspondante au clic du bouton du chap/ sous chap
  reservationByChapter(item) {
  
  return (this.resList = this.afs.list(
      'reservations/' + this.formName + '/' + this.service.idSolution + '/' + item,
      ref => ref.orderByChild('idRefReservation').equalTo(this.service.idSolution+"-"+item)))
      .snapshotChanges()
      .subscribe(ok => {
        this.reservationsList = [];
        ok.forEach(element => {
          const x = element.payload.toJSON();
          x['$key'] = element.key;
          this.reservationsList.push(x as Reservation);
        });
      });
      
  }
  
  //au clic du bouton pour accéder au pdf
  generatePdf() {

    this.toastr.success('Download in progress...', '', {
      timeOut: 4000,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-center'
    });

    //sert juste à avoir le chapitre traduit
    this.download = true;

    this.service
      .getAllReservations(this.formName)
      .snapshotChanges()
      .subscribe(ok => {
        this.reservationByChapterIsPresent = [];
        ok.forEach(element => {
          console.log('Chaptitres contenant une reservation: ' + element.key);

          this.reservationByChapterIsPresent.push(element.key);

          this.reservationsList = [];
          (this.resList = this.afs.list(
            'reservations/' + this.formName + '/' + this.service.idSolution + '/' + element.key,
            ref => ref.orderByChild('idRefReservation').equalTo(this.service.idSolution + "-" + element.key)))
            .snapshotChanges()
            .subscribe(res => {

              res.forEach(elem => {
                const x = elem.payload.toJSON();
                x['$key'] = elem.key;
                this.reservationsList.push(x as Reservation);
              });
            });

        });
      });


     this.delay(1500).then(any => {

      //var win = window.open('', '_blank');

      const fullEval = document.getElementById('allReservationsForPdf').innerHTML;

      let finalVar = htmlToPdfmake(fullEval);

      let documentDefinition = {
            footer: function (pagenumber, pagecount) {
              return {
                  alignment: 'right', margin: [0, 10, 10, 0],
                  text: pagenumber + '/' + pagecount
              };
            },
            content: finalVar
      };
      //pdfMake.createPdf(documentDefinition).open(); // Erreur 'Open PDF in new window blocked by browser'

    if(this.formName === 'Article 28 Form') { //Annexes
      pdfMake.createPdf(documentDefinition).download('Reservations-' + this.solName + '-Annexes.pdf');  
    } else {
      pdfMake.createPdf(documentDefinition).download('Reservations-' + this.solName + '-'+ this.formName + '.pdf');
    }

      this.download = false;
     });

  }
  
  
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  

}
