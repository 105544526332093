<h2 mat-dialog-title class="primary-background text-white">
  {{ 'Solution and form overview for' | translate }} <span class="primary-solution-name"># - {{ demand?.rfpName || demand?.solutionName }}</span>
</h2>
<mat-dialog-content class="mat-typography">
  <button mat-raised-button type="button" title="Click to download the solution overview"
  class="center"
  (click)="generatePdf()"
  >Download PDF of request summary<em class="material-icons" style="margin-right: 5px"> cloud_download </em>
  </button>
  <br />
  <br />
  <div id="fullSolInfoForPdf">
    <h1 *ngIf="download === true" style="text-align:center; font-size: 20px;">Solution-Form overview for {{ demand?.solutionName }}</h1>
    <br *ngIf="download === true"  />
    <br *ngIf="download === true"  />

     <mat-card class="user-access-card">
      <mat-card-header>
        <mat-card-title><span class="title" style="color: #5bc0de; font-weight: bold;">{{ 'Users access' | translate }}</span></mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="download === true">
          <p>{{ 'Solution owner' | translate }}:</p>
          <table style="display: initial;" aria-label="Veolia users">
            <tr>
              <th scope="col">{{ 'User email' | translate }}</th>
            </tr>
            <tr>
              <td><strong>{{ demand?.solutionOwner }}</strong></td>
            </tr>
          </table>
        </div>
        <mat-card *ngIf="!download" class="owner-email">
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;"><span class="title" style="color: #5bc0de;">{{ 'Solution owner' | translate }}</span></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-chip-set>
              <mat-chip>{{ demand?.solutionOwner }}</mat-chip>
            </mat-chip-set>
          </mat-card-content>
        </mat-card>

        <br />
        <div *ngIf="download === true">
          <p>{{ 'Veolia email access' | translate }}:</p>
          <table style="display: initial;" aria-label="Veolia users">
            <tr>
              <th scope="col">{{ 'User email' | translate }}</th>
            </tr>
            <tr *ngFor="let email of demand?.emailVeoliaAuthorized; index as i">
              <td><strong>{{ i }} - {{email}}</strong></td>
            </tr>
          </table>
        </div>
        <mat-card *ngIf="!download" class="editor-email">
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;"><span class="title" style="color: #5bc0de;">{{ 'Veolia email access' | translate }}</span></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-chip-set>
              <mat-chip *ngFor="let email of demand?.emailVeoliaAuthorized; index as i">{{email}}</mat-chip>
            </mat-chip-set>
          </mat-card-content>
        </mat-card>
        <br />
        <div *ngIf="download === true">
          <p>{{ 'Editor email access' | translate }}:</p>
          <table style="display: initial;" aria-label="Editor users">
            <tr>
              <th scope="col">{{ 'User email' | translate }}</th>
            </tr>
            <tr *ngFor="let email of demand?.emailVendorAuthorized; index as i">
              <td><strong>{{ i }}- {{email}}</strong></td>
            </tr>
          </table>
        </div>
        <mat-card *ngIf="!download" class="editor-email">
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;">{{ 'Editor email access' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
             <mat-chip-set>
            <mat-chip *ngFor="let email of demand?.emailVendorAuthorized; index as i">{{email}}</mat-chip>
          </mat-chip-set>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>
    <br />

    <mat-card class="user-access-card">
      <mat-card-header>
        <mat-card-title>
          <span class="title" style="color: #5bc0de; font-weight: bold;">
            {{ 'Form informations' | translate }}
          </span>
          <br *ngIf="download === true"  />
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <!-- Design form -->
        <mat-card>
        <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;"><span class="title" style="color: #5bc0de;">{{ 'Design form' | translate }}</span></mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>
              <p>Version json-FR: <strong>{{ designForm?.jsonFR }}</strong></p>
              <p>Version json-US: <strong>{{ designForm?.jsonUS }}</strong></p>
              <p>
                {{ 'Formulaire créé le' | translate }}:
                <strong *ngIf="!designForm?.createdAt">
                  {{ 'The vendor/PM/SO has not yet accessed the form'  }}
                </strong>
                <strong *ngIf="designForm?.createdAt">
                  {{ convertDate(designForm?.createdAt) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'status of the current form' | translate }}:
                <strong *ngIf="!designForm?.designStatus">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="designForm?.designStatus">
                  {{ designForm?.designStatus }}
                </strong>
              </p>
              <p>
                {{ 'Last update of the form' | translate }}:
                <strong *ngIf="!designForm?.lastUpdate">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="designForm?.lastUpdate">
                  {{ convertDate(designForm?.lastUpdate) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'lastUpdateBy of the form' | translate }}:
                <strong>
                  {{ designForm?.lastUpdateBy || 'The vendor did not begin to complete the form' }}
                </strong>
              </p>
              <p>
                {{ 'firstUpdate of the form' | translate }}:
                <strong *ngIf="!designForm?.firstUpdate">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="designForm?.firstUpdate">
                  {{ convertDate(designForm?.firstUpdate) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'firstUpdateBy of the form' | translate }}:
                <strong>
                  {{ designForm?.firstUpdateBy || 'The vendor did not begin to complete the form' }}
                </strong>
              </p>
            </div>
          </mat-card-content>
        </mat-card>
        <!-- Legal form -->
        <mat-card>
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;">
              <span class="title" style="color: #5bc0de;">
                {{ 'Legal form' | translate }}
              </span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>
              <p>Version json-FR: <strong>{{ legalForm?.jsonFR }}</strong></p>
              <p>Version json-US: <strong>{{ legalForm?.jsonUS }}</strong></p>
              <p>
                {{ 'Formulaire créé le' | translate }}:
                <strong *ngIf="!legalForm?.createdAt">
                  {{ 'The vendor/PM/SO has not yet accessed the form'  }}
                </strong>
                <strong *ngIf="legalForm?.createdAt">
                  {{ convertDate(legalForm?.createdAt) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'status of the current form' | translate }}:
                <strong *ngIf="!legalForm?.legalStatus">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="legalForm?.legalStatus">
                  {{ legalForm?.legalStatus }}
                </strong>
              </p>
              <p>
                {{ 'Last update of the form' | translate }}:
                <strong *ngIf="!legalForm?.lastUpdate">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="legalForm?.lastUpdate">
                  {{ convertDate(legalForm?.lastUpdate) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'lastUpdateBy of the form' | translate }}:
                <strong>
                  {{ legalForm?.lastUpdateBy || 'The vendor did not begin to complete the form' }}
                </strong>
              </p>
              <p>
                {{ 'firstUpdate of the form' | translate }}:
                <strong *ngIf="!legalForm?.firstUpdate">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="legalForm?.firstUpdate">
                  {{ convertDate(legalForm?.firstUpdate) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'firstUpdateBy of the form' | translate }}:
                <strong>
                  {{ legalForm?.firstUpdateBy || 'The vendor did not begin to complete the form' }}
                </strong>
              </p>
            </div>
          </mat-card-content>
        </mat-card>

        <!-- Q32 Form -->
        <mat-card>
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;">
              <span class="title" style="color: #5bc0de;">
                {{ 'Article 32 form' | translate }}
              </span>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div>
              <p>Version json-FR: <strong>{{ article32Form?.jsonFR }}</strong></p>
              <p>Version json-US: <strong>{{ article32Form?.jsonUS }}</strong></p>
              <p>
                {{ 'Formulaire créé le' | translate }}:
                <strong *ngIf="!article32Form?.createdAt">
                  {{ 'The vendor/PM/SO has not yet accessed the form'  }}
                </strong>
                <strong *ngIf="article32Form?.createdAt">
                  {{ convertDate(article32Form?.createdAt) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'status of the current form' | translate }}:
                <strong *ngIf="!article32Form?.article32Status">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="article32Form?.article32Status">
                  {{ article32Form?.article32Status }}
                </strong>
              </p>
              <p>
                {{ 'Last update of the form' | translate }}:
                <strong *ngIf="!article32Form?.lastUpdate">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="article32Form?.lastUpdate">
                  {{ convertDate(article32Form?.lastUpdate) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'lastUpdateBy of the form' | translate }}:
                <strong>
                  {{ article32Form?.lastUpdateBy || 'The vendor did not begin to complete the form' }}
                </strong>
              </p>
              <p>
                {{ 'firstUpdate of the form' | translate }}:
                <strong *ngIf="!article32Form?.firstUpdate">
                  {{ 'The vendor did not begin to complete the form' }}
                </strong>
                <strong *ngIf="article32Form?.firstUpdate">
                  {{ convertDate(article32Form?.firstUpdate) | date: 'MMMM d, y, h:mm a' }}
                </strong>
              </p>
              <p>
                {{ 'firstUpdateBy of the form' | translate }}:
                <strong>
                  {{ article32Form?.firstUpdateBy || 'The vendor did not begin to complete the form' }}
                </strong>
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>
    <br />
     <mat-card class="solution-request-informations">
      <mat-card-header>
        <mat-card-title>
          <span class="title" style="color: #5bc0de; font-weight: bold;">
            {{ 'Solution/Request informations' | translate }}
          </span>
          <br *ngIf="download === true"  />
        </mat-card-title>
      </mat-card-header>
      <!-- Primary informations -->
      <mat-card-content>
        <mat-card class="primary-informations">
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;">Primary informations</mat-card-title>
          </mat-card-header>
          <mat-card-content>
             <p>
              {{ 'full Name initiator' | translate }}: <strong>{{ demand?.fullNameInitiatorVeolia }}</strong>
              </p>
              <p>
              {{ 'email initiator' | translate }}: <strong>{{ demand?.emailInitiatorVeolia }}</strong>
              </p>
              <p>
              {{ 'Solution owner' | translate }}: <strong>{{ demand?.solutionOwner }}</strong>
              </p>
              <p>
              {{ 'bu' | translate }}: <strong>{{ demand?.businessUnitOfSolution }}</strong>
              </p>
          </mat-card-content>
        </mat-card>
        <br />
        <!-- About the request -->
        <mat-card class="about-request">
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;">Informations about the request</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="demand?.requestDate && isDate(demand?.requestDate)">
              {{ 'Date de demande' | translate }}: <strong>{{  convertDate(demand?.requestDate) | date: 'longDate' }}</strong>
            </p>
            <p>
              {{ 'Request validation date' | translate }}: <strong *ngIf="demand?.fullyEvaluated===true; else elseRequest">{{   convertDate(demand?.expectedEvaluationDate) | date: 'longDate' }}</strong>
              <ng-template #elseRequest><strong>N/A</strong></ng-template>
            </p>
            <p>
              {{ 'Request status' | translate }}: <strong>{{  demand?.status }}</strong>
            </p>
            <p *ngIf="demand?.lastUpdate">
              {{ 'Last update of application status' | translate }}: <strong *ngIf="isString(demand?.lastUpdate); else elseBlock">{{ demand?.lastUpdate }}</strong>
              <ng-template #elseBlock><strong>{{ convertDate(demand?.lastUpdate) | date: 'longDate' }}</strong></ng-template>
            </p>
            <!--Seulement si Design + Legal + Art32 évalué => fullyEvaluated=true -->
            <p *ngIf="demand?.fullyEvaluated===true">
              {{ "Date de l'évaluation finale" | translate }}: <strong>{{  convertDate(demand?.finalEvaluationDate) | date: 'longDate' }}</strong>
            </p>
          </mat-card-content>
        </mat-card>
        <br />
        <!-- Main details -->
        <mat-card class="main-details">
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;">Main details</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              {{ 'Solution type' | translate }}: <strong>{{  demand?.type }}</strong>
            </p>
            <p>
              {{ 'Nom RFP' | translate }}: <strong>{{  demand?.rfpName }}</strong>
            </p>
            <p>
              {{ 'Domain' | translate }}: <strong>{{  demand?.domainOfSolution }}</strong>
            </p>
            <p>
              {{ 'Pouvez-vous décrire le besoin ?' | translate }}: <strong>{{ demand?.description }}</strong>
            </p>
            <p>
              {{ 'Who will access the solution ?' | translate }}: <strong>{{ demand?.accessQuestion }}</strong>
            </p>
            <p>
              {{'Will the solution have to integrate within Google Workspace? (Drive, GMail, Doc, Sheet...) ?' | translate }}: <strong>{{  demand?.integrateQuestion || 'No' }}</strong>
            </p>
            <p>
              {{ 'Do you know if the solution will have to interconnect with 3rd party applications ?' | translate }}: <strong>{{  demand?.interconnectQuestion }}</strong>
            </p>
            <p>
              {{ 'Please give more details about interconnection with 3rd party applications' | translate }}: <strong>{{  demand?.interconnectQuestion2 }}</strong>
            </p>
            <p>
              {{ 'Communication with 3rd parties' | translate }}: <strong>{{  demand?.communicationQuestion }}</strong>
            </p>
            <p>
              {{ 'Date completion attendue' | translate }}: <strong *ngIf="demand?.expectedCompletionDate && isDate(demand?.expectedCompletionDate)">{{  convertDate(demand?.expectedCompletionDate) | date }}</strong>
            </p>
            <p>
              {{ 'Date eval attendue' | translate }}: <strong *ngIf="demand?.expectedEvaluationDate && isDate(demand?.expectedEvaluationDate)">{{  convertDate(demand?.expectedEvaluationDate) | date }}</strong>
            </p>
            <p>
              {{ 'expectedOnlineReleaseDate' | translate }}: <strong *ngIf="demand?.expectedOnlineReleaseDate && isDate(demand?.expectedOnlineReleaseDate)">{{  convertDate(demand?.expectedOnlineReleaseDate) | date }}</strong>
            </p>
            <p>
              {{ 'Nombre de solution à évaluer' | translate }}: <strong>{{  demand?.nbSolution }}</strong>
            </p>
          </mat-card-content>
        </mat-card>
        <br />
        <!-- Solution -->
        <mat-card class="solution">
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;">The solution</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p>
              {{ 'Nom de la solution à évaluer' | translate }}: <strong>{{  demand?.solutionName }}</strong>
              </p>
              <p>
              {{ 'Nom editeur' | translate }}: <strong>{{  demand?.editorBrand }}</strong>
              </p>
              <p>
              {{ 'Solution is already in use / DLA renewal ?' | translate }}: <strong>{{  demand?.inPlace }}</strong>
              </p>
              <p>
              {{ 'If the solution is already in use, please provide its APM number (if available)' | translate }}: <strong>{{  demand?.apmNumber }}</strong>
              </p>
              <p>
              {{ 'Lien app' | translate }}: <strong>{{  demand?.link }}</strong>
              </p>
          </mat-card-content>
        </mat-card>
        <br />
        <!-- Main contact -->
        <mat-card class="main-contact">
          <mat-card-header>
            <mat-card-title translate style="color: #5bc0de;">Main contact</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div style="text-align : left">
              <p>
              {{ 'Email editeur' | translate }}: <strong>{{  demand?.mainEmailVendor }}</strong>
              </p>
              <p>
              {{ 'nom' | translate }}: <strong>{{  demand?.mainLastnameVendor }}</strong>
              </p>
              <p>
              {{ 'prenom' | translate }}: <strong>{{  demand?.mainFirstnameVendor }}</strong>
              </p>
              <p>
              {{ 'entreprise' | translate }}: <strong>{{  demand?.mainCompanyVendor }}</strong>
              </p>
              <p>
              {{ 'Lien app commercial' | translate }}: <strong>{{  demand?.linkCom }}</strong>
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
