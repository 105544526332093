<div class="container">
  <mat-card>
    <mat-card-content>

      <mat-icon>code</mat-icon>
      <span translate>Version </span>v2.4.39-9/10/2023

      <h3 style="color:#00aec7" translate>Mentions légales</h3>
      <br />

      <section style="text-align:justify">

        <p translate>cgu_text1</p>
        <ul>
          <li translate>pro_email</li>
          <li translate>first_and_lastname</li>
          <li translate>google_picture</li>
          <li translate>bu</li>
          <li translate>comments</li>
          <li translate>files</li>
          <li translate>logs</li>
        </ul>
        <p translate>cgu_text2</p>
        <p translate>cgu_text3</p>

        <br />

        <p translate>cgu_text4</p>

        <br />

        <p translate>cgu_text5</p>

        <br />

        <p translate>cgu_text6</p>

      </section>


    </mat-card-content>
  </mat-card>
</div>
