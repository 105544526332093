import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from './shell/shell.service';
import { EditDemandeDlaResolver } from './homeUser/editDemandeDla/editDemandeDla.resolver';

import { HomeComponent } from './home/home.component';
import { GestionVendorResolver } from './homeUser/gestionVendor/gestionVendor.resolver';
import { FirstConnectionResolver } from './firstConnection/firstConnection.resolver';

import { FormulaireDaResolver } from './homeUser/formulairesAccess/formulaireAll/formulaireDa.resolver';
import { FormulaireLaResolver } from './homeUser/formulairesAccess/formulaireAll/formulaireLa.resolver';
import { TableauEvalsResolver } from './homeUser/tableauEvals/tableauEvals.resolver';
import { LogsResolver } from './homeUser/logs/logs.resolver';
import { FormulaireA28Resolver } from './homeUser/formulairesAccess/formulaireAll/formulaireA28.resolver';
import { FormulaireA32Resolver } from './homeUser/formulairesAccess/formulaireAll/formulaireA32.resolver';
import { EditPasswordResolver } from './editPassword/editPassword.resolver';
import { SolutionResolver } from './homeUser/formulairesAccess/solution.resolver';
import { ValidateSolutionResolver } from './admin/validate-solution.resolver.';
import { FormulaireIaaSResolver } from './homeUser/formulairesAccess/formulaireAll/formulaireIaaS.resolver';

import { AuthGuard } from './core/auth.guard';
import { AdminGuard } from './core/admin.guard';
import { AccessFormsGuard } from './core/access-forms.guard';
import { AccessEditDemandeGuard } from './core/access-editDemande.guard';
import { AccessGestionVendorGuard } from './core/access-gestionVendor.guard';
import { AccessEditPasswordGuard } from './core/access-editPassword.guard';
import { AccessFirstConnectionGuard } from './core/access-firstConnection.guard';
import { AccessLogsGuard } from './core/access-logs.guard';
import { AccessTableauEvalsGuard } from './core/access-tableauEvals.guard';
import { AccessProfileGuard } from './core/access-profile.guard';
import { EvaluatorGuard } from './core/evaluator.guard';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'home', 
      loadChildren: () => import('app/home/home.module').then(x => x.HomeModule)
    },
    
    { path: 'homeUser', 
      loadChildren: () => import('app/homeUser/homeUser.module').then(x => x.HomeUserModule),
      canActivate: [AuthGuard] 
    },
    
    { path: 'evaluator', 
      loadChildren: () => import('app/evaluator/evaluator.module').then(x => x.EvaluatorModule),
      canActivate: [EvaluatorGuard] 
    },
    
    {
      path: 'firstConnection/:id',
      loadChildren: () => import('app/firstConnection/firstConnection.module').then(x => x.FirstConnectionModule),
      resolve: { data: FirstConnectionResolver },
      canActivate: [AuthGuard, AccessFirstConnectionGuard]
    }, //page infos user à compléter lors de la 1ere connexion
    
    {
      path: 'editPassword/:id',
      loadChildren: () => import('app/editPassword/editPassword.module').then(x => x.EditPasswordModule),
      resolve: { data: EditPasswordResolver },
      canActivate: [AuthGuard, AccessEditPasswordGuard]
    }, //page changement de pass

    {
      path: 'demandeDLA',
      loadChildren: () => import('app/homeUser/demandeDla/demandeDla.module').then(x => x.DemandeDlaModule),
      canActivate: [AuthGuard]
    },
    
    {
      path: 'editDemandeDla/:id/:type',
      loadChildren: () => import('app/homeUser/editDemandeDla/editDemandeDla.module').then(x => x.EditDemandeDlaModule),
      resolve: { data: EditDemandeDlaResolver },
      canActivate: [AuthGuard, AccessEditDemandeGuard]
    },
    
    {
      path: 'validateSolution/:id/:type',
      loadChildren: () => import('app/admin/validate-solution.module').then(x => x.ValidateSolutionModule),
      resolve: { data: ValidateSolutionResolver },
      canActivate: [AuthGuard, AdminGuard]
    },

    {
      path: 'profile/:id',
      loadChildren: () => import('app/homeUser/profile/profile.module').then(x => x.ProfileModule),
      canActivate: [AuthGuard, AccessProfileGuard]
    },

    {
      path: 'gestionVendor/:id/:type',
      loadChildren: () => import('app/homeUser/gestionVendor/gestionVendor.module').then(x => x.GestionVendorModule),
      resolve: { data: GestionVendorResolver },
      canActivate: [AuthGuard, AccessGestionVendorGuard]
    },

    {
      path: 'tableauEvals/:id/:type',
      loadChildren: () => import('app/homeUser/tableauEvals/tableauEvals.module').then(x => x.TableauEvalsModule),
      resolve: { data: TableauEvalsResolver },
      canActivate: [AuthGuard, AccessTableauEvalsGuard]
    },
    
    {
      path: 'designForm/:id',
      loadChildren: () => import('app/homeUser/formulairesAccess/designForm/designForm.module').then(x => x.DesignFormModule),
      resolve: { dataSolution: SolutionResolver, dataDesignForm: FormulaireDaResolver },
      canActivate: [AuthGuard, AccessFormsGuard]
    },
    
    {
      path: 'legalForm/:id',
      loadChildren: () => import('app/homeUser/formulairesAccess/legalForm/legalForm.module').then(x => x.LegalFormModule),
      resolve: { dataSolution: SolutionResolver, dataLegalForm: FormulaireLaResolver },
      canActivate: [AuthGuard, AccessFormsGuard]
    },
    
    {
      path: 'article32Form/:id',
      loadChildren: () => import('app/homeUser/formulairesAccess/article32Form/article32Form.module').then(x => x.Article32FormModule),
      resolve: { dataSolution: SolutionResolver, dataArticle32Form: FormulaireA32Resolver },
      canActivate: [AuthGuard, AccessFormsGuard]
    },
    
    {
      path: 'article28Form/:id',
      loadChildren: () => import('app/homeUser/formulairesAccess/article28Form/article28Form.module').then(x => x.Article28FormModule),
      resolve: { dataSolution: SolutionResolver, dataArticle28Form: FormulaireA28Resolver },
      canActivate: [AuthGuard, AccessFormsGuard]
    },
    
    {
      path: 'IaaSForm/:id',
      loadChildren: () => import('app/homeUser/formulairesAccess/iaasForm/iaasForm.module').then(x => x.IaaSFormModule),
      resolve: { dataSolution: SolutionResolver, dataIaaSForm: FormulaireIaaSResolver },
      canActivate: [AuthGuard, AccessFormsGuard]
    },
    
    {
      path: 'logs/:id/:type',
      loadChildren: () => import('app/homeUser/logs/logs.module').then(x => x.LogsModule),
      resolve: { data: LogsResolver },
      canActivate: [AuthGuard, AccessLogsGuard]
    },

    { path: 'admin',
      loadChildren: () => import('app/admin/admin.module').then(x => x.AdminModule),
      canActivate: [AdminGuard] 
    },

    { path: 'contact',
      loadChildren: () => import('app/contact/contact.module').then(x => x.ContactModule),
      canActivate: [AuthGuard]
    },
    
    { path: 'about',
      loadChildren: () => import('app/about/about.module').then(x => x.AboutModule)
    }
    
  ]),

  

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/homeUser', pathMatch: 'full' } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  //RouterModule.forRoot(routes, {enableTracing: true}),
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
