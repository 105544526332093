import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HomeUserService } from '../homeUser.service';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase/compat/app';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AuthService } from '../../core/auth.service';
import { FormulaireAllService } from '../formulairesAccess/formulaireAll/formulaireAll.service';
import { SummaryEvaluationComponent } from './summary-evaluation.component';
import { ReservationsEvaluationComponent } from './reservations-evaluation.component';
import { ReservationsListComponent } from './reservations-list.component';
import { SummarySolutionComponent } from './summary-solution.component';


@Component({
  selector: 'app-tableauEvals',
  templateUrl: './tableauEvals.component.html',
  styleUrls: ['./tableauEvals.component.scss']
})
export class TableauEvalsComponent implements OnInit {
  solutionName: string;
  item: any;
  idSolution: string;
  businessUnitOfSolution: string;

  type: string;

  //SaaS//
  statusDesignForm: string;
  statusLegalForm: string;
  statusArticle32Form: string;
  statusArticle28Form: string;

  //IaaS
  statusIaaSForm: string;

  @ViewChild(SummaryEvaluationComponent, { static: false }) summaryEvaluationComponent: SummaryEvaluationComponent; //getEvaluation dans la vue au clic du modale
  @ViewChild(ReservationsEvaluationComponent, { static: false }) reservationsEvaluationComponent: ReservationsEvaluationComponent;
  @ViewChild(ReservationsListComponent, { static: false }) reservationsListComponent: ReservationsListComponent;
  @ViewChild(SummarySolutionComponent, { static: false }) summarySolutionComponent: SummarySolutionComponent;

  userIsInEmailEvaluatorAuthorized = false;
  userIsInEmailVeoliaAuthorized = false;
  userIsInEmailVendorAuthorized = false;
  userIsInEvaluatorsBu = false;
  isSoOfSolution = false;

  constructor(
    public homeUserService: HomeUserService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private firestore: AngularFirestore,
    public auth: AuthService,
    public afs: AngularFireDatabase,
    public formservice: FormulaireAllService
  ) {}

  ngOnInit() {
    //on récupère les données issues de la demande
    this.route.data.subscribe(routeData => {
      const data = routeData['data'];
      if (data) {
        this.item = data.payload.data();
        this.item.id = data.payload.id;
      }
    });

    this.solutionName = this.item.solutionName;
    this.idSolution = this.item.id;
    this.businessUnitOfSolution = this.item.businessUnitOfSolution;

    //Pour récupérer dans le service l'id de la demande pour l'associé à l'évaluation
    this.formservice.getSolutionId(this.idSolution);

    //get type
    this.route.params.subscribe((params: Params) => this.type = params['type']);
    console.log('type',this.type)

    if(this.type === 'SaaS') {
      this.getEvaluationForDesignForm(this.idSolution);
      this.getEvaluationForLegalForm(this.idSolution);
      this.getEvaluationForArticle32Form(this.idSolution);
      this.getEvaluationForArticle28Form(this.idSolution);
    }

    if(this.type === 'IaaS') {
      this.getEvaluationForIaaSForm(this.idSolution);
    }

    if(this.type === 'PaaS') {
      //empty
    }

    if(this.type === 'Apps') {
      //empty
    }

    //check si user est autorisé à accéder aux formulaires et actionsButtons

      //le user est dans emailEvaluatorAuthorized de la solution
      firebase.firestore().collection('solutions').doc(this.idSolution).get().then(val2 => {
        if(!val2.data().emailEvaluatorAuthorized.find(x => x === this.auth.currentUserEmail)){

          this.userIsInEmailEvaluatorAuthorized = false;
        } else {
          console.log("USER IS IN emailEvaluatorAuthorized")
          this.userIsInEmailEvaluatorAuthorized = true;
        }
      });

      //le user est dans emailVeoliaAuthorized de la solution
      firebase.firestore().collection('solutions').doc(this.idSolution).get().then(val2 => {
        if(!val2.data().emailVeoliaAuthorized.find(x => x === this.auth.currentUserEmail)){

          this.userIsInEmailVeoliaAuthorized = false;
        } else {
          console.log("USER IS IN emailVeoliaAuthorized")
          this.userIsInEmailVeoliaAuthorized = true;
        }
      });

      if(this.type === 'SaaS') {
        //le user est dans emailVendorAuthorized de la solution (Only SaaS)
        firebase.firestore().collection('solutions').doc(this.idSolution).get().then(val2 => {
          if(!val2.data().emailVendorAuthorized.find(x => x === this.auth.currentUserEmail)){
            console.log("USER IS NOT IN emailVendorAuthorized")
            this.userIsInEmailVendorAuthorized = false;
          } else {
            console.log("USER IS IN emailVendorAuthorized")
            this.userIsInEmailVendorAuthorized = true;
          }
        });
      }

      //le user est sous ‘evaluators’ de la BU
      firebase.firestore().collection('businessUnit').where('nomBu', '==', this.businessUnitOfSolution)
        .get().then(snapshot => {
            snapshot.forEach(doc => {
              if(!doc.data().evaluators.find(x => x === this.auth.currentUserEmail)){ //GET evaluators for the BU matching with Bu of solution
                console.log("USER IS NOT IN evaluators for the BU")
                this.userIsInEvaluatorsBu = false;
              } else {
                console.log("USER IS IN evaluators for the BU")
                this.userIsInEvaluatorsBu = true;
              }
            });
          });

    //vérifie si le user est SO de la demande
    firebase.firestore().collection('solutions').where('solutionOwner', '==', this.auth.currentUserEmail).where(firebase.firestore.FieldPath.documentId(), '==', this.idSolution)
      .get().then(snapshot => {
        if (snapshot.empty) {
          //si false
          console.log('The user is not SO of solution');
        } else {
          console.log('User is SO of solution');
          this.isSoOfSolution = true;
        }
      });
    //setTimeout(function(){ this.getEvaluationForDesignForm(this.idSolution); }, 1000);
  }

  //SaaS//
  getEvaluationForDesignForm(idSolution) {
    let myRef = firebase.database().ref(`evaluations/Design Form/${idSolution}`);
    myRef.limitToFirst(1).once('value', snap => {
      //ou  function(snapshot) { mais variable globale ne marchera pas

      if (snap.exists()) {
        snap.forEach(elem => {
          this.statusDesignForm = elem.val().status;

          setTimeout(function() {
            (window as any).getColorDesignEval(elem.val().status);
          }, 1200);
        });
      } else {
        //Aucune évaluation n'a encore été faite
        this.statusDesignForm = 'No evaluation started';
      }
    });

    return this.statusDesignForm;
  }

  getEvaluationForLegalForm(idSolution) {
    let myRef = firebase.database().ref(`evaluations/Legal Form/${idSolution}`);
    myRef.limitToFirst(1).once('value', snap => {
      //ou  function(snapshot) { mais variable globale ne marchera pas

      if (snap.exists()) {
        snap.forEach(elem => {
          this.statusLegalForm = elem.val().status;

          setTimeout(function() {
            (window as any).getColorLegalEval(elem.val().status);
          }, 1200);
        });
      } else {
        //Aucune évaluation n'a encore été faite
        this.statusLegalForm = 'No evaluation started';
      }
    });

    return this.statusLegalForm;
  }

  getEvaluationForArticle32Form(idSolution) {
    let myRef = firebase.database().ref(`evaluations/Article 32 Form/${idSolution}`);
    myRef.limitToFirst(1).once('value', snap => {
      //ou  function(snapshot) { mais variable globale ne marchera pas

      if (snap.exists()) {
        snap.forEach(elem => {
          this.statusArticle32Form = elem.val().status;

          setTimeout(function() {
            (window as any).getColorArticle32Eval(elem.val().status);
          }, 1200);
        });
      } else {
        //Aucune évaluation n'a encore été faite
        this.statusArticle32Form = 'No evaluation started';
      }
    });

    return this.statusArticle32Form;
  }

  getEvaluationForArticle28Form(idSolution) {
    let myRef = firebase.database().ref(`evaluations/Article 28 Form/${idSolution}`);
    myRef.limitToFirst(1).once('value', snap => {
      //ou  function(snapshot) { mais variable globale ne marchera pas

      if (snap.exists()) {
        snap.forEach(elem => {
          this.statusArticle28Form = elem.val().status;

          setTimeout(function() {
            (window as any).getColorArticle28Eval(elem.val().status);
          }, 1200);
        });
      } else {
        //Aucune évaluation n'a encore été faite
        this.statusArticle28Form = 'No evaluation started';
      }
    });

    return this.statusArticle28Form;
  }

  //IaaS//
  getEvaluationForIaaSForm(idSolution) {
    let myRef = firebase.database().ref(`evaluations/IaaS Form/${idSolution}`);
    myRef.limitToFirst(1).once('value', snap => {
      //ou  function(snapshot) { mais variable globale ne marchera pas

      if (snap.exists()) {
        snap.forEach(elem => {
          this.statusIaaSForm = elem.val().status;

          setTimeout(function() {
            (window as any).getColorIaaSEval(elem.val().status);
          }, 1200);
        });
      } else {
        //Aucune évaluation n'a encore été faite
        this.statusIaaSForm = 'No evaluation started';
      }
    });

    return this.statusIaaSForm;
  }

  /////////FORMULAIRES/////////

  //Formulaire DESIGN
  gotoDesignForm(id: string) {
    this.homeUserService.initializeDesignForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire LEGAL
  gotoLegalForm(id: string) {
    this.homeUserService.initializeLegalForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 32
  gotoArticle32Form(id: string) {
    this.homeUserService.initializeArticle32Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Formulaire ARTICLE 28
  gotoArticle28Form(id: string) {
    this.homeUserService.initializeArticle28Form(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //IaaS
  gotoIaaSForm(id: string) {
    this.homeUserService.initializeIaaSForm(id); //initialisation du formulaire puis redirection au sein de la fonction
  }

  //Demande séléctionée dans le tableau pour afficher ses infos dans une autre pages avec redirection des données
  modifierDemande(id: string, type: string) {
    this.router.navigate(['/editDemandeDla/' + id + '/' + type]); //{ this.router.navigate(['/details/'+ item.payload.doc.id]);}
  }

  //redirection vers la page de création du compte pour le vendor
  creationCompteVendor(id: string, type: string) {
    this.router.navigate(['/gestionVendor/' + id + '/' + type]);
  }

  //redirection vers la page des logs pour la demande
  logs(id: string, type: string) {
    this.router.navigate(['/logs/' + id + '/' + type]);
  }

  //redirection sur le tableau de synthese des forms avec l'éval actuelle par form
  tableauEvals(id: string, type: string) {
    this.router.navigate(['/tableauEvals/' + id + '/' + type]);
  }

}
