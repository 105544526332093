import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { I18nService } from '../core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from '../core/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  
  changePassForm: FormGroup;
  public oldPassword: string;
  public newPassword: string;

  validation_messages = {
    newPassword: [{ type: 'required', message: '*Valid password is required with 10 characters' }],
    oldPassword: [{ type: 'required', message: '*Valid password is required with 10 characters' }]
  };

  pwd = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%-_?/]).{10}$'; // au moins 1 chiffre/lettre min maj/car spécial / 10 de longeur peu importe l'ordre

  constructor(
    private i18nService: I18nService,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public auth: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit() {
    // Automatically close side menu on screens > sm breakpoint
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.toastr.error(error.message, 'Error', {
      timeOut: 10000,
      positionClass: 'toast-top-center'
    });
  }


  //Pour mettre en subrillance le bouton
  isHomeRoute() {
    return this.router.url === '/home';
  }
  isDemandeDlaRoute() {
    return this.router.url === '/demandeDLA';
  }
  isDemandePreDlaRoute() {
    return this.router.url === '/about';
  }
  isFichePersoRoute() {
    return this.router.url === '/fichePersoUser';
  }
  isHomeUserRoute() {
    return this.router.url === '/homeUser';
  }
  isEvaluatorRoute() {
    return this.router.url === '/about';
  }
  isContactRoute() {
    return this.router.url === '/contact';
  }
  isAdminRoute() {
    return this.router.url === '/admin';
  }
  
}
