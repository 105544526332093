<div class="container">
  <mat-card>
   
    <mat-card-content>
      <mat-card-title id="titre_form" translate style="color:red">Vos informations personnelles</mat-card-title>
      <br>
      
      <div *ngIf="(auth.user | async) as user">
      
      <!-- USER VEOLIA -->
      
      <!-- Formulaire 1ère connexion -->
      <form 
        *ngIf="firstConnectionForm && auth.isViewer(user)"
        [formGroup]="firstConnectionForm"
        novalidate
        (ngSubmit)="onSubmit(firstConnectionForm.value)"
        class="firstCo-form"
      >
        
          <div style="display:grid">
            <mat-form-field appearance="outline">
              <mat-label translate>Your country</mat-label>
              <select
                matNativeControl
                formControlName="country"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.country.errors }"
              >
                <option selected translate>Veuillez choisir</option>
                <option *ngFor="let country of countryList" translate [value]="country.name">
                  {{ country.name }}
                </option>
              </select>
              <span
              class="text-danger"
              *ngIf="(f.country.touched || submitted) && f.country.errors?.required"
            >
              <em style="font-size: small;">Country required</em>
            </span>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label translate>Votre Business unit</mat-label>
              <select
                matNativeControl
                formControlName="businessUnit"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.businessUnit.errors }"
              >
                <option selected translate>Veuillez choisir</option>
                <option *ngFor="let businessUnit of listBU" translate [value]="businessUnit.nomBu">
                  {{ businessUnit.nomBu }}
                </option>
              </select>
              <span
              class="text-danger"
              *ngIf="(f.businessUnit.touched || submitted) && f.businessUnit.errors?.required"
            >
              <em style="font-size: small;">Domain required</em>
            </span>
            </mat-form-field>

            <mat-form-field>
              <mat-label translate>Votre role</mat-label>
              <input
                matInput
                placeholder="{{'Votre role' |translate}}"
                class="form-control"
                formControlName="role"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label translate>Votre nom et prénom</mat-label>
              <input
                matInput
                placeholder="{{'Votre nom et prénom' |translate}}"
                class="form-control readonly"
                formControlName="displayName"
                readonly
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label translate>Votre email</mat-label>
              <input
                matInput
                placeholder="{{'Votre email' |translate}}"
                class="form-control"
                formControlName="email"
                readonly
              />
            </mat-form-field>

            <mat-form-field class="readonly" style="display: none">
              <input matInput class="form-control" formControlName="uid" />
            </mat-form-field>

            <mat-form-field class="readonly" style="display: none">
              <input matInput class="form-control" formControlName="photoURL" />
            </mat-form-field>
            <br />
          </div>
          
          <mat-card>
            <!--terms-->
            <section style="padding: 10px">
        
            <p translate>cgu_text1</p>
            <ul>
              <li translate>pro_email</li>
              <li translate>first_and_lastname</li>
              <li translate>google_picture</li>
              <li translate>bu</li>
              <li translate>comments</li>
              <li translate>files</li>
              <li translate>logs</li>
            </ul>
            <p translate>cgu_text2</p>
            <p translate>cgu_text3</p>
            
            <br />
            
            <p translate>cgu_text4</p>
            
            <br />
            
            <p translate>cgu_text5</p>
            
            <br />
            
            <p translate>cgu_text6</p>
            
          </section>
          
          <mat-label translate class="radio-title" style="padding: 10px">Do you agree with terms ?</mat-label>
          <mat-radio-group class="radio-group" formControlName="terms_RADIO">
            <mat-radio-button class="radio-button" value="Yes" >Yes</mat-radio-button>
            <mat-radio-button class="radio-button" value="No" >No</mat-radio-button>
            <span class="text-danger" *ngIf="(f.terms_RADIO.touched || submitted) && f.terms_RADIO.errors?.required">
              <br />
              <em style="font-size: small;">Yes required</em>
            </span>
            <br />
           <span *ngIf="this.firstConnectionForm.get('terms_RADIO').value === 'No' || !this.firstConnectionForm.get('terms_RADIO').value" style="color: red" translate>('Yes' is required to get access to the application)</span>
          </mat-radio-group>
        </mat-card>
        <br />

            <div class="row submit-button-container">
              <div class="col-md-4">
                <button translate mat-raised-button class="submit-button" style="color: white;background: #00aec7;" type="submit">
                  {{ 'OK' | translate }}
                </button>
              </div>

              
            </div>
          
        
      </form>
      
      
      <!-- USER VENDOR -->
      
      <!-- Formulaire 1ère connexion -->
      <form 
        *ngIf="firstConnectionForm2 && auth.isVendor(user)"
        [formGroup]="firstConnectionForm2"
        novalidate
        (ngSubmit)="onSubmit2(firstConnectionForm2.value)"
        class="firstCo-form"
      >
          
          <mat-card>
            <!--terms-->
            <section style="padding: 10px">
        
            <p translate>cgu_text1</p>
            <ul>
              <li translate>pro_email</li>
              <li translate>first_and_lastname</li>
              <li translate>google_picture</li>
              <li translate>bu</li>
              <li translate>comments</li>
              <li translate>files</li>
              <li translate>logs</li>
            </ul>
            <p translate>cgu_text2</p>
            <p translate>cgu_text3</p>
            
            <br />
            
            <p translate>cgu_text4</p>
            
            <br />
            
            <p translate>cgu_text5</p>
            
            <br />
            
            <p translate>cgu_text6</p>
            
          </section>
          
          <mat-label translate class="radio-title" style="padding: 10px">Do you agree with terms ?</mat-label>
          <mat-radio-group class="radio-group" formControlName="terms_RADIO">
            <mat-radio-button class="radio-button" value="Yes" >Yes</mat-radio-button>
            <mat-radio-button class="radio-button" value="No" >No</mat-radio-button>
            <span class="text-danger" *ngIf="(f2.terms_RADIO.touched || submitted) && f2.terms_RADIO.errors?.required">
              <br />
              <em style="font-size: small;">Yes required</em>
            </span>
            <br />
           <span *ngIf="this.firstConnectionForm2.get('terms_RADIO').value === 'No' || !this.firstConnectionForm2.get('terms_RADIO').value" style="color: red" translate>('Yes' is required to get access to the application)</span>
          </mat-radio-group>
        </mat-card>
        <br />

            <div class="row submit-button-container">
              <div class="col-md-4">
                <button translate mat-raised-button class="submit-button" style="color: white;background: #00aec7;" type="submit">
                  {{ 'OK' | translate }}
                </button>
              </div>

              
            </div>
          
        
      </form>
      
      </div>
      
    </mat-card-content>
  </mat-card>
</div>
