import { Component, OnInit } from '@angular/core';
import { FormulaireAllService } from '../formulairesAccess/formulaireAll/formulaireAll.service';
import { ToastrService } from 'ngx-toastr';
import firebase from 'firebase/compat/app';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Evaluation } from '../formulairesAccess/formulaireAll/evaluation.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { first } from 'rxjs/operators';
import { AuthService } from '../../core/auth.service';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-summary-solution',
  templateUrl: './summary-solution.component.html',
  styleUrls: ['./summary-solution.component.scss']
})
export class SummarySolutionComponent implements OnInit {
  currentEvaluation: Evaluation[];
  isSoOfSolution = false; //on vérifie si le user est solution owner de la solution
  idSol: string;
  
  //données de la demande
  //SaaS
  expectedEvaluationDate: Date;
  expectedCompletionDate: Date;
  expectedOnlineReleaseDate: Date;
  accessQuestion: string;
  integrateQuestion: string;
  interconnectQuestion: string;
  interconnectQuestion2: string;
  communicationQuestion: string;
  nbSolution: number;
  linkCom: string;
  rfpName: string;
  emailVendorAuthorized: Array<string>;
  emailVeoliaAuthorized: Array<string>;
  emailEvaluatorAuthorized: Array<string>;
  
  mainEmailVendor?: string;
  mainFirstnameVendor?: string;
  mainLastnameVendor?: string;
  mainCompanyVendor?: string;
  
  //IaaS
  q0a_iaas: string;
  q0b_iaas: string;
  
  //PaaS
  
  //Apps
  
  //All
  status: string;
  solutionName: string;
  domainOfSolution: string;
  businessUnitOfSolution: string;
  solutionOwner: string;
  requestDate: Date;
  description: string;
  editorBrand: string;
  link: string;
  emailInitiatorVeolia: string;
  fullNameInitiatorVeolia: string;
  inPlace: string;
  apmNumber: string;
  type: string;
  fullyEvaluated: boolean;
  finalEvaluationDate: Date;
  
  
  //données du formulaire
  jsonFR: string;
  jsonUS: string;
  
  designStatus: string;
  legalStatus: string;
  article32Status: string;
  article28Status: string;
  iaasStatus: string;
  PaaSStatus: string;
  AppsStatus: string;

  lastUpdate: Date;
  lastUpdateBy: string;
  firstUpdate: Date;
  firstUpdateBy: string;
  form: string;
  createdAt: Date;

  fire = firebase.firestore();
  
  tabName;
  download= false;

  constructor(
    public service: FormulaireAllService,
    private route: ActivatedRoute,
    private afAuth: AngularFireAuth,
    private router: Router,
    public auth: AuthService,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    //get type
    this.route.params.subscribe((params: Params) => this.type = params['type']);
    console.log('type',this.type)
    
    if(this.type === 'SaaS') {
      //Recup données de la demande: Partie demande datastore db
    const demandeRef = this.fire.collection('solutions').doc(this.service.idSolution);
    demandeRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
            (this.domainOfSolution = doc.data().domainOfSolution),
            (this.businessUnitOfSolution = doc.data().businessUnitOfSolution),
            (this.solutionName = doc.data().solutionName),
            (this.rfpName = doc.data().rfpName),
            (this.description = doc.data().description),
            (this.link = doc.data().link),
            (this.linkCom = doc.data().linkCom),
            (this.editorBrand = doc.data().editorBrand),
            (this.solutionOwner = doc.data().solutionOwner),
            (this.emailInitiatorVeolia = doc.data().emailInitiatorVeolia),
            (this.fullNameInitiatorVeolia = doc.data().fullNameInitiatorVeolia),
            (this.emailVendorAuthorized = doc.data().emailVendorAuthorized),
            (this.mainEmailVendor = doc.data().mainEmailVendor),
            (this.mainFirstnameVendor = doc.data().mainFirstnameVendor),
            (this.mainLastnameVendor = doc.data().mainLastnameVendor),
            (this.mainCompanyVendor = doc.data().mainCompanyVendor),
            (this.emailVeoliaAuthorized = doc.data().emailVeoliaAuthorized),
            (this.emailEvaluatorAuthorized = doc.data().emailEvaluatorAuthorized),
            (this.expectedEvaluationDate = this.convertDate(doc.data().expectedEvaluationDate)),
            (this.expectedCompletionDate = this.convertDate(doc.data().expectedCompletionDate)),
            (this.expectedOnlineReleaseDate = this.convertDate(doc.data().expectedOnlineReleaseDate)),
            (this.requestDate = this.convertDate(doc.data().requestDate)),
            (this.status = doc.data().status),
            (this.fullyEvaluated = doc.data().fullyEvaluated),
            (this.finalEvaluationDate = this.convertDate(doc.data().finalEvaluationDate)),
            (this.inPlace = doc.data().inPlace),
            (this.apmNumber = doc.data().apmNumber),
            (this.type = doc.data().type),
            (this.accessQuestion =  doc.data().accessQuestion),
            (this.integrateQuestion =  doc.data().integrateQuestion),
            (this.interconnectQuestion =  doc.data().interconnectQuestion),
            (this.interconnectQuestion2 =  doc.data().interconnectQuestion2),
            (this.communicationQuestion =  doc.data().communicationQuestion),
            (this.nbSolution=  doc.data().nbSolution)
            
            //to do get emailEvaluatorAuthorized for solution + evaluators for Bu solution
            
          //vérifie si le user est SO de la demande
          const fire = firebase.firestore();
          fire
            .collection('solutions')
            .where('solutionOwner', '==', this.auth.currentUserEmail).where('solutionName', '==', this.solutionName)
            .get()
            .then(snapshot => {
              if (snapshot.empty) {
                //si false
                console.log('The user is not SO of solution');
              } else {
                console.log('User is SO of solution');
                this.isSoOfSolution = true;
              }
            });
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
    
    }
    
    if(this.type === 'IaaS') {
      //Recup données de la demande: Partie demande datastore db
    const demandeRef = this.fire.collection('solutions').doc(this.service.idSolution);
    demandeRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
            (this.q0a_iaas = doc.data().q0a_iaas),
            (this.q0b_iaas = doc.data().q0b_iaas),
            (this.domainOfSolution = doc.data().domainOfSolution),
            (this.businessUnitOfSolution = doc.data().businessUnitOfSolution),
            (this.solutionName = doc.data().solutionName),
            (this.description = doc.data().description),
            (this.link = doc.data().link),
            (this.editorBrand = doc.data().editorBrand),
            (this.solutionOwner = doc.data().solutionOwner),
            (this.emailInitiatorVeolia = doc.data().emailInitiatorVeolia),
            (this.fullNameInitiatorVeolia = doc.data().fullNameInitiatorVeolia),
            (this.emailVeoliaAuthorized = doc.data().emailVeoliaAuthorized),
            (this.requestDate = this.convertDate(doc.data().requestDate)),
            (this.status = doc.data().status),
            (this.inPlace = doc.data().inPlace),
            (this.apmNumber = doc.data().apmNumber),
            (this.type = doc.data().type),
            (this.fullyEvaluated = doc.data().fullyEvaluated),
            (this.finalEvaluationDate = this.convertDate(doc.data().finalEvaluationDate))
            
          //vérifie si le user est SO de la demande
          const fire = firebase.firestore();
          fire
            .collection('solutions')
            .where('solutionOwner', '==', this.auth.currentUserEmail).where('solutionName', '==', this.solutionName)
            .get()
            .then(snapshot => {
              if (snapshot.empty) {
                //si false
                console.log('The user is not SO of solution');
              } else {
                console.log('User is SO of solution');
                this.isSoOfSolution = true;
              }
            });
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

    }
    
    if(this.type === 'PaaS') {
      //empty
    }
    
    if(this.type === 'Apps') {
      //empty
    }

  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  //Récup data real time database
  getDataRealDb(form: string) {
    this.tabName = form;

    //Recup les infos de l'évaluation realtime db
    this.service
      .getEvaluationInTableauEvalsOverviewSol(this.service.idSolution, form)
      .snapshotChanges()
      .subscribe(ok => {
        this.currentEvaluation = [];
        ok.forEach(element => {
          const x = element.payload.toJSON();
          x['$key'] = element.key;
          this.currentEvaluation.push(x as Evaluation);
          //console.log("id: " + element.key + "posted BY: " + element.payload.val().postedBy)
          const user = this.afAuth.authState.pipe(first()).toPromise();
          if (user) {
            firebase
              .database()
              .ref('evaluations/' + form + '/' + this.service.idSolution + '/' + element.key)
              .once('value')
              .then(function(snapshot) {
                //snapshot
              });
          }
        });
      });

    //Recup les infos du formulaire: Partie Form datastore db
    if (form === 'Design Form') {
      //form même chose
      const formDaRef = this.fire.collection('designForm').doc(this.service.idSolution);
      formDaRef
        .get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            //console.log('Document data:', doc.data());
            this.jsonFR = doc.data().jsonFR;
            this.jsonUS = doc.data().jsonUS;
            this.designStatus = doc.data().designStatus;
            this.lastUpdate = this.convertDate(doc.data().lastUpdate);
            this.lastUpdateBy = doc.data().lastUpdateBy;
            this.firstUpdate = this.convertDate(doc.data().firstUpdate);
            this.firstUpdateBy = doc.data().firstUpdateBy;
            this.createdAt = this.convertDate(doc.data().createdAt);
            this.form = form;
            console.log("TESTEST", doc.data().lastUpdate)
          }
        })
        .catch(err => {
          console.log('Error getting document', err);
        });
    }

    if (form === 'Legal Form') {
      const formLaRef = this.fire.collection('legalForm').doc(this.service.idSolution);
      formLaRef
        .get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            //console.log('Document data:', doc.data());
            this.jsonFR = doc.data().jsonFR;
            this.jsonUS = doc.data().jsonUS;
            this.legalStatus = doc.data().legalStatus;
            this.lastUpdate = this.convertDate(doc.data().lastUpdate);
            this.lastUpdateBy = doc.data().lastUpdateBy;
            this.firstUpdate = this.convertDate(doc.data().firstUpdate);
            this.firstUpdateBy = doc.data().firstUpdateBy;
            this.createdAt = this.convertDate(doc.data().createdAt);
            this.form = form;
          }
        })
        .catch(err => {
          console.log('Error getting document', err);
        });
    }
    
    if (form === 'Article 28 Form') {
      const form28Ref = this.fire.collection('article28Form').doc(this.service.idSolution);
      form28Ref
        .get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            //console.log('Document data:', doc.data());
            this.jsonFR = doc.data().jsonFR;
            this.jsonUS = doc.data().jsonUS;
            this.article28Status = doc.data().article28Status;
            this.lastUpdate = this.convertDate(doc.data().lastUpdate);
            this.lastUpdateBy = doc.data().lastUpdateBy;
            this.firstUpdate = this.convertDate(doc.data().firstUpdate);
            this.firstUpdateBy = doc.data().firstUpdateBy;
            this.createdAt = this.convertDate(doc.data().createdAt);
            this.form = form;
          }
        })
        .catch(err => {
          console.log('Error getting document', err);
        });
    }
    
    if (form === 'Article 32 Form') {
      const form32Ref = this.fire.collection('article32Form').doc(this.service.idSolution);
      form32Ref
        .get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            //console.log('Document data:', doc.data());
            this.jsonFR = doc.data().jsonFR;
            this.jsonUS = doc.data().jsonUS;
            this.article32Status = doc.data().article32Status;
            this.lastUpdate = this.convertDate(doc.data().lastUpdate);
            this.lastUpdateBy = doc.data().lastUpdateBy;
            this.firstUpdate = this.convertDate(doc.data().firstUpdate);
            this.firstUpdateBy = doc.data().firstUpdateBy;
            this.createdAt = this.convertDate(doc.data().createdAt);
            this.form = form;
          }
        })
        .catch(err => {
          console.log('Error getting document', err);
        });
    }
    
    if (form === 'IaaS Form') {
      const IaaSRef = this.fire.collection('iaasForm').doc(this.service.idSolution);
      IaaSRef
        .get()
        .then(doc => {
          if (!doc.exists) {
            console.log('No such document!');
          } else {
            //console.log('Document data:', doc.data().jsonFR);
            this.jsonFR = doc.data().jsonFR;
            this.jsonUS = doc.data().jsonUS;
            this.iaasStatus = doc.data().iaasStatus;
            this.lastUpdate = this.convertDate(doc.data().lastUpdate);
            this.lastUpdateBy = doc.data().lastUpdateBy;
            this.firstUpdate = this.convertDate(doc.data().firstUpdate);
            this.firstUpdateBy = doc.data().firstUpdateBy;
            this.createdAt = this.convertDate(doc.data().createdAt);
            this.form = form;
          }
        })
        .catch(err => {
          console.log('Error getting document', err);
        });
    }
  }

  convertDate(timestamp) {
    if (timestamp) {
      let date = new Date(new Date(null).getTime());
      let myDate = timestamp.toString();
      let seondsTimestamp = myDate.substring(18, myDate.indexOf(',')); //ON prend que les seconds de Timestamp(seconds=1564610400,nanoseconds=0)

      date.setTime(seondsTimestamp * 1000);
      //console.log(date); //Thu Aug 01 2019 00:00:00 GMT+0200 (heure d’été d’Europe centrale) bon format à insérer dans le champ
      //console.log(date.getTime()); //1564610400000
      return date;
    } else {
      console.log('Pas de date pour le champ');
    }
  }

  redirectGestionVendor(idSol, type) {
    this.router.navigate(['/gestionVendor/' + idSol + '/' + type]); //{ this.router.navigate(['/details/'+ item.payload.doc.id]);}
  }
  
  //au clic du bouton pour accéder au pdf
  generatePdf() {
    this.toastr.success('Download in progress...', '', {
      timeOut: 4000,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-center'
    });
    
    this.download = true;
  
    this.delay(1500).then(any => { 
      const fullEval = document.getElementById('fullSolInfoForPdf').innerHTML; //tous les chapitres et sous chapitres + status/comment eval final
  
      let finalVar = htmlToPdfmake(fullEval);
  
      let documentDefinition = { 
            footer: function (pagenumber, pagecount) {
              return {
                  alignment: 'right', margin: [0, 10, 10, 0],
                  text: pagenumber + '/' + pagecount
              };
            },
            content: finalVar 
      };
  
      if(this.tabName === 'Article 28 Form') { //Annexes
      pdfMake.createPdf(documentDefinition).download('Overview-' + this.solutionName + '-Annexes.pdf');  
    } else {
      pdfMake.createPdf(documentDefinition).download('Overview-' + this.solutionName + '-'+ this.tabName + '.pdf');
    }
      
      this.download = false;
    });
  }
  
  
}
