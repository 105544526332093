import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../../../core/auth.service';
import firebase from 'firebase/compat/app';
import { throwError } from 'rxjs';
import { dictToURI } from '../../../core/helpers';

import { DesignForm } from './designForm.model';
import { LegalForm } from './legalForm.model';
import { Article28Form } from './article28Form.model';
import { Article32Form } from './article32Form.model';

//Upload de fichiers
import { Upload } from './upload.model';
import { AngularFireList, AngularFireDatabase } from '@angular/fire/compat/database';
//import 'firebase/storage';
import { getStorage, ref, getMetadata, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

import { Comment } from './comment.model';
import { catchError } from 'rxjs/operators';
import { Evaluation } from './evaluation.model';
import { UploadEval } from './uploadEval.model';
import { Demande } from '../../demandeDla/demande.model';
import { Reservation } from './reservation.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CompleteChapterSubChapter } from './completeChapterSubChapter.model';
import { Router } from '@angular/router';
import { IaaSForm } from './iaasForm.model';
import { HomeUserService } from 'app/homeUser/homeUser.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class FormulaireAllService {
  ////////////COMPLETE CHAPTER SUB CHAPTER/////////////
  completeList: AngularFireList<any>;
  selectedCompleteChapterSubChapter: CompleteChapterSubChapter = new CompleteChapterSubChapter();

  ////////////COMMENTS/////////////
  commentsList: AngularFireList<any>;
  selectedComment: Comment = new Comment();
  private path: string = ''; //aussi utilisé par evaluation + CompleteChapterSubChapter
  ////////////////////////////////////////

  ///////////FILES///////////////////////////
  private basePath: string = '';
  uploads: AngularFireList<Upload[]>;
  ////////////////////////////////////////

  contentTypeOfFile: string;

  idSol: string; //Récupérer l'id de la demande
  tabName: string; //Recup nom du tab actuellement actif
  reference: string; //Recup nom du chap/sous chap selon la modale ouverte
  solName: string; //Recup nom de la solution
  rfpName: string;
  editorBrand: string;
  buOfSolution: string;
  emailSo: string;
  type: string;
  emailsVendors: Array<string>;
  emailsVeolias: Array<string>;
  emailsEvaluatorsBu: Array<string>;
  docName: string; //pour changer la variable du formulaire actuelle utilisé dans évaluation tout en bas

  ///////Evaluation/////
  currentEvaluation: AngularFireList<any>;
  selectedEvaluation: Evaluation = new Evaluation();
  uploadsForEval: AngularFireList<UploadEval[]>;
  //////////////////////

  //////Reservation////
  currentReservation: AngularFireList<any>;
  selectedReservation: Reservation = new Reservation();
  reservationsList: AngularFireList<any>;

  constructor(
    private firestore: AngularFirestore,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private afs: AngularFireDatabase,
    private httpClient: HttpClient,
    public homeUserService: HomeUserService,
    private translateService: TranslateService
  ) {}

  // designform: Observable<DesignForm>;
  // legalForm: Observable<LegalForm>;

  ////////////////Méthodes d'accès fichiers + commentaires//////////////

  getSolutionName(name: string) {
    this.solName = name;
    return this.solName;
  }

  getRfpName(name: string) {
    this.rfpName = name;
    return this.rfpName;
  }

  getEditorBrand(name: string) {
    this.editorBrand = name;
    return this.editorBrand;
  }

  getBuOfSol(name: string) {
    this.buOfSolution = name;
    return this.buOfSolution;
  }

  getEmailSo(name: string) {
    this.emailSo = name;
    return this.emailSo;
  }

  getVendorsAuthorized(vendors) {
    this.emailsVendors = vendors;
    return this.emailsVendors;
  }

  getVeoliasAuthorized(veolias) {
    this.emailsVeolias = veolias;
    return this.emailsVeolias
  }

  getEvaluatorsBuAuthorized(evaluatorsBu) {
    this.emailsEvaluatorsBu = evaluatorsBu;
    return this.emailsEvaluatorsBu
  }

  getSolutionId(id: string) {
    this.idSol = id;
    return this.idSol;
  }

  //Pour savoir quel est le nom du chapitre ou sous chapitre sur lequel on ajoute un commentaire/fichier (depuis le clique du bouton de la modale)
  getReference(name: string) {
    this.reference = name;
    console.log(this.reference);
    return this.reference;
  }

  get nameSol() {
    return this.solName;
  }

  get rfpNameOfSol() {
    return this.rfpName;
  }

  get editorBrandOfSol() {
    return this.editorBrand;
  }

  get buOfSolu() {
    return this.buOfSolution;
  }

  get emailSolOwner() {
    return this.emailSo;
  }


  //get le chapitre sous chapitre utilisé par items-list.ts et get chapitre dans files-list.ts et evaluationChap
  get ref() {
    return this.reference;
  }

  //get l'id de la demande utilisé par items-list.ts et files-list.ts
  get idSolution() {
    return this.idSol;
  }

  //idSolution+chapitre/sous chapitre pour le orderbychild dans items-list et files-list
  getIdRef() {
    console.log("getIdRef",this.idSolution + '-' + this.ref)
    return this.idSolution + '-' + this.ref;
  }

  /////////////////////////////////////////////////////////////////

  /////buttons communs à tous les formulaires/////
  //veolia ADMIN
  redirectHomeAdmin() {
    this.router.navigate(['/admin']);
  }

  //veolia classique/ vendor
  redirectHomeUser() {
    this.router.navigate(['/homeUser']);
  }

  //ALL
  redirectGestionVendor(idSol, type) {
    this.router.navigate(['/gestionVendor/' + idSol + '/' + type]);
  }

  redirectLogs(idSol, type) {
    this.router.navigate(['/logs/' + idSol + '/' + type]);
  }

  redirectTableauEvals(idSol, type) {
    this.router.navigate(['/tableauEvals/' + idSol + '/' + type]);
  }

  ///////////FILES///////////////////////////

  get formTabName() {
    console.log("TAB NAME",this.tabName)
    return this.tabName;
  }

  //get le tab actif appelé depuis le ts
  getTabName(currTab: string) {
    console.log(currTab);
    //On fait en sorte que dans la BD ce soit toujours en anglais:
    if (currTab === 'Formulaire Design') {
      currTab = 'Design Form';
    }
    if (currTab === 'Formulaire Legal') {
      currTab = 'Legal Form';
    }
    // if (currTab === 'Formulaire Article 28') {
    //   currTab = 'Article 28 Form';
    // }
    if (currTab === 'Annexes') {
      currTab = 'Article 28 Form';
    }
    if (currTab === 'Formulaire Article 32') {
      currTab = 'Article 32 Form';
    }
    this.tabName = currTab;

    return this.tabName;
  }


  pushUpload(upload: Upload, form: string, chapter: string) {

    let stringForm;

    if (this.tabName === 'Design Form') {
      stringForm = 'designForm';
    }
    if (this.tabName === 'Legal Form') {
      stringForm = 'legalForm';
    }
    if (this.tabName === 'Article 32 Form') {
      stringForm = 'article32Form';
    }
    if (this.tabName === 'Article 28 Form') {//Annexes
      stringForm = 'article28Form';
    }

    this.basePath = '/files/' + form + '/' + this.idSolution + '/' + chapter;

    const storage = getStorage();


    // Upload file and metadata to teh object
    const storageRef = ref(storage, `${this.basePath}/${upload.file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, upload.file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case 'running':
            console.log('Upload is running');
            console.log('Upload is ' + upload.progress + '% done');
            break;
        }
        //add data ti real DB
        upload.idRef = this.getIdRef();
        upload.solutionName = this.nameSol;
        upload.reference = chapter;
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => { //success // Upload completed successfully, now we can get the download URL

        this.addLogUploadFile(upload.file.name, upload.reference);//add to log

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { //add others data to real DB
          console.log('File available at', downloadURL);
          upload.url = downloadURL;
          upload.name = upload.file.name; //ok
          upload.userId = this.authService.currentUserId; //ok
          upload.postedBy = this.authService.currentUserEmail; //ok
          upload.postedAt = new Date(Date.now()).toString(); //ok

          this.basePath = '/files/' + form + '/' + this.idSolution + '/' + chapter;
          this.afs.list(`${this.basePath}/`).push(upload).then( reference => {

            getMetadata(storageRef).then(metadata => {
              console.log('All metadata are:', metadata); //contient tout les acces
              console.log('File type is', metadata.contentType);
              this.contentTypeOfFile = metadata.contentType;
              reference.ref.update({
                url: upload.url,
                name: upload.name,
                userId: upload.userId,
                postedBy: upload.postedBy,
                postedAt: upload.postedAt,
                contentType: this.contentTypeOfFile
              });
            });

          });
        });

          this.toastr.success(upload.file.name, 'File uploaded successfuly', {
            timeOut: 10000,
            closeButton: true,
            positionClass: 'toast-top-center'
          });

        //Envoi des notifs
          const fire = firebase.firestore();
        //récup infos du user
        fire.collection('users').where("email", '==', this.authService.currentUserEmail)
        .get().then(snap => {
          snap.forEach(val => {

          //SaaS
          if(form === 'Design Form' || form === 'Legal Form' || form === 'Article 32 Form' || form === 'Article 28 Form') {
            //on envoi la notif que si vendor qui ajoute le fichier en général ce sera toujours lui
            if(!val.data().displayName) {

               //envoi du mail au SO + gbldla
                console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")
                    this.sendFileAddedSaaS(this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, val.data().email, val.data().firstname, val.data().lastname, chapter, new Date(Date.now()).toString(), upload.file.name, this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                      if (data !== "200") {
                        console.error("KO mail retarus unsent !")
                      } else {
                        console.log("OK mail retarus sent !")
                      }
                        console.log("from api: " + data);
                    });

              //envoie mail aux emailVeoliaAuthorized qu'un file a été ajouté
              for(let i = 0; i < this.emailsVeolias.length; i++) {
                console.log("mail envoyé au veolia: (" + i + ") " + this.emailsVeolias[i])

                    this.sendFileAddedSaaS(this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, val.data().email, val.data().firstname, val.data().lastname, chapter, new Date(Date.now()).toString(), upload.file.name, this.buOfSolu, null, this.emailsVeolias[i], null).subscribe(data => {
                      if (data !== "200") {
                        console.error("KO mail retarus unsent !")
                      } else {
                        console.log("OK mail retarus sent !")
                      }
                        console.log("from api: " + data);
                    });
              }

              //envoi email aux evaluators BU inclus dans evaluators
                if(this.emailsEvaluatorsBu) {
                  for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
                    console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

                    this.sendFileAddedSaaS(this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, val.data().email, val.data().firstname, val.data().lastname, chapter, new Date(Date.now()).toString(), upload.file.name, this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
                      if (data !== "200") {
                        console.error("KO mail retarus unsent !")
                      } else {
                        console.log("OK mail retarus sent !")
                      }
                        console.log("from api: " + data);
                      });
                  }
                  }
            }
          }

          //IaaS PaaS Apps
          if(form === 'IaaS Form' || form === 'PaaS Form' || form === 'Apps Form') {
            //envoie mail au SO + BU DLA

                    this.sendFileAdded(this.tabName, stringForm, this.idSolution, this.solName, this.editorBrandOfSol, val.data().email, chapter, new Date(Date.now()).toString(), upload.file.name, this.buOfSolu, this.emailSolOwner).subscribe(data => {
                      if (data !== "200") {
                        console.error("KO mail retarus unsent !")
                      } else {
                        console.log("OK mail retarus sent !")
                      }
                        console.log("from api: " + data);
                    });
          }

          })
        })


      }
    );

        (window as any).resetFormUpload();
      }

  //SaaS
  sendFileAddedSaaS(tabName, stringForm, idSol, solName, rfpName, editorBrand, email, firstname, lastname, reference, dateUpload, fileName, buOfSolu, emailSo, emailsList, emailDla){
    const query = dictToURI({
      formName: tabName,
      stringForm,
      idSol,
      email,
      firstnameVendor: firstname,
      lastnameVendor: lastname,
      bu: buOfSolu,
      solutionName: solName,
      rfpName: rfpName,
      editorBrand: editorBrand,
      chapter: this.translateService.instant(reference),
      postedAt: dateUpload,
      fileName: fileName,
      emailSolOwner: emailSo,
      emailsList,
      emailDla
    });
    return this.httpClient.get('/api/file_added_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  //IaaS PaaS Apps
  sendFileAdded(tabName, stringForm, idSol, solName, editorBrand, email, reference, dateUpload, fileName, buOfSolu, emailSo){
    const query = dictToURI({
      formName: tabName,
      stringForm: stringForm,
      idSol,
      email,
      bu: buOfSolu,
      solutionName: solName,
      editorBrand: editorBrand,
      chapter: this.translateService.instant(reference),
      postedAt: dateUpload,
      fileName,
      emailSolOwner: emailSo,
      type: tabName.substring(0, 4)
    });
    return this.httpClient.get('/api/file_added?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  /////////////DELETE FILES////////////////
  deleteUpload(upload: Upload, form: string, chapter: string) {
    this.addLogDeleteFile(upload.name, chapter);

    document.getElementById(chapter + '_FILE').style.color = 'grey';

    this.deleteFileData(upload.$key, form, chapter)
      .then(() => {
        this.deleteFileStorage(upload.name, form, chapter);
      })
      .catch(error => console.log(error));
  }

  // Deletes the file details from the realtime db
  private deleteFileData(key: string, form: string, chapter: string) {
    this.basePath = '/files/' + form + '/' + this.idSolution + '/' + chapter;
    console.log('KEY: ' + key);
    return this.afs.list(`${this.basePath}/`).remove(key);
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name: string, form: string, chapter: string) {
    const storage = getStorage()

    this.basePath = '/files/' + form + '/' + this.idSolution + '/' + chapter;

    const storageRef = ref(storage, `${this.basePath}/${name}`);

    // Delete the file from storage
    deleteObject(storageRef).then(() => {
      this.toastr.warning(name, 'File deleted successfuly', {
            timeOut: 10000,
            closeButton: true,
            positionClass: 'toast-top-center'
          });
      console.log('File removed !')
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }
  ///////////////////////////////////////////////////////

  //Lors du clic sur la modale appelé par le ts
  getFiles(idref: string) {
    console.log('dans le getFiles()');
    return (this.uploads = this.afs.list('files/' + this.tabName + '/' + this.idSolution + '/' + this.ref, ref =>
      ref.orderByChild('idRef').equalTo(idref)
    ));
  }

  //pour checker les chapitres qui ont des fichiers
  getAllFiles() {
    return (this.uploads = this.afs.list('files/' + this.tabName + '/' + this.idSolution));
  }

  /////////////////////////////////////////////////////////////

  ////////FORMULAIRE ALL//////////

  //resolver
  //select les données du formulaireDA correspondant pour les placer dans le formulaire avec champs déj�� complétés
  getFormDaById(id: string) {
    return this.firestore
      .collection('designForm')
      .doc(id)
      .snapshotChanges();
  }

  //resolver
  //select les données du formulaireLA correspondant pour les placer dans le formulaire avec champs déjà complétés
  getFormLaById(id: string) {
    return this.firestore
      .collection('legalForm')
      .doc(id)
      .snapshotChanges();
  }

  //resolver
  //select les données du formulaire28 correspondant pour les placer dans le formulaire avec champs déjà complétés
  getForm28ById(id: string) {
    return this.firestore
      .collection('article28Form')
      .doc(id)
      .snapshotChanges();
  }

  //resolver
  //select les données du formulaire32 correspondant pour les placer dans le formulaire avec champs déjà complétés
  getForm32ById(id: string) {
    return this.firestore
      .collection('article32Form')
      .doc(id)
      .snapshotChanges();
  }

  //resolver
  //select les données du formulaireIaaS correspondant pour les placer dans le formulaire avec champs déjà complétés
  getFormIaaSById(id: string) {
    return this.firestore
      .collection('iaasForm')
      .doc(id)
      .snapshotChanges();
  }

  ////////AJOUT DES DONNEES DES FORM/////////

  //appelé dans le formulaireAll.ts
  //Technique moins bien car je suis obligé de mettre à chaque fois tous les attricbuts du formulaire, mais marche nickel

  updateDataDesign(
    id: string,
    value,
    newLink,
    //utilisés que pour retarus
    vendorEmail,
    solutionOwner,
    rfpName,
    editorBrand,
    businessUnitOfSolution
  ) {

    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${id}`); // id du document designForm=idSolution

    //Ajoute firstUpdate et firstUpdateBy lors de la premiere modif du form par un vendor
    //Si le champ firstUpdate n'existe pas lors du premier save par le vendor
    //alors il n'y a jamais eu de premiere modif donc (que ce soit design ou legal)
    //= ajout du firstUpdate et firstUpdateBy ; premier vendor à avoir save
    const fire = firebase.firestore();

    fire
      .collection('designForm')
      .doc(this.idSolution)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          let firstUpdate = doc.data().firstUpdate;

          if (!firstUpdate) {
            //envoie au SO que le vendor a commencé à remplir le form
            this.sendFormStartedSaaS(this.solName, this.tabName, solutionOwner, rfpName, editorBrand, businessUnitOfSolution, vendorEmail).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
                console.log("from api: " + data);
            });

            //si ce champ n'existe pas alors le doc a juste été initialisé par le user veolia
            console.log("Il n'y a jamais eu de modif,ajout firstUpdate");

            //ADD des first
            const data: DesignForm = {
              firstUpdate: new Date(Date.now()),
              firstUpdateBy: this.authService.currentUserEmail
            };
            return refFormDa.update(data);
          } else {
            console.log('firstUpdate déjà connu');
          }
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

    //MAJ des données peut importe le cas
    this.firestore.doc('solutions/' + id).update({ link: newLink })//maj du lien qu'il change ou non

    const data: DesignForm = {
      //Ajoutés par la suite
      lastUpdate: new Date(Date.now()),
      lastUpdateBy: this.authService.currentUserEmail,
      link: newLink,
      acunetixApprobation: value.acunetixApprobation,
      dateAcunetixScanExpectedFrom: value.dateAcunetixScanExpectedFrom,
      dateAcunetixScanExpectedTo: value.dateAcunetixScanExpectedTo,
      timeAcunetixScanExpectedFrom: value.timeAcunetixScanExpectedFrom,
      timeAcunetixScanExpectedTo: value.timeAcunetixScanExpectedTo,
      acunetixInfo: value.acunetixInfo,
      remediationHigh: value.remediationHigh,
      remediationMedium: value.remediationMedium,
      remediationLow: value.remediationLow,
      remediationInfo: value.remediationInfo,

      //All planned field
      DA_15_03_PLANNED: value.DA_15_03_PLANNED,
      DA_16_02_PLANNED: value.DA_16_02_PLANNED,
      DA_21_03_PLANNED: value.DA_21_03_PLANNED,
      DA_21_05_PLANNED: value.DA_21_05_PLANNED,
      DA_22_01_PLANNED: value.DA_22_01_PLANNED,
      DA_22_03_PLANNED: value.DA_22_03_PLANNED,
      DA_22_05_00_PLANNED: value.DA_22_05_00_PLANNED,
      DA_22_07_PLANNED: value.DA_22_07_PLANNED,
      DA_23_01_PLANNED: value.DA_23_01_PLANNED,
      DA_24_01_PLANNED: value.DA_24_01_PLANNED,
      DA_24_02_PLANNED: value.DA_24_02_PLANNED,
      DA_24_03_PLANNED: value.DA_24_03_PLANNED,
      DA_24_04_PLANNED: value.DA_24_04_PLANNED,
      DA_24_05_PLANNED: value.DA_24_05_PLANNED,
      DA_25_01_PLANNED: value.DA_25_01_PLANNED,
      DA_25_03_PLANNED: value.DA_25_03_PLANNED,
      DA_25_04_PLANNED: value.DA_25_04_PLANNED,
      DA_25_06_PLANNED: value.DA_25_06_PLANNED,
      DA_25_12_PLANNED: value.DA_25_12_PLANNED,
      DA_41_01_PLANNED: value.DA_41_01_PLANNED,
      DA_41_06_PLANNED: value.DA_41_06_PLANNED,
      DA_41_08_PLANNED: value.DA_41_08_PLANNED,
      DA_41_10_PLANNED: value.DA_41_10_PLANNED,
      DA_41_11_PLANNED: value.DA_41_11_PLANNED,
      DA_410_01_PLANNED: value.DA_410_01_PLANNED,
      DA_410_03_PLANNED: value.DA_410_03_PLANNED,
      DA_410_05_PLANNED: value.DA_410_05_PLANNED,
      DA_42_01_PLANNED: value.DA_42_01_PLANNED,
      DA_42_02_PLANNED: value.DA_42_02_PLANNED,
      DA_42_03_PLANNED: value.DA_42_03_PLANNED,
      DA_42_05_PLANNED: value.DA_42_05_PLANNED,
      DA_42_07_PLANNED: value.DA_42_07_PLANNED,
      DA_42_16_PLANNED: value.DA_42_16_PLANNED,
      DA_42_18_PLANNED: value.DA_42_18_PLANNED,
      DA_42_19_PLANNED: value.DA_42_19_PLANNED,
      DA_42_20_PLANNED: value.DA_42_20_PLANNED,
      DA_42_22_PLANNED: value.DA_42_22_PLANNED,
      DA_42_23_PLANNED: value.DA_42_23_PLANNED,
      DA_42_25_PLANNED: value.DA_42_25_PLANNED,
      DA_43_01_PLANNED: value.DA_43_01_PLANNED,
      DA_44_02_PLANNED: value.DA_44_02_PLANNED,
      DA_45_13_PLANNED: value.DA_45_13_PLANNED,
      DA_47_01_PLANNED: value.DA_47_01_PLANNED,

      //Chapitre 1
      DA_11_01: value.DA_11_01,
      DA_11_02_RADIO: value.DA_11_02_RADIO,
      DA_11_02_01: value.DA_11_02_01,
      DA_11_03: value.DA_11_03,

      DA_12_01: value.DA_12_01,
      DA_12_02_SELECT: value.DA_12_02_SELECT,
      DA_12_03: value.DA_12_03,

      DA_13_01_CHECKBOX: value.DA_13_01_CHECKBOX,
      DA_13_02: value.DA_13_02,
      DA_13_03: value.DA_13_03,

      DA_14_01: value.DA_14_01,
      DA_14_02: value.DA_14_02,
      DA_14_03: value.DA_14_03,
      DA_14_04: value.DA_14_04,
      DA_14_04_01: value.DA_14_04_01,
      DA_14_04_02_RADIO: value.DA_14_04_02_RADIO,
      DA_14_04_03: value.DA_14_04_03,
      DA_14_05: value.DA_14_05,

      DA_14_09: value.DA_14_09,
      DA_14_XX_1_5_2_CHECKBOX: value.DA_14_XX_1_5_2_CHECKBOX,
      DA_14_06: value.DA_14_06,
      DA_14_07: value.DA_14_07,
      DA_14_08: value.DA_14_08,
      DA_14_XX_1_5_6_RADIO: value.DA_14_XX_1_5_6_RADIO,
      DA_14_XX_1_5_6_more: value.DA_14_XX_1_5_6_more,
      DA_14_XX_1_5_7_RADIO: value.DA_14_XX_1_5_7_RADIO,
      DA_14_XX_1_5_7_more: value.DA_14_XX_1_5_7_more,
      DA_14_XX_1_5_8_RADIO: value.DA_14_XX_1_5_8_RADIO,
      DA_14_XX_1_5_8_more: value.DA_14_XX_1_5_8_more,

      DA_15_01: value.DA_15_01,
      DA_15_02: value.DA_15_02,
      DA_15_03_RADIO: value.DA_15_03_RADIO,
      DA_15_04: value.DA_15_04,
      DA_15_05: value.DA_15_05,

      DA_16_01: value.DA_16_01,
      DA_16_01_02: value.DA_16_01_02,
      DA_16_02_RADIO: value.DA_16_02_RADIO,
      DA_16_03: value.DA_16_03,

      //Chapitre 2
      DA_21_01_RADIO: value.DA_21_01_RADIO,
      //DA_21_01_YES: value.DA_21_01_YES,
      DA_21_02: value.DA_21_02,
      DA_21_02_01_RADIO: value.DA_21_02_01_RADIO,
      DA_21_02_01_YES: value.DA_21_02_01_YES,
      DA_21_03_RADIO: value.DA_21_03_RADIO,
      DA_21_04: value.DA_21_04,
      DA_21_05_RADIO: value.DA_21_05_RADIO,
      DA_21_06: value.DA_21_06,

      DA_22_01_RADIO: value.DA_22_01_RADIO,
      DA_22_02: value.DA_22_02,
      DA_22_03_RADIO: value.DA_22_03_RADIO,
      DA_22_04: value.DA_22_04,
      DA_22_05_00_RADIO: value.DA_22_05_00_RADIO,
      DA_22_05_01_RADIO: value.DA_22_05_01_RADIO,
      DA_22_05_02: value.DA_22_05_02,
      DA_22_05_03_RADIO: value.DA_22_05_03_RADIO,
      DA_22_05_04: value.DA_22_05_04,
      DA_22_06: value.DA_22_06,
      DA_22_07_RADIO: value.DA_22_07_RADIO,
      DA_22_08_CHECKBOX: value.DA_22_08_CHECKBOX,
      DA_22_09: value.DA_22_09,
      DA_22_10_RADIO: value.DA_22_10_RADIO,
      DA_22_11: value.DA_22_11,
      DA_22_12: value.DA_22_12,

      DA_23_01_RADIO: value.DA_23_01_RADIO,
      DA_23_02_CHECKBOX: value.DA_23_02_CHECKBOX,
      DA_23_05: value.DA_23_05,
      DA_23_06_RADIO: value.DA_23_06_RADIO,
      DA_23_07: value.DA_23_07,
      DA_23_08_RADIO: value.DA_23_08_RADIO,
      DA_23_09: value.DA_23_09,

      DA_24_01_RADIO: value.DA_24_01_RADIO,
      DA_24_02_RADIO: value.DA_24_02_RADIO,
      DA_24_03_RADIO: value.DA_24_03_RADIO,
      DA_24_04_RADIO: value.DA_24_04_RADIO,
      DA_24_05_RADIO: value.DA_24_05_RADIO,
      DA_24_06: value.DA_24_06,

      DA_25_01_RADIO: value.DA_25_01_RADIO,
      DA_25_02: value.DA_25_02,
      DA_25_02_01: value.DA_25_02_01,
      DA_25_03_RADIO: value.DA_25_03_RADIO,
      DA_25_03_01: value.DA_25_03_01,
      DA_25_04_RADIO: value.DA_25_04_RADIO,
      DA_25_04_01: value.DA_25_04_01,
      DA_25_05: value.DA_25_05,
      DA_25_06_RADIO: value.DA_25_06_RADIO,
      DA_25_06_01: value.DA_25_06_01,
      DA_25_07_CHECKBOX: value.DA_25_07_CHECKBOX,
      DA_25_08_CHECKBOX: value.DA_25_08_CHECKBOX,
      DA_25_09: value.DA_25_09,
      DA_25_10: value.DA_25_10,
      DA_25_11: value.DA_25_11,
      DA_25_12_RADIO: value.DA_25_12_RADIO,
      DA_25_13: value.DA_25_13,
      DA_25_14: value.DA_25_14,

      //Chapitre 3
      DA_31_01: value.DA_31_01,

      DA_32_01: value.DA_32_01,

      //Chapitre 4
      DA_41_01_RADIO: value.DA_41_01_RADIO,
      DA_41_01_CHECKBOX: value.DA_41_01_CHECKBOX,
      DA_41_03_RADIO: value.DA_41_03_RADIO,
      DA_41_06_RADIO: value.DA_41_06_RADIO,
      DA_41_07: value.DA_41_07,
      DA_41_08_RADIO: value.DA_41_08_RADIO,
      DA_41_09: value.DA_41_09,
      DA_41_10_RADIO: value.DA_41_10_RADIO,
      DA_41_10_MORE: value.DA_41_10_MORE,
      DA_41_11_RADIO: value.DA_41_11_RADIO,

      DA_410_01_RADIO: value.DA_410_01_RADIO,
      DA_410_02: value.DA_410_02,
      DA_410_03_RADIO: value.DA_410_03_RADIO,
      DA_410_04: value.DA_410_04,
      DA_410_05_RADIO: value.DA_410_05_RADIO,
      DA_410_06: value.DA_410_06,

      DA_42_01_RADIO: value.DA_42_01_RADIO,
      DA_42_02_RADIO: value.DA_42_02_RADIO,
      DA_42_03_RADIO: value.DA_42_03_RADIO,
      DA_42_04: value.DA_42_04,
      DA_42_05_RADIO: value.DA_42_05_RADIO,
      DA_42_07_RADIO: value.DA_42_07_RADIO,
      DA_42_16_RADIO: value.DA_42_16_RADIO,
      DA_42_17: value.DA_42_17,
      DA_42_18_RADIO: value.DA_42_18_RADIO,
      DA_42_18_01: value.DA_42_18_01,
      DA_42_19_RADIO: value.DA_42_19_RADIO,
      DA_42_20_RADIO: value.DA_42_20_RADIO,
      DA_42_22_RADIO: value.DA_42_22_RADIO,
      DA_42_23_RADIO: value.DA_42_23_RADIO,
      DA_42_23_01: value.DA_42_23_01,
      DA_42_25_RADIO: value.DA_42_25_RADIO,
      DA_42_25_01: value.DA_42_25_01,

      DA_43_01_RADIO: value.DA_43_01_RADIO,
      DA_43_02: value.DA_43_02,
      DA_43_04: value.DA_43_04,

      DA_44_01: value.DA_44_01,
      DA_44_02_RADIO: value.DA_44_02_RADIO,
      DA_44_04: value.DA_44_04,

      DA_45_00_A_RADIO: value.DA_45_00_A_RADIO,
      DA_45_01: value.DA_45_01,
      DA_45_02: value.DA_45_02,
      DA_45_03: value.DA_45_03,
      DA_45_04: value.DA_45_04,
      DA_45_05: value.DA_45_05,
      DA_45_06: value.DA_45_06,
      DA_45_07: value.DA_45_07,
      DA_45_08: value.DA_45_08,
      DA_45_09: value.DA_45_09,
      DA_45_10: value.DA_45_10,
      DA_45_13_RADIO: value.DA_45_13_RADIO,

      DA_46_00_RADIO: value.DA_46_00_RADIO,
      DA_46_00_more: value.DA_46_00_more,
      DA_46_01_CHECKBOX: value.DA_46_01_CHECKBOX,
      DA_46_02: value.DA_46_02,
      DA_46_03_RADIO: value.DA_46_03_RADIO,

      DA_47_01_RADIO: value.DA_47_01_RADIO,
      DA_47_02: value.DA_47_02,

      //Chapitre 5
      DA_51_01: value.DA_51_01,

      DA_52_01: value.DA_52_01,
      DA_52_02: value.DA_52_02,

      DA_53_01: value.DA_53_01,

      DA_54_01: value.DA_54_01,
      DA_54_02: value.DA_54_02,
      DA_54_03: value.DA_54_03,
      DA_54_04: value.DA_54_04,
      DA_54_05_RADIO: value.DA_54_05_RADIO,
      DA_54_06: value.DA_54_06,

      //Chapitre 6
      DA_61_01: value.DA_61_01,
      DA_61_02: value.DA_61_02,
      DA_61_03: value.DA_61_03,
      DA_61_04: value.DA_61_04,

      DA_62_01: value.DA_62_01,
      DA_62_02_RADIO: value.DA_62_02_RADIO,
      DA_62_03: value.DA_62_03,
      DA_62_04: value.DA_62_04,
      DA_62_05: value.DA_62_05,
      DA_62_06: value.DA_62_06,

      DA_63_01: value.DA_63_01,
      DA_63_02: value.DA_63_02,
      DA_63_02_06_RADIO: value.DA_63_02_06_RADIO,
      DA_63_02_07: value.DA_63_02_07,

      DA_64_01: value.DA_64_01,
      DA_64_02: value.DA_64_02,
      DA_64_03: value.DA_64_03,
      DA_64_04: value.DA_64_04,
      DA_64_05: value.DA_64_05,

      DA_65_01_RADIO: value.DA_65_01_RADIO,
      DA_65_01_01: value.DA_65_01_01,
      DA_65_01_02: value.DA_65_01_02,
      DA_65_02_RADIO: value.DA_65_02_RADIO,
      DA_65_02_01: value.DA_65_02_01,

      //Chapitre 7
      DA_71_01: value.DA_71_01,

      DA_72_01: value.DA_72_01,

      //Chapitre 8
      DA_81_01: value.DA_81_01,

      DA_82_01: value.DA_82_01
    };

    //on update les data dans 'designForm' et le champ lastUpdate dans 'solutions'
    return refFormDa.update(data).then(res => {
      this.firestore.doc('solutions/' + id).update({ lastUpdate: new Date(Date.now()) });
    });
  }

  //Meilleure technique evite de mettre tous les attributs et de compléter le formDa.model.ts aussi
  //Fonctionne mais update que checkbox et prend en compte les maj des fields dans le mapage "value" comme dans editdemande, donc méthode classique en haut à prendre pour l'instant
  //UPDATE du form DA lors du clique sur bouton validation du formulaire dans formulaireAll.ts
  /*updateDataDa(id: string, value, DA_13_01_CHECKBOX) {
    return this.firestore
      .collection('formDa')
      .doc(id)
      .update({value,DA_13_01_CHECKBOX})
  }*/

  updateDataLegal(id: string, value,
  //utilisés que pour retarus
    vendorEmail,
    solutionOwner,
    rfpName,
    editorBrand,
    businessUnitOfSolution) {

    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${id}`); // id du document formLa=idSolution

    //Ajoute firstUpdate et firstUpdateBy lors de la premiere modif du form par un vendor
    //Si le champ firstUpdate n'existe pas lors du premier save par le vendor
    //alors il n'y a jamais eu de premiere modif donc (que ce soit design ou legal)
    //= ajout du firstUpdate et firstUpdateBy ; premier vendor à avoir save
    const fire = firebase.firestore();

    fire
      .collection('legalForm')
      .doc(this.idSolution)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          let firstUpdate = doc.data().firstUpdate;

          if (!firstUpdate) {
            //envoie au SO que le vendor a commencé à remplir le form
            this.sendFormStartedSaaS(this.solName, this.tabName, solutionOwner, rfpName, editorBrand, businessUnitOfSolution, vendorEmail).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
                console.log("from api: " + data);
            });

            //si ce champ n'existe pas alors le doc a juste été initialisé par le user veolia
            console.log("Il n'y a jamais eu de modif,ajout firstUpdate");

            //ADD des first
            const data: LegalForm = {
              firstUpdate: new Date(Date.now()),
              firstUpdateBy: this.authService.currentUserEmail
            };
            return refFormLa.update(data);
          } else {
            console.log('firstUpdate déjà connu');
          }
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

    //MAJ données legal peut importe le cas
    const data: LegalForm = {
      //Ajoutés par la suite
      lastUpdate: new Date(Date.now()),
      lastUpdateBy: this.authService.currentUserEmail,

      //Chapitre 0
      LA_0_01_RADIO: value.LA_0_01_RADIO,
      LA_0_02: value.LA_0_02,

      //Chapitre 1
      LA_10_01: value.LA_10_01,
      LA_111_01_RADIO: value.LA_111_01_RADIO,
      LA_111_02: value.LA_111_02,
      LA_112_01: value.LA_112_01,
      LA_113_01_RADIO: value.LA_113_01_RADIO,
      LA_114_01_RADIO: value.LA_114_01_RADIO,
      LA_114_02: value.LA_114_02,
      LA_114_03: value.LA_114_03,
      LA_115_01_RADIO: value.LA_115_01_RADIO,
      LA_115_02: value.LA_115_02,
      LA_115_03: value.LA_115_03,
      LA_116_01_RADIO: value.LA_116_01_RADIO,
      LA_116_02: value.LA_116_02,
      LA_116_03: value.LA_116_03,
      // LA_121_01: value.LA_121_01,
      // LA_121_02: value.LA_121_02,
      LA_121_b_01_RADIO: value.LA_121_b_01_RADIO,
      LA_121_b_02_PLANNED: value.LA_121_b_02_PLANNED,
      LA_122_01_A_RADIO: value.LA_122_01_A_RADIO,
      LA_122_01_B_CHECKBOX: value.LA_122_01_B_CHECKBOX,
      LA_123_01_01: value.LA_123_01_01,
      LA_123_01_02: value.LA_123_01_02,
      LA_123_01_03: value.LA_123_01_03,
      LA_123_02_01: value.LA_123_01_01,
      LA_123_02_02: value.LA_123_01_02,
      LA_124_01_RADIO: value.LA_124_01_RADIO,
      LA_125_01_RADIO: value.LA_125_01_RADIO,
      LA_126_01_RADIO: value.LA_126_01_RADIO,
      LA_126_02: value.LA_126_02,
      LA_127_01_RADIO: value.LA_127_01_RADIO,
      LA_127_02_RADIO: value.LA_127_02_RADIO,
      LA_128_01_RADIO: value.LA_128_01_RADIO,
      LA_129_01_RADIO: value.LA_129_01_RADIO,
      LA_129_02_RADIO: value.LA_129_02_RADIO,
      LA_1210_01_RADIO: value.LA_1210_01_RADIO,
      LA_1210_02: value.LA_1210_02,
      LA_1211_01_RADIO: value.LA_1211_01_RADIO,
      LA_1211_02_RADIO: value.LA_1211_02_RADIO,
      LA_131_01: value.LA_131_01,
      LA_132_01_RADIO: value.LA_132_01_RADIO,
      LA_132_01_02_CHECKBOX: value.LA_132_01_02_CHECKBOX,
      LA_132_02: value.LA_132_02,
      LA_132_03: value.LA_132_03,
      LA_141_01_RADIO: value.LA_141_01_RADIO,
      LA_141_02: value.LA_141_02,
      LA_142_01_RADIO: value.LA_142_01_RADIO,
      LA_142_02: value.LA_142_02,
      LA_142_03_RADIO: value.LA_142_03_RADIO,
      LA_142_04: value.LA_142_04,
      LA_142_05: value.LA_142_05,
      LA_151_01: value.LA_151_01,
      LA_151_02: value.LA_151_02,
      LA_152_01_RADIO: value.LA_152_01_RADIO,
      LA_152_02: value.LA_152_02,
      LA_153_01: value.LA_153_01,
      LA_153_02_RADIO: value.LA_153_02_RADIO,

      //Chapitre 2
      LA_21_01: value.LA_21_01,
      LA_22_01_CHECKBOX: value.LA_22_01_CHECKBOX,
      LA_23_01: value.LA_23_01,
      LA_24_01: value.LA_24_01,
      LA_25_01_RADIO: value.LA_25_01_RADIO,
      LA_26_01_RADIO: value.LA_26_01_RADIO,

      //Chapitre 3
      LA_31_01: value.LA_31_01,
      LA_32_01_RADIO: value.LA_32_01_RADIO,
      LA_33_01_RADIO: value.LA_33_01_RADIO,
      LA_33_02: value.LA_33_02,
      LA_34_01_RADIO: value.LA_34_01_RADIO,
      LA_34_02: value.LA_34_02,

      //Chapitre 4
      LA_41_01: value.LA_41_01,
      LA_42_01_RADIO: value.LA_42_01_RADIO,
      LA_43_01_RADIO: value.LA_43_01_RADIO,

      LA_44_01_RADIO: value.LA_44_01_RADIO,
      LA_44_01_NONA: value.LA_44_01_NONA,
      LA_44_02_RADIO: value.LA_44_02_RADIO,
      LA_44_02_NONA: value.LA_44_02_NONA,
      LA_44_03_RADIO: value.LA_44_03_RADIO,
      LA_44_03_NONA: value.LA_44_03_NONA,
      LA_44_04_RADIO: value.LA_44_04_RADIO,
      LA_44_04_NONA: value.LA_44_04_NONA,
      LA_44_05_RADIO: value.LA_44_05_RADIO,
      LA_44_05_NONA: value.LA_44_05_NONA,
      LA_44_06_RADIO: value.LA_44_06_RADIO,
      LA_44_06_NONA: value.LA_44_06_NONA,
      LA_44_07_RADIO: value.LA_44_07_RADIO,
      LA_44_07_NONA: value.LA_44_07_NONA,
      LA_44_08_RADIO: value.LA_44_08_RADIO,
      LA_44_08_NONA: value.LA_44_08_NONA,
      LA_44_09_RADIO: value.LA_44_09_RADIO,
      LA_44_09_NONA: value.LA_44_09_NONA,
      LA_44_10_CHECKBOX: value.LA_44_10_CHECKBOX,
      LA_44_11_RADIO: value.LA_44_11_RADIO,
      LA_44_11_NONA: value.LA_44_11_NONA,
      LA_44_12_RADIO: value.LA_44_12_RADIO,
      LA_44_12_NONA: value.LA_44_12_NONA,
      LA_44_13_RADIO: value.LA_44_13_RADIO,
      LA_44_13_NONA: value.LA_44_13_NONA,
      LA_44_14_RADIO: value.LA_44_14_RADIO,
      LA_44_14_NONA: value.LA_44_14_NONA,
      LA_44_15_RADIO: value.LA_44_15_RADIO,
      LA_44_15_NONA: value.LA_44_15_NONA,
      LA_44_16_RADIO: value.LA_44_16_RADIO,
      LA_44_16_NONA: value.LA_44_16_NONA,
      LA_44_17_RADIO: value.LA_44_17_RADIO,
      LA_44_17_NONA: value.LA_44_17_NONA,
      LA_44_18_RADIO: value.LA_44_18_RADIO,
      LA_44_18_NONA: value.LA_44_18_NONA,
      LA_44_19_RADIO: value.LA_44_19_RADIO,
      LA_44_19_NONA: value.LA_44_19_NONA,
      LA_44_20_RADIO: value.LA_44_20_RADIO,
      LA_44_20_NONA: value.LA_44_20_NONA,
      LA_44_21_RADIO: value.LA_44_21_RADIO,
      LA_44_21_NONA: value.LA_44_21_NONA,
      LA_44_22_RADIO: value.LA_44_22_RADIO,
      LA_44_22_NONA: value.LA_44_22_NONA,

      //Chapitre 5
      LA_51_01_RADIO: value.LA_51_01_RADIO,
      LA_52_01_RADIO: value.LA_52_01_RADIO,
      LA_52_02_RADIO: value.LA_52_02_RADIO,
    };

    //on update les data dans 'legalForm' et le champ lastUpdate dans 'solutions'
    return refFormLa.update(data).then(res => {
      this.firestore.doc('solutions/' + id).update({ lastUpdate: new Date(Date.now()) }); //on update dans 'solutions' juste lastUpdate
    });
  }


  updateDataArticle28(id: string, value,
  //utilisés que pour retarus
    vendorEmail,
    solutionOwner,
    rfpName,
    editorBrand,
    businessUnitOfSolution) {

    const refForm28: AngularFirestoreDocument<Article28Form> = this.firestore.doc(`article28Form/${id}`);

    //Ajoute firstUpdate et firstUpdateBy lors de la premiere modif du form par un vendor
    //Si le champ firstUpdate n'existe pas lors du premier save par le vendor
    //alors il n'y a jamais eu de premiere modif donc (que ce soit design ou legal)
    //= ajout du firstUpdate et firstUpdateBy ; premier vendor à avoir save
    const fire = firebase.firestore();

    fire
      .collection('article28Form')
      .doc(this.idSolution)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          let firstUpdate = doc.data().firstUpdate;

          if (!firstUpdate) {
            //envoie au SO que le vendor a commencé à remplir le form
            this.sendFormStartedSaaS(this.solName, this.tabName, solutionOwner, rfpName, editorBrand, businessUnitOfSolution, vendorEmail).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
                console.log("from api: " + data);
            });

            //si ce champ n'existe pas alors le doc a juste été initialisé par le user veolia
            console.log("Il n'y a jamais eu de modif,ajout firstUpdate");

            //ADD des first
            const data: Article28Form = {
              firstUpdate: new Date(Date.now()),
              firstUpdateBy: this.authService.currentUserEmail
            };
            return refForm28.update(data);
          } else {
            console.log('firstUpdate déjà connu');
          }
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

    //MAJ données legal peut importe le cas
    const data: Article28Form = {
      //Ajoutés par la suite
      lastUpdate: new Date(Date.now()),
      lastUpdateBy: this.authService.currentUserEmail,

      //SCC1 ANNEX 1
      // AR28_SCC1_ANNEX_1_Q1: value.AR28_SCC1_ANNEX_1_Q1,
      // AR28_SCC1_ANNEX_1_Q2: value.AR28_SCC1_ANNEX_1_Q2,

      //SCC1 ANNEX 2
      // AR28_SCC1_ANNEX_2_Q1: value.AR28_SCC1_ANNEX_2_Q1,
      // AR28_SCC1_ANNEX_2_Q2: value.AR28_SCC1_ANNEX_2_Q2,
      // AR28_SCC1_ANNEX_2_Q3: value.AR28_SCC1_ANNEX_2_Q3,
      // AR28_SCC1_ANNEX_2_Q4: value.AR28_SCC1_ANNEX_2_Q4,
      // AR28_SCC1_ANNEX_2_Q5: value.AR28_SCC1_ANNEX_2_Q5,
      // AR28_SCC1_ANNEX_2_Q6: value.AR28_SCC1_ANNEX_2_Q6,
      // AR28_SCC1_ANNEX_2_Q7: value.AR28_SCC1_ANNEX_2_Q7,

      //SCC1 ANNEX 3 -> Annex A - TOMs Template
      AR28_SCC1_ANNEX_3_Q0_DATE: value.AR28_SCC1_ANNEX_3_Q0_DATE,
      AR28_SCC1_ANNEX_3_Q0_RADIO: value.AR28_SCC1_ANNEX_3_Q0_RADIO,
      AR28_SCC1_ANNEX_3_Q1_1: value.AR28_SCC1_ANNEX_3_Q1_1,
      AR28_SCC1_ANNEX_3_Q2_1: value.AR28_SCC1_ANNEX_3_Q2_1,
      AR28_SCC1_ANNEX_3_Q2_2: value.AR28_SCC1_ANNEX_3_Q2_2,
      AR28_SCC1_ANNEX_3_Q3_1: value.AR28_SCC1_ANNEX_3_Q3_1,
      AR28_SCC1_ANNEX_3_Q3_2: value.AR28_SCC1_ANNEX_3_Q3_2,
      AR28_SCC1_ANNEX_3_Q3_3: value.AR28_SCC1_ANNEX_3_Q3_3,
      AR28_SCC1_ANNEX_3_Q3_4: value.AR28_SCC1_ANNEX_3_Q3_4,
      AR28_SCC1_ANNEX_3_Q3_5: value.AR28_SCC1_ANNEX_3_Q3_5,
      AR28_SCC1_ANNEX_3_Q3_6: value.AR28_SCC1_ANNEX_3_Q3_6,
      AR28_SCC1_ANNEX_3_Q3_7: value.AR28_SCC1_ANNEX_3_Q3_7,
      AR28_SCC1_ANNEX_3_Q4_1: value.AR28_SCC1_ANNEX_3_Q4_1,
      AR28_SCC1_ANNEX_3_Q4_2: value.AR28_SCC1_ANNEX_3_Q4_2,
      AR28_SCC1_ANNEX_3_Q4_3: value.AR28_SCC1_ANNEX_3_Q4_3,
      AR28_SCC1_ANNEX_3_Q4_4: value.AR28_SCC1_ANNEX_3_Q4_4,
      AR28_SCC1_ANNEX_3_Q5_1: value.AR28_SCC1_ANNEX_3_Q5_1,
      AR28_SCC1_ANNEX_3_Q5_2: value.AR28_SCC1_ANNEX_3_Q5_2,
      AR28_SCC1_ANNEX_3_Q5_3: value.AR28_SCC1_ANNEX_3_Q5_3,
      AR28_SCC1_ANNEX_3_Q5_4: value.AR28_SCC1_ANNEX_3_Q5_4,
      AR28_SCC1_ANNEX_3_Q6_1: value.AR28_SCC1_ANNEX_3_Q6_1,
      AR28_SCC1_ANNEX_3_Q6_2: value.AR28_SCC1_ANNEX_3_Q6_2,
      AR28_SCC1_ANNEX_3_Q7_1: value.AR28_SCC1_ANNEX_3_Q7_1,
      AR28_SCC1_ANNEX_3_Q7_2: value.AR28_SCC1_ANNEX_3_Q7_2,
      AR28_SCC1_ANNEX_3_Q7_3: value.AR28_SCC1_ANNEX_3_Q7_3,
      AR28_SCC1_ANNEX_3_Q7_4: value.AR28_SCC1_ANNEX_3_Q7_4,
      AR28_SCC1_ANNEX_3_Q7_5: value.AR28_SCC1_ANNEX_3_Q7_5,
      AR28_SCC1_ANNEX_3_Q7_6: value.AR28_SCC1_ANNEX_3_Q7_6,
      AR28_SCC1_ANNEX_3_Q8_1: value.AR28_SCC1_ANNEX_3_Q8_1,
      AR28_SCC1_ANNEX_3_Q8_2: value.AR28_SCC1_ANNEX_3_Q8_2,
      AR28_SCC1_ANNEX_3_Q9_1: value.AR28_SCC1_ANNEX_3_Q9_1,
      AR28_SCC1_ANNEX_3_Q9_2: value.AR28_SCC1_ANNEX_3_Q9_2,
      AR28_SCC1_ANNEX_3_Q10_1: value.AR28_SCC1_ANNEX_3_Q10_1,
      AR28_SCC1_ANNEX_3_Q10_2: value.AR28_SCC1_ANNEX_3_Q10_2,
      AR28_SCC1_ANNEX_3_Q10_3: value.AR28_SCC1_ANNEX_3_Q10_3,
      AR28_SCC1_ANNEX_3_Q11_1: value.AR28_SCC1_ANNEX_3_Q11_1,
      AR28_SCC1_ANNEX_3_Q11_2: value.AR28_SCC1_ANNEX_3_Q11_2,
      AR28_SCC1_ANNEX_3_Q12_1: value.AR28_SCC1_ANNEX_3_Q12_1,
      AR28_SCC1_ANNEX_3_Q12_2: value.AR28_SCC1_ANNEX_3_Q12_2,
      AR28_SCC1_ANNEX_3_Q13_1: value.AR28_SCC1_ANNEX_3_Q13_1,
      AR28_SCC1_ANNEX_3_Q13_2: value.AR28_SCC1_ANNEX_3_Q13_2,
      AR28_SCC1_ANNEX_3_Q13_3: value.AR28_SCC1_ANNEX_3_Q13_3,
      AR28_SCC1_ANNEX_3_Q13_4: value.AR28_SCC1_ANNEX_3_Q13_4,
      AR28_SCC1_ANNEX_3_Q14_1: value.AR28_SCC1_ANNEX_3_Q14_1,
      AR28_SCC1_ANNEX_3_Q14_2: value.AR28_SCC1_ANNEX_3_Q14_2,
      AR28_SCC1_ANNEX_3_Q15_1: value.AR28_SCC1_ANNEX_3_Q15_1,
      AR28_SCC1_ANNEX_3_Q15_2: value.AR28_SCC1_ANNEX_3_Q15_2,
      AR28_SCC1_ANNEX_3_Q15_3: value.AR28_SCC1_ANNEX_3_Q15_3,
      AR28_SCC1_ANNEX_3_Q15_4: value.AR28_SCC1_ANNEX_3_Q15_4,
      AR28_SCC1_ANNEX_3_Q16_1: value.AR28_SCC1_ANNEX_3_Q16_1,
      AR28_SCC1_ANNEX_3_Q16_2: value.AR28_SCC1_ANNEX_3_Q16_2,
      AR28_SCC1_ANNEX_3_Q17: value.AR28_SCC1_ANNEX_3_Q17,
      AR28_SCC1_ANNEX_3_Q18: value.AR28_SCC1_ANNEX_3_Q18,

      //SCC1 ANNEX 4
      // AR28_SCC1_ANNEX_4_Q1: value.AR28_SCC1_ANNEX_4_Q1,
      // AR28_SCC1_ANNEX_4_Q2: value.AR28_SCC1_ANNEX_4_Q2,
      // AR28_SCC1_ANNEX_4_Q3: value.AR28_SCC1_ANNEX_4_Q3,

      //SCC2 ANNEX 1
      // AR28_SCC2_ANNEX_1_A_Q1: value.AR28_SCC2_ANNEX_1_A_Q1,
      // AR28_SCC2_ANNEX_1_A_Q2: value.AR28_SCC2_ANNEX_1_A_Q2,
      // AR28_SCC2_ANNEX_1_A_Q3: value.AR28_SCC2_ANNEX_1_A_Q3,
      // AR28_SCC2_ANNEX_1_A_Q4: value.AR28_SCC2_ANNEX_1_A_Q4,
      // AR28_SCC2_ANNEX_1_B_Q1: value.AR28_SCC2_ANNEX_1_B_Q1,
      // AR28_SCC2_ANNEX_1_B_Q2: value.AR28_SCC2_ANNEX_1_B_Q2,
      // AR28_SCC2_ANNEX_1_B_Q3: value.AR28_SCC2_ANNEX_1_B_Q3,
      // AR28_SCC2_ANNEX_1_B_Q4: value.AR28_SCC2_ANNEX_1_B_Q4,
      // AR28_SCC2_ANNEX_1_B_Q5: value.AR28_SCC2_ANNEX_1_B_Q5,
      // AR28_SCC2_ANNEX_1_B_Q6: value.AR28_SCC2_ANNEX_1_B_Q6,
      // AR28_SCC2_ANNEX_1_B_Q7: value.AR28_SCC2_ANNEX_1_B_Q7,
      // AR28_SCC2_ANNEX_1_B_Q8: value.AR28_SCC2_ANNEX_1_B_Q8,
      // AR28_SCC2_ANNEX_1_C_Q1: value.AR28_SCC2_ANNEX_1_C_Q1,

      // //SCC2 ANNEX 2
      // AR28_SCC2_ANNEX_2_Q0_DATE: value.AR28_SCC2_ANNEX_2_Q0_DATE,
      // AR28_SCC2_ANNEX_2_Q0_RADIO: value.AR28_SCC2_ANNEX_2_Q0_RADIO,
      // AR28_SCC2_ANNEX_2_Q1_1: value.AR28_SCC2_ANNEX_2_Q1_1,
      // AR28_SCC2_ANNEX_2_Q2_1: value.AR28_SCC2_ANNEX_2_Q2_1,
      // AR28_SCC2_ANNEX_2_Q2_2: value.AR28_SCC2_ANNEX_2_Q2_2,
      // AR28_SCC2_ANNEX_2_Q3_1: value.AR28_SCC2_ANNEX_2_Q3_1,
      // AR28_SCC2_ANNEX_2_Q3_2: value.AR28_SCC2_ANNEX_2_Q3_2,
      // AR28_SCC2_ANNEX_2_Q3_3: value.AR28_SCC2_ANNEX_2_Q3_3,
      // AR28_SCC2_ANNEX_2_Q3_4: value.AR28_SCC2_ANNEX_2_Q3_4,
      // AR28_SCC2_ANNEX_2_Q3_5: value.AR28_SCC2_ANNEX_2_Q3_5,
      // AR28_SCC2_ANNEX_2_Q3_6: value.AR28_SCC2_ANNEX_2_Q3_6,
      // AR28_SCC2_ANNEX_2_Q3_7: value.AR28_SCC2_ANNEX_2_Q3_7,
      // AR28_SCC2_ANNEX_2_Q4_1: value.AR28_SCC2_ANNEX_2_Q4_1,
      // AR28_SCC2_ANNEX_2_Q4_2: value.AR28_SCC2_ANNEX_2_Q4_2,
      // AR28_SCC2_ANNEX_2_Q4_3: value.AR28_SCC2_ANNEX_2_Q4_3,
      // AR28_SCC2_ANNEX_2_Q4_4: value.AR28_SCC2_ANNEX_2_Q4_4,
      // AR28_SCC2_ANNEX_2_Q5_1: value.AR28_SCC2_ANNEX_2_Q5_1,
      // AR28_SCC2_ANNEX_2_Q5_2: value.AR28_SCC2_ANNEX_2_Q5_2,
      // AR28_SCC2_ANNEX_2_Q5_3: value.AR28_SCC2_ANNEX_2_Q5_3,
      // AR28_SCC2_ANNEX_2_Q5_4: value.AR28_SCC2_ANNEX_2_Q5_4,
      // AR28_SCC2_ANNEX_2_Q6_1: value.AR28_SCC2_ANNEX_2_Q6_1,
      // AR28_SCC2_ANNEX_2_Q6_2: value.AR28_SCC2_ANNEX_2_Q6_2,
      // AR28_SCC2_ANNEX_2_Q7_1: value.AR28_SCC2_ANNEX_2_Q7_1,
      // AR28_SCC2_ANNEX_2_Q7_2: value.AR28_SCC2_ANNEX_2_Q7_2,
      // AR28_SCC2_ANNEX_2_Q7_3: value.AR28_SCC2_ANNEX_2_Q7_3,
      // AR28_SCC2_ANNEX_2_Q7_4: value.AR28_SCC2_ANNEX_2_Q7_4,
      // AR28_SCC2_ANNEX_2_Q7_5: value.AR28_SCC2_ANNEX_2_Q7_5,
      // AR28_SCC2_ANNEX_2_Q7_6: value.AR28_SCC2_ANNEX_2_Q7_6,
      // AR28_SCC2_ANNEX_2_Q8_1: value.AR28_SCC2_ANNEX_2_Q8_1,
      // AR28_SCC2_ANNEX_2_Q8_2: value.AR28_SCC2_ANNEX_2_Q8_2,
      // AR28_SCC2_ANNEX_2_Q9_1: value.AR28_SCC2_ANNEX_2_Q9_1,
      // AR28_SCC2_ANNEX_2_Q9_2: value.AR28_SCC2_ANNEX_2_Q9_2,
      // AR28_SCC2_ANNEX_2_Q10_1: value.AR28_SCC2_ANNEX_2_Q10_1,
      // AR28_SCC2_ANNEX_2_Q10_2: value.AR28_SCC2_ANNEX_2_Q10_2,
      // AR28_SCC2_ANNEX_2_Q10_3: value.AR28_SCC2_ANNEX_2_Q10_3,
      // AR28_SCC2_ANNEX_2_Q11_1: value.AR28_SCC2_ANNEX_2_Q11_1,
      // AR28_SCC2_ANNEX_2_Q11_2: value.AR28_SCC2_ANNEX_2_Q11_2,
      // AR28_SCC2_ANNEX_2_Q12_1: value.AR28_SCC2_ANNEX_2_Q12_1,
      // AR28_SCC2_ANNEX_2_Q12_2: value.AR28_SCC2_ANNEX_2_Q12_2,
      // AR28_SCC2_ANNEX_2_Q13_1: value.AR28_SCC2_ANNEX_2_Q13_1,
      // AR28_SCC2_ANNEX_2_Q13_2: value.AR28_SCC2_ANNEX_2_Q13_2,
      // AR28_SCC2_ANNEX_2_Q13_3: value.AR28_SCC2_ANNEX_2_Q13_3,
      // AR28_SCC2_ANNEX_2_Q13_4: value.AR28_SCC2_ANNEX_2_Q13_4,
      // AR28_SCC2_ANNEX_2_Q14_1: value.AR28_SCC2_ANNEX_2_Q14_1,
      // AR28_SCC2_ANNEX_2_Q14_2: value.AR28_SCC2_ANNEX_2_Q14_2,
      // AR28_SCC2_ANNEX_2_Q15_1: value.AR28_SCC2_ANNEX_2_Q15_1,
      // AR28_SCC2_ANNEX_2_Q15_2: value.AR28_SCC2_ANNEX_2_Q15_2,
      // AR28_SCC2_ANNEX_2_Q15_3: value.AR28_SCC2_ANNEX_2_Q15_3,
      // AR28_SCC2_ANNEX_2_Q15_4: value.AR28_SCC2_ANNEX_2_Q15_4,
      // AR28_SCC2_ANNEX_2_Q16_1: value.AR28_SCC2_ANNEX_2_Q16_1,
      // AR28_SCC2_ANNEX_2_Q16_2: value.AR28_SCC2_ANNEX_2_Q16_2,
      // AR28_SCC2_ANNEX_2_Q17: value.AR28_SCC2_ANNEX_2_Q17,

      // //SCC2 ANNEX 3
      // AR28_SCC2_ANNEX_3_Q1: value.AR28_SCC2_ANNEX_3_Q1,
      // AR28_SCC2_ANNEX_3_Q2: value.AR28_SCC2_ANNEX_3_Q2,
      // AR28_SCC2_ANNEX_3_Q3: value.AR28_SCC2_ANNEX_3_Q3,

      // //SCHREMS US
      // AR28_SCHREMS_US_1_1: value.AR28_SCHREMS_US_1_1,
      // AR28_SCHREMS_US_1_1_MORE: value.AR28_SCHREMS_US_1_1_MORE,
      // AR28_SCHREMS_US_1_1_A: value.AR28_SCHREMS_US_1_1_A,
      // AR28_SCHREMS_US_1_1_A_MORE: value.AR28_SCHREMS_US_1_1_A_MORE,
      // AR28_SCHREMS_US_1_1_B: value.AR28_SCHREMS_US_1_1_B,
      // AR28_SCHREMS_US_1_1_B_MORE: value.AR28_SCHREMS_US_1_1_B_MORE,
      // AR28_SCHREMS_US_1_1_C: value.AR28_SCHREMS_US_1_1_C,
      // AR28_SCHREMS_US_1_1_C_MORE: value.AR28_SCHREMS_US_1_1_C_MORE,
      // AR28_SCHREMS_US_1_1_D: value.AR28_SCHREMS_US_1_1_D,
      // AR28_SCHREMS_US_1_1_D_MORE: value.AR28_SCHREMS_US_1_1_D_MORE,
      // AR28_SCHREMS_US_1_1_E: value.AR28_SCHREMS_US_1_1_E,
      // AR28_SCHREMS_US_1_1_E_MORE: value.AR28_SCHREMS_US_1_1_E_MORE,
      // AR28_SCHREMS_US_2: value.AR28_SCHREMS_US_2,
      // AR28_SCHREMS_US_2_MORE: value.AR28_SCHREMS_US_2_MORE,
      // AR28_SCHREMS_US_3: value.AR28_SCHREMS_US_3,
      // AR28_SCHREMS_US_3_MORE: value.AR28_SCHREMS_US_3_MORE,
      // AR28_SCHREMS_US_4_1_0: value.AR28_SCHREMS_US_4_1_0,
      // AR28_SCHREMS_US_4_1_1: value.AR28_SCHREMS_US_4_1_1,
      // AR28_SCHREMS_US_4_2: value.AR28_SCHREMS_US_4_2,
      // AR28_SCHREMS_US_4_3: value.AR28_SCHREMS_US_4_3,
      // AR28_SCHREMS_US_4_4: value.AR28_SCHREMS_US_4_4,
      // AR28_SCHREMS_US_5_A: value.AR28_SCHREMS_US_5_A,
      // AR28_SCHREMS_US_5_A_MORE: value.AR28_SCHREMS_US_5_A_MORE,
      // AR28_SCHREMS_US_5_B: value.AR28_SCHREMS_US_5_B,
      // AR28_SCHREMS_US_6: value.AR28_SCHREMS_US_6,
      // AR28_SCHREMS_US_7_1: value.AR28_SCHREMS_US_7_1,
      // AR28_SCHREMS_US_7_2: value.AR28_SCHREMS_US_7_2,

      // //SCHREMS UK
      // AR28_SCHREMS_UK_1_1: value.AR28_SCHREMS_UK_1_1,
      // AR28_SCHREMS_UK_1_1_MORE: value.AR28_SCHREMS_UK_1_1_MORE,
      // AR28_SCHREMS_UK_1_2: value.AR28_SCHREMS_UK_1_2,
      // AR28_SCHREMS_UK_1_2_MORE: value.AR28_SCHREMS_UK_1_2_MORE,
      // AR28_SCHREMS_UK_2: value.AR28_SCHREMS_UK_2,
      // AR28_SCHREMS_UK_2_MORE: value.AR28_SCHREMS_UK_2_MORE,
      // AR28_SCHREMS_UK_3_1_0: value.AR28_SCHREMS_UK_3_1_0,
      // AR28_SCHREMS_UK_3_1_1: value.AR28_SCHREMS_UK_3_1_1,
      // AR28_SCHREMS_UK_3_2: value.AR28_SCHREMS_UK_3_2,
      // AR28_SCHREMS_UK_3_3: value.AR28_SCHREMS_UK_3_3,
      // AR28_SCHREMS_UK_3_4: value.AR28_SCHREMS_UK_3_4,
      // AR28_SCHREMS_UK_4_A: value.AR28_SCHREMS_UK_4_A,
      // AR28_SCHREMS_UK_4_A_MORE: value.AR28_SCHREMS_UK_4_A_MORE,
      // AR28_SCHREMS_UK_4_B: value.AR28_SCHREMS_UK_4_B,
      // AR28_SCHREMS_UK_5: value.AR28_SCHREMS_UK_5,
      // AR28_SCHREMS_UK_6_1: value.AR28_SCHREMS_UK_6_1,
      // AR28_SCHREMS_UK_6_2: value.AR28_SCHREMS_UK_6_2,

      // //SCHREMS INDIA
      // AR28_SCHREMS_INDIA_1_1: value.AR28_SCHREMS_INDIA_1_1,
      // AR28_SCHREMS_INDIA_1_1_MORE: value.AR28_SCHREMS_INDIA_1_1_MORE,
      // AR28_SCHREMS_INDIA_1_2: value.AR28_SCHREMS_INDIA_1_2,
      // AR28_SCHREMS_INDIA_1_2_MORE: value.AR28_SCHREMS_INDIA_1_2_MORE,
      // AR28_SCHREMS_INDIA_2_1: value.AR28_SCHREMS_INDIA_2_1,
      // AR28_SCHREMS_INDIA_2_1_MORE: value.AR28_SCHREMS_INDIA_2_1_MORE,
      // AR28_SCHREMS_INDIA_2_2: value.AR28_SCHREMS_INDIA_2_2,
      // AR28_SCHREMS_INDIA_2_2_MORE: value.AR28_SCHREMS_INDIA_2_2_MORE,
      // AR28_SCHREMS_INDIA_3: value.AR28_SCHREMS_INDIA_3,
      // AR28_SCHREMS_INDIA_3_MORE: value.AR28_SCHREMS_INDIA_3_MORE,
      // AR28_SCHREMS_INDIA_4_1_0: value.AR28_SCHREMS_INDIA_4_1_0,
      // AR28_SCHREMS_INDIA_4_1_1: value.AR28_SCHREMS_INDIA_4_1_1,
      // AR28_SCHREMS_INDIA_4_2: value.AR28_SCHREMS_INDIA_4_2,
      // AR28_SCHREMS_INDIA_4_3: value.AR28_SCHREMS_INDIA_4_3,
      // AR28_SCHREMS_INDIA_4_4: value.AR28_SCHREMS_INDIA_4_4,
      // AR28_SCHREMS_INDIA_5_A: value.AR28_SCHREMS_INDIA_5_A,
      // AR28_SCHREMS_INDIA_5_A_MORE: value.AR28_SCHREMS_INDIA_5_A_MORE,
      // AR28_SCHREMS_INDIA_5_B: value.AR28_SCHREMS_INDIA_5_B,
      // AR28_SCHREMS_INDIA_6: value.AR28_SCHREMS_INDIA_6,
      // AR28_SCHREMS_INDIA_7_1: value.AR28_SCHREMS_INDIA_7_1,
      // AR28_SCHREMS_INDIA_7_2: value.AR28_SCHREMS_INDIA_7_2,

      // //SCHREMS GENERIC
      // AR28_SCHREMS_GENERIC_1: value.AR28_SCHREMS_GENERIC_1,
      // AR28_SCHREMS_GENERIC_1_1: value.AR28_SCHREMS_GENERIC_1_1,
      // AR28_SCHREMS_GENERIC_1_1_MORE: value.AR28_SCHREMS_GENERIC_1_1_MORE,
      // AR28_SCHREMS_GENERIC_1_2: value.AR28_SCHREMS_GENERIC_1_2,
      // AR28_SCHREMS_GENERIC_1_2_MORE: value.AR28_SCHREMS_GENERIC_1_2_MORE,
      // AR28_SCHREMS_GENERIC_2: value.AR28_SCHREMS_GENERIC_2,
      // AR28_SCHREMS_GENERIC_2_MORE: value.AR28_SCHREMS_GENERIC_2_MORE,
      // AR28_SCHREMS_GENERIC_3_1_0: value.AR28_SCHREMS_GENERIC_3_1_0,
      // AR28_SCHREMS_GENERIC_3_1_1: value.AR28_SCHREMS_GENERIC_3_1_1,
      // AR28_SCHREMS_GENERIC_3_2: value.AR28_SCHREMS_GENERIC_3_2,
      // AR28_SCHREMS_GENERIC_3_3: value.AR28_SCHREMS_GENERIC_3_3,
      // AR28_SCHREMS_GENERIC_3_4: value.AR28_SCHREMS_GENERIC_3_4,
      // AR28_SCHREMS_GENERIC_4_A: value.AR28_SCHREMS_GENERIC_4_A,
      // AR28_SCHREMS_GENERIC_4_A_MORE: value.AR28_SCHREMS_GENERIC_4_A_MORE,
      // AR28_SCHREMS_GENERIC_4_B: value.AR28_SCHREMS_GENERIC_4_B,
      // AR28_SCHREMS_GENERIC_5: value.AR28_SCHREMS_GENERIC_5,
      // AR28_SCHREMS_GENERIC_6_1: value.AR28_SCHREMS_GENERIC_6_1,
      // AR28_SCHREMS_GENERIC_6_2: value.AR28_SCHREMS_GENERIC_6_2,

    };

    //on update les data dans 'article28Form' et le champ lastUpdate dans 'solutions'
    return refForm28.update(data).then(res => {
      this.firestore.doc('solutions/' + id).update({ lastUpdate: new Date(Date.now()) }); //on update dans 'solutions' juste lastUpdate
    });
  }

  updateDataArticle32(id: string, value, //utilisés que pour retarus
    vendorEmail,
    solutionOwner,
    rfpName,
    editorBrand,
    businessUnitOfSolution) {

    const refForm32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${id}`);

    //Ajoute firstUpdate et firstUpdateBy lors de la premiere modif du form par un vendor
    //Si le champ firstUpdate n'existe pas lors du premier save par le vendor
    //alors il n'y a jamais eu de premiere modif donc (que ce soit design ou legal)
    //= ajout du firstUpdate et firstUpdateBy ; premier vendor à avoir save
    const fire = firebase.firestore();

    fire
      .collection('article32Form')
      .doc(this.idSolution)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          let firstUpdate = doc.data().firstUpdate;

          if (!firstUpdate) {
            //envoie au SO que le vendor a commencé à remplir le form
            this.sendFormStartedSaaS(this.solName, this.tabName, solutionOwner, rfpName, editorBrand, businessUnitOfSolution, vendorEmail).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
                console.log("from api: " + data);
            });

            //si ce champ n'existe pas alors le doc a juste été initialisé par le user veolia
            console.log("Il n'y a jamais eu de modif,ajout firstUpdate");

            //ADD des first
            const data: Article32Form = {
              firstUpdate: new Date(Date.now()),
              firstUpdateBy: this.authService.currentUserEmail
            };
            return refForm32.update(data);
          } else {
            console.log('firstUpdate déjà connu');
          }
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

    //MAJ données legal peut importe le cas
    const data: Article32Form = {
      //Ajoutés par la suite
      lastUpdate: new Date(Date.now()),
      lastUpdateBy: this.authService.currentUserEmail,

      //CHAPITRE 1
      AR32_1_01_SELECT: value.AR32_1_01_SELECT,
      AR32_1_01_PROOF: value.AR32_1_01_PROOF,
      AR32_1_02_SELECT: value.AR32_1_02_SELECT,
      AR32_1_02_PROOF: value.AR32_1_02_PROOF,

      //CHAPITRE 2
      AR32_2_01_SELECT: value.AR32_2_01_SELECT,
      AR32_2_01_PROOF: value.AR32_2_01_PROOF,
      AR32_2_02_SELECT: value.AR32_2_02_SELECT,
      AR32_2_02_PROOF: value.AR32_2_02_PROOF,
      AR32_2_03_SELECT: value.AR32_2_03_SELECT,
      AR32_2_03_PROOF: value.AR32_2_03_PROOF,
      AR32_2_04_SELECT: value.AR32_2_04_SELECT,
      AR32_2_04_PROOF: value.AR32_2_04_PROOF,

      //CHAPITRE 3
      AR32_3_01_SELECT: value.AR32_3_01_SELECT,
      AR32_3_01_PROOF: value.AR32_3_01_PROOF,
      AR32_3_02_SELECT: value.AR32_3_02_SELECT,
      AR32_3_02_PROOF: value.AR32_3_02_PROOF,
      AR32_3_03_SELECT: value.AR32_3_03_SELECT,
      AR32_3_03_PROOF: value.AR32_3_03_PROOF,
      AR32_3_04_SELECT: value.AR32_3_04_SELECT,
      AR32_3_04_PROOF: value.AR32_3_04_PROOF,
      AR32_3_05_SELECT: value.AR32_3_05_SELECT,
      AR32_3_05_PROOF: value.AR32_3_05_PROOF,
      AR32_3_06_SELECT: value.AR32_3_06_SELECT,
      AR32_3_06_PROOF: value.AR32_3_06_PROOF,
      AR32_3_07_SELECT: value.AR32_3_07_SELECT,
      AR32_3_07_PROOF: value.AR32_3_07_PROOF,
      AR32_3_08_SELECT: value.AR32_3_08_SELECT,
      AR32_3_08_PROOF: value.AR32_3_08_PROOF,

      //CHAPITRE 4
      AR32_4_01_SELECT: value.AR32_4_01_SELECT,
      AR32_4_01_PROOF: value.AR32_4_01_PROOF,
      AR32_4_02_SELECT: value.AR32_4_02_SELECT,
      AR32_4_02_PROOF: value.AR32_4_02_PROOF,
      AR32_4_03_SELECT: value.AR32_4_03_SELECT,
      AR32_4_03_PROOF: value.AR32_4_03_PROOF,
      AR32_4_04_SELECT: value.AR32_4_04_SELECT,
      AR32_4_04_PROOF: value.AR32_4_04_PROOF,
      AR32_4_05_SELECT: value.AR32_4_05_SELECT,
      AR32_4_05_PROOF: value.AR32_4_05_PROOF,
      AR32_4_06_SELECT: value.AR32_4_06_SELECT,
      AR32_4_06_PROOF: value.AR32_4_06_PROOF,
      AR32_4_07_SELECT: value.AR32_4_07_SELECT,
      AR32_4_07_PROOF: value.AR32_4_07_PROOF,
      AR32_4_08_SELECT: value.AR32_4_08_SELECT,
      AR32_4_08_PROOF: value.AR32_4_08_PROOF,
      AR32_4_09_SELECT: value.AR32_4_09_SELECT,
      AR32_4_09_PROOF: value.AR32_4_09_PROOF,
      AR32_4_10_SELECT: value.AR32_4_10_SELECT,
      AR32_4_10_PROOF: value.AR32_4_10_PROOF,
      AR32_4_11_SELECT: value.AR32_4_11_SELECT,
      AR32_4_11_PROOF: value.AR32_4_11_PROOF,
      AR32_4_12_SELECT: value.AR32_4_12_SELECT,
      AR32_4_12_PROOF: value.AR32_4_12_PROOF,
      AR32_4_13_SELECT: value.AR32_4_13_SELECT,
      AR32_4_13_PROOF: value.AR32_4_13_PROOF,
      AR32_4_14_SELECT: value.AR32_4_14_SELECT,
      AR32_4_14_PROOF: value.AR32_4_14_PROOF,

      //CHAPITRE 5
      AR32_5_01_SELECT: value.AR32_5_01_SELECT,
      AR32_5_01_PROOF: value.AR32_5_01_PROOF,
      AR32_5_02_SELECT: value.AR32_5_02_SELECT,
      AR32_5_02_PROOF: value.AR32_5_02_PROOF,
      AR32_5_03_SELECT: value.AR32_5_03_SELECT,
      AR32_5_03_PROOF: value.AR32_5_03_PROOF,
      AR32_5_04_SELECT: value.AR32_5_04_SELECT,
      AR32_5_04_PROOF: value.AR32_5_04_PROOF,
      AR32_5_05_SELECT: value.AR32_5_05_SELECT,
      AR32_5_05_PROOF: value.AR32_5_05_PROOF,

      //CHAPITRE 6
      AR32_6_01_SELECT: value.AR32_6_01_SELECT,
      AR32_6_01_PROOF: value.AR32_6_01_PROOF,
      AR32_6_02_SELECT: value.AR32_6_02_SELECT,
      AR32_6_02_PROOF: value.AR32_6_02_PROOF,
      AR32_6_03_SELECT: value.AR32_6_03_SELECT,
      AR32_6_03_PROOF: value.AR32_6_03_PROOF,
      AR32_6_04_SELECT: value.AR32_6_04_SELECT,
      AR32_6_04_PROOF: value.AR32_6_04_PROOF,

      //CHAPITRE 7
      AR32_7_01_SELECT: value.AR32_7_01_SELECT,
      AR32_7_01_PROOF: value.AR32_7_01_PROOF,
      AR32_7_02_SELECT: value.AR32_7_02_SELECT,
      AR32_7_02_PROOF: value.AR32_7_02_PROOF,
      AR32_7_03_SELECT: value.AR32_7_03_SELECT,
      AR32_7_03_PROOF: value.AR32_7_03_PROOF,
      AR32_7_04_SELECT: value.AR32_7_04_SELECT,
      AR32_7_04_PROOF: value.AR32_7_04_PROOF,

      //CHAPITRE 8
      AR32_8_01_SELECT: value.AR32_8_01_SELECT,
      AR32_8_01_PROOF: value.AR32_8_01_PROOF,
      AR32_8_02_SELECT: value.AR32_8_02_SELECT,
      AR32_8_02_PROOF: value.AR32_8_02_PROOF,
      AR32_8_03_SELECT: value.AR32_8_03_SELECT,
      AR32_8_03_PROOF: value.AR32_8_03_PROOF,
      AR32_8_04_SELECT: value.AR32_8_04_SELECT,
      AR32_8_04_PROOF: value.AR32_8_04_PROOF
    };

    //on update les data dans 'article28Form' et le champ lastUpdate dans 'solutions'
    return refForm32.update(data).then(res => {
      this.firestore.doc('solutions/' + id).update({ lastUpdate: new Date(Date.now()) }); //on update dans 'solutions' juste lastUpdate
    });
  }

  updateDataIaaS(id: string, value,
  //utilisés que pour retarus
    vendorEmail,
    solutionOwner,
    editorBrand,
    businessUnitOfSolution) {

    const refFormIaaS: AngularFirestoreDocument<IaaSForm> = this.firestore.doc(`iaasForm/${id}`); // id du document formIaaS=idSolution

    //Ajoute firstUpdate et firstUpdateBy lors de la premiere modif du form par un veolia owner
    //Si le champ firstUpdate n'existe pas lors du premier save par le veolia owner
    //alors il n'y a jamais eu de premiere modif donc (que ce soit design ou legal)
    //= ajout du firstUpdate et firstUpdateBy ; premier veolia owner à avoir save
    const fire = firebase.firestore();

    fire
      .collection('iaasForm')
      .doc(this.idSolution)
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          //console.log('Document data:', doc.data());
          let firstUpdate = doc.data().firstUpdate;

          if (!firstUpdate) {
            //si ce champ n'existe pas alors le doc a juste été initialisé par le user veolia
            console.log("Il n'y a jamais eu de modif,ajout firstUpdate");

            //ADD des first
            const data: IaaSForm = {
              firstUpdate: new Date(Date.now()),
              firstUpdateBy: this.authService.currentUserEmail
            };
            return refFormIaaS.update(data);
          } else {
            console.log('firstUpdate déjà connu');
          }
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });

    //MAJ données iaas peut importe le cas
    const data: IaaSForm = {
      //Ajoutés par la suite
      lastUpdate: new Date(Date.now()),
      lastUpdateBy: this.authService.currentUserEmail,

      //Chapitre 1
      IAAS_11_01_RADIO: value.IAAS_11_01_RADIO,
      IAAS_11_02: value.IAAS_11_02,
      IAAS_12_01_RADIO: value.IAAS_12_01_RADIO,
      IAAS_12_02: value.IAAS_12_02,

      //Chapitre 2
      IAAS_2_01_RADIO: value.IAAS_2_01_RADIO,
      IAAS_2_02: value.IAAS_2_02,
      IAAS_2_03: value.IAAS_2_03,
      IAAS_2_04: value.IAAS_2_04,

      //Chapitre 3
      IAAS_3_01: value.IAAS_3_01,
      IAAS_3_02: value.IAAS_3_02,

      //Chapitre 4
      IAAS_4_01: value.IAAS_4_01
    };

    //on update les data dans 'legalForm' et le champ lastUpdate dans 'solutions'
    return refFormIaaS.update(data).then(res => {
      this.firestore.doc('solutions/' + id).update({ lastUpdate: new Date(Date.now()) }); //on update dans 'solutions' juste lastUpdate
    });
  }

  //Lorsque le vendor a terminé de tout remplir (design ou legal ou art32 ou art28) est déclenché par formComplete dans ts
  //on met pour designStatus ou legalStatus 'Completed' dans collection designForm ou legalForm
  //on met pour designStatus ou legalStatus 'Completed' dans collection solutions
  setCompleteStatus(form){
    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
    const refForm28: AngularFirestoreDocument<Article28Form> = this.firestore.doc(`article28Form/${this.idSolution}`);
    const refForm32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);

    const refFormIaaS: AngularFirestoreDocument<IaaSForm> = this.firestore.doc(`iaasForm/${this.idSolution}`);

    if(form === "Design Form") {
      refFormDa.set( {designStatus: 'Completed' }, {merge:true} )
      demandeRef.set( {designStatus: 'Completed' }, {merge:true} )
    }

    if(form === "Legal Form") {
      refFormLa.set( {legalStatus: 'Completed' }, {merge:true} )
      demandeRef.set( {legalStatus: 'Completed' }, {merge:true} )
    }

    if(form === "Article 28 Form") {
      refForm28.set( {article28Status: 'Completed' }, {merge:true} )
      demandeRef.set( {article28Status: 'Completed' }, {merge:true} )
    }

    if(form === "Article 32 Form") {
      refForm32.set( {article32Status: 'Completed' }, {merge:true} )
      demandeRef.set( {article32Status: 'Completed' }, {merge:true} )
    }

    if(form === "IaaS Form") {
      refFormIaaS.set( {iaasStatus: 'Completed' }, {merge:true} )
      demandeRef.set( {iaasStatus: 'Completed' }, {merge:true} )
    }

  }

  setUnderAssessmentStatus(form) {
    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
    const refForm28: AngularFirestoreDocument<Article28Form> = this.firestore.doc(`article28Form/${this.idSolution}`);
    const refForm32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);

    const refFormIaaS: AngularFirestoreDocument<IaaSForm> = this.firestore.doc(`iaasForm/${this.idSolution}`);

    if(form === "Design Form") {
      refFormDa.set( {designStatus: 'DLA is reviewing' }, {merge:true} )
      demandeRef.set( {designStatus: 'DLA is reviewing' }, {merge:true} )
    }

    if(form === "Legal Form") {
      refFormLa.set( {legalStatus: 'DLA is reviewing' }, {merge:true} )
      demandeRef.set( {legalStatus: 'DLA is reviewing' }, {merge:true} )
    }

    if(form === "Article 28 Form") {
      refForm28.set( {article28Status: 'DLA is reviewing' }, {merge:true} )
      demandeRef.set( {article28Status: 'DLA is reviewing' }, {merge:true} )
    }

    if(form === "Article 32 Form") {
      refForm32.set( {article32Status: 'DLA is reviewing' }, {merge:true} )
      demandeRef.set( {article32Status: 'DLA is reviewing' }, {merge:true} )
    }

    if(form === "IaaS Form") {
      refFormIaaS.set( {iaasStatus: 'DLA is reviewing' }, {merge:true} )
      demandeRef.set( {iaasStatus: 'DLA is reviewing' }, {merge:true} )
    }
  }

  ////////COMPLETE CHAPTER/SUB CHAPTER///////
  insertCompleteChapterSubChapter(com: CompleteChapterSubChapter) {
    let email;
    let firstname;
    let lastname;
    let displayName;

    this.path = '/completeChapSubChap/' + this.tabName + '/' + this.idSolution + '/' + this.reference;

    this.afs.list(`${this.path}/`).push({
      idSolution: this.idSol,
      formIs: this.tabName,
      reference: this.reference, //recup selon la modale ouverte
      addedAt: new Date(Date.now()).toString(),
      userId: this.authService.currentUserId,
      addedBy: this.authService.currentUserEmail,
      comment: com.comment || null,
      idRef: this.getIdRef(),
      solutionName: this.solName,
      status: com.status,
    });

  this.addLogCompleteChapterSubChapter(this.reference, com.comment, com.status);

  const fire = firebase.firestore();
      //récup infos du user
        fire.collection('users').where("email", '==', this.authService.currentUserEmail)
        .get().then(snap => {
          snap.forEach(val => {
            email = val.data().email;
            firstname = val.data().firstname;
            lastname = val.data().lastname;
            displayName = val.data().displayName;
          })
        }).then(res => {
          //SaaS
          if(this.tabName === 'Design Form' || this.tabName === 'Legal Form' || this.tabName === 'Article 32 Form' || this.tabName === 'Article 28 Form') {
          //1- envoie mail à la DLA + SO que le chapitre/sous-chapitre a été complété
            //   this.sendCompleteChapterSubChapterSaaS(this.tabName, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, email, firstname, lastname, displayName, this.reference, new Date(Date.now()).toString(), com.comment, com.status, this.buOfSolu, this.emailSo, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
            //   if (data !== "200") {
            //     console.error("KO mail retarus unsent !")
            //   } else {
            //     console.log("OK mail retarus sent !")
            //   }
            //     console.log("from api: " + data);
            // });
          }

          //2-envoi email aux evaluators BU inclus dans evaluators
          if(this.emailsEvaluatorsBu) {
            for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
              //console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

              // this.sendCompleteChapterSubChapterSaaS(this.tabName, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, email, firstname, lastname, displayName, this.reference, new Date(Date.now()).toString(), com.comment, com.status, this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              //   if (data !== "200") {
              //     console.error("KO mail retarus unsent !")
              //   } else {
              //       console.log("OK mail retarus sent !")
              //   }
              //   console.log("from api: " + data);
              // });
            }
          }

          //IaaS PaaS Apps
          if(this.tabName === 'IaaS Form' || this.tabName === 'PaaS Form' || this.tabName === 'Apps Form') {
          //envoie mail à la DLA + SO que le chapitre/sous-chapitre a été complété
            //   this.sendCompleteChapterSubChapter(this.tabName, this.solName, this.editorBrandOfSol, email, this.reference, new Date(Date.now()).toString(), com.comment, com.status, this.buOfSolu, this.emailSo).subscribe(data => {
            //   if (data !== "200") {
            //     console.error("KO mail retarus unsent !")
            //   } else {
            //     console.log("OK mail retarus sent !")
            //   }
            //     console.log("from api: " + data);
            // });
          }
        })

  }

  getCompleteChapterSubChapter(idref: string) {
    return (this.completeList = this.afs.list(
      'completeChapSubChap/' + this.tabName + '/' + this.idSolution + '/' + this.ref,
      ref => ref.orderByChild('idRef').equalTo(idref)
    ));
  }

  updateCompleteChapterSubChapter(com: CompleteChapterSubChapter) {
    this.completeList.update(com.$key, {
      addedAt: new Date(Date.now()).toString(),
      addedBy: this.authService.currentUserEmail,
      comment: com.comment || null,
      status: com.status,
    });
    this.addLogCompleteChapterSubChapter(this.reference, com.comment, com.status);
  }


  ////////COMMENTAIRES////////
  getComments(idref: string) {
    return (this.commentsList = this.afs.list(
      'comments/' + this.tabName + '/' + this.idSolution + '/' + this.ref,
      ref => ref.orderByChild('idRef').equalTo(idref)
    ));
  }

  getAllComments() {
    return (this.commentsList = this.afs.list('comments/' + this.tabName + '/' + this.idSolution));
  }

  insertComment(com: Comment) {
    //vendor
    let firstnameVendor;
    let lastnameVendor;
    //veolia
    let fullNameVeolia;

    let stringForm;

    if (this.tabName === 'Design Form') {
      stringForm = 'designForm';
    }
    if (this.tabName === 'Legal Form') {
      stringForm = 'legalForm';
    }
    if (this.tabName === 'Article 32 Form') {
      stringForm = 'article32Form';
    }
    if (this.tabName === 'Article 28 Form') { //Annexes
      stringForm = 'article28Form';
    }

    this.path = '/comments/' + this.tabName + '/' + this.idSolution + '/' + this.reference;

    this.afs.list(`${this.path}/`).push({
      idSolution: this.idSol,
      formIs: this.tabName,
      reference: this.reference, //recup selon la modale ouverte
      postedAt: new Date(Date.now()).toString(),
      userId: this.authService.currentUserId,
      postedBy: this.authService.currentUserEmail,
      content: com.content,
      idRef: this.getIdRef(),
      solutionName: this.solName,
      taggedUser: com.taggedUser //=email => un user OU tous les users veolias OU tous les vendors OU tous evaluators bu
    });

    this.addLogSetComment(this.reference, com.taggedUser, com.content);

    //si un user spécifique a été taggué
    if(com.taggedUser !== "vendors" && com.taggedUser !== "veolias" && com.taggedUser !== "evaluatorsBu") {
      const fire = firebase.firestore();

      //récup infos du user
        fire.collection('users').where("email", '==', this.authService.currentUserEmail)
        .get().then(snap => {
          snap.forEach(val => {
            //récup infos du vendor
            firstnameVendor = val.data().firstname;
            lastnameVendor = val.data().lastname
            //récup infos du veolia
            fullNameVeolia = val.data().displayName
          })
        }).then(res => {
            //la DLA n'est pas notifiée sendCommentAddedSaaS non déclenché

            //envoie mail au user taggé dans le commentaire uniquement
            this.sendTaggedUser(this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, fullNameVeolia, firstnameVendor, lastnameVendor, this.reference, new Date(Date.now()).toString(), com.content, com.taggedUser, this.buOfSolu).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
                console.log("from api: " + data);
            });

          });

    } //END user taggué

    //si une liste a été tagguée
    if(com.taggedUser === "vendors" || com.taggedUser === "veolias" || com.taggedUser === "evaluatorsBu") {

      //SaaS
      if (this.tabName === 'Design Form' || this.tabName === 'Legal Form' || this.tabName === 'Article 32 Form' || this.tabName === 'Article 28 Form') {
        const fire = firebase.firestore();
          //récup infos du user
        fire.collection('users').where("email", '==', this.authService.currentUserEmail)
        .get().then(snap => {
          snap.forEach(val => {
            //récup infos du vendor
            firstnameVendor = val.data().firstname;
            lastnameVendor = val.data().lastname
            //récup infos du veolia
            fullNameVeolia = val.data().displayName
          })
        }).then(res => {

                //2-
                if(com.taggedUser === "vendors") {
                  console.log("vendors tagged")

                  //1-envoi du mail au SO + gbldla
                  console.log("mail envoyé au so " + this.emailSo + " + à la DLA")
                  this.sendCommentAddedSaaS("vendorsList", this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, fullNameVeolia, firstnameVendor, lastnameVendor, this.reference, new Date(Date.now()).toString(), com.content, this.buOfSolu, null, this.emailSo, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                    if (data !== "200") {
                      console.error("KO mail retarus unsent !")
                    } else {
                      console.log("OK mail retarus sent !")
                    }
                      console.log("from api: " + data);
                  });

                  //envoi mail aux vendors inclus dans emailVendorAuthorized
                  for(let i = 0; i < this.emailsVendors.length; i++) {
                    console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

                    this.sendCommentAddedSaaS("vendorsList", this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, fullNameVeolia, firstnameVendor, lastnameVendor, this.reference, new Date(Date.now()).toString(), com.content, this.buOfSolu, this.emailsVendors[i], null, null).subscribe(data => {
                        if (data !== "200") {
                          console.error("KO mail retarus unsent !")
                        } else {
                          console.log("OK mail retarus sent !")
                        }
                          console.log("from api: " + data);
                        });

                  }
                }

                //2-
                if(com.taggedUser === "veolias") {
                  console.log("veolias tagged")

                  //1-envoi du mail au SO + gbldla
                  console.log("mail envoyé au so " + this.emailSo + " + à la DLA")
                  this.sendCommentAddedSaaS("veoliasList", this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, fullNameVeolia, firstnameVendor, lastnameVendor, this.reference, new Date(Date.now()).toString(), com.content, this.buOfSolu, null, this.emailSo, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                    if (data !== "200") {
                      console.error("KO mail retarus unsent !")
                    } else {
                      console.log("OK mail retarus sent !")
                    }
                      console.log("from api: " + data);
                  });

                  //envoi email aux veolias inclus dans emailVeoliaAuthorized
                  for(let i = 0; i < this.emailsVeolias.length; i++) {
                    console.log("mail envoyé au veolia: (" + i + ") " + this.emailsVeolias[i])

                    this.sendCommentAddedSaaS("veoliasList", this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, fullNameVeolia, firstnameVendor, lastnameVendor, this.reference, new Date(Date.now()).toString(), com.content, this.buOfSolu, this.emailsVeolias[i], null, null).subscribe(data => {
                        if (data !== "200") {
                          console.error("KO mail retarus unsent !")
                        } else {
                          console.log("OK mail retarus sent !")
                        }
                          console.log("from api: " + data);
                        });

                  }
                }

                //2-
                if(com.taggedUser === "evaluatorsBu") {
                  console.log("evaluators bu tagged")

                  //1-envoi du mail au SO + gbldla
                  console.log("mail envoyé au so " + this.emailSo + " + à la DLA")
                  this.sendCommentAddedSaaS("evaluatorsBuList", this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, fullNameVeolia, firstnameVendor, lastnameVendor, this.reference, new Date(Date.now()).toString(), com.content, this.buOfSolu, null, this.emailSo, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                    if (data !== "200") {
                      console.error("KO mail retarus unsent !")
                    } else {
                      console.log("OK mail retarus sent !")
                    }
                      console.log("from api: " + data);
                  });

                  //envoi email aux evaluators BU inclus dans evaluators
                if(this.emailsEvaluatorsBu) {
                  for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
                    console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

                    this.sendCommentAddedSaaS("evaluatorsBuList", this.tabName, stringForm, this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, fullNameVeolia, firstnameVendor, lastnameVendor, this.reference, new Date(Date.now()).toString(), com.content, this.buOfSolu, this.emailsEvaluatorsBu[i], null, null).subscribe(data => {
                    if (data !== "200") {
                          console.error("KO mail retarus unsent !")
                        } else {
                          console.log("OK mail retarus sent !")
                        }
                          console.log("from api: " + data);
                        });
                  }
                  }
                }
        });
      }

      //IaaS PaaS Apps
      if (this.tabName === 'IaaS Form' || this.tabName === 'PaaS Form' || this.tabName === 'Apps Form') {
        const fire = firebase.firestore();
          //récup infos du user
            fire.collection('users').where("email", '==', this.authService.currentUserEmail)
            .get().then(snap => {
              snap.forEach(val => {

              //envoi mail à la BU DLA
                    this.sendCommentAdded(this.tabName, stringForm, this.idSolution, this.solName, this.editorBrandOfSol, val.data().email, val.data().displayName, this.reference, new Date(Date.now()).toString(), com.content, this.buOfSolu, this.emailSo).subscribe(data => {
                      if (data !== "200") {
                        console.error("KO mail retarus unsent !")
                      } else {
                        console.log("OK mail retarus sent !")
                      }
                        console.log("from api: " + data);
                    });

            })
          })
      }
    } //END liste taggée

    //repasse en Vendor is responding le formulaire même s'il était à completed (si le form est déjà évalué on ne change pas le statut)
    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc('solutions/'+ this.idSolution);


    demandeRef.get().forEach(val =>{
       if(this.tabName === "Design Form") {
          if(val.data().designStatus === "DLA is reviewing" || val.data().designStatus === "Recommended" || val.data().designStatus === "Reservations" || val.data().designStatus === "Not recommended" ) {
            console.log("Le status du formulaire est déjà évalué=> aucun changement effectué")
          }
          else { //si statut du formulaire est à Completed ou New ou Vendor is responding on repasse à Vendor is responding
            demandeRef.set( {designStatus: 'Vendor is responding' }, {merge:true} )  //maj designStatus in solutions
          }
       }
       if(this.tabName === "Legal Form") {
          if(val.data().legalStatus === "DLA is reviewing" || val.data().legalStatus === "Recommended" || val.data().legalStatus === "Reservations" || val.data().legalStatus === "Not recommended" ) {
            console.log("Le status du formulaire est déjà évalué=> aucun changement effectué")
          }
          else { //si statut du formulaire est à Completed ou New ou Vendor is responding on repasse à Vendor is responding
            demandeRef.set( {legalStatus: 'Vendor is responding' }, {merge:true} )  //maj legalStatus in solutions
          }
       }
       if(this.tabName === "Article 28 Form") {
          if(val.data().article28Status === "DLA is reviewing" || val.data().article28Status === "Recommended" || val.data().article28Status === "Reservations" || val.data().article28Status === "Not recommended" ) {
            console.log("Le status du formulaire est déjà évalué=> aucun changement effectué")
          }
          else { //si statut du formulaire est à Completed ou New ou Vendor is responding on repasse à Vendor is responding
            demandeRef.set( {article28Status: 'Vendor is responding' }, {merge:true} )  //maj article28Status in solutions
          }
       }
       if(this.tabName === "Article 32 Form") {
          if(val.data().article32Status === "DLA is reviewing" || val.data().article32Status === "Recommended" || val.data().article32Status === "Reservations" || val.data().article32Status === "Not recommended" ) {
            console.log("Le status du formulaire est déjà évalué=> aucun changement effectué")
          }
          else { //si statut du formulaire est à Completed ou New ou Vendor is responding on repasse à Vendor is responding
            demandeRef.set( {article32Status: 'Vendor is responding' }, {merge:true} )  //maj article32Status in solutions
          }
       }
       if(this.tabName === "IaaS Form") {
          if(val.data().iaasStatus === "DLA is reviewing" || val.data().iaasStatus === "Recommended" || val.data().iaasStatus === "Reservations" || val.data().iaasStatus === "Not recommended" ) {
            console.log("Le status du formulaire est déjà évalué=> aucun changement effectué")
          }
          else { //si statut du formulaire est à Completed ou New ou Vendor is responding on repasse à Vendor is responding
            demandeRef.set( {iaasStatus: 'Vendor is responding' }, {merge:true} )  //maj iaasStatusStatus in solutions
          }
       }
    })
  }

  updateComment(com: Comment) {
    this.commentsList.update(com.$key, {
      postedAt: new Date(Date.now()).toString(),
      content: com.content,
      status: null,// on retire le seen
      taggedUser: com.taggedUser || null
    });

    this.addLogEditComment(this.reference, com.taggedUser, com.content);
  }

  deleteComment($key: string, chap: string, taggedUser: string, content: string) {
    document.getElementById(chap + '_COM').style.color = 'grey';

    this.commentsList.remove($key);
    this.addLogDeleteComment(chap, taggedUser, content);
  }

  //Seulement SaaS
  sendTaggedUser(tabName, stringForm, idSol, solName, rfpName, editorBrand, email, displayName, firstname, lastname, reference, comDate, content, taggedUser, buOfSolu){
    const query = dictToURI({
      emailTagged: taggedUser,
      stringForm,
      idSol,
      formName: tabName,
      email,
      displayNameVeolia: displayName,
      firstnameVendor: firstname,
      lastnameVendor: lastname,
      bu: buOfSolu,
      solutionName:solName,
      rfpName,
      editorBrand,
      chapter: this.translateService.instant(reference),
      postedAt: comDate,
      content
    });

    return this.httpClient.get('/api/tagged_user?'+query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  //SaaS
  sendCommentAddedSaaS(list, tabName, stringForm, idSol, solName, rfpName, editorBrand, email, displayName, firstname, lastname, reference, comDate, content, buOfSolu, emailsArray, emailSo, emailDla){
    const query = dictToURI({
      formName: tabName,
      list,
      stringForm,
      idSol,
      email,
      displayNameVeolia: displayName,
      firstnameVendor: firstname,
      lastnameVendor: lastname,
      bu: buOfSolu,
      solutionName: solName,
      rfpName,
      editorBrand,
      chapter: this.translateService.instant(reference),
      postedAt: comDate,
      content,
      emailsList: emailsArray,
      emailSolOwner: emailSo,
      emailDla
    });

    return this.httpClient.get('/api/comment_added_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  //IaaS PaaS Apps
  sendCommentAdded(tabName, stringForm, idSol, solName, editorBrand, email, displayName, reference, comDate, content, buOfSolu, emailSo){
      const query = dictToURI({
        formName: tabName,
        stringForm,idSol,
        email,
        displayNameVeolia: displayName,
        bu: buOfSolu,
        solutionName: solName,
        editorBrand: editorBrand,
        chapter: this.translateService.instant(reference),
        postedAt: comDate,
        content,
        emailSolOwner: emailSo,
        type: tabName.substring(0, 4)
      });
      return this.httpClient.get('/api/comment_added?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttp)
      );
  }

  ////////fin commentaire////////

  ////////COMPLETE CHAPTER/SUB CHAPTER///////
  //SaaS
  // sendCompleteChapterSubChapterSaaS(tabName: string, solName: string, rfpName: string, editorBrand: string, email: string, firstname: string, lastname: string, displayName: string, reference: string, completeDate: string, comment: string, status: string, buOfSolu: string, emailSo: string, evaluatorsBu, emailgbldla){
  //     return this.httpClient.get('/api/completed_chap_subchap_SaaS?formName='+tabName+'&emailgbldla='+emailgbldla+'&email='+email+'&firstnameVendor='+firstname+'&lastnameVendor='+lastname+'&displayName='+displayName+'&bu='+buOfSolu+'&solutionName='+solName+'&rfpName='+rfpName+'&editorBrand='+editorBrand+'&chapter='+reference+'&addedAt='+completeDate+'&comment='+comment+'&status='+status+'&emailSolOwner='+emailSo+'&emailsEvaluatorsBuList='+evaluatorsBu, {responseType:'text'}).pipe(
  //       catchError(this.handleErrorHttp)
  //     );
  // }
  // //IaaS PaaS Apps
  // sendCompleteChapterSubChapter(tabName, solName, editorBrand, email, reference, completeDate, comment, status, buOfSolu, emailSo){
  //     return this.httpClient.get('/api/completed_chap_subchap?formName='+tabName+'&email='+email+'&bu='+buOfSolu+'&solutionName='+solName+'&editorBrand='+editorBrand+'&chapter='+reference+'&addedAt='+completeDate+'&comment='+comment+'&status='+status+'&emailSolOwner='+emailSo+'&type='+tabName.substring(0, 4), {responseType:'text'}).pipe(
  //       catchError(this.handleErrorHttp)
  //     );
  // }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.toastr.error(error.message, 'Error', {
      timeOut: 10000,
      positionClass: 'toast-top-center'
    });
  }

  ////////////////LOGS/////////////////////

  getIdRefLogs() {
    return this.idSolution + '-' + this.tabName + '-' + this.getCurrentDate();
  }

  getCurrentDate() {
    let today = new Date(Date.now());
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    return dd + '-' + mm + '-' + yyyy;

  }

  //Pour addLogConnection
  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms));
  }

  //LOG action: connexion sur form (c'est obligatoirement le 1er log)
  addLogConnection() {
    this.delay(3000).then(any => {
      console.log(this.tabName + this.idSolution + this.getCurrentDate());

      let myRef = firebase
        .database()
        .ref('logs/' + this.tabName + '/' + this.idSolution + '/Connection/' + this.getCurrentDate());

      let idRefLogs = this.getIdRefLogs();
      const varUserConnected = this.authService.currentUserEmail;
      if(document.getElementById('roleUser').innerHTML) {
        var varRoleUserConnected = document.getElementById('roleUser').innerHTML;
      }
      let currentDate = this.getCurrentDate();
      const varUserIdConnected = this.authService.currentUserId;

      myRef.once('value', function(snapshot) {
        //qu'il y ai déjà la date existante ou pas

        console.log('Date ' + snapshot.key);

        //creation du premier logs ou des logs suivants
        //(ce sera toujours une action de connexion en premier)
        myRef.push({
          idRefLogs: idRefLogs,
          userEmail: varUserConnected,
          roleUser: varRoleUserConnected,
          action: 'Connection',
          dateTime: new Date(Date.now()).toString(),
          userId: varUserIdConnected
        });
        console.log('Append OK addLogConnection');

        //Pour ajouter au noeud actuel
        // return firebase.database().ref(
        // 'logs/' + currentForm +
        // '/' + currentIdDemande +
        // '/' + currentDate +
        // '/' + element.key
        // ).update({ status: 'Vu' });
        //});
      });
    });
  }

  addLogSaveForm() {
    //LOG action: save du form effectué manuel et auto
    let myRef = firebase
      .database()
      .ref('logs/' + this.tabName + '/' + this.idSolution + '/SaveForm/' + this.getCurrentDate());

    const varUserSave = this.authService.currentUserEmail;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    myRef.once('value', function(snapshot) {
      console.log('Log existant pour la date: ' + snapshot.key);
      //console.log("HERE " + snapshot.key) le child "8-7-2020" par ex

      //Ajout au log du jour actuel les infos sur le save effectué
      firebase
        .database()
        .ref('logs/' + currentForm + '/' + currentIdSolution + '/SaveForm/' + currentDate)
        .push({
          userEmail: varUserSave,
          action: 'Save form',
          dateTime: new Date(Date.now()).toString(),
          userId: varUserIdConnected
        });
      console.log('Append OK addLogSaveForm');
    });
  }

  addLogSaveAndSend() {
    //LOG action: save du form complet et envoi notif dla
    let myRef = firebase
      .database()
      .ref('logs/' + this.tabName + '/' + this.idSolution + '/SaveCompleteForm/' + this.getCurrentDate());

    const varUserSave = this.authService.currentUserEmail;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    myRef.once('value', function(snapshot) {
      console.log('Log existant pour la date: ' + snapshot.key);
      //console.log("HERE " + snapshot.key) le child "8-7-2020" par ex

      //Ajout au log du jour actuel les infos sur le save effectué
      firebase
        .database()
        .ref('logs/' + currentForm + '/' + currentIdSolution + '/SaveCompleteForm/' + currentDate)
        .push({
          userEmail: varUserSave,
          action: 'Save complete form',
          dateTime: new Date(Date.now()).toString(),
          userId: varUserIdConnected
        });
      console.log('Append OK addLogSaveAndSend');
    });
  }

  addLogUploadFile(name, chap) {
    //LOG action: fichier uploadé
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/File/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'File uploaded',
        dateTime: new Date(Date.now()).toString(),
        fileName: name,
        chapter: chap,
        userId: varUserIdConnected
      });
    console.log('Append OK addLogUploadFile');
  }

  addLogDeleteFile(name, chap) {
    //LOG action: fichier supprimé
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/File/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'File deleted',
        dateTime: new Date(Date.now()).toString(),
        fileName: name,
        chapter: chap,
        userId: varUserIdConnected
      });
    console.log('Append OK addLogDeleteFile');
  }

  addLogCompleteChapterSubChapter(chap, comment, status) {
    //LOG action: completion ajouté pour un chap/sous chap
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/Complete/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'Chapter/sub-chapter completed',
        dateTime: new Date(Date.now()).toString(),
        chapter: chap,
        userId: varUserIdConnected,
        status: status,
        comment: comment || null
      });
    console.log('Append OK addLogCompleteChapterSubChapter');
  }

  addLogSetComment(chap, tagUsr, content) {
    //LOG action: commentaire ajouté
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/Comment/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'Comment added',
        content: content,
        dateTime: new Date(Date.now()).toString(),
        chapter: chap,
        userId: varUserIdConnected,
        taggedUser: tagUsr || null
      });
    console.log('Append OK addLogSetComment');
  }

  addLogEditComment(chap, tagUsr, content) {
    //LOG action: commentaire modifié
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/Comment/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'Comment edited',
        content: content,
        dateTime: new Date(Date.now()).toString(),
        chapter: chap,
        userId: varUserIdConnected,
        taggedUser: tagUsr || null
      });
    console.log('Append OK addLogEditComment');
  }

  addLogDeleteComment(chap, tagUsr, content) {
    //LOG action: commentaire supprimé
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/Comment/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'Comment deleted',
        content: content,
        dateTime: new Date(Date.now()).toString(),
        chapter: chap,
        userId: varUserIdConnected,
        taggedUser: tagUsr || null
      });
    console.log('Append OK addLogDeleteComment');
  }


  addLogHelp() {
    //LOG action: demande d'aide depuis le formulaire need help dans les form
    const varUserConnected = this.authService.currentUserEmail;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/NeedHelp/' + currentDate)
      .push({
        userEmail: varUserConnected,
        action: 'Help requested',
        dateTime: new Date(Date.now()).toString(),
        userId: varUserIdConnected
      });
    console.log('Append OK addLogHelp');
  }

  addLogSaveEval(chap) {
    //LOG action: sauvegarde d'une évaluation d'un sous chapitre ou d'un chapitre
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/Evaluation/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'Evaluation saved',
        dateTime: new Date(Date.now()).toString(),
        chapter: chap,
        userId: varUserIdConnected
      });
    console.log('Append OK addLogSaveEval');
  }

  addLogSaveFinalEval(status, comment) {
    //LOG action: sauvegarde d'une évaluation finale
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/FinalEvaluation/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'Final evaluation saved',
        dateTime: new Date(Date.now()).toString(),
        status: status,
        comment: comment || null,
        userId: varUserIdConnected
      });
    console.log('Append OK addLogSaveFinalEval');
  }

  addLogSaveAcunetix() {
    //LOG action: sauvegarde acunetix
    const varUserConnected = this.authService.currentUserEmail;
    let varRoleUserConnected = document.getElementById('roleUser').innerHTML;

    let currentIdSolution = this.idSolution;
    let currentForm = this.formTabName;
    let currentDate = this.getCurrentDate();
    const varUserIdConnected = this.authService.currentUserId;

    firebase
      .database()
      .ref('logs/' + currentForm + '/' + currentIdSolution + '/Acunetix/' + currentDate)
      .push({
        userEmail: varUserConnected,
        roleUser: varRoleUserConnected,
        action: 'Acunetix saved',
        dateTime: new Date(Date.now()).toString(),
        userId: varUserIdConnected
      });
    console.log('Append OK addLogSaveAcunetix');
  }

  //////////////////END LOGS////////////////

  ////////////////EVALUATION////////////////

  getIdRefEval() {
    return this.idSolution + '-' + this.tabName;
  }

  //GET evaluation
  getEvaluation(idrefeval: string) {
    return (this.currentEvaluation = this.afs.list('evaluations/' + this.tabName + '/' + this.idSolution, ref =>
      ref.orderByChild('idRefEval').equalTo(idrefeval)
    ));
  }

  //GET evaluation (utiliser seulement par summary-solution)
  getEvaluationInTableauEvalsOverviewSol(idSol: string, form: string) {
    return (this.currentEvaluation = this.afs.list('evaluations/' + form + '/' + idSol, ref =>
      ref.orderByChild('idRefEval').equalTo(idSol+'-'+form)
    ));
  }


  pushUploadEval(upload: UploadEval) {
    this.basePath = 'evaluations/files/' + this.tabName + '/' + this.idSolution;

    const storage = getStorage();


    // Upload file and metadata to teh object
    const storageRef = ref(storage, `${this.basePath}/${upload.file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, upload.file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case 'running':
            console.log('Upload is running');
            console.log('Upload is ' + upload.progress + '% done');
            break;
        }
        //add data ti real DB
        upload.idRefEval = this.getIdRefEval();
        upload.solutionName = this.nameSol;
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => { //success // Upload completed successfully, now we can get the download URL

        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { //add others data to real DB
          console.log('File available at', downloadURL);
          upload.url = downloadURL;
          upload.name = upload.file.name; //ok
          upload.userId = this.authService.currentUserId; //ok
          upload.postedBy = this.authService.currentUserEmail; //ok
          upload.postedAt = new Date(Date.now()).toString(); //ok

          this.basePath = 'evaluations/files/' + this.tabName + '/' + this.idSolution;
          this.afs.list(`${this.basePath}/`).push(upload).then( reference => {

            getMetadata(storageRef).then(metadata => {
              console.log('All metadata are:', metadata); //contient tout les acces
              console.log('File type is', metadata.contentType);
              this.contentTypeOfFile = metadata.contentType;
              reference.ref.update({
                url: upload.url,
                name: upload.name,
                userId: upload.userId,
                postedBy: upload.postedBy,
                postedAt: upload.postedAt,
                contentType: this.contentTypeOfFile
              });
            });

          });
        });

          this.toastr.success(upload.file.name, 'File uploaded successfuly', {
            timeOut: 10000,
            closeButton: true,
            positionClass: 'toast-top-center'
          });




      }
    );

        (window as any).resetFormUpload();
      }

  //Lors du clic sur la modale pour l'évalution totale par le ts
  getFilesForEvaluation(idrefeval: string) {
    console.log('dans le getFilesForEvaluation()');
    return (this.uploadsForEval = this.afs.list('evaluations/files/' + this.tabName + '/' + this.idSolution, ref =>
      ref.orderByChild('idRefEval').equalTo(idrefeval)
    ));
  }

  deleteUploadEval(upload: UploadEval) {
    this.deleteFileEvaluationData(upload.$key)
      .then(() => {
        this.deleteFileEvaluationStorage(upload.name);
      })
      .catch(error => console.log(error));
  }

  // Deletes the file details from the realtime db
  private deleteFileEvaluationData(key: string) {
    this.basePath = 'evaluations/files/' + this.tabName + '/' + this.idSolution;
    console.log('KEY: ' + key);
    return this.afs.list(`${this.basePath}/`).remove(key);
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileEvaluationStorage(name: string) {
    const storage = getStorage()

    this.basePath = 'evaluations/files/' + this.tabName + '/' + this.idSolution;

    const storageRef = ref(storage, `${this.basePath}/${name}`);

    // Delete the file from storage
    deleteObject(storageRef).then(() => {
      this.toastr.warning(name, 'File deleted successfuly', {
            timeOut: 10000,
            closeButton: true,
            positionClass: 'toast-top-center'
          });
      console.log('File removed !')
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }

  //Que par contact-form du vendor
  updateStatusEval(currentEvalKey) {
    this.currentEvaluation.update(currentEvalKey, {
      status: 'On hold'
    });
  }

  //SaaS
  sendEvaluationFormCompletedSaaS(idSolution, formName, stringForm, evaluatorEmail, solutionName, rfpName, editorBrand, solOwner, buOfSolution, status, comment, evaluatorsBu, emailgbldla){
    const query = dictToURI({
      emailSolOwner: solOwner,
      idSol: idSolution,
      stringForm: stringForm,
      emailgbldla: emailgbldla,
      emailEvaluator: evaluatorEmail,
      formName,
      solutionName: solutionName,
      rfpName,
      editorBrand,
      bu: buOfSolution,
      status,
      comment,
      emailsEvaluatorsBuList: evaluatorsBu
    });
    return this.httpClient.get('/api/evaluation_form_completed_overview_SaaS?' + query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  //Iaas PaaS Apps
  sendEvaluationFormCompleted(idSolution, formName, stringForm, evaluatorEmail, solutionName, editorBrand, solOwner, buOfSolution, status, comment){
    const query = dictToURI({
      emailSolOwner: solOwner,
      idSol: idSolution,
      stringForm,
      emailEvaluator: evaluatorEmail,
      formName,
      solutionName,
      editorBrand,
      bu: buOfSolution,
      status,
      comment,
      type: formName.substring(0, 4)
    });
    return this.httpClient.get('/api/evaluation_form_completed_overview?'+ query, {responseType:'text'}).pipe(
      catchError(this.handleErrorHttp)
    );
  }

  public handleErrorHttp(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }

  ////////////////////CHAP////////////////////

  //SaaS
  sendOnHoldChapter(tabName, stringForm, idSol, solName, rfpName, editorBrand, emailEvaluator, fullNameEvaluator, chapter, date, comment, status, buOfSolu, emailSo, list, emailgbldla){
      const query = dictToURI({
        formName: tabName,
        stringForm,
        idSol,
        solutionName: solName,
        emailgbldla,
        emailEvaluator,
        fullNameEvaluator,
        bu: buOfSolu,
        rfpName,
        editorBrand,
        chapter: this.translateService.instant(chapter),
        postedAt: date,
        comment,
        status,
        emailSo,
        emailsList: list
      });
      return this.httpClient.get('/api/chapter_on_hold?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttp)
      );
  }

  //Cas ou il y'a déjà une évaluation

  //DESIGN
  updateEvaluationChap1DA(values, currentEvalKey, currentChap, currentReservationKey) {

    //MAJ noeud evaluations
    this.currentEvaluation.update(currentEvalKey, {
      DA_1_RADIO: values.DA_1_RADIO || null,
      DA_1_RADIO_comment: values.DA_1_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_1_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_1_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null //OK
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_1_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

      //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_1_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_1', new Date(Date.now()).toString(), values.DA_1_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_1', new Date(Date.now()).toString(), values.DA_1_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_1', new Date(Date.now()).toString(), values.DA_1_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }


  }

  updateEvaluationChap2DA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      DA_2_RADIO: values.DA_2_RADIO || null,
      DA_2_RADIO_comment: values.DA_2_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_2_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_2_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_2_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

      //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_2_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_2', new Date(Date.now()).toString(), values.DA_2_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_2', new Date(Date.now()).toString(), values.DA_2_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_2', new Date(Date.now()).toString(), values.DA_2_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap3DA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      DA_3_RADIO: values.DA_3_RADIO || null,
      DA_3_RADIO_comment: values.DA_3_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_3_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_3_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_3_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_3_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_3', new Date(Date.now()).toString(), values.DA_3_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_3', new Date(Date.now()).toString(), values.DA_3_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_3', new Date(Date.now()).toString(), values.DA_3_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }
  }

  updateEvaluationChap4DA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      DA_4_RADIO: values.DA_4_RADIO || null,
      DA_4_RADIO_comment: values.DA_4_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_4_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_4_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_4_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_4_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_4', new Date(Date.now()).toString(), values.DA_4_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_4', new Date(Date.now()).toString(), values.DA_4_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_4', new Date(Date.now()).toString(), values.DA_4_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap5DA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      DA_5_RADIO: values.DA_5_RADIO || null,
      DA_5_RADIO_comment: values.DA_5_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_5_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_5_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_5_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_5_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_5', new Date(Date.now()).toString(), values.DA_5_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_5', new Date(Date.now()).toString(), values.DA_5_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_5', new Date(Date.now()).toString(), values.DA_5_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap6DA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      DA_6_RADIO: values.DA_6_RADIO || null,
      DA_6_RADIO_comment: values.DA_6_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_6_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_6_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_6_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_6_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_6', new Date(Date.now()).toString(), values.DA_6_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_6', new Date(Date.now()).toString(), values.DA_6_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_6', new Date(Date.now()).toString(), values.DA_6_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }
  }

  updateEvaluationChap7DA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      DA_7_RADIO: values.DA_7_RADIO || null,
      DA_7_RADIO_comment: values.DA_7_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_7_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_7_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_7_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_7_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_7', new Date(Date.now()).toString(), values.DA_7_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_7', new Date(Date.now()).toString(), values.DA_7_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_7', new Date(Date.now()).toString(), values.DA_7_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap8DA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      DA_8_RADIO: values.DA_8_RADIO || null,
      DA_8_RADIO_comment: values.DA_8_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_8_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_8_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_8_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_8_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_8', new Date(Date.now()).toString(), values.DA_8_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_8', new Date(Date.now()).toString(), values.DA_8_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_8', new Date(Date.now()).toString(), values.DA_8_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap9DA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      DA_9_RADIO: values.DA_9_RADIO || null,
      DA_9_RADIO_comment: values.DA_9_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_9_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_9_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_9_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.DA_9_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_9', new Date(Date.now()).toString(), values.DA_9_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_9', new Date(Date.now()).toString(), values.DA_9_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_9', new Date(Date.now()).toString(), values.DA_9_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' designStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          designStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'designForm' designStatus = 'Vendor is responding'
        //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
        const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
        const data2: DesignForm = {
          designStatus: 'Vendor is responding',
        };
        refFormDa.update(data2);

        //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du design
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Design Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 9; i++) {
                  if (data.child('DA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      designStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'designForm' designStatus = 'DLA is reviewing'
                    //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                    const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                    const data2: DesignForm = {
                     designStatus: 'DLA is reviewing',
                    };
                    refFormDa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  //LEGAL //Seulement les chap 2 3 et 4 sont déclenchés car pas de sous chapitres donc obligé eval chapitre directement
  updateEvaluationChap0LA(values, currentEvalKey, currentChap, currentReservationKey) {
    this.currentEvaluation.update(currentEvalKey, {
      LA_0_RADIO: values.LA_0_RADIO || null,
      LA_0_RADIO_comment: values.LA_0_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_0_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_0_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_0_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.LA_0_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_0', new Date(Date.now()).toString(), values.LA_0_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_0', new Date(Date.now()).toString(), values.LA_0_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_0', new Date(Date.now()).toString(), values.LA_0_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' legalStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          legalStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'legalForm' legalStatus = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusLegalInProgress(this.idSolution);
        const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
        const data2: LegalForm = {
          legalStatus: 'Vendor is responding',
        };
        refFormLa.update(data2);

        //dans 'evaluations/Legal Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du legal
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Legal Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 5; i++) {
                  if (data.child('LA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Legal Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      legalStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusDesignInProgress(this.idSolution);
                    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
                    const data2: LegalForm = {
                     legalStatus: 'DLA is reviewing',
                    };
                    refFormLa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }
  }

  updateEvaluationChap1LA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      LA_1_RADIO: values.LA_1_RADIO || null,
      LA_1_RADIO_comment: values.LA_1_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_1_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_1_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_1_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.LA_1_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_1', new Date(Date.now()).toString(), values.LA_1_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_1', new Date(Date.now()).toString(), values.LA_1_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_1', new Date(Date.now()).toString(), values.LA_1_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' legalStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          legalStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'legalForm' legalStatus = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusLegalInProgress(this.idSolution);
        const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
        const data2: LegalForm = {
          legalStatus: 'Vendor is responding',
        };
        refFormLa.update(data2);

        //dans 'evaluations/Legal Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du legal
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Legal Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 5; i++) {
                  if (data.child('LA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Legal Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      legalStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusDesignInProgress(this.idSolution);
                    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
                    const data2: LegalForm = {
                     legalStatus: 'DLA is reviewing',
                    };
                    refFormLa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap2LA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      LA_2_RADIO: values.LA_2_RADIO || null,
      LA_2_RADIO_comment: values.LA_2_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_2_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_2_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_2_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.LA_2_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_2', new Date(Date.now()).toString(), values.LA_2_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_2', new Date(Date.now()).toString(), values.LA_2_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_2', new Date(Date.now()).toString(), values.LA_2_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' legalStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          legalStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'legalForm' legalStatus = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusLegalInProgress(this.idSolution);
        const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
        const data2: LegalForm = {
          legalStatus: 'Vendor is responding',
        };
        refFormLa.update(data2);

        //dans 'evaluations/Legal Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du legal
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Legal Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 5; i++) {
                  if (data.child('LA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Legal Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      legalStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusDesignInProgress(this.idSolution);
                    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
                    const data2: LegalForm = {
                     legalStatus: 'DLA is reviewing',
                    };
                    refFormLa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap3LA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      LA_3_RADIO: values.LA_3_RADIO || null,
      LA_3_RADIO_comment: values.LA_3_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap); //car pas de sous chap

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_3_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_3_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_3_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.LA_3_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_3', new Date(Date.now()).toString(), values.LA_3_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_3', new Date(Date.now()).toString(), values.LA_3_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_3', new Date(Date.now()).toString(), values.LA_3_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' legalStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          legalStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'legalForm' legalStatus = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusLegalInProgress(this.idSolution);
        const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
        const data2: LegalForm = {
          legalStatus: 'Vendor is responding',
        };
        refFormLa.update(data2);

        //dans 'evaluations/Legal Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du legal
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Legal Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 5; i++) {
                  if (data.child('LA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Legal Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      legalStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusDesignInProgress(this.idSolution);
                    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
                    const data2: LegalForm = {
                     legalStatus: 'DLA is reviewing',
                    };
                    refFormLa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap4LA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      LA_4_RADIO: values.LA_4_RADIO || null,
      LA_4_RADIO_comment: values.LA_4_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_4_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_4_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_4_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    //Si On hold alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
      if(values.LA_4_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_4', new Date(Date.now()).toString(), values.LA_4_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_4', new Date(Date.now()).toString(), values.LA_4_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_4', new Date(Date.now()).toString(), values.LA_4_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' legalStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          legalStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'legalForm' legalStatus = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusLegalInProgress(this.idSolution);
        const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
        const data2: LegalForm = {
          legalStatus: 'Vendor is responding',
        };
        refFormLa.update(data2);

        //dans 'evaluations/Legal Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du legal
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Legal Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 5; i++) {
                  if (data.child('LA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Legal Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      legalStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusDesignInProgress(this.idSolution);
                    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
                    const data2: LegalForm = {
                     legalStatus: 'DLA is reviewing',
                    };
                    refFormLa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap5LA(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      LA_5_RADIO: values.LA_5_RADIO || null,
      LA_5_RADIO_comment: values.LA_5_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_5_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya d��jà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_5_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_5_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

      if(values.LA_5_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_5', new Date(Date.now()).toString(), values.LA_5_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_5', new Date(Date.now()).toString(), values.LA_5_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_5', new Date(Date.now()).toString(), values.LA_5_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' legalStatus = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          legalStatus: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'legalForm' legalStatus = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusLegalInProgress(this.idSolution);
        const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
        const data2: LegalForm = {
          legalStatus: 'Vendor is responding',
        };
        refFormLa.update(data2);

        //dans 'evaluations/Legal Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du legal
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Legal Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 1; i <= 5; i++) {
                  if (data.child('LA_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Legal Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      legalStatus: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' legalStatus = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusDesignInProgress(this.idSolution);
                    const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
                    const data2: LegalForm = {
                     legalStatus: 'DLA is reviewing',
                    };
                    refFormLa.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  //ARTICLE 32 //Tous les chaps sont déclenchés car pas de sous chapitres donc obligé eval chapitre directement
  updateEvaluationChap0AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_0_RADIO: values.AR32_0_RADIO || null,
      AR32_0_RADIO_comment: values.AR32_0_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_0_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_0_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_0_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

      if(values.AR32_0_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_0', new Date(Date.now()).toString(), values.AR32_0_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_0', new Date(Date.now()).toString(), values.AR32_0_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_0', new Date(Date.now()).toString(), values.AR32_0_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }
  }

  updateEvaluationChap1AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_1_RADIO: values.AR32_1_RADIO || null,
      AR32_1_RADIO_comment: values.AR32_1_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_1_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_1_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_1_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    if(values.AR32_1_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_1', new Date(Date.now()).toString(), values.AR32_1_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_1', new Date(Date.now()).toString(), values.AR32_1_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_1', new Date(Date.now()).toString(), values.AR32_1_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap2AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_2_RADIO: values.AR32_2_RADIO || null,
      AR32_2_RADIO_comment: values.AR32_2_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_2_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_2_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_2_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    if(values.AR32_2_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_2', new Date(Date.now()).toString(), values.AR32_2_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_2', new Date(Date.now()).toString(), values.AR32_2_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_2', new Date(Date.now()).toString(), values.AR32_2_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap3AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_3_RADIO: values.AR32_3_RADIO || null,
      AR32_3_RADIO_comment: values.AR32_3_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_3_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_3_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_3_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    if(values.AR32_3_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_3', new Date(Date.now()).toString(), values.AR32_3_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_3', new Date(Date.now()).toString(), values.AR32_3_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_3', new Date(Date.now()).toString(), values.AR32_3_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap4AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_4_RADIO: values.AR32_4_RADIO || null,
      AR32_4_RADIO_comment: values.AR32_4_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_4_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_4_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_4_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    if(values.AR32_4_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_4', new Date(Date.now()).toString(), values.AR32_4_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_4', new Date(Date.now()).toString(), values.AR32_4_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_4', new Date(Date.now()).toString(), values.AR32_4_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap5AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_5_RADIO: values.AR32_5_RADIO || null,
      AR32_5_RADIO_comment: values.AR32_5_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_5_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_5_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_5_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    if(values.AR32_5_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_5', new Date(Date.now()).toString(), values.AR32_5_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_5', new Date(Date.now()).toString(), values.AR32_5_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_5', new Date(Date.now()).toString(), values.AR32_5_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap6AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_6_RADIO: values.AR32_6_RADIO || null,
      AR32_6_RADIO_comment: values.AR32_6_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_6_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_6_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_6_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    if(values.AR32_6_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_6', new Date(Date.now()).toString(), values.AR32_6_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_6', new Date(Date.now()).toString(), values.AR32_6_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_6', new Date(Date.now()).toString(), values.AR32_6_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }

  }

  updateEvaluationChap7AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_7_RADIO: values.AR32_7_RADIO || null,
      AR32_7_RADIO_comment: values.AR32_7_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_7_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_7_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_7_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    if(values.AR32_7_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_7', new Date(Date.now()).toString(), values.AR32_7_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_7', new Date(Date.now()).toString(), values.AR32_7_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_7', new Date(Date.now()).toString(), values.AR32_7_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }
  }

  updateEvaluationChap8AR32(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR32_8_RADIO: values.AR32_8_RADIO || null,
      AR32_8_RADIO_comment: values.AR32_8_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR32_8_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR32_8_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR32_8_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    if(values.AR32_8_RADIO === 'On hold') {

        //1-envoi du mail au SO + gbldla
        console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

        this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_8', new Date(Date.now()).toString(), values.AR32_8_RADIO_comment, 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
          if (data !== "200") {
            console.error("KO mail retarus unsent !")
          } else {
            console.log("OK mail retarus sent !")
            }
          console.log("from api: " + data);
        });

        //2- envoi email aux evaluators BU inclus dans evaluators
        if(this.emailsEvaluatorsBu) {
          for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
            console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_8', new Date(Date.now()).toString(), values.AR32_8_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });
          }
        }

        //3- envoi mail aux vendors inclus dans emailVendorAuthorized
        for(let i = 0; i < this.emailsVendors.length; i++) {
          console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

            this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_8', new Date(Date.now()).toString(), values.AR32_8_RADIO_comment, 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
              if (data !== "200") {
                console.error("KO mail retarus unsent !")
              } else {
                console.log("OK mail retarus sent !")
              }
              console.log("from api: " + data);
            });

          }

        //dans 'solutions' article32Status = 'Vendor is responding'
        //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
        const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
        const data1: Demande = {
          article32Status: 'Vendor is responding'
        };
        demandeRef.update(data1);

        //dans 'article32Form' article32Status = 'Vendor is responding'
        //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
        const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
        const data2: Article32Form = {
          article32Status: 'Vendor is responding',
        };
        refFormAR32.update(data2);

        //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
        //MAJ status du article 32
        this.currentEvaluation.update(currentEvalKey, {
          status: 'Vendor is responding',
        });

      } else { //check si au moins un chapitre est on hold, si oui on conserve 'Vendor is responding' sinon on passe 'DLA is reviewing'

        //check chaque chapitre
        //dans 'evaluations/Article 32 Form' (realtime db)
        firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => {
              snapshot.forEach(data => {
                for (let i = 0; i <= 8; i++) {
                  if (data.child('AR32_'+i+'_RADIO').toJSON() !== 'On hold') { //Si aucun 'On hold'

                    //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                    this.currentEvaluation.update(currentEvalKey, {
                      status: 'DLA is reviewing',
                    });

                    //dans 'solutions' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                    const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                    const data1: Demande = {
                      article32Status: 'DLA is reviewing'
                    };
                    demandeRef.update(data1);

                    //dans 'legalForm' article32Status = 'DLA is reviewing'
                    //this.homeUserService.editLegalFormStatusArticle32InProgress(this.idSolution);
                    const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                    const data2: Article32Form = {
                     article32Status: 'DLA is reviewing',
                    };
                    refFormAR32.update(data2);

                  } //Sinon le status reste à Vendor is responding
                }
              })
            })
       }
  }

  //ARTICLE 28
  //SCC1
  updateEvaluationChapSCC1AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC1_RADIO: values.AR28_SCC1_RADIO || null,
      AR28_SCC1_RADIO_comment: values.AR28_SCC1_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC1_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC1_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC1_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC1 ANNEX1
  updateEvaluationChapSCC1ANNEX1AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC1_ANNEX_1_RADIO: values.AR28_SCC1_ANNEX_1_RADIO || null,
      AR28_SCC1_ANNEX_1_RADIO_comment: values.AR28_SCC1_ANNEX_1_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC1_ANNEX_1_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC1_ANNEX_1_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC1_ANNEX_1_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC1 ANNEX2
  updateEvaluationChapSCC1ANNEX2AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC1_ANNEX_2_RADIO: values.AR28_SCC1_ANNEX_2_RADIO || null,
      AR28_SCC1_ANNEX_2_RADIO_comment: values.AR28_SCC1_ANNEX_2_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC1_ANNEX_2_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC1_ANNEX_2_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC1_ANNEX_2_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC1 ANNEX3
  updateEvaluationChapSCC1ANNEX3AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC1_ANNEX_3_RADIO: values.AR28_SCC1_ANNEX_3_RADIO || null,
      AR28_SCC1_ANNEX_3_RADIO_comment: values.AR28_SCC1_ANNEX_3_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC1_ANNEX_3_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC1_ANNEX_3_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC1_ANNEX_3_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC1 ANNEX4
  updateEvaluationChapSCC1ANNEX4AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC1_ANNEX_4_RADIO: values.AR28_SCC1_ANNEX_4_RADIO || null,
      AR28_SCC1_ANNEX_4_RADIO_comment: values.AR28_SCC1_ANNEX_4_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC1_ANNEX_4_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC1_ANNEX_4_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC1_ANNEX_4_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC1 ANNEX5
  updateEvaluationChapSCC1ANNEX5AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC1_ANNEX_5_RADIO: values.AR28_SCC1_ANNEX_5_RADIO || null,
      AR28_SCC1_ANNEX_5_RADIO_comment: values.AR28_SCC1_ANNEX_5_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC1_ANNEX_5_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC1_ANNEX_5_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC1_ANNEX_5_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC2
  updateEvaluationChapSCC2AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC2_RADIO: values.AR28_SCC2_RADIO || null,
      AR28_SCC2_RADIO_comment: values.AR28_SCC2_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC2_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC2_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC2_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC2 ANNEX1
  updateEvaluationChapSCC2ANNEX1AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC2_ANNEX_1_RADIO: values.AR28_SCC2_ANNEX_1_RADIO || null,
      AR28_SCC2_ANNEX_1_RADIO_comment: values.AR28_SCC2_ANNEX_1_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC2_ANNEX_1_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC2_ANNEX_1_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC2_ANNEX_1_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC2 ANNEX2
  updateEvaluationChapSCC2ANNEX2AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC2_ANNEX_2_RADIO: values.AR28_SCC2_ANNEX_2_RADIO || null,
      AR28_SCC2_ANNEX_2_RADIO_comment: values.AR28_SCC2_ANNEX_2_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC2_ANNEX_2_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC2_ANNEX_2_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC2_ANNEX_2_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCC2 ANNEX3
  updateEvaluationChapSCC2ANNEX3AR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCC2_ANNEX_3_RADIO: values.AR28_SCC2_ANNEX_3_RADIO || null,
      AR28_SCC2_ANNEX_3_RADIO_comment: values.AR28_SCC2_ANNEX_3_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCC2_ANNEX_3_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCC2_ANNEX_3_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCC2_ANNEX_3_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCHERMS II US
  updateEvaluationChapSCHREMSUSAR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCHREMS_US_RADIO: values.AR28_SCHREMS_US_RADIO || null,
      AR28_SCHREMS_US_RADIO_comment: values.AR28_SCHREMS_US_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCHREMS_US_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCHREMS_US_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCHREMS_US_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCHERMS II UK
  updateEvaluationChapSCHREMSUKAR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCHREMS_UK_RADIO: values.AR28_SCHREMS_UK_RADIO || null,
      AR28_SCHREMS_UK_RADIO_comment: values.AR28_SCHREMS_UK_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCHREMS_UK_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCHREMS_UK_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCHREMS_UK_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCHERMS II INDIA
  updateEvaluationChapSCHREMSINDIAAR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCHREMS_INDIA_RADIO: values.AR28_SCHREMS_INDIA_RADIO || null,
      AR28_SCHREMS_INDIA_RADIO_comment: values.AR28_SCHREMS_INDIA_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCHREMS_INDIA_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCHREMS_INDIA_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCHREMS_INDIA_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //SCHERMS II GENERIC
  updateEvaluationChapSCHREMSGENERICAR28(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      AR28_SCHREMS_GENERIC_RADIO: values.AR28_SCHREMS_GENERIC_RADIO || null,
      AR28_SCHREMS_GENERIC_RADIO_comment: values.AR28_SCHREMS_GENERIC_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_SCHREMS_GENERIC_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_SCHREMS_GENERIC_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_SCHREMS_GENERIC_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //IAAS 1
  updateEvaluationChap1IAAS(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      IAAS_1_RADIO: values.IAAS_1_RADIO || null,
      IAAS_1_RADIO_comment: values.IAAS_1_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.IAAS_1_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.IAAS_1_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.IAAS_1_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //IAAS 2
  updateEvaluationChap2IAAS(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      IAAS_2_RADIO: values.IAAS_2_RADIO || null,
      IAAS_2_RADIO_comment: values.IAAS_2_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.IAAS_2_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.IAAS_2_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.IAAS_2_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //IAAS 3
  updateEvaluationChap3IAAS(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      IAAS_3_RADIO: values.IAAS_3_RADIO || null,
      IAAS_3_RADIO_comment: values.IAAS_3_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.IAAS_3_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.IAAS_3_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.IAAS_3_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  //IAAS 4
  updateEvaluationChap4IAAS(values, currentEvalKey, currentChap, currentReservationKey) {

    this.currentEvaluation.update(currentEvalKey, {
      IAAS_4_RADIO: values.IAAS_4_RADIO || null,
      IAAS_4_RADIO_comment: values.IAAS_4_RADIO_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName,

    });

    this.addLogSaveEval(currentChap);

    //MAJ noeud reservations //Check si il y'a une reserve
      if(values.IAAS_4_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.IAAS_4_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChap}`;
            this.afs.list(`${this.path}/`).push({
              content: values.IAAS_4_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              reference: currentChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

  }

  ////////////////END CHAP//////////////////////////////

  ////////////////////SOUS CHAP////////////////////
  //Cas ou il y'a déjà une évaluation

  updateEvaluationSubChap(values, currentEvalKey, currentSubChap, currentReservationKey) {

  this.addLogSaveEval(currentSubChap);

///////////////////////////////////DESIGN//////////////////////////////////////////

    if (currentSubChap === 'DA_11') {

      //MAJ noeud evaluations
      this.currentEvaluation.update(currentEvalKey, {
        DA_11_RADIO: values.DA_11_RADIO || null,
        DA_11_RADIO_comment: values.DA_11_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,
      });


      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_11_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_11_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_11_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_12') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_12_RADIO: values.DA_12_RADIO || null,
        DA_12_RADIO_comment: values.DA_12_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,
      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_12_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_12_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_12_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_13') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_13_RADIO: values.DA_13_RADIO || null,
        DA_13_RADIO_comment: values.DA_13_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,
      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_13_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_13_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_13_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_14') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_14_RADIO: values.DA_14_RADIO || null,
        DA_14_RADIO_comment: values.DA_14_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,
      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_14_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_14_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_14_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_150') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_150_RADIO: values.DA_150_RADIO || null,
        DA_150_RADIO_comment: values.DA_150_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,
      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_150_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_150_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_150_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_15') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_15_RADIO: values.DA_15_RADIO || null,
        DA_15_RADIO_comment: values.DA_15_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,
      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_15_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_15_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_15_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_16') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_16_RADIO: values.DA_16_RADIO || null,
        DA_16_RADIO_comment: values.DA_16_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,
      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_16_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_16_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_16_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation
    }

    if (currentSubChap === 'DA_21') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_21_RADIO: values.DA_21_RADIO || null,
        DA_21_RADIO_comment: values.DA_21_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_21_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_21_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_21_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_22') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_22_RADIO: values.DA_22_RADIO || null,
        DA_22_RADIO_comment: values.DA_22_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_22_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_22_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_22_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }


    if (currentSubChap === 'DA_23') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_23_RADIO: values.DA_23_RADIO || null,
        DA_23_RADIO_comment: values.DA_23_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_23_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_23_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_23_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_24') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_24_RADIO: values.DA_24_RADIO || null,
        DA_24_RADIO_comment: values.DA_24_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_24_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_24_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_24_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_25') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_25_RADIO: values.DA_25_RADIO || null,
        DA_25_RADIO_comment: values.DA_25_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_25_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_25_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_25_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_31') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_31_RADIO: values.DA_31_RADIO || null,
        DA_31_RADIO_comment: values.DA_31_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_31_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_31_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_31_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_32') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_32_RADIO: values.DA_32_RADIO || null,
        DA_32_RADIO_comment: values.DA_32_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_32_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_32_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_32_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_41') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_41_RADIO: values.DA_41_RADIO || null,
        DA_41_RADIO_comment: values.DA_41_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_41_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_41_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_41_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_410') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_410_RADIO: values.DA_410_RADIO || null,
        DA_410_RADIO_comment: values.DA_410_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_410_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_410_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_410_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_42') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_42_RADIO: values.DA_42_RADIO || null,
        DA_42_RADIO_comment: values.DA_42_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_42_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_42_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_42_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_43') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_43_RADIO: values.DA_43_RADIO || null,
        DA_43_RADIO_comment: values.DA_43_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_43_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_43_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_43_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_44') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_44_RADIO: values.DA_44_RADIO || null,
        DA_44_RADIO_comment: values.DA_44_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_44_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_44_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_44_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_45') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_45_RADIO: values.DA_45_RADIO || null,
        DA_45_RADIO_comment: values.DA_45_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_45_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_45_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_45_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_46') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_46_RADIO: values.DA_46_RADIO || null,
        DA_46_RADIO_comment: values.DA_46_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_46_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_46_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_46_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_47') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_47_RADIO: values.DA_47_RADIO || null,
        DA_47_RADIO_comment: values.DA_47_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_47_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_47_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_47_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }


    if (currentSubChap === 'DA_51') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_51_RADIO: values.DA_51_RADIO || null,
        DA_51_RADIO_comment: values.DA_51_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_51_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_51_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_51_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_52') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_52_RADIO: values.DA_52_RADIO || null,
        DA_52_RADIO_comment: values.DA_52_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_52_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_52_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_52_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_53') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_53_RADIO: values.DA_53_RADIO || null,
        DA_53_RADIO_comment: values.DA_53_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_53_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_53_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_53_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_54') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_54_RADIO: values.DA_54_RADIO || null,
        DA_54_RADIO_comment: values.DA_54_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_54_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_54_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_54_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_61') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_61_RADIO: values.DA_61_RADIO || null,
        DA_61_RADIO_comment: values.DA_61_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_61_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_61_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_61_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_62') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_62_RADIO: values.DA_62_RADIO || null,
        DA_62_RADIO_comment: values.DA_62_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_62_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_62_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_62_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_63') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_63_RADIO: values.DA_63_RADIO || null,
        DA_63_RADIO_comment: values.DA_63_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_63_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_63_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_63_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_64') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_64_RADIO: values.DA_64_RADIO || null,
        DA_64_RADIO_comment: values.DA_64_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_64_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_64_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_64_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_65') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_65_RADIO: values.DA_65_RADIO || null,
        DA_65_RADIO_comment: values.DA_65_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_65_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_65_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_65_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_71') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_71_RADIO: values.DA_71_RADIO || null,
        DA_71_RADIO_comment: values.DA_71_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_71_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_71_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_71_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'DA_72') {

      this.currentEvaluation.update(currentEvalKey, {
        DA_72_RADIO: values.DA_72_RADIO || null,
        DA_72_RADIO_comment: values.DA_72_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.DA_72_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.DA_72_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.DA_72_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }
///////////////////////END DESIGN//////////////////////


/////////////////////////LEGAL///////////////////////
    if (currentSubChap === 'LA_10') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_10_RADIO: values.LA_10_RADIO || null,
        LA_10_RADIO_comment: values.LA_10_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_10_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_10_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_10_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_11') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_11_RADIO: values.LA_11_RADIO || null,
        LA_11_RADIO_comment: values.LA_11_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_11_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_11_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_11_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_111') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_111_RADIO: values.LA_111_RADIO || null,
        LA_111_RADIO_comment: values.LA_111_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_111_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_111_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_111_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_112') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_112_RADIO: values.LA_112_RADIO || null,
        LA_112_RADIO_comment: values.LA_112_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_112_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_112_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_112_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_113') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_113_RADIO: values.LA_113_RADIO || null,
        LA_113_RADIO_comment: values.LA_113_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_113_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_113_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_113_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_114') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_114_RADIO: values.LA_114_RADIO || null,
        LA_114_RADIO_comment: values.LA_114_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_114_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_114_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_114_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_115') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_115_RADIO: values.LA_115_RADIO || null,
        LA_115_RADIO_comment: values.LA_115_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_115_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_115_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_115_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_116') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_116_RADIO: values.LA_116_RADIO || null,
        LA_116_RADIO_comment: values.LA_116_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_116_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_116_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_116_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_12') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_12_RADIO: values.LA_12_RADIO || null,
        LA_12_RADIO_comment: values.LA_12_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_12_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_12_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_12_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    // if (currentSubChap === 'LA_121') {

    //   this.currentEvaluation.update(currentEvalKey, {
    //     LA_121_RADIO: values.LA_121_RADIO || null,
    //     LA_121_RADIO_comment: values.LA_121_RADIO_comment || null,
    //     postedAt: new Date(Date.now()).toString(),
    //     postedBy: this.authService.currentUserEmail,
    //     userId: this.authService.currentUserId,
    //     solutionName: this.solName,

    //   });

    //   //MAJ noeud reservations //Check si il y'a une reserve
    //   if(values.LA_121_RADIO === 'Recommended with reservations') {

    //     if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
    //       console.log("-------------UPDATE RESERVATION-----------")

    //       this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
    //       this.afs.list(`${this.path}/`).update(currentReservationKey, {
    //         content: values.LA_121_RADIO_comment || null,
    //         postedAt: new Date(Date.now()).toString(),
    //         postedBy: this.authService.currentUserEmail,
    //         userId: this.authService.currentUserId,
    //         userRole: document.getElementById('roleUser').textContent,
    //         solutionName: this.solName,
    //         fixed: false,
    //         deadlineDate: values.deadlineDate || null
    //       });

    //     } else { //SI currentReservationKey est null
    //       console.log("-------------ADD RESERVATION-----------")

    //         this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
    //         this.afs.list(`${this.path}/`).push({
    //           content: values.LA_121_RADIO_comment || null,
    //           idRefReservation: this.getIdRefReservation(currentSubChap),
    //           formIs: this.tabName,
    //           idSolution: this.idSol,
    //           solutionName: this.solName,
    //           postedAt: new Date(Date.now()).toString(),
    //           postedBy: this.authService.currentUserEmail,
    //           userId: this.authService.currentUserId,
    //           userRole: document.getElementById('roleUser').textContent,
    //           reference: currentSubChap,
    //           fixed: false,
    //         }).then(data => {
    //             this.afs.list(`${this.path}/`).update(data.key, {
    //             deadlineDate: values.deadlineDate || null
    //             });
    //           });

    //     }

    //   }//END check reservation

    // }

     if (currentSubChap === 'LA_1212') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_1212_RADIO: values.LA_1212_RADIO || null,
        LA_1212_RADIO_comment: values.LA_1212_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_1212_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_1212_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_1212_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_122') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_122_RADIO: values.LA_122_RADIO || null,
        LA_122_RADIO_comment: values.LA_122_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_122_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_122_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_122_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_123') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_123_RADIO: values.LA_123_RADIO || null,
        LA_123_RADIO_comment: values.LA_123_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_123_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_123_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_123_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_124') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_124_RADIO: values.LA_124_RADIO || null,
        LA_124_RADIO_comment: values.LA_124_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_124_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_124_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_124_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_125') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_125_RADIO: values.LA_125_RADIO || null,
        LA_125_RADIO_comment: values.LA_125_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_125_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_125_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_125_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_126') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_126_RADIO: values.LA_126_RADIO || null,
        LA_126_RADIO_comment: values.LA_126_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_126_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_126_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_126_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_127') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_127_RADIO: values.LA_127_RADIO || null,
        LA_127_RADIO_comment: values.LA_127_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_127_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_127_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_127_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_128') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_128_RADIO: values.LA_128_RADIO || null,
        LA_128_RADIO_comment: values.LA_128_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_128_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_128_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_128_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_129') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_129_RADIO: values.LA_129_RADIO || null,
        LA_129_RADIO_comment: values.LA_129_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_129_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_129_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_129_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_1210') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_1210_RADIO: values.LA_1210_RADIO || null,
        LA_1210_RADIO_comment: values.LA_1210_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_1210_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_1210_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_1210_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_1211') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_1211_RADIO: values.LA_1211_RADIO || null,
        LA_1211_RADIO_comment: values.LA_1211_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_1211_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_1211_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_1211_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_13') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_13_RADIO: values.LA_13_RADIO || null,
        LA_13_RADIO_comment: values.LA_13_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_13_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_13_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_13_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_131') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_131_RADIO: values.LA_131_RADIO || null,
        LA_131_RADIO_comment: values.LA_131_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_131_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_131_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_131_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_132') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_132_RADIO: values.LA_132_RADIO || null,
        LA_132_RADIO_comment: values.LA_132_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_132_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_132_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_132_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_14') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_14_RADIO: values.LA_14_RADIO || null,
        LA_14_RADIO_comment: values.LA_14_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_14_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_14_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_14_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_141') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_141_RADIO: values.LA_141_RADIO || null,
        LA_141_RADIO_comment: values.LA_141_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_141_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_141_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_141_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_142') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_142_RADIO: values.LA_142_RADIO || null,
        LA_142_RADIO_comment: values.LA_142_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_142_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_142_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_142_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_15') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_15_RADIO: values.LA_15_RADIO || null,
        LA_15_RADIO_comment: values.LA_15_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_15_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_15_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_15_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_151') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_151_RADIO: values.LA_151_RADIO || null,
        LA_151_RADIO_comment: values.LA_151_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_151_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_151_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_151_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_152') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_152_RADIO: values.LA_152_RADIO || null,
        LA_152_RADIO_comment: values.LA_152_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_152_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_152_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_152_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_153') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_153_RADIO: values.LA_153_RADIO || null,
        LA_153_RADIO_comment: values.LA_153_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_153_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_153_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_153_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_21') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_21_RADIO: values.LA_21_RADIO || null,
        LA_21_RADIO_comment: values.LA_21_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_21_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_21_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_21_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_22') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_22_RADIO: values.LA_22_RADIO || null,
        LA_22_RADIO_comment: values.LA_22_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_22_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_22_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_22_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_23') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_23_RADIO: values.LA_23_RADIO || null,
        LA_23_RADIO_comment: values.LA_23_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_23_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_23_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_23_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_24') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_24_RADIO: values.LA_24_RADIO || null,
        LA_24_RADIO_comment: values.LA_24_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_24_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_24_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_24_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_25') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_25_RADIO: values.LA_25_RADIO || null,
        LA_25_RADIO_comment: values.LA_25_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_25_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_25_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_25_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_26') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_26_RADIO: values.LA_26_RADIO || null,
        LA_26_RADIO_comment: values.LA_26_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_26_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_26_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_26_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_31') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_31_RADIO: values.LA_31_RADIO || null,
        LA_31_RADIO_comment: values.LA_31_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_31_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_31_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_31_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_32') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_32_RADIO: values.LA_32_RADIO || null,
        LA_32_RADIO_comment: values.LA_32_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_32_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_32_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_32_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_33') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_33_RADIO: values.LA_33_RADIO || null,
        LA_33_RADIO_comment: values.LA_33_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_33_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_33_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_33_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_34') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_34_RADIO: values.LA_34_RADIO || null,
        LA_34_RADIO_comment: values.LA_34_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_34_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_34_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_34_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_41') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_41_RADIO: values.LA_41_RADIO || null,
        LA_41_RADIO_comment: values.LA_41_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_41_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_41_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_41_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_42') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_42_RADIO: values.LA_42_RADIO || null,
        LA_42_RADIO_comment: values.LA_42_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_42_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_42_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_42_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_43') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_43_RADIO: values.LA_43_RADIO || null,
        LA_43_RADIO_comment: values.LA_43_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_43_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_43_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_43_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_51') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_51_RADIO: values.LA_51_RADIO || null,
        LA_51_RADIO_comment: values.LA_51_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_51_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_51_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_51_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'LA_52') {

      this.currentEvaluation.update(currentEvalKey, {
        LA_52_RADIO: values.LA_52_RADIO || null,
        LA_52_RADIO_comment: values.LA_52_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.LA_52_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.LA_52_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.LA_52_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }
  ////////////////////////END LEGAL//////////////////////

  /////////////////////////ARTICLE 28///////////////////////
    if (currentSubChap === 'AR28_DPA_11') {

      this.currentEvaluation.update(currentEvalKey, {
        AR28_DPA_11_RADIO: values.AR28_DPA_11_RADIO || null,
        AR28_DPA_11_RADIO_comment: values.AR28_DPA_11_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_DPA_11_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_DPA_11_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_DPA_11_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'AR28_DPA_12') {

      this.currentEvaluation.update(currentEvalKey, {
        AR28_DPA_12_RADIO: values.AR28_DPA_12_RADIO || null,
        AR28_DPA_12_RADIO_comment: values.AR28_DPA_12_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_DPA_12_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_DPA_12_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_DPA_12_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'AR28_DPA_21') {

      this.currentEvaluation.update(currentEvalKey, {
        AR28_DPA_21_RADIO: values.AR28_DPA_21_RADIO || null,
        AR28_DPA_21_RADIO_comment: values.AR28_DPA_21_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_DPA_21_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_DPA_21_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_DPA_21_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'AR28_DPA_22') {

      this.currentEvaluation.update(currentEvalKey, {
        AR28_DPA_22_RADIO: values.AR28_DPA_22_RADIO || null,
        AR28_DPA_22_RADIO_comment: values.AR28_DPA_22_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_DPA_22_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_DPA_22_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_DPA_22_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'AR28_DPA_23') {

      this.currentEvaluation.update(currentEvalKey, {
        AR28_DPA_23_RADIO: values.AR28_DPA_23_RADIO || null,
        AR28_DPA_23_RADIO_comment: values.AR28_DPA_23_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_DPA_23_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_DPA_23_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_DPA_23_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }

    if (currentSubChap === 'AR28_DPA_24') {

      this.currentEvaluation.update(currentEvalKey, {
        AR28_DPA_24_RADIO: values.AR28_DPA_24_RADIO || null,
        AR28_DPA_24_RADIO_comment: values.AR28_DPA_24_RADIO_comment || null,
        postedAt: new Date(Date.now()).toString(),
        postedBy: this.authService.currentUserEmail,
        userId: this.authService.currentUserId,
        solutionName: this.solName,

      });

      //MAJ noeud reservations //Check si il y'a une reserve
      if(values.AR28_DPA_24_RADIO === 'Recommended with reservations') {

        if(currentReservationKey) { //Si currentReservationKey existe alors il ya déjà une reserve pour ce sous chap
          console.log("-------------UPDATE RESERVATION-----------")

          this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
          this.afs.list(`${this.path}/`).update(currentReservationKey, {
            content: values.AR28_DPA_24_RADIO_comment || null,
            postedAt: new Date(Date.now()).toString(),
            postedBy: this.authService.currentUserEmail,
            userId: this.authService.currentUserId,
            userRole: document.getElementById('roleUser').textContent,
            solutionName: this.solName,
            fixed: false,
            deadlineDate: values.deadlineDate || null
          });

        } else { //SI currentReservationKey est null
          console.log("-------------ADD RESERVATION-----------")

            this.path = 'reservations/' + this.tabName + '/' + this.idSolution + '/' + currentSubChap;
            this.afs.list(`${this.path}/`).push({
              content: values.AR28_DPA_24_RADIO_comment || null,
              idRefReservation: this.getIdRefReservation(currentSubChap),
              formIs: this.tabName,
              idSolution: this.idSol,
              solutionName: this.solName,
              postedAt: new Date(Date.now()).toString(),
              postedBy: this.authService.currentUserEmail,
              userId: this.authService.currentUserId,
              userRole: document.getElementById('roleUser').textContent,
              reference: currentSubChap,
              fixed: false,
            }).then(data => {
                this.afs.list(`${this.path}/`).update(data.key, {
                deadlineDate: values.deadlineDate || null
                });
              });

        }

      }//END check reservation

    }
    /////////////////////////END ARTICLE 28/////////////////////////

  }

  //Cas aucune evaluation existante
  //on Initialise donc dans la BD le noeud qui va contenir toutes les evals de la solution en fonction du form design ou legal
  //par la suite on ne passera que par updateEvaluationSubChap et updateEvaluationChap
  //à la premiere evaluation on passe le statut du form sous 'solutions' et sous 'designForm' ou 'legalForm' ou 'article32Form' ou 'article28Form' à 'DLA is reviewing'
  insertEvaluation(values, currentChapOrSubChap) {

    this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
    this.afs.list(`${this.path}/`).push({
      idSolution: this.idSol,
      formIs: this.tabName,
      postedAt: new Date(Date.now()).toString(),
      userId: this.authService.currentUserId,
      postedBy: this.authService.currentUserEmail,
      solutionName: this.solName,
      idRefEval: this.getIdRefEval(),

      //DESIGN
      //CHAP
      DA_1_RADIO: values.DA_1_RADIO || null,
      DA_1_RADIO_comment: values.DA_1_RADIO_comment || null,

      DA_2_RADIO: values.DA_2_RADIO || null,
      DA_2_RADIO_comment: values.DA_2_RADIO_comment || null,

      DA_3_RADIO: values.DA_3_RADIO || null,
      DA_3_RADIO_comment: values.DA_3_RADIO_comment || null,

      DA_4_RADIO: values.DA_4_RADIO || null,
      DA_4_RADIO_comment: values.DA_4_RADIO_comment || null,

      DA_5_RADIO: values.DA_5_RADIO || null,
      DA_5_RADIO_comment: values.DA_5_RADIO_comment || null,

      DA_6_RADIO: values.DA_6_RADIO || null,
      DA_6_RADIO_comment: values.DA_6_RADIO_comment || null,

      DA_7_RADIO: values.DA_7_RADIO || null,
      DA_7_RADIO_comment: values.DA_7_RADIO_comment || null,

      DA_8_RADIO: values.DA_8_RADIO || null,
      DA_8_RADIO_comment: values.DA_8_RADIO_comment || null,

      DA_9_RADIO: values.DA_9_RADIO || null,
      DA_9_RADIO_comment: values.DA_9_RADIO_comment || null,

      //SOUS CHAP
      DA_11_RADIO: values.DA_11_RADIO || null,
      DA_11_RADIO_comment: values.DA_11_RADIO_comment || null,

      DA_12_RADIO: values.DA_12_RADIO || null,
      DA_12_RADIO_comment: values.DA_12_RADIO_comment || null,

      DA_13_RADIO: values.DA_13_RADIO || null,
      DA_13_RADIO_comment: values.DA_13_RADIO_comment || null,

      DA_14_RADIO: values.DA_14_RADIO || null,
      DA_14_RADIO_comment: values.DA_14_RADIO_comment || null,

      DA_150_RADIO: values.DA_150_RADIO || null,
      DA_150_RADIO_comment: values.DA_150_RADIO_comment || null,

      DA_15_RADIO: values.DA_15_RADIO || null,
      DA_15_RADIO_comment: values.DA_15_RADIO_comment || null,

      DA_16_RADIO: values.DA_16_RADIO || null,
      DA_16_RADIO_comment: values.DA_16_RADIO_comment || null,

      DA_21_RADIO: values.DA_21_RADIO || null,
      DA_21_RADIO_comment: values.DA_21_RADIO_comment || null,

      DA_22_RADIO: values.DA_22_RADIO || null,
      DA_22_RADIO_comment: values.DA_22_RADIO_comment || null,

      DA_23_RADIO: values.DA_23_RADIO || null,
      DA_23_RADIO_comment: values.DA_23_RADIO_comment || null,

      DA_24_RADIO: values.DA_24_RADIO || null,
      DA_24_RADIO_comment: values.DA_24_RADIO_comment || null,

      DA_25_RADIO: values.DA_25_RADIO || null,
      DA_25_RADIO_comment: values.DA_25_RADIO_comment || null,

      DA_31_RADIO: values.DA_31_RADIO || null,
      DA_31_RADIO_comment: values.DA_31_RADIO_comment || null,

      DA_32_RADIO: values.DA_32_RADIO || null,
      DA_32_RADIO_comment: values.DA_32_RADIO_comment || null,

      DA_41_RADIO: values.DA_41_RADIO || null,
      DA_41_RADIO_comment: values.DA_41_RADIO_comment || null,

      DA_42_RADIO: values.DA_42_RADIO || null,
      DA_42_RADIO_comment: values.DA_42_RADIO_comment || null,

      DA_43_RADIO: values.DA_43_RADIO || null,
      DA_43_RADIO_comment: values.DA_43_RADIO_comment || null,

      DA_44_RADIO: values.DA_44_RADIO || null,
      DA_44_RADIO_comment: values.DA_44_RADIO_comment || null,

      DA_45_RADIO: values.DA_45_RADIO || null,
      DA_45_RADIO_comment: values.DA_45_RADIO_comment || null,

      DA_46_RADIO: values.DA_46_RADIO || null,
      DA_46_RADIO_comment: values.DA_46_RADIO_comment || null,

      DA_51_RADIO: values.DA_51_RADIO || null,
      DA_51_RADIO_comment: values.DA_51_RADIO_comment || null,

      DA_52_RADIO: values.DA_52_RADIO || null,
      DA_52_RADIO_comment: values.DA_52_RADIO_comment || null,

      DA_53_RADIO: values.DA_53_RADIO || null,
      DA_53_RADIO_comment: values.DA_53_RADIO_comment || null,

      DA_54_RADIO: values.DA_54_RADIO || null,
      DA_54_RADIO_comment: values.DA_54_RADIO_comment || null,

      DA_61_RADIO: values.DA_61_RADIO || null,
      DA_61_RADIO_comment: values.DA_61_RADIO_comment || null,

      DA_62_RADIO: values.DA_62_RADIO || null,
      DA_62_RADIO_comment: values.DA_62_RADIO_comment || null,

      DA_63_RADIO: values.DA_63_RADIO || null,
      DA_63_RADIO_comment: values.DA_63_RADIO_comment || null,

      DA_64_RADIO: values.DA_64_RADIO || null,
      DA_64_RADIO_comment: values.DA_64_RADIO_comment || null,

      DA_65_RADIO: values.DA_65_RADIO || null,
      DA_65_RADIO_comment: values.DA_65_RADIO_comment || null,

      DA_71_RADIO: values.DA_71_RADIO || null,
      DA_71_RADIO_comment: values.DA_71_RADIO_comment || null,

      DA_72_RADIO: values.DA_72_RADIO || null,
      DA_72_RADIO_comment: values.DA_72_RADIO_comment || null,

      DA_81_RADIO: values.DA_81_RADIO || null,
      DA_81_RADIO_comment: values.DA_81_RADIO_comment || null,

      DA_82_RADIO: values.DA_82_RADIO || null,
      DA_82_RADIO_comment: values.DA_82_RADIO_comment || null,

      //LEGAL
      //CHAP
      LA_0_RADIO: values.LA_0_RADIO || null,
      LA_0_RADIO_comment: values.LA_0_RADIO_comment || null,

      LA_1_RADIO: values.LA_1_RADIO || null,
      LA_1_RADIO_comment: values.LA_1_RADIO_comment || null,

      LA_2_RADIO: values.LA_2_RADIO || null,
      LA_2_RADIO_comment: values.LA_2_RADIO_comment || null,

      LA_3_RADIO: values.LA_3_RADIO || null,
      LA_3_RADIO_comment: values.LA_3_RADIO_comment || null,

      LA_4_RADIO: values.LA_4_RADIO || null,
      LA_4_RADIO_comment: values.LA_4_RADIO_comment || null,

      LA_5_RADIO: values.LA_5_RADIO || null,
      LA_5_RADIO_comment: values.LA_5_RADIO_comment || null,

      //SOUS CHAP
      LA_10_RADIO: values.LA_10_RADIO || null,
      LA_10_RADIO_comment: values.LA_10_RADIO_comment || null,

      LA_11_RADIO: values.LA_11_RADIO || null,
      LA_11_RADIO_comment: values.LA_11_RADIO_comment || null,

      LA_111_RADIO: values.LA_111_RADIO || null,
      LA_111_RADIO_comment: values.LA_111_RADIO_comment || null,

      LA_112_RADIO: values.LA_112_RADIO || null,
      LA_112_RADIO_comment: values.LA_112_RADIO_comment || null,

      LA_113_RADIO: values.LA_113_RADIO || null,
      LA_113_RADIO_comment: values.LA_113_RADIO_comment || null,

      LA_114_RADIO: values.LA_114_RADIO || null,
      LA_114_RADIO_comment: values.LA_114_RADIO_comment || null,

      LA_115_RADIO: values.LA_115_RADIO || null,
      LA_115_RADIO_comment: values.LA_115_RADIO_comment || null,

      LA_116_RADIO: values.LA_116_RADIO || null,
      LA_116_RADIO_comment: values.LA_116_RADIO_comment || null,

      LA_12_RADIO: values.LA_12_RADIO || null,
      LA_12_RADIO_comment: values.LA_12_RADIO_comment || null,

      // LA_121_RADIO: values.LA_121_RADIO || null,
      // LA_121_RADIO_comment: values.LA_121_RADIO_comment || null,

      LA_1212_RADIO: values.LA_1212_RADIO || null,
      LA_1212_RADIO_comment: values.LA_1212_RADIO_comment || null,

      LA_122_RADIO: values.LA_122_RADIO || null,
      LA_122_RADIO_comment: values.LA_122_RADIO_comment || null,

      LA_123_RADIO: values.LA_123_RADIO || null,
      LA_123_RADIO_comment: values.LA_123_RADIO_comment || null,

      LA_124_RADIO: values.LA_124_RADIO || null,
      LA_124_RADIO_comment: values.LA_124_RADIO_comment || null,

      LA_125_RADIO: values.LA_125_RADIO || null,
      LA_125_RADIO_comment: values.LA_125_RADIO_comment || null,

      LA_126_RADIO: values.LA_126_RADIO || null,
      LA_126_RADIO_comment: values.LA_126_RADIO_comment || null,

      LA_127_RADIO: values.LA_127_RADIO || null,
      LA_127_RADIO_comment: values.LA_127_RADIO_comment || null,

      LA_128_RADIO: values.LA_128_RADIO || null,
      LA_128_RADIO_comment: values.LA_128_RADIO_comment || null,

      LA_129_RADIO: values.LA_129_RADIO || null,
      LA_129_RADIO_comment: values.LA_129_RADIO_comment || null,

      LA_1210_RADIO: values.LA_1210_RADIO || null,
      LA_1210_RADIO_comment: values.LA_1210_RADIO_comment || null,

      LA_1211_RADIO: values.LA_1211_RADIO || null,
      LA_1211_RADIO_comment: values.LA_1211_RADIO_comment || null,

      LA_13_RADIO: values.LA_13_RADIO || null,
      LA_13_RADIO_comment: values.LA_13_RADIO_comment || null,

      LA_131_RADIO: values.LA_131_RADIO || null,
      LA_131_RADIO_comment: values.LA_131_RADIO_comment || null,

      LA_132_RADIO: values.LA_132_RADIO || null,
      LA_132_RADIO_comment: values.LA_132_RADIO_comment || null,

      LA_14_RADIO: values.LA_14_RADIO || null,
      LA_14_RADIO_comment: values.LA_14_RADIO_comment || null,

      LA_141_RADIO: values.LA_141_RADIO || null,
      LA_141_RADIO_comment: values.LA_141_RADIO_comment || null,

      LA_142_RADIO: values.LA_142_RADIO || null,
      LA_142_RADIO_comment: values.LA_142_RADIO_comment || null,

      LA_15_RADIO: values.LA_15_RADIO || null,
      LA_15_RADIO_comment: values.LA_15_RADIO_comment || null,

      LA_151_RADIO: values.LA_151_RADIO || null,
      LA_151_RADIO_comment: values.LA_151_RADIO_comment || null,

      LA_152_RADIO: values.LA_152_RADIO || null,
      LA_152_RADIO_comment: values.LA_152_RADIO_comment || null,

      LA_153_RADIO: values.LA_153_RADIO || null,
      LA_153_RADIO_comment: values.LA_153_RADIO_comment || null,

      LA_21_RADIO: values.LA_21_RADIO || null,
      LA_21_RADIO_comment: values.LA_21_RADIO_comment || null,

      LA_22_RADIO: values.LA_22_RADIO || null,
      LA_22_RADIO_comment: values.LA_22_RADIO_comment || null,

      LA_23_RADIO: values.LA_23_RADIO || null,
      LA_23_RADIO_comment: values.LA_23_RADIO_comment || null,

      LA_24_RADIO: values.LA_24_RADIO || null,
      LA_24_RADIO_comment: values.LA_24_RADIO_comment || null,

      LA_25_RADIO: values.LA_25_RADIO || null,
      LA_25_RADIO_comment: values.LA_25_RADIO_comment || null,

      LA_26_RADIO: values.LA_26_RADIO || null,
      LA_26_RADIO_comment: values.LA_26_RADIO_comment || null,

      LA_31_RADIO: values.LA_31_RADIO || null,
      LA_31_RADIO_comment: values.LA_31_RADIO_comment || null,

      LA_32_RADIO: values.LA_32_RADIO || null,
      LA_32_RADIO_comment: values.LA_32_RADIO_comment || null,

      LA_33_RADIO: values.LA_33_RADIO || null,
      LA_33_RADIO_comment: values.LA_33_RADIO_comment || null,

      LA_34_RADIO: values.LA_34_RADIO || null,
      LA_34_RADIO_comment: values.LA_34_RADIO_comment || null,

      LA_41_RADIO: values.LA_41_RADIO || null,
      LA_41_RADIO_comment: values.LA_41_RADIO_comment || null,

      LA_42_RADIO: values.LA_42_RADIO || null,
      LA_42_RADIO_comment: values.LA_42_RADIO_comment || null,

      LA_43_RADIO: values.LA_43_RADIO || null,
      LA_43_RADIO_comment: values.LA_43_RADIO_comment || null,

      LA_51_RADIO: values.LA_51_RADIO || null,
      LA_51_RADIO_comment: values.LA_51_RADIO_comment || null,

      LA_52_RADIO: values.LA_52_RADIO || null,
      LA_52_RADIO_comment: values.LA_52_RADIO_comment || null,

      //ARTICLE 32
      //CHAP
      AR32_0_RADIO: values.AR32_0_RADIO || null,
      AR32_0_RADIO_comment: values.AR32_0_RADIO_comment || null,

      AR32_1_RADIO: values.AR32_1_RADIO || null,
      AR32_1_RADIO_comment: values.AR32_1_RADIO_comment || null,

      AR32_2_RADIO: values.AR32_2_RADIO || null,
      AR32_2_RADIO_comment: values.AR32_2_RADIO_comment || null,

      AR32_3_RADIO: values.AR32_3_RADIO || null,
      AR32_3_RADIO_comment: values.AR32_3_RADIO_comment || null,

      AR32_4_RADIO: values.AR32_4_RADIO || null,
      AR32_4_RADIO_comment: values.AR32_4_RADIO_comment || null,

      AR32_5_RADIO: values.AR32_5_RADIO || null,
      AR32_5_RADIO_comment: values.AR32_5_RADIO_comment || null,

      AR32_6_RADIO: values.AR32_6_RADIO || null,
      AR32_6_RADIO_comment: values.AR32_6_RADIO_comment || null,

      AR32_7_RADIO: values.AR32_7_RADIO || null,
      AR32_7_RADIO_comment: values.AR32_7_RADIO_comment || null,

      AR32_8_RADIO: values.AR32_8_RADIO || null,
      AR32_8_RADIO_comment: values.AR32_8_RADIO_comment || null,

      //ARTICLE 28
      //CHAP
      AR28_SCC1_RADIO: values.AR28_SCC1_RADIO || null,
      AR28_SCC1_RADIO_comment: values.AR28_SCC1_RADIO_comment || null,

      AR28_SCC1_ANNEX_1_RADIO: values.AR28_SCC1_ANNEX_1_RADIO || null,
      AR28_SCC1_ANNEX_1_RADIO_comment: values.AR28_SCC1_ANNEX_1_RADIO_comment || null,

      AR28_SCC1_ANNEX_2_RADIO: values.AR28_SCC1_ANNEX_2_RADIO || null,
      AR28_SCC1_ANNEX_2_RADIO_comment: values.AR28_SCC1_ANNEX_2_RADIO_comment || null,

      AR28_SCC1_ANNEX_3_RADIO: values.AR28_SCC1_ANNEX_3_RADIO || null,
      AR28_SCC1_ANNEX_3_RADIO_comment: values.AR28_SCC1_ANNEX_3_RADIO_comment || null,

      AR28_SCC1_ANNEX_4_RADIO: values.AR28_SCC1_ANNEX_4_RADIO || null,
      AR28_SCC1_ANNEX_4_RADIO_comment: values.AR28_SCC1_ANNEX_4_RADIO_comment || null,

      AR28_SCC1_ANNEX_5_RADIO: values.AR28_SCC1_ANNEX_5_RADIO || null,
      AR28_SCC1_ANNEX_5_RADIO_comment: values.AR28_SCC1_ANNEX_5_RADIO_comment || null,

      AR28_SCC2_RADIO: values.AR28_SCC2_RADIO || null,
      AR28_SCC2_RADIO_comment: values.AR28_SCC2_RADIO_comment || null,

      AR28_SCC2_ANNEX_1_RADIO: values.AR28_SCC2_ANNEX_1_RADIO || null,
      AR28_SCC2_ANNEX_1_RADIO_comment: values.AR28_SCC2_ANNEX_1_RADIO_comment || null,

      AR28_SCC2_ANNEX_2_RADIO: values.AR28_SCC2_ANNEX_2_RADIO || null,
      AR28_SCC2_ANNEX_2_RADIO_comment: values.AR28_SCC2_ANNEX_2_RADIO_comment || null,

      AR28_SCC2_ANNEX_3_RADIO: values.AR28_SCC2_ANNEX_3_RADIO || null,
      AR28_SCC2_ANNEX_3_RADIO_comment: values.AR28_SCC2_ANNEX_3_RADIO_comment || null,

      AR28_SCHREMS_US_RADIO: values.AR28_SCHREMS_US_RADIO || null,
      AR28_SCHREMS_US_RADIO_comment: values.AR28_SCHREMS_US_RADIO_comment || null,

      AR28_SCHREMS_UK_RADIO: values.AR28_SCHREMS_UK_RADIO || null,
      AR28_SCHREMS_UK_RADIO_comment: values.AR28_SCHREMS_UK_RADIO_comment || null,

      AR28_SCHREMS_INDIA_RADIO: values.AR28_SCHREMS_INDIA_RADIO || null,
      AR28_SCHREMS_INDIA_RADIO_comment: values.AR28_SCHREMS_INDIA_RADIO_comment || null,

      AR28_SCHREMS_GENERIC_RADIO: values.AR28_SCHREMS_GENERIC_RADIO || null,
      AR28_SCHREMS_GENERIC_RADIO_comment: values.AR28_SCHREMS_GENERIC_RADIO_comment || null,

      //IaaS
      IAAS_1_RADIO: values.IAAS_1_RADIO || null,
      IAAS_1_RADIO_comment: values.IAAS_1_RADIO_comment || null,

      IAAS_2_RADIO: values.IAAS_2_RADIO || null,
      IAAS_2_RADIO_comment: values.IAAS_2_RADIO_comment || null,

      IAAS_3_RADIO: values.IAAS_3_RADIO || null,
      IAAS_3_RADIO_comment: values.IAAS_3_RADIO_comment || null,

      IAAS_4_RADIO: values.IAAS_4_RADIO || null,
      IAAS_4_RADIO_comment: values.IAAS_4_RADIO_comment || null,

    }).then(snap => {
        //SaaS
        if(this.tabName === 'Design Form' || this.tabName === 'Legal Form'  || this.tabName === 'Article 32 Form'  || this.tabName === 'Article 28 Form') {

          firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => { //once n'est effectué qu'une fois et ne reste pas à l'écoute OK
              snapshot.forEach(data => {
                for (let i = 0; i <= 82; i++) {

                  //DESIGN
                  if (this.tabName === 'Design Form') {

                    //-1
                    //on check si la première évaluation émise pour sous chap ou chap est 'Recommended with reservations'
                    //Si pas de reservation lors du premier save de l'éval alors pas de reservation a ajoutée
                    if (data.child('DA_' + i + '_RADIO').toJSON() === 'Recommended with reservations') {//récupère le radio qui est une reservation

                      console.log("-------------ADD RESERVATION-----------");
                        this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                        this.afs.list(`${this.path}/`).push({
                          content: (document.getElementById('DA_' + i + '_RES') as HTMLInputElement).value || null,//recupère le commentaire du chap/sous chap qui contient la reserve
                          idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                          formIs: this.tabName,
                          idSolution: this.idSol,
                          solutionName: this.solName,
                          postedAt: new Date(Date.now()).toString(),
                          postedBy: this.authService.currentUserEmail,
                          userId: this.authService.currentUserId,
                          userRole: document.getElementById('roleUser').textContent,
                          reference: currentChapOrSubChap,
                          fixed: false,
                        }).then(data => {
                          this.afs.list(`${this.path}/`).update(data.key, {
                            deadlineDate: values.deadlineDate || null
                          });
                        });

                      //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                      //MAJ status du design
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'DLA is reviewing'
                      });

                      //Statut 'DLA is reviewing' (firestore db)
                      this.setUnderAssessmentStatus(this.tabName);

                    }

                    //-2
                    //Check la première evaluation emise est 'On hold' alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
                    if(data.child('DA_' + i + '_RADIO').toJSON() === 'On hold') {

                      //1-envoi du mail au SO + gbldla
                      console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

                      this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_' + i, new Date(Date.now()).toString(), data.child('DA_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                        if (data !== "200") {
                          console.error("KO mail retarus unsent !")
                        } else {
                          console.log("OK mail retarus sent !")
                          }
                        console.log("from api: " + data);
                      });

                      //2- envoi email aux evaluators BU inclus dans evaluators
                      if(this.emailsEvaluatorsBu) {
                        for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
                          console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

                          this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_' + i, new Date(Date.now()).toString(), data.child('DA_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
                            if (data !== "200") {
                              console.error("KO mail retarus unsent !")
                            } else {
                              console.log("OK mail retarus sent !")
                            }
                            console.log("from api: " + data);
                          });
                        }
                      }

                      //3- envoi mail aux vendors inclus dans emailVendorAuthorized
                      for(let i = 0; i < this.emailsVendors.length; i++) {
                        console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

                          this.sendOnHoldChapter(this.tabName, 'designForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'DA_' + i, new Date(Date.now()).toString(), data.child('DA_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
                            if (data !== "200") {
                              console.error("KO mail retarus unsent !")
                            } else {
                              console.log("OK mail retarus sent !")
                            }
                            console.log("from api: " + data);
                          });

                        }

                      //dans 'solutions' designStatus = 'Vendor is responding'
                      //this.homeUserService.editSolutionStatusDesignInProgress(this.idSolution);
                      const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                      const data1: Demande = {
                        designStatus: 'Vendor is responding'
                      };
                      demandeRef.update(data1);

                      //dans 'designForm' designStatus = 'Vendor is responding'
                      //this.homeUserService.editDesignFormStatusDesignInProgress(this.idSolution);
                      const refFormDa: AngularFirestoreDocument<DesignForm> = this.firestore.doc(`designForm/${this.idSolution}`);
                      const data2: DesignForm = {
                        designStatus: 'Vendor is responding',
                      };
                      refFormDa.update(data2);

                      //dans 'evaluations/Design Form' (realtime db) status = 'Vendor is responding'
                      //MAJ status du design
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'Vendor is responding'
                      });

                    }

                    //-3
                    //Si la première évaluation émise n'est ni 'Reco with reservations' ni 'On hold'
                    if (data.child('DA_' + i + '_RADIO').toJSON() === 'Recommended' || data.child('DA_' + i + '_RADIO').toJSON() === 'Not recommended') {

                      //dans 'evaluations/Design Form' (realtime db) status = 'DLA is reviewing'
                      //MAJ status du design
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'DLA is reviewing'
                      });

                      //Statut 'DLA is reviewing' (firestore db)
                      this.setUnderAssessmentStatus(this.tabName);
                    }
                  }

                  //LEGAL
                  if (this.tabName === 'Legal Form') {

                    //-1
                    //on check si la première évaluation émise pour sous chap ou chap est 'Recommended with reservations'
                    //Si pas de reservation lors du premier save de l'éval alors pas de reservation a ajoutée
                    if (data.child('LA_' + i + '_RADIO').toJSON() === 'Recommended with reservations') {//récupère le radio qui est une reservation

                      console.log("-------------ADD RESERVATION-----------");
                        this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                        this.afs.list(`${this.path}/`).push({
                          content: (document.getElementById('LA_' + i + '_RES') as HTMLInputElement).value || null,//recupère le commentaire du chap/sous chap qui contient la reserve
                          idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                          formIs: this.tabName,
                          idSolution: this.idSol,
                          solutionName: this.solName,
                          postedAt: new Date(Date.now()).toString(),
                          postedBy: this.authService.currentUserEmail,
                          userId: this.authService.currentUserId,
                          userRole: document.getElementById('roleUser').textContent,
                          reference: currentChapOrSubChap,
                          fixed: false,
                        }).then(data => {
                          this.afs.list(`${this.path}/`).update(data.key, {
                            deadlineDate: values.deadlineDate || null
                          });
                        });

                      //dans 'evaluations/Legal Form' (realtime db) status = 'DLA is reviewing'
                      //MAJ status du legal
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'DLA is reviewing'
                      });

                      //Statut 'DLA is reviewing' (firestore db)
                      this.setUnderAssessmentStatus(this.tabName);

                    }

                    //-2
                    //Check la première evaluation emise est 'On hold' alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
                    if(data.child('LA_' + i + '_RADIO').toJSON() === 'On hold') {

                      //1-envoi du mail au SO + gbldla
                      console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

                      this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_' + i, new Date(Date.now()).toString(), data.child('LA_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                        if (data !== "200") {
                          console.error("KO mail retarus unsent !")
                        } else {
                          console.log("OK mail retarus sent !")
                          }
                        console.log("from api: " + data);
                      });

                      //2- envoi email aux evaluators BU inclus dans evaluators
                      if(this.emailsEvaluatorsBu) {
                        for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
                          console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

                          this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_' + i, new Date(Date.now()).toString(), data.child('LA_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
                            if (data !== "200") {
                              console.error("KO mail retarus unsent !")
                            } else {
                              console.log("OK mail retarus sent !")
                            }
                            console.log("from api: " + data);
                          });
                        }
                      }

                      //3- envoi mail aux vendors inclus dans emailVendorAuthorized
                      for(let i = 0; i < this.emailsVendors.length; i++) {
                        console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

                          this.sendOnHoldChapter(this.tabName, 'legalForm', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_' + i, new Date(Date.now()).toString(), data.child('LA_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
                            if (data !== "200") {
                              console.error("KO mail retarus unsent !")
                            } else {
                              console.log("OK mail retarus sent !")
                            }
                            console.log("from api: " + data);
                          });

                        }

                      //dans 'solutions' legalStatus = 'Vendor is responding'
                      //this.homeUserService.editSolutionStatusLegalInProgress(this.idSolution);
                      const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                      const data1: Demande = {
                        legalStatus: 'Vendor is responding'
                      };
                      demandeRef.update(data1);

                      //dans 'legalForm' legalStatus = 'Vendor is responding'
                      //this.homeUserService.editDesignFormStatusLegalInProgress(this.idSolution);
                      const refFormLa: AngularFirestoreDocument<LegalForm> = this.firestore.doc(`legalForm/${this.idSolution}`);
                      const data2: LegalForm = {
                        legalStatus: 'Vendor is responding',
                      };
                      refFormLa.update(data2);

                      //dans 'evaluations/Legal Form' (realtime db) status = 'Vendor is responding'
                      //MAJ status du legal
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'Vendor is responding'
                      });

                    }

                    //-3
                    //Si la première évaluation émise n'est ni 'Reco with reservations' ni 'On hold'
                    if (data.child('LA_' + i + '_RADIO').toJSON() === 'Recommended' || data.child('LA_' + i + '_RADIO').toJSON() === 'Not recommended') {

                      //dans 'evaluations/Legal Form' (realtime db) status = 'DLA is reviewing'
                      //MAJ status du legal
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'DLA is reviewing'
                      });

                      //Statut 'DLA is reviewing' (firestore db)
                      this.setUnderAssessmentStatus(this.tabName);
                    }
                  }

                  //ARTICLE 32
                  if (this.tabName === 'Article 32 Form') {

                    //-1
                    //on check si la première évaluation émise pour sous chap ou chap est 'Recommended with reservations'
                    //Si pas de reservation lors du premier save de l'éval alors pas de reservation a ajoutée
                    if (data.child('AR32_' + i + '_RADIO').toJSON() === 'Recommended with reservations') {//récupère le radio qui est une reservation

                      console.log("-------------ADD RESERVATION-----------");
                        this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                        this.afs.list(`${this.path}/`).push({
                          content: (document.getElementById('AR32_' + i + '_RES') as HTMLInputElement).value || null,//recupère le commentaire du chap/sous chap qui contient la reserve
                          idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                          formIs: this.tabName,
                          idSolution: this.idSol,
                          solutionName: this.solName,
                          postedAt: new Date(Date.now()).toString(),
                          postedBy: this.authService.currentUserEmail,
                          userId: this.authService.currentUserId,
                          userRole: document.getElementById('roleUser').textContent,
                          reference: currentChapOrSubChap,
                          fixed: false,
                        }).then(data => {
                          this.afs.list(`${this.path}/`).update(data.key, {
                            deadlineDate: values.deadlineDate || null
                          });
                        });

                      //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                      //MAJ status du article32
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'DLA is reviewing'
                      });

                      //Statut 'DLA is reviewing' (firestore db)
                      this.setUnderAssessmentStatus(this.tabName);

                    }

                    //-2
                    //Check la première evaluation emise est 'On hold' alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
                    if(data.child('AR32_' + i + '_RADIO').toJSON() === 'On hold') {

                      //1-envoi du mail au SO + gbldla
                      console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

                      this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_' + i, new Date(Date.now()).toString(), data.child('AR32_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                        if (data !== "200") {
                          console.error("KO mail retarus unsent !")
                        } else {
                          console.log("OK mail retarus sent !")
                          }
                        console.log("from api: " + data);
                      });

                      //2- envoi email aux evaluators BU inclus dans evaluators
                      if(this.emailsEvaluatorsBu) {
                        for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
                          console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

                          this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR32_' + i, new Date(Date.now()).toString(), data.child('AR32_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
                            if (data !== "200") {
                              console.error("KO mail retarus unsent !")
                            } else {
                              console.log("OK mail retarus sent !")
                            }
                            console.log("from api: " + data);
                          });
                        }
                      }

                      //3- envoi mail aux vendors inclus dans emailVendorAuthorized
                      for(let i = 0; i < this.emailsVendors.length; i++) {
                        console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

                          this.sendOnHoldChapter(this.tabName, 'article32Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'LA_' + i, new Date(Date.now()).toString(), data.child('AR32_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
                            if (data !== "200") {
                              console.error("KO mail retarus unsent !")
                            } else {
                              console.log("OK mail retarus sent !")
                            }
                            console.log("from api: " + data);
                          });

                        }

                      //dans 'solutions' article32Status = 'Vendor is responding'
                      //this.homeUserService.editSolutionStatusArticle32InProgress(this.idSolution);
                      const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                      const data1: Demande = {
                        article32Status: 'Vendor is responding'
                      };
                      demandeRef.update(data1);

                      //dans 'article32Form' article32Status = 'Vendor is responding'
                      //this.homeUserService.editArticle32FormStatusLegalInProgress(this.idSolution);
                      const refFormAR32: AngularFirestoreDocument<Article32Form> = this.firestore.doc(`article32Form/${this.idSolution}`);
                      const data2: Article32Form = {
                        article32Status: 'Vendor is responding',
                      };
                      refFormAR32.update(data2);

                      //dans 'evaluations/Article 32 Form' (realtime db) status = 'Vendor is responding'
                      //MAJ status du article32
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'Vendor is responding'
                      });

                    }

                    //-3
                    //Si la première évaluation émise n'est ni 'Reco with reservations' ni 'On hold'
                    if (data.child('AR32_' + i + '_RADIO').toJSON() === 'Recommended' || data.child('AR32_' + i + '_RADIO').toJSON() === 'Not recommended') {

                      //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                      //MAJ status du article32
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'DLA is reviewing'
                      });

                      //Statut 'DLA is reviewing' (firestore db)
                      this.setUnderAssessmentStatus(this.tabName);
                    }
                  }

                  //ARTICLE 28
                  if (this.tabName === 'Article 28 Form') { //Annexes

                    //-1
                    //on check si la première évaluation émise pour sous chap ou chap est 'Recommended with reservations'
                    //Si pas de reservation lors du premier save de l'éval alors pas de reservation a ajoutée
                    //ANNEX
                    if (data.child('AR28_SCC1_ANNEX_' + i + '_RADIO').toJSON() === 'Recommended with reservations') {//récupère le radio qui est une reservation
                      console.log("-------------ADD RESERVATION-----------");

                        this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                        this.afs.list(`${this.path}/`).push({
                          content: (<HTMLInputElement>document.getElementById('AR28_SCC1_ANNEX_' + i + '_RES')).value || null,
                          idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                          formIs: this.tabName,
                          idSolution: this.idSol,
                          solutionName: this.solName,
                          postedAt: new Date(Date.now()).toString(),
                          postedBy: this.authService.currentUserEmail,
                          userId: this.authService.currentUserId,
                          userRole: document.getElementById('roleUser').textContent,
                          reference: currentChapOrSubChap,
                          fixed: false,
                        }).then(data => {
                          this.afs.list(`${this.path}/`).update(data.key, {
                            deadlineDate: values.deadlineDate || null
                          });
                        });

                      //dans 'evaluations/Article 28 Form' (realtime db) status = 'DLA is reviewing'
                      //MAJ status du article28
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'DLA is reviewing'
                      });

                      //Statut 'DLA is reviewing' (firestore db)
                      this.setUnderAssessmentStatus(this.tabName);

                    }

                    //-2
                    //Check la première evaluation emise est 'On hold' alors statut passe/reste à 'Vendor is responding' + envoi notif vendor(s) + evaluators Bu + DLA
                    if(data.child('AR28_SCC1_ANNEX_' + i + '_RADIO').toJSON() === 'On hold') {

                      //1-envoi du mail au SO + gbldla
                      console.log("mail envoyé au so " + this.emailSolOwner + " + à la DLA")

                      this.sendOnHoldChapter(this.tabName, 'article28Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR28_SCC1_ANNEX_' + i, new Date(Date.now()).toString(), data.child('AR28_SCC1_ANNEX_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, this.emailSolOwner, null, 'gbl.ist.technologydesignauthority.all.groups@veolia.com').subscribe(data => {
                        if (data !== "200") {
                          console.error("KO mail retarus unsent !")
                        } else {
                          console.log("OK mail retarus sent !")
                          }
                        console.log("from api: " + data);
                      });

                      //2- envoi email aux evaluators BU inclus dans evaluators
                      if(this.emailsEvaluatorsBu) {
                        for(let i = 0; i < this.emailsEvaluatorsBu.length; i++) {
                          console.log("mail envoyé a l'evaluator: (" + i + ") " + this.emailsEvaluatorsBu[i])

                          this.sendOnHoldChapter(this.tabName, 'article28Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR28_SCC1_ANNEX_' + i + '_RADIO', new Date(Date.now()).toString(), data.child('AR28_SCC1_ANNEX_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, null, this.emailsEvaluatorsBu[i], null).subscribe(data => {
                            if (data !== "200") {
                              console.error("KO mail retarus unsent !")
                            } else {
                              console.log("OK mail retarus sent !")
                            }
                            console.log("from api: " + data);
                          });
                        }
                      }

                      //3- envoi mail aux vendors inclus dans emailVendorAuthorized
                      for(let i = 0; i < this.emailsVendors.length; i++) {
                        console.log("mail envoyé au vendor: (" + i + ") " + this.emailsVendors[i])

                          this.sendOnHoldChapter(this.tabName, 'article28Form', this.idSolution, this.solName, this.rfpNameOfSol, this.editorBrandOfSol, this.authService.currentUserEmail, this.authService.currentUserDisplayName, 'AR28_SCC1_ANNEX_' + i, new Date(Date.now()).toString(), data.child('AR28_SCC1_ANNEX_' + i + '_RADIO_comment').toJSON(), 'On hold', this.buOfSolu, null, this.emailsVendors[i], null).subscribe(data => {
                            if (data !== "200") {
                              console.error("KO mail retarus unsent !")
                            } else {
                              console.log("OK mail retarus sent !")
                            }
                            console.log("from api: " + data);
                          });

                        }

                      //dans 'solutions' article28Status = 'Vendor is responding'
                      //this.homeUserService.editSolutionStatusArticle28InProgress(this.idSolution);
                      const demandeRef: AngularFirestoreDocument<Demande> = this.firestore.doc(`solutions/${this.idSolution}`);
                      const data1: Demande = {
                        article28Status: 'Vendor is responding'
                      };
                      demandeRef.update(data1);

                      //dans 'article28Form' article28Status = 'Vendor is responding'
                      //this.homeUserService.editArticle28FormStatusLegalInProgress(this.idSolution);
                      const refFormAR28: AngularFirestoreDocument<Article28Form> = this.firestore.doc(`article28Form/${this.idSolution}`);
                      const data2: Article28Form = {
                        article28Status: 'Vendor is responding',
                      };
                      refFormAR28.update(data2);

                      //dans 'evaluations/Article 28 Form' (realtime db) status = 'Vendor is responding'
                      //MAJ status du article32
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'Vendor is responding'
                      });

                    }

                    //-3
                    //Si la première évaluation émise n'est ni 'Reco with reservations' ni 'On hold'
                    if (data.child('AR28_SCC1_ANNEX_' + i + '_RADIO').toJSON() === 'Recommended' || data.child('AR28_SCC1_ANNEX_' + i + '_RADIO').toJSON() === 'Not recommended') {

                      //dans 'evaluations/Article 32 Form' (realtime db) status = 'DLA is reviewing'
                      //MAJ status du article32
                      this.path = 'evaluations/' + this.tabName + '/' + this.idSolution;
                      this.afs.list(`${this.path}/`).update(snap.key, {
                        status: this.selectedEvaluation.status || 'DLA is reviewing'
                      });

                      //Statut 'DLA is reviewing' (firestore db)
                      this.setUnderAssessmentStatus(this.tabName);
                    }
                  }

                  //ARTICLE 28
                  //if (this.tabName === 'Article 28 Form') { //Annexes
                    //SCC1 et SCC2
                  //   if (data.child('AR28_SCC'+i+'_RADIO').toJSON() === 'Recommended with reservations') {//récupère le radio qui est une reservation
                  //     console.log("-------------ADD RESERVATION-----------");

                  //       this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                  //       this.afs.list(`${this.path}/`).push({
                  //         content: (<HTMLInputElement>document.getElementById('AR28_SCC'+i+'_RES')).value || null,
                  //         idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                  //         formIs: this.tabName,
                  //         idSolution: this.idSol,
                  //         solutionName: this.solName,
                  //         postedAt: new Date(Date.now()).toString(),
                  //         postedBy: this.authService.currentUserEmail,
                  //         userId: this.authService.currentUserId,
                  //         userRole: document.getElementById('roleUser').textContent,
                  //         reference: currentChapOrSubChap,
                  //         fixed: false,
                  //       }).then(data => {
                  //         this.afs.list(`${this.path}/`).update(data.key, {
                  //           deadlineDate: values.deadlineDate || null
                  //         });
                  //       });
                  //   }

                  //   //ANNEX
                  //   if (data.child('AR28_SCC1_ANNEX_' + i + '_RADIO').toJSON() === 'Recommended with reservations') {//récupère le radio qui est une reservation
                  //     console.log("-------------ADD RESERVATION-----------");

                  //       this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                  //       this.afs.list(`${this.path}/`).push({
                  //         content: (<HTMLInputElement>document.getElementById('AR28_SCC1_ANNEX_' + i + '_RES')).value || null,
                  //         idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                  //         formIs: this.tabName,
                  //         idSolution: this.idSol,
                  //         solutionName: this.solName,
                  //         postedAt: new Date(Date.now()).toString(),
                  //         postedBy: this.authService.currentUserEmail,
                  //         userId: this.authService.currentUserId,
                  //         userRole: document.getElementById('roleUser').textContent,
                  //         reference: currentChapOrSubChap,
                  //         fixed: false,
                  //       }).then(data => {
                  //         this.afs.list(`${this.path}/`).update(data.key, {
                  //           deadlineDate: values.deadlineDate || null
                  //         });
                  //       });
                  //   }
                  //   if (data.child('AR28_SCC2_ANNEX_' + i + '_RADIO').toJSON() === 'Recommended with reservations') {//récupère le radio qui est une reservation
                  //     console.log("-------------ADD RESERVATION-----------");

                  //       this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                  //       this.afs.list(`${this.path}/`).push({
                  //         content: (<HTMLInputElement>document.getElementById('AR28_SCC2_ANNEX_' + i + '_RES')).value || null,
                  //         idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                  //         formIs: this.tabName,
                  //         idSolution: this.idSol,
                  //         solutionName: this.solName,
                  //         postedAt: new Date(Date.now()).toString(),
                  //         postedBy: this.authService.currentUserEmail,
                  //         userId: this.authService.currentUserId,
                  //         userRole: document.getElementById('roleUser').textContent,
                  //         reference: currentChapOrSubChap,
                  //         fixed: false,
                  //       }).then(data => {
                  //         this.afs.list(`${this.path}/`).update(data.key, {
                  //           deadlineDate: values.deadlineDate || null
                  //         });
                  //       });
                  //   }

                  //   //SCHREMS US
                  //   if(data.child('AR28_SCHREMS_US_RADIO').toJSON() === 'Recommended with reservations') {
                  //     console.log("-------------ADD RESERVATION-----------")

                  //     this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                  //     this.afs.list(`${this.path}/`).push({
                  //       content: (<HTMLInputElement>document.getElementById('AR28_SCHREMS_US_RES')).value || null,
                  //         idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                  //         formIs: this.tabName,
                  //         idSolution: this.idSol,
                  //         solutionName: this.solName,
                  //         postedAt: new Date(Date.now()).toString(),
                  //         postedBy: this.authService.currentUserEmail,
                  //         userId: this.authService.currentUserId,
                  //         userRole: document.getElementById('roleUser').textContent,
                  //         reference: currentChapOrSubChap,
                  //         fixed: false,
                  //       }).then(data => {
                  //         this.afs.list(`${this.path}/`).update(data.key, {
                  //           deadlineDate: values.deadlineDate || null
                  //         });
                  //       });
                  //     }

                  //   //SCHREMS UK
                  //   if(data.child('AR28_SCHREMS_UK_RADIO').toJSON() === 'Recommended with reservations') {
                  //     console.log("-------------ADD RESERVATION-----------")

                  //     this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                  //     this.afs.list(`${this.path}/`).push({
                  //       content: (<HTMLInputElement>document.getElementById('AR28_SCHREMS_UK_RES')).value || null,
                  //         idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                  //         formIs: this.tabName,
                  //         idSolution: this.idSol,
                  //         solutionName: this.solName,
                  //         postedAt: new Date(Date.now()).toString(),
                  //         postedBy: this.authService.currentUserEmail,
                  //         userId: this.authService.currentUserId,
                  //         userRole: document.getElementById('roleUser').textContent,
                  //         reference: currentChapOrSubChap,
                  //         fixed: false,
                  //       }).then(data => {
                  //         this.afs.list(`${this.path}/`).update(data.key, {
                  //           deadlineDate: values.deadlineDate || null
                  //         });
                  //       });
                  //     }

                  //   //SCHREMS INDIA
                  //   if(data.child('AR28_SCHREMS_INDIA_RADIO').toJSON() === 'Recommended with reservations') {
                  //     console.log("-------------ADD RESERVATION-----------")

                  //     this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                  //     this.afs.list(`${this.path}/`).push({
                  //       content: (<HTMLInputElement>document.getElementById('AR28_SCHREMS_INDIA_RES')).value || null,
                  //         idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                  //         formIs: this.tabName,
                  //         idSolution: this.idSol,
                  //         solutionName: this.solName,
                  //         postedAt: new Date(Date.now()).toString(),
                  //         postedBy: this.authService.currentUserEmail,
                  //         userId: this.authService.currentUserId,
                  //         userRole: document.getElementById('roleUser').textContent,
                  //         reference: currentChapOrSubChap,
                  //         fixed: false,
                  //       }).then(data => {
                  //         this.afs.list(`${this.path}/`).update(data.key, {
                  //           deadlineDate: values.deadlineDate || null
                  //         });
                  //       });
                  //     }

                  //   //SCHREMS GENERIC
                  //   if(data.child('AR28_SCHREMS_GENERIC_RADIO').toJSON() === 'Recommended with reservations') {
                  //     console.log("-------------ADD RESERVATION-----------")

                  //     this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                  //     this.afs.list(`${this.path}/`).push({
                  //       content: (<HTMLInputElement>document.getElementById('AR28_SCHREMS_GENERIC_RES')).value || null,
                  //         idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                  //         formIs: this.tabName,
                  //         idSolution: this.idSol,
                  //         solutionName: this.solName,
                  //         postedAt: new Date(Date.now()).toString(),
                  //         postedBy: this.authService.currentUserEmail,
                  //         userId: this.authService.currentUserId,
                  //         userRole: document.getElementById('roleUser').textContent,
                  //         reference: currentChapOrSubChap,
                  //         fixed: false,
                  //       }).then(data => {
                  //         this.afs.list(`${this.path}/`).update(data.key, {
                  //           deadlineDate: values.deadlineDate || null
                  //         });
                  //       });
                  //     }

                  // }

                }//END FOR

              });

            });
          }

        //IaaS
        if(this.tabName === 'IaaS Form'){
          //IAAS
          //on check si la première évaluation émise pour sous chap ou chap est 'Recommended with reservations'
          //Si pas de reservation lors du premier save de l'éval alors pas de reservation a ajoutée
          firebase
            .database()
            .ref('evaluations/' + this.tabName + '/' + this.idSolution)
            .once('value', snapshot => { //once n'est effectué qu'une fois et ne reste pas à l'écoute OK
              snapshot.forEach(data => {
                for (let i = 1; i <= 4; i++) {
                  //IAAS
                  if (this.tabName === 'IaaS Form') {
                    if (data.child('IAAS_' + i + '_RADIO').toJSON() === 'Recommended with reservations') {//récupère le radio qui est une reservation
                      console.log("-------------ADD RESERVATION-----------");
                        this.path = `reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`;
                        this.afs.list(`${this.path}/`).push({
                          content: (document.getElementById('IAAS_' + i + '_RES') as HTMLInputElement).value || null,//recupère le commentaire du chap/sous chap qui contient la reserve
                          idRefReservation: this.getIdRefReservation(currentChapOrSubChap),
                          formIs: this.tabName,
                          idSolution: this.idSol,
                          solutionName: this.solName,
                          postedAt: new Date(Date.now()).toString(),
                          postedBy: this.authService.currentUserEmail,
                          userId: this.authService.currentUserId,
                          userRole: document.getElementById('roleUser').textContent,
                          reference: currentChapOrSubChap,
                          fixed: false,
                        }).then(data => {
                          this.afs.list(`${this.path}/`).update(data.key, {
                            deadlineDate: values.deadlineDate || null
                          });
                        });
                    }

                  }
                }//END FOR
              });
            });
          }

      });//END THEN

  }
  ////////////////////////END CHAP/////////////////////


  ////////////////////////RESERVATION//////////////////////
  getIdRefReservation(currentChapOrSubChap) {
    return this.idSolution + '-' + currentChapOrSubChap;
  }

  getReservation(idrefreservation: string, currentChapOrSubChap) {
    this.currentReservation = this.afs.list(`reservations/${this.tabName}/${this.idSolution}/${currentChapOrSubChap}`, ref =>
      ref.orderByChild('idRefReservation').equalTo(idrefreservation))
    return this.currentReservation;
  }

  //tableauEval
  //pour checker les chapitres qui ont une reservation
  getAllReservations(form) {
    return (this.reservationsList = this.afs.list('reservations/' + form + '/' + this.idSolution));
  }

  //tableauEval
  getReservationForChapter(idrefreservation: string, formName: string) {
    return (this.reservationsList = this.afs.list(
      'reservations/' + formName + '/' + this.idSolution + '/' + this.ref,
      ref => ref.orderByChild('idRefReservation').equalTo(idrefreservation)
    ));
  }

  //modifier datas de la reservation fixedDate et fixed et finexOn (vendor)
  //Dans tableau eval
  updateFixedAndFixedDateReservation(fixedVal, fixedDateVal, fixedOn, resKey, formName, idSol, ref) {

    let path = 'reservations/' + formName + '/' + idSol + '/' + ref;
          this.afs.list(`${path}/`).update(resKey, {
            fixed: JSON.parse(fixedVal),//conversion en boolean
            fixedDate: fixedDateVal, //date de correction soumise par vendor
            fixedOn: fixedOn //date à laquelle le vendor a mis fixed en true
          });
  }

  ////////////////////////END RESERVATION//////////////////////

  ////////////////////OVERVIEW EVAL///////////////////////////

  //pour modifier évaluation finale (design ou legal ou article 32 ou 28 ou iaas ou paas ou apps selon form actuel)
  updateFinalEvaluation(evaluation: Evaluation, currentEvalKey) {
    this.currentEvaluation.update(currentEvalKey, {
      finalComment: evaluation.finalComment || null,
      status: evaluation.status || 'DLA is reviewing', //Si toujours pas d'évaluation finale cochée alors DLA is reviewing
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName
    });
  }

  //pour modifier évaluation (acunetix)
  updateAcunetix(evaluation: Evaluation, currentEvalKey) {
    this.currentEvaluation.update(currentEvalKey, {
      acunetix_radio: evaluation.acunetix_radio || null,
      secu_radio: evaluation.secu_radio || null,
      acunetix_comment: evaluation.acunetix_comment || null,
      postedAt: new Date(Date.now()).toString(),
      postedBy: this.authService.currentUserEmail,
      userId: this.authService.currentUserId,
      solutionName: this.solName
    });
  }

  //SaaS
  sendFormCompletedAcknowledgmentSaaS(idSolution, solName, stringForm, tabName, emailVendor, firstname, lastname, rfpName, editorBrand, buOfSolution){
    const query = dictToURI({
      vendorEmail: emailVendor,
      idSol: idSolution,
      stringForm,
      formName: tabName,
      solutionName: solName,
      firstnameVendor: firstname,
      lastnameVendor: lastname,
      bu: buOfSolution,
      rfpName,
      editorBrand
    });
    return this.httpClient.get('/api/form_completed_acknowledgment_SaaS?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //IaaS PaaS Apps
  sendFormCompletedAcknowledgment(idSolution, solName, stringForm, tabName, emailVeolia, fullNameVeolia, editorBrand, buOfSolution){
    const query = dictToURI(
      {
        veoliaEmail: emailVeolia,
        stringForm,
        formName: tabName,
        solutionName: solName,
        fullNameVeolia,
        bu: buOfSolution,
        editorBrand: editorBrand,
        type: tabName.substring(0, 4)
      });
   return this.httpClient.get('/api/form_completed_acknowledgment?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //SaaS
  sendFormCompletedSaaS(idSolution, solName, stringForm, tabName, emailVendor, solOwner, rfpName, editorBrand, buOfSolution, evaluatorsBu, emailgbldla){
    const query = dictToURI({
      vendorEmail: emailVendor,
      emailgbldla,
      idSol: idSolution,
      stringForm,
      formName: tabName,
      solutionName: solName,
      emailSolOwner: solOwner,
      rfpName,
      editorBrand,
      bu: buOfSolution,
      emailsEvaluatorsBuList: evaluatorsBu
    });
    return this.httpClient.get('/api/form_completed_SaaS?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //IaaS PaaS Apps
  sendFormCompleted(idSolution, solName, stringForm, tabName, emailVeolia, solOwner, editorBrand, buOfSolution, emailgbldla){
    const query = dictToURI({
      veoliaEmail: emailVeolia,
      emailgbldla,
      idSol: idSolution,
      stringForm,
      formName: tabName,
      solutionName: solName,
      emailSolOwner: solOwner,
      editorBrand,
      bu: buOfSolution,
      type: tabName.substring(0, 4)
    });

    return this.httpClient.get('/api/form_completed?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //SaaS only
  sendNeedHelpForm(solName, tabName, emailVendor, firstName, lastName, subject, text, rfpName, editorBrand, solOwner, buOfSolution){
    const query = dictToURI({
      vendorEmail: emailVendor,
      formName: tabName,
      solutionName: solName,
      emailSolOwner: solOwner,
      rfpName,
      editorBrand,
      firstnameVendor: firstName,
      lastnameVendor: lastName,
      subject: subject,
      content: text,
      bu: buOfSolution
    });
    return this.httpClient.get('/api/need_help_form?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //SaaS only
  sendFormStartedSaaS(solName, tabName, solOwner, rfpName, editorBrand, buOfSolution, vendorEmail){
    const query = dictToURI(
      {
        vendorEmail,
        formName: tabName,
        solutionName: solName,
        emailSolOwner: solOwner,
        rfpName: rfpName,
        editorBrand: editorBrand,
        bu: buOfSolution
      });

   return this.httpClient.get('/api/form_started_SaaS?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //SaaS
  sendReservationFixedSaaS(formName, solutionName, rfpName, editorBrand, solOwner, buOfSol, vendorEmail, ref, content, postedBy, postedAt, evaluatorsBu, emailgbldla){
    const query = dictToURI({
      vendorEmail,
      emailgbldla,
      formName,
      solutionName,
      emailSolOwner: solOwner,
      rfpName: rfpName,
      editorBrand,
      bu: buOfSol,
      chapter: this.translateService.instant(ref),
      postedAt,
      postedByEvaluator: postedBy,
      content,
      emailsEvaluatorsBuList: evaluatorsBu
    });
    return this.httpClient.get('/api/reservation_fixed_SaaS?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //IaaS PaaS Apps
  sendReservationFixed(formName, solutionName, editorBrand, solOwner, buOfSol, veoliaEmail, ref, content, postedBy, postedAt){
    const query = dictToURI({
      veoliaEmail,
      formName,
      solutionName,
      emailSolOwne: solOwner,
      editorBrand,
      bu: buOfSol,
      chapter: this.translateService.instant(ref),
      postedAt,
      postedByEvaluator: postedBy,
      content: content,
      type: formName.substring(0, 4)
    });
    return this.httpClient.get('/api/reservation_fixed?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //SaaS
  sendReservationDateProvidedSaaS(formName, solutionName, rfpName, editorBrand, solOwner, buOfSol, vendorEmail, ref, content, postedBy, postedAt, fixDate, evaluatorsBu, emailgbldla){
    const query = dictToURI({
      vendorEmail,
      emailgbldla,
      formName,
      solutionName,
      emailSolOwner: solOwner,
      rfpName: rfpName,
      editorBrand,
      bu: buOfSol,
      chapter: this.translateService.instant(ref),
      postedAt,
      postedByEvaluator: postedBy,
      content,
      fixDate,
      emailsEvaluatorsBuList: evaluatorsBu
    })
    return this.httpClient.get('/api/reservation_date_provided_SaaS?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  //IaaS PaaS Apps
  sendReservationDateProvided(formName, solutionName, editorBrand, solOwner, buOfSol, veoliaEmail, ref, content, postedBy, postedAt, fixDate){
   const query = dictToURI({
      veoliaEmail,
      formName,
      solutionName,
      emailSolOwner: solOwner,
      editorBrand,
      bu: buOfSol,
      chapter: this.translateService.instant(ref),
      postedAt,
      postedByEvaluator: postedBy,
      content,
      fixDate,
      type: formName.substring(0, 4)
    });
    return this.httpClient.get('/api/reservation_date_provided?' + query, {responseType:'text'}).pipe(
        catchError(this.handleErrorHttpBasic)
    );
  }

  public handleErrorHttpBasic(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else { //status code 500 ou 401 ou 400
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}\n, ` +
          `body was: ${error.error}\n`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'API request failed.');
    }

}
