<p class="title">{{formName}}</p>

<div *ngIf="!reservationByChapterIsPresent || reservationByChapterIsPresent.length === 0"><strong style="color:red" translate>No reservations</strong></div>
<ul>
  <li *ngFor="let item of reservationByChapterIsPresent; index as i">
    <a
      class="btn btn-info text-white"
      (click)="service.getReference(item); reservationByChapter()"
      title="See comments submitted"
      translate id="{{item}}"
      >{{ item | translate }} <em id="icon{{i}}" class="fas fa-exclamation-triangle"></em><!--id item sert pour colorer le bouton-->
    </a>
  </li>
</ul>

<div *ngIf="(auth.user | async) as user">
  
  <!--SaaS-->
  <section *ngIf="formName === 'Design Form' || formName === 'Legal Form' || formName === 'Article 32 Form' || formName === 'Article 28 Form'">
  
    <!--VEOLIA-->
    <div *ngIf="auth.isVeolia(user)">
  
      <div *ngFor="let res of reservationsList">
        <hr>
          
          <div *ngIf="auth.isAdmin(user) || auth.isEvaluator(user) || userIsInEmailEvaluatorAuthorized">
            <p translate>Edit reservation
              <a target="_blank" routerLink="/formulaireAll/{{formName}}/{{res.idSolution}}" translate>{{res.reference}}</a>
            </p>
          </div>
          
          <div *ngIf="!auth.isAdmin(user) && !auth.isEvaluator(user) || !userIsInEmailEvaluatorAuthorized">
            <p translate>Check out
              <a target="_blank" routerLink="/formulaireAll/{{formName}}/{{res.idSolution}}" translate>{{res.reference}}</a>
            </p>
          </div>
          
          <br />
          
          <strong style="color: #5bc0de;">{{ res.reference }}</strong> <br />
          <em> {{'Réserve émise le' | translate }} {{ res.postedAt | date: 'longDate' }}</em> <br />
          <em> {{'Deadline imposée' | translate }} {{ res.deadlineDate | date: 'longDate' }} </em> <br />
          <em> {{'By' | translate }} {{ res.postedBy }} ({{res.userRole}})</em> <br />
  
          <mat-label translate class="radio-title">Fixed ?</mat-label>
              <mat-radio-group class="radio-group readonly" value="{{res.fixed}}">
                  <mat-radio-button class="radio-button" value="true">{{
                    'Yes' | translate
                    }}</mat-radio-button>
                  <mat-radio-button class="radio-button" value="false">{{
                    'No' | translate
                    }}</mat-radio-button>
              </mat-radio-group>
              <!--Si le vendor a fixé la reserve-->
          <strong *ngIf="res.fixed === true">{{'Fixé le' | translate }} => {{res.fixedOn | date}}</strong> <br /><br />
          <br />
          
          <!--Si le vendor a donné une date de correction-->
          <strong *ngIf="res.fixedDate && res.fixedDate !== null" class="fix">{{'Date de correction prévue' | translate }} => {{res.fixedDate | date}}</strong> <br /><br />
          
           <mat-form-field>
              <textarea id="myInput"
                matInput
                placeholder="{{ 'Description de la reserve' | translate }}"
                class="form-control" value="{{res.content}}"
                readonly>
              </textarea>
            </mat-form-field>
  
          <a class="icon" (click)="copy()" title="Copy to clipboard"><em style="color:#5bc0de;" class="fas fa-copy"></em></a>
          
      </div>
      
    </div>
      
    <!--VENDOR-->
    <div *ngIf="auth.isVendor(user)">
      
       <div *ngFor="let res of reservationsList">
        <hr>
        
        <p translate>Check out
            <a target="_blank" routerLink="/formulaireAll/{{formName}}/{{res.idSolution}}" translate>{{res.reference}}</a>
        </p>
        
        <br />
        
        <strong style="color: #5bc0de;">{{ res.reference }}</strong> <br />
          <em> {{'Réserve émise le' | translate }} {{ res.postedAt | date: 'longDate' }}</em> <br />
          <em> {{'Deadline imposée' | translate }} {{ res.deadlineDate | date: 'longDate' }} </em> <br />
          <em> {{'By' | translate }} {{ res.postedBy }} ({{res.userRole}})</em> <br />
          <em> {{'Description de la reserve' | translate }}: 
                      <strong style="color:#5bc0de" *ngIf="res.content">{{ res.content }}</strong>
                      <strong style="color:#5bc0de" *ngIf="!res.content" translate>(Not provided)</strong>
          </em>
          <!--Si le vendor a fixé la reserve-->
          <strong *ngIf="res.fixed === true">{{'Fixé le' | translate }} => {{res.fixedOn | date}}</strong> <br /><br />
          
          <em> {{'Fixed ?' | translate }}: <strong style="color:#5bc0de">{{ res.fixed }}</strong> </em> <br />
          
          <!--Si le vendor a fixé une date de correction-->
          <strong *ngIf="res.fixedDate && res.fixedDate !== null" class="fix">{{'Date de correction prévue' | translate }} => {{res.fixedDate | date}}</strong> <br />
          
          <a
          class="btn text-primary "
          (click)="getForm(res.fixed,res.fixedDate)"
          title="Edit"
          >Edit<em class="material-icons iconEdit"> edit </em></a
          > 
          
          
        <br />
        
        <hr>
        <form
          class="res-form"
          *ngIf="reservationForm && this.click===true"
          [formGroup]="reservationForm"
          novalidate
          (ngSubmit)="onSubmit(reservationForm.value, this.resKey, res.formIs, res.idSolution, res.reference, res.content, res.postedBy, res.postedAt)"
        >
            
            <mat-label translate class="radio-title">Fixed ?</mat-label>
              <mat-radio-group class="radio-group" formControlName="fixed" >
                  <mat-radio-button class="radio-button" value="true" (click)="toggleHide()">{{
                    'Yes' | translate
                    }}</mat-radio-button>
                  <mat-radio-button class="radio-button" value="false" (click)="toggleDisplay()">{{
                    'No' | translate
                    }}</mat-radio-button>
              </mat-radio-group>
          <br />
          
          
          <mat-form-field id="fixedDateField">
              <input
                matInput
                [matDatepicker]="picker"
                placeholder="{{'Date de correction prévue' | translate}}"
                class="form-control" formControlName="fixedDate"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          
          
          <div class="row submit-button-container">
            <div class="col-md-4">
              <button
                mat-raised-button
                class="submit-button"
                color="primary"
                type="submit"
                style="background:#00AEC7; "
              >
                Save
              </button>
            </div>
          </div>
          
        </form>
      
       </div>
       
  
       
    </div>
    
  </section>
  
  <!--IaaS PaaS Apps-->
  <section *ngIf="formName === 'IaaS Form' || formName === 'PaaS Form' || formName === 'Apps Form'">
    
    <!--VEOLIA-->
    <div *ngIf="auth.isVeolia(user) && isSoOfSolution === false">
  
      <div *ngFor="let res of reservationsList">
        <hr>
          
          <div *ngIf="auth.isAdmin(user) || auth.isEvaluator(user) || userIsInEmailEvaluatorAuthorized">
            <p translate>Edit reservation
              <a target="_blank" routerLink="/formulaireAll/{{res.type}}Form/{{res.idSolution}}" translate>{{res.reference}}</a>
            </p>
          </div>
          
          <div *ngIf="!auth.isAdmin(user) && !auth.isEvaluator(user) || !userIsInEmailEvaluatorAuthorized">
            <p translate>Check out
              <a target="_blank" routerLink="/formulaireAll/{{res.type}}Form/{{res.idSolution}}" translate>{{res.reference}}</a>
            </p>
          </div>
          
          <br />
          
          <strong style="color: #5bc0de;">{{ res.reference }}</strong> <br />
          <em> {{'Réserve émise le' | translate }} {{ res.postedAt | date: 'longDate' }}</em> <br />
          <em> {{'Deadline imposée' | translate }} {{ res.deadlineDate | date: 'longDate' }} </em> <br />
          <em> {{'By' | translate }} {{ res.postedBy }} ({{res.userRole}})</em> <br />
  
          <mat-label translate class="radio-title">Fixed ?</mat-label>
              <mat-radio-group class="radio-group readonly" value="{{res.fixed}}">
                  <mat-radio-button class="radio-button" value="true">{{
                    'Yes' | translate
                    }}</mat-radio-button>
                  <mat-radio-button class="radio-button" value="false">{{
                    'No' | translate
                    }}</mat-radio-button>
              </mat-radio-group>
              <!--Si le vendor a fixé la reserve-->
          <strong *ngIf="res.fixed === true">{{'Fixé le' | translate }} => {{res.fixedOn | date}}</strong> <br /><br />
          <br />
          
          <!--Si le vendor a donné une date de correction-->
          <strong *ngIf="res.fixedDate && res.fixedDate !== null" class="fix">{{'Date de correction prévue' | translate }} => {{res.fixedDate | date}}</strong> <br /><br />
          
           <mat-form-field appareance="outline">
             <mat-label translate>Description de la reserve</mat-label>
              <textarea id="myInput"
                matInput
                placeholder="{{ 'Description de la reserve' | translate }}"
                class="form-control" value="{{res.content}}"
                readonly>
              </textarea>
            </mat-form-field>
  
          <a class="icon" (click)="copy()" title="Copy to clipboard"><em style="color:#5bc0de;" class="fas fa-copy"></em></a>
          
      </div>
      
    </div>
      
    <!--VEOLIA OWNER-->
    <div *ngIf="isSoOfSolution === true">
      
       <div *ngFor="let res of reservationsList">
        <hr>
        
        <p translate>Check out
            <a target="_blank" routerLink="/formulaireAll/{{res.type}}Form/{{res.idSolution}}" translate>{{res.reference}}</a>
        </p>
        
        <br />
        
        <strong style="color: #5bc0de;">{{ res.reference }}</strong> <br />
          <em> {{'Réserve émise le' | translate }} {{ res.postedAt | date: 'longDate' }}</em> <br />
          <em> {{'Deadline imposée' | translate }} {{ res.deadlineDate | date: 'longDate' }} </em> <br />
          <em> {{'By' | translate }} {{ res.postedBy }} ({{res.userRole}})</em> <br />
          <em> {{'Description de la reserve' | translate }}: 
                      <strong style="color:#5bc0de" *ngIf="res.content">{{ res.content }}</strong>
                      <strong style="color:#5bc0de" *ngIf="!res.content" translate>(Not provided)</strong>
          </em>
          <!--Si le vendor a fixé la reserve-->
          <strong *ngIf="res.fixed === true">{{'Fixé le' | translate }} => {{res.fixedOn | date}}</strong> <br /><br />
          
          <em> {{'Fixed ?' | translate }}: <strong style="color:#5bc0de">{{ res.fixed }}</strong> </em> <br />
          
          <!--Si le vendor a fixé une date de correction-->
          <strong *ngIf="res.fixedDate && res.fixedDate !== null" class="fix">{{'Date de correction prévue' | translate }} => {{res.fixedDate | date}}</strong> <br />
          
          <a
          class="btn text-primary "
          (click)="getForm(res.fixed,res.fixedDate)"
          title="Edit"
          >Edit<em class="material-icons iconEdit"> edit </em></a
          > 
          
          
        <br />
        
        <hr>
        <form
          class="res-form"
          *ngIf="reservationForm && this.click===true"
          [formGroup]="reservationForm"
          novalidate
          (ngSubmit)="onSubmit(reservationForm.value, this.resKey, res.formIs, res.idSolution, res.reference, res.content, res.postedBy, res.postedAt)"
        >
            
            <mat-label translate class="radio-title">Fixed ?</mat-label>
              <mat-radio-group class="radio-group" formControlName="fixed" >
                  <mat-radio-button class="radio-button" value="true" (click)="toggleHide()">{{
                    'Yes' | translate
                    }}</mat-radio-button>
                  <mat-radio-button class="radio-button" value="false" (click)="toggleDisplay()">{{
                    'No' | translate
                    }}</mat-radio-button>
              </mat-radio-group>
          <br />
          
          
          <mat-form-field id="fixedDateField">
              <input
                matInput
                [matDatepicker]="picker"
                placeholder="{{'Date de correction prévue' | translate}}"
                class="form-control" formControlName="fixedDate"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          
          
          <div class="row submit-button-container">
            <div class="col-md-4">
              <button
                mat-raised-button
                class="submit-button"
                color="primary"
                type="submit"
                style="background:#00AEC7; "
              >
                Save
              </button>
            </div>
          </div>
          
        </form>
      
       </div>
       
  
    </div>
  
  </section>
  
</div>
    