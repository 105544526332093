import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GuardFunctionsService } from './guard-functions.service';


@Injectable({
  providedIn: 'root'
})

export class AccessEditDemandeGuard implements CanActivate {
  constructor(private guardService: GuardFunctionsService, private router: Router, private toastr: ToastrService) {}

 canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const idSol = route.paramMap.get('id')

    try {
       return this.guardService.isAuthorizedToAccessEditDemande(idSol).pipe(
        tap((authorized) => {

          if(authorized === false) {
            console.log("DENIED FALSE")
            alert('You are not allowed to view this page');
            this.toastr.error('You are not allowed to view this page', 'Access denied', {
              timeOut: 5000,
              closeButton: true,
              progressBar: true,
              positionClass: 'toast-bottom-center'
            });
            this.router.navigate(['/homeUser']);
          } else {
            console.log("GRANTED TRUE")
            //alert('Access granted !');
          }
        })
       )

    } catch(err) {
        console.log(err);
        return false;
    }

 }

}
