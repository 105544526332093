import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { HomeUserService } from '../homeUser.service';

@Injectable()
export class SolutionResolver implements Resolve<any> {
  constructor(public firebaseService: HomeUserService) {}

  //Pour récupérer les données de la solution (utilisé par tous les forms)
  resolve(route: ActivatedRouteSnapshot) {
    return new Promise((resolve, reject) => {
      const solutionId = route.paramMap.get('id');
      this.firebaseService.getDemandeBySolutionId(solutionId).subscribe(dataSolution => {
        resolve(dataSolution);
      });
    });
  }
}
