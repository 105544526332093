<div class="container">
  <mat-card>
   
    <mat-card-content>
      <mat-card-title id="titre_form" translate>Edit your password</mat-card-title>
      
      <div *ngIf="(auth.user | async) as user">
      
      <!-- USER VENDOR ONLY-->
      
      <div>
        <em style="color:black;">{{ 'infosChangePass' | translate }}</em>
        <ul>
          <li><em style="color:black;">{{ 'lowercase' | translate }}</em></li>
          <li><em style="color:black;">{{ 'uppercase' | translate }}</em></li>
          <li><em style="color:black;">{{ 'digits' | translate }}</em></li>
          <li><em style="color:black;">{{ 'special' | translate }}</em></li>
        </ul>
      </div>
      <br />
                
      <form 
        *ngIf="editPassForm && auth.isVendor(user)"
        [formGroup]="editPassForm"
        (ngSubmit)="onSubmit()"
        class="editPwd-form"
      >
          
        <mat-card>
          
          <label for="password"
                    >{{ 'oldPassword' | translate }}<em style="font-size: small;">{{ 'infos2' | translate }}</em>
                    <span class="material-icons" id="seePassword">visibility</span>
                    </label
                  >
                <div>
                    <input
                    type="password"
                    class="form-control"
                    formControlName="oldPassword"
                    [(ngModel)]="oldPassword"
                    required
                    minlength="6" maxlength="32"
                    id="pwd"
                  />
                </div>
                  

                  <!--Si expression régulière non respectée-->
                  <div *ngIf="f.oldPassword.invalid && (f.oldPassword.dirty || f.oldPassword.touched)" style="color:red;">
                    <em>Your current password must match with required characters (see above description)</em>
                  </div>
                  <div *ngIf="f.oldPassword.errors?.minlength" style="color:red;">
                    <em>Password must contains min 6-32 max characters</em>
                  </div>
              <!--</div>-->
              <!--<div *ngIf="f.oldPassword.errors?.required">-->
              <!--  Name is required.-->
              <!--</div>-->

                  <br /><br />
                  <label for="password"
                    >{{ 'newPassword' | translate }}<em style="font-size: small;">{{ 'infos2' | translate }}</em> 
                    <span class="material-icons" id="seePassword2">visibility</span>
                    </label
                  >
                  <div>
                  <input
                    type="password"
                    class="form-control"
                    formControlName="newPassword"
                    [(ngModel)]="newPassword"
                    required
                    minlength="6" maxlength="32"
                    id="pwd2"
                  />
                  </div>
                  
                  <div *ngIf="f.newPassword.invalid && (f.newPassword.dirty || f.newPassword.touched)" style="color:red;">
                    <em>Your new password must match with required characters (see above description)</em>
                  </div>
                  <div *ngIf="f.newPassword.errors?.minlength" style="color:red;">
                    <em>Password must contains min 6-32 max characters</em>
                  </div>
                  
        </mat-card>
        <br />


            <div class="row submit-button-container">
              <div class="col-md-4">
                <button translate mat-raised-button class="submit-button" style="color: white;background: #00aec7;" type="submit">
                  {{ 'OK' | translate }}
                </button>
              </div>
            </div>
          
        
      </form>
      
      </div>
      
    </mat-card-content>
  </mat-card>
</div>